// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../../../node_modules/leaflet/dist/leaflet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-container {
  width: 100%;
  height: 400px;
}

.cluster-icon {
  background-color: #333;
  height: 2em;
  width: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 5px #fff;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/views/visitor-profile/components/SessionsLeafletMap.css"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;AAAF;;AAGA;EACE,sBAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;EACA,4BAAA;AAAF","sourcesContent":["@import url('~leaflet/dist/leaflet.css');\n\n.leaflet-container {\n  width: 100%;\n  height: 400px;\n}\n\n.cluster-icon {\n  background-color: #333;\n  height: 2em;\n  width: 2em;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  font-size: 1.2rem;\n  box-shadow: 0 0 0px 5px #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

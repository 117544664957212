import React from 'react';

import { Typography } from '@mui/material';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { trendChartList } from '../trendChart/trendChartList';
import { translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';
import { getDataExplorationSelectedChart } from 'app/shared/reducers/views/data-exploration';
import { KpiLineChart } from './KpiLineChart';
import { useDataExplorationKpisHistory } from '../../hooks/useDataExplorationKpisHistory';

export const KpiLineCard = () => {
  const selectedChart = useAppSelector(getDataExplorationSelectedChart);
  const { timerange, isFetching, kpisHistoryInTimerangeData, error, isError } = useDataExplorationKpisHistory();
  return (
    <LayoutItem
      gridItemProps={{ xs: 12, lg: 8 }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h4">
              {trendChartList.filter(element => element.kpi === selectedChart.name)[0] &&
                translate(`open-analytics.chart.${trendChartList.filter(element => element.kpi === selectedChart.name)[0].title}`)}
            </Typography>
          ),
          sx: {
            py: 1,
            pb: 0,
          },
        },
        content: { sx: { height: '300px', py: 0, '&:last-child': { py: 0 } } },
      }}
    >
      <KpiLineChart
        timerange={timerange}
        data={kpisHistoryInTimerangeData[selectedChart.name]}
        kpi={selectedChart.name}
        isFetching={isFetching}
        error={error}
        isError={isError}
      />
    </LayoutItem>
  );
};

import { Avatar, Popover, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { Translate } from 'react-jhipster';
import { MiniProductsMenus } from './MiniProductsMenus';
import { productsModules } from 'app/modules/views/module-root/config/productModules';
import { AvailableProductsMenu } from './AvailableProductsMenu';

export const AvailableProducts = ({ otherProducts }) => {
  const [product, setProduct] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {otherProducts
        .sort(function (a, b) {
          if (a.name > b.name) {
            return -1;
          }
          if (b.name > a.name) {
            return 1;
          }
          return 0;
        })
        .map((p, index) => {
          const Icon = productsModules[p.name].sidebar.icon;
          return (
            <Tooltip
              key={index}
              title={
                <Typography variant="h6" textTransform="capitalize">
                  <Translate contentKey={`module-factory.${p.name}`}>{p.name}</Translate>
                </Typography>
              }
              placement="left"
              arrow
            >
              <Avatar
                onClick={e => {
                  setProduct(p);
                  handleClick(e);
                }}
                sx={{
                  cursor: 'pointer',
                  width: 56,
                  height: 56,
                  bgcolor: theme => theme.palette.primary.light,
                  m: 2,
                  '&:hover': {
                    bgcolor: 'transparent',
                    color: theme => theme.palette.primary.light,
                    boxShadow: theme => theme.shadows[5],
                  },
                }}
                variant="rounded"
              >
                {Icon}
              </Avatar>
            </Tooltip>
          );
        })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: -23,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'visible',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            left: '150px',
            width: '0px',
            height: '0px',
            borderTop: '5px solid transparent',
            borderBottom: '5px solid transparent',
            borderLeft: theme => `8px solid ${theme.palette.primary.main}`,
          }}
        />
        <AvailableProductsMenu p={product} />
      </Popover>
    </>
  );
};

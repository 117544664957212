import React, { useState, useEffect } from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Add, East, ListAlt, UploadFile } from '@mui/icons-material';
import { RouterLink } from 'app/config/modules/react-router-dom';
import { sitemap } from 'app/shared/util/url-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { UploadDialog } from 'app/modules/views/recommendations/catalog/legacy/UploadDialog';
import { Translate } from 'react-jhipster';
import { getCorpus } from 'app/shared/reducers/recommendations/corpus';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { CreateCorpus } from 'app/modules/views/recommendations/catalog/components/CreateCorpus';
import { useFindAllCorporaQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const CatalogManagement = () => {
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { data: corpusList = {} } = useFindAllCorporaQuery(
    accountProfile.accountId ? { accountId: accountProfile.accountId.substring(0, 8) } : skipToken
  );

  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.catalog-management">Catalog Management</Translate>
            </Typography>
          ),
          avatar: <ListAlt color="primary" />,
          disableTypography: true,
          action: (
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={e => {
                e.stopPropagation();
                setOpenUploadDialog(prev => !prev);
              }}
              startIcon={<Add />}
            >
              <Translate contentKey="recommendation.corpus.create-corpus">Upload new catalog</Translate>
            </Button>
          ),
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
            transition: 'all 500ms linear',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        actions: (
          <>
            <Button size="small" variant="outlined" component={RouterLink} to={`${sitemap.recommendations.corpus}`} endIcon={<East />}>
              <Translate contentKey="module-factory.accordion.catalog-management-page">Catalog management page</Translate>
            </Button>
          </>
        ),
      }}
    >
      <Typography variant="subtitle1">
        <Translate contentKey="module-factory.accordion.catalog-management-text">
          Manage your catalog, view catalog list add new one.
        </Translate>
      </Typography>
      {corpusList.content && corpusList.content.length > 0 && (
        <BasicCardLayout
          header={{
            title: (
              <Typography variant="subtitle1">
                <Translate contentKey="module-factory.accordion.catalog-list">Catalog List</Translate>
              </Typography>
            ),
            disableTypography: true,
          }}
          card={{ sx: { width: '30%', mx: 'auto' } }}
          content={{ sx: { p: 0, '&:last-child': { p: 0 } } }}
        >
          <List>
            {corpusList.content.map((corpus, index) => (
              <ListItem divider key={index}>
                <ListItemText primary={corpus.name} />
              </ListItem>
            ))}
          </List>
        </BasicCardLayout>
      )}
      <CreateCorpus open={openUploadDialog} setOpen={setOpenUploadDialog} />
    </LayoutItem>
  );
};

import React from 'react';

import { GroupedListLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { ClickStreamConfig } from '../accordion-items/ClickStreamConfig';
import { Dashboards } from '../accordion-items/Dashboards';
import { ExportRawData } from '../accordion-items/ExportRawData';
import { TagManager } from '../accordion-items/TagManager';

export const WebAnalyticsAccordionWithReacdyStatus = () => {
  return (
    <GroupedListLayout>
      <ClickStreamConfig />
      <TagManager />
      <ExportRawData />
      <Dashboards />
    </GroupedListLayout>
  );
};

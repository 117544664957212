import React, { useState } from 'react';

import { ControlledTabulate, TabItem } from 'app/components/tabulation';
import { tabulations } from './tabulations';

export const LineChartsTabs = () => {
  const [selectedTab, setSelectedTab] = useState('0');
  return (
    <ControlledTabulate tabValue={selectedTab} setTabValue={value => setSelectedTab(value)} variant="fullWidth">
      {tabulations().map((el, index) => (
        <TabItem key={index} {...el} />
      ))}
    </ControlledTabulate>
  );
};

import { Moving, TrendingDown, TrendingUp } from '@mui/icons-material';
import { Tooltip, Typography, Box } from '@mui/material';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { KPIS } from 'app/shared/util/reducer-utils';
import React from 'react';
import TrendChart from './TrendChart';
import { useDashboardData } from './useDashboardData';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import GradingIcon from '@mui/icons-material/Grading';
import { Translate } from 'react-jhipster';

const icons = {
  [KPIS.USER_COUNT.name]: <SupervisedUserCircleIcon color="primary" fontSize="large" />,
  [KPIS.SESSION_COUNT.name]: <AccessTimeIcon color="primary" fontSize="large" />,
  [KPIS.PAGEVIEWS_COUNT.name]: <DescriptionIcon color="primary" fontSize="large" />,
  [KPIS.BOUNCE_RATE.name]: <GradingIcon color="primary" fontSize="large" />,
};

function formatNumberWithOptionalDecimal(number) {
  const formattedNumber = number.toFixed(1);
  const decimalPart = formattedNumber.split('.')[1];

  if (decimalPart === '0') {
    return number.toFixed(0);
  } else {
    return formattedNumber;
  }
}

export const DashboardCard = ({ kpi }) => {
  const { lastWekkData, weekBeforeData, differenceInPercentage, isFetching, isLoading, isSuccess } = useDashboardData(kpi);

  return (
    <LayoutItem
      gridItemProps={{ xs: 3 }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h4">
              <Translate contentKey={`open-analytics.mapChart.${kpi}`}>{kpi}</Translate>
            </Typography>
          ),
          action: icons[kpi],
        },
        ...(!isFetching &&
          lastWekkData.chartData &&
          lastWekkData.chartData.length > 0 && {
            actions: (
              <Tooltip
                title={
                  <Typography variant="subtitle2">
                    {lastWekkData.totalLastWeek && formatNumberWithOptionalDecimal(lastWekkData.totalLastWeek)} Last week VS{' '}
                    {weekBeforeData.totalWeekBefore && formatNumberWithOptionalDecimal(weekBeforeData.totalWeekBefore)} the week before
                  </Typography>
                }
              >
                {differenceInPercentage >= 0 ? (
                  <Box display="flex" sx={{ backgroundColor: theme => theme.palette.success.light, borderRadius: '3px', px: 1 }}>
                    <TrendingUp sx={{ color: theme => theme.palette.success.contrastText, mr: 1 }} fontSize="small" />
                    <Typography color={theme => theme.palette.success.contrastText} variant="subtitle1">
                      + {formatNumberWithOptionalDecimal(differenceInPercentage)} %
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" sx={{ backgroundColor: theme => theme.palette.error.light, borderRadius: '3px', px: 1 }}>
                    <TrendingDown sx={{ color: theme => theme.palette.error.contrastText, mr: 1 }} />
                    <Typography color={theme => theme.palette.error.contrastText} variant="subtitle1">
                      {formatNumberWithOptionalDecimal(differenceInPercentage)} %
                    </Typography>
                  </Box>
                )}
              </Tooltip>
            ),
          }),
        content: { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 0, '&:last-child': { py: 0 } } },
      }}
    >
      {lastWekkData.chartData && lastWekkData.chartData.length > 0 && (
        <Typography variant="h1">
          {lastWekkData.totalLastWeek && formatNumberWithOptionalDecimal(lastWekkData.totalLastWeek)} {kpi === KPIS.BOUNCE_RATE.name && '%'}
        </Typography>
      )}
      <TrendChart data={lastWekkData.chartData} loading={isLoading} />
    </LayoutItem>
  );
};

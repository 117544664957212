import React from 'react';

import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';
import { fr, enAU } from 'date-fns/locale';
import { translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';
import { createData } from './UsersOverviewTable';

export const UsersRow = (props: { row: ReturnType<typeof createData> }) => {
  const locales = { fr, en: enAU };
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}>
        <TableCell
          component="span"
          title={row.user}
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'black' }}
          onClick={() => {
            /* dispatch(getUserInfo([], row.user)); */
            /* navigate('/dashboards/explore/visitor'); */
            setOpen(!open);
          }}
        >
          {row.user}
        </TableCell>
        <TableCell color="black" align="center">
          {row.sessionCount}
        </TableCell>
        <TableCell color="black" align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ my: 1, ml: 5 }}>
              <Table size="small" sx={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" sx={{ width: '50%', color: 'common.black' }}>
                      {translate('user-exploration.users-table.session-id')}
                    </TableCell>
                    <TableCell variant="head" sx={{ width: '25%', color: 'common.black' }}>
                      {translate('user-exploration.users-table.first-event')}
                    </TableCell>
                    <TableCell variant="head" sx={{ width: '25%', color: 'common.black' }}>
                      {translate('user-exploration.users-table.last-event')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.sessions.map(sessionsRow => (
                    <TableRow hover key={sessionsRow.sessionId}>
                      <TableCell
                        component="span"
                        title={sessionsRow.sessionId}
                        scope="row"
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1.05em' }}
                      >
                        {sessionsRow.sessionId}
                      </TableCell>
                      <TableCell
                        component="span"
                        title={format(new Date(sessionsRow.firstEventEpochSeconds), 'MMM do HH:mm:ss', { locale: locales[currentLocale] })}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1.05em' }}
                      >
                        {format(new Date(sessionsRow.firstEventEpochSeconds), 'MMM do HH:mm:ss', { locale: locales[currentLocale] })}
                      </TableCell>
                      <TableCell
                        component="span"
                        title={format(new Date(sessionsRow.lastEventEpochSeconds), 'MMM do HH:mm:ss', { locale: locales[currentLocale] })}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1.05em' }}
                      >
                        {format(new Date(sessionsRow.lastEventEpochSeconds), 'MMM do HH:mm:ss', { locale: locales[currentLocale] })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

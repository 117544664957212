import React from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setSplit } from 'app/shared/reducers/web-analytics/pie-charts';

const SplitToggler = () => {
  const { splitBy } = useAppSelector(state => state.webAnalyticsOverview.pieCharts);
  const dispatch = useAppDispatch();

  const onChangeHandlerToggle = (event, currentBucket) => {
    if (currentBucket) dispatch(setSplit(currentBucket));
  };

  return (
    <ToggleButtonGroup size="small" exclusive value={splitBy} onChange={onChangeHandlerToggle}>
      <ToggleButton disableRipple={true} value={'sessions-by'} color="primary" sx={{ paddingBlock: 0 }}>
        <Translate contentKey="user-exploration.sessions">Sessions</Translate>
      </ToggleButton>
      <ToggleButton disableRipple={true} value={'users-by'} color="primary" sx={{ paddingBlock: 0 }}>
        <Translate contentKey="user-exploration.users">Users</Translate>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SplitToggler;

import React from 'react';
import { Button } from '@mui/material';
import { Translate } from 'react-jhipster';
import { Schedule } from '@mui/icons-material';

export function ScheduleDemoButton(props) {
  return (
    <Button
      variant="outlined"
      // size="large"
      href="https://calendly.com/hurence/scoringjoe-welcome"
      target="_blank"
      sx={{ width: 'max-content' }}
      startIcon={<Schedule />}
      {...props}
    >
      <Translate contentKey="landing.actions.schedule-demo">Schedule a demo</Translate>
    </Button>
  );
}

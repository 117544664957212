import React from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Typography } from '@mui/material';
import { Receipt } from '@mui/icons-material';
import { Translate } from 'react-jhipster';
import { InvoicesDataGrid } from 'app/modules/views/account-page/components/data-grids/invoices/InvoicesDataGrid';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';

export const InvoicesItem = () => {
  const { invoiceList } = useAppAccountStates();
  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.invoices">Invoices</Translate>
            </Typography>
          ),
          avatar: <Receipt color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <InvoicesDataGrid invoiceList={invoiceList} />
    </LayoutItem>
  );
};

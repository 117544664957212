import { useAppSelector } from 'app/config/store';
import { ProductNames } from 'app/shared/model/catalog.model';

export const useProductCardSecondary = (productName: ProductNames) => {
  const { currentUserRoles } = useAppSelector(state => state.userProfile);
  const { subscribedProducts } = useAppSelector(state => state.subscriptionBundles);

  const subscription = subscribedProducts.filter(sub => sub.productName === productName);

  const isSubscribed = subscription.length > 0;
  const isUserAdmin = currentUserRoles.includes('HURENCE_ADMIN');
  const isProductMultiple = subscribedProducts.filter(product => product.productName === productName).length > 1;

  return {
    isSubscribed,
    isUserAdmin,
    isProductMultiple,
  };
};

import { createSlice } from '@reduxjs/toolkit';

const mediaScreenSizes = {
  xs: 600,
  sm: 600,
  md: 768,
  lg: 992,
  xl: 1200,
};
export const mediaScreenTypeQueries = {
  None: undefined,
  Desktop: 'and (min-width: 1200px)',
  Laptop: 'and (min-width: 992px) and (max-width: 1200px)',
  Tablet: 'and (min-width: 600px) and (max-width: 992px)',
  Smartphone: 'and (max-width: 600px)',
};

type MediaScreenTypeQueriesType = typeof mediaScreenTypeQueries;

export type MediaScreenTypes = keyof MediaScreenTypeQueriesType;

export const mediaScreenTypes: MediaScreenTypes[] = Object.keys(mediaScreenTypeQueries) as MediaScreenTypes[];

export const propertyCardInitialState = {
  value: null,
  tag: null,
  classname: null,
  link: null,
  style: (mediaScreenTypes as string[]).reduce((acc, item) => {
    acc[item] = [];
    return acc;
  }, {}),
};

export const recoWidgetInitialState = {
  layoutStyle: {
    value: {
      None: 'grid',
    },
    style: mediaScreenTypes.reduce((acc, next) => {
      acc[next] = [
        {
          display: 'grid',
          'grid-gap': '20px',
          'grid-template-columns': 'repeat(5, 1fr)',
        },
        {
          display: 'grid',
          'grid-gap': '20px',
          'grid-template-columns': '1fr',
        },
        {
          display: 'flex',
          'flex-wrap': 'wrap',
          gap: '20px',
          'justify-content': 'space-around',
        },
        {
          'column-count': 4,
          'column-gap': '20px',
        },
        {
          overflow: 'hidden',
          'white-space': 'nowrap',
        },
      ];
      return acc;
    }, {}),
    itemStyle: mediaScreenTypes.reduce((acc, next) => {
      acc[next] = [
        {},
        {},
        { width: '22%' },
        {
          'break-inside': 'avoid-column',
          margin: '0 0 20px 0',
        },
        {
          display: 'inline-block',
          width: '30%',
        },
      ];
      return acc;
    }, {}),
  },
  containerStyle: {
    value: 'container',
    attributes: [],
    style: {
      None: [{}, { 'font-family': '"Roboto","Helvetica","Arial",sans-serif' }, {}],
    },
  },
  itemBoxStyle: {
    value: 'item-box',
    attributes: [],
    style: {
      None: [
        { padding: '1em' },
        { 'text-align': 'justify' },
        {
          background: 'rgb(255, 255, 255)',
          'border-radius': '6px',
          'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
        },
        { 'box-sizing': 'border-box' },
        // {
        //   'transition': 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        //   'overflow': 'hidden',
        // }
      ],
    },
  },
  cardStructure: [
    { ...propertyCardInitialState, tag: 'img', style: { None: [{ width: '100%' }] } },
    { ...propertyCardInitialState, value: 'title', tag: 'h4' },
    { ...propertyCardInitialState, value: 'description', tag: 'span' },
  ],
  recoParams: {
    strategy: 'random',
  },
  mediaScreenType: 'None' as MediaScreenTypes,
};

export type RecoWidgetState = Readonly<typeof recoWidgetInitialState>;

export const recoWidgetSlice = createSlice({
  name: 'recoWidget',
  initialState: recoWidgetInitialState as RecoWidgetState,
  reducers: {
    setLayoutStyle(state, action) {
      state.layoutStyle = action.payload;
    },
    setLayoutStyleValue(state, action) {
      if (action.payload.value === 'false') {
        delete state.layoutStyle.value[action.payload.mediaScreenType];
      } else {
        state.layoutStyle.value[action.payload.mediaScreenType] = action.payload.value;
      }
    },
    setContainerStyle(state, action) {
      state.containerStyle = action.payload;
    },
    setItemBoxStyle(state, action) {
      state.itemBoxStyle = action.payload;
    },
    setCardStructure(state, action) {
      state.cardStructure = action.payload;
    },
    updateCardStructure(state, action) {
      const { index, element } = action.payload;
      const newCardStructure = [...state.cardStructure];
      newCardStructure[index] = element;
      state.cardStructure = newCardStructure;
    },
    deleteCardStructure(state, action) {
      const { index } = action.payload;
      state.cardStructure = [...state.cardStructure.slice(undefined, index), ...state.cardStructure.slice(index + 1)];
    },
    addCardStructure(state, action) {
      state.cardStructure = [...state.cardStructure, action.payload];
    },
    setRecoParams(state, action) {
      state.recoParams = action.payload;
    },
    setMediaScreenType(state, action) {
      state.mediaScreenType = action.payload;
    },
    setRecoWidget(state, action) {
      return action.payload;
    },
    resetRecoWidget: () => recoWidgetInitialState,
  },
});

export const getRecoWidgetLayoutStyle = state => state.views.recoWidget.layoutStyle;
export const getRecoWidgetContainerStyle = state => state.views.recoWidget.containerStyle;
export const getRecoWidgetItemBoxStyle = state => state.views.recoWidget.itemBoxStyle;
export const getRecoWidgetCardStructure = state => state.views.recoWidget.cardStructure;

export const getRecoWidgetStrategy = state => state.views.recoWidget.recoParams;

export const getRecoWidgetMediaScreenType = state => state.views.recoWidget.mediaScreenType;
export const getRecoWidget = state => state.views.recoWidget;

export const {
  setLayoutStyle,
  setLayoutStyleValue,
  setContainerStyle,
  setItemBoxStyle,
  setCardStructure,
  addCardStructure,
  updateCardStructure,
  deleteCardStructure,
  setRecoParams,
  setMediaScreenType,
  setRecoWidget,
  resetRecoWidget,
} = recoWidgetSlice.actions;

export default recoWidgetSlice.reducer;

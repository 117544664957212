import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { lastWeek } from 'app/modules/views/module-root/views/web-analytics/components/map-chart/actions/timeranges';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { serializeAxiosError } from '../reducer.utils';

const kpisAggregationUrl = DATA_HUB_API_URL + '/exploration/data';

const initialState = {
  errorMessage: null as unknown as string, // Errors returned from server side
  loading: false,

  data: [] as Array<any>,

  params: lastWeek,
};

export type TopMetricsState = Readonly<typeof initialState>;

// Actions
export const getTopMetricsAggregation = createAsyncThunk(
  'webAnalyticsSummary/FETCH_TOP_METRICS_AGGREGATION',
  async ({ filter, from, to }: any) => {
    return microserviceHttpClient(
      `${kpisAggregationUrl}/kpisPerBucket?from=${from}&to=${to}&bucketingDimensions=COUNTRY_CODE,CITY_NAME,FIRST_VISITED_PAGE,SOURCE_OF_TRAFFIC_SOURCE&kpis=SESSION_COUNT`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    ).then(resp => resp.data);
  },
  { serializeError: serializeAxiosError }
);

// Slice
export const TopMetricsSlice = createSlice({
  name: 'topMetrics',
  initialState: initialState as TopMetricsState,
  reducers: {
    setParams(state, action) {
      state.params = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTopMetricsAggregation.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getTopMetricsAggregation.fulfilled, (state, action) => {
        state.loading = false;
        state.data = getTopData(action.payload);
      })
      .addCase(getTopMetricsAggregation.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;

        state.data = null as Array<any>;
      });
  },
});

export const { setParams } = TopMetricsSlice.actions;

export default TopMetricsSlice.reducer;

const bucketingDimensionsMappingList = [
  { name: 'name', value: 'FIRST_VISITED_PAGE' },
  { name: 'name', value: 'CITY_NAME' },
  { name: 'countryCode', value: 'COUNTRY_CODE' },
  { name: 'name', value: 'SOURCE_OF_TRAFFIC_SOURCE' },
];

const getTopData = payload => {
  return bucketingDimensionsMappingList.map(bucket => {
    const sortedData = payload.data[bucket.value].sort((a, b) => b.docCount - a.docCount);
    const count = sortedData[0].docCount;
    const name = sortedData[0][bucket.name];
    const total = payload.data[bucket.value].reduce((prev, current) => prev + Number(current.docCount), 0);
    const percentage = (Number(count) / total) * 100;
    return {
      count,
      name,
      kpi: bucket.value,
      percentage: percentage.toFixed(1),
    };
  });
};

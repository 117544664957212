import { createSlice } from '@reduxjs/toolkit';
import { dynamicStoreManager } from 'app/config/store';

export const useFormSlice = (storeKey, initialState = {}) => {
  const storeSlice = dynamicStoreManager.getDynamicSlice(storeKey);
  if (!storeSlice) {
    const newSlice = createSlice({
      name: storeKey,
      initialState,
      reducers: {
        setFormData: (state, action) => action.payload,
      },
    });

    dynamicStoreManager.injectSlice(storeKey, newSlice);
    return newSlice;
  }
  return storeSlice;
};

import isEqual from 'lodash/isEqual';
import React, { Fragment, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { FormControl, FormControlLabel, FormControlProps, FormGroup, Radio, RadioProps, Typography } from '@mui/material';

import { shuffleArray } from '../utils';
import { getOptionFromConfig, Option } from '../utils/options';
import { CommonFieldProps, MultiOptionFieldProps } from './props/FieldProps';
import ErrorText from './Widgets/ErrorText';
import { Title } from './Widgets/Title';

export interface StandardRadioGroupProps<TOption> extends CommonFieldProps<'radio-group', TOption> {
  attribute: Required<CommonFieldProps<'radio-group', TOption>>['attribute'];
  options: MultiOptionFieldProps<TOption>['options'];
  optionConfig?: MultiOptionFieldProps<TOption>['optionConfig'];
  randomizeOptions?: MultiOptionFieldProps<TOption>['randomizeOptions'];
  labelProps?: MultiOptionFieldProps<TOption>['labelProps'];
  groupContainerProps?: MultiOptionFieldProps<TOption>['groupContainerProps'];
}

export default function StandardRadioGroup<TOption>(props: {
  field: StandardRadioGroupProps<TOption>;
  methods: UseFormReturn;
  hideTitle?: boolean;
}) {
  const {
    field: fieldConfig,
    methods: {
      control,
      getValues,
      setValue,
      trigger,
      formState: { errors },
    },
    hideTitle,
  } = props;

  const options: Array<Option<TOption>> = useMemo(() => {
    let opt = fieldConfig.options || [];
    if (fieldConfig.randomizeOptions) {
      opt = shuffleArray(fieldConfig.options || []);
    }
    return opt.map(option => getOptionFromConfig(option, fieldConfig.optionConfig));
  }, [fieldConfig.options, fieldConfig.optionConfig]);

  const handleRadioChange = (value: TOption, checked: boolean) => {
    if (checked) {
      setValue(fieldConfig.attribute, value);
    }
  };

  const componentProps = (
    fieldConf: StandardRadioGroupProps<TOption>,
    option: Option<TOption>,
    value: TOption,
    index: number
  ): RadioProps => {
    return {
      id: fieldConf.id + '-' + index,
      key: option.key,
      color: 'primary',
      ...fieldConf.props,
      checked: isEqual(value, option.value),
      value: option.value,
      onChange: event => handleRadioChange(option.value, event.target.checked),
    };
  };

  const containerProps = (fieldConf: StandardRadioGroupProps<TOption>): FormControlProps => {
    return {
      error: !!errors[fieldConf.attribute],
      onBlur() {
        trigger(fieldConf.attribute);
      },
      ...fieldConf.groupContainerProps,
      sx: { flexWrap: 'wrap', ...fieldConf.groupContainerProps?.sx },
    };
  };

  return (
    <Controller
      name={fieldConfig.attribute}
      control={control}
      render={({ field }) => (
        <Fragment>
          {!hideTitle && fieldConfig.title && <Title field={fieldConfig} />}
          <FormGroup>
            <FormControl component={'fieldset' as 'div'} {...containerProps(fieldConfig)}>
              {options.map((option, index) => (
                <FormControlLabel
                  key={fieldConfig.attribute + '-' + index}
                  control={<Radio {...componentProps(fieldConfig, option, field.value, index)} />}
                  label={option.label}
                  {...fieldConfig.labelProps}
                />
              ))}
            </FormControl>
            {!!errors[fieldConfig.attribute] && <ErrorText error={String(errors[fieldConfig.attribute].message)} />}
          </FormGroup>
        </Fragment>
      )}
    />
  );
}

import React from 'react';

// material-ui
import { Avatar, ButtonBase } from '@mui/material';

import stringAvatar from 'app/shared/util/avatar-utils';
import { ProfilePopper } from './ProfilePopper';

interface ProfileSectionProps {
  theme: any;
  anchorRef: any;
  userProfile: any;
  userRoles: any;
  handleToggle: any;
  handleClose: any;
  open: any;
}

export const ProfileSectionPresentation = ({
  theme,
  anchorRef,
  userProfile,
  userRoles,
  handleToggle,
  handleClose,
  open,
}: ProfileSectionProps) => {
  return (
    <ButtonBase>
      <Avatar
        {...stringAvatar(`${userProfile.firstName} ${userProfile.lastName}`, 1, 30, {
          background: theme.palette.secondary.light,
          color: theme.palette.secondary.contrastText,
          '&:hover': {
            background: theme.palette.secondary.dark,
          },
          width: '34px',
          height: '34px',
          fontSize: '1.5rem',
        })}
        variant="rounded"
        onClick={handleToggle}
        ref={anchorRef}
      />
      <ProfilePopper
        theme={theme}
        anchorRef={anchorRef}
        userProfile={userProfile}
        userRoles={userRoles}
        handleClose={handleClose}
        open={open}
      />
    </ButtonBase>
  );
};

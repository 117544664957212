import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import { IconButton, IconButtonProps, styled, Toolbar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setExpanded } from 'app/shared/reducers/main-layouts/menubar';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const MenuToolbar = () => {
  const dispatch = useAppDispatch();
  const { expanded } = useAppSelector(state => state.mainLayouts.menubarReducer);
  const { subscribedProducts } = useAppSelector(state => state.subscriptionBundles);
  const { pathname } = useLocation();

  const handleExpandClick = () => {
    dispatch(setExpanded(!expanded));
  };

  if (subscribedProducts.length === 0 && pathname === '/home') return <></>;

  return (
    <Toolbar
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        // borderBlock: '1px solid',
        // borderColor: theme => theme.palette.divider,
        // py: expanded ? 1 : 0,
        py: 0,
        justifyContent: 'space-between',
        transition: 'all 500ms linear',
      }}
    >
      <Outlet />
      {/* <Box flexGrow={1} /> */}
      <ExpandMore expand={expanded} onClick={handleExpandClick}>
        <ExpandMoreIcon />
      </ExpandMore>
    </Toolbar>
  );
};

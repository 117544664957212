import React from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setSelectedMapChart } from 'app/shared/reducers/web-analytics/map-chart';

export const session = {
  name: 'SESSION_COUNT',
  value: 'count',
  i18n: 'sessions',
};
const user = {
  name: 'USER_COUNT',
  value: 'count',
  i18n: 'users',
};
export const activeUser = {
  name: 'ACTIVE_USER_COUNT',
  value: 'count',
  i18n: 'Active Users',
};

const KPIToggler = () => {
  const { selectedMapChart } = useAppSelector(state => state.webAnalyticsOverview.mapChart);
  const dispatch = useAppDispatch();

  const onChangeHandlerToggle = (event, currentBucket) => {
    if (currentBucket) dispatch(setSelectedMapChart(currentBucket));
  };

  return (
    <ToggleButtonGroup size="small" exclusive value={selectedMapChart} onChange={onChangeHandlerToggle}>
      <ToggleButton disableRipple={true} value={session} color="primary" sx={{ paddingBlock: 0 }}>
        <Translate contentKey="user-exploration.sessions">Sessions</Translate>
      </ToggleButton>
      <ToggleButton disableRipple={true} value={user} color="primary" sx={{ paddingBlock: 0 }}>
        <Translate contentKey="user-exploration.users">Users</Translate>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default KPIToggler;

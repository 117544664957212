import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { RECO_ENGINE_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
// import { IItems } from 'app/shared/model/recommendations/items.model';

const corpusUrl = RECO_ENGINE_API_URL + '/corpus';

export const initialState = {
  itemsLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  corpusList: null as Array<string>,
};

export type RecoCorpusState = Readonly<typeof initialState>;

// Actions

export const getCorpus = createAsyncThunk('reco/getCorpus', async () => microserviceHttpClient(`${corpusUrl}`, 'post'), {
  serializeError: serializeAxiosError,
});

// Slice

export const recoCorpusSlice = createSlice({
  name: 'corpus',
  initialState: initialState as RecoCorpusState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCorpus.pending, state => {
        state.itemsLoading = true;
        state.errorMessage = null;
      })
      .addCase(getCorpus.fulfilled, (state, action) => {
        state.itemsLoading = false;
        state.corpusList = action.payload.data as Array<string>;
      })
      .addCase(getCorpus.rejected, (state, action) => {
        state.itemsLoading = false;
        state.errorMessage = action.error.message;
        state.corpusList = null as Array<string>;
      });
  },
});

// Reducer
export default recoCorpusSlice.reducer;

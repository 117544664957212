import React, { useEffect, useState } from 'react';

import { DataGrid, GridActionsCellItem, GridRowParams, GridColDef } from '@mui/x-data-grid';
import { addFlex, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { translate } from 'react-jhipster';
import { ConfirmDeleteToken } from './ConfirmDeleteToken';
import { Tooltip } from '@mui/material';
import { useAccessToken } from '../../../hooks/useAccessToken';

export const AccessTokenDataGrid = () => {
  const accessTokenList = useAccessToken();
  const [token, setToken] = useState(null);

  // TODO DELETE action not available in the endpoints yet

  // const deleteAccesToken = () => {
  //   dispatch(deleteToken(token));
  //   dispatch(resetSucceededToken());
  //   setToken(null);
  // };

  const columns: GridColDef[] = [
    { field: 'name', headerAlign: 'left', type: 'string', align: 'left' },
    { field: 'expires', type: 'string' },
    { field: 'created', type: 'string', sortingOrder: ['desc'] },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key="delete"
          icon={
            <Tooltip title={translate('account.access-token.delete')}>
              <DeleteForeverOutlinedIcon />
            </Tooltip>
          }
          disabled
          color="error"
          onClick={() => {
            setToken(params.row.token);
          }}
          label={translate('account.access-token.delete')}
        />,
      ],
    },
  ];

  return (
    <>
      <DataGrid
        rows={accessTokenList || []}
        columns={columns.map(addFlex).map(translateHeaders('account.access-token'))}
        density="compact"
        getRowId={row => row.name}
        hideFooter
        initialState={{
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
        }}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
        }}
      />
      <ConfirmDeleteToken token={token} setToken={setToken} deleteAccesToken={() => {}} />
    </>
  );
};

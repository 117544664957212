import { useEffect } from 'react';

import { useAppDispatch } from 'app/config/store';
import { setUserExplorationTimerange } from 'app/shared/reducers/views/user-exploration';

export const useUserExplorationTimerange = timerange => {
  const dispatch = useAppDispatch();
  const { chosenRange, fromDate, toDate, refresh, refreshRate } = timerange;
  useEffect(() => {
    dispatch(
      setUserExplorationTimerange({
        chosenRange,
        fromDate,
        toDate,
        refresh,
        refreshRate,
        step: timerange.getStep(),
        live: timerange.isLive(),
      })
    );
  }, [chosenRange, fromDate, toDate, refresh, refreshRate]);
};

import { accountApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useMemo } from 'react';

export const useSubscriptionsStates = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});

  const subscriptionsListSelector = res =>
    useMemo(() => {
      return {
        ...res,
        subscriptionsList: res.data
          ? res.data.flatMap(value => {
              return value.subscriptions;
            })
          : [],
      };
    }, [res.data]);

  const { subscriptionsList = [], isError: isSubscriptionListError } = accountApi.endpoints.getAccountBundlesUsingGet.useQueryState(
    { accountKey: accountProfile.accountId },
    {
      selectFromResult: subscriptionsListSelector,
    }
  );

  return {
    isSubscriptionListError,
    subscriptionsList,
    subscribedProducts: subscriptionsList.filter(sub => sub.state === 'ACTIVE' && sub.productCategory === 'BASE'),
  };
};

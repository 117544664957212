import dayjs from 'dayjs';

export const getInvoiceDetail = (invoiceDetail, userProfile, accountProfile, billingAccount, currentLocale) => {
  return {
    date: dayjs(invoiceDetail.invoiceDate).locale(currentLocale).format('ll'),
    id: invoiceDetail.invoiceId,
    number: invoiceDetail.invoiceNumber,
    email: userProfile.email,
    company: billingAccount.company ? billingAccount.company : '-',
    address: billingAccount.address1 ? billingAccount.address1 : '-',
    accountID: userProfile.currentAccount,
    accountName: accountProfile.name ? accountProfile.name : '',
    // company: accountProfile.company ? accountProfile.company : '',
    items: invoiceDetail.items
      ? invoiceDetail.items.map(i => {
          return {
            module: i.prettyPhaseName,
            billingPeriod: i.endDate
              ? `${dayjs(i.startDate).locale(currentLocale).format('ll')} - ${dayjs(i.endDate).locale(currentLocale).format('ll')}`
              : `${dayjs(i.startDate).locale(currentLocale).format('ll')}`,
            amount: i.amount,
          };
        })
      : [],
    currency: invoiceDetail.currency,
    amount: invoiceDetail.amount,
    paidAmount: invoiceDetail.creditAdj,
    balance: invoiceDetail.balance,
  };
};

import React from 'react';
import { GreensightsHome } from '../views/greensights/GreensightsHome';
import { GreensightsSummary } from '../views/greensights/GreensightsSummary';
import { RecommendationsHome } from '../../recommendations/kpis/RecommendationHome';
import { RecommendationsSummary } from '../views/recommendations/RecommendationSummary';
import { WebAnalyticsHome } from '../views/web-analytics/WebAnalyticsHome';
import { WebAnalyticsSummary } from '../views/web-analytics/WebAnalyticsSummary';
import RecommendIcon from '@mui/icons-material/Recommend';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import { CoreCardLayoutProps } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Settings } from '@mui/icons-material';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { sitemap } from 'app/shared/util/url-utils';
import { Translate } from 'react-jhipster';
import { StatusPopper } from '../components/cards/StatusPopper';
import { Tooltip } from '@mui/material';
import { WebAnalyticsActions } from '../views/web-analytics/WebAnalyticsActions';

export interface ModuleProps {
  sidebar?;
  full?: JSX.Element;
  summary?: {
    cardOptions?: (props) => CoreCardLayoutProps;
    content: JSX.Element;
  };
}

export const productsModules = {
  'web-analytics': {
    full: <WebAnalyticsHome />,
    summary: {
      cardOptions({ p }) {
        return {
          header: {
            title: <Translate contentKey={`module-factory.${p}`}>{p}</Translate>,
            action: <WebAnalyticsActions />,
          },
        };
      },
      content: <WebAnalyticsSummary />,
    },
    sidebar: { icon: <AnalyticsIcon /> },
  },
  recommendations: {
    full: <RecommendationsHome />,
    summary: {
      cardOptions({ p, navigate }) {
        return {
          header: {
            title: <Translate contentKey={`module-factory.${p}`}>{p}</Translate>,
            // action: (
            //   <RawStackLayout direction="row">
            //     {/* <StatusPopper to={sitemap[p].home} missingSteps={2} /> */}
            //     <Tooltip title={<Translate contentKey="module-factory.settings">Settings</Translate>}>
            //       <Settings
            //         color="neutral"
            //         sx={{ cursor: 'pointer' }}
            //         onClick={() => {
            //           navigate(sitemap[p].settings);
            //         }}
            //       />
            //     </Tooltip>
            //   </RawStackLayout>
            // ),
          },
        };
      },
      content: <RecommendationsSummary />,
    },
    sidebar: { icon: <RecommendIcon /> },
  },
  greensights: {
    full: <GreensightsHome />,
    summary: {
      cardOptions({ p }) {
        return {
          header: {
            title: <Translate contentKey={`module-factory.${p}`}>{p}</Translate>,
          },
        };
      },
      content: <GreensightsSummary />,
    },
    sidebar: { icon: <BubbleChartIcon /> },
  },
  erpnext: {},
} as Record<string, ModuleProps>;

import React from 'react';

import AnalyticsIcon from '@mui/icons-material/Analytics';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Divider, Typography } from '@mui/material';
import { RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { sitemap } from 'app/shared/util/url-utils';
import { translate, Translate } from 'react-jhipster';
import { ImageCard } from '../../components/cards/ImageCard';
import TopMetricsGrid from './components/top-card';
import { useAppSelector } from 'app/config/store';
import { WizardStepper } from '../../components/stepper/WizardStepper';
import { steps } from './config/config-steps';
import { WebAnalyticsAccordion } from '../../components/accordion/web-analytics/WebAnalyticsAccordion';
import { DASHBOARDS, SETTINGS } from 'app/shared/reducers/main-layouts/menubar';
import { WebAnalyticsDashboards } from './dashboards/WebAnalyticsDashboards';

export function WebAnalyticsSummary() {
  const view = useAppSelector(state => state.mainLayouts.menubarReducer['web-analytics']);

  if (view === SETTINGS) return <WebAnalyticsAccordion />;
  else if (view === DASHBOARDS) return <WebAnalyticsDashboards />;

  if (!['READY'].includes(status)) {
    return <WizardStepper steps={steps} nonLinear orientation="vertical" />;
  } else {
    return (
      <RawStackLayout>
        <RawGridLayout>
          <LayoutItem gridItemProps={{ xs: 4, display: 'grid' }}>
            <ImageCard
              title={
                <Typography variant="h5">
                  <Translate contentKey="module-factory.dashboard">Dashboard</Translate>
                </Typography>
              }
              icon={AnalyticsIcon}
              links={[
                { title: translate(`module-factory.audience`, null, 'Audience'), to: sitemap['web-analytics'].audience },
                { title: translate(`module-factory.sessions`, null, 'Sessions'), to: sitemap['web-analytics'].sessions },
              ]}
            />
          </LayoutItem>
          <LayoutItem gridItemProps={{ xs: 4, display: 'grid' }}>
            <ImageCard
              title={
                <Typography variant="h5">
                  <Translate contentKey="module-factory.tools">Tools</Translate>
                </Typography>
              }
              icon={HomeRepairServiceIcon}
              links={[
                {
                  title: translate(`module-factory.get-tmg`, null, 'Get tag manager container'),
                  to: sitemap['web-analytics'].tagManager,
                },
                {
                  title: translate(`module-factory.export`, null, 'Export raw data'),
                  to: sitemap['web-analytics'].export,
                },
              ]}
            />
          </LayoutItem>
          <LayoutItem gridItemProps={{ xs: 4, display: 'grid' }}>
            <ImageCard
              title={
                <Typography variant="h5">
                  <Translate contentKey="module-factory.documentation">Documentation</Translate>
                </Typography>
              }
              icon={MenuBookIcon}
              links={[
                {
                  title: translate(`module-factory.quickstart`, null, 'Quickstart'),
                  to: sitemap.ext.docs.webAnalytics,
                  target: '_blank',
                },
              ]}
            />
          </LayoutItem>
        </RawGridLayout>

        <RawGridLayout mt={1}>
          <LayoutItem gridItemProps={{ xs: 12 }}>
            <Divider variant="fullWidth" sx={{ mb: 2 }} />
          </LayoutItem>
        </RawGridLayout>
        <TopMetricsGrid />
      </RawStackLayout>
    );
  }
}

import { ColorTranslator } from 'colortranslator';

function stringToRGBColor(string): string {
  let hash = 0;
  if (string.length === 0) return 'rgb(0, 0, 0)';
  for (let i = 0; i < string.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    // eslint-disable-next-line no-bitwise
    hash = hash & hash;
  }
  const rgb = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
}

function stringToHexColor(string): string {
  let hash = 0;
  if (string.length === 0) return '#000';
  for (let i = 0; i < string.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    // eslint-disable-next-line no-bitwise
    hash = hash & hash;
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).substring(-2);
  }
  return color;
}

function getInitials(name: string) {
  let initials = '';
  const words = name.trim().toUpperCase().split(' ');

  for (const word of words) {
    initials += word[0];
  }

  return initials;
}

function stringAvatar(name: string, length = 1, diameter = undefined, sx = {}, computeColor = false) {
  const bgcolor = new ColorTranslator(stringToRGBColor(name));
  const color = ColorTranslator.getHarmony(bgcolor.RGB);
  const initials = getInitials(name);
  return {
    sx: {
      ...(computeColor && { bgcolor: bgcolor.RGB, color: `${color[1]} !important` }),
      ...(diameter && { width: diameter, height: diameter, fontSize: diameter * 0.8 }),
      ...sx,
    },
    children: `${initials.substring(0, Math.min(initials.length, length))}`,
  };
}

export default stringAvatar;

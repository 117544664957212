import React, { useState } from 'react';

import { Divider, Drawer, Toolbar } from '@mui/material';
import { StyledDrawer } from './components/StyledDrawer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useTheme } from '@mui/material/styles';
import { AccountStatus } from 'app/shared/reducers/account-profiles/account-profile';
import { OpenSidebar } from './components/OpenSidebar';
import { PopperSidebar } from './components/PopperSidebar';
import { CollapseSidebar } from './components/CollapseSidebar';
import { setOpen as SetOpenMobile } from 'app/shared/reducers/main-layouts/left-sidebar';
import { useAppAccountStates } from '../../restricted/hooks/selectors/useAppAccountStates';
import { ErrorProductListDialog } from './components/ErrorProductListDialog';

export const AppSidebar = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();
  const { open: mobileOpen } = useAppSelector(state => state.mainLayouts.leftSidebar);
  const theme = useTheme();
  const { appBarHeight } = useAppSelector(state => state.mainLayouts.appbarReducer);
  const { accountProfile } = useAppAccountStates();

  const handleMobileToggle = () => {
    dispatch(SetOpenMobile(false));
  };

  const handleDrawerToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  if (accountProfile.status === AccountStatus.BOOTSTRAP) return null;

  const drawer = (
    <>
      <Toolbar sx={{ minHeight: `${appBarHeight}px !important` }} />
      {mobileOpen ? <OpenSidebar /> : open ? <OpenSidebar /> : <PopperSidebar />}

      <Divider />
      <CollapseSidebar open={open} handleDrawerToggle={handleDrawerToggle} />
    </>
  );

  return (
    <>
      <StyledDrawer
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
        variant="permanent"
        open={open}
        theme={theme}
      >
        {drawer}
      </StyledDrawer>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleMobileToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 200 },
        }}
      >
        {drawer}
      </Drawer>
      <ErrorProductListDialog />
    </>
  );
};

import React from 'react';
import { BasicCardLayout } from '../../components/layout/card/BasicCardLayout';
import { LayoutItemProps } from '../../components/LayoutItem';

export function LayoutItemCard({ children, key, cardOptions }: LayoutItemProps): JSX.Element {
  return (
    <BasicCardLayout key={key} {...cardOptions}>
      {children}
    </BasicCardLayout>
  );
}

import React from 'react';

import { Button, CardMedia, Typography } from '@mui/material';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate, translate } from 'react-jhipster';
import { variables } from '../../LandingPage';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { sitemap } from 'app/shared/util/url-utils';
import { Link } from 'react-router-dom';

export const Recommendations = () => {
  return (
    <GridLayout justifyContent={{ lg: 'space-between', xs: 'none' }}>
      <LayoutItem
        gridItemProps={{
          xs: 12,
          lg: 4,
          md: 6,
          sx: {
            textAlign: { xs: 'center', md: 'left' },
          },
          alignSelf: 'center',
        }}
        cardOptions={{
          variant: 'div',
          header: {
            disableTypography: true,
            title: (
              <Typography fontSize={'2.2rem'}>
                <Translate contentKey={'landingproducts.recommendations.title'} interpolate={{ ...variables }}>
                  Recommendations
                </Translate>
              </Typography>
            ),
          },
          content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        }}
      >
        <Typography fontSize="1.1rem" sx={{ mt: '3vh', mb: '5vh', textAlign: 'justify' }}>
          <Translate contentKey={'landing.products.recommendations.summary'} interpolate={{ ...variables }}>
            Recommend
          </Translate>
          <Button component={Link} to={`${sitemap.recommendationAlgorithms}`} target="_blank">
            {translate(`recommendation.see-more`)}
          </Button>
        </Typography>
      </LayoutItem>
      <LayoutItem
        gridItemProps={{
          xs: 12,
          lg: 5,
          md: 6,
        }}
        cardOptions={{
          variant: 'div',
          card: { sx: { overflow: 'visible' } },
          content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        }}
      >
        <CardMedia sx={{ aspectRatio: '2.2' }} image="content/img/landing-page/reco-capture.png" />
      </LayoutItem>
    </GridLayout>
  );
};

export const MiniRecommendations = () => {
  return (
    <BasicCardLayout
      variant="div"
      header={{
        disableTypography: true,
        title: (
          <Typography fontSize={'2.2rem'} textAlign="center" mb="5vh">
            <Translate contentKey={'landingproducts.recommendations.title'} interpolate={{ ...variables }}>
              Recommendations
            </Translate>
          </Typography>
        ),
      }}
      card={{ sx: { overflow: 'visible' } }}
      content={{ sx: { p: 0, '&:last-child': { p: 0 } } }}
      media={{
        sx: { aspectRatio: '2.2' },
        image: 'content/img/landing-page/reco-capture.png',
      }}
    >
      <Typography fontSize="1.1rem" sx={{ mt: '3vh', mb: '5vh', textAlign: 'justify' }}>
        <Translate contentKey={'landing.products.recommendations.summary'} interpolate={{ ...variables }}>
          Recommend
        </Translate>
      </Typography>
    </BasicCardLayout>
  );
};

import { useGetKpisHistoryInTimeRangeUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { getKpiFromPayload } from 'app/shared/util/data-hub-utils';
import { KPIS } from 'app/shared/util/reducer-utils';

export const useActiveUsersData = () => {
  const { data = null } = useGetKpisHistoryInTimeRangeUsingPostQuery(
    {
      kpis: 'ACTIVE_USER_COUNT',
      requestFilters: {
        filterEntryList: [],
      },
      from: 'now-15m',
      to: 'now',
      timeStep: 'PT1M',
    },
    {
      selectFromResult(res) {
        return {
          data: res.data ? getKpiFromPayload(res.data, KPIS.ACTIVE_USER_COUNT) : null,
        };
      },
    }
  );

  return {
    data,
  };
};

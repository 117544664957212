import React from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setParams } from 'app/shared/reducers/web-analytics/top-metrics';
import { lastMonth, lastWeek } from '../../map-chart/actions/timeranges';

export const TimeToggle = () => {
  const { params } = useAppSelector(state => state.webAnalyticsOverview.topMetrics);
  const dispatch = useAppDispatch();

  const onChangeHandlerToggle = (event, currentBucket) => {
    if (currentBucket) dispatch(setParams(currentBucket));
  };

  return (
    <ToggleButtonGroup size="small" exclusive value={params} onChange={onChangeHandlerToggle}>
      <ToggleButton disableRipple={true} value={lastWeek} color="primary" sx={{ paddingBlock: 0 }}>
        <Translate contentKey="module-factory.last-week">Last Week</Translate>
      </ToggleButton>
      <ToggleButton disableRipple={true} value={lastMonth} color="primary" sx={{ paddingBlock: 0 }}>
        <Translate contentKey="module-factory.last-month">Last Month</Translate>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

import React from 'react';

import * as echarts from 'echarts';
import world from 'app/shared/util/world.json';
import ReactECharts from 'echarts-for-react';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { WithData } from 'app/modules/loading-lib/WithData';
import latlong from '../latlong';
import { getName } from 'country-list';

const MapKPIChart = ({ data, selectedChart }) => {
  echarts.registerMap('world', world);

  let max = -Infinity;
  let min = Infinity;
  data.forEach(function (element) {
    if (element.buckets[selectedChart.name][selectedChart.value] > max) {
      max = element.buckets[selectedChart.name][selectedChart.value];
    }
    if (element.buckets[selectedChart.name][selectedChart.value] < min) {
      min = element.buckets[selectedChart.name][selectedChart.value];
    }
  });

  const option = {
    tooltip: {
      trigger: 'item',
      formatter(params) {
        return params.seriesName + '<br/>' + params.name + ' : ' + params.value[2];
      },
    },
    visualMap: {
      show: false,
      min: 0,
      max,
      inRange: {
        symbolSize: [6, 20],
      },
    },
    geo: {
      type: 'map',
      map: 'world',
      roam: true,
      scaleLimit: {
        max: 4,
        min: 1,
      },
      label: {
        emphasis: {
          show: false,
        },
      },
      // itemStyle: {
      //   normal: {
      //     areaColor: '#323c48',
      //     borderColor: '#111',
      //   },
      //   emphasis: {
      //     areaColor: '#2a333d',
      //   },
      // },
    },
    series: [
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        name: 'Active Users',
        data: data.map(function (element) {
          let obj = {};
          for (const [key, value] of Object.entries(latlong)) {
            if (element.countryCode === key)
              obj = {
                name: getName(key),
                value: [latlong[key].longitude, latlong[key].latitude, element.buckets[selectedChart.name][selectedChart.value]],
              };
          }
          // Object.entries(latlong).forEach(function (itemOpt) {
          //   if (element.countryCode === itemOpt.code)
          //     obj = {
          //       name: itemOpt.name,
          //       value: [
          //         latlong[itemOpt.code].longitude,
          //         latlong[itemOpt.code].latitude,
          //         element.buckets[selectedChart.name][selectedChart.value],
          //       ],
          //     };
          // });
          return obj;
        }),
      },
    ],
  };

  // const option: EChartsOption = {
  //   series: [
  //     {
  //       type: 'map',
  //       map: 'world',
  //       roam: true,
  //       scaleLimit: {
  //         max: 5,
  //         min: 1,
  //       },
  //     },
  //   ],
  // };
  // const theme = useTheme();
  // const option = {
  //   tooltip: {
  //     show: true,
  //     trigger: 'item',
  //     formatter(params) {
  //       return params.name + ' : ' + params.value;
  //     },
  //   },
  //   dataRange: {
  //     show: false,
  //     min: 0,
  //     max: 10,
  //     realtime: false,
  //     text: ['High', 'Low'],
  //     calculable: true,
  //     color: [theme.palette.primary.dark, theme.palette.primary.light],
  //   },
  //   series: {
  //     type: 'map',
  //     name: `kpi by country`,
  //     map: 'world',
  //     roam: true,
  //     data: data
  //       ? data.map(element => {
  //           let obj = {};
  //           getWorldMapData().forEach(function (itemOpt) {
  //             if (element.countryCode === itemOpt.code)
  //               obj = {
  //                 name: itemOpt.name,
  //                 value: element.buckets[selectedChart.name][selectedChart.value],
  //               };
  //           });
  //           return obj;
  //         })
  //       : [],
  //   },
  // };

  return <ReactECharts style={{ height: '100%', width: '100%' }} option={option} />;
};

export default WithLoading(WithData(MapKPIChart), state => state.webAnalyticsOverview.mapChart.loading);

import { Dashboard } from 'tabler-icons-react';

const icons = { Dashboard };

const recommendations = [
  {
    id: 'inventories',
    title: 'inventories',
    type: 'item',
    url: '/recommendations/inventories',
  },
];

export default recommendations;

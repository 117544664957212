import { Box, Typography } from '@mui/material';
import React from 'react';
import humanNumber from 'human-number';

export const RecommendationsProgress = ({ data, outerBoxProps = {}, innerBoxProps = {}, typographyProps = { variant: 'h4' } as any }) => {
  const [style, setStyle] = React.useState({} as any);

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${data.percentage}%`,
    };

    setStyle(newStyle);
  }, 200);

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: theme => theme.palette.primary.light,
        borderRadius: '10px',
        position: 'relative',
        margin: '15px 0',
        height: '30px',
        width: '100%',
        ...outerBoxProps,
      }}
    >
      <Box
        sx={{
          backgroundColor: theme => theme.palette.primary.dark,
          borderRadius: '10px',
          color: theme => theme.palette.primary.contrastText,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          height: '100%',
          width: 0,
          opacity: 0,
          transition: '1s ease 0.3s',
          padding: 1,
          ...style,
          ...innerBoxProps,
        }}
      >
        <Typography {...typographyProps}>{humanNumber(data.value[0], n => Number.parseFloat(n).toFixed(1))} From Reco</Typography>
      </Box>
      <Box
        sx={{
          borderRadius: '10px',
          color: theme => theme.palette.primary.contrastText,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          height: '100%',
          width: '100%',
          transition: '1s ease 0.3s',
          padding: 1,
          ...(style.width ? { width: `${100 - Number(data.percentage)}%` } : {}),
          ...innerBoxProps,
        }}
      >
        <Typography {...typographyProps}>{humanNumber(data.value[1], n => Number.parseFloat(n).toFixed(1))}</Typography>
      </Box>
    </Box>
  );
};

import { ICohort } from 'app/shared/model/user-exploration/cohort.model';
import { format } from 'date-fns';
import { fr, enAU } from 'date-fns/locale';
import { translate } from 'react-jhipster';

const locales = { fr, en: enAU };

export const checkIfOtherYearExist = (row: {
  [x: string]: {
    count: number;
  };
}): {
  isYearExist: boolean;
  index: number;
} => {
  const yearExist = { isYearExist: false, index: null };
  const keys = Object.keys(row);
  keys.forEach((element, index) => {
    if (Math.abs(Number(element) - Number(keys[index - 1])) > 1) {
      yearExist.isYearExist = true;
      yearExist.index = index;
    }
  });
  return yearExist;
};

const getValuesInPercentage = (row: ICohort, elementKey: string, startDate: string): number => {
  if (Number(row.data[startDate].count) === 0) return 0;
  return Number(Number((row.data[elementKey].count / row.data[startDate].count) * 100).toFixed(2));
};

export const getDates = (year: number, startDate: string, timestep: string, currentLocale: string): string => {
  if (timestep === 'DAY') {
    const date: Date = new Date(year, 0);
    return format(new Date(date.setDate(Number(startDate))), 'yyyy MMM d', { locale: locales[currentLocale] });
  } else if (timestep === 'WEEK') {
    const date = 1 + (Number(startDate) - 1) * 7;
    return `${format(new Date(year, 0, date), 'yyyy MMM d', { locale: locales[currentLocale] })} ${translate(
      'user-exploration.cohort.to'
    )} ${format(new Date(year, 0, date + 7), 'yyyy MMM d', { locale: locales[currentLocale] })}`;
  } else if (timestep === 'MONTH') {
    return format(new Date(year, Number(startDate) - 1), 'yyyy MMM', { locale: locales[currentLocale] });
  }
};

export const mapCohortDataIfOtherYearExist = (
  row: ICohort,
  rowIndex: number,
  yearIndex: number,
  displayedValeuType: string
): {
  rowValues: number[][];
  maxRowValues: number;
} => {
  const rowValues: number[][] = [];
  let maxRowValues = 0;
  const oldYearKeys = Object.keys(row.data).slice(yearIndex);
  const newYearKeys = Object.keys(row.data).slice(0, yearIndex);
  let counter = 0;
  oldYearKeys.forEach((key, keyIndex) => {
    if (displayedValeuType === 'count')
      rowValues.push([
        counter,
        rowIndex,
        row.data[key].count,
        getValuesInPercentage(row, key, row.startDate),
        Object.keys(row.data).length - 1 - counter,
      ]);
    else if (displayedValeuType === 'percentage')
      rowValues.push([
        counter,
        rowIndex,
        getValuesInPercentage(row, key, row.startDate),
        row.data[key].count,
        Object.keys(row.data).length - 1 - counter,
      ]);
    counter = counter + 1;
    if (row.data[key].count > maxRowValues) maxRowValues = row.data[key].count;
  });
  newYearKeys.forEach((key, keyIndex) => {
    if (displayedValeuType === 'count')
      rowValues.push([
        counter,
        rowIndex,
        row.data[key].count,
        getValuesInPercentage(row, key, row.startDate),
        Object.keys(row.data).length - 1 - counter,
      ]);
    else if (displayedValeuType === 'percentage')
      rowValues.push([
        counter,
        rowIndex,
        getValuesInPercentage(row, key, row.startDate),
        row.data[key].count,
        Object.keys(row.data).length - 1 - counter,
      ]);
    counter = counter + 1;
    if (row.data[key].count > maxRowValues) maxRowValues = row.data[key].count;
  });
  return {
    rowValues,
    maxRowValues,
  };
};

export const mapCohortDataIfNoOtherYearExist = (
  row: ICohort,
  rowIndex: number,
  displayedValeuType: string
): {
  rowValues: number[][];
  maxRowValues: number;
} => {
  const rowValues: number[][] = [];
  let maxRowValues = 0;
  let counter = 0;
  Object.keys(row.data).forEach((elementKey, elementKeyIndex) => {
    if (displayedValeuType === 'count')
      rowValues.push([
        counter,
        rowIndex,
        row.data[elementKey].count,
        getValuesInPercentage(row, elementKey, row.startDate),
        Object.keys(row.data).length - 1 - counter,
      ]);
    else if (displayedValeuType === 'percentage')
      rowValues.push([
        counter,
        rowIndex,
        getValuesInPercentage(row, elementKey, row.startDate),
        row.data[elementKey].count,
        Object.keys(row.data).length - 1 - counter,
      ]);
    counter = counter + 1;
    if (row.data[elementKey].count > maxRowValues) maxRowValues = row.data[elementKey].count;
  });

  return {
    rowValues,
    maxRowValues,
  };
};

import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  addFlex,
  renderBrowser,
  renderDevice,
  renderFlag,
  translateHeaders,
  typographyWithTooltip,
} from 'app/components/data-grid/columns/column-helpers';
import { IUsersOverview } from 'app/shared/model/users-overview.model';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { WithData } from 'app/modules/loading-lib/WithData';
import { LoadingStackSkeleton } from 'app/modules/loading-lib/component/LoadingStackSkeleton';

interface TopUsersDataGridProps {
  data: IUsersOverview[];
}

const columns: GridColDef[] = [
  {
    field: 'userId',
    ...typographyWithTooltip(),
  },
  {
    field: 'engagementScore',
    valueFormatter(value) {
      if (value.value) return `${Number(Number(value.value) * 100).toFixed(2)} %`;
    },
    ...typographyWithTooltip('formattedValue'),
    type: 'number',
  },
  {
    field: 'sessionCount',
    ...typographyWithTooltip(),
    type: 'number',
  },
  {
    field: 'countrySplit',
    renderCell: renderFlag,
  },
  {
    field: 'deviceCategorySplit',
    renderCell: renderDevice,
  },
  {
    field: 'browserFamilySplit',
    renderCell: renderBrowser,
  },
];

const TopUsersDataGrid = ({ data }: TopUsersDataGridProps) => {
  return (
    <DataGrid
      rows={data || []}
      columns={columns.map(addFlex).map(translateHeaders('user-exploration.users-table'))}
      density="compact"
      getRowId={row => row.id}
      initialState={{
        sorting: {
          sortModel: [{ field: 'engagementScore', sort: 'desc' }],
        },
      }}
      hideFooter
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      showColumnVerticalBorder
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
      }}
    />
  );
};

export default WithLoading(WithData(TopUsersDataGrid), state => state.webAnalyticsOverview.topUsers.loading, {
  loader() {
    return <LoadingStackSkeleton />;
  },
});

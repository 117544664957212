import React from 'react';

import { SettingsApplicationsOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate } from 'react-jhipster';

export const ServiceAccount = () => {
  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.service-account">Service Account</Translate>
            </Typography>
          ),
          avatar: <SettingsApplicationsOutlined color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
            transition: 'all 500ms linear',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <Typography variant="subtitle1">
        <Translate contentKey="module-factory.accordion.service-account-text">
          Grant permission to service account to start the greensight service.
        </Translate>
      </Typography>
    </LayoutItem>
  );
};

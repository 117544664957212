import { DataGridProps } from '@mui/x-data-grid';

export function autoPagination(): Partial<DataGridProps> {
  return {
    pagination: true,
    autoPageSize: true,
  };
}

export function sizedPagination(pageSize = 10, rowsPerPageOptions = [10, 20, 50, 100]): Partial<DataGridProps> {
  return {
    pagination: true,
    initialState: {
      pagination: {
        paginationModel: {
          pageSize,
        },
      },
    },
    pageSizeOptions: rowsPerPageOptions,
  };
}

export function controlledPagination(pageSize, setPageSize, rowsPerPageOptions = [10, 20, 50, 100]): Partial<DataGridProps> {
  return {
    pagination: true,
    pageSizeOptions: rowsPerPageOptions,
    paginationModel: { pageSize, page: 0 },
    onPaginationModelChange: newPageSize => setPageSize(newPageSize.pageSize),
  };
}

import React from 'react';

import { Divider } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { UsersDatagridCard } from './components/users-datagrid/UsersDatagridCard';
import { UsersMapCard } from './components/users-map';
import { DonutsSplitCard } from './components/donuts-splits';
import { UserExplorationRequestFilters } from './components/filter/UserExplorationRequestFilters';
import { UserExplorationTimeRangeFilters } from './components/filter/UserExplorationTimeRangeFilters';
import { useUserExploration } from './hooks/useUserExploration';
import { UsersCohortSectionCard } from './components/users-cohort-section/UsersCohortSectionCard';

export const UserExploration = () => {
  useUserExploration();

  return (
    <BasicCardLayout
      variant="div"
      header={{
        disableTypography: true,
        title: <UserExplorationRequestFilters />,
        action: <UserExplorationTimeRangeFilters />,
        sx: { p: 1, '.MuiCardHeader-action': { alignSelf: 'auto' } },
      }}
      content={{ sx: { p: 0 } }}
      card={{ sx: { overflow: 'visible' } }}
    >
      <RawStackLayout>
        <GridLayout>
          <UsersDatagridCard />

          <UsersCohortSectionCard />

          <UsersMapCard />
        </GridLayout>

        <LayoutItem>
          <Divider variant="fullWidth" sx={{ mb: 2 }} />
        </LayoutItem>

        <DonutsSplitCard />
      </RawStackLayout>
    </BasicCardLayout>
  );
};

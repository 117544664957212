import { Backdrop, CircularProgress, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import React, { ComponentType, FC } from 'react';
import { ErrorComponent } from './component/ErrorComponent';

export const WithLoadingHOC = <P extends object>(Component: ComponentType<P>): FC<P> => {
  const withLoadingHOC = props => {
    // if (props?.isLoading) return <Skeleton animation="wave" variant="rectangular" height={'100%'} />;
    return (
      <Box height="100%" width="100%" position="relative">
        <Backdrop
          open={props?.isFetching}
          sx={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.05)', zIndex: theme => theme.zIndex.drawer }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        {props?.isError ? <ErrorComponent error={props?.error} /> : <Component {...props} />}
      </Box>
    );
  };
  return withLoadingHOC;
};

import { TranslatorContext, Storage } from 'react-jhipster';
import * as muiLocales from '@mui/material/locale';
import { setLocale } from 'app/shared/reducers/locale';
import * as muiGridLocales from '@mui/x-data-grid/locales';
import { Localization } from '@mui/x-data-grid/utils/getGridLocalization';

TranslatorContext.setDefaultLocale('en');
TranslatorContext.setRenderInnerTextForMissingKeys(true);

/* export const languages: any = {
  'ar-ly': { name: 'العربية', rtl: true },
  'zh-cn': { name: '中文（简体）' },
  en: { name: 'English' },
  fr: { name: 'Français' },
  de: { name: 'Deutsch' },
  it: { name: 'Italiano' },
  es: { name: 'Español' },
  // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
}; */

interface ILanguage {
  name: string;
  mui: (muiLocales.Localization | Localization)[];
  rtl?: boolean;
}

export function getLocale(locales: string[] = []) {
  const navigatorLocale = new Intl.Locale(navigator.language);
  locales = locales.concat([localStorage.getItem('locale'), navigatorLocale.language, 'en']);
  return locales.find(locale => languages[locale] !== undefined);
}

export const languages: Record<string, ILanguage> = {
  en: { name: 'English', mui: [muiLocales.enUS, muiGridLocales.enUS] },
  fr: { name: 'Français', mui: [muiLocales.frFR, muiGridLocales.frFR] },
  // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
};

export const locales = Object.keys(languages).sort();

export const isRTL = (lang: string): boolean => languages[lang] && languages[lang].rtl;

export const registerLocale = store => {
  store.dispatch(setLocale(getLocale()));
};

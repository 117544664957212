import React, { useContext } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUserExplorationSplitBy, setUserExplorationSplitBy } from 'app/shared/reducers/views/user-exploration';

const BucketingToggler = () => {
  const dispatch = useAppDispatch();
  const splitBy = useAppSelector(getUserExplorationSplitBy);

  const onChangeHandlerToggle = (event, currentBucket) => {
    if (currentBucket) dispatch(setUserExplorationSplitBy(currentBucket));
  };

  return (
    <ToggleButtonGroup size="small" exclusive value={splitBy} onChange={onChangeHandlerToggle}>
      <ToggleButton disableRipple={true} value={'sessions-by'} color="primary" sx={{ paddingBlock: 0 }}>
        <Translate contentKey="user-exploration.sessions">Sessions</Translate>
      </ToggleButton>
      <ToggleButton disableRipple={true} value={'users-by'} color="primary" sx={{ paddingBlock: 0 }}>
        <Translate contentKey="user-exploration.users">Users</Translate>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default BucketingToggler;

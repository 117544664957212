import React, { useEffect } from 'react';

import { ListItemButton, ListItemText, List, Typography, Collapse } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { locales, languages, isRTL } from 'app/config/translation';
import { setLocale } from 'app/shared/reducers/locale';

export const LanguageMenu = ({ openLang, setOpen, setOpenLang }) => {
  const dispatch = useAppDispatch();
  const { currentLocale } = useAppSelector(state => state.locale);
  const handleLocaleChange = langKey => {
    dispatch(setLocale(langKey));
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      setOpenLang(false);
    };
  }, []);

  return (
    <Collapse in={openLang} timeout="auto" sx={{ mx: 5 }} unmountOnExit>
      <List component="div">
        {locales.map(locale => (
          <ListItemButton selected={locale === currentLocale} key={locale} onClick={() => handleLocaleChange(locale)}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={{ ...(locale === currentLocale && { color: 'primary.main' }) }}>
                  {`${languages[locale].name}`}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  );
};

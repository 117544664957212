import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { microserviceHttpClient } from 'app/shared/api/microservices';
import { ISessionInfo } from 'app/shared/model/visitor-profile/sessionInfo.model';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';

const sessionsExplorationUrl = DATA_HUB_API_URL + '/exploration/sessions';

export const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  session_info: null as unknown as ISessionInfo,
};

export type SessionInfoState = Readonly<typeof initialState>;

// Actions

export const getSessionInfo = createAsyncThunk(
  'sessionInfo/getSessionInfo',
  async ({ filter, sessionId }: { filter: any; sessionId: any }) => {
    return microserviceHttpClient(
      `${sessionsExplorationUrl}/${sessionId}`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    );
  },
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const SessionInfoSlice = createSlice({
  name: 'sessionInfo',
  initialState: initialState as SessionInfoState,
  reducers: {
    resetSessionInfo(state) {
      state.session_info = null as unknown as ISessionInfo;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSessionInfo.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getSessionInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.session_info = action.payload.data as ISessionInfo;
      })
      .addCase(getSessionInfo.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.session_info = null as unknown as ISessionInfo;
      });
  },
});

// Reducer
export default SessionInfoSlice.reducer;

export const { resetSessionInfo } = SessionInfoSlice.actions;

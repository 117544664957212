import { Box } from '@mui/material';
import {
  DataGridProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import React, { JSXElementConstructor } from 'react';

type IToolbarComponents = string | React.ReactNode;

const toolbarComponents = {
  columns: <GridToolbarColumnsButton key="columns" />,
  filter: <GridToolbarFilterButton key="filter" />,
  density: <GridToolbarDensitySelector key="density" />,
  export: <GridToolbarExport key="export" />,
  search: <GridToolbarQuickFilter key="search" />,
};

export function ToolbarFactory(components: IToolbarComponents[]): JSXElementConstructor<any> {
  return () => (
    <GridToolbarContainer>
      {components.map(value => {
        if (typeof value === 'string') {
          return toolbarComponents[value];
        } else {
          return value;
        }
      })}
    </GridToolbarContainer>
  );
}

export const commonToolbars: { [key: string]: IToolbarComponents[] } = {
  searchOnlyLeft: ['search'],
  searchOnlyRight: [<Box key="3" flex={1} />, 'search'],
  readOnlyLeft: ['columns', 'filter', 'density', <Box key="3" flex={1} />, 'search'],
  readOnlyRight: ['search', <Box key="3" flex={1} />, 'columns', 'filter', 'density'],
};

export function toolbarProps(components: IToolbarComponents[] = commonToolbars.readOnlyLeft): Partial<DataGridProps> {
  return {
    components: {
      ...(components && { Toolbar: ToolbarFactory(components) }),
    },
  };
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

export type LeftSidebarState = Readonly<typeof initialState>;

export const LeftSidebarSlice = createSlice({
  name: 'leftSidebar',
  initialState: initialState as LeftSidebarState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = LeftSidebarSlice.actions;

export default LeftSidebarSlice.reducer;

import React from 'react';
import UsersByCountry from './components/UsersByCountry';
import { useUsersMap } from './hooks/useUsersMap';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate, translate } from 'react-jhipster';
import { Typography } from '@mui/material';

export const UsersMapCard = () => {
  const { usersMapData, isFetching, isError, error } = useUsersMap();

  return (
    <LayoutItem
      cardOptions={{
        header: {
          title: (
            <Typography variant="h4">
              {`${translate('user-exploration.users-by', 'Users By')} ${translate('user-exploration.country', 'Country')}`}
            </Typography>
          ),
          disableTypography: true,
        },
        content: { sx: { height: '300px', py: 0, '&:last-child': { py: 0 } } },
      }}
      gridItemProps={{ xs: 12, lg: 4, display: 'grid' }}
    >
      <UsersByCountry users_by_country={usersMapData} isFetching={isFetching} isError={isError} error={error} />
    </LayoutItem>
  );
};

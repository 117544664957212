import { emptyAirbyteApi } from './airbyte-api/emptyAirbyteApi';
import { emptyConfigManagerCloudServicesApi } from './config-manager-cloud-services/emptyConfigManagerCloudServicesApi';
import { emptyDataHubCloudServicesApi } from './data-hub-cloud-services/emptyDataHubCloudServicesApi';
import { emptyGreensightsDataHubCloudServicesApi } from './greensights-data-hub-cloud-services/emptyGreensightsDataHubCloudServicesApi';
import { emptyRecoEngineCloudServicesApi } from './reco-engine/emptyRecoEngineCloudServicesApi';

export const rtkReducers = {
  [emptyDataHubCloudServicesApi.reducerPath]: emptyDataHubCloudServicesApi.reducer,
  [emptyConfigManagerCloudServicesApi.reducerPath]: emptyConfigManagerCloudServicesApi.reducer,
  [emptyGreensightsDataHubCloudServicesApi.reducerPath]: emptyGreensightsDataHubCloudServicesApi.reducer,
  [emptyRecoEngineCloudServicesApi.reducerPath]: emptyRecoEngineCloudServicesApi.reducer,
  [emptyAirbyteApi.reducerPath]: emptyAirbyteApi.reducer,
};

export const rtkMiddlewares = [
  emptyDataHubCloudServicesApi.middleware,
  emptyConfigManagerCloudServicesApi.middleware,
  emptyGreensightsDataHubCloudServicesApi.middleware,
  emptyRecoEngineCloudServicesApi.middleware,
  emptyAirbyteApi.middleware,
];

import React from 'react';

import { Typography } from '@mui/material';
import { RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { getSplit } from 'app/shared/util/split';
import { getDonutList } from './donutList';
import { usePieChartsData } from './hooks/usePieChartsData';
import { translate } from 'react-jhipster';
import PieChart from './charts/PieChart';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';

export const PieCharts = () => {
  const { data, splitBy } = usePieChartsData();
  return (
    <RawGridLayout spacing={0}>
      {getDonutList(data, getSplit(splitBy)).map(
        (donutChart: {
          title: string;
          data: {
            split: {
              key: string;
              count: number;
            }[];
            total: number;
          };
        }) => {
          return (
            <LayoutItem gridItemProps={{ xs: 6, lg: 4, sm: 4 }} key={donutChart.title}>
              <BasicCardLayout
                variant="div"
                card={{
                  sx: { overflow: 'visible' },
                }}
                header={{
                  title: (
                    <Typography
                      variant="h5"
                      sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      component="span"
                      title={`${translate(`user-exploration.${splitBy}`)} ${donutChart.title}`}
                      p={0}
                    >
                      {`${translate(`user-exploration.${splitBy}`)} ${donutChart.title}`}
                    </Typography>
                  ),
                  sx: {
                    '.MuiCardHeader-content': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 1 },
                    p: 0,
                    textAlign: 'center',
                  },
                }}
                content={{ sx: { height: '150px', py: 0, '&:last-child': { py: 0 } } }}
              >
                <PieChart data={data} dataset={donutChart.data} />
              </BasicCardLayout>
            </LayoutItem>
          );
        }
      )}
    </RawGridLayout>
  );
};

import React from 'react';
import { LayoutItem, LayoutItemProps } from '../../components/LayoutItem';

export function toLayoutItemProps(child: React.ReactNode, index: number): LayoutItemProps {
  let childProps: LayoutItemProps = { children: child, key: index };

  if (React.isValidElement(child)) {
    if (child.type === LayoutItem) {
      childProps = {
        key: child.key ?? index,
        ...child.props,
      };
    } else if (typeof child.type === 'function') {
      const comp = child.type as (props?) => any;
      const item = comp(child.props);
      return toLayoutItemProps(item, index);
    } else if (typeof child.type === 'string') {
      return toLayoutItemProps(child.props.children, index);
    }
  }

  return childProps;
}

import React, { useEffect, useState } from 'react';

// material-ui
import { CardContent, ClickAwayListener, Paper, Popper } from '@mui/material';

// assets
import Transitions from 'app/components/transition';

import { isRTL } from 'app/config/translation';
import { setLocale } from 'app/shared/reducers/locale';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Box } from '@mui/system';
import { ProfileTitle } from './ProfileTitle';
import { ProfileMainMenu } from './ProfileMainMenu';
import { ProfileLanguageMenu } from './ProfileLanguageMenu';
import axios from 'axios';
import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { AccountStatus } from 'app/shared/reducers/account-profiles/account-profile';

export const ProfilePopper = ({ theme, anchorRef, userProfile, userRoles, handleClose, open }) => {
  const { accountProfile } = useAppSelector(state => state.accountProfile.accountProfileReducer);
  const bootstrap = accountProfile.status === AccountStatus.BOOTSTRAP;
  const initHeight = bootstrap ? 165 : 230;

  const [activeMenu, setActiveMenu] = useState('main');
  const [listHeight, setListHeight] = useState(initHeight);
  const [listWidth, setListWidth] = useState(215);
  const containerRef = React.useRef(null);

  const OnMenuEnter = el => {
    const calHeight = el.offsetHeight;
    const calWidth = el.offsetWidth;
    setListHeight(calHeight);
    setListWidth(calWidth);
  };
  const dispatch = useAppDispatch();

  const handleLocaleChange = (event, langKey) => {
    axios.put(`${CONFIG_MANAGER_API_URL}/user-profiles/current/locale/${langKey}`).then(
      () => {
        dispatch(setLocale(langKey));
        document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
        handleClose(event);
        setActiveMenu('main');
      },
      () => {
        handleClose(event);
        setActiveMenu('main');
      }
    );
  };

  useEffect(() => {
    if (!open) setActiveMenu('main');
    setListHeight(initHeight);
    setListWidth(215);
  }, [open]);

  return (
    <Popper
      placement="bottom-end"
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 14],
            },
          },
        ],
      }}
    >
      {({ TransitionProps }) => (
        <Transitions in={open} {...TransitionProps} position="top-right">
          <Paper sx={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.25)' }}>
            <ClickAwayListener onClickAway={handleClose}>
              <CardContent sx={{ padding: '16px !important', overflow: 'hidden' }} ref={containerRef}>
                <ProfileTitle theme={theme} userProfile={userProfile} userRoles={userRoles} width={listWidth} />
                <Box sx={{ height: listHeight, width: listWidth, transition: 'all 500ms ease' }}>
                  <ProfileMainMenu
                    handleClose={handleClose}
                    setActiveMenu={setActiveMenu}
                    activeMenu={activeMenu}
                    containerRef={containerRef}
                    OnMenuEnter={OnMenuEnter}
                    openPopper={open}
                  />
                  <ProfileLanguageMenu
                    handleLocaleChange={handleLocaleChange}
                    setActiveMenu={setActiveMenu}
                    activeMenu={activeMenu}
                    containerRef={containerRef}
                    OnMenuEnter={OnMenuEnter}
                  />
                </Box>
              </CardContent>
            </ClickAwayListener>
          </Paper>
        </Transitions>
      )}
    </Popper>
  );
};

import React, { useState } from 'react';

import { Login, Logout, OpenInBrowser } from '@mui/icons-material';
import { AppBar, Button, Container, Toolbar, useScrollTrigger, useTheme } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { BetaRegisterButton } from 'app/modules/views/public-home/pages/landing-page/sections/components/BetaRegisterButton';
import { getAlphaColor } from 'app/shared/util/color-palette-calculator';
import { sitemap } from 'app/shared/util/url-utils';
import { Translate, translate } from 'react-jhipster';
import { LanguageMenu } from '../components/header/language-menu';
import { useAppbarRef } from '../hooks/useAppbarRef';
import { BetaProgramBar } from '../components/landing/BetaProgramBar';
import { HiddenMenuButton, MenuButton } from '../components/landing/MenuButton';
import { ScoringJoe } from '../components/landing/ScoringJoe';
import { LandingMenu } from '../components/landing/LandingMenu';

export const StyleOnScroll = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

export const LandingAppbar = () => {
  const appBarRef = useAppbarRef();
  const theme = useTheme();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <StyleOnScroll>
        <AppBar
          position="fixed"
          ref={appBarRef}
          sx={{
            backgroundColor: getAlphaColor(theme.palette.background.paper, 0.7),
            color: theme.palette.text.primary,
            backdropFilter: 'blur(5px)',
          }}
          elevation={0}
        >
          <Container maxWidth="xl" disableGutters>
            <Toolbar
              sx={{
                [theme.breakpoints.up('md')]: {
                  justifyContent: 'space-between',
                },
              }}
            >
              <HiddenMenuButton />

              {/* ScoringJoe name as Logo */}
              <RawStackLayout
                sx={{
                  [theme.breakpoints.down('md')]: {
                    mx: 'auto',
                  },
                }}
                direction="row"
                spacing={0}
                flexWrap="nowrap"
              >
                <ScoringJoe />
              </RawStackLayout>

              {/* Beta program launch */}
              <RawStackLayout display={{ xs: 'none', md: 'flex' }} direction="row" spacing={0} flexWrap="nowrap">
                <BetaRegisterButton variant="text">
                  <Translate contentKey="landing.actions.register-beta-program">Apply to our Beta Program now!</Translate>
                </BetaRegisterButton>
              </RawStackLayout>

              <RawStackLayout display={{ xs: 'none', md: 'flex' }} direction="row" spacing={1} justifyContent="flex-end">
                {/* Workspace button */}
                {isAuthenticated && (
                  <Button color="neutral" href={sitemap.home} startIcon={<OpenInBrowser />}>
                    <Translate contentKey="root.menu.workspace">Workspace</Translate>
                  </Button>
                )}

                {/* Login OR Logout button */}
                <Button
                  color="neutral"
                  href={isAuthenticated ? sitemap.logout : sitemap.home}
                  startIcon={isAuthenticated ? <Logout /> : <Login />}
                >
                  <>{isAuthenticated ? translate('root.menu.logout', null, 'Logout') : translate('root.menu.login', null, 'Login')}</>
                </Button>

                {/* Language selector */}
                <LanguageMenu />
              </RawStackLayout>
              <MenuButton
                handleMenu={() => {
                  setOpen(prev => !prev);
                }}
              />
            </Toolbar>
          </Container>
        </AppBar>
      </StyleOnScroll>

      <BetaProgramBar appBarRef={appBarRef} />

      <LandingMenu appBarRef={appBarRef} open={open} setOpen={setOpen} />
    </>
  );
};

import { BehaviorSubject } from 'rxjs';
import { trendChartList } from 'app/modules/views/greensights/component/trendChart/list';
import { useEffect, useState } from 'react';

const selectedChart$ = new BehaviorSubject({
  chart: trendChartList[0],
  data: [],
});

const setSelectedChart = (chart: any) => {
  selectedChart$.next(chart);
};

export const useSelectedChart = () => {
  const [selectedChart, updateSelectedChart] = useState(selectedChart$.value);
  useEffect(() => {
    const subscription = selectedChart$.subscribe(value => updateSelectedChart(value));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { selectedChart, setSelectedChart };
};

const selectedData$ = new BehaviorSubject([]);

const setSelectedData = (data: Array<any>) => {
  selectedData$.next(data);
};

export const useSelectedData = () => {
  const [selectedData, updateSelectedData] = useState(selectedData$.value);
  useEffect(() => {
    const subscription = selectedData$.subscribe(value => updateSelectedData(value));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { selectedData, setSelectedData };
};

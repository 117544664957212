import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openAll: false,
};

export type VisitorProfileState = Readonly<typeof initialState>;

export const visitorProfileSlice = createSlice({
  name: 'visitorProfile',
  initialState: initialState as VisitorProfileState,
  reducers: {
    setOpenAll(state, action) {
      state.openAll = action.payload;
    },
  },
});

export const getVisitorProfileOpenAll = state => state.views.visitorProfile.openAll;

export const { setOpenAll } = visitorProfileSlice.actions;

export default visitorProfileSlice.reducer;

import React, { useCallback } from 'react';

import { IconButton } from '@mui/material';
import { ControlledPopover, ControlledPopoverProps, SetAnchorType } from './ControlledPopover';

interface IconButtonPopoverProps extends Omit<ControlledPopoverProps, 'action'> {
  icon: JSX.Element;
}

export const IconButtonPopover = ({ icon, ...rest }: IconButtonPopoverProps) => {
  const iconButton = useCallback((onOpen: SetAnchorType) => <IconButton onClick={el => onOpen(el)}>{icon}</IconButton>, [icon]);

  return <ControlledPopover action={iconButton} {...rest} />;
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { RECO_ENGINE_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IProfile } from 'app/shared/model/recommendations/profile.model';

const addActionsUrl = RECO_ENGINE_API_URL + '/users';

export const initialState = {
  addActionsLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  profile: null as IProfile,
};

export type RecoAddActionsState = Readonly<typeof initialState>;

// Actions

export const addActions = createAsyncThunk(
  'reco/addActions',
  async ({
    accountId,
    profileId,
    type,
    itemId,
    timestamp,
    corpus,
  }: {
    accountId: any;
    profileId: any;
    type: any;
    itemId: any;
    timestamp: any;
    corpus: any;
  }) =>
    microserviceHttpClient(
      `${addActionsUrl}/actions`,
      'post',
      JSON.stringify([
        {
          profile_id: profileId,
          type,
          item_id: itemId,
          timestamp,
          corpus,
          account_id: accountId,
        },
      ])
    ),
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const recoAddActionsSlice = createSlice({
  name: 'addActions',
  initialState: initialState as RecoAddActionsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addActions.pending, state => {
        state.addActionsLoading = true;
        state.errorMessage = null;
      })
      .addCase(addActions.fulfilled, (state, action) => {
        state.addActionsLoading = false;
        state.profile = action.payload.data as IProfile;
      })
      .addCase(addActions.rejected, (state, action) => {
        state.addActionsLoading = false;
        state.errorMessage = action.error.message;
        state.profile = null as IProfile;
      });
  },
});

// Reducer
export default recoAddActionsSlice.reducer;

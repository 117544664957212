import React, { useState, useEffect } from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Button, Typography, Link, IconButton } from '@mui/material';
import { Translate } from 'react-jhipster';
import { East, FileDownload, Tag, VisibilityOff } from '@mui/icons-material';
import Mustache from 'mustache';
import { LoadingButton } from '@mui/lab';
import { RouterLink } from 'app/config/modules/react-router-dom';
import { sitemap } from 'app/shared/util/url-utils';
import { useJsScriptUrlUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/divolte';
import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';

export const TagManager = () => {
  const { subscribedProducts } = useSubscriptionsStates();
  const id = subscribedProducts.filter(sub => sub.productName === 'web-analytics')[0]?.bundleId;
  const { data = {}, isLoading } = useJsScriptUrlUsingGetQuery({ bundleId: id });
  const [innerText, setInnerText] = useState(null);

  function renderText() {
    fetch('content/mustache/GTM-demo-container.mst')
      .then(response => response.text())
      .then(template => {
        setInnerText(Mustache.render(template, { 'divolte-browser-url': data.endpoint }));
      });
  }

  useEffect(() => {
    if (data.endpoint) renderText();
  }, [data]);

  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.tag-manager-quick-setup">Tag Manager Quick Setup</Translate>
            </Typography>
          ),
          avatar: <Tag color="primary" />,
          disableTypography: true,
          ...(innerText && {
            action: (
              <LoadingButton
                onClick={e => {
                  e.stopPropagation();
                }}
                loading={isLoading}
                variant="outlined"
                size="small"
                color="primary"
                LinkComponent={Link}
                href={`data:text/json;chatset=utf-8,${encodeURIComponent(innerText)}`}
                download="tag-manager.json"
                startIcon={<FileDownload />}
              >
                <Translate contentKey="web-analytics.export.download-tag-manager">Download Tag Manager</Translate>
              </LoadingButton>
            ),
          }),
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
            transition: 'all 500ms linear',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        actions: (
          <Button size="small" variant="outlined" component={RouterLink} to={`${sitemap['web-analytics'].tagManager}`} endIcon={<East />}>
            <Translate contentKey="web-analytics.export.tag-manager-page">Tag manager page</Translate>
          </Button>
        ),
      }}
    >
      <Typography variant="subtitle1">
        <Translate contentKey="module-factory.accordion.tag-manager-quick-setup-text">
          Setup your tag manager for better analytics experience.
        </Translate>
      </Typography>
    </LayoutItem>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IPaymentMethods } from 'app/shared/model/payment-methods.model';
// import { IInvoices } from 'app/shared/model/invoices.model';

const paymentMethodsUrl = CONFIG_MANAGER_API_URL + '/billing/accounts';

export const initialState = {
  paymentMethodLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  paymentMethods: [] as IPaymentMethods[],
};

export type PaymentMethodState = Readonly<typeof initialState>;

// Actions

export const getPaymentMethods = createAsyncThunk(
  'paymentMethods/getPaymentMethods',
  async ({ accountId }: { accountId: any }) =>
    microserviceHttpClient(`${paymentMethodsUrl}/${accountId}/paymentMethods?withPluginInfo=true`),
  { serializeError: serializeAxiosError }
);

export const setDefaultPaymentMethod = createAsyncThunk(
  'paymentMethods/setDefaultPaymentMethod',
  async ({ accountId, paymentMethodId }: { accountId: any; paymentMethodId: string }, thunkAPI) =>
    microserviceHttpClient(`${paymentMethodsUrl}/${accountId}/paymentMethods/${paymentMethodId}/setDefault`, 'put').then(resp => {
      thunkAPI.dispatch(getPaymentMethods({ accountId }));
      return resp;
    }),
  { serializeError: serializeAxiosError }
);

// Slice

export const PaymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState: initialState as PaymentMethodState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPaymentMethods.pending, state => {
        state.paymentMethodLoading = true;
        state.errorMessage = null;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethodLoading = false;
        state.paymentMethods = action.payload.data;
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.paymentMethodLoading = false;
        state.errorMessage = action.error.message;
        state.paymentMethods = [] as any;
      })
      .addCase(setDefaultPaymentMethod.pending, state => {
        state.paymentMethodLoading = true;
        state.errorMessage = null;
      })
      .addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
        state.paymentMethodLoading = false;
      })
      .addCase(setDefaultPaymentMethod.rejected, (state, action) => {
        state.paymentMethodLoading = false;
        state.errorMessage = action.error.message;
      });
  },
});

// Reducer
export default PaymentMethodsSlice.reducer;

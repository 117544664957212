import React from 'react';

import { StackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { Divider } from '@mui/material';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate } from 'react-jhipster';
import { Account, User } from '../../../components/accordion/account-management';

export function ManagementView() {
  return (
    <StackLayout
      cardOptions={{
        variant: 'div',
        header: { sx: { padding: 2 } },
      }}
      divider={<Divider />}
    >
      <LayoutItem cardOptions={{ header: { title: <Translate contentKey="module-factory.account">Account</Translate> } }}>
        <Account />
      </LayoutItem>
      <LayoutItem cardOptions={{ header: { title: <Translate contentKey="module-factory.user">User</Translate> } }}>
        <User />
      </LayoutItem>
    </StackLayout>
  );
}

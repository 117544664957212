import { Theme } from '@mui/material';
import ThemeBuilder from 'app/shared/themes/builders/themeBuilder';

const palette = {
  mode: 'light',
  primary: {
    main: '#2196f3',
    light: '#e3f2fd',
    dark: '#1e88e5',
  },
  secondary: {
    main: '#673ab7',
    light: '#ede7f6',
    dark: '#5e35b1',
  },
  background: {
    paper: '#fff',
    default: '#e3f2fd',
  },
};

const mixins = {
  toolbar: {
    minHeight: '48px',
    padding: '16px',
    '@media (min-width: 600px)': {
      minHeight: '48px',
    },
  },
};

class BerryLight extends ThemeBuilder {
  palette() {
    return {
      ...palette,
      mode: this.customization?.palette?.mode,
    };
  }

  mixins() {
    return mixins;
  }

  typography() {
    const weight = this.customization.weight || 0;

    return {
      fontFamily: this.customization?.fontFamily,
      h6: {
        fontWeight: 500 + weight * 100,
        // color: theme?.heading,
        fontSize: '0.75rem',
      },
      h5: {
        fontSize: '0.875rem',
        // color: theme?.heading,
        fontWeight: 500 + weight * 100,
      },
      h4: {
        fontSize: '1rem',
        // color: theme?.heading,
        fontWeight: 600 + weight * 100,
      },
      h3: {
        fontSize: '1.25rem',
        // color: theme?.heading,
        fontWeight: 600 + weight * 100,
      },
      h2: {
        fontSize: '1.5rem',
        // color: theme?.heading,
        fontWeight: 700 + weight * 100,
      },
      h1: {
        fontSize: '2.125rem',
        // color: theme?.heading,
        fontWeight: 700 + weight * 100,
      },
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 500 + weight * 100,
        // color: theme?.textDark,
      },
      subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 400 + weight * 100,
        // color: theme?.darkTextSecondary,
      },
      caption: {
        fontSize: '0.75rem',
        // color: theme?.darkTextSecondary,
        fontWeight: 400 + weight * 100,
      },
      body1: {
        fontSize: '0.875rem',
        fontWeight: 400 + weight * 100,
        lineHeight: '1.334em',
      },
      body2: {
        letterSpacing: '0em',
        fontWeight: 400 + weight * 100,
        lineHeight: '1.5em',
        // color: theme?.darkTextPrimary,
      },
      button: {
        textTransform: 'capitalize',
      },
      customInput: {
        marginTop: 1,
        marginBottom: 1,
        '& > label': {
          top: 23,
          left: 0,
          // color: theme?.grey500,
          '&[data-shrink="false"]': {
            top: 5,
          },
        },
        '& > div > input': {
          padding: '30.5px 14px 11.5px !important',
        },
        '& legend': {
          display: 'none',
        },
        '& fieldset': {
          top: 0,
        },
      },
      mainContent: {
        // backgroundColor: theme?.background,
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginTop: '88px',
        marginRight: '20px',
        borderRadius: `${this.customization?.borderRadius}px`,
      },
      menuCaption: {
        fontSize: '0.875rem',
        fontWeight: 500 + weight * 100,
        // color: theme?.heading,
        padding: '6px',
        textTransform: 'capitalize',
        marginTop: '10px',
      },
      subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 500 + weight * 100,
        // color: theme?.darkTextSecondary,
        textTransform: 'capitalize',
      },
      commonAvatar: {
        cursor: 'pointer',
        borderRadius: '8px',
      },
      smallAvatar: {
        width: '22px',
        height: '22px',
        fontSize: '1rem',
      },
      mediumAvatar: {
        width: '34px',
        height: '34px',
        fontSize: '1.2rem',
      },
      largeAvatar: {
        width: '44px',
        height: '44px',
        fontSize: '1.5rem',
      },
    };
  }

  components(theme: Theme) {
    return {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            borderRadius: '4px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: `${this.customization?.borderRadius}px`,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            // color: theme?.textDark,
            padding: '24px',
          },
          action: {
            alignSelf: 'center',
            paddingRight: '8px',
          },
          title: {
            fontSize: '1.125rem',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '24px',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          footerContainer: {
            '&.MuiTablePagination-root': {
              '&.MuiToolBar-root': {
                padding: '0px',
              },
            },
          },
        },
      },
      MuiToolBar: {
        styleOverrides: {
          root: {
            padding: '0px',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            // color: theme?.darkTextPrimary,
            paddingTop: '10px',
            paddingBottom: '10px',
            // '&.Mui-selected': {
            //   color: theme?.menuSelected,
            //   backgroundColor: theme?.menuSelectedBack,
            //   '&:hover': {
            //     backgroundColor: theme?.menuSelectedBack,
            //   },
            //   '& .MuiListItemIcon-root': {
            //     color: theme?.menuSelected,
            //   },
            // },
            // '&:hover': {
            //   backgroundColor: theme?.menuSelectedBack,
            //   color: theme?.menuSelected,
            //   '& .MuiListItemIcon-root': {
            //     color: theme?.menuSelected,
            //   },
            // },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingLeft: '0px !important',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            // color: theme?.darkTextPrimary,
            minWidth: '30px',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          inset: {
            paddingLeft: '30px',
          },
          primary: {
            // color: theme?.textDark,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              // color: theme?.colors?.grey300,
            },
          },
          mark: {
            // backgroundColor: theme?.paper,
            width: '4px',
          },
          valueLabel: {
            // color: theme?.colors?.primaryLight,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            // borderColor: theme?.divider,
            opacity: 1,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          // root: {
          //   color: theme?.colors?.primaryDark,
          //   background: theme?.colors?.primary200,
          // },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '&.MuiChip-deletable .MuiChip-deleteIcon': {
              color: 'inherit',
            },
          },
        },
      },
      // MuiTooltip: {
      //   styleOverrides: {
      //     tooltip: {
      //       color: theme?.paper,
      //       background: theme?.colors?.grey700,
      //     },
      //   },
      // },
      /* MuiTextField: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-notchedOutline': {
              legend: {
                float: 'unset !important',
              },
            },
          },
        },
      }, */
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            legend: {
              float: 'unset !important',
            },
          },
        },
      },
      /* MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            padding: '8px 0 !important',
          },
          popper: {
            boxShadow: '0 0 40px rgba(0, 0, 0, 0.25)',
          },
        },
      }, */
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-root': {
              borderRadius: '24px',
              background: 'rgb(250, 250, 250) none repeat scroll 0% 0%',
            },
          },
          input: {
            // color: theme?.textDark,
            fontWeight: 500,
            '&::placeholder': {
              // color: theme?.darkTextSecondary,
              fontSize: '0.875rem',
            },
          },
        },
      },
    };
  }

  override updateTheme(theme: Theme) {
    theme.components = this.components(theme);
    return theme;
  }
}

export default BerryLight;

import React, { useEffect } from 'react';

import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Typography } from '@mui/material';
import { translate } from 'react-jhipster';
import DonutChart from './chart/DonutChart';
import { getSplit } from 'app/shared/util/split';
import { getDonutList } from './functions/donutList';
import { useGetTimeframeUserScoresUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-scores';
import { useAppSelector } from 'app/config/store';
import {
  getUserExplorationSplitBy,
  getUserExplorationSplitLimit,
  getUserExplorationTimerange,
  selectAllUserExplorationFilters,
} from 'app/shared/reducers/views/user-exploration';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import BucketingToggler from './components/BucketingToggler';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const DonutsSplitCard = () => {
  const splitLimit = useAppSelector(getUserExplorationSplitLimit);
  const splitBy = useAppSelector(getUserExplorationSplitBy);
  const filter = useAppSelector(selectAllUserExplorationFilters);
  const timerange = useAppSelector(getUserExplorationTimerange);

  const {
    currentData: usersList = null,
    isFetching,
    isUninitialized: isTimeframeUserScoresUninitialized,
    isError,
    error,
    refetch,
  } = useGetTimeframeUserScoresUsingPostQuery(
    timerange
      ? {
          from: timerange?.fromDate,
          to: timerange?.toDate,
          // limit: splitLimit.toString(),
          scoreBodyDto: { filters: { filterEntryList: filter } },
        }
      : skipToken
  );

  useEffect(() => {
    if (!isTimeframeUserScoresUninitialized) refetch();
  }, [timerange?.refresh]);

  return (
    <BasicCardLayout
      variant="div"
      header={{ title: <BucketingToggler />, sx: { textAlign: 'center' } }}
      content={{ sx: { p: 0 } }}
      card={{ sx: { overflow: 'visible' } }}
    >
      <GridLayout>
        {getDonutList(usersList, getSplit(splitBy)).map(
          (donutChart: {
            title: string;
            data: {
              split: {
                key: string;
                count: number;
              }[];
              total: number;
            };
          }) => {
            return (
              <LayoutItem
                cardOptions={{
                  card: {
                    sx: { overflow: 'visible' },
                  },
                  header: {
                    title: (
                      <Typography
                        variant="h4"
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        component="span"
                        title={`${translate(`user-exploration.${splitBy}`)} ${donutChart.title}`}
                        p={0}
                      >
                        {`${translate(`user-exploration.${splitBy}`)} ${donutChart.title}`}
                      </Typography>
                    ),
                    disableTypography: true,
                    sx: {
                      '.MuiCardHeader-content': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 1 },
                      p: 0,
                    },
                  },
                  content: { sx: { height: '165px', py: 0, '&:last-child': { py: 0 } } },
                }}
                gridItemProps={{ xs: 6, lg: 2.4, sm: 4 }}
                key={donutChart.title}
              >
                <DonutChart dataset={donutChart.data} isFetching={isFetching} isError={isError} error={error} />
              </LayoutItem>
            );
          }
        )}
      </GridLayout>
    </BasicCardLayout>
  );
};

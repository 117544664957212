import { Dashboard } from 'tabler-icons-react';

const icons = { Dashboard };

const webAnalytics = [
  {
    id: 'overview',
    title: 'overview',
    type: 'item',
    url: '/web-analytics/overview',
  },
  {
    id: 'explore',
    title: 'explore',
    type: 'collapse',
    icon: icons.Dashboard,
    children: [
      {
        id: 'audience',
        title: 'audience',
        type: 'item',
        url: '/web-analytics/explore/audience',
      },
      {
        id: 'sessions',
        title: 'sessions',
        type: 'item',
        url: '/web-analytics/explore/sessions',
      },
    ],
  },
];

export default webAnalytics;

import React, { useContext } from 'react';

import { Typography } from '@mui/material';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';
import { getUserExplorationSelectedCellDates, getUserExplorationSelectedCohortCell } from 'app/shared/reducers/views/user-exploration';

export const UsersCohortHeader = () => {
  const selectedCohortCell = useAppSelector(getUserExplorationSelectedCohortCell);
  const selectedCellDates = useAppSelector(getUserExplorationSelectedCellDates);

  const usersOverviewlistTitle = `${translate('user-exploration.users')} ${
    selectedCellDates.length === 0
      ? ''
      : selectedCellDates[0] === selectedCellDates[1]
      ? `${translate('user-exploration.users-table.first-visit')} ${selectedCellDates[0]}`
      : `${translate('user-exploration.users-table.first-visit')} ${selectedCellDates[0]} ${translate(
          'user-exploration.users-table.return'
        )} ${selectedCellDates[1]}`
  }`;

  if (selectedCohortCell.length > 0) return <Typography variant="h4">{usersOverviewlistTitle}</Typography>;
  else
    return (
      <Typography variant="h4">
        <Translate contentKey="user-exploration.cohort.users-cohort">Users Cohort</Translate>
      </Typography>
    );
};

import React from 'react';

import { Translate } from 'react-jhipster';
import { Typography, Alert, Collapse, AlertTitle } from '@mui/material';
import { clearError } from 'app/shared/reducers/account-profiles/access-token';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const NewAccessTokenError = () => {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector(state => state.accountProfile.accessToken);

  return (
    <Collapse in={error !== null}>
      <Alert
        variant="outlined"
        severity="error"
        sx={{ '.MuiAlert-message': { width: '100%' } }}
        onClose={() => {
          dispatch(clearError());
        }}
      >
        <AlertTitle>
          <Typography variant="h4">
            <Translate contentKey="account.access-token.new-token-error">An error occurred</Translate>
          </Typography>
        </AlertTitle>
        {error && <Typography>Status: {error.status}</Typography>}
        {error && <Typography>Error message: {error.data.detail}</Typography>}
      </Alert>
    </Collapse>
  );
};

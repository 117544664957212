import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SvgIcon from '@mui/material/SvgIcon';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { IPaymentMethods } from 'app/shared/model/payment-methods.model';
import React from 'react';
import { Translate } from 'react-jhipster';

export const bankAccountPaymentMethodMap = (paymentMethod: IPaymentMethods, setDefault: () => void) => {
  function getProperty(prop) {
    return paymentMethod.pluginInfo.properties.find(item => item.key === prop).value;
  }

  return {
    id: paymentMethod.paymentMethodId,
    paymentMainInfos: {
      icon: (
        <SvgIcon color="neutral" sx={{ width: 32, height: 32 }}>
          <AccountBalanceIcon />
        </SvgIcon>
      ),
      mainInfos: [
        getProperty('bank_name'),
        `•••• ${getProperty('last4')}`,
        // String(getProperty('currency')).toUpperCase(),
      ],
      chipMainInfos: [
        { size: 'small', color: 'neutral', variant: 'outlined', label: String(getProperty('currency')).toUpperCase() },
        // paymentMethod.isDefault ? { color: "primary", size: "small", label: translate('payment-method.default', null, 'Default') } : undefined,
      ],
      isDefault: paymentMethod.isDefault,
      setDefault,
    },
    paymentMethodInfos: [
      {
        title: 'Payment method ID',
        i18n: 'payment-method.payment-method-id',
        value: paymentMethod.paymentMethodId,
      },
      {
        title: 'Country',
        i18n: 'payment-method.country',
        value: getProperty('country'),
        renderPaymentInfo(info: { title: string; i18n: string; value: any }, index: number) {
          return [
            <Translate key={index} contentKey={info.i18n}>
              {info.title}
            </Translate>,
            <FlagWithCountryName
              key={index}
              country={info.value}
              sxContainer={{ float: 'right' }}
              typographyProps={{ color: 'textSecondary' }}
            />,
          ];
        },
      },
      {
        title: 'Type',
        i18n: 'payment-method.type',
        value: String(getProperty('object')).toUpperCase(),
      },
      {
        title: 'Bank name',
        i18n: 'payment-method.bank-name',
        value: getProperty('bank_name'),
      },
      // {
      //   title: 'Account holder type',
      //   i18n: 'payment-method.account-holder-type',
      //   value: getProperty('account_holder_type'),
      // },
      // {
      //   title: 'Status',
      //   i18n: 'payment-method.status',
      //   value: getProperty('status'),
      // },
      {
        title: 'Number',
        i18n: 'payment-method.number',
        value: `•••• ${getProperty('last4')}`,
      },
      {
        title: 'Currency',
        i18n: 'payment-method.currency',
        value: String(getProperty('currency')).toUpperCase(),
      },
      // {
      //   title: 'Created',
      //   i18n: 'payment-method.created',
      //   value: dayjs(getProperty('created')).locale(currentLocale).toNow()
      // },
      {
        title: 'Fingerprint',
        i18n: 'payment-method.fingerprint',
        value: getProperty('fingerprint'),
      },
    ],
  };
};

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FieldProp, FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import { fieldPropsBuilder, formAttributes } from 'app/shared/datasets/formAttributes';
import {
  usePutSubscriptionSettingsUsingPutMutation,
  usePostSubscriptionSettingsUsingPostMutation,
} from 'app/shared/reducers/api/config-manager-cloud-services/store/subscription-settings';
import { saveSubscriptionSettings } from 'app/shared/reducers/subscription-settings/settings';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { translate } from 'react-jhipster';

interface SortableFieldProps {
  field: any;
  priority: number;
  fieldProps: FieldProp;
}

function fieldsFactory(settingsCatalog): Array<FieldProp> {
  const mappedFields = Object.values<any>(settingsCatalog)
    .map(f => {
      const titleText = translate('form-attribute.' + f.keyName + '.title', null, f.keyName);
      const tooltipText = translate('form-attribute.' + f.keyName + '.tooltip');

      const isTranslationNotFound = text => {
        return text.startsWith('translation-not-found[') && text.endsWith(']');
      };

      return {
        field: {
          ...f,
        },
        priority: formAttributes[f.keyName]?.priority ?? 0,
        fieldProps: {
          ...fieldPropsBuilder(f.valueType, f.required),
          ...(formAttributes[f.keyName]?.fieldProps ?? {}),
          ...{
            attribute: f.keyName.replaceAll('.', ':'),
            title: titleText,
            titleTooltip: isTranslationNotFound(tooltipText) ? undefined : tooltipText,
            label: f.defaultValue ?? formAttributes[f.keyName]?.fieldProps?.label,
            options: f.options,
          },
        },
      } as SortableFieldProps;
    })
    .sort((a, b) => {
      if (a.priority - b.priority === 0) {
        if (a.fieldProps.attribute < b.fieldProps.attribute) {
          return -1;
        } else {
          return 1;
        }
      }
      return b.priority - a.priority;
    });

  return mappedFields.map(f => f.fieldProps);
}

const replaceKeySeparator = (settings, find, replace) => {
  return Object.entries<any>(settings)
    .map(entry => {
      return {
        [entry[0].split(find).join(replace)]: entry[1],
      };
    })
    .reduce((acc, v) => {
      return { ...acc, ...v };
    }, {});
};

const SettingsForm = ({ settings, settingsCatalogId, settingsCatalog, settingsId }) => {
  const [saveSubscriptionSettingsUsingPut, { isLoading: isSubscriptionSavingUsingPut }] = usePutSubscriptionSettingsUsingPutMutation();
  const [saveSubscriptionSettingsUsingPost, { isLoading: isSubscriptionSavingUsingPost }] = usePostSubscriptionSettingsUsingPostMutation();
  const fields = settingsCatalog ? fieldsFactory(settingsCatalog) : [];
  const initValues = settings ? replaceKeySeparator(settings, '.', ':') : {};

  const schema = validationUtils.getFormSchema(fields);
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {
    const formData = replaceKeySeparator(data, ':', '.');

    if (settingsId) {
      saveSubscriptionSettingsUsingPut({ subscriptionSettingsDtoReq: { settings: { ...formData }, subscriptionId: settingsCatalogId } });
    } else {
      saveSubscriptionSettingsUsingPost({ subscriptionSettingsDtoReq: { settings: { ...formData }, subscriptionId: settingsCatalogId } });
    }

    methods.reset(initValues);
  };

  useEffect(() => {
    methods.reset(initValues);
  }, [settings]);

  const submitButton = (
    <LoadingButton
      loadingPosition="start"
      loading={isSubscriptionSavingUsingPut || isSubscriptionSavingUsingPost}
      disabled={!methods.formState.isDirty}
      variant="contained"
      color="primary"
      sx={{ my: 2 }}
      onClick={() => {
        methods.handleSubmit(onSubmit)();
      }}
    >
      Submit
    </LoadingButton>
  );

  return (
    <FormBuilder
      fields={fields}
      defaultValue={initValues}
      methods={methods}
      optionalMarkerDefault={true}
      requiredMarkerDefault={true}
      containerProps={{ marginY: 0.5, spacing: 2 }}
    >
      {submitButton}
    </FormBuilder>
  );
};

export default SettingsForm;

import React from 'react';

import { GroupedListLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { DetailsItem } from './accordion-items/details/DetailsItem';
import { BillingItem } from './accordion-items/billing/BillingItem';
import { PaymentMethodItem } from './accordion-items/payment-method/PaymentMethodItem';
import { SubscriptionsItem } from './accordion-items/subscriptions/SubscriptionsItem';
import { InvoicesItem } from './accordion-items/invoices/InvoicesItem';
import { MembersItem } from './accordion-items/members/MembersItem';
import { SecurityItem } from './accordion-items/security/SecurityItem';
import { ProfileItem } from './accordion-items/profile/ProfileItem';
import { AccountItem } from './accordion-items/account/AccountItem';

export const Account = () => {
  return (
    <GroupedListLayout>
      <DetailsItem />
      <BillingItem />
      <PaymentMethodItem />
      <SubscriptionsItem />
      <InvoicesItem />
      <MembersItem />
      <SecurityItem />
    </GroupedListLayout>
  );
};

export const User = () => {
  return (
    <GroupedListLayout>
      <ProfileItem />
      <AccountItem />
    </GroupedListLayout>
  );
};

import React from 'react';

import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Translate } from 'react-jhipster';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LineChartsTabs } from './components/line-charts-tabs';
import { LineChartsActions } from './components/line-charts-tabs/actions/LineChartsActions';
import WorldMapChart from './components/map-chart/components/MapChart';
import { MapChartActions } from './components/map-chart/actions/MapChartActions';

export const GreensightsHome = () => {
  return (
    <BasicCardLayout
      variant="div"
      header={{
        title: <Translate contentKey="module-factory.dashboard-overview">Dashboard Overview</Translate>,
      }}
    >
      <GridLayout>
        <LayoutItem
          gridItemProps={{ xs: 12, lg: 8, display: 'grid' }}
          cardOptions={{
            card: { sx: { display: 'flex', flexDirection: 'column' } },
            content: { sx: { height: '200px', display: 'flex', flexDirection: 'column', flexGrow: 1, p: 0, '&:last-child': { p: 0 } } },
            actions: <LineChartsActions />,
            actionsProps: { sx: { py: 0 } },
          }}
        >
          <LineChartsTabs />
        </LayoutItem>
        <LayoutItem
          gridItemProps={{ xs: 8, lg: 4, display: 'grid' }}
          cardOptions={{
            header: { title: <Translate contentKey="module-factory.world-map">World Map</Translate> },
            content: { sx: { height: '200px', p: 0, '&:last-child': { p: 0 } } },
            actions: <MapChartActions />,
            actionsProps: { sx: { py: 0 } },
          }}
        >
          <WorldMapChart />
        </LayoutItem>
      </GridLayout>
    </BasicCardLayout>
  );
};

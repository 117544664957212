import { List, ListItemSecondaryAction, ListItemText, Typography, ListItem } from '@mui/material';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { useAppSelector } from 'app/config/store';
import React from 'react';
import { translate } from 'react-jhipster';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useGetUserInfoQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-exploration';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { PropertiesGrid } from 'app/components/properties/properties-grid';
import { SessionBrowser } from './session-infos-with-icons/SessionBrowser.';
import { SessionDevice } from './session-infos-with-icons/SessionDevice';
import { SessionOs } from './session-infos-with-icons/SessionOs';

const getUserInfos = (user_info, currentLocale) => {
  return [
    {
      text: 'country',
      info: user_info.MAX_COUNTRY_NAME.names ? <FlagWithCountryName country={user_info.MAX_COUNTRY_NAME.names[0]} /> : '-',
    },
    {
      text: 'city',
      info: user_info.MAX_CITY_NAME.names[0] || '-',
    },
    {
      text: 'os',
      info: user_info.MAX_USER_AGENT_OS_FAMILY.names[0] ? <SessionOs os={user_info.MAX_USER_AGENT_OS_FAMILY.names[0]} /> : '-',
    },
    {
      text: 'browser',
      info: user_info.MAX_USER_AGENT_NAME.names[0] ? <SessionBrowser browser={user_info.MAX_USER_AGENT_NAME.names[0]} /> : '-',
    },
    {
      text: 'device',
      info: user_info.MAX_USER_AGENT_DEVICE_CATEGORY.names[0] ? (
        <SessionDevice device={user_info.MAX_USER_AGENT_DEVICE_CATEGORY.names[0]} />
      ) : (
        '-'
      ),
    },
    {
      text: 'First visit',
      info: user_info.MAX_FIRST_USER_VISIT_EPOCH_SECONDS
        ? dayjs(user_info.MAX_FIRST_USER_VISIT_EPOCH_SECONDS.names[0]).locale(currentLocale).format('llll')
        : '-',
    },
    {
      text: 'First visited page',
      info: user_info.MAX_FIRST_VISITED_PAGE.names[0] || '-',
    },
    {
      text: 'Time since last visit',
      info: user_info.timeSinceLastVisit
        ? dayjs
            .duration(user_info.timeSinceLastVisit.value * 1000)
            .locale(currentLocale)
            .format('HH:mm:ss')
        : '-',
    },
    {
      text: 'Referer',
      info: user_info.MAX_REFERER.names[0] || '-',
    },
  ];
};

export const UserInfoList = () => {
  const { userId } = useParams();
  const { data } = useGetUserInfoQuery(
    userId
      ? {
          userId,
          requestFilters: {
            filterEntryList: [],
          },
        }
      : skipToken
  );
  const { currentLocale }: any = useAppSelector(state => state.locale);
  const userInfos = data ? getUserInfos(data.metadata, currentLocale) : [];
  const userInfosProperties = userInfos.map(obj => {
    return [String(obj.text), String(obj.info)];
  });
  // return <PropertiesGrid gridProps={{ spacing: 1 }} keyProps={{ align: 'left' }} properties={userInfosProperties} />;
  return (
    <List dense disablePadding sx={{ width: '100%', mt: '16px !important' }}>
      {userInfos.map((userInfo, indexLink) => {
        return (
          <ListItem disableGutters key={indexLink}>
            <ListItemText primary={translate(`visitor-profile.${userInfo.text}`, null, userInfo.text)} />
            {userInfo.info && (
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" color="textSecondary">
                  {userInfo.info}
                </Typography>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

import { createTheme, ThemeOptions } from '@mui/material/styles';
import BerryLight from './builders/berryLight';
import MuiDefault from './builders/muiDefault';
import ScoringJoe from './builders/scoringJoe';
import ThemeBuilder from './builders/themeBuilder';

function getThemeBuilder(customization) {
  switch (customization.themeName) {
    case 'scoringJoe':
      return new ScoringJoe(customization);
    case 'berryLight':
      return new BerryLight(customization);
    case 'berryDark':
      return new BerryLight({
        palette: { mode: 'dark' },
        ...customization,
      });
    case 'muiDark':
      return new MuiDefault({
        palette: { mode: 'dark' },
        ...customization,
      });
    default:
      // returns MUI Light default theme
      return new MuiDefault(customization);
  }
}

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization, locales: object[]) => {
  const themeBuilder: ThemeBuilder = getThemeBuilder(customization);

  const themeOptions: ThemeOptions = {
    direction: customization.ltr || 'ltr',
    /* shape: {
      borderRadius: '24px',
    }, */
    palette: themeBuilder.palette(),
    mixins: themeBuilder.mixins(),
    typography: themeBuilder.typography(),
  };

  const themes = locales ? createTheme(themeOptions, ...locales) : createTheme(themeOptions);

  return themeBuilder.updateTheme(themes);
};

export default theme;

import React from 'react';

import { Divider } from '@mui/material';
import { StackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { useNavigate } from 'react-router-dom';
import { productsModules } from '../../../config/productModules';

export function ApplicationsView({ subscribedProducts }) {
  const navigate = useNavigate();

  return (
    <StackLayout
      cardOptions={{
        variant: 'div',
        header: { sx: { padding: 2 } },
      }}
      divider={<Divider />}
    >
      {subscribedProducts.map((p, index) => (
        <LayoutItem key={index} cardOptions={productsModules[p].summary?.cardOptions({ p, navigate })}>
          {React.isValidElement(productsModules[p].summary?.content) && productsModules[p].summary?.content}
        </LayoutItem>
      ))}
    </StackLayout>
  );
}

import React from 'react';

import { Edit } from 'tabler-icons-react';
import { translate } from 'react-jhipster';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import dayjs from 'dayjs';
import { CustomListTabWithAction } from './common/CustomListTabWithAction';
import { IDialogsState } from '../../hooks/useAccountPage';
import { AccountDtoRes, accountApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { userProfilesApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '@mui/material';

export const AccountDetailsTab = ({ dialogsState }: { dialogsState: IDialogsState }): JSX.Element => {
  const { data: userProfile } = userProfilesApi.endpoints.getCurrentUserProfileUsingGet.useQueryState({});
  const { data: billingAccount = {} as AccountDtoRes } = accountApi.endpoints.getAccountByKeyUsingGet.useQueryState({
    accountKey: userProfile ? userProfile.currentAccount : null,
  });
  const accountDetailsList = [
    {
      icon: LibraryBooksIcon,
      key: 'account.account-page.account',
      value: billingAccount.name ? billingAccount.name : 'Unnamed account',
    },
    {
      icon: TimelapseIcon,
      key: 'account.account-page.since',
      value: billingAccount.referenceTime ? dayjs(billingAccount.referenceTime).fromNow() : '-',
    },
    {
      icon: CurrencyExchangeIcon,
      key: 'account.account-page.currency',
      value: billingAccount.currency ? billingAccount.currency : '-',
    },
    {
      icon: LocalPhoneIcon,
      key: 'account.account-page.phone',
      value: billingAccount.phone ? billingAccount.phone : '-',
    },
    {
      icon: BusinessIcon,
      key: 'account.account-page.company',
      value: billingAccount.company ? billingAccount.company : '-',
    },
    {
      icon: AccountBalanceIcon,
      key: 'account.account-page.balance',
      value: billingAccount.accountBalance ? billingAccount.accountBalance : '-',
    },
    {
      icon: AccountBalanceWalletIcon,
      key: 'account.account-page.credit',
      value: billingAccount.accountCBA ? billingAccount.accountCBA : '-',
    },
    {
      icon: LanguageIcon,
      key: 'account.account-page.locale',
      value: billingAccount.locale ? translate(`language.${billingAccount.locale}`) : '-',
    },
  ];

  return (
    <CustomListTabWithAction
      list={accountDetailsList}
      actions={
        <>
          <Button
            startIcon={<Edit />}
            sx={{ ml: 2 }}
            variant="outlined"
            size="small"
            onClick={() => {
              dialogsState.setOpenAccountEditForm(true);
            }}
          >
            {translate('account.account-page.edit')}
          </Button>
          <CopyToClipboard text={billingAccount.externalKey} onCopy={() => billingAccount.externalKey}>
            <Button startIcon={<ContentCopy />} sx={{ ml: 2 }} variant="outlined" size="small">
              {'Copy Account ID'}
            </Button>
          </CopyToClipboard>
        </>
      }
      sxContainer={{
        width: '50%',
        marginX: 'auto',
      }}
    />
  );
};

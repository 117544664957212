import React from 'react';

import { Dialog } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { addFlex, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import { sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import { commonToolbars, toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import { PaymentDetails } from './PaymentDetails';
import { InvoiceView } from './InvoiceView';
import { useInvoicesColumns } from '../../../hooks/useInvoicesColumns';
import { useInvoicesDataGrid } from '../../../hooks/useInvoicesDataGrid';

export const InvoicesDataGrid = ({ invoiceList }) => {
  const {
    invoice,
    setInvoice,
    openInvoiceDetailsDialog,
    setOpenInvoiceDetailsDialog,
    payment,
    setPayment,
    openPaymentDetailsDialog,
    setOpenPaymentDetailsDialog,
    handleCloseInvoiceDetails,
    handleClosePaymentDetails,
  } = useInvoicesDataGrid(invoiceList);

  const columns = useInvoicesColumns(setInvoice, setOpenInvoiceDetailsDialog, setPayment, setOpenPaymentDetailsDialog);

  return (
    <>
      <DataGrid
        rows={invoiceList || []}
        columns={columns.map(addFlex).map(translateHeaders('account.invoice-table'))}
        {...toolbarProps(commonToolbars.readOnlyLeft)}
        {...sizedPagination()}
        density="compact"
        getRowId={row => row.invoiceId}
        sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'invoiceDate', sort: 'desc' }],
          },
        }}
      />

      <Dialog onClose={handleCloseInvoiceDetails} open={openInvoiceDetailsDialog} fullWidth={true} maxWidth="lg">
        <InvoiceView invoice={invoice} />
      </Dialog>
      {payment && (
        <Dialog onClose={handleClosePaymentDetails} open={openPaymentDetailsDialog} fullWidth={true} maxWidth="lg">
          <PaymentDetails payment={payment} setOpen={setOpenPaymentDetailsDialog} />
        </Dialog>
      )}
    </>
  );
};

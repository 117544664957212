import PaymentIcon from '@mui/icons-material/Payment';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { IPaymentMethods } from 'app/shared/model/payment-methods.model';
import dayjs from 'dayjs';
import { Image } from 'mui-image';
import React from 'react';
import { Translate, translate } from 'react-jhipster';

const iconPaths = {
  Visa: '/content/images/material_credit_cards_free/MATERIAL/credit_cards/png/32/visa.png',
};

function getCardIcon(cardBrand) {
  const imgSrc = iconPaths[cardBrand];
  return imgSrc ? <Image src={imgSrc} width={32} height={32} /> : <PaymentIcon color="primary" />;
}

export const cardPaymentMethodMap = (paymentMethod: IPaymentMethods, setDefault: () => void, currentLocale: string) => {
  function getProperty(prop) {
    return paymentMethod.pluginInfo.properties.find(item => item.key === prop).value;
  }

  const expYear = Number(getProperty('card_exp_year'));
  const expMonth = Number(getProperty('card_exp_month'));
  const expDate = dayjs(new Date(expYear, expMonth)).subtract(dayjs.duration(1, 'second'));
  const isExpired = dayjs().isAfter(expDate);

  return {
    id: paymentMethod.paymentMethodId,
    paymentMainInfos: {
      icon: getCardIcon(getProperty('card_brand')),
      mainInfos: [
        String(getProperty('card_brand')),
        `•••• ${getProperty('card_last4')}`,
        // `${translate('payment-method.expire')} ${getProperty('card_exp_month')}/${getProperty('card_exp_year')}`,
      ],
      chipMainInfos: [
        isExpired
          ? { color: 'error', variant: 'outlined', size: 'small', label: translate('payment-method.expired', null, 'Expired') }
          : { size: 'small', color: 'neutral', variant: 'outlined', label: expDate.locale(currentLocale).format('M/YYYY') },
        // paymentMethod.isDefault ? { color: "primary", size: "small", label: translate('payment-method.default', null, 'Default') } : undefined,
      ],
      isDefault: paymentMethod.isDefault,
      setDefault,
    },
    paymentMethodInfos: [
      {
        title: 'Payment method ID',
        i18n: 'payment-method.payment-method-id',
        value: paymentMethod.paymentMethodId,
      },
      {
        title: 'Country',
        i18n: 'payment-method.country',
        value: getProperty('card_country'),
        renderPaymentInfo(info: { title: string; i18n: string; value: any }, index: number) {
          return [
            <Translate key={index} contentKey={info.i18n}>
              {info.title}
            </Translate>,
            <FlagWithCountryName
              key={index}
              country={info.value}
              sxContainer={{ float: 'right' }}
              typographyProps={{ color: 'textSecondary' }}
            />,
          ];
        },
      },
      {
        title: 'Type',
        i18n: 'payment-method.type',
        value: String(getProperty('card_brand')).toUpperCase(),
      },
      {
        title: 'Card funding',
        i18n: 'payment-method.card-funding',
        value: getProperty('card_funding'),
      },
      {
        title: 'Number',
        i18n: 'payment-method.number',
        value: `•••• ${getProperty('card_last4')}`,
      },
      {
        title: 'Expire',
        i18n: 'payment-method.expire',
        value: expDate.locale(currentLocale).format('MMMM YYYY'),
      },
      {
        title: 'Created',
        i18n: 'payment-method.created',
        value: dayjs(parseInt(getProperty('created'), 10) * 1000)
          .locale(currentLocale)
          .format('lll'),
      },
      {
        title: 'Fingerprint',
        i18n: 'payment-method.fingerprint',
        value: getProperty('card_fingerprint'),
      },
    ],
  };
};

import { combineReducers } from 'redux';
import sessionInfo from './sessionInfo';
import userInfo from './userInfo';

const visitorProfile = combineReducers({
  sessionInfo,
  userInfo,
});

export default visitorProfile;

import { combineReducers } from 'redux';
import subscriptionSettings from './settings';
import settingsCatalog from './settingsCatalog';

const subscriptionConf = combineReducers({
  subscriptionSettings,
  settingsCatalog,
});

export default subscriptionConf;

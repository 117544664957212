export interface IAccountProfile {
  auditInfo: {
    createdBy: string;
    createdDate: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
  };
  accountId: string;
  name: string;
  status: string;
}

export const defaultValue: Readonly<IAccountProfile> = {
  auditInfo: {
    createdBy: '',
    createdDate: '',
    lastModifiedBy: '',
    lastModifiedDate: '',
  },
  accountId: '',
  name: '',
  status: '',
};

import { IUsers } from 'app/shared/model/users.model';
import { translate } from 'react-jhipster';

export const getDonutList = (
  usersList,
  getSplit: (
    data: IUsers[],
    splitKpi: string
  ) => {
    split: {
      key: string;
      count: number;
    }[];
    total: number;
  }
): {
  title: string;
  data: {
    split: {
      key: string;
      count: number;
    }[];
    total: number;
  };
}[] => {
  return [
    {
      title: translate('user-exploration.donut.country'),
      data: usersList ? getSplit(usersList, 'countrySplit') : null,
    },
    {
      title: translate('user-exploration.donut.city'),
      data: usersList ? getSplit(usersList, 'citySplit') : null,
    },
    {
      title: translate('user-exploration.donut.continent'),
      data: usersList ? getSplit(usersList, 'continentSplit') : null,
    },
    {
      title: translate('user-exploration.donut.first-visited-page'),
      data: usersList ? getSplit(usersList, 'firstVisitedPageSplit') : null,
    },
    {
      title: translate('user-exploration.donut.last-visited-page'),
      data: usersList ? getSplit(usersList, 'lastVisitedPageSplit') : null,
    },
    {
      title: translate('user-exploration.donut.referer'),
      data: usersList ? getSplit(usersList, 'refererSplit') : null,
    },
    {
      title: translate('user-exploration.donut.fqdn'),
      data: usersList ? getSplit(usersList, 'fqdnSplit') : null,
    },
    {
      title: translate('user-exploration.donut.device-category'),
      data: usersList ? getSplit(usersList, 'deviceCategorySplit') : null,
    },
    {
      title: translate('user-exploration.donut.browser'),
      data: usersList ? getSplit(usersList, 'browserFamilySplit') : null,
    },
    {
      title: translate('user-exploration.donut.os'),
      data: usersList ? getSplit(usersList, 'osFamilySplit') : null,
    },
  ];
};

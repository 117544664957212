import React from 'react';

import { TablePaginationProps, Box, Alert } from '@mui/material';
import {
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridFilteredTopLevelRowCountSelector,
  useGridRootProps,
  GridPagination,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { translate } from 'react-jhipster';

const getPageCount = (rowCount: number, pageSize: number): number => {
  if (pageSize > 0 && rowCount > 0) {
    return Math.ceil(rowCount / pageSize);
  }

  return 0;
};

function Pagination({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
  const rootProps = useGridRootProps();
  const pageCount = getPageCount(rootProps.rowCount ?? visibleTopLevelRowCount, pageSize);

  return (
    <MuiPagination
      color="primary"
      variant="outlined"
      className={className}
      count={pageCount}
      page={page + 1}
      boundaryCount={2}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

export function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export const NoCorpusFound = () => {
  return (
    <Box display="flex" height="100%">
      <Alert variant="outlined" severity="warning" sx={{ width: '30%', mx: 'auto', my: 'auto' }}>
        {translate('recommendation.corpus.no-corpus-found', null, 'No Corpus Found')}
      </Alert>
    </Box>
  );
};

export const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton key="columns" />
      <GridToolbarFilterButton key="filter" />
      <GridToolbarDensitySelector key="density" />
      <Box key="filler" flex={1} />
    </GridToolbarContainer>
  );
};

import { useAppSelector } from 'app/config/store';
import { useGetKpisPerKpiInTimeRangeUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';

export const useMapChartData = () => {
  const { selectedMapChart, params } = useAppSelector(state => state.webAnalyticsOverview.mapChart);

  const { data = null } = useGetKpisPerKpiInTimeRangeUsingPostQuery(
    {
      from: 'now-15m',
      to: 'now',
      bucketingDimensions: 'COUNTRY_CODE',
      kpis: 'USER_COUNT,SESSION_COUNT,ACTIVE_USER_COUNT',
      requestFilters: {
        filterEntryList: params.filter,
      },
    },
    {
      selectFromResult(res) {
        return {
          data: res.data
            ? res.data.data['COUNTRY_CODE'].map(element => {
                return { countryCode: element.countryCode, buckets: element.buckets };
              })
            : null,
        };
      },
    }
  );

  return {
    data,
    selectedMapChart,
  };
};

import { useAppSelector } from 'app/config/store';
import { useGetKpisHistoryInTimeRangeUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { getKpiFromPayload } from 'app/shared/util/data-hub-utils';
import { KPIS } from 'app/shared/util/reducer-utils';

export const useLineChartsTabsData = () => {
  const { params } = useAppSelector(state => state.webAnalyticsOverview.lineChartsTabs);
  const {
    data = {
      USER_COUNT: null,
      SESSION_COUNT: null,
      BOUNCE_RATE: null,
      AVG_SESSION_DURATION: null,
    },
    isFetching,
    error,
    isError,
  } = useGetKpisHistoryInTimeRangeUsingPostQuery(
    {
      kpis: 'USER_COUNT,SESSION_COUNT,AVG_SESSION_DURATION,BOUNCE_RATE',
      requestFilters: {
        filterEntryList: params.filter,
      },
      from: params.from,
      to: params.to,
      timeStep: params.step,
    },
    {
      selectFromResult(res) {
        return {
          ...res,
          data: res.data
            ? {
                USER_COUNT: getKpiFromPayload(res.data, KPIS.USER_COUNT),
                SESSION_COUNT: getKpiFromPayload(res.data, KPIS.SESSION_COUNT),
                BOUNCE_RATE: getKpiFromPayload(res.data, KPIS.BOUNCE_RATE),
                AVG_SESSION_DURATION: getKpiFromPayload(res.data, KPIS.AVG_SESSION_DURATION),
              }
            : {
                USER_COUNT: null,
                SESSION_COUNT: null,
                BOUNCE_RATE: null,
                AVG_SESSION_DURATION: null,
              },
        };
      },
    }
  );

  return {
    data,
    isFetching,
    error,
    isError,
  };
};

import React, { useState } from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Button, Dialog, DialogTitle, Tooltip, Typography } from '@mui/material';
import { Add, Payment, WarningAmber } from '@mui/icons-material';
import { Translate } from 'react-jhipster';
import { NoPaymentMethodeSaved } from 'app/modules/views/account-page/components/payment-methods/NoPaymentMethodeSaved';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { paymentMethodApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/payment-method';
import PaymentMethodList from 'app/modules/views/account-page/components/payment-methods/components/PaymentMethodList';
import { useKillBillStripe } from 'app/modules/views/account-page/hooks/useKillBillStripe';
import { Elements } from '@stripe/react-stripe-js';
import { AddPaymentMethodForm } from 'app/modules/views/account-page/account-forms/AddPaymentMethodForm';

export const PaymentMethodItem = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { data: paymentMethods } = paymentMethodApi.endpoints.getPaymentMethodsUsingGet.useQueryState({
    accountKey: accountProfile.accountId,
    withPluginInfo: true,
  });
  const { stripePromise, clientSecret } = useKillBillStripe();
  const [openAddPaymentMethodeForm, setOpenAddPaymentMethodeForm] = useState(false);

  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.payment-methods">Payment Methods</Translate>
            </Typography>
          ),
          avatar: <Payment color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        actions: (
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => {
              setOpenAddPaymentMethodeForm(true);
            }}
          >
            <Translate contentKey="account.account-page.add-new-payment-method">Add</Translate>
          </Button>
        ),
      }}
    >
      {paymentMethods && paymentMethods.length > 0 ? <PaymentMethodList paymentMethodsList={paymentMethods} /> : <NoPaymentMethodeSaved />}
      <Dialog
        onClose={() => {
          setOpenAddPaymentMethodeForm(false);
        }}
        open={openAddPaymentMethodeForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add payment method</DialogTitle>
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <AddPaymentMethodForm setOpen={setOpenAddPaymentMethodeForm} accountProfile={accountProfile} />
        </Elements>
      </Dialog>
    </LayoutItem>
  );
};

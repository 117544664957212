import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

export const ScoringJoe = () => {
  return (
    <Box display="flex">
      <Typography variant="h2" color="primary" noWrap>
        Scoring
      </Typography>
      <Typography variant="h2" color="secondary" noWrap>
        Joe
      </Typography>
    </Box>
  );
};

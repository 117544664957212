import React from 'react';

import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { Progress } from '../components/progress/Progress';
import { LoadingButton } from '@mui/lab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { translate } from 'react-jhipster';
import { useDeleteCsv } from './state/useDeleteCsv';
import { getListCSVFiles } from 'app/shared/reducers/web-analytics/tools/export/list';
import { Link } from '@mui/material';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { fr, enAU } from 'date-fns/locale';
import { format } from 'date-fns';

function getExtensionName(extension) {
  const extensionMap = {
    txt: 'Plain Text',
    html: 'HyperText Markup Language',
    json: 'JavaScript Object Notation',
    xml: 'Extensible Markup Language',
    pdf: 'Portable Document Format',
    xls: 'Microsoft Excel Spreadsheet',
    xlsx: 'Microsoft Excel Open XML Spreadsheet',
    zip: 'Zip Archive',
    rar: 'RAR Archive',
    '7z': '7-Zip Archive',
    'tar.gz': 'Tar Gzip Archive',
    tgz: 'Tar Gzip Archive',
    'tar.bz2': 'Tar Bzip2 Archive',
    tbz: 'Tar Bzip2 Archive',
    tar: 'Tar Archive',
  };

  const officialName = extensionMap[extension.toLowerCase()] || 'Unknown';
  return officialName.toUpperCase();
}

const locales = { fr, en: enAU };

export function useDatagridColumns(): GridColDef[] {
  const dispatch = useAppDispatch();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  return [
    {
      field: 'fileName',
      valueFormatter(params: GridValueFormatterParams<number>) {
        const parts = String(params.value).split(/_(\d*)_(\d*)_(\d*)\.zip$/);
        const prefix = parts[0];

        return prefix;
      },
    },
    {
      field: 'fileType',
      valueGetter(params) {
        const parts = String(params.row.fileName).split(/\.([^.]+)$/);
        return parts[1];
      },
      valueFormatter(params: GridValueFormatterParams<number>) {
        return getExtensionName(String(params.value));
      },
    },
    {
      field: 'from',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value * 1000),
    },
    {
      field: 'to',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value * 1000),
    },
    {
      field: 'creation',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value * 1000),
    },
    {
      field: 'length',
      renderCell: (params: GridRenderCellParams) => <Progress params={params} />,
      align: 'right',
    },
    {
      field: 'actions',
      type: 'actions',

      getActions(params: GridRowParams) {
        const { data, loading: deleteLoading, deleteCSVFile } = useDeleteCsv(params.row.fileName);
        return [
          <LoadingButton
            key="delete"
            loading={deleteLoading}
            loadingPosition="start"
            color="error"
            size="small"
            variant="outlined"
            startIcon={<HighlightOffIcon />}
            disabled={params.row.length === 0}
            onClick={() => {
              deleteCSVFile().then(res => {
                dispatch(getListCSVFiles());
              });
            }}
          >
            {translate('web-analytics.export.columns.delete', null, 'Delete')}
          </LoadingButton>,
          <LoadingButton
            key="download"
            loadingPosition="start"
            color="primary"
            size="small"
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            disabled={params.row.length === 0}
            LinkComponent={Link}
            href={`${DATA_HUB_API_URL + '/download/csv'}/${params.row.fileName}`}
            download={params.row.fileName}
            target="_blank"
          >
            {translate('account.download', null, 'Download')}
          </LoadingButton>,
        ];
      },
    },
  ];
}

import React, { useEffect } from 'react';

import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import { sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import { pageGridList } from 'app/modules/views/greensights/component/pageGrid/list';
import { translate } from 'react-jhipster';
import { useRequests } from 'app/modules/views/greensights/component/pageGrid/api';
import { AxiosRequestConfig } from 'axios';
import { renderEcoIndex } from 'app/components/data-grid/columns/column-helpers';

const callback = (responses: Array<any>) => {
  const dataMap = {};
  const map = {};
  responses[2].forEach(value => {
    map[value.url] = value;
  });
  responses.forEach((response, index) => {
    if (index === 2) {
      return;
    }
    if (response && response.content.status === 'success') {
      response.content.data.result.forEach(result => {
        const name = result.metric['__name__'];
        const path = result.metric['page_path'] || 'other';
        const rootUrl = result.metric['root_url'];
        if (!dataMap[path]) {
          dataMap[path] = {};
        }
        if (map[rootUrl + path]) {
          dataMap[path]['page_size_bytes'] = map[rootUrl + path].pageSizeInBytes;
          dataMap[path]['eco_index_grade'] = map[rootUrl + path].ecoIndexGrade;
          dataMap[path]['eco_index_score'] = map[rootUrl + path].ecoIndexScore;
          dataMap[path]['eco_index_ges'] = map[rootUrl + path].ecoIndexGES;
        }
        if (!dataMap[path][name]) {
          dataMap[path][name] = 0;
        }
        result.values.forEach(value => {
          dataMap[path][name] += parseFloat('' + value[1]);
        });
      });
    }
  });

  const data = Object.keys(dataMap).map((key, index) => {
    const value = dataMap[key];
    value.id = index + 1;
    value.page_path = key;
    value.page_size_bytes = value.page_size_bytes / (1024 * 1024);
    return value;
  });
  return data;
};

interface PageGridProps {
  fromDate;
  toDate;
}

const PageGrid = ({ fromDate, toDate }: PageGridProps) => {
  // const [webPageAnalytics, loadWebPageAnalytics] = useRequest({url: '/web-page-analytics', method: 'get'});

  const { data, load } = useRequests(callback);

  const columns: GridColDef[] = [
    { field: 'page_path', headerName: translate('greensights.table.page_path'), minWidth: 600, sortable: false, flex: 1 },
    {
      field: 'page_size_bytes',
      headerName: translate('greensights.table.page_size'),
      width: 200,
      sortingOrder: ['desc'],
    },
    { field: 'page_views', headerName: translate('greensights.table.page_views'), width: 200, sortingOrder: ['desc'] },
    {
      field: 'co2_eq_by_page_kg',
      headerName: translate('greensights.table.page_co2'),
      width: 200,
      sortingOrder: ['desc'],
    },
    {
      field: 'eco_index_score',
      headerName: translate('greensights.table.eco_index_grade'),
      width: 200,
      sortingOrder: ['asc'],
      renderCell: renderEcoIndex,
    },
  ];

  const requests: Array<{ key: number; method: string; url: string; oneTime?: boolean; body?: any; config?: AxiosRequestConfig }> =
    pageGridList.map((topChart, index) => {
      return {
        key: index,
        method: 'post',
        url: '/metric/load/93090364',
        body: {
          start: fromDate,
          end: toDate,
          query: `${topChart.name}{#filters}`,
          // query: `sum(sum_over_time(${topChart.name}{#filters})) by (page_path)`,
          filters: {
            scope: topChart.scope,
          },
        },
      };
    });
  requests.push({ oneTime: true, key: requests.length, url: '/web-page-analytics', method: 'get' });

  useEffect(() => {
    load(requests);
  }, [fromDate, toDate]);

  const columnVisibility = columns.reduce((a, b) => {
    return { ...a, ...b };
  }, {});

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    ...columnVisibility,
  });

  useEffect(() => {
    setColumnVisibilityModel({
      ...columnVisibility,
    });
  }, []);

  return (
    <DataGrid
      autoHeight
      density="compact"
      {...toolbarProps()}
      {...sizedPagination()}
      disableColumnMenu
      disableRowSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'co2_eq_by_page_kg', sort: 'desc' }],
        },
        pagination: {
          paginationModel: { pageSize: 10 },
        },
      }}
      onResize={() =>
        setColumnVisibilityModel({
          ...columnVisibility,
        })
      }
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
      columns={columns}
      rows={data}
    />
  );
};

export default PageGrid;

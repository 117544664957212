import React from 'react';

import { Api, ArrowCircleUp, HelpCenter, HighlightOff, Settings } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';
import { useCancelSubscriptionUsingDeleteMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/subscription';
import { sitemap } from 'app/shared/util/url-utils';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';

export const MiniProductsMenus = ({ p, handleClose }) => {
  const [deleteSubscription] = useCancelSubscriptionUsingDeleteMutation();
  const { subscriptionsList } = useSubscriptionsStates();
  function getSubscriptionId() {
    const subscription = subscriptionsList.filter(el => el.state === 'ACTIVE' && el.productName === p.name);
    return subscription[0]?.subscriptionId;
  }
  const handleCancel = () => {
    deleteSubscription({ subscriptionId: getSubscriptionId() });
  };

  return (
    <List disablePadding sx={{ width: '150px' }}>
      <ListItem sx={{ justifyContent: 'center' }} selected divider>
        <Typography fontWeight="bold" textTransform="capitalize">
          <Translate contentKey={`module-factory.${p.name}`}>{p.name}</Translate>
        </Typography>
      </ListItem>

      <ListItemButton component={Link} to={sitemap.setup} state={{ product: p }}>
        <ListItemIcon>
          <ArrowCircleUp color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<Translate contentKey="products.upgrade">Upgrade</Translate>} />
      </ListItemButton>

      <ListItemButton component={Link} to={sitemap && sitemap[p.name] && sitemap[p.name].settings}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<Translate contentKey="module-factory.settings">Setting</Translate>} />
      </ListItemButton>

      <ListItemButton>
        <ListItemIcon>
          <Api fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<Translate contentKey="products.api-doc">Api Doc</Translate>} />
      </ListItemButton>

      <ListItemButton divider>
        <ListItemIcon>
          <HelpCenter fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<Translate contentKey="products.help">Help</Translate>} />
      </ListItemButton>

      <ListItemButton
        onClick={() => {
          handleCancel();
          handleClose();
        }}
      >
        <ListItemIcon>
          <HighlightOff color="error" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<Translate contentKey="account.cancel">Cancel</Translate>} />
      </ListItemButton>
    </List>
  );
};

import { QuestionMark } from '@mui/icons-material';
import { SvgIcon, Typography } from '@mui/material';
import { IPaymentMethods } from 'app/shared/model/payment-methods.model';
import React from 'react';
import { translate } from 'react-jhipster';

export const unknownPaymentMethodMap = (paymentMethod: IPaymentMethods, setDefault: () => void) => {
  const propertyInfos = paymentMethod.pluginInfo.properties.map(property => {
    return {
      renderPaymentInfo(index: number): [React.ReactNode, string] {
        return [<Typography key={property.key}>{property.key}</Typography>, property.value];
      },
    };
  });

  return {
    id: paymentMethod.paymentMethodId,
    paymentMainInfos: {
      icon: (
        <SvgIcon color="neutral" sx={{ width: 32, height: 32 }}>
          <QuestionMark />
        </SvgIcon>
      ),
      mainInfos: [<Typography key={paymentMethod.paymentMethodId}>{translate('payment-method.unknown-payment-method')}</Typography>],
      chipMainInfos: [],
      isDefault: paymentMethod.isDefault,
      setDefault,
    },
    paymentMethodInfos: [
      {
        title: 'Payment method ID',
        i18n: 'payment-method.payment-method-id',
        value: paymentMethod.paymentMethodId,
      },
      ...propertyInfos,
    ],
  };
};

import React, { useState } from 'react';

import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { durationFormats, durationToString } from 'app/shared/util/date-utils';
import {
  addFlex,
  renderBrowser,
  renderDevice,
  renderFlag,
  translateHeaders,
  typographyWithTooltip,
} from 'app/components/data-grid/columns/column-helpers';
import { toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import { controlledPagination, sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import { generatePath, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sitemap } from 'app/shared/util/url-utils';
import { NoData } from 'app/components/no-data/NoData';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsersColumnVisibilityModel, setUsersColumnVisibilityModel } from 'app/shared/reducers/views/user-exploration';
import { Button, Icon, Tooltip } from '@mui/material';
import { translate } from 'react-jhipster';
import { ContactPage } from '@mui/icons-material';

export const UsersWithScore = ({ usersStats, loading, isError }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const usersColumnVisibilityModel = useAppSelector(getUsersColumnVisibilityModel);

  const columns: GridColDef[] = [
    {
      field: 'userId',
      ...typographyWithTooltip(),
    },
    {
      field: 'engagementScore',
      valueFormatter(value) {
        if (value.value) return `${Number(Number(value.value) * 100).toFixed(2)} %`;
      },
      ...typographyWithTooltip('formattedValue'),
      type: 'number',
    },
    {
      field: 'sessionCount',
      ...typographyWithTooltip(),
      type: 'number',
    },
    {
      field: 'eventsCounterSum',
      ...typographyWithTooltip(),
      type: 'number',
    },
    {
      field: 'pageviewsCounterSum',
      ...typographyWithTooltip(),
      type: 'number',
    },
    {
      field: 'sessionDurationSum',
      ...typographyWithTooltip('formattedValue'),
      valueFormatter(value) {
        if (value.value) return durationToString(Math.floor(Number(value.value)), durationFormats.TRUNC_SHORT_DENSE_TIME);
      },
      type: 'number',
    },
    {
      field: 'bounceCount',
      valueFormatter(value) {
        if (value.value) return `${Number(value.value)} %`;
      },
      ...typographyWithTooltip('formattedValue'),
      type: 'number',
    },
    {
      field: 'firstUserVisitEpochSeconds',
      valueFormatter(value) {
        if (value.value) return new Date(Number(value.value) * 1000).toISOString();
      },
      ...typographyWithTooltip('formattedValue'),
      type: 'number',
    },
    {
      field: 'lastEventEpochSeconds',
      valueFormatter(value) {
        if (value.value) return new Date(Number(value.value) * 1000).toISOString();
      },
      ...typographyWithTooltip('formattedValue'),
      type: 'number',
    },
    {
      field: 'timeSinceLastVisit',
      valueFormatter(value) {
        const numValue = Number(value.value);
        if (numValue === 0) return '0 s';
        const h = Math.floor(numValue / 1000 / 3600);
        const m = Math.floor(((numValue / 1000) % 3600) / 60);
        const s = Math.floor(((numValue / 1000) % 3600) % 60);
        const hDisplay = h > 0 ? h + 'h' : '';
        const mDisplay = m > 0 ? m + 'm' : '';
        const sDisplay = s > 0 ? s + 's' : '';
        return `${hDisplay + mDisplay + sDisplay}`;
      },
      ...typographyWithTooltip('formattedValue'),
      type: 'number',
    },
    {
      field: 'countrySplit',
      renderCell: renderFlag,
    },
    {
      field: 'citySplit',
      ...typographyWithTooltip(),
    },
    {
      field: 'continentSplit',
      ...typographyWithTooltip(),
    },
    {
      field: 'firstVisitedPageSplit',
      ...typographyWithTooltip(),
    },
    {
      field: 'lastVisitedPageSplit',
      ...typographyWithTooltip(),
    },
    {
      field: 'refererSplit',
      ...typographyWithTooltip(),
    },
    {
      field: 'fqdnSplit',
      ...typographyWithTooltip(),
    },
    {
      field: 'deviceCategorySplit',
      renderCell: renderDevice,
    },
    {
      field: 'browserFamilySplit',
      renderCell: renderBrowser,
    },
    {
      field: 'osFamilySplit',
      ...typographyWithTooltip(),
    },
    {
      field: 'user-profile',
      type: 'actions',
      getActions(params: GridRowParams) {
        return [
          <GridActionsCellItem
            key="edit"
            icon={
              <Tooltip title={translate('recommendation.view', null, 'View')}>
                <Icon
                  sx={{
                    ':hover': {
                      color: t => t.palette.primary.light,
                    },
                  }}
                >
                  <ContactPage />
                </Icon>
              </Tooltip>
            }
            onClick={() => {
              navigate(generatePath(sitemap['web-analytics'].visitor, { userId: params.row.userId }));
            }}
            label={translate('recommendation.view', null, 'View')}
          />,
        ];
      },
    },
  ];

  return (
    <DataGrid
      autoHeight
      rows={usersStats || []}
      columns={columns.map(addFlex).map(translateHeaders('user-exploration.users-table'))}
      {...toolbarProps()}
      {...sizedPagination()}
      density="compact"
      getRowId={row => row.id}
      loading={loading}
      onColumnVisibilityModelChange={newModel => dispatch(setUsersColumnVisibilityModel(newModel))}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        sorting: {
          sortModel: [{ field: 'engagementScore', sort: 'desc' }],
        },
        columns: {
          columnVisibilityModel: usersColumnVisibilityModel,
        },
      }}
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
      }}
    />
  );
};

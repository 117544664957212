import { Box, Button, Divider, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAppSelector } from 'app/config/store';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { translate } from 'react-jhipster';
import { parseEsDateString } from '../filter-wrapper/useTimeRangeFilter';

interface DateTimePickerProps {
  fromDate: dayjs.Dayjs;
  toDate: dayjs.Dayjs;
  setFrom: any;
  setTo: any;
  setChosenRange: any;
  setFromDate: any;
  setToDate: any;
  closePopper: () => void;
}

export const DateTimePicker = ({
  fromDate,
  toDate,
  setFrom,
  setTo,
  setChosenRange,
  setFromDate,
  setToDate,
  closePopper,
}: DateTimePickerProps) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const fromLabel = translate('web-analytics.DatePicker.From');
  const toLabel = translate('web-analytics.DatePicker.To');

  const onApply = event => {
    setFromDate(`${fromDate.toISOString().slice(0, 10)}||/d`);
    setToDate(`${toDate.toISOString().slice(0, 10)}||/d`);
    setChosenRange([fromDate, toDate]);
    closePopper();
  };

  useEffect(() => {
    setFrom(fromDate);
    setTo(toDate);
  }, []);

  return (
    <Stack spacing={3} p={2} width="250px">
      <Stack spacing={0}>
        <Typography variant="subtitle1">{translate(`web-analytics.DatePicker.AbsoluteTimeRanges`)}</Typography>
        <Divider sx={{ marginY: '3px' }} />
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-around">
        <Typography variant="subtitle1">{fromLabel}</Typography>
        <Box width={'80%'}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocale}>
            <DatePicker
              value={fromDate}
              maxDate={toDate}
              onChange={newValue => {
                setFrom(newValue);
              }}
            />
          </LocalizationProvider>
        </Box>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-around">
        <Typography variant="subtitle1">{toLabel}</Typography>
        <Box width={'80%'}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocale}>
            <DatePicker
              // slotProps={{ textField: { InputProps: { size: 'small' } } }}
              value={toDate}
              minDate={fromDate}
              onChange={newValue => {
                setTo(newValue);
              }}
            />
          </LocalizationProvider>
        </Box>
      </Stack>

      <Button sx={{ width: '50%', alignSelf: 'center' }} color="primary" size="small" variant="outlined" onClick={onApply}>
        {translate('web-analytics.DatePicker.apply')}
      </Button>
    </Stack>
  );
};

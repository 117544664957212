import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Add, Remove } from '@mui/icons-material';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';

import { CommonFieldProps, CounterFieldProps } from './props/FieldProps';
import ErrorText from './Widgets/ErrorText';
import { Title } from './Widgets/Title';

export interface StandardCounterProps extends CommonFieldProps<'counter'>, CounterFieldProps {
  attribute: Required<CommonFieldProps<'counter'>>['attribute'];
}

export default function StandardCounter(props: { field: StandardCounterProps; methods: UseFormReturn; hideTitle?: boolean }) {
  const {
    field: fieldConfig,
    methods: {
      control,
      getValues,
      setValue,
      trigger,
      formState: { errors },
    },
    hideTitle,
  } = props;

  return (
    <Controller
      name={fieldConfig.attribute}
      control={control}
      render={({ field }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!hideTitle && fieldConfig.title && <Title field={fieldConfig} />}
          <Tooltip title="Reduce">
            <span>
              <IconButton
                aria-label="reduce"
                sx={{
                  color: theme => theme.palette.primary.light,
                }}
                onClick={() => setValue(fieldConfig.attribute, Math.max(Number(field.value) - 1, 0))}
                disabled={fieldConfig.props?.disabled || Number(field.value || 0) <= Number(fieldConfig.inputMin || 0)}
                size="small"
              >
                <Remove />
              </IconButton>
            </span>
          </Tooltip>
          <TextField
            type="number"
            value={field.value ? Number(field.value) : undefined}
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(fieldConfig.attribute, Number(event.target.value));
            }}
          />
          <Tooltip title="Add">
            <span>
              <IconButton
                aria-label="add"
                sx={{
                  color: theme => theme.palette.primary.main,
                }}
                onClick={() => setValue(fieldConfig.attribute, Number(field.value || 0) + 1)}
                disabled={fieldConfig.props?.disabled || Number(field.value || 0) >= Number(fieldConfig.inputMax || 1000000)}
                size="small"
              >
                <Add />
              </IconButton>
            </span>
          </Tooltip>

          {!!errors[fieldConfig.attribute] && <ErrorText error={String(errors[fieldConfig.attribute].message)} />}
        </Box>
      )}
    />
  );
}

import { useState } from 'react';

export const useUploadItemsFile = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [fileType, setFileType] = useState<'json' | 'csv'>(null);
  const handleClickUploadPopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilterPopover = () => {
    setFileType(null);
    setAnchorEl(null);
  };

  const setType = (e, type) => {
    setFileType(type);
    handleClickUploadPopover(e);
  };
  const openUploadPopover = Boolean(fileType !== null);
  return {
    anchorEl,
    openUploadPopover,
    fileType,
    handleClickFilterPopover: handleClickUploadPopover,
    handleCloseFilterPopover,
    setType,
  };
};

import React from 'react';

import { DialogTitle, DialogContent, Typography, CircularProgress, Alert } from '@mui/material';
import { Translate } from 'react-jhipster';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useFindItemByIdQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { Item } from '../../items/components/Item';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const ItemDetails = ({ itemId }) => {
  const { accountProfile } = useAppAccountStates();
  const {
    currentData: item = null,
    isFetching,
    isError,
  } = useFindItemByIdQuery(itemId ? { accountId: accountProfile?.accountId.substring(0, 8), id: itemId } : skipToken);

  return (
    <>
      <DialogTitle typography="h4">
        <Translate contentKey="recommendation.items.item-details">Item details</Translate>
      </DialogTitle>
      <DialogContent dividers sx={{ display: 'flex' }}>
        {isFetching && <CircularProgress sx={{ mx: 'auto' }} />}
        {item && <Item item={item} />}
        {isError && (
          <Alert variant="outlined" severity="warning" sx={{ width: '50%', mx: 'auto' }}>
            <Translate contentKey="recommendation.items.counld-not-find-item">Could not find item with id : </Translate>
            {itemId}
          </Alert>
        )}
      </DialogContent>
    </>
  );
};

import { GridProps, AccordionProps, AccordionActionsProps, AccordionDetailsProps, AccordionSummaryProps } from '@mui/material';
import { TabItemProps } from 'app/components/tabulation';
import { Key } from 'react';
import { RenderChildFunctionType } from '../models/models';
import { CoreCardLayoutProps } from './layout/card/BasicCardLayout';

export interface AccordionLayoutProps {
  accordion?: AccordionProps;
  summary?: AccordionSummaryProps;
  details?: AccordionDetailsProps;
  actionsProps?: AccordionActionsProps;
}

export type LayoutItemProps<T = unknown> = T & {
  children: React.ReactNode;

  key?: Key;
  renderChild?: RenderChildFunctionType;

  gridItemProps?: GridProps;
  tabItemProps?: Omit<TabItemProps, 'children'>;

  accordionOptions?: AccordionLayoutProps;
  cardOptions?: CoreCardLayoutProps;
};

export function LayoutItem(props: LayoutItemProps) {
  return null;
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { KPIS } from 'app/shared/util/reducer-utils';
import { getKpiFromPayload } from 'app/shared/util/data-hub-utils';
import { serializeAxiosError } from '../reducer.utils';

const kpisAggregationUrl = DATA_HUB_API_URL + '/exploration/data';

const initialState = {
  errorMessage: null as unknown as string, // Errors returned from server side
  loading: false,

  data: null as Array<[number, string]>,
};

// Actions
export const getActiveUsersData = createAsyncThunk(
  'webAnalyticsHome/getActiveUsersData',
  async () => {
    return microserviceHttpClient(
      `${kpisAggregationUrl}/kpisHistory?timeStep=PT1M&from=now-15m&to=now&kpis=ACTIVE_USER_COUNT`,
      'post',
      JSON.stringify({
        filterEntryList: [],
      })
    ).then(resp => resp.data);
  },
  { serializeError: serializeAxiosError }
);

export type ActiveUsersData = Readonly<typeof initialState>;

// Slice
export const ActiveUsersSlice = createSlice({
  name: 'activeUsers',
  initialState: initialState as ActiveUsersData,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getActiveUsersData.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getActiveUsersData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = getKpiFromPayload(action.payload, KPIS.ACTIVE_USER_COUNT);
      })
      .addCase(getActiveUsersData.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.data = null as Array<[number, string]>;
      });
  },
});

export default ActiveUsersSlice.reducer;

import React, { useEffect, useState } from 'react';

import { Button, Link, Stack, Typography } from '@mui/material';
import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import Mustache from 'mustache';
import { Translate } from 'react-jhipster';
import { JsonViewer } from '@textea/json-viewer';
import { useJsScriptUrlUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/divolte';
import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';

export const WebAnalyticsTagManager = () => {
  const { subscribedProducts } = useSubscriptionsStates();
  const id = subscribedProducts.filter(sub => sub.productName === 'web-analytics')[0]?.bundleId;

  const { data = {} } = useJsScriptUrlUsingGetQuery({ bundleId: id });

  const [innerText, setInnerText] = useState(null);

  function renderText() {
    fetch('content/mustache/GTM-demo-container.mst')
      .then(response => response.text())
      .then(template => {
        setInnerText(Mustache.render(template, { 'divolte-browser-url': data.endpoint }));
      });
  }

  useEffect(() => {
    renderText();
  }, [data]);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} paddingBottom={3}>
        <BreadcrumbGenerator />
      </Stack>
      <BasicCardLayout
        header={{
          title: (
            <Typography variant="h4">
              <Translate contentKey="web-analytics.export.export-tgm">Export Tag Manager Container</Translate>
            </Typography>
          ),
          action: (
            <Button
              variant="contained"
              color="primary"
              LinkComponent={Link}
              href={`data:text/json;chatset=utf-8,${encodeURIComponent(innerText)}`}
              download="tag-manager.json"
            >
              <Translate contentKey="web-analytics.export.download">Download</Translate>
            </Button>
          ),
        }}
      >
        <JsonViewer
          value={JSON.parse(innerText)}
          indentWidth={2}
          displayDataTypes={false}
          collapseStringsAfterLength={20}
          defaultInspectDepth={2}
          displayObjectSize={false}
          rootName={false}
          theme="auto"
        />
      </BasicCardLayout>
    </>
  );
};

import { FieldProp } from 'app/modules/form-builder-lib';

export const inviteMemberAttributes: Array<FieldProp> = [
  {
    component: 'text-field',
    attribute: 'email',
    label: 'account.account-page.email',
    validationType: 'string',
    validations: [['email', true]],
  },
  {
    component: 'autocomplete',
    attribute: 'role',
    label: 'account.member-table.roles',
    options: ['ADMIN', 'CONTRIBUTOR', 'GUEST'],
  },
];

import React, { useEffect } from 'react';

import { Stack, Typography } from '@mui/material';
import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { RecoStrategyForm } from './components/RecoStrategyForm';
import { Translate } from 'react-jhipster';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { RecommendedItems } from './components/RecommendedItems';
import { useGetRecos } from './hooks/useGetRecos';
import { RecommendationsStrategiesInfos } from './components/RecommendationsStrategiesInfos';

export const RecoStrategies = props => {
  const { resReco, handleGetRecos } = useGetRecos();
  return (
    <>
      <Stack direction="row" alignItems="center" paddingBottom={3}>
        <BreadcrumbGenerator />
      </Stack>

      <Stack sx={{ height: '100%', alignItems: 'center' }} flexWrap="nowrap">
        <RecoStrategyForm handleGetRecos={handleGetRecos} />
        {resReco.data && <RecommendationsStrategiesInfos algo={resReco.originalArgs.queryParameters.recommender_type} />}
        {(resReco.data || resReco.isLoading) && (
          <BasicCardLayout
            header={{
              title: (
                <Typography variant="h4">
                  <Translate contentKey="recommendation.recommended-items">Recommended Items</Translate>
                </Typography>
              ),
            }}
            card={{ sx: { width: '100%' } }}
          >
            <RecommendedItems items={resReco.data ? resReco.data.impressions : []} isLoading={resReco.isLoading} />
          </BasicCardLayout>
        )}
      </Stack>
    </>
  );
};

import L, { LatLngExpression } from 'leaflet';
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import './SessionLeafletMap.css';

export const SessionLeafletMap = ({ geoLocation }) => {
  const position = Object.values(geoLocation) as LatLngExpression;

  return (
    <MapContainer center={position} zoom={13}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>Session Geolocation</Popup>
      </Marker>
    </MapContainer>
  );
};

const DefaultIcon = L.icon({
  iconUrl: 'src/main/webapp/content/img/leaflet/marker-icon.png',
  iconAnchor: [12, 41],
  popupAnchor: [2, -40],
});
L.Marker.prototype.options.icon = DefaultIcon;

export const pageGridList = [
  // {
  //   title: 'page-size',
  //   name: 'page_size_bytes',
  //   key: 'page_path',
  //   scope: 'metric'
  // },
  {
    title: 'page-views',
    name: 'page_views',
    key: 'page_path',
    scope: 'page'
  },
  {
    title: 'co2-eq-by-page',
    name: 'co2_eq_by_page_kg',
    key: 'page_path',
    scope: 'page'
  },
];

import React from 'react';

import { Chip, LinearProgress, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from 'app/config/store';
import { FieldProp } from 'app/modules/form-builder-lib';
import { bucketingDimensions } from 'app/shared/util/data-exploration-utils';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { translate } from 'react-jhipster';
import { RequestFilterEntry } from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { EntityId } from '@reduxjs/toolkit';

const OptionWithDetails = ({ option, count, percent, state, filterKeys, ...rest }) => {
  return (
    <Box display="flex" alignItems="center" {...rest} width="100%">
      <Box display="flex" alignItems="center" width="60%">
        <Chip size="small" variant="outlined" label={`#${state.index + 1}`} />
        <Tooltip title={option}>
          <Typography component="span" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ml: 1 }}>
            {option}
          </Typography>
        </Tooltip>
      </Box>

      <Tooltip
        title={`Count: ${count} ( with ${filterKeys.length} filter(s) applied ${filterKeys.length > 0 ? `: ${filterKeys.join(',')}` : ''})`}
      >
        <Box display="flex" alignItems="center" width="40%">
          <Box sx={{ width: '80%', mx: 1 }}>
            <LinearProgress value={percent} variant="determinate" />
          </Box>
          <Typography variant="body2" color="text.secondary">{`${Number(percent).toFixed(1)}%`}</Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

let formFields: Array<FieldProp> = [];

export function getFormFields(
  values: object,
  editFilter,
  selectAllFilters: (state: any) => RequestFilterEntry[],
  selectFiltersIds: (state: any) => EntityId[],
  methods?: UseFormReturn<FieldValues, any>
): Array<FieldProp> {
  const operators = methods && values[methods.watch('key')] ? values[methods.watch('key')].operations : [];
  const optionsValue = methods && values[methods.watch('key')] ? values[methods.watch('key')].values : [];
  const filterKeys = useAppSelector(selectFiltersIds);
  const filter = useAppSelector(selectAllFilters);
  formFields = [
    {
      component: 'select',
      attribute: 'key',
      label: 'web-analytics.request-filter.filter',
      options:
        editFilter !== null
          ? bucketingDimensions.map(o => {
              return {
                value: o,
                label: translate(`web-analytics.request-filter.bucketingDimensions.${o}`, null, o),
              };
            })
          : bucketingDimensions
              .filter(bucket => !filterKeys.includes(bucket))
              .map(o => {
                return {
                  value: o,
                  label: translate(`web-analytics.request-filter.bucketingDimensions.${o}`, null, o),
                };
              }),
      optionConfig: { value: 'value', key: 'value', label: 'label' },
      validationType: 'string',
      props: {
        onChange(event) {
          if (filterKeys.includes(String(event.target.value))) {
            methods.setValue('filteringKind', filter.filter(f => f.key === String(event.target.value))[0].filteringKind);
            methods.setValue('value', filter.filter(f => f.key === String(event.target.value))[0].value);
            if (methods.watch('filteringKind') === 'IN_VALUE_RANGE' || methods.watch('filteringKind') === 'NOT_IN_VALUE_RANGE') {
              methods.setValue('from', filter.filter(f => f.key === String(event.target.value))[0].value[0]);
              methods.setValue('to', filter.filter(f => f.key === String(event.target.value))[0].value[1]);
            } else if (methods.watch('filteringKind') === 'GREATER_THAN' || methods.watch('filteringKind') === 'LOWER_THAN') {
              methods.setValue('from', filter.filter(f => f.key === String(event.target.value))[0].value[0]);
            }
          } else {
            methods.resetField('filteringKind');
            methods.resetField('value');
          }
        },
        disabled: editFilter !== null ? true : false,
      },
    },
    {
      component: 'select',
      attribute: 'filteringKind',
      label: 'web-analytics.request-filter.filter-data-grid.filteringKind',
      options: operators.map(o => {
        return {
          value: o,
          label: translate(`web-analytics.request-filter.filter-operators.${o}`, null, o),
        };
      }),
      optionConfig: { value: 'value', key: 'value', label: 'label' },
      validationType: 'string',
      props: {
        disabled: methods ? methods.watch('key') === '' : true,
      },
    },
    {
      component: 'autocomplete',
      attribute: 'value',
      label: 'web-analytics.request-filter.filter-data-grid.value',
      options: optionsValue,
      props: {
        autoHighlight: true,
        multiple: true,
        freeSolo: true,
        renderOption: (props, option, state) => (
          <OptionWithDetails
            count={option.count}
            option={option.value}
            percent={option.percent}
            state={state}
            filterKeys={filterKeys}
            {...props}
          />
        ),
        getOptionLabel(option) {
          return String(option.value);
        },
        limitTags: 2,
      },
      optionConfig: { value: 'value', label: 'value' },
      hideCondition: methods ? !['PRESENT_IN_VALUES', 'ABSENT_IN_VALUES'].includes(methods.watch('filteringKind')) : true,
    },
    {
      component: 'counter',
      attribute: 'from',
      validationType: 'number',
      label: 'counter',
      inputMax: methods ? methods.watch('to') - 1 : undefined,
      hideCondition: methods
        ? !['IN_VALUE_RANGE', 'NOT_IN_VALUE_RANGE', 'GREATER_THAN', 'LOWER_THAN'].includes(methods.watch('filteringKind'))
        : true,
      col: {
        xs: 6,
      },
    },
    {
      component: 'counter',
      attribute: 'to',
      validationType: 'number',
      label: 'counter',
      inputMin: methods ? methods.watch('from') + 1 : undefined,
      hideCondition: methods ? !['IN_VALUE_RANGE', 'NOT_IN_VALUE_RANGE'].includes(methods.watch('filteringKind')) : true,
      col: {
        xs: 6,
      },
    },
  ];

  return formFields;
}

import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { FieldProp } from 'app/modules/form-builder-lib';
import React from 'react';
import { translate } from 'react-jhipster';

const countryOptions = ['FR', 'US'];

export const billingEditAttributes: Array<FieldProp> = [
  {
    component: 'text-field',
    validationType: 'string',
    attribute: 'email',
    label: 'account.account-page.email',
    validations: [['email', true]],
  },
  {
    component: 'text-field',
    validationType: 'string',
    attribute: 'address1',
    label: 'account.account-page.address',
    /* validations: [['matches', [/[a-z]/i, 'Can only contain letters']]], */
  },
  {
    component: 'text-field',
    col: {
      xs: 9,
    },
    validationType: 'string',
    attribute: 'city',
    label: 'account.account-page.city',
    /* validations: [['matches', [/[a-z]/i, 'Can only contain letters']]], */
  },
  {
    component: 'text-field',
    col: {
      xs: 3,
    },
    attribute: 'postalCode',
    label: 'account.account-page.postalCode',
  },
  {
    component: 'autocomplete',
    options: countryOptions,
    attribute: 'country',
    label: 'account.account-page.country',
    props: {
      renderOption: (props, option) => <FlagWithCountryName country={option} {...props} />,
      getOptionLabel(option) {
        if (option !== '') return translate(`countries.${option}`, null, option);
        else return '';
      },
    },
  },
];

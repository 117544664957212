import dayjs from 'dayjs';
import plugin from 'dayjs/plugin/duration';

// Relative time ranges
const relTimeRangesDefault = ['-1h', '-12h', '-1d', '-1w', '-1M', '-3M', '-6M', '-1y'];

function relTimeRangeMapper(rangeOffset) {
  return { i18nKey: `web-analytics.DatePicker.SincePeriod`, from: `now${rangeOffset}` };
}

// So far time ranges
const soFarRangesDefault = { today: '/d', week: '/w', month: '/M', year: '/y' };

function soFarTimeRangeMapper([key, range]) {
  return { i18nKey: `web-analytics.DatePicker.SoFar.${key}`, from: `now${range}` };
}

// Previous time ranges
const previousRangesDefault = {
  day: { offset: '-1d', trunc: '/d' }, // Yesterday
  week: { offset: '-1w', trunc: '/w' }, // Previous week
  month: { offset: '-1M', trunc: '/M' }, // Previous month
  year: { offset: '-1y', trunc: '/y' }, // Previous year
};

function previousTimeRangeMapper([key, range]) {
  return { i18nKey: `web-analytics.DatePicker.Previous.${key}`, from: `now${range.offset}${range.trunc}`, to: `now${range.trunc}` };
}

// Other time ranges
const otherRangesDefault = [
  { i18nKey: 'web-analytics.DatePicker.OtherRanges.DayBeforeYesterday', from: 'now-2d/d', to: 'now-1d/d' },
  { i18nKey: 'web-analytics.DatePicker.OtherRanges.ThisDayLastWeek', from: 'now-7d/d', to: 'now-6d/d' },
];

// Time range list of ranges options
type TimeRangeType = {
  i18nKey: string;
  from: string;
  to?: string;
  step?: string;
  value?: string;
};

type TimeRangeListType = {
  title: string;
  list: TimeRangeType[];
};

export function timeRangeListBuilder(
  relTimeRanges: string[],
  soFarRanges,
  previousRanges,
  otherRanges: TimeRangeType[]
): TimeRangeListType[] {
  return [
    ...(relTimeRanges ? [{ title: 'RelativeTimeRanges', list: relTimeRanges.map(relTimeRangeMapper) }] : []),
    ...(soFarRanges ? [{ title: 'SoFarTimeRanges', list: Object.entries(soFarRanges).map(soFarTimeRangeMapper) }] : []),
    ...(previousRanges ? [{ title: 'PreviousTimeRanges', list: Object.entries(previousRanges).map(previousTimeRangeMapper) }] : []),
    ...(otherRanges ? [{ title: 'OtherQuickRanges', list: otherRanges }] : []),
  ];
}

export const timeRangeListDefault = timeRangeListBuilder(
  relTimeRangesDefault,
  soFarRangesDefault,
  previousRangesDefault,
  otherRangesDefault
);

export const refreshRateList: plugin.Duration[] = [
  dayjs.duration(15, 'seconds'),
  dayjs.duration(1, 'minute'),
  dayjs.duration(5, 'minutes'),
  dayjs.duration(10, 'minutes'),
  dayjs.duration(30, 'minutes'),
  dayjs.duration(1, 'hour'),
];

import React from 'react';

import { DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer';
import { InvoiceDocument } from './payment/InvoiceDocument';
import { useAppSelector } from 'app/config/store';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { getInvoiceDetail } from './payment/getInvoiceDetails';
import { translate } from 'react-jhipster';
import { AccountDtoRes, accountApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';

export const InvoiceView = ({ invoice }) => {
  const { currentLocale }: any = useAppSelector(state => state.locale);
  const { userProfile, accountProfile } = useAppAccountStates();
  const { data: billingAccount = {} as AccountDtoRes } = accountApi.endpoints.getAccountByKeyUsingGet.useQueryState({
    accountKey: userProfile ? userProfile.currentAccount : null,
  });
  const document = getInvoiceDetail(invoice, userProfile, accountProfile, billingAccount, currentLocale);
  const [instance, updateInstance] = usePDF({ document: InvoiceDocument({ invoice: document }) });

  return (
    <>
      <DialogTitle typography="h4">
        {translate('account.invoice-details', null, 'Invoice details')} {document.number}
      </DialogTitle>
      <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
        <PDFViewer height="800px" width="70%">
          <InvoiceDocument invoice={document} />
        </PDFViewer>
      </DialogContent>
      <DialogActions sx={{ paddingX: 3 }}>
        <Button href={instance.url} download={`${document.id}.pdf`}>
          {translate('account.invoice-table.download', null, 'Download')}
        </Button>
      </DialogActions>
    </>
  );
};

import React from 'react';

import { Box, Typography } from '@mui/material';
import { RecommendationsProgress } from './RecommendationsProgress';
import { ActionPercentageBigArrow } from './ActionPercentageBigArrow';
import { getRecommendationsToActionsChartData } from '../utils/actions-data-transformation';
import { WithData } from 'app/modules/loading-lib/WithData';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

const RecommendationToActionChart = ({ data, actions, isFetching }) => {
  const chartData = getRecommendationsToActionsChartData(actions);

  return (
    <>
      {chartData && (
        <Box width="80%" mx="auto">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography textAlign="center" variant="h2">
              {chartData.demonstrationOfInterest.percentage} %
            </Typography>
            <Typography textAlign="center" variant="h4" color="textSecondary" mx={1}>
              {chartData.demonstrationOfInterest.name} From Recommendation Of All
            </Typography>
            <Typography textAlign="center" variant="h2">
              {chartData.demonstrationOfInterest.total}
            </Typography>
          </Box>
          <RecommendationsProgress data={chartData.demonstrationOfInterest} />
          <Box display="flex" justifyContent="space-between" mx={3}>
            <ActionPercentageBigArrow
              data={((chartData.conversion.value[0] * 100) / chartData.demonstrationOfInterest.value[0]).toFixed(1)}
            />
            <ActionPercentageBigArrow
              data={((chartData.conversion.value[1] * 100) / chartData.demonstrationOfInterest.value[1]).toFixed(1)}
            />
          </Box>
          <RecommendationsProgress data={chartData.conversion} />
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography textAlign="center" variant="h2">
              {chartData.conversion.percentage} %
            </Typography>
            <Typography textAlign="center" variant="h4" color="textSecondary" mx={1}>
              {chartData.conversion.name} From Recommendation Of All
            </Typography>
            <Typography textAlign="center" variant="h2">
              {chartData.conversion.total}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default WithLoadingHOC(WithData(RecommendationToActionChart));

import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';
import { useStatusUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/divolte';

export const useWebAnalyticsStatus = () => {
  const { subscribedProducts } = useSubscriptionsStates();
  const id = subscribedProducts.filter(sub => sub.productName === 'web-analytics')[0]?.bundleId;
  const { data = {}, isSuccess } = useStatusUsingGetQuery({ bundleId: id });
  const status = isSuccess && data?.status ? data.status : undefined;
  return status;
};

import React from 'react';

import { GroupedListLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { ClickStreamConfig } from './accordion-items/ClickStreamConfig';
import { TagManager } from './accordion-items/TagManager';
import { ExportRawData } from './accordion-items/ExportRawData';
import { Dashboards } from './accordion-items/Dashboards';
import { useWebAnalyticsStatus } from './hooks/useWebAnalyticsStatus';
import { WebAnalyticsAccordionWithReacdyStatus } from './components/WebAnalyticsAccordionWithReacdyStatus';
import { WebAnalyticsAccordionWithNotReacdyStatus } from './components/WebAnalyticsAccordionWithNotReacdyStatus';

export const WebAnalyticsAccordion = () => {
  const status = useWebAnalyticsStatus();
  if (status && status.startsWith('READY')) {
    return <WebAnalyticsAccordionWithReacdyStatus />;
  }
  return <WebAnalyticsAccordionWithNotReacdyStatus />;
};

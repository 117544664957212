import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { LandingAppbar } from '../layout/main-layouts/appbar/index/LandingAppbar';
import { WorkspaceAppbar } from '../layout/main-layouts/appbar/workspace/WorkspaceAppbar';
import { sitemap } from '../util/url-utils';
import { UserProfileSection } from '../layout/main-layouts/appbar/workspace/UserProfileSection';

export const AppbarRoutes = () => {
  return (
    <Routes>
      <Route index element={<LandingAppbar />} />
      <Route path={sitemap.recommendationAlgorithms} element={<LandingAppbar />} />
      <Route element={<WorkspaceAppbar />}>
        <Route path={sitemap.error} element={<></>} />
        <Route path={'/*'} element={<UserProfileSection />} />
      </Route>
    </Routes>
  );
};

import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { useState } from 'react';

export const useProgress = () => {
  const [progress, setProgress] = useState(0);

  const getProgress = fileName => {
    const evtSource = new EventSource(DATA_HUB_API_URL + '/download/csv/progress' + `/${fileName}`, {
      withCredentials: true,
    });
    evtSource.onmessage = event => {
      const parsedData = JSON.parse(event.data);

      if (parsedData.type === 'DATA_DUMP_PROGRESS') {
        setProgress(parsedData.data);
        if (parsedData.data === 100) evtSource.close();
      } else if (parsedData.type === 'NO_DATA_DUMP_PROGRESS') {
        setProgress(100);
        evtSource.close();
      }
    };
  };
  return {
    progress,
    getProgress,
  };
};

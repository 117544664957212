import { useAppSelector } from 'app/config/store';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import {
  MultiHistoryAggsSerieItemOfobject,
  useGetKpisHistoryInTimeRangeUsingPostQuery,
} from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { useEffect } from 'react';

type KPI = 'BOUNCE_RATE' | 'PAGEVIEWS_COUNT' | 'SESSION_COUNT' | 'USER_COUNT';

function getTotalKPIValue(data: { date: MultiHistoryAggsSerieItemOfobject[] }, kpi: KPI): number {
  const kpiData = data.date.filter(d => d.buckets && d.buckets[kpi]);
  const kpiValues = kpiData.map(d => d.buckets[kpi][Object.keys(d.buckets[kpi])[0]]);
  if (kpi === 'BOUNCE_RATE') {
    return kpiValues.length > 0 ? kpiValues.reduce((total, val) => total + val, 0) / kpiValues.length : 0;
  } else {
    return kpiValues.reduce((total, val) => total + val, 0);
  }
}

interface DataBucket {
  [key: string]: {
    sum?: number;
    rate?: number;
    count?: number;
  };
}

interface DateBucket {
  docCount: number;
  buckets: DataBucket;
  date: string;
}

interface Data {
  date: DateBucket[];
}

function extractKpiData(data: Data, kpi: string): [number, string][] {
  const kpiData: [number, string][] = [];
  data.date.forEach(dateBucket => {
    const kpiBucket = dateBucket.buckets[kpi];
    const value = Object.keys(dateBucket.buckets[kpi])[0];

    if (kpiBucket && kpiBucket[value] !== undefined) {
      const timestamp = Date.parse(dateBucket.date);
      const count = kpiBucket[value].toString();
      kpiData.push([timestamp, count]);
    }
  });
  return kpiData;
}

export const useDashboardData = kpi => {
  const { accountProfile } = useAppAccountStates();

  const {
    data: lastWekkData = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch: refetchLastWeek,
  } = useGetKpisHistoryInTimeRangeUsingPostQuery(
    {
      from: 'now-7d',
      to: 'now',
      timeStep: 'P1D',
      kpis: 'USER_COUNT,SESSION_COUNT,PAGEVIEWS_COUNT,BOUNCE_RATE',
      requestFilters: {
        filterEntryList: [],
      },
    },
    {
      selectFromResult({ data, ...rest }) {
        return {
          data: data?.data
            ? {
                totalLastWeek: getTotalKPIValue(data.data, kpi as KPI),
                chartData: extractKpiData(data.data as Data, kpi as KPI),
              }
            : {},
          ...rest,
        };
      },
      pollingInterval: 3 * 60 * 1000,
    }
  );
  const { data: weekBeforeData = {}, refetch: refetchWeekBefore } = useGetKpisHistoryInTimeRangeUsingPostQuery(
    {
      from: 'now-14d',
      to: 'now-7d',
      timeStep: 'P1D',
      kpis: 'USER_COUNT,SESSION_COUNT,PAGEVIEWS_COUNT,BOUNCE_RATE',
      requestFilters: {
        filterEntryList: [],
      },
    },
    {
      selectFromResult({ data, ...rest }) {
        return {
          data: data?.data
            ? {
                totalWeekBefore: getTotalKPIValue(data.data, kpi as KPI),
              }
            : {},
          ...rest,
        };
      },
      pollingInterval: 3 * 60 * 1000,
    }
  );

  const differenceInPercentage =
    weekBeforeData.totalWeekBefore !== 0
      ? ((lastWekkData.totalLastWeek - weekBeforeData.totalWeekBefore) / weekBeforeData.totalWeekBefore) * 100
      : 100;

  const difference = lastWekkData.totalLastWeek - weekBeforeData.totalWeekBefore;

  useEffect(() => {
    refetchLastWeek();
    refetchWeekBefore();
  }, [accountProfile]);

  return {
    lastWekkData,
    weekBeforeData,
    difference,
    differenceInPercentage,
    isFetching,
    isLoading,
    isSuccess,
  };
};

import React from 'react';

import { DashboardOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate } from 'react-jhipster';

export const Dashboard = () => {
  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.dashboard">Dashboard</Translate>
            </Typography>
          ),
          avatar: <DashboardOutlined color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
            transition: 'all 500ms linear',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <Typography variant="subtitle1">
        <Translate contentKey="module-factory.accordion.dashboard-text">Explore your consumtions with greensight dashboard</Translate>
      </Typography>
    </LayoutItem>
  );
};

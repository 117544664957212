import React, { useContext } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { translate } from 'react-jhipster';
import { UsersRow } from './UsersRow';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';
import { useAppSelector } from 'app/config/store';
import { getUserExplorationSelectedCohortCell } from 'app/shared/reducers/views/user-exploration';

export const createData = (user: string, sessionCount: number, sessions: any) => {
  return {
    user,
    sessionCount,
    sessions,
  };
};

interface UsersOverviewTableProps {
  data: any;
  isFetching: boolean;
  isError: boolean;
  error: any;
}

export const UsersOverviewTable = ({ data, isFetching, isError, error }: UsersOverviewTableProps) => {
  const selectedCohortCell = useAppSelector(getUserExplorationSelectedCohortCell);

  const rows = data
    ? data.map(userRow => {
        return createData(userRow.user, userRow.sessionCount, userRow.sessions);
      })
    : [];

  return rows && rows.length > 0 ? (
    <Table sx={{ tableLayout: 'fixed', pb: 1 }} size="small">
      <TableHead>
        <TableRow hover>
          <TableCell
            variant="head"
            sx={{
              width: '65%',
            }}
          >
            {translate('user-exploration.users-table.userId')}
          </TableCell>
          <TableCell
            variant="head"
            sx={{
              width: '20%',
            }}
            align="center"
          >
            {translate('user-exploration.users-table.session-count')}
          </TableCell>
          <TableCell variant="head" sx={{ width: '15%' }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <UsersRow key={row.user} row={row} />
        ))}
      </TableBody>
    </Table>
  ) : selectedCohortCell.length === 0 ? (
    <Typography variant="h5" sx={{ textAlign: 'center', mt: '50px' }}>
      {translate('user-exploration.users-table.click-a-cell')}
    </Typography>
  ) : (
    <Typography variant="h5" sx={{ textAlign: 'center', mt: '50px' }}>
      {translate('user-exploration.users-table.zero-users')}
    </Typography>
  );
};

export default WithLoadingHOC(UsersOverviewTable);

// Microservices
export const CONFIG_MANAGER_SERVICE_URL = SERVER_API_URL + '/services/' + CONFIG_MANAGER_CLOUD_SERVICES;
export const CONFIG_MANAGER_API_URL = CONFIG_MANAGER_SERVICE_URL + '/api/' + CONFIG_MANAGER_API_VERSION;

export const DATA_HUB_SERVICE_URL = SERVER_API_URL + '/services/' + DATA_HUB_CLOUD_SERVICES;
export const DATA_HUB_API_URL = DATA_HUB_SERVICE_URL + '/api/' + DATA_HUB_API_VERSION;

export const RECO_ENGINE_SERVICE_URL = SERVER_API_URL + '/services/' + RECOMMENDER_ENGINE_CLOUD_SERVICES;
export const RECO_ENGINE_API_URL = RECO_ENGINE_SERVICE_URL + '/api/' + RECOMMENDER_ENGINE_API_VERSION;

export const GREENSIGHTS_DATA_HUB_SERVICE_URL = SERVER_API_URL + '/services/' + GREENSIGHTS_DATA_HUB_CLOUD_SERVICES;
export const GREENSIGHTS_DATA_HUB_API_URL = GREENSIGHTS_DATA_HUB_SERVICE_URL + '/api/' + GREENSIGHTS_DATA_HUB_API_VERSION;

export const RECO_ENGINE_URL = SERVER_API_URL + '/services';

import React from 'react';

import { BasicCardLayout, BasicCardLayoutProps } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { CardHeaderProps, Typography, TypographyProps } from '@mui/material';

const defaultKeyProps: TypographyProps = { variant: 'h1' };
const defaultValueProps: TypographyProps = {
  variant: 'h5',
  color: 'textSecondary',
};

interface DisplayOptions {
  key: string;
  value: string | number;
}

interface BigNumberCardProps extends Omit<BasicCardLayoutProps, 'header'> {
  options?: DisplayOptions;
  icon: any;
  keyProps?: TypographyProps;
  valueProps?: TypographyProps;
  cardHeaderProps?: CardHeaderProps;
}

export const BigNumberCard = ({
  options,
  icon,
  keyProps = {},
  valueProps = {},
  cardHeaderProps = {},
  ...cardProps
}: BigNumberCardProps) => {
  return (
    <BasicCardLayout
      header={{
        disableTypography: true,
        title: (
          <Typography {...defaultKeyProps} {...keyProps}>
            {options.key}
          </Typography>
        ),
        subheader: (
          <Typography {...defaultValueProps} {...valueProps}>
            {options.value}
          </Typography>
        ),
        avatar: icon,
        ...cardHeaderProps,
      }}
      {...cardProps}
    />
  );
};

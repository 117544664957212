import { useAppSelector } from 'app/config/store';
import { ProductNames } from 'app/shared/model/catalog.model';

export const useProductCardButtons = (productName: ProductNames) => {
  const { bundles } = useAppSelector(state => state.subscriptionBundles);

  const subscription = bundles.filter(el =>
    el.subscriptions.some(sub => sub.state === 'ACTIVE' && sub.productCategory === 'BASE' && sub.productName === productName)
  );
  return subscription[0]?.bundleId;
};

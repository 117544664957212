import { useTheme } from '@mui/material/styles';
import { colorPaletteMode, getChartColorPalette } from 'app/shared/util/color-palette-calculator';
import type { EChartsOption } from 'echarts';
import { merge } from 'merge-anything';

export function buildEChartOptions(partialOptions: Partial<EChartsOption>) {
  const theme = useTheme();

  const styledOptions: EChartsOption = {
    color: getChartColorPalette(theme.palette.secondary.main, colorPaletteMode.MAIN_LIGHT_DARK),
    tooltip: {
      show: true,
    },
    // tooltip: {
    //   trigger: 'item',
    //   position: 'inside',
    //   formatter(params) {
    //     return `${params.name}<br/>${Number(params.value * 100).toFixed(2)} %`;
    //   },
    // },
    series: [
      {
        type: 'pie',
        stillShowZeroSum: true,
        radius: ['60%', '90%'],
      },
    ],
  };

  return merge(styledOptions, partialOptions);
}

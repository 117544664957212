import { Fab, Drawer } from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import { Routes, Route } from 'react-router-dom';

export const PropertyBarRoutes = () => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(prevOpen => !prevOpen);
  };
  return (
    <Routes>
      <Route
        path="/propertybar"
        element={
          <>
            <Drawer
              anchor={'right'}
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <Box width="200px" />
            </Drawer>
            <Fab
              color="primary"
              onClick={() => toggleDrawer()}
              sx={{
                position: 'absolute',
                bottom: 16,
                right: 50,
              }}
            >
              <AddIcon />
            </Fab>
          </>
        }
      />
      <Route path="*" element={null} />
    </Routes>
  );
};

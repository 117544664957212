import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IBundle, ISubscription } from 'app/shared/model/subscription.model';

const subscriptionBundlesUrl = CONFIG_MANAGER_API_URL + '/billing/accounts';

export const initialState = {
  subscriptionlLoading: true,
  errorMessage: null as unknown as string, // Errors returned from server side
  bundles: [] as unknown as IBundle[],
  subscriptions: [] as unknown as ISubscription[],
  subscribedProducts: [] as unknown as ISubscription[],
  isAnalyticsSubscribed: null,
  isRecoSubscribed: null,
  isGreenSightsSubscribed: null,
};

export type SubscriptionBundlesState = Readonly<typeof initialState>;

// Actions

export const getSubscriptionBundles = createAsyncThunk(
  'subscriptionBundles/getSubscriptionBundles',
  async ({ accountId }: { accountId: any }) => microserviceHttpClient(`${subscriptionBundlesUrl}/${accountId}/bundles`),
  { serializeError: serializeAxiosError }
);

// Slice

export const SubscriptionBundlesSlice = createSlice({
  name: 'subscriptionBundles',
  initialState: initialState as SubscriptionBundlesState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSubscriptionBundles.pending, state => {
        state.subscriptionlLoading = true;
        state.errorMessage = null;
      })
      .addCase(getSubscriptionBundles.fulfilled, (state, action) => {
        state.subscriptionlLoading = false;
        state.bundles = action.payload.data as IBundle[];
        state.subscriptions = getSubscriptions(action.payload.data);
        state.subscribedProducts = getSubscribedProducts(state.subscriptions);
        state.isAnalyticsSubscribed = isSubscribed(state.subscribedProducts, 'analytics');
        state.isRecoSubscribed = isSubscribed(state.subscribedProducts, 'recommendations');
        state.isGreenSightsSubscribed = isSubscribed(state.subscribedProducts, 'greensights');
      })
      .addCase(getSubscriptionBundles.rejected, (state, action) => {
        state.subscriptionlLoading = false;
        state.errorMessage = action.error.message;
        state.bundles = [] as unknown as IBundle[];
        state.isAnalyticsSubscribed = false;
        state.isRecoSubscribed = false;
        state.isGreenSightsSubscribed = false;
        state.subscribedProducts = [];
      });
  },
});

// Reducer
export default SubscriptionBundlesSlice.reducer;

const isSubscribed = (subscribedProducts: ISubscription[], product) => {
  return subscribedProducts.filter(sub => sub.productName.includes(product)).length > 0;
};

const getSubscribedProducts = (subscriptions: ISubscription[]) => {
  return subscriptions.filter(sub => sub.state === 'ACTIVE' && sub.productCategory === 'BASE');
  // .map(sub => sub.productName);
};

export const getSubscriptions = subscriptions => {
  return subscriptions.flatMap(value => {
    return value.subscriptions;
  });
};

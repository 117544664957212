import React from 'react';
import { Typography } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { durationFormats, durationToString } from 'app/shared/util/date-utils';

export const UserKpiCard = ({ title, value }) => {
  return (
    <BasicCardLayout
      header={{
        disableTypography: true,
        title: (
          <Typography variant="subtitle1" color="textSecondary">
            {title}
          </Typography>
        ),
      }}
      card={{ sx: { width: '25%' } }}
    >
      <Typography variant="h1">
        {title === 'Sum Sessions Duration' ? durationToString(Math.floor(Number(value)), durationFormats.TRUNC_SHORT_DENSE_TIME) : value}
      </Typography>
    </BasicCardLayout>
  );
};

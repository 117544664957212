import { useState } from 'react';

export const useInvoicesDataGrid = invoiceList => {
  const [invoice, setInvoice] = useState(invoiceList.length > 0 ? invoiceList[0] : ({} as any));
  const [openInvoiceDetailsDialog, setOpenInvoiceDetailsDialog] = useState(false);
  const [payment, setPayment] = useState(null);
  const [openPaymentDetailsDialog, setOpenPaymentDetailsDialog] = useState(false);
  const handleCloseInvoiceDetails = () => setOpenInvoiceDetailsDialog(false);
  const handleClosePaymentDetails = () => setOpenPaymentDetailsDialog(false);

  return {
    invoice,
    setInvoice,
    openInvoiceDetailsDialog,
    setOpenInvoiceDetailsDialog,
    payment,
    setPayment,
    openPaymentDetailsDialog,
    setOpenPaymentDetailsDialog,
    handleCloseInvoiceDetails,
    handleClosePaymentDetails,
  };
};

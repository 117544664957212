import { Button, Grid, Typography } from '@mui/material';
import React from 'react';

import { Box, useTheme } from '@mui/system';
import componentSamples from './Samples';

const MuiComponentSamples = () => {
  const theme = useTheme();

  const classes = {
    sampleItem: {
      margin: 'auto',
      marginBottom: theme.spacing(10),
      width: '100%',
      maxWidth: 1000,
      paddingLeft: theme.spacing(4),
    },
    inset: {},
    docsButton: {
      marginLeft: theme.spacing(2),
    },
    sampleContainer: {
      maxWidth: 1000,
      padding: theme.spacing(),
      margin: 'auto',
    },
  };

  return (
    <Box sx={classes.sampleContainer}>
      <Typography variant="h4" gutterBottom>
        Material-UI Components
      </Typography>
      {componentSamples.map(({ id, title, component, docs }) => (
        <div key={id} id={id}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
            <Button variant="outlined" color="secondary" size="small" href={docs} target="_blank" rel="noreferrer">
              Docs
            </Button>
          </Grid>
          <Box sx={classes.sampleItem}>{component}</Box>
        </div>
      ))}
    </Box>
  );
};

export default MuiComponentSamples;

import { useAppDispatch } from 'app/config/store';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMediasStyleForm } from './useMediasStyleForm';

export const useElementStyleForm = (element, action, payload = data => data) => {
  const dispatch = useAppDispatch();

  const methods = useForm({
    // mode: 'onChange',
    // reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: { ...element },
    values: element,
  });

  const { control, watch, handleSubmit } = methods;
  const { style } = useMediasStyleForm(control);

  useEffect(() => {
    let timer: number;
    const subscription = watch(() => {
      handleSubmit(data => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(action(payload(data)));
        }, 1000);
      })();
    });
    return () => {
      subscription.unsubscribe();
      clearTimeout(timer);
    };
  }, [watch]);

  return {
    methods,
    style,
  };
};

import React from 'react';

import { GroupedListLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { ClickStreamConfig } from './accordion-items/ClickStreamConfig';
import { CatalogManagement } from './accordion-items/CatalogManagement';
import { ExploreStrategies } from './accordion-items/ExploreStrategies';

export const RecommendationsAccordion = () => {
  return (
    <GroupedListLayout>
      <ClickStreamConfig />
      <CatalogManagement />
      <ExploreStrategies />
    </GroupedListLayout>
  );
};

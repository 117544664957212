import { useAppDispatch, useAppSelector } from 'app/config/store';
import { accountApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { catalogApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/catalog';
import { paymentMethodApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/payment-method';
import { useCreateSubscriptionUsingPostMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/subscription';
import { userProfilesApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';
import { setAppLoading, toggleAppStarting } from 'app/shared/reducers/appState';
import { useEffect } from 'react';

export const useAppStarting = () => {
  const { appLoading, appStarting } = useAppSelector(state => state.appState);

  const { loading: authenticationLoading } = useAppSelector(state => state.authentication);
  // TODO loading creating subscription should be handled at the action of creating the subscription
  // TODO add paymentMethodLoading when setting a default
  const dispatch = useAppDispatch();

  const { data: userProfile, isFetching: userProfileLoading } = userProfilesApi.endpoints.getCurrentUserProfileUsingGet.useQueryState({});
  const { isFetching: billingAccountLoading } = accountApi.endpoints.getAccountByKeyUsingGet.useQueryState({
    accountKey: userProfile ? userProfile.currentAccount : null,
  });
  const { data: accountProfile = {}, isFetching: accountProfileLoading } =
    accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { isFetching: subscriptionlLoading } = accountApi.endpoints.getAccountBundlesUsingGet.useQueryState({
    accountKey: accountProfile.accountId,
  });
  const { isFetching: catalogLoading } = catalogApi.endpoints.getCatalogJsonUsingGet.useQueryState();
  const { isFetching: paymentMethodLoading } = paymentMethodApi.endpoints.getPaymentMethodsUsingGet.useQueryState({
    accountKey: accountProfile.accountId,
    withPluginInfo: true,
  });
  const [createSubscription, { isLoading: createSubscriptionLoading }] = useCreateSubscriptionUsingPostMutation({
    fixedCacheKey: 'shared-create-subscription-post',
  });

  useEffect(() => {
    dispatch(
      setAppLoading(
        authenticationLoading ||
          userProfileLoading ||
          accountProfileLoading ||
          subscriptionlLoading ||
          billingAccountLoading ||
          catalogLoading ||
          paymentMethodLoading ||
          createSubscriptionLoading
      )
    );
  }, [
    authenticationLoading ||
      userProfileLoading ||
      accountProfileLoading ||
      subscriptionlLoading ||
      billingAccountLoading ||
      catalogLoading ||
      paymentMethodLoading ||
      createSubscriptionLoading,
  ]);

  // useEffect is useless here ?
  useEffect(() => {
    if (appStarting && !appLoading) {
      dispatch(toggleAppStarting());
    }
  }, [appLoading]);
};

import React from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { getAlphaColor } from 'app/shared/util/color-palette-calculator';
import { Box, Container, Typography } from '@mui/material';
import { Translate } from 'react-jhipster';
import { variables } from '../LandingPage';
import { ProductSlider } from './components/ProductSlider';
import { ScheduleDemoButton } from './components/ScheduleDemoButton';

export const ProductList = () => {
  return (
    <LayoutItem
      cardOptions={{
        variant: 'div',
        card: {
          id: 'ProductList',
          sx: { backgroundColor: theme => getAlphaColor(theme.palette.background.paper, 0.4), py: '10vh' },
        },
        header: {
          disableTypography: true,
          title: (
            <Typography fontSize="2.2rem" textAlign="center">
              <Translate contentKey={'landing.products.title'} interpolate={{ ...variables }}>
                Our products
              </Translate>
            </Typography>
          ),
          sx: {
            pb: '5vh',
          },
        },
        actions: <ScheduleDemoButton />,
        actionsProps: { sx: { justifyContent: 'center', p: 0 } },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <Container maxWidth="xl">
        <ProductSlider />
      </Container>
    </LayoutItem>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { validationUtils } from 'app/modules/form-builder-lib';
import { generateCSVFile } from 'app/shared/reducers/web-analytics/tools/export/generate';
import { getListCSVFiles } from 'app/shared/reducers/web-analytics/tools/export/list';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { getFormFields } from '../components/form/formFields';

export const useExportForm = () => {
  const { loading } = useAppSelector(state => state.webAnalyticsOverview.exportCSV.generateCSV);
  const dispatch = useAppDispatch();
  const [fileDownload, setFileDownload] = useState(null);
  const schema = validationUtils.getFormSchema(getFormFields());
  const defaultValue = {
    'preset-ranges': 'customPreset',
    startDate: '',
    endDate: '',
    filename: 'webevents',
  } as FieldValues;
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });
  const onSubmit = methods.handleSubmit((data: { 'preset-ranges': string; startDate: string; endDate: string; filename: string }) => {
    dispatch(generateCSVFile({ from: data?.startDate, to: data?.endDate, filename: data?.filename })).then(res => {
      setFileDownload(res.payload.fileName);
      dispatch(getListCSVFiles());
    });
  });
  return {
    loading,
    methods,
    defaultValue,
    onSubmit,
    fileDownload,
    setFileDownload,
  };
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../../reducer.utils';
import dayjs, { Dayjs } from 'dayjs';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';

type TokenProps = {
  token: string;
  name: string;
  created: string;
  expires: string;
};

export const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  accessToken: null as unknown as TokenProps,
  accessTokenList: [] as TokenProps[],
  succeededToken: false,
  token: null,
  error: null,
};

export type AccessTokenState = Readonly<typeof initialState>;

// Actions

export const createAccessToken = createAsyncThunk(
  'accessToken/createAccessToken',
  async ({ name, expires }: { name: string; expires: string }) =>
    microserviceHttpClient(`${CONFIG_MANAGER_API_URL}/account-profiles/create-api-key`, 'post', { username: name }),
  {
    serializeError: serializeAxiosError,
  }
);

export const getAccessTokens = createAsyncThunk(
  'accessToken/getAccessTokens',
  async () => microserviceHttpClient(`${CONFIG_MANAGER_API_URL}/account-profiles/keys`, 'get'),
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const accessTokenSlice = createSlice({
  name: 'accessToken',
  initialState: initialState as AccessTokenState,
  reducers: {
    resetSucceededToken(state) {
      state.succeededToken = false;
    },
    deleteToken(state, { payload }) {
      state.accessTokenList = state.accessTokenList.filter(acces => acces.token !== payload);
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    clearToken(state) {
      state.token = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createAccessToken.pending, state => {
        state.loading = true;
        state.errorMessage = null;
        state.succeededToken = false;
      })
      .addCase(createAccessToken.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = getTokenProps(action.payload.data);
        state.succeededToken = true;
        state.accessTokenList = [...state.accessTokenList, state.accessToken];
      })
      .addCase(createAccessToken.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.accessToken = null as unknown as {
          token: string;
          name: string;
          created: string;
          expires: string;
        };
        state.succeededToken = false;
      })
      .addCase(getAccessTokens.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getAccessTokens.fulfilled, (state, action) => {
        state.loading = false;
        state.accessTokenList = getArrayTokenProps(action.payload.data);
      })
      .addCase(getAccessTokens.rejected, (state, action) => {
        state.loading = false;
        state.accessTokenList = [] as TokenProps[];
        state.errorMessage = action.error.message;
      });
  },
});

function getTokenProps({ key, name, created, expires }): TokenProps {
  const expiresDate: Dayjs = Number(expires) ? dayjs(Number(expires)) : dayjs('invalid');
  return {
    token: key,
    name: name.startsWith('sa-') && name.length > 40 ? name.substring(40) : name,
    created: dayjs(Number(created)).format('lll'),
    expires: expiresDate.isValid() ? expiresDate.format('lll') : 'Never',
  };
}

export function getArrayTokenProps({ keys = [] }) {
  return keys.map(({ name, creation_timestamp }) => getTokenProps({ key: name, name, created: creation_timestamp, expires: undefined }));
}

export const { resetSucceededToken, deleteToken, setToken, clearToken, setError, clearError } = accessTokenSlice.actions;

// Reducer
export default accessTokenSlice.reducer;

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAccountBundlesUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useGetCatalogJsonUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/catalog';

export const useFetchCatalogSubscriptions = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  useGetAccountBundlesUsingGetQuery(accountProfile.accountId ? { accountKey: accountProfile.accountId } : skipToken);
  useGetCatalogJsonUsingGetQuery();
};

import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

export const SessionDevice = ({ device }) => {
  let icon;

  if (String(device) === 'Personal computer') icon = <LaptopWindowsIcon titleAccess="Personal computer" sx={{ color: 'black' }} />;
  else if (String(device) === 'Smartphone') icon = <PhoneIphoneIcon titleAccess="Smartphone" sx={{ color: 'black' }} />;

  return (
    <Box display="flex" alignItems="center" justifyContent="end">
      {icon}
      <Typography>{device}</Typography>
    </Box>
  );
};

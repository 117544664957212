import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import { default as MuiFab } from '@mui/material/Fab';
import { styled, useTheme } from '@mui/system';
import React from 'react';

export default function FabExample() {
  const theme = useTheme();

  const classes = {
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  };

  const Fab = styled(MuiFab)({ margin: theme.spacing(1) });

  return (
    <div>
      <Fab color="primary" aria-label="add">
        <AddIcon />
      </Fab>
      <Fab color="secondary" aria-label="edit">
        <EditIcon />
      </Fab>
      <Fab variant="extended">
        <NavigationIcon sx={classes.extendedIcon} />
        Navigate
      </Fab>
      <Fab disabled aria-label="like">
        <FavoriteIcon />
      </Fab>
    </div>
  );
}

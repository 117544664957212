import React from 'react';

import { Translate } from 'react-jhipster';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { FormBuilder } from 'app/modules/form-builder-lib';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getFormFields } from './formFields';
import { useExportForm } from '../../hooks/useExportForm';

export const ExportForm = () => {
  const { loading, methods, defaultValue, onSubmit } = useExportForm();

  return (
    <BasicCardLayout
      header={{
        title: <FormBuilder fields={getFormFields(methods)} defaultValue={defaultValue} methods={methods} />,
        action: (
          <LoadingButton
            startIcon={<FileDownloadIcon />}
            variant="contained"
            color="primary"
            loading={loading}
            loadingPosition="start"
            onClick={() => {
              onSubmit();
            }}
          >
            <Translate contentKey="web-analytics.export.export">Export</Translate>
          </LoadingButton>
        ),
        sx: { '.MuiCardHeader-action': { alignSelf: 'auto' } },
      }}
    />
  );
};

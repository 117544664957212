import { AggregateImpressionsApiArg, useAggregateImpressionsQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { getIntervalFromStep } from '../../utils/actions-types';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const useAggregateImpressionsData = (timerange, requestParams, accountProfile, rest = {}) => {
  const { fromDate: from, toDate: to, refreshRate } = timerange;
  const step = timerange.getStep();

  return useAggregateImpressionsQuery(
    accountProfile.accountId
      ? ({
          accountId: accountProfile.accountId.substring(0, 8),
          corpusId: requestParams?.corpusId,
          from,
          to,
          interval: getIntervalFromStep(step),
          ...rest,
        } as AggregateImpressionsApiArg)
      : skipToken,
    { pollingInterval: refreshRate }
  );
};

import { useState } from 'react';

import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';
import { useCancelSubscriptionUsingDeleteMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/subscription';

export const useProductMenu = productName => {
  const { subscriptionsList } = useSubscriptionsStates();
  const [deleteSubscription] = useCancelSubscriptionUsingDeleteMutation();
  function getSubscriptionId() {
    const subscription = subscriptionsList.filter(el => el.state === 'ACTIVE' && el.productName === productName);
    return subscription[0]?.subscriptionId;
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCancel = () => {
    deleteSubscription({ subscriptionId: getSubscriptionId() });
    setAnchorEl(null);
  };

  return {
    anchorEl,
    open,
    handleCancel,
    handleClick,
    handleClose,
  };
};

import { dynamicStoreManager, useAppDispatch, useAppSelector } from 'app/config/store';
import { useCallback } from 'react';
import { UseFormProps, useForm } from 'react-hook-form';
import { useFormSlice } from './useFormSlice';

export const usePersistedForm = (storeKey: string, initialState: unknown = {}, formProps: Partial<UseFormProps> = {}) => {
  // Redux Dynamic Reducers configuration
  const formSlice = useFormSlice(storeKey, initialState);
  const selectFormState = dynamicStoreManager.selectSliceState(storeKey);
  const formState = useAppSelector(selectFormState);

  // React Hook Form configuration
  const methods = useForm({
    ...formProps,
    defaultValues: formSlice.getInitialState(),
    values: formState,
  });

  // Definition of callbacks
  const dispatch = useAppDispatch();
  const { handleSubmit } = methods;
  const { setFormData } = formSlice.actions;

  const onSubmitHandler = useCallback(() => {
    handleSubmit(data => dispatch(setFormData(data)))();
  }, [handleSubmit, setFormData, dispatch]);

  return {
    form: {
      methods,
      onSubmitHandler,
    },
    actions: {
      ...formSlice.actions,
    },
    selector: selectFormState as any,
    state: formState,
  };
};

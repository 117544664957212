import humanNumber from 'human-number';

export const STERMS_COUNT_BY_TYPE = 'sterms#countByType';
export const DATE_HISTOGRAM = 'date_histogram#0';

const filterActions = actionsList => {
  return b => actionsList.includes(b.key);
};

const filterOthersAction = requestParams => {
  return b => !requestParams.demonstrationOfInterestActions.includes(b.key) && !requestParams.conversionActions.includes(b.key);
};

const reduceDocsCount = (result, bucket) => {
  return result + Number(bucket.doc_count);
};

const filterAndReduceActions = (data, actionsList) => {
  return data?.filter(filterActions(actionsList)).reduce(reduceDocsCount, 0);
};

const filterAndReduceOtherActions = (data, requestParams) => {
  return data?.filter(filterOthersAction(requestParams)).reduce(reduceDocsCount, 0);
};

const getTimeValue = (data, getValue) => {
  return data?.map(bucket => ({
    time: bucket.key_as_string,
    value: getValue(bucket),
  }));
};

export const getActionsDataObject = (data, requestParams) => {
  return {
    actionBuckets: data?.aggregations[STERMS_COUNT_BY_TYPE]?.buckets,
    total: {
      all: data?.aggregations[STERMS_COUNT_BY_TYPE]?.buckets.reduce(reduceDocsCount, 0),
      demonstrationOfInterest: filterAndReduceActions(
        data?.aggregations[STERMS_COUNT_BY_TYPE]?.buckets,
        requestParams.demonstrationOfInterestActions
      ),
      conversion: filterAndReduceActions(data?.aggregations[STERMS_COUNT_BY_TYPE]?.buckets, requestParams.conversionActions),
      other: filterAndReduceOtherActions(data?.aggregations[STERMS_COUNT_BY_TYPE]?.buckets, requestParams),
    },
    // byType: data?.aggregations[DATE_HISTOGRAM]?.buckets,
    timeseries: {
      all: getTimeValue(data?.aggregations[DATE_HISTOGRAM]?.buckets, b => b.doc_count),
      byType: getTimeValue(data?.aggregations[DATE_HISTOGRAM]?.buckets, b => b[STERMS_COUNT_BY_TYPE]?.buckets),
      demonstrationOfInterest: getTimeValue(data?.aggregations[DATE_HISTOGRAM]?.buckets, b =>
        filterAndReduceActions(b[STERMS_COUNT_BY_TYPE]?.buckets, requestParams.demonstrationOfInterestActions)
      ),
      conversion: getTimeValue(data?.aggregations[DATE_HISTOGRAM]?.buckets, b =>
        filterAndReduceActions(b[STERMS_COUNT_BY_TYPE]?.buckets, requestParams.conversionActions)
      ),
      other: getTimeValue(data?.aggregations[DATE_HISTOGRAM]?.buckets, b =>
        filterAndReduceOtherActions(b[STERMS_COUNT_BY_TYPE]?.buckets, requestParams)
      ),
    },
  };
};

export const getSunburstChartData = data => {
  return data.actionBuckets && data.withRecoId.actionBuckets && data.withoutRecoId.actionBuckets
    ? data.actionBuckets
        // .sort((a, b) => a.doc_count < b.doc_count)
        .map(e => ({
          name: e.key,
          value: e.doc_count,
          children: [
            {
              name: `${e.key} (with reco id)`,
              value: data.withRecoId.actionBuckets.find(b => b.key === e.key)
                ? data.withRecoId.actionBuckets.find(b => b.key === e.key).doc_count
                : 0,
            },
            {
              name: `${e.key} (without reco id)`,
              value: data.withoutRecoId.actionBuckets.find(b => b.key === e.key)
                ? data.withoutRecoId.actionBuckets.find(b => b.key === e.key).doc_count
                : 0,
            },
          ],
        }))
    : [];
};

export const getRecommendationsToActionsChartData = actions => {
  if (actions.withRecoId)
    return {
      demonstrationOfInterest: {
        name: 'Demonstration Of Interest Actions',
        value: [actions.withRecoId.total.demonstrationOfInterest, actions.withoutRecoId.total.demonstrationOfInterest],
        total: humanNumber(actions.total.demonstrationOfInterest, n => Number.parseFloat(n).toFixed(1)),
        percentage: humanNumber((actions.withRecoId.total.demonstrationOfInterest * 100) / actions.total.demonstrationOfInterest, n =>
          Number.parseFloat(n).toFixed(1)
        ),
      },
      conversion: {
        name: 'Conversions Actions',
        value: [actions.withRecoId.total.conversion, actions.withoutRecoId.total.conversion],
        total: humanNumber(actions.total.conversion, n => Number.parseFloat(n).toFixed(1)),
        percentage: humanNumber((actions.withRecoId.total.conversion * 100) / actions.total.conversion, n =>
          Number.parseFloat(n).toFixed(1)
        ),
      },
    };
};

import React from 'react';

import clsx from 'clsx';
import { Box } from '@mui/system';
import { translate } from 'react-jhipster';
import { Typography, TypographyProps } from '@mui/material';
import { getCode, getName } from 'country-list';

export const Flag = ({
  country,
  width = '25px',
  height = '20px',
  squared = false,
  tooltip = false,
  sx = { margin: '5px' },
}: {
  country: string;
  width?: any;
  height?: any;
  squared?: boolean;
  tooltip?: boolean;
  sx?: any;
}) => {
  const countryName = getName(country) || country;
  const countryCode = getCode(country) || country;

  return (
    <span
      className={clsx('fi', `fi-${countryCode.toLowerCase()}`, squared && 'fis')}
      title={tooltip ? countryName : undefined}
      style={{ width, height, ...sx }}
    />
  );
};

export const FlagWithCountryName = ({
  country,
  width = '25px',
  height = '20px',
  squared = false,
  sxFlag = { margin: '5px' },
  sxContainer = {},
  typographyProps = {},
  ...rest
}: {
  country: string;
  width?: any;
  height?: any;
  squared?: boolean;
  sxFlag?: any;
  sxContainer?: any;
  typographyProps?: TypographyProps;
}) => {
  const countryName = getName(country) || country;
  const countryCode = getCode(country) || country;

  return (
    <Box display="flex" alignItems="center" {...rest} sx={...sxContainer}>
      <Flag country={country} height={height} width={width} squared={squared} sx={...sxFlag} />
      <Typography {...typographyProps}>{translate(`countries.${countryCode}`, null, countryName)}</Typography>
    </Box>
  );
};

import React, { useContext } from 'react';

import { Stack, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import StartIcon from '@mui/icons-material/Start';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { generatePath } from 'react-router-dom';
import { patterns } from 'app/shared/util/url-utils';
import { Translate } from 'react-jhipster';
import { useProductCardButtons } from '../../hooks/product-card/useProductCardButtons';
import { Product } from 'app/shared/model/catalog.model';
import { ProductContext, ProductContextType } from '../../context/ProductContext';

export const ProductButtons = ({ product }: { product: Product }) => {
  const bundleId = useProductCardButtons(product.name);
  const { setProduct } = useContext<ProductContextType>(ProductContext);

  return (
    <Stack direction="row" alignItems="center" mt={1}>
      {bundleId ? (
        <>
          <Button variant="outlined" startIcon={<UpgradeIcon />} onClick={() => setProduct(product)}>
            <Translate contentKey="products.upgrade">Upgrade</Translate>
          </Button>
          <Button
            variant="outlined"
            startIcon={<SettingsIcon />}
            href={generatePath(patterns.product.settings, { productName: product.name })}
          >
            <Translate contentKey="products.settings">Settings</Translate>
          </Button>
        </>
      ) : (
        <Button variant="outlined" endIcon={<StartIcon />} onClick={() => setProduct(product)}>
          <Translate contentKey="products.start-now">Start now</Translate>
        </Button>
      )}
    </Stack>
  );
};

import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Slide, Typography } from '@mui/material';
import { Language, Logout, UserCircle } from 'tabler-icons-react';
import React from 'react';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import StoreIcon from '@mui/icons-material/Store';
import { translate } from 'react-jhipster';
import { languages } from 'app/config/translation';
import { documentationUrl, sitemap } from 'app/shared/util/url-utils';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'app/config/store';
import { AccountStatus } from 'app/shared/reducers/account-profiles/account-profile';

export const ProfileMainMenu = ({ handleClose, setActiveMenu, activeMenu, containerRef, OnMenuEnter, openPopper }) => {
  const { accountProfile } = useAppSelector(state => state.accountProfile.accountProfileReducer);

  return (
    <Slide
      in={activeMenu === 'main' && openPopper}
      hidden={activeMenu !== 'main'}
      direction="right"
      container={containerRef.current}
      appear={false}
      timeout={500}
      onEnter={OnMenuEnter}
    >
      <List
        component="nav"
        sx={{
          width: '222px',
          maxWidth: '350px',
          paddingLeft: '0px !important',
          borderRadius: '10px',
        }}
      >
        {accountProfile.status !== AccountStatus.BOOTSTRAP && (
          <>
            <Divider />
            <ListItemButton component={Link} to={sitemap.account} onClick={handleClose} style={{ textDecoration: 'none' }}>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2">{translate('profile-section.account-details')}</Typography>} />
            </ListItemButton>
            <ListItemButton component={Link} to={sitemap.user} onClick={handleClose} style={{ textDecoration: 'none' }}>
              <ListItemIcon>
                <UserCircle />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2">{translate('profile-section.user-profile')}</Typography>} />
            </ListItemButton>
          </>
        )}
        {Object.keys(languages).length > 1 && (
          <>
            <Divider />
            <ListItemButton
              onClick={() => {
                setActiveMenu('language');
              }}
            >
              <ListItemIcon>
                <Language />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2">{translate('profile-section.choose-language')}</Typography>} />
            </ListItemButton>
          </>
        )}
        <Divider />

        <ListItemButton
          component={Link}
          to={documentationUrl(sitemap.ext.docs.root)}
          target="_blank"
          onClick={handleClose}
          style={{ textDecoration: 'none' }}
        >
          <ListItemIcon>
            <ArticleOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">{translate('profile-section.documentation')}</Typography>} />
        </ListItemButton>
        <Divider />
        <ListItemButton component={Link} to={sitemap.logout} onClick={handleClose} style={{ textDecoration: 'none' }}>
          <ListItemIcon>
            <Logout stroke={'1.5'} size="1.3rem" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">{translate('profile-section.logout')}</Typography>} />
        </ListItemButton>
      </List>
    </Slide>
  );
};

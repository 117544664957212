import { clearError, clearToken, setError, setToken } from 'app/shared/reducers/account-profiles/access-token';
import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';
import { enqueueSnackbar } from 'notistack';
import { translate } from 'react-jhipster';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    createApiTokenUsingPost: build.mutation<CreateApiTokenUsingPostApiResponse, CreateApiTokenUsingPostApiArg>({
      query: queryArg => ({ url: `/api/v1/account-profiles/create-api-key`, method: 'POST', body: queryArg.apiKeyRequestBom }),
      invalidatesTags: ['API_KEYS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.key));
          // dispatch(clearError());
          enqueueSnackbar(translate('access-token-created-successfully', null, 'access token created successfully !'));
        } catch (err) {
          // dispatch(setError(err.error));
          enqueueSnackbar(
            `Async error! : ${translate(
              'account.access.token.failed-to-create-access-token',
              null,
              'failed to create a new access token '
            )}`,
            {
              variant: 'collapsible',
              error: err.error,
            }
          );
          dispatch(clearToken());
        }
      },
    }),
    getCurrentAccountProfileUsingGet: build.query<GetCurrentAccountProfileUsingGetApiResponse, GetCurrentAccountProfileUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/account-profiles/current`, params: { createIfMissing: queryArg.createIfMissing } }),
      providesTags: ['ACCOUNT_PROFILE'],
    }),
    getCurrentAccountBundlesUsingGet: build.query<GetCurrentAccountBundlesUsingGetApiResponse, GetCurrentAccountBundlesUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/account-profiles/current/bundles`,
        params: { includeSubscriptionDetails: queryArg.includeSubscriptionDetails },
      }),
    }),
    inviteMemberUsingPost: build.mutation<InviteMemberUsingPostApiResponse, InviteMemberUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/account-profiles/current/inviteMember`,
        method: 'POST',
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
          userEmail: queryArg.userEmail,
          roles: queryArg.roles,
        },
      }),
      invalidatesTags: ['MEMBERS'],
    }),
    getCurrentAccountMembersUsingGet: build.query<GetCurrentAccountMembersUsingGetApiResponse, GetCurrentAccountMembersUsingGetApiArg>({
      query: () => ({ url: `/api/v1/account-profiles/current/members` }),
      providesTags: ['MEMBERS', 'ACCOUNT_PROFILE'],
    }),
    setCurrentAccountMemberRolesUsingPut: build.mutation<
      SetCurrentAccountMemberRolesUsingPutApiResponse,
      SetCurrentAccountMemberRolesUsingPutApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/account-profiles/current/members/${queryArg.userEmail}/roles`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    updateAccountProfileStatusUsingPost: build.mutation<
      UpdateAccountProfileStatusUsingPostApiResponse,
      UpdateAccountProfileStatusUsingPostApiArg
    >({
      query: () => ({ url: `/api/v1/account-profiles/current/update-status`, method: 'POST' }),
      invalidatesTags: ['ACCOUNT_PROFILE', 'ACCOUNT_LIST'],
    }),
    listKeysUsingGet: build.query<ListKeysUsingGetApiResponse, ListKeysUsingGetApiArg>({
      query: () => ({ url: `/api/v1/account-profiles/keys` }),
      providesTags: ['API_KEYS_LIST'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as accountProfileApi };
export type CreateApiTokenUsingPostApiResponse = /** status 200 OK */ ApiKeyResponseBom | /** status 201 Created */ undefined;
export type CreateApiTokenUsingPostApiArg = {
  apiKeyRequestBom: ApiKeyRequestBom;
};
export type GetCurrentAccountProfileUsingGetApiResponse = /** status 200 OK */ AccountProfileDto;
export type GetCurrentAccountProfileUsingGetApiArg = {
  /** createIfMissing */
  createIfMissing?: boolean;
};
export type GetCurrentAccountBundlesUsingGetApiResponse = /** status 200 OK */ BundleDto[];
export type GetCurrentAccountBundlesUsingGetApiArg = {
  /** includeSubscriptionDetails */
  includeSubscriptionDetails?: boolean;
};
export type InviteMemberUsingPostApiResponse = unknown;
export type InviteMemberUsingPostApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** userEmail */
  userEmail: string;
  /** roles */
  roles?: 'ADMIN' | 'CONTRIBUTOR' | 'GUEST' | 'HURENCE_ADMIN';
};
export type GetCurrentAccountMembersUsingGetApiResponse = /** status 200 OK */ AccountMemberDto[];
export type GetCurrentAccountMembersUsingGetApiArg = void;
export type SetCurrentAccountMemberRolesUsingPutApiResponse = unknown;
export type SetCurrentAccountMemberRolesUsingPutApiArg = {
  /** userEmail */
  userEmail: string;
  body: string[];
};
export type UpdateAccountProfileStatusUsingPostApiResponse = /** status 200 OK */ AccountProfileDto | /** status 201 Created */ undefined;
export type UpdateAccountProfileStatusUsingPostApiArg = void;
export type ListKeysUsingGetApiResponse = /** status 200 OK */ KeyListWithAccount;
export type ListKeysUsingGetApiArg = void;
export type ApiKeyResponseBom = {
  created?: string;
  expires?: string;
  key?: string;
  name?: string;
};
export type ApiKeyRequestBom = {
  attributes?: {
    [key: string]: string[];
  };
  email?: string;
  roles?: string[];
  username: string;
};
export type AuditingEntityDto = {
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
};
export type AccountProfileDto = {
  accountId?: string;
  auditInfo?: AuditingEntityDto;
  name?: string;
  status?:
    | 'ACTIVE'
    | 'BOOTSTRAP'
    | 'KB_EXCEPTION'
    | 'MISSING_ADDRESS'
    | 'MISSING_CONTACT'
    | 'MISSING_DETAILS'
    | 'MISSING_KB_ACCOUNT'
    | 'MISSING_PAYMENT_METHOD'
    | 'READY';
};
export type AuditLog = {
  changeDate?: string;
  changeType?: string;
  changedBy?: string;
  comments?: string;
  history?: object;
  objectId?: string;
  objectType?:
    | 'ACCOUNT'
    | 'ACCOUNT_EMAIL'
    | 'BLOCKING_STATES'
    | 'BUNDLE'
    | 'CUSTOM_FIELD'
    | 'INVOICE'
    | 'INVOICE_ITEM'
    | 'INVOICE_PAYMENT'
    | 'PAYMENT'
    | 'PAYMENT_ATTEMPT'
    | 'PAYMENT_METHOD'
    | 'SERVICE_BROADCAST'
    | 'SUBSCRIPTION'
    | 'SUBSCRIPTION_EVENT'
    | 'TAG'
    | 'TAG_DEFINITION'
    | 'TENANT'
    | 'TENANT_KVS'
    | 'TRANSACTION';
  reasonCode?: string;
  userToken?: string;
};
export type EventSubscription = {
  auditLogs?: AuditLog[];
  billingPeriod?:
    | 'ANNUAL'
    | 'BIANNUAL'
    | 'BIENNIAL'
    | 'BIMESTRIAL'
    | 'BIWEEKLY'
    | 'DAILY'
    | 'MONTHLY'
    | 'NINETY_DAYS'
    | 'NO_BILLING_PERIOD'
    | 'QUARTERLY'
    | 'SIXTY_DAYS'
    | 'THIRTY_DAYS'
    | 'TRIANNUAL'
    | 'WEEKLY';
  effectiveDate?: string;
  eventId?: string;
  eventType?:
    | 'CHANGE'
    | 'PAUSE_BILLING'
    | 'PAUSE_ENTITLEMENT'
    | 'PHASE'
    | 'RESUME_BILLING'
    | 'RESUME_ENTITLEMENT'
    | 'SERVICE_STATE_CHANGE'
    | 'START_BILLING'
    | 'START_ENTITLEMENT'
    | 'STOP_BILLING'
    | 'STOP_ENTITLEMENT';
  isBlockedBilling?: boolean;
  isBlockedEntitlement?: boolean;
  phase?: string;
  plan?: string;
  priceList?: string;
  product?: string;
  serviceName?: string;
  serviceStateName?: string;
};
export type BlockPrice = {
  max?: number;
  price?: number;
  size?: number;
  unitName?: string;
};
export type TierPrice = {
  blockPrices?: BlockPrice[];
};
export type UsagePrice = {
  billingMode?: 'IN_ADVANCE' | 'IN_ARREAR';
  tierBlockPolicy?: 'ALL_TIERS' | 'TOP_TIER';
  tierPrices?: TierPrice[];
  usageName?: string;
  usageType?: 'CAPACITY' | 'CONSUMABLE';
};
export type PhasePrice = {
  fixedPrice?: number;
  phaseName?: string;
  phaseType?: string;
  planName?: string;
  recurringPrice?: number;
  usagePrices?: UsagePrice[];
};
export type SubscriptionDto = {
  accountExternalKey?: string;
  auditLogs?: AuditLog[];
  billCycleDayLocal?: number;
  billingEndDate?: string;
  billingPeriod?:
    | 'ANNUAL'
    | 'BIANNUAL'
    | 'BIENNIAL'
    | 'BIMESTRIAL'
    | 'BIWEEKLY'
    | 'DAILY'
    | 'MONTHLY'
    | 'NINETY_DAYS'
    | 'NO_BILLING_PERIOD'
    | 'QUARTERLY'
    | 'SIXTY_DAYS'
    | 'THIRTY_DAYS'
    | 'TRIANNUAL'
    | 'WEEKLY';
  billingStartDate?: string;
  bundleExternalKey?: string;
  bundleId?: string;
  cancelledDate?: string;
  chargedThroughDate?: string;
  events?: EventSubscription[];
  externalKey?: string;
  phaseType?: 'DISCOUNT' | 'EVERGREEN' | 'FIXEDTERM' | 'TRIAL';
  planName?: string;
  priceList?: string;
  priceOverrides?: PhasePrice[];
  prices?: PhasePrice[];
  productCategory?: 'ADD_ON' | 'BASE' | 'STANDALONE';
  productName?: string;
  sourceType?: 'MIGRATED' | 'NATIVE' | 'TRANSFERRED';
  startDate?: string;
  state?: 'ACTIVE' | 'BLOCKED' | 'CANCELLED' | 'EXPIRED' | 'PENDING';
  subscriptionId?: string;
};
export type BundleDto = {
  accountId?: string;
  auditInfo?: AuditingEntityDto;
  bundleId: string;
  settings?: {
    [key: string]: object;
  };
  subscriptions?: SubscriptionDto[];
};
export type AccountMemberDto = {
  auditInfo?: AuditingEntityDto;
  email?: string;
  firstName?: string;
  lastName?: string;
  roles?: ('ADMIN' | 'CONTRIBUTOR' | 'GUEST' | 'HURENCE_ADMIN')[];
  status?: 'ACTIVE' | 'BLOCKED' | 'DISABLED' | 'INVITED';
};
export type KeyInfo = {
  creation_timestamp?: number;
  name?: string;
};
export type KeyListWithAccount = {
  accountId?: string;
  keys?: KeyInfo[];
};
export const {
  useCreateApiTokenUsingPostMutation,
  useGetCurrentAccountProfileUsingGetQuery,
  useLazyGetCurrentAccountProfileUsingGetQuery,
  useGetCurrentAccountBundlesUsingGetQuery,
  useInviteMemberUsingPostMutation,
  useGetCurrentAccountMembersUsingGetQuery,
  useLazyGetCurrentAccountMembersUsingGetQuery,
  useSetCurrentAccountMemberRolesUsingPutMutation,
  useUpdateAccountProfileStatusUsingPostMutation,
  useListKeysUsingGetQuery,
} = injectedRtkApi;

export const demonstrationOfInterestActions = [
  'PAGE_SCROLL',
  'SHARE',
  'DOWNLOAD_ABSTRACT',
  'COMMENT',
  'PAGEVIEW',
  'CLICK_SLIDES',
  'VIEW',
  'FAVORITE',
  'LIKE',
  'CLICK_NAV',
  'VIDEO_ENGAGEMENT',
  'ML_RECOMMENDED',
  'SEARCH',
  'VIDEO_PLAY_REQUEST',
  'BOOKMARK_REMOVE',
];
export const conversionActions = ['BUY', 'BASKET'];
export const otherActions = ['UNKNOWN'];

export const getIntervalFromStep = step => {
  let interval = undefined;
  switch (step) {
    case 'PT1M':
      interval = '1m';
      break;
    case 'PT1H':
      interval = '1h';
      break;
    case 'P1D':
      interval = '1d';
      break;
    default:
      break;
  }
  return interval;
};

import React, { ComponentType, FC } from 'react';

import { IRootState, useAppSelector } from 'app/config/store';
import { LoadingSkeleton } from './component/LoadingSkeleton';

const getDisplayName = (WrappedComponent: ComponentType<any>) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export const WithLoading = <P extends object>(
  Component: ComponentType<P>,
  loading?: ((state: IRootState) => boolean) | boolean,
  opts?: { loader?: () => JSX.Element }
): FC<P> => {
  const withLoading = props => {
    const isLoading: boolean =
      loading === undefined ? props?.loading : typeof loading === 'boolean' ? loading : useAppSelector<boolean>(loading);
    if (isLoading) {
      return opts?.loader ? opts.loader() : <LoadingSkeleton />;
    }
    return <Component {...props} />;
  };
  withLoading.displayName = `WithLoading(${getDisplayName(Component)})`;
  return withLoading;
};

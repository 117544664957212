import React, { useEffect } from 'react';

import { FormBuilder } from 'app/modules/form-builder-lib';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { QueryStats } from '@mui/icons-material';
import { Translate } from 'react-jhipster';
import { Button } from '@mui/material';
import { useRecoForm } from '../hooks/useRecoForm';

export const RecoStrategyForm = ({ handleGetRecos }) => {
  const { getFields, methods, corpusList, isSuccess } = useRecoForm();

  useEffect(() => {
    if (isSuccess) {
      handleGetRecos(methods.getValues());
    }
  }, [isSuccess]);
  return (
    <BasicCardLayout
      header={{
        title: isSuccess && (
          <FormBuilder
            fields={getFields(methods.watch(['strategy']))}
            defaultValue={{
              corpus: corpusList[0] ? corpusList[0] : '',
              strategy: 'random',
              profileId: '',
              itemId: '',
              trendingScale: 'trending_short',
            }}
            methods={methods}
            containerProps={{ marginY: 0.5, spacing: 2, paddingLeft: '10px' }}
          />
        ),
        action: (
          <Button
            disabled={!methods.formState.isValid}
            onClick={() => {
              methods.handleSubmit(data => {
                handleGetRecos(data);
              })();
            }}
            startIcon={<QueryStats />}
            variant="outlined"
          >
            <Translate contentKey="recommendation.buttons.apply">Apply</Translate>
          </Button>
        ),
        sx: { '.MuiCardHeader-action': { alignSelf: 'auto' } },
      }}
      card={{ sx: { width: '100%' } }}
    />
  );
};

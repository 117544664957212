import React from 'react';
import appRoutes from './appRoutes';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import { Box, Divider, List } from '@mui/material';
import { managementRoutes } from './managementRoutes';
import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';
import { ErrorComponent } from 'app/modules/loading-lib/component/ErrorComponent';

export const OpenSidebar = () => {
  const { isSubscriptionListError } = useSubscriptionsStates();
  return (
    <Box display="flex" flexDirection="column" flex={1} sx={{ overflowY: 'auto' }}>
      <List>
        {appRoutes().map((route, index) =>
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        )}
      </List>
      {isSubscriptionListError && <ErrorComponent error={{ data: { title: 'Failed to fetch products' } }} />}
      <Divider sx={{ mt: 2, mb: 1 }} />
      <Box flexGrow={1} />
      <List>{managementRoutes().map((route, index) => (route.sidebarProps ? <SidebarItem item={route} key={index} /> : null))}</List>
    </Box>
  );
};

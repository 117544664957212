import { Stack, StackProps, Link, LinkProps } from '@mui/material';
import React, { useState } from 'react';
import { Translate, translate } from 'react-jhipster';

export interface IShowMoreLessProps {
  openContent: React.ReactNode;
  closedContent?: React.ReactNode;
  contentPosition?: 'before' | 'after';
  openLabel?: string | React.ReactNode;
  closeLabel?: string | React.ReactNode;
  labelProps?: LinkProps;
  stackProps?: StackProps;
  initState?: boolean;
}

export const ShowMoreLess = ({
  openContent,
  closedContent = <></>,
  contentPosition = 'before',
  openLabel = 'show-more-less.show-more',
  closeLabel = 'show-more-less.show-less',
  labelProps,
  stackProps,
  initState = false,
}: IShowMoreLessProps) => {
  const [open, setOpen] = useState(initState);

  const labelFactory = label => {
    return typeof label === 'string' ? (
      <Link
        type="button"
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setOpen(!open);
        }}
        {...labelProps}
      >
        {translate(label, null, label)}
      </Link>
    ) : (
      <>{label}</>
    );
  };

  return (
    <Stack spacing={1} {...stackProps}>
      {contentPosition === 'before' && (open ? openContent : closedContent)}
      {open ? labelFactory(closeLabel) : labelFactory(openLabel)}
      {contentPosition === 'after' && (open ? openContent : closedContent)}
    </Stack>
  );
};

import React from 'react';

import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { Stack } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { FilesGrid } from './components/datagrid/FilesGrid';
import { ExportForm } from './components/form/ExportForm';

export const WebAnalyticsExport = () => {
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} paddingBottom={3}>
        <BreadcrumbGenerator />
      </Stack>
      <RawStackLayout>
        <ExportForm />
        <BasicCardLayout>
          <FilesGrid />
        </BasicCardLayout>
      </RawStackLayout>
    </>
  );
};

import { combineReducers } from 'redux';
import addActions from './addAction';
import bootstrapRatings from './bootstrapRatings';
import dumpUserProfiles from './dumpUserProfiles';
import health from './health';
import items from './items';
import profile from './profile';
import randomItems from './randomItems';
import randomUsers from './randomUsers';
import ratings from './ratings';
import recommendationsForItem from './by-item';
import recommendationsForUser from './by-user';
import statsPopularity from './statsPopularity';
import statsTrending from './statsTrending';
import userProfiles from './userProfiles';
import corpus from './corpus';
import uploadInventory from './upload-inventory';

const recommendations = combineReducers({
  corpus,
  addActions,
  // bootstrapRatings,
  // dumpUserProfiles,
  // health,
  items,
  profile,
  // randomItems,
  // randomUsers,
  // ratings,
  recommendationsForUser,
  recommendationsForItem,
  // statsPopularity,
  // statsTrending,
  // userProfiles,
  uploadInventory,
});

export default recommendations;

import React, { useState } from 'react';

import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { PIE_CHART_VIEW, RecommendationsCountToggleButton, TOP_N_VIEW } from './RecommendationsCountToggleButton';
import RecommendationsFunnelChart from './RecommendationsFunnelChart';
import RecommendationsRanking from './RecommendationsRanking';
import { Box } from '@mui/system';
import { RecommendationsTopNToggleButton } from './RecommendationsTopNToggleButton';
import { getImpressionsDataTopNToggler, getRecommendationsCountData } from '../utils/impressions-data-transformation';

export const RecommendationsCount = ({ data }) => {
  const [view, setView] = useState(PIE_CHART_VIEW);
  const [topNToggle, setTopNToggle] = useState(false);

  const recoView = {
    [PIE_CHART_VIEW]: (
      <RecommendationsFunnelChart
        data={getImpressionsDataTopNToggler(topNToggle, getRecommendationsCountData(data))}
        name={'Recommendations'}
        totalReco={data.impressions.total}
        isFetching={data.impressions.isFetching}
      />
    ),
    [TOP_N_VIEW]: (
      <RecommendationsRanking
        data={getImpressionsDataTopNToggler(topNToggle, getRecommendationsCountData(data))}
        total={data.impressions.total}
        isFetching={data.impressions.isFetching}
      />
    ),
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-around" width="40%">
      <BasicCardLayout
        variant="div"
        header={{
          title: <RecommendationsTopNToggleButton topNToggle={topNToggle} setTopNToggle={setTopNToggle} />,
          disableTypography: true,
          action: <RecommendationsCountToggleButton view={view} setView={setView} />,
          sx: { p: '0 !important', '.MuiCardHeader-action': { alignSelf: 'auto' } },
        }}
        content={{
          sx: {
            p: 0,
            '&:last-child': { p: 0 },
            flexGrow: 1,
          },
        }}
        card={{
          sx: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            overflow: 'visible',
          },
        }}
      >
        {recoView[view]}
      </BasicCardLayout>
    </Box>
  );
};

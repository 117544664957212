import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataGridState: {} as any,
  searchType: '',
  searchText: '',
  searchedItemsList: [] as any,
  loading: false,
};

export type ItemsState = Readonly<typeof initialState>;

export const recoItemsSlice = createSlice({
  name: 'recoItems',
  initialState: initialState as ItemsState,
  reducers: {
    setSearchType(state, action) {
      state.searchType = action.payload;
    },
    setSearchText(state, action) {
      state.searchText = action.payload;
    },
    setItemsList(state, action) {
      state.searchedItemsList = action.payload;
    },
    setSearchLoading(state, action) {
      state.loading = action.payload;
    },
    setUpdatedState(state, action) {
      state.dataGridState = action.payload;
    },
  },
});

export const { setSearchType, setSearchText, setItemsList, setSearchLoading, setUpdatedState } = recoItemsSlice.actions;

export default recoItemsSlice.reducer;

import React, { useState } from 'react';

import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { addFlex, chipString, subFieldToggle, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import { ToolbarFactory, commonToolbars, toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import { Visibility } from '@mui/icons-material';
import { Translate, translate } from 'react-jhipster';
import { Alert, Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import { ItemsView } from './ItemsView';
import { CollapsiblePropertiesGrid, PropertiesType } from 'app/components/properties/properties-grid';
import { dynamicHeightProps } from 'app/components/data-grid/rows/rows-props';
import { ShowMoreLess } from 'app/components/buttons/ShowMoreLess';

function sortProperties(properties): PropertiesType {
  return Object.entries<string>(properties).sort((a, b) => (a[0] < b[0] ? -1 : 1));
}

const NoRecommendationsFound = () => {
  return (
    <Box display="flex" height="100%">
      <Alert variant="outlined" severity="warning" sx={{ width: '30%', mx: 'auto', my: 'auto' }}>
        {translate('recommendation.no-recommendations-found', null, 'No Recommendations Found')}
      </Alert>
    </Box>
  );
};

export const RecommendedItems = ({ items, isLoading }) => {
  const [itemId, setItemId] = useState(null);

  const columns: GridColDef[] = [
    { field: 'id' },
    {
      field: 'reference_id',
    },
    {
      field: 'item-title',
      flex: 4,
      valueGetter(params) {
        return JSON.parse(JSON.parse(params.row.content).content).title;
      },
      renderCell: params => (
        <Stack spacing={1}>
          <Typography>{JSON.parse(JSON.parse(params.row.content).content).title}</Typography>
          <ShowMoreLess
            openLabel={'recommendation.items-table.show-description'}
            closeLabel={'recommendation.items-table.hide-description'}
            openContent={<Typography color="textSecondary">{JSON.parse(JSON.parse(params.row.content).content).description}</Typography>}
          />
        </Stack>
      ),
    },
    {
      field: 'item-attributes',
      flex: 2,
      valueGetter(params) {
        return JSON.parse(JSON.parse(params.row.content).content).attributes;
      },
      renderCell: params => (
        <CollapsiblePropertiesGrid
          properties={sortProperties(JSON.parse(JSON.parse(params.row.content).content).attributes)}
          hideMoreThan={2}
          gridProps={{ spacing: 1 }}
        />
      ),
    },
    {
      field: 'corpus',
      ...chipString(params => {
        return { variant: 'outlined', size: 'small', color: 'default' };
      }),
    },
    { field: 'index' },
    {
      field: 'timestamp',
      flex: 2,
      valueGetter: ({ value }) => value && new Date(value).toLocaleString(),
    },
    {
      field: 'recommender_type',
      renderCell: params => (
        <Chip
          label={translate(`recommendation.strategies.${params.value}`, null, params.value)}
          variant="outlined"
          size="small"
          color="default"
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key="view"
          icon={
            <Tooltip title={translate('recommendation.recommended-table.view-item', null, 'View item')}>
              <Visibility />
            </Tooltip>
          }
          onClick={() => {
            setItemId(params.row.reference_id);
          }}
          label={translate('recommendation.recommended-table.view-item', null, 'View item')}
        />,
      ],
    },
  ];

  return (
    <>
      <ItemsView itemId={itemId} setOpen={setItemId} />
      <DataGrid
        rows={items || []}
        columns={columns.map(addFlex).map(translateHeaders('recommendation.recommended-table'))}
        getRowId={row => row.id}
        slots={{
          toolbar: ToolbarFactory(commonToolbars.readOnlyLeft),
          noResultsOverlay: NoRecommendationsFound,
          noRowsOverlay: NoRecommendationsFound,
        }}
        {...toolbarProps()}
        {...sizedPagination()}
        {...dynamicHeightProps()}
        density="compact"
        disableColumnMenu
        loading={isLoading}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              index: false,
            },
          },
        }}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
        }}
      />
    </>
  );
};

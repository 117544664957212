import React from 'react';

import { Divider, IconButton, Link, Stack } from '@mui/material';
import { Translate } from 'react-jhipster';
import { LinkedIn, Twitter, GitHub, Room } from '@mui/icons-material';

export const FooterIcons = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} color={theme => theme.palette.primary.contrastText}>
      <Link color="inherit" underline="hover" href="https://www.hurence.com/en/history/" target="_blank">
        <Translate contentKey="landing.footer.about">About</Translate>
      </Link>
      <Link color="inherit" underline="hover" href="https://www.hurence.com/en/contact-us/" target="_blank">
        <Translate contentKey="landing.footer.help">Help</Translate>
      </Link>
      <Divider orientation="vertical" sx={{ borderColor: theme => theme.palette.primary.contrastText }} flexItem />
      <IconButton
        href="https://fr.linkedin.com/company/hurence"
        target="_blank"
        sx={{ marginLeft: 0, color: theme => theme.palette.primary.contrastText }}
      >
        <LinkedIn />
      </IconButton>
      <IconButton
        href="https://twitter.com/Hurence"
        target="_blank"
        sx={{ marginLeft: 0, color: theme => theme.palette.primary.contrastText }}
      >
        <Twitter />
      </IconButton>
      <IconButton
        href="https://github.com/Hurence"
        target="_blank"
        sx={{ marginLeft: 0, color: theme => theme.palette.primary.contrastText }}
      >
        <GitHub />
      </IconButton>
      <IconButton
        href="https://goo.gl/maps/iDb44xRwRQZv8oKw8"
        target="_blank"
        sx={{ marginLeft: 0, color: theme => theme.palette.primary.contrastText }}
      >
        <Room />
      </IconButton>
    </Stack>
  );
};

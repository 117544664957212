import { ColorTranslator, Harmony } from 'colortranslator';

export const colorPaletteMode = {
  LIGHT_DARK_MAIN: [0, 2, 1],
  LIGHT_MAIN_DARK: [0, 1, 2],
  MAIN_DARK_LIGHT: [1, 2, 0],
  MAIN_LIGHT_DARK: [1, 0, 2],
  DARK_MAIN_LIGHT: [2, 1, 0],
  DARK_LIGHT_MAIN: [2, 0, 1],
};

export function getChartColorPalette(colorInput, indexes = colorPaletteMode.LIGHT_MAIN_DARK): string[] {
  const mainColor = new ColorTranslator(colorInput);

  const normal = ColorTranslator.getHarmony(mainColor.HEX).flatMap(color => ColorTranslator.getHarmony(color, Harmony.TRIADIC));
  const tints = normal.flatMap(color => ColorTranslator.getTints(color, 2).reverse()[0]);
  const shades = normal.flatMap(color => ColorTranslator.getShades(color, 2).reverse()[0]);

  const palette = [tints, normal, shades];

  return indexes.filter(i => i < palette.length).flatMap(i => palette[i]);
}

export function getColorTone() {}

export function getAlphaColor(colorInput, alpha) {
  const mainColor = new ColorTranslator(colorInput);
  mainColor.setA(alpha);
  return mainColor.HSLA;
}

const transparent = {
  10: color => getAlphaColor(color, 0.1),
};

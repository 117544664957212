import React from 'react';

import { UserOverviewList } from '../users-overview/index';
import UserCohort from '../cohort/index';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { UsersCohortHeader } from './UsersCohortHeader';
import { UsersCohortSecondary } from './UsersCohortSecondary';
import { useAppSelector } from 'app/config/store';
import { getUserExplorationSelectedCohortCell } from 'app/shared/reducers/views/user-exploration';

export const UsersCohortSectionCard = () => {
  const selectedCohortCell = useAppSelector(getUserExplorationSelectedCohortCell);
  return (
    <LayoutItem
      cardOptions={{
        header: {
          title: <UsersCohortHeader />,
          action: <UsersCohortSecondary />,
          disableTypography: true,
        },
        content: { sx: { height: '300px', overflow: 'scroll', mb: 1, py: 0, '&:last-child': { py: 0 } } },
      }}
      gridItemProps={{ xs: 12, lg: 8, display: 'grid' }}
    >
      {selectedCohortCell.length > 0 ? <UserOverviewList /> : <UserCohort />}
    </LayoutItem>
  );
};

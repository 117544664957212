import React from 'react';
import { Box } from '@mui/material';
import { AppbarRoutes } from './shared/routes/AppbarRoutes';
import { AppSidebarRoutes } from './shared/routes/AppSidebarRoutes';
import { MenubarRoutes } from './shared/routes/MenubarRoutes';
import { PropertyBarRoutes } from './shared/routes/PropertyBarRoutes';
import { MainRoutes } from './shared/routes/MainRoutes';
import { RightSidebarRoutes } from './shared/routes/RightSidebarRoutes';
import { useAppSelector } from './config/store';

const AppRoutes = () => {
  const { appBarHeight } = useAppSelector(state => state.mainLayouts.appbarReducer);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppbarRoutes />
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} mt={`${appBarHeight}px`}>
        <AppSidebarRoutes />
        <Box
          component="main"
          sx={{
            width: '100%',
            flexGrow: 1,
          }}
        >
          <MenubarRoutes />
          <MainRoutes />
          <PropertyBarRoutes />
        </Box>
        <RightSidebarRoutes />
      </Box>
    </Box>
  );
};

export default AppRoutes;

import React from 'react';

import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { SvgIcon, Typography } from '@mui/material';
import { sitemap } from 'app/shared/util/url-utils';

export const AppLogo = () => {
  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      {/* to={layoutctx.bootstrapPage ? '/' : sitemap.home} */}
      <Link to={sitemap.home} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
        <Box>
          <SvgIcon component="div" fontSize="large">
            <img
              src="content/img/hurence-logo.png"
              alt="logo"
              loading="lazy"
              style={{ width: '100%', height: '100%', verticalAlign: 'top' }}
              className="img-fluid"
            />
          </SvgIcon>
        </Box>
        <Typography variant="h2" ml={2} color="primary.contrastText" noWrap>
          Scoring
        </Typography>
        <Typography variant="h2" mr={2} color="secondary" noWrap>
          Joe
        </Typography>
      </Link>
    </Box>
  );
};

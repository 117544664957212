import { useEffect } from 'react';
import { useAggregateActionsData } from './useAggregateActionsData';
import { STERMS_COUNT_BY_TYPE, getActionsDataObject } from '../../utils/actions-data-transformation';

export const useActionsKpis = (timerange, requestParams, accountProfile) => {
  const { refresh } = timerange;

  const {
    currentData: aggregatedActions,
    refetch: refetchAggregatedActions,
    isUninitialized: isAggregatedActionsUninitialized,
    isFetching: isAggregatedActionsFetching,
  } = useAggregateActionsData(timerange, requestParams, accountProfile);

  const {
    currentData: aggregatedActionsWithRecoId,
    refetch: refetchAggregatedActionsWithRecoId,
    isUninitialized: isAggregatedActionsWithRecoIdUninitialized,
    isFetching: isAggregatedActionsWithRecoIdFetching,
  } = useAggregateActionsData(timerange, requestParams, accountProfile, { existsRecoUuid: true });

  const {
    currentData: aggregatedActionsWithoutRecoId,
    refetch: refetchAggregatedActionsWithoutRecoId,
    isUninitialized: isAggregatedActionsWithoutRecoIdUninitialized,
    isFetching: isAggregatedActionsWithoutRecoIdFetching,
  } = useAggregateActionsData(timerange, requestParams, accountProfile, { existsRecoUuid: false });

  useEffect(() => {
    if (!isAggregatedActionsUninitialized) refetchAggregatedActions();
    if (!isAggregatedActionsWithRecoIdUninitialized) refetchAggregatedActionsWithRecoId();
    if (!isAggregatedActionsWithoutRecoIdUninitialized) refetchAggregatedActionsWithoutRecoId();
  }, [refresh]);

  return {
    actionsList: aggregatedActions ? aggregatedActions.aggregations[STERMS_COUNT_BY_TYPE].buckets.map(a => a.key) : [],
    ...getActionsDataObject(aggregatedActions, requestParams),
    withRecoId: {
      ...getActionsDataObject(aggregatedActionsWithRecoId, requestParams),
    },
    withoutRecoId: {
      ...getActionsDataObject(aggregatedActionsWithoutRecoId, requestParams),
    },
    isFetching: isAggregatedActionsFetching || isAggregatedActionsWithRecoIdFetching || isAggregatedActionsWithoutRecoIdFetching,
  };
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';

const accountProfilesUrl = CONFIG_MANAGER_API_URL + '/account-profiles';

export const initialState = {
  guestsLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  guests: null,
};

export type GuestsState = Readonly<typeof initialState>;

// Actions

export const getGuests = createAsyncThunk(
  'accountProfile/getGuests',
  async ({ accountId }: { accountId: any }) => microserviceHttpClient(`${accountProfilesUrl}/${accountId}/guests`),
  { serializeError: serializeAxiosError }
);
export const addGuests = createAsyncThunk(
  'accountProfile/addGuests',
  async ({ accountId, userEmail }: { accountId: any; userEmail: any }) =>
    microserviceHttpClient(`${accountProfilesUrl}/${accountId}/guests/${userEmail}`, 'put'),
  { serializeError: serializeAxiosError }
);

// Slice

export const GuestsSlice = createSlice({
  name: 'guests',
  initialState: initialState as GuestsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGuests.pending, state => {
        state.guestsLoading = true;
        state.errorMessage = null;
      })
      .addCase(getGuests.fulfilled, (state, action) => {
        state.guestsLoading = false;
        state.guests = action.payload.data;
      })
      .addCase(getGuests.rejected, (state, action) => {
        state.guestsLoading = false;
        state.errorMessage = action.error.message;
        state.guests = null;
      })
      .addCase(addGuests.pending, state => {
        state.guestsLoading = true;
        state.errorMessage = null;
      })
      .addCase(addGuests.fulfilled, (state, action) => {
        state.guestsLoading = false;
      })
      .addCase(addGuests.rejected, (state, action) => {
        state.guestsLoading = false;
        state.errorMessage = action.error.message;
      });
  },
});

// Reducer
export default GuestsSlice.reducer;

import React from 'react';

import { sitemap } from 'app/shared/util/url-utils';
import { useNavigate } from 'react-router-dom';
import { AccountSectionPresentation } from './AccountSection';
import { useCreateAccountUsingPostMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { useSetCurrentAccountUsingPutMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';

interface AccountSectionProps {
  email: any;
  accountOptions: any;
  selectedAccount: any;
}

export const AccountSection = ({ email, accountOptions, selectedAccount }: AccountSectionProps) => {
  const navigate = useNavigate();
  const [createEmptyBillingAccount, { data: accountId }] = useCreateAccountUsingPostMutation({});
  const [setCurrentAccount] = useSetCurrentAccountUsingPutMutation();

  const handleChange = (_event, newAccount) => {
    _event.preventDefault();

    if (newAccount.id === '-1') {
      createEmptyBillingAccount({ accountDtoReq: { currency: 'EUR' } })
        .unwrap()
        .then(resp => {
          setCurrentAccount({ userEmail: email, accountId: resp.externalKey });
        })
        .then(x => navigate(sitemap.home));
    } else {
      setCurrentAccount({ userEmail: email, accountId: newAccount.id }).then(x => navigate(sitemap.home));
    }
  };

  return <AccountSectionPresentation accountOptions={accountOptions} selectedAccount={selectedAccount} handleChange={handleChange} />;
};

import * as React from 'react';
import { Button, Box } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useContext, useEffect, useState } from 'react';
import { MainCard } from 'app/components/card/main-card/MainCard';
import { LayoutContext, LayoutContextType } from 'app/shared/layout/restricted/context/layoutContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import { useForm } from 'react-hook-form';
import { updateBillingAccount } from 'app/shared/reducers/billing-account/billing-account';
import { Link, useNavigate } from 'react-router-dom';

export const AccountFormStep = ({ formTitle, accountAttributes, defaultValues, onSubmit, buttons }) => {
  const schema = validationUtils.getFormSchema(accountAttributes);
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const submitButton = title => {
    return (
      <Button
        /* disabled={!methods.formState.isDirty} */
        variant="contained"
        color="primary"
        sx={{ mb: 2, fontWeight: 'bold' }}
        onClick={() => {
          methods.handleSubmit(onSubmit)();
        }}
      >
        {title}
      </Button>
    );
  };

  return (
    <FormBuilder
      fields={accountAttributes}
      defaultValue={defaultValues}
      methods={methods}
      optionalMarkerDefault={true}
      containerProps={{ marginY: 0.5, spacing: 2 }}
    >
      {buttons(submitButton)}
    </FormBuilder>
  );
};

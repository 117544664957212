import React from 'react';

import { Chip, Tooltip, Typography } from '@mui/material';
import { translate } from 'react-jhipster';

export const RequestFilterChips = ({ filters, handleChipClick, handleChipDelete, handleClickFilterPopover, maxDisplay }) => {
  return (
    <>
      {filters.slice(0, maxDisplay).map(filter => {
        return (
          <Tooltip
            key={filter.key}
            arrow
            title={`${translate(`web-analytics.request-filter.bucketingDimensions.${filter.key}`, null, filter.key)}  ${translate(
              `web-analytics.request-filter.filter-operators.${filter.filteringKind}`,
              null,
              filter.filteringKind
            )} : ${String(filter.value)}`}
          >
            <Chip
              size="small"
              label={`${translate(`web-analytics.request-filter.bucketingDimensions.${filter.key}`, null, filter.key)} ${translate(
                `web-analytics.request-filter.filter-operators.${filter.filteringKind}`,
                null,
                filter.filteringKind
              )} : ${String(filter.value)}`}
              onDelete={() => {
                handleChipDelete(filter);
              }}
              onClick={e => {
                handleChipClick(e, filter);
              }}
              sx={{ maxWidth: '400px' }}
            />
          </Tooltip>
        );
      })}
      {filters.length > maxDisplay && (
        <Chip
          size="small"
          label={`+${filters.length - maxDisplay}`}
          sx={{ ml: 1 }}
          onClick={e => {
            handleClickFilterPopover(e);
          }}
        />
      )}
    </>
  );
};

import React, { useEffect } from 'react';
import { FieldValues, UseFieldArrayReturn, useForm } from 'react-hook-form';
import { FormBuilder } from '../../../../form-builder-lib/Components/FormBuilder';

function filterExistingKeys(data) {
  return Object.entries(data).reduce((filteredData, [key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      filteredData[key] = value;
    }
    return filteredData;
  }, {});
}

function StylesFormBuilder({ index, style, fieldProps }) {
  const { append, update, fields } = style as UseFieldArrayReturn<FieldValues, 'id'>;

  const updateAtIndex = (arrayIndex, newValue) => {
    // Ensure that the index is within a valid range, and add missing values up to the index.
    const missingIndexes = arrayIndex + 1 - fields.length;

    if (missingIndexes > 0) {
      for (let i = 0; i < missingIndexes; i++) {
        append({}); // You can specify the default value here if needed
      }
    } else if (arrayIndex < 0 || arrayIndex >= fields.length) {
      // Handle an invalid index (outside the array bounds).
      return;
    }

    update(arrayIndex, newValue);
  };

  const values = fields[index] ?? {};
  const validKeys = ['id', ...fieldProps.map(f => f.attribute)];
  const acceptedValues = Object.fromEntries(Object.entries(values).filter(([key]) => validKeys.includes(key)));

  const methods = useForm({
    defaultValues: acceptedValues,
  });

  useEffect(() => {
    if (Object.keys(acceptedValues).length !== Object.keys(values).length) {
      // Reset form values with accepted values
      updateAtIndex(index, filterExistingKeys(acceptedValues));
    }

    const subscription = methods.watch(data => {
      updateAtIndex(index, filterExistingKeys(data));
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return <FormBuilder methods={methods} fields={fieldProps} />;
}

export { StylesFormBuilder };

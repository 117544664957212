export const pieChartList = [
  {
    title: 'energy-impact_kwh',
    key: 'energy_impact_kwh',
    scope: 'metric'
  },
  {
    title: 'co2-eq_kg',
    key: 'co2_eq_kg',
    scope: 'metric'
  },
  {
    title: 'page-size_bytes',
    key: 'page_size_bytes',
    scope: 'metric'
  },
  {
    title: 'page-views',
    key: 'page_views',
    scope: 'metric'
  },
  {
    title: 'avg-time-on-page_sec',
    key: 'avg_time_on_page_sec',
    scope: 'metric'
  },
  // {
  //   title: 'transferred-data_bytes',
  //   key: 'transferred_bytes',
  //   scope: 'metric'
  // },
  // {
  //   title: 'co2-eq-by-page_kg',
  //   key: 'co2_eq_by_page_kg',
  //   scope: 'metric'
  // },
  // {
  //   title: 'avg-page-size_bytes',
  //   key: 'avg_page_size_bytes',
  //   scope: 'metric'
  // },
];

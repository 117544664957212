import React, { useContext, useState } from 'react';

import { ProductContext, ProductContextType } from '../../context/ProductContext';
import { Stepper, Step, StepLabel, Typography, Stack, Select, MenuItem, Chip } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { getMonthlyPrice } from 'app/shared/util/catalog-utils';
import { Translate, translate } from 'react-jhipster';

export const ProductPlan = () => {
  const { plan, subscribedPlan } = useContext<ProductContextType>(ProductContext);
  const [currency, setCurrency] = useState('EUR');
  const activeStep =
    subscribedPlan.length > 0
      ? plan?.phases.findIndex(p => {
          p.type === subscribedPlan[0].phaseType;
        }) + 1
      : 0;

  return (
    <>
      <Typography variant="h5" m={1}>
        {plan?.phases.length} <Translate contentKey="products.phases-available">Phases Available</Translate>
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {plan?.phases.map((phase, index) => (
          <Step key={index}>
            <StepLabel>
              <BasicCardLayout>
                <Typography variant="h5" m={1}>
                  {phase.type} {translate('products.phase', null, 'Phase')}{' '}
                  {phase.duration.unit !== 'UNLIMITED' &&
                    `${translate('products.for', null, 'For')} ${phase.duration.number} ${translate(
                      `products.${phase.duration.unit}`,
                      null,
                      phase.duration.unit
                    )}`}
                  {'     '}
                  {subscribedPlan.length > 0 && subscribedPlan[0].phaseType === phase.type && (
                    <Chip
                      variant="outlined"
                      label={translate('products.current-phase', null, 'Current Phase')}
                      color="secondary"
                      size="small"
                    />
                  )}
                </Typography>
                <Stack direction="row" alignItems="baseline" justifyContent="center" spacing={0.5}>
                  <Typography variant="h1" color="primary">
                    {Number(getMonthlyPrice(phase.prices, currency, plan.billingPeriod)).toFixed(2)}
                  </Typography>
                  <Select
                    sx={{
                      ml: 2,
                    }}
                    disabled
                    variant="standard"
                    value={currency}
                    onChange={event => setCurrency(event.target.value)}
                  >
                    <MenuItem value="EUR">EUR</MenuItem>
                  </Select>
                  <Typography variant="h3">{translate('products.per-month')}</Typography>
                </Stack>
              </BasicCardLayout>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

import React from 'react';

import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { getRecommendationsByTypeOptions } from '../utils/echart-reco-options';
import { WithData } from 'app/modules/loading-lib/WithData';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

const RecommendationsFunnelChart = ({ data, name, totalReco, isFetching }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
      <ReactECharts style={{ height: '200px', width: '100%' }} option={getRecommendationsByTypeOptions(data, totalReco, theme)} />
      <Typography variant="h4" color="textSecondary">
        {name}
      </Typography>
    </Box>
  );
};

export default WithLoadingHOC(WithData(RecommendationsFunnelChart));

import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { QueryStats } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { FieldProp, FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import {
  GetCurrentUserProfileUsingGetApiResponse,
  useGetCurrentUserProfileUsingGetQuery,
} from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { Translate, translate } from 'react-jhipster';

export const ProfileForm = props => {
  const { data: userProfile = {} as GetCurrentUserProfileUsingGetApiResponse } = useGetCurrentUserProfileUsingGetQuery({});
  const [filterMode, setFilterMode] = React.useState('basic');

  const [formValues, setFormValues] = React.useState(userProfile);

  const changeFilterMode = () => {
    if (filterMode === 'basic') {
      setFilterMode('advanced');
    } else {
      setFilterMode('basic');
    }
  };

  function formFields(methods?: UseFormReturn<FieldValues, any>, watch?: [string]): Array<FieldProp> {
    const basicFilters: Array<FieldProp> = [
      {
        component: 'text-field',
        attribute: 'email',
        title: translate('profile.email', null, 'Email'),
        props: { disabled: true },
        validationType: 'string',
        validations: [
          ['required', true],
          ['email', true],
        ],
        // col: { md: 2 },
      },
      {
        component: 'text-field',
        attribute: 'firstName',
        title: translate('profile.firstName', null, 'First Name'),
        props: { disabled: true },
        validations: [['required', true]],
        // col: { md: 2 },
      },
      {
        component: 'text-field',
        attribute: 'lastName',
        title: translate('profile.lastName', null, 'Last Name'),
        props: { disabled: true },
        validations: [['required', true]],
        // col: { md: 2 },
      },
    ];
    const advancedFilters: Array<FieldProp> = [];
    const fillerField: Array<FieldProp> = [
      {
        component: 'custom',
        attribute: 'filler',
        customComponent: () => <Box flexGrow={1} />,
      },
    ];
    return [...basicFilters, ...(filterMode === 'advanced' ? [...fillerField, ...advancedFilters] : [])];
  }

  const schema = validationUtils.getFormSchema(formFields());

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const watch = undefined; // methods.watch();

  // const applyFilters = methods.handleSubmit(data => {
  //   if (data.corpus) {
  //     dispatch(getItems({ corpus: data.corpus }));
  //     dispatch(strategies[data.strategy].action(data));
  //     setFormValues({ ...data });
  //   }

  //   //   // dispatch(getProfile({ profileId }));
  // });

  const submitButtons = (
    <Stack direction="row" my={2}>
      <Button color="neutral" onClick={changeFilterMode}>
        <Translate contentKey={`recommendation.buttons.${filterMode}`}>{filterMode === 'basic' ? 'advanced' : 'basic'}</Translate>
      </Button>
      <Button
        // disabled={!methods.formState.isDirty}
        // onClick={applyFilters}
        startIcon={<QueryStats />}
        variant="outlined"
      >
        <Translate contentKey="recommendation.buttons.apply">Apply</Translate>
      </Button>
    </Stack>
  );

  return (
    <FormBuilder
      fields={formFields(methods, watch)}
      defaultValue={formValues}
      methods={methods}
      requiredMarkerDefault={true}
      containerProps={{ marginY: 0.5, spacing: 2, paddingLeft: '10px' }}
    />
  );
};

import { BehaviorSubject } from 'rxjs';
import MetricRequest from 'app/modules/views/greensights/model/metric-request';
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import MetricResponse from 'app/modules/views/greensights/model/metricResponse';
import { ResponseWrapper } from 'app/modules/views/greensights/model/responseWrapper';
import { GREENSIGHTS_DATA_HUB_API_URL } from 'app/shared/api/constants';

const map = {};

export const useData = (key?: string, callback?: (response: ResponseWrapper<MetricResponse>) => any) => {
  let loading$;
  let data$;

  if (key && map[key]) {
    loading$ = map[key].loading$;
    data$ = map[key].data$;
  } else {
    loading$ = new BehaviorSubject(true);
    data$ = new BehaviorSubject([]);
    if (key) {
      map[key] = { data$, loading$ };
    }
  }
  const load = (accountId: string, body: MetricRequest, config?: AxiosRequestConfig<MetricRequest>) => {
    loading$.next(true);
    if (body.start.startsWith('now') && ['now-1w/d', 'now-1M/d', 'now-6M/d'].indexOf(body.start) === -1) {
      loading$.next(false);
      return;
    }
    axios.post(`${GREENSIGHTS_DATA_HUB_API_URL}/metric/load/${accountId}`, body, config).then(
      response => {
        if (callback) {
          data$.next(callback(response.data));
        } else {
          data$.next(response.data);
        }
        loading$.next(false);
      },
      reason => {
        console.error(reason);
      }
    );
  };

  const [data, setData] = useState(data$.value);
  const [loading, setLoading] = useState(loading$.value);

  useEffect(() => {
    const subscription = data$.subscribe(value => setData(value));
    const loadingSub = loading$.subscribe(value => {
      setLoading(value);
    });
    return () => {
      subscription.unsubscribe();
      loadingSub.unsubscribe();
    };
  }, []);
  return { data, load, loading };
};

import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { patterns, sitemap } from '../util/url-utils';
import { RequireAuth } from './components/RequireAuth';
import LoginRedirect from 'app/modules/login/login-redirect';
import Logout from 'app/modules/login/logout';
import Greensights from 'app/modules/views/greensights';
import PageNotFound from 'app/shared/error/page-not-found';
import { AccountPage } from '../../modules/views/account-page';
import { DataExploration } from '../../modules/views/data-exploration';
import { ModuleRoot } from '../../modules/views/module-root';
import { ProfilePage } from '../../modules/views/profile-page';
import { LandingPage } from '../../modules/views/public-home/pages/landing-page/LandingPage';
import { RecoStrategies } from '../../modules/views/recommendations/strategies';
import { Products } from '../../modules/views/setup/Products';
import { UserExploration } from '../../modules/views/user-exploration';
import MuiComponentSamples from '../../modules/views/mui-components-samples';
import { SubscriptionSettings } from '../../modules/views/subscription-settings';
import { VisitorProfile } from '../../modules/views/visitor-profile';
import { WebAnalyticsExport } from '../../modules/views/web-analytics-tools/export';
import { WebAnalyticsTagManager } from '../../modules/views/web-analytics-tools/tag-manager';
import { CatalogManagement } from '../../modules/views/recommendations/catalog';
import { ItemsManagement } from 'app/modules/views/recommendations/items';
import { RecommendationsAlgorithms } from 'app/modules/views/recommendations/algorithms-page/RecommendationsAlgorithms';
import { ErrorPage } from 'app/modules/views/error-page';
import { Widget } from 'app/modules/views/recommendations/widget';

export const MainRoutes = () => {
  return (
    <Routes>
      <Route index element={<LandingPage />} />
      <Route path={sitemap.login} element={<LoginRedirect />} />
      <Route path={sitemap.logout} element={<Logout />} />
      <Route path={sitemap.recommendationAlgorithms} element={<RecommendationsAlgorithms />} />
      <Route path={sitemap.error} element={<ErrorPage />} />

      <Route path="/" element={<RequireAuth />}>
        {/* Global application URLs */}
        <Route path={sitemap.home} element={<ModuleRoot />} />
        <Route path={patterns.home} element={<ModuleRoot />} />
        <Route path={sitemap.setup} element={<Products />} />
        <Route path={sitemap.account} element={<AccountPage />} />
        <Route path={sitemap.user} element={<ProfilePage />} />

        {/* Product URLs */}
        <Route path={patterns.product.settings} element={<SubscriptionSettings />} />

        {/* Open Analytics URLs */}
        {/* <Route path={sitemap.overview} element={<AnalyticsOverview />} /> */}
        <Route path={sitemap['web-analytics'].audience} element={<UserExploration />} />
        <Route path={sitemap['web-analytics'].sessions} element={<DataExploration />} />
        <Route path={sitemap['web-analytics'].visitor} element={<VisitorProfile />} />
        <Route path={sitemap['web-analytics'].export} element={<WebAnalyticsExport />} />
        <Route path={sitemap['web-analytics'].tagManager} element={<WebAnalyticsTagManager />} />

        {/* Recommendations URLs */}
        <Route path={sitemap.recommendations.strategies} element={<RecoStrategies />} />
        <Route path={sitemap.recommendations.corpus} element={<CatalogManagement />} />
        <Route path={sitemap.recommendations.itemsManagement} element={<ItemsManagement />} />
        <Route path={sitemap.recommendations.widget} element={<Widget />} />

        {/* Greensights URLs */}
        <Route path={sitemap.greensights.board} element={<Greensights />} />

        {/* Dev only pages */}
        {/* <Route path={sitemap.devOnly.admin} element={<Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN] />} /> */}
        <Route
          path={sitemap.devOnly.muiComponentSamples}
          element={<MuiComponentSamples />}
          //  renderelement={renderComponentDefault} hasAnyAuthorities={[AUTHORITIES.ADMIN]}
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

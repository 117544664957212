import { emptyDataHubCloudServicesApi as api } from '../emptyDataHubCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getUserInfo: build.query<GetUserInfoApiResponse, GetUserInfoApiArg>({
      query: queryArg => ({
        url: `/api/v1/exploration/users/${queryArg.userId}`,
        method: 'POST',
        body: queryArg.requestFilters,
        params: { includeSessionIds: queryArg.includeSessionIds, from: queryArg['from'], to: queryArg.to },
      }),
      extraOptions: { maxRetries: 3 },
    }),
    getUserSessions: build.query<GetUserSessionsApiResponse, GetUserSessionsApiArg>({
      query: queryArg => ({
        url: `/api/v1/exploration/users/${queryArg.userId}/sessions`,
        method: 'POST',
        body: queryArg.requestFilters,
        params: { sessionIds: queryArg.sessionIds, page: queryArg.page, size: queryArg.size, direction: queryArg.direction },
      }),
      extraOptions: { maxRetries: 3 },
    }),
    getUserEvents: build.query<GetUserEventsApiResponse, GetUserEventsApiArg>({
      query: queryArg => ({
        url: `/api/v1/exploration/users/${queryArg.userId}/events`,
        method: 'POST',
        body: queryArg.requestFilters,
        params: { sessionIds: queryArg.sessionIds, page: queryArg.page, size: queryArg.size, direction: queryArg.direction },
      }),
      extraOptions: { maxRetries: 3 },
    }),
    getUserStats: build.query<GetUserStatsApiResponse, GetUserStatsApiArg>({
      query: queryArg => ({
        url: `/api/v1/exploration/userStats`,
        method: 'POST',
        body: queryArg.requestFilters,
        params: { from: queryArg['from'], to: queryArg.to, kpis: queryArg.kpis },
      }),
    }),
    getCohortAnalysisMatrix: build.query<GetCohortAnalysisMatrixApiResponse, GetCohortAnalysisMatrixApiArg>({
      query: queryArg => ({
        url: `/api/v1/exploration/user/cohortAnalysis`,
        method: 'POST',
        body: queryArg.requestFilters,
        params: { timeStep: queryArg.timeStep, nbTimeSteps: queryArg.nbTimeSteps, userIdField: queryArg.userIdField },
      }),
      extraOptions: { maxRetries: 3 },
    }),
    getMatrixCellUsers: build.query<GetMatrixCellUsersApiResponse, GetMatrixCellUsersApiArg>({
      query: queryArg => ({
        url: `/api/v1/exploration/user/cohortAnalysis/users`,
        method: 'POST',
        body: queryArg.requestFilters,
        params: {
          timeStep: queryArg.timeStep,
          truncateToTimestepStart: queryArg.truncateToTimestepStart,
          nbTimeStepsFirstVisit: queryArg.nbTimeStepsFirstVisit,
          nbTimeStepsMatrixCell: queryArg.nbTimeStepsMatrixCell,
        },
      }),
      extraOptions: { maxRetries: 3 },
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userExplorationApi };
export type GetUserInfoApiResponse = /** status 200 OK */ UserInfo;
export type GetUserInfoApiArg = {
  userId: string;
  includeSessionIds?: boolean;
  from?: string;
  to?: string;
  requestFilters: RequestFilters;
};
export type GetUserSessionsApiResponse = /** status 200 OK */ WebSession[];
export type GetUserSessionsApiArg = {
  userId: string;
  sessionIds?: string[];
  page?: number;
  size?: number;
  direction?: 'ASC' | 'DESC';
  requestFilters: RequestFilters;
};
export type GetUserEventsApiResponse = /** status 200 OK */ WebEvent[];
export type GetUserEventsApiArg = {
  userId: string;
  sessionIds?: string[];
  page?: number;
  size?: number;
  direction?: 'ASC' | 'DESC';
  requestFilters: RequestFilters;
};
export type GetUserStatsApiResponse = /** status 200 OK */ MultiAggsSerieDto;
export type GetUserStatsApiArg = {
  from?: string;
  to?: string;
  kpis: string[];
  requestFilters: RequestFilters;
};
export type GetCohortAnalysisMatrixApiResponse = /** status 200 OK */ CohortAnalysisLine[];
export type GetCohortAnalysisMatrixApiArg = {
  timeStep?: 'YEAR' | 'MONTH' | 'WEEK' | 'DAY';
  nbTimeSteps?: number;
  userIdField?: string;
  requestFilters: RequestFilters;
};
export type GetMatrixCellUsersApiResponse = /** status 200 OK */ SessionsPerUser[];
export type GetMatrixCellUsersApiArg = {
  timeStep?: 'YEAR' | 'MONTH' | 'WEEK' | 'DAY';
  truncateToTimestepStart?: boolean;
  nbTimeStepsFirstVisit: number;
  nbTimeStepsMatrixCell: number;
  requestFilters: RequestFilters;
};
export type EntryDtoDoubleString = {
  value?: number;
};
export type EntryDtoLongString = {
  value?: number;
  name?: string;
};
export type SerieDtoLongStringString = {
  data?: EntryDtoLongString[];
  name?: string;
};
export type ParsedExtendedStats = {
  name?: string;
  metadata?: {
    [key: string]: object;
  };
  count?: number;
  min?: number;
  max?: number;
  sum?: number;
  avg?: number;
  sumOfSquares?: number;
  variance?: number;
  variancePopulation?: number;
  varianceSampling?: number;
  stdDeviation?: number;
  stdDeviationPopulation?: number;
  stdDeviationSampling?: number;
  sumOfSquaresAsString?: string;
  varianceAsString?: string;
  variancePopulationAsString?: string;
  varianceSamplingAsString?: string;
  stdDeviationAsString?: string;
  stdDeviationPopulationAsString?: string;
  stdDeviationSamplingAsString?: string;
  stdDeviationBounds?: number[];
  stdDeviationBoundsAsString?: string[];
  type?: string;
  minAsString?: string;
  maxAsString?: string;
  avgAsString?: string;
  sumAsString?: string;
  fragment?: boolean;
};
export type UserInfo = {
  metadata?: {
    [key: string]: EntryDtoDoubleString;
  };
  series?: SerieDtoLongStringString[];
  extendedStats?: {
    [key: string]: ParsedExtendedStats;
  };
  sessionIds?: string[];
};
export type QueryBuilder = {
  name?: string;
  writeableName?: string;
  fragment?: boolean;
};
export type RequestFilterEntry = {
  filteringKind?: 'PRESENT_IN_VALUES' | 'ABSENT_IN_VALUES' | 'IN_VALUE_RANGE' | 'NOT_IN_VALUE_RANGE' | 'GREATER_THAN' | 'LOWER_THAN';
  key?:
    | 'EVENT_COUNTER'
    | 'ACTIVE_SESSION'
    | 'PAGEVIEWS_COUNTER'
    | 'REFERER'
    | 'REFERER_HOSTNAME'
    | 'REMOTE_HOST'
    | 'REMOTE_HOST_FQDN'
    | 'INTRANET_IP'
    | 'INTRANET_TAGGING'
    | 'UTM_SOURCE'
    | 'UTM_CAMPAIGN'
    | 'UTM_MEDIUM'
    | 'UTM_CONTENT'
    | 'UTM_TERM'
    | 'FIRST_USER_VISIT_EPOCH_SECONDS'
    | 'IS_FIRST_SESSION_OF_USER'
    | 'FIRST_EVENT_EPOCH_SECONDS'
    | 'LAST_EVENT_EPOCH_SECONDS'
    | 'FIRST_VISITED_PAGE'
    | 'LAST_VISITED_PAGE'
    | 'SESSION_ID'
    | 'SOURCE_OF_TRAFFIC_SOURCE'
    | 'SOURCE_OF_TRAFFIC_CAMPAIGN'
    | 'SOURCE_OF_TRAFFIC_MEDIUM'
    | 'SOURCE_OF_TRAFFIC_CONTENT'
    | 'SOURCE_OF_TRAFFIC_KEYWORD'
    | 'SOURCE_OF_TRAFFIC_ORGANIC_SEARCH'
    | 'SOURCE_OF_TRAFFIC_REFERRAL_PATH'
    | 'USER_AGENT_NAME'
    | 'USER_AGENT_FAMILY'
    | 'USER_AGENT_VENDOR'
    | 'USER_AGENT_TYPE'
    | 'USER_AGENT_VERSION'
    | 'USER_AGENT_DEVICE_CATEGORY'
    | 'USER_AGENT_OS_FAMILY'
    | 'USER_AGENT_OS_VERSION'
    | 'USER_AGENT_OS_VENDOR'
    | 'USER_ID'
    | 'CITY_ID'
    | 'CITY_NAME'
    | 'CONTINENT_CODE'
    | 'CONTINENT_ID'
    | 'CONTINENT_NAME'
    | 'COUNTRY_CODE'
    | 'COUNTRY_ID'
    | 'COUNTRY_NAME'
    | 'METRO_CODE'
    | 'TIMEZONE'
    | 'MOST_SPECIFIC_SUBDIVISION_CODE'
    | 'MOST_SPECIFIC_SUBDIVISION_ID'
    | 'MOST_SPECIFIC_SUBDIVISION_NAME'
    | 'POSTAL_CODE'
    | 'SUBDIVISION_CODES'
    | 'SUBDIVISION_IDS'
    | 'SUBDIVISION_NAMES'
    | 'VALID_DOMAIN';
  value?: object;
  queryBuilder?: QueryBuilder;
};
export type RequestFilters = {
  filterEntryList?: RequestFilterEntry[];
};
export type GeoPoint = {
  lat?: number;
  lon?: number;
};
export type WebSession = {
  id?: string;
  timestamp?: number;
  get_id?: string;
  get_index?: string;
  get_score?: number;
  get_type?: string;
  activeSession?: boolean;
  bundleId?: string;
  cityId?: number;
  cityName?: string;
  continentCode?: string;
  continentId?: number;
  continentName?: string;
  countryCode?: string;
  countryId?: number;
  countryName?: string;
  esIndex?: string;
  esType?: string;
  eventsCounter?: number;
  firstEventDateTime?: string;
  firstEventEpochSeconds?: string;
  firstVisitedPage?: string;
  geolocation?: GeoPoint;
  intranetIp?: string;
  intranetTagging?: string;
  is_single_page_visit?: boolean;
  lastEventDateTime?: string;
  lastEventEpochSeconds?: string;
  lastVisitedPage?: string;
  metroCode?: number;
  mostSpecificSubdivisionCode?: string;
  mostSpecificSubdivisionId?: number;
  mostSpecificSubdivisionName?: string;
  ownerAccount?: string;
  postalCode?: string;
  record_id?: string;
  record_time?: number;
  record_type?: string;
  referer?: string;
  refererHostname?: string;
  remoteHost?: string;
  remoteHostFqdn?: string;
  sessionDuration?: number;
  sessionId?: string;
  sessionInactivityDuration?: number;
  subdivisionCodes?: string;
  subdivisionIds?: string;
  subdivisionNames?: string;
  timeZone?: string;
  userAgentDeviceCategory?: string;
  userAgentFamily?: string;
  userAgentName?: string;
  userAgentOsFamily?: string;
  userAgentOsVendor?: string;
  userAgentOsVersion?: string;
  userAgentType?: string;
  userAgentVendor?: string;
  userAgentVersion?: string;
  userId?: string;
  validDomain?: string;
  pageviewsCounter?: number;
};
export type WebEvent = {
  get_id?: string;
  get_index?: string;
  get_score?: number;
  get_type?: string;
  applicationId?: string;
  bundleId?: string;
  checkoutCurrency?: string;
  checkoutId?: string;
  checkoutStep?: string;
  cityId?: number;
  cityName?: string;
  clickTarget?: string;
  clickText?: string;
  clickUrl?: string;
  clientTimestamp?: number;
  continentCode?: string;
  continentId?: number;
  continentName?: string;
  countryCode?: string;
  countryId?: number;
  countryName?: string;
  customizationEnabled?: string;
  detectedCorruption?: boolean;
  detectedDuplicate?: boolean;
  devicePixelRatio?: number;
  ecommerceEnabled?: string;
  esIndex?: string;
  esType?: string;
  eventAction?: string;
  eventCategory?: string;
  eventId?: string;
  eventLabel?: string;
  eventType?: string;
  eventValue?: string;
  firstInSession?: boolean;
  geolocation?: GeoPoint;
  gtmStart?: number;
  gtmTimerCurrentTime?: number;
  gtmTimerElapsedTime?: number;
  gtmTimerEventNumber?: number;
  gtmTimerId?: number;
  gtmTimerInterval?: number;
  gtmTimerStartTime?: number;
  hitType?: string;
  itemCatalog?: string;
  itemListIndex?: number;
  itemListPage?: number;
  intranetIp?: string;
  intranetTagging?: string;
  ip2geoEnabled?: string;
  itemCanonicalUrl?: string;
  itemId?: string;
  latitude?: number;
  location?: string;
  locationPath?: string;
  locationQueryString?: string;
  longitude?: number;
  mediaCurrentTime?: number;
  mediaDuration?: number;
  mediaElapsedTime?: number;
  mediaEnabled?: string;
  mediaEvent?: string;
  mediaId?: string;
  mediaPercent?: number;
  mediaPlayer?: string;
  mediaPublisher?: string;
  mediaTitle?: string;
  mediaUrl?: string;
  metroCode?: number;
  mostSpecificSubdivisionCode?: string;
  mostSpecificSubdivisionId?: number;
  mostSpecificSubdivisionName?: string;
  originalSessionId?: string;
  ownerAccount?: string;
  pageType?: string;
  pageViewId?: string;
  partyId?: string;
  postalCode?: string;
  reasonForNewSession?: string;
  record_id?: string;
  record_time?: number;
  record_type?: string;
  referer?: string;
  refererHostname?: string;
  remoteHost?: string;
  remoteHostFqdn?: string;
  screenPixelHeight?: number;
  screenPixelWidth?: number;
  scrollDirection?: string;
  scrollThreshold?: number;
  scrollUnits?: string;
  sessionId?: string;
  socialAction?: string;
  socialActionTarget?: string;
  socialNetwork?: string;
  sourceOfTraffic_organic_search?: boolean;
  subdivisionCodes?: string;
  subdivisionNames?: string;
  tagName?: string;
  timePageOpen?: number;
  timeZone?: string;
  timestamp?: number;
  timingCategory?: string;
  timingLabel?: string;
  timingValue?: string;
  timingVar?: string;
  userAgent?: string;
  userAgentDeviceCategory?: string;
  userAgentFamily?: string;
  userAgentName?: string;
  userAgentOsFamily?: string;
  userAgentOsVendor?: string;
  userAgentOsVersion?: string;
  userAgentType?: string;
  userAgentVendor?: string;
  userAgentVersion?: string;
  userId?: string;
  validDomain?: string;
  viewportPixelHeight?: number;
  viewportPixelWidth?: number;
  xforwardedFor?: string;
};
export type AggregationResult = object;
export type MultiAggsSerieItemObject = {
  docCount?: number;
  buckets?: {
    [key: string]: AggregationResult;
  };
};
export type MultiAggsSerieDto = {
  bucketingDimensions?: string[];
  data?: {
    [key: string]: MultiAggsSerieItemObject[];
  };
  kpis?: string[];
};
export type Count = {
  count?: number;
};
export type CohortAnalysisLine = {
  dateType?: 'YEAR' | 'MONTH' | 'WEEK' | 'DAY';
  startDate?: string;
  data?: {
    [key: string]: Count;
  };
};
export type SessionSummary = {
  sessionId?: string;
  firstEventEpochSeconds?: number;
  lastEventEpochSeconds?: number;
};
export type SessionsPerUser = {
  user?: string;
  sessionCount?: number;
  sessions?: SessionSummary[];
};
export const {
  useGetUserInfoQuery,
  useGetUserSessionsQuery,
  useGetUserEventsQuery,
  useGetUserStatsQuery,
  useGetCohortAnalysisMatrixQuery,
  useGetMatrixCellUsersQuery,
} = injectedRtkApi;

import React, { useEffect } from 'react';

import * as echarts from 'echarts';
import world from 'app/shared/util/world.json';
import { useData } from 'app/modules/views/greensights/data/api';
import { getWorldMapData } from 'app/shared/util/worldMapData';
import { useSelectedChart } from 'app/modules/views/greensights/data/state';
import { ResponseWrapper } from 'app/modules/views/greensights/model/responseWrapper';
import MetricResponse from 'app/modules/views/greensights/model/metricResponse';
import { Typography } from '@mui/material';
import { translate } from 'react-jhipster';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import MapEchart from './MapEchart';
import {
  ResponseWrapperMetricResponse,
  useLazyLoadQuery,
} from 'app/shared/reducers/api/greensights-data-hub-cloud-services/store/greensights-data-hub';

const wordMap = {};
getWorldMapData().forEach(value => (wordMap[value.code] = value.name));
const callback = (response: ResponseWrapperMetricResponse) => {
  if (response.content.status === 'success') {
    const map = {};
    response.content.data.result.forEach(result => {
      const code = result.metric['country_code'];
      map[code] = 0;
      result.values.forEach(value => {
        map[code] += parseFloat('' + value[1]);
      });
    });
    return getWorldMapData().map(country => ({ name: country.name, value: map[country.code] || 0 }));
  }
  return [];
};

const MapChart = ({ fromDate, toDate }) => {
  const [load, { data = [], loading }] = useLazyLoadQuery({
    selectFromResult(response) {
      return { data: response.data ? callback(response.data) : [], loading: response.isLoading };
    },
  });
  const { selectedChart } = useSelectedChart();

  useEffect(() => {
    load(
      {
        accountId: '93090364',
        metricRequest: {
          start: fromDate,
          end: toDate,
          query: `sum(sum_over_time(${selectedChart.chart.key}{#filters})) by (country_code)`,
          filters: {
            scope: selectedChart.chart.scope,
          },
        },
      },
      true
    );
  }, [selectedChart, fromDate, toDate]);

  echarts.registerMap('world', world);

  return (
    <LayoutItem
      gridItemProps={{ xs: 12, lg: 4 }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h4" paddingBottom={1}>
              {translate(`greensights.line-chart.${selectedChart.chart.title}`)} {translate(`greensights.by-country`)}
            </Typography>
          ),
          sx: {
            py: 1,
            pb: 0,
          },
        },
        content: { sx: { height: '300px', py: 0, '&:last-child': { py: 0 } } },
      }}
    >
      <MapEchart data={data} loading={loading} />
    </LayoutItem>
  );
};

export default MapChart;

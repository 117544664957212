import React from 'react';

import { Button, Typography } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { FormBuilder } from 'app/modules/form-builder-lib';
import { getFormFields } from '../form/requestFilterFormFields';
import { useRequestFilterForm } from '../hooks/useRequestFilterForm';
import { translate } from 'react-jhipster';
import { useAppDispatch } from 'app/config/store';

export const AddRequestFilterForm = ({ filterValues, handleClose, setEditFilter, filter, filterActions }) => {
  const { addFilter, deleteFilter, selectAllFilters, selectFiltersIds } = filterActions;
  const { methods, defaultValue, onSubmit } = useRequestFilterForm(filterValues, filter, selectAllFilters, selectFiltersIds);
  const dispatch = useAppDispatch();

  return (
    <BasicCardLayout
      variant="div"
      header={{
        title: (
          <Typography variant="subtitle1">
            {filter === null
              ? translate('web-analytics.request-filter.add-filter', null, 'Add Filter')
              : translate('web-analytics.request-filter.edit', null, 'Edit Filter')}
          </Typography>
        ),
        disableTypography: true,
        sx: { p: 2, pb: 0 },
      }}
      card={{ sx: { width: '400px', mx: 'auto' } }}
      content={{ sx: { p: 2, '&:last-child': { p: 2 } } }}
      actions={
        <>
          {filter && (
            <Button
              color="error"
              onClick={() => {
                dispatch(deleteFilter(filter.key));
                methods.reset();
                setEditFilter(null);
              }}
              size="small"
              sx={{ mr: 'auto' }}
            >
              {translate('web-analytics.request-filter.delete', null, 'Delete Filter')}
            </Button>
          )}
          <Button
            color="error"
            onClick={() => {
              methods.reset();
              handleClose();
              if (filter) {
                setEditFilter(null);
              }
            }}
            size="small"
          >
            {translate('account.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onSubmit(addFilter);
              handleClose();
              if (filter) {
                setEditFilter(null);
              }
            }}
            size="small"
          >
            {translate('account.submit')}
          </Button>
        </>
      }
      actionsProps={{
        sx: {
          justifyContent: 'end',
          p: 2,
          pt: 0,
        },
      }}
    >
      <FormBuilder
        fields={getFormFields(filterValues, filter, selectAllFilters, selectFiltersIds, methods)}
        defaultValue={defaultValue}
        methods={methods}
        containerProps={{ spacing: 2 }}
      />
    </BasicCardLayout>
  );
};

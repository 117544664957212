import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, AccordionSummaryProps, Box, Chip, ChipProps, Stack, StackProps, Typography, Tooltip } from '@mui/material';
import React from 'react';
import { translate } from 'react-jhipster';

export interface IPaymentMainInfos {
  icon?: JSX.Element;
  mainInfos: string[] | React.ReactNode[];
  renderMainInfos?: (info: string | React.ReactNode, index: number) => JSX.Element;
  isDefault: boolean;
  setDefault: () => void;
  chipMainInfos?: ChipProps[];
  renderChipMainInfos?: (info: string, index: number) => JSX.Element;
}

interface IPaymentMethodAccordionSummaryProps extends AccordionSummaryProps {
  stackProps?: StackProps;
  chipProps?: ChipProps;
  paymentMainInfos: IPaymentMainInfos;
}

export const PaymentMethodAccordionSummary = ({ stackProps = {}, paymentMainInfos, ...rest }: IPaymentMethodAccordionSummaryProps) => {
  const renderMainInfos = (info: string | React.ReactNode, index: number): JSX.Element => {
    return typeof info === 'string' ? <Typography key={index}>{info}</Typography> : <React.Fragment key={index}>{info}</React.Fragment>;
  };

  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />} {...rest}>
      <Stack direction="row" alignItems="center" spacing={1} {...stackProps}>
        {paymentMainInfos.isDefault ? (
          <Chip color="primary" size="small" label={translate('payment-method.default', null, 'Default')} />
        ) : (
          <Tooltip title={translate('payment-method.set-default-payment-method', null, 'Set as default')}>
            <Chip
              color="neutral"
              size="small"
              variant="outlined"
              label={translate('payment-method.default', null, 'Default')}
              onClick={e => {
                e.stopPropagation();
                paymentMainInfos.setDefault();
              }}
              clickable
            />
          </Tooltip>
        )}
        <Box minWidth={15} />
        {paymentMainInfos.icon}
        {paymentMainInfos.mainInfos.map((mainInfo: string | React.ReactNode, index: number) => {
          return paymentMainInfos.renderMainInfos ? paymentMainInfos.renderMainInfos(mainInfo, index) : renderMainInfos(mainInfo, index);
        })}
        {paymentMainInfos.chipMainInfos?.map((chipProps: ChipProps, index: number) => {
          return chipProps && <Chip key={index} {...chipProps} />;
        })}
      </Stack>
    </AccordionSummary>
  );
};

import React from 'react';
import { EmailTwoTone, LocationOnTwoTone, LocationCityTwoTone, MarkunreadMailboxTwoTone, PublicTwoTone } from '@mui/icons-material';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';

export const BillingList = () => {
  const { billingAccount } = useAppAccountStates();

  return [
    {
      icon: EmailTwoTone,
      key: 'account.account-page.email',
      value: billingAccount.email ? billingAccount.email : '-',
    },
    {
      icon: LocationOnTwoTone,
      key: 'account.account-page.address',
      value: billingAccount.address1 ? billingAccount.address1 : '-',
    },
    { icon: LocationCityTwoTone, key: 'account.account-page.city', value: billingAccount.city ? billingAccount.city : '-' },
    {
      icon: MarkunreadMailboxTwoTone,
      key: 'account.account-page.postalCode',
      value: billingAccount.postalCode ? billingAccount.postalCode : '-',
    },
    {
      icon: PublicTwoTone,
      key: 'account.account-page.country',
      value: billingAccount.country ? <FlagWithCountryName country={billingAccount.country} /> : '-',
    },
  ];
};

import React from 'react';

import PopupState, { bindPopper, bindHover } from 'material-ui-popup-state';
import { InfoOutlined } from '@mui/icons-material';
import { Popper, Paper } from '@mui/material';
import { PropertiesGrid } from 'app/components/properties/properties-grid';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { SessionDevice } from '../session-infos-with-icons/SessionDevice';
import { SessionOs } from '../session-infos-with-icons/SessionOs';
import { SessionBrowser } from '../session-infos-with-icons/SessionBrowser.';

export const SessionInfoPopup = ({ session }) => {
  return (
    <PopupState variant="popper" popupId="session-info-popper">
      {popupState => (
        <>
          <InfoOutlined fontSize="small" {...bindHover(popupState)} />
          <Popper {...bindPopper(popupState)} placement="left" sx={{ zIndex: theme => theme.zIndex.appBar + 1 }}>
            <Paper
              sx={{
                p: 2,
                maxWidth: '400px',
              }}
            >
              <PropertiesGrid
                gridProps={{ spacing: 1 }}
                properties={[
                  [
                    'Country',
                    session.countryName ? (
                      <FlagWithCountryName country={session.countryName} sxContainer={{ justifyContent: 'right' }} />
                    ) : (
                      '-'
                    ),
                  ],
                  ['City', session.cityName || '-'],
                  ['OS', session.userAgentOsFamily ? <SessionOs os={session.userAgentOsFamily} /> : '-'],
                  ['Browser', session.userAgentName ? <SessionBrowser browser={session.userAgentName} /> : '-'],
                  ['Device', session.userAgentDeviceCategory ? <SessionDevice device={session.userAgentDeviceCategory} /> : '-'],
                ]}
              />
            </Paper>
          </Popper>
        </>
      )}
    </PopupState>
  );
};

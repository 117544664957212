import React from 'react';

import { ListItemIcon, Drawer, Toolbar, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Outlet } from 'react-router-dom';
import { StyledDrawer } from './StyledDrawer';
import { setOpenMobileVersion } from 'app/shared/reducers/main-layouts/right-sidebar';
import { Box } from '@mui/system';
import { ChevronLeft } from '@mui/icons-material';

export const SidebarContainer = () => {
  const { open, openMobileVersion } = useAppSelector(state => state.mainLayouts.rightSidebar);
  const { appBarHeight } = useAppSelector(state => state.mainLayouts.appbarReducer);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  return (
    <>
      <StyledDrawer
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
        variant="permanent"
        open={open}
        theme={theme}
        anchor="right"
      >
        <Toolbar sx={{ minHeight: `${appBarHeight}px !important` }} />

        <Outlet />
      </StyledDrawer>
      <Box sx={{ display: { xs: 'flex', md: 'none', alignItems: 'center', borderLeft: `1px solid ${theme.palette.divider}` } }}>
        <IconButton
          onClick={() => {
            dispatch(setOpenMobileVersion(true));
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Box>
      <Drawer
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 88 },
        }}
        variant="temporary"
        anchor={'right'}
        open={openMobileVersion}
        onClose={() => {
          dispatch(setOpenMobileVersion(false));
        }}
      >
        <Outlet />
      </Drawer>
    </>
  );
};

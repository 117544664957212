import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { CONFIG_MANAGER_SERVICE_URL } from 'app/shared/api/constants';
import { axiosBaseQuery } from 'app/shared/api/microservices';

const staggeredBaseQuery = retry(axiosBaseQuery({ baseUrl: CONFIG_MANAGER_SERVICE_URL }), {
  maxRetries: 0,
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptyConfigManagerCloudServicesApi = createApi({
  reducerPath: 'configManagerCloudServicesApi',
  baseQuery: staggeredBaseQuery,
  tagTypes: [
    'USER_PROFILE',
    'ACCOUNT_PROFILE',
    'ACCOUNT_LIST',
    'BILLING_ACCOUNT',
    'MEMBERS',
    'PAYMENT_METHOD',
    'SUBSCRIPTION_LIST',
    'INVOICES_LIST',
    'API_KEYS_LIST',
    'SUBSCRIPTION_SETTINGS',
  ],
  endpoints: () => ({}),
});

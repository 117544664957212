import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Svg, Path, PDFDownloadLink } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useAppSelector } from 'app/config/store';
import { translate } from 'react-jhipster';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 8,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 30,
  },
  header: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
    borderBottom: '1px thin rgba(0, 0, 0, 0.12)',
    fontWeight: 'normal',
    hurenceIcon: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    invoiceInfo: {
      flexGrow: 1,
    },
    filler: {
      flexGrow: 4,
    },
  },
  companyInfo: {
    flexDirection: 'row',
    width: '50%',
  },
  invoiceInfo: {
    width: '50%',
    textAlign: 'right',
  },
  textRight: { textAlign: 'right' },
  invoiceTitle: {
    fontWeight: 'bold',
  },
  invoiceData: {},
  itemsTable: {
    marginTop: 10,
    paddingHorizontal: 10,
  },
  itemRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  itemCell: {
    width: '20%',
    // borderRightWidth: 1,
    // borderColor: '#000',
    paddingRight: 8,
  },
  itemCellSecond: {
    width: '30%',
    // borderRightWidth: 1,
    // borderColor: '#000',
    paddingRight: 8,
  },
  itemCellFirst: {
    width: '50%',
    paddingRight: 8,
  },
  totalRow: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    height: 24,
    // marginTop: 10,
  },
  totalLabel: {
    width: '80%',
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 12,
  },
  totalPrice: {
    width: '20%',
    textAlign: 'right',
    paddingRight: 8,
    fontWeight: 'bold',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 60,
    right: 60,
    textAlign: 'center',
  },
  icon: {
    marginHorizontal: 5,
  },
});

// Invoice data
const invoiceData = {
  id: 'INV-001',
  date: 'June 30, 2023',
  recipient: {
    name: 'John Doe',
    address: '123 Main St, City, State, ZIP',
  },
  items: [{ module: 'recommendations.annual', billingPeriod: 'Apr 12, 2023 - Jun 12,2023', amount: 333.33 }],
};

// Create your PDF document component
export const InvoiceDocument = ({ invoice }) => {
  // const { currentLocale }: any = useAppSelector(state => state.locale);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <Text style={styles.title}>Invoice</Text> */}

        <View style={styles.header}>
          <View style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Image src="content/img/hurence-logo.png" style={{ height: '40px', width: '40px' }} />
            <Text style={{ fontSize: 26, marginLeft: '5px', fontWeight: 'normal' }}>Hurence</Text>
            {/* <Text style={{ fontSize: 26, marginLeft: '5px', fontWeight: 'bold' }}>Hurence</Text> */}
          </View>
          <View style={styles.header.filler}></View>
          <View style={styles.header.invoiceInfo}>
            <Text style={{ fontSize: 20, marginLeft: 'auto' }}>{translate('account.invoice-document.invoice', null, 'Invoice')}</Text>
            <View style={{ flexDirection: 'column', fontSize: 10, marginLeft: 'auto' }}>
              <View style={{ flexDirection: 'row', marginLeft: 'auto' }}>
                <Text style={{ fontWeight: 'bold' }}>{translate('account.invoice-document.date', null, 'Date')} :</Text>
                <Text style={{ fontWeight: 'light' }}>{invoice.date}</Text>
              </View>
              <View style={{ flexDirection: 'row', marginLeft: 'auto' }}>
                <Text style={{ fontWeight: 'bold' }}>{translate('account.invoice-document.invoice', null, 'Invoice')} :</Text>
                <Text>{invoice.number}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: 'row', padding: 10, marginTop: 10 }}>
          <View style={{ flexGrow: 1, border: '1px thin rgba(0, 0, 0, 0.12)', padding: 10 }}>
            <Text style={{ fontSize: 18, marginBottom: 5 }}>
              {translate('account.invoice-document.invoice-issuer-details', null, 'Invoice Issuer Details')}
            </Text>
            <Text>HURENCE</Text>

            <Text>400 chemin des Longs Prés</Text>
            <Text>38560 Lumbin</Text>
            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              <Svg height="6px" width="6px" viewBox="0 0 512 512" style={styles.icon}>
                <Path
                  fill="black"
                  d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                />
              </Svg>
              <Text>06 50 89 27</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Svg height="6px" width="6px" viewBox="0 0 512 512" style={styles.icon}>
                <Path
                  fill="black"
                  d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                />
              </Svg>
              <Text>laurence.hubert@hurence.com</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Svg height="6px" width="6px" viewBox="0 0 576 512" style={styles.icon}>
                <Path
                  fill="black"
                  d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V352H64V64H512z"
                />
              </Svg>
              <Text>http://hurence.com</Text>
            </View>
          </View>
          <View style={{ flexGrow: 5 }}></View>
          <View style={{ flexGrow: 1, border: '1px thin rgba(0, 0, 0, 0.12)', padding: 10 }}>
            <Text style={{ fontSize: 18, marginBottom: 5, marginLeft: 'auto' }}>
              {translate('account.invoice-document.invoice-recipient', null, 'Invoice Recipient')}
            </Text>
            <Text style={{ marginLeft: 'auto' }}>{invoice.company}</Text>

            <Text style={{ marginLeft: 'auto' }}>{invoice.address}</Text>

            <View style={{ flexDirection: 'row', marginTop: 5, marginLeft: 'auto' }}>
              <Text>{invoice.email}</Text>
              <Svg height="6px" width="6px" viewBox="0 0 512 512" style={styles.icon}>
                <Path
                  fill="black"
                  d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                />
              </Svg>
            </View>
            <View style={{ flexDirection: 'row', marginLeft: 'auto' }}>
              <Text>{invoice.currency}</Text>
              <Svg height="6px" width="6px" viewBox="0 0 512 512" style={styles.icon}>
                <Path
                  fill="black"
                  d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"
                />
              </Svg>
            </View>
            <View style={{ flexDirection: 'row', marginLeft: 'auto' }}>
              <Text>{invoice.accountID}</Text>
              <Svg height="6px" width="6px" viewBox="0 0 512 512" style={styles.icon}>
                <Path
                  fill="black"
                  d="M256 48V64c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H256zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM160 320h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"
                />
              </Svg>
            </View>
          </View>
        </View>

        <View style={styles.itemsTable}>
          <View style={{ fontSize: 12, ...styles.itemRow }}>
            <Text style={styles.itemCellFirst}> {translate('account.invoice-document.module', null, 'Module')}</Text>
            <Text style={styles.itemCellSecond}> {translate('account.invoice-document.billing-period', null, 'Billing Period')}</Text>
            <Text style={styles.itemCell}>
              {' '}
              {translate('account.invoice-document.amount', null, 'Amount')} {`(${invoice.currency})`}
            </Text>
          </View>

          {invoice.items &&
            invoice.items.map((item, index) => (
              <View style={styles.itemRow} key={index}>
                <Text style={styles.itemCellFirst}>{item.module}</Text>
                <Text style={styles.itemCellSecond}>{item.billingPeriod}</Text>
                <Text style={styles.itemCell}>{item.amount}</Text>
              </View>
            ))}

          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>{translate('account.invoice-document.amount', null, 'Amount')}</Text>
            <Text style={styles.totalPrice}>
              {invoice.amount} {invoice.currency}
            </Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>{translate('account.invoice-document.paid-amount', null, 'Paid Amount')}</Text>
            <Text style={styles.totalPrice}>
              {invoice.paidAmount} {invoice.currency}
            </Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>{translate('account.invoice-document.balance', null, 'Balance')}</Text>
            <Text style={styles.totalPrice}>
              {invoice.balance} {invoice.currency}
            </Text>
          </View>
        </View>

        <View style={styles.footer}>
          <Text>Hurence</Text>
          <Text>Société par actions simplifiée (SAS) - Capital de 10 000 € - SIRET: 532 001 146 00036</Text>
          <Text>NAF-APE: 6201Z - RCS/RM 532 001146 R.C.S Grenoble - Numéro TVA: FR79532001146</Text>
        </View>
      </Page>
    </Document>
  );
};

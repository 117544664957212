import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appBarHeight: 0,
};

export type AppbarState = Readonly<typeof initialState>;

export const AppbarSlice = createSlice({
  name: 'appbar',
  initialState: initialState as AppbarState,
  reducers: {
    setAppBarHeight(state, action) {
      state.appBarHeight = action.payload;
    },
  },
});

export const { setAppBarHeight } = AppbarSlice.actions;

export default AppbarSlice.reducer;

import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FieldProp, validationUtils } from 'app/modules/form-builder-lib';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useFindAllCorporaQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { useForm } from 'react-hook-form';
import { translate } from 'react-jhipster';
import * as yup from 'yup';

export const recommendersList = [
  {
    label: 'recommendation.strategies.global',
    isGroup: true,
    value: 'global',
  },
  { label: 'recommendation.strategies.random', value: 'random' },
  { label: 'recommendation.strategies.popularity', value: 'popularity' },
  { label: 'recommendation.strategies.trending', value: 'trending' },
  {
    label: 'recommendation.strategies.personalization',
    isGroup: true,
    value: 'personalization',
  },
  { label: 'recommendation.strategies.collaborative-filtering', value: 'als' },
  { label: 'recommendation.strategies.boosted-terms', value: 'boosted_terms' },
  // { label: 'More like this', value: 'more_like_this' },
];

export const useRecoForm = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});

  const { corpusList, isSuccess } = useFindAllCorporaQuery(
    accountProfile.accountId ? { accountId: accountProfile.accountId.substring(0, 8) } : skipToken,
    {
      selectFromResult(res) {
        return {
          ...res,
          corpusList: res.data ? res.data.content.map(corpus => corpus.id) : [],
        };
      },
    }
  );

  const getFields = (watch?): Array<FieldProp> => {
    return [
      {
        component: 'autocomplete',
        options: corpusList.sort(),
        attribute: 'corpus',
        label: translate('recommendation.corpus.corpus-name', undefined, 'Corpus'),
        validationType: 'string',
        validations: [['required', '']],
        col: { xs: 2 },
      },
      {
        component: 'select',
        options: recommendersList.map(item => {
          return {
            value: item.value,
            label: item.label,
            ...(item.isGroup ? { isGroup: item.isGroup } : {}),
          };
        }),
        attribute: 'strategy',
        label: translate('recommendation.form-attributes.strategy', undefined, 'Strategy'),
        optionConfig: { value: 'value', key: 'value', label: 'label', isGroup: 'isGroup' },
        validationType: 'string',
        validations: [['required', 'Hi']],
        props: {
          onChange(event) {
            methods.resetField('itemId');
            methods.resetField('profileId');
          },
        },
        col: { xs: 2 },
      },
      {
        component: 'text-field',
        attribute: 'itemId',
        label: translate('recommendation.form-attributes.itemId', undefined, 'Item Id'),
        // This will hide the form field if the condition is true
        hideCondition: watch ? watch[0] !== 'more_like_this' : undefined,
        validations: [['when', ['strategy', { is: val => val === 'more_like_this', then: () => yup.string().required() }]]],
        col: {
          md: 2,
        },
      },
      {
        component: 'text-field',
        attribute: 'profileId',
        label: translate('recommendation.form-attributes.profileId', undefined, 'Profile Id'),
        // This will hide the form field if the condition is true
        hideCondition: watch ? watch[0] !== 'als' && watch[0] !== 'boosted_terms' : undefined,
        validations: [['when', ['strategy', { is: val => val === 'als' || val === 'boosted_terms', then: () => yup.string().required() }]]],
        col: {
          md: 2,
        },
      },
      {
        component: 'select',
        attribute: 'trendingScale',
        label: translate('recommendation.form-attributes.trendingScale', undefined, 'Trending Scale'),
        options: [
          { label: translate('recommendation.form-attributes.short-term', undefined, 'Short term'), value: 'trending_short' },
          { label: translate('recommendation.form-attributes.long-term', undefined, 'Long term'), value: 'trending_long' },
        ],
        optionConfig: { value: 'value', key: 'value', label: 'label' },
        // This will hide the form field if the condition is true
        hideCondition: watch ? watch[0] !== 'trending' : undefined,
        col: {
          md: 2,
        },
      },
    ];
  };

  const schema = validationUtils.getFormSchema(getFields());
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });
  return {
    getFields,
    methods,
    corpusList,
    isSuccess,
  };
};

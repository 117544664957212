import { combineReducers } from 'redux';
import appbarReducer from './appbar';
import menubarReducer from './menubar';
import rightSidebar from './right-sidebar';
import leftSidebar from './left-sidebar';

const mainLayouts = combineReducers({
  appbarReducer,
  menubarReducer,
  rightSidebar,
  leftSidebar,
});

export default mainLayouts;

import { Button } from '@mui/material';
import React from 'react';
import { StepProps } from './WizardStepper';

export function useStepperState(steps: StepProps[]) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const optionalSteps = new Set(steps.map((step, index) => step.optional && index));

  // const [completed, setCompleted] = React.useState<{ [k: number]: boolean; }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const isFirstStep = step => {
    return step === 0;
  };

  const isLastStep = step => {
    return step === totalSteps() - 1;
  };

  // const completedSteps = () => {
  //   return Object.keys(completed).length;
  // };

  // const allStepsCompleted = () => {
  //   return completedSteps() === totalSteps();
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    const newSkipped = new Set(skipped.values());
    newSkipped.delete(activeStep);

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    const newSkipped = new Set(skipped.values());
    newSkipped.add(activeStep);

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const isStepOptional = (step: number) => {
    return optionalSteps.has(step);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const isStepCompleted = (step: number) => {
    // steps.find(v => v === step);
    return isStepSkipped(step) ? false : undefined;
  };

  function BackButton({ step }) {
    return (
      <Button disabled={isFirstStep(step)} onClick={handleBack}>
        Back
      </Button>
    );
  }

  function SkipButton() {
    return (
      <Button color="neutral" onClick={handleSkip}>
        Skip
      </Button>
    );
  }

  function NextButton({ step }) {
    return (
      <Button variant="contained" onClick={handleNext}>
        {step === totalSteps() - 1 ? 'Finish' : 'Continue'}
      </Button>
    );
  }

  const methods = {
    totalSteps,
    isFirstStep,
    isLastStep,
    handleReset,
    handleBack,
    handleNext,
    handleSkip,
    handleStep,
    isStepCompleted,
    isStepOptional,
    isStepSkipped,
  };

  const buttons = {
    BackButton,
    SkipButton,
    NextButton,
  };

  return { activeStep, methods, buttons };
}

export type StepperState = ReturnType<typeof useStepperState>;

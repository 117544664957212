import { mediaScreenTypeQueries } from 'app/shared/reducers/views/recommendations/widget';
import { merge } from 'merge-anything';

function stringToValidCSSClass(str: string): string {
  // Remove leading and trailing whitespace
  str = str.trim();

  // Replace characters that are not allowed in CSS class names with underscores
  str = str.replace(/[^a-zA-Z0-9_-]/g, '_');

  // Remove consecutive underscores
  str = str.replace(/_+/g, '_');

  // Ensure the class name starts with a letter (a-z or A-Z)
  if (!/^[a-zA-Z]/.test(str)) {
    str = 'class_' + str;
  }

  return str;
}

function stringToValidCSSClasses(str: string): string[] {
  return str.split(' ').filter(Boolean).map(stringToValidCSSClass);
}

const screenMaxWidth = {
  tablet: 1024,
  phone: 640,
};

const generateCSS = cssProperties => {
  return (
    cssProperties &&
    Object.entries(cssProperties)
      .filter(([property, value]) => property !== 'id')
      .map(([property, value]) => `${property}: ${String(value)};`)
      .join('\n')
  );
};

const generateMediaCSS = (style, screenQuery: string) => style && `@media screen ${screenQuery} { ${generateCSS(style)} }`;

const wrapElementWithAnchorTag = (elementString, link) => {
  return `<a href={{${link}}} target="_blank" class="no-style-anchor">${elementString}</a>`;
};

export const generateHtmlElement = (element, children?) => {
  const { value, tag = 'div', link, classname, style = {} } = element;

  const mediaStyles = Object.entries(style as { [key: string]: Record<'id', string>[] })
    .map(([media, styles]) => {
      const mergedStyle = styles.reduce((acc, f) => merge(acc, f), {});
      if (mediaScreenTypeQueries[media] && Object.keys(mergedStyle).length > 0) {
        return generateMediaCSS(mergedStyle, mediaScreenTypeQueries[media]);
      } else {
        return generateCSS(mergedStyle);
      }
    })
    .filter(Boolean);

  const valueClassname = stringToValidCSSClass(`sj-elem-${value}`);
  const styleString =
    mediaStyles.length > 0
      ? `.${valueClassname} {
      ${mediaStyles.join('\n')}
    }`
      : undefined;

  const extraClassnames = classname ? `${stringToValidCSSClasses(classname).join(' ')}` : '';
  const content = children ?? `{{${value}}}`;

  let el;
  switch (tag) {
    case 'img':
      el = `<${tag} class="${valueClassname} ${extraClassnames}" src="{{${value}}}" />`;
      break;
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
    case 'p':
    case 'span':
      el = `<div class="${valueClassname} sj-typo-${tag} ${extraClassnames}" >${content}</div>`;
      break;
    // Handle unknown tag types with provided default behavior
    default:
      el = `<div class="${valueClassname} ${extraClassnames}">${content}</div>`;
  }

  const elementString = link ? wrapElementWithAnchorTag(el, link) : el;

  return {
    style: styleString,
    template: elementString,
  };
};

export const generateContainerItemStyles = containerItemStyle => {
  const { style } = containerItemStyle;
  const cssObject = {} as any;

  Object.keys(style).forEach(size => {
    const styleBase = style[size].base;
    styleBase.forEach(property => {
      const value = property.value;
      if (value !== '') {
        if (!cssObject[size]) {
          cssObject[size] = {};
        }
        cssObject[size][property.key] = value;
      }
    });
    const addStyle = style[size].style;
    addStyle.forEach(property => {
      const value = property.value;
      if (value !== '') {
        if (!cssObject[size]) {
          cssObject[size] = {};
        }
        cssObject[size][property.key] = value;
      }
    });
  });

  const fullCss = `
    ${cssObject.large ? `${generateCSS(cssObject.large)} \n` : ''}
    ${cssObject.medium ? `@media screen and (max-width: 1024px) { ${generateCSS(cssObject.medium)} } \n` : ''}
    ${cssObject.small ? `@media screen and (max-width: 600px) { ${generateCSS(cssObject.small)} } \n` : ''}`;

  return fullCss;
};

export function generateCssObjectFromStyle(style) {
  const cssObject = {} as any;

  if (style) {
    // Process typography
    Object.keys(style?.typography).forEach(size => {
      const typographyBase = style.typography[size].base;
      typographyBase.forEach(property => {
        const value = property.value;
        if (value !== '') {
          if (!cssObject[size]) {
            cssObject[size] = {};
          }
          cssObject[size][property.key] = value;
        }
      });
    });

    // Process spacing
    Object.keys(style?.spacing).forEach(size => {
      const spacingBase = style.spacing[size].base;
      spacingBase.forEach(property => {
        const value = property.value;
        if (value !== '') {
          if (!cssObject[size]) {
            cssObject[size] = {};
          }
          cssObject[size][property.key] = value;
        }
      });
    });
  }

  return cssObject;
}

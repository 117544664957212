import { useTimeRange } from 'app/components/filter-wrapper/useTimeRangeFilter';
import { usePresistedRecommendationsKpisForm } from './usePresistedRecommendationsKpisForm';
import { useRecommendationsKpisFormFields } from './useRecommendationsKpisFormFields';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useActionsKpis } from './data/useActionsKpis';
import { useImpressionsKpis } from './data/useImpressionsKpis';

export const useRecommendationsKpis = () => {
  const { requestParams, formParams } = usePresistedRecommendationsKpisForm();
  const timerange = useTimeRange({ chosenRange: 'recommendation.DatePicker.SincePeriod', fromDate: 'now-1M' });
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const impressions = useImpressionsKpis(timerange, requestParams, accountProfile);
  const actions = useActionsKpis(timerange, requestParams, accountProfile);
  const { fields } = useRecommendationsKpisFormFields(actions.actionsList);

  return { fields, formParams, timerange, data: { impressions, actions } };
};

import { FieldProp } from 'app/modules/form-builder-lib';
import { translate } from 'react-jhipster';
import { Box } from '@mui/system';
import React from 'react';

const localeOptions = ['en_US', 'fr_FR'];

export const accountEditAttributes: Array<FieldProp> = [
  {
    component: 'text-field',
    attribute: 'name',
    label: 'account.account-page.name',
    validationType: 'string',
    props: {
      autoFocus: true,
    },
    validations: [
      ['required', true],
      ['matches', [/[a-z]/i, 'Can only contain letters']],
    ],
  },
  {
    component: 'text-field',
    attribute: 'phone',
    label: 'account.account-page.phone',
  },
  {
    component: 'text-field',
    attribute: 'company',
    label: 'account.account-page.company',
  },
  {
    component: 'autocomplete',
    options: localeOptions,
    attribute: 'locale',
    label: 'account.account-page.locale',
    props: {
      renderOption: (props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {translate(`language.${option}`)}
        </Box>
      ),
      getOptionLabel(option) {
        if (option !== '') return translate(`language.${option}`);
        else return '';
      },
    },
  },
  {
    component: 'text-field',
    validationType: 'string',
    props: {
      multiline: true,
      rows: 2,
    },
    attribute: 'notes',
    label: 'account.account-page.notes',
  },
];

import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useDeleteCorpusMutation } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { PropertiesGrid } from 'app/components/properties/properties-grid';
import { Translate, translate } from 'react-jhipster';

export const ConfirmDeleteCorpus = ({ setOpen, corpus }) => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const [deleteItem] = useDeleteCorpusMutation();

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Dialog fullWidth={true} open={corpus !== null} onClose={handleClose} maxWidth="sm">
      <DialogTitle typography="h4">
        <Translate contentKey="recommendation.corpus.corpus-table.delete-corpus">Delete Corpus</Translate>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Translate contentKey="recommendation.corpus.confirm-delete-corpus">Confirm deleting corpus :</Translate>
        </DialogContentText>
        <PropertiesGrid
          gridProps={{ spacing: 1, mt: 1 }}
          properties={[
            ['ID :', corpus?.id],
            ['Name :', corpus?.name],
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translate('recommendation.cancel', null, 'Cancel')}</Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            deleteItem({ accountId: accountProfile?.accountId.substring(0, 8), id: corpus?.id });
            handleClose();
          }}
        >
          {translate('recommendation.corpus.corpus-table.delete-corpus', null, 'Delete corpus')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';

import { Tooltip, IconButton, Button } from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ApiIcon from '@mui/icons-material/Api';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box } from '@mui/system';
import { translate } from 'react-jhipster';
import { ProductNames } from 'app/shared/model/catalog.model';
import { useProductCardSecondary } from '../../hooks/product-card/useProductCardSecondary';
import { ProductCardMenu } from './ProductCardMenu';

export const ProductCardSecondary = ({ productName }: { productName: ProductNames }) => {
  const { isProductMultiple, isUserAdmin, isSubscribed } = useProductCardSecondary(productName);
  return (
    <Box>
      {isUserAdmin && isProductMultiple && (
        <Tooltip title={translate('products.multiple-products-available', null, 'Multiple products available')}>
          <IconButton color="warning">
            <WarningAmberIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={translate('products.help', null, 'Help')}>
        <IconButton color="primary">
          <HelpCenterIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={translate('products.api-doc', null, 'Api documentation')}>
        <Button variant="text" startIcon={<ApiIcon />}>
          Api doc
        </Button>
      </Tooltip>
      {isSubscribed && <ProductCardMenu productName={productName} />}
    </Box>
  );
};

import { useAppSelector } from 'app/config/store';
import {
  checkIfOtherYearExist,
  getDates,
  mapCohortDataIfNoOtherYearExist,
  mapCohortDataIfOtherYearExist,
} from 'app/modules/views/user-exploration/components/cohort/functions/cohortHelper';
import { translate } from 'react-jhipster';
import { ICohort } from 'app/shared/model/user-exploration/cohort.model';

export const useCohortUtils = cohortData => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const { timeStep } = useAppSelector(state => state.webAnalyticsOverview.usersCohort.params);

  const values: number[][] = [];
  let maxValue = 0;

  cohortData.forEach((row: ICohort, rowIndex: number) => {
    const yearExist = checkIfOtherYearExist(row.data);

    if (yearExist.isYearExist) {
      const { rowValues, maxRowValues } = mapCohortDataIfOtherYearExist(row, rowIndex, yearExist.index, 'count');
      values.push(...rowValues);
      if (maxRowValues > maxValue) maxValue = maxRowValues;
    } else {
      const { rowValues, maxRowValues } = mapCohortDataIfNoOtherYearExist(row, rowIndex, 'count');
      values.push(...rowValues);
      if (maxRowValues > maxValue) maxValue = maxRowValues;
    }
  });

  let usedYear: number = new Date().getFullYear();

  const yAxisData: string[] = cohortData.map((item: ICohort, itemIndex: number) => {
    if (timeStep === 'YEAR') return item.startDate;
    if (itemIndex === 0 || item.startDate < cohortData[itemIndex - 1].startDate) {
      return getDates(usedYear, item.startDate, timeStep, currentLocale);
    } else {
      usedYear = usedYear - 1;
      return getDates(usedYear, item.startDate, timeStep, currentLocale);
    }
  });

  const xAxisData: string[] = cohortData.map((item: ICohort, itemIndex: number) => {
    if (timeStep === 'DAY') return `${translate('user-exploration.cohort.day')} ${itemIndex}`;
    if (timeStep === 'WEEK') return `${translate('user-exploration.cohort.week')} ${itemIndex}`;
    if (timeStep === 'MONTH') return `${translate('user-exploration.cohort.month')} ${itemIndex}`;
    if (timeStep === 'YEAR') return `${translate('user-exploration.cohort.year')} ${itemIndex}`;
  });

  return {
    xAxisData,
    yAxisData,
    values,
    maxValue,
  };
};

import React, { useState } from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Button, Tooltip, Typography } from '@mui/material';
import { AccountBalanceWallet, Info, WarningAmber } from '@mui/icons-material';
import { translate, Translate } from 'react-jhipster';
import { useForm } from 'react-hook-form';
import { Edit } from 'tabler-icons-react';
import { BillingAttributes } from './form-attributes/BillingAttributes';
import { FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { AccountDtoReq, useUpdateAccountUsingPutMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';

export const BillingItem = () => {
  const [mode, setMode] = useState('display');
  const { billingAccount, accountProfile } = useAppAccountStates();
  const [updateBillingAccount] = useUpdateAccountUsingPutMutation();
  const schema = validationUtils.getFormSchema(BillingAttributes()[mode]);
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const billingMissingInfo =
    !billingAccount.name ||
    billingAccount.name === '' ||
    !billingAccount.locale ||
    billingAccount.locale === '' ||
    !billingAccount.company ||
    billingAccount.company === '' ||
    !billingAccount.phone ||
    billingAccount.phone === '' ||
    !billingAccount.notes ||
    billingAccount.notes === '';

  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.billing">Billing</Translate>
            </Typography>
          ),
          avatar: <AccountBalanceWallet color="primary" />,
          disableTypography: true,
          action: billingMissingInfo ? (
            <Tooltip title={<Translate contentKey="module-factory.accordion.missing-billing-details">Missing billing details</Translate>}>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<WarningAmber color="warning" fontSize="small" />}
                  onClick={e => {
                    e.stopPropagation();
                    setMode('edit');
                  }}
                  disabled={mode === 'edit'}
                >
                  <Translate contentKey="module-factory.accordion.configure">Configure</Translate>
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Button
              variant="outlined"
              size="small"
              startIcon={<Edit />}
              onClick={e => {
                e.stopPropagation();
                setMode('edit');
              }}
              disabled={mode === 'edit'}
            >
              <Translate contentKey="account.account-page.edit">Edit</Translate>
            </Button>
          ),
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        ...(mode === 'edit' && {
          actions: (
            <>
              <Button
                color="error"
                onClick={() => {
                  setMode('display');
                }}
              >
                {translate('account.cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  methods.handleSubmit(data => {
                    if (accountProfile.accountId) {
                      updateBillingAccount({ accountKey: accountProfile.accountId, accountDtoReq: data as AccountDtoReq }).then(r =>
                        setMode('display')
                      );
                    }
                  })();
                }}
              >
                {translate('account.submit')}
              </Button>
            </>
          ),
        }),
      }}
    >
      <FormBuilder
        fields={BillingAttributes()[mode]}
        defaultValue={{
          email: billingAccount.email ? billingAccount.email : '',
          country: billingAccount.country ? billingAccount.country : '',
          address1: billingAccount.address1 ? billingAccount.address1 : '',
          city: billingAccount.city ? billingAccount.city : '',
          postalCode: billingAccount.postalCode ? billingAccount.postalCode : '',
        }}
        methods={methods}
        optionalMarkerDefault={true}
        containerProps={{ marginY: 0.5, spacing: 2, width: '100%' }}
      />
    </LayoutItem>
  );
};

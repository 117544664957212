import { Code, DataObject, Link, Style } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { TabItem, Tabulate } from 'app/components/tabulation';
import { useAppSelector } from 'app/config/store';
import StandardSelect from 'app/modules/form-builder-lib/Components/StandardSelect';
import StandardTextField from 'app/modules/form-builder-lib/Components/StandardTextField';
import { getRecoWidgetMediaScreenType } from 'app/shared/reducers/views/recommendations/widget';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { MediaStyleFormType } from '../hooks/forms/useMediasStyleForm';
import { badgeCircular, iconSxHover, popoverPosition } from '../utils/CommonPopoverStyles';
import { cssStylesTabs } from '../utils/CssFields';
import { tagIcons } from '../utils/CssLayout';
import { StylesFormBuilder } from './StylesFormBuilder';
import { IconButtonPopover } from './popover/IconButtonPopover';

export function countModifiedValues(obj?: FieldValues) {
  if (obj) {
    return Object.entries(obj).filter(el => el[0] !== 'id' && el[1] != null).length;
  }
  return 0;
}

export function countModifiedStyles(styles: Record<'id', string>[]) {
  return styles.reduce((acc, obj: FieldValues) => acc + countModifiedValues(obj), 0);
}

interface StylesActionBarProps {
  methods: UseFormReturn<any, any>;
  style: MediaStyleFormType;
  hideButtons?: ('link' | 'classname' | 'style' | 'tag')[];
}

export const StylesActionBar = ({ methods, style, hideButtons }: StylesActionBarProps) => {
  const mediaScreenType = useAppSelector(getRecoWidgetMediaScreenType);
  const { watch } = methods;

  const modifiedValueCount = countModifiedStyles(style[mediaScreenType].fields);
  const TagIcon = tagIcons[watch('tag')] ?? <Code />;

  return (
    <Stack direction="row" spacing={1}>
      {!hideButtons?.includes('link') && (
        <IconButtonPopover
          icon={<Link sx={iconSxHover} />}
          badge={{ ...badgeCircular, variant: 'dot', invisible: !watch('link') }}
          tooltip={{ title: 'Item Link' }}
          popover={popoverPosition}
        >
          <StandardTextField
            methods={methods}
            field={{
              component: 'text-field',
              attribute: 'link',
              label: 'Link property name',
            }}
          />
        </IconButtonPopover>
      )}

      {!hideButtons?.includes('tag') && (
        <IconButtonPopover
          icon={TagIcon}
          badge={{ ...badgeCircular, variant: 'dot', invisible: !watch('tag') }}
          tooltip={{ title: 'Item Tag' }}
          popover={popoverPosition}
        >
          <StandardSelect
            field={{
              component: 'select',
              attribute: 'tag',
              label: 'Choose Item Tag',
              options: ['img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'].map(e => ({
                label: e,
                value: e,
                icon: tagIcons[e],
              })),
              optionConfig: { value: 'value', key: 'value', label: 'label', icon: 'icon' },
              enableNoneOption: true,
              props: {
                sx: {
                  width: '150px',
                  '& .MuiInputBase-input': {
                    display: 'flex',
                  },
                },
              },
            }}
            methods={methods}
          />
        </IconButtonPopover>
      )}

      {!hideButtons?.includes('classname') && (
        <IconButtonPopover
          icon={<DataObject sx={iconSxHover} />}
          badge={{ ...badgeCircular, variant: 'dot', invisible: !watch('classname') }}
          tooltip={{ title: 'Classname' }}
          popover={popoverPosition}
        >
          <StandardTextField
            methods={methods}
            field={{
              component: 'text-field',
              attribute: 'classname',
              label: 'Classname',
            }}
          />
        </IconButtonPopover>
      )}

      {!hideButtons?.includes('style') && (
        <IconButtonPopover
          icon={<Style sx={iconSxHover} />}
          badge={{ ...badgeCircular, badgeContent: modifiedValueCount }}
          tooltip={{ title: 'Style' }}
          popover={popoverPosition}
        >
          <Tabulate variant="fullWidth">
            {cssStylesTabs?.map((item, index) => (
              <TabItem key={index} tab={item.tab} panel={{ sx: { width: '25vw' } }}>
                <StylesFormBuilder index={index} style={style[mediaScreenType]} fieldProps={item.fields} />
              </TabItem>
            ))}
          </Tabulate>
        </IconButtonPopover>
      )}

      {/* <IconButtonPopover
        icon={<SelectAll sx={iconSxHover} />}
        badge={{ ...badgeCircular, badgeContent: baseStyleCount }}
        tooltip={{ title: 'Style' }}
        popover={popoverPosition}
      >
        <Tabulate variant="fullWidth">
          <TabItem tab={{ value: 'laptop', icon: <Laptop fontSize="small" /> }}>
            <ItemBoxStyleTextFields styles={base.large} addStyle={style.large} path={'style.large'} methods={methods} />
          </TabItem>

          <TabItem tab={{ value: 'tablet', icon: <Tablet fontSize="small" /> }}>
            <ItemBoxStyleTextFields styles={base.medium} addStyle={style.medium} path={'style.medium'} methods={methods} />
          </TabItem>

          <TabItem tab={{ value: 'phone', icon: <Smartphone fontSize="small" /> }}>
            <ItemBoxStyleTextFields styles={base.small} addStyle={style.small} path={'style.small'} methods={methods} />
          </TabItem>
        </Tabulate>
      </IconButtonPopover> */}

      {/* <IconButtonPopover
        icon={<DataArray sx={iconSxHover} />}
        badge={{ ...badgeCircular, badgeContent: countModifiedValues(attributes.fields) }}
        tooltip={{ title: 'Attributes' }}
        popover={popoverPosition}
      >
        <ItemBoxAddAttributesTextFields attributes={attributes} path={'attributes'} methods={methods} />
      </IconButtonPopover> */}
    </Stack>
  );
};

import React from 'react';

import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { last3Month, last3Weeks, last6Weeks, last7Days } from './timeranges';
import { setParams } from 'app/shared/reducers/web-analytics/users-cohort';
import { Translate } from 'react-jhipster';

export const UsersCohortActions = () => {
  const { params } = useAppSelector(state => state.webAnalyticsOverview.usersCohort);
  const dispatch = useAppDispatch();

  return (
    <Select
      value={params}
      variant="standard"
      size="small"
      onChange={(event: SelectChangeEvent) => {
        dispatch(setParams(event.target.value));
      }}
    >
      <MenuItem value={last7Days as any}>
        <Translate contentKey="module-factory.timerange.7-days">Last 7 days</Translate>
      </MenuItem>
      <MenuItem value={last3Weeks as any}>
        <Translate contentKey="module-factory.timerange.3-weeks">Last 3 weeks</Translate>
      </MenuItem>
      <MenuItem value={last6Weeks as any}>
        <Translate contentKey="module-factory.timerange.6-weeks">Last 6 weeks</Translate>
      </MenuItem>
      <MenuItem value={last3Month as any}>
        <Translate contentKey="module-factory.timerange.3-months">Last 3 months</Translate>
      </MenuItem>
    </Select>
  );
};

import { ICatalog, Plan, ProductNames } from '../model/catalog.model';

export const getProductPlans = (catalog: ICatalog, productName: ProductNames): Plan[] => {
  return catalog.products.filter(el => el.name === productName)[0].plans;
};

export const getProducts = (catalog: ICatalog[]) => {
  return catalog[0].products.map(product => product.name);
};

export const getTrialPlan = (catalog: ICatalog[], productName: ProductNames) => {
  return catalog[0].products
    .filter(el => el.name === productName)[0]
    ?.plans.filter(el => el.name.startsWith([productName, 'trial'].join('.')))[0];
};

export const getMontlhyPlans = (catalog, productName) => {
  return catalog[0].products.filter(el => el.name === productName)[0]?.plans.filter(el => el.billingPeriod === 'MONTHLY');
};

export const getAnnualPlans = (catalog, productName) => {
  return catalog[0].products.filter(el => el.name === productName)[0]?.plans.filter(el => el.billingPeriod === 'ANNUAL');
};

export const getPlan = (catalog, productName, planName) => {
  return catalog[0].products.filter(el => el.name === productName)[0]?.plans.filter(el => el.name === planName)[0];
};

export const getMonthlyPrice = (prices, currency, billingPeriod) => {
  const price = prices.find(el => el.currency === currency)?.value || 0;

  const monthlyRatio = {
    DAILY: 365 / 12,
    WEEKLY: 52 / 12,
    BIWEEKLY: (1 / 2) * (52 / 12),
    THIRTY_DAYS: (1 / 30) * (365 / 12),
    MONTHLY: 1,
    QUARTERLY: 1 / 3,
    BIANNUAL: 1 / 6,
    ANNUAL: 1 / 12,
    BIENNIAL: 1 / 24,
    NO_BILLING_PERIOD: 0,
  };
  return price * monthlyRatio[billingPeriod];
};

export const getFinalPhase = plan => {
  return plan.phases.filter(el => el.duration.unit === 'UNLIMITED')[0] || plan.phases[plan.phases.length - 1];
};

export const getPlans = (catalog, productName) => {
  return catalog[0].products.reduce((products, product) => {
    return {
      ...products,
      [product.name]: {
        ...product,
        plans: product.plans.reduce((plans, plan) => {
          return {
            ...plans,
            [plan.name]: {
              ...plan,
              phases: plan.phases.map(phase => {
                return {
                  ...phase,
                  prices: phase.prices.reduce((prices, price) => {
                    return {
                      ...prices,
                      [price.currency]: price.value,
                    };
                  }, {}),
                };
              }),
            },
          };
        }, {}),
      },
    };
  }, {});
};

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestFilterEntry } from '../api/data-hub-cloud-services/store/data-exploration';

const filtersAdapter = createEntityAdapter<RequestFilterEntry>({
  selectId: filter => filter.key,
  sortComparer: (a, b) => a.key.localeCompare(b.key),
});

const initialState = filtersAdapter.getInitialState();

export const UserExplorationsFiltersSlice = createSlice({
  name: 'UserExplorationFilters',
  initialState,
  reducers: {
    addFilter: filtersAdapter.setOne,
    deleteFilter: filtersAdapter.removeOne,
    deleteFilters: filtersAdapter.removeAll,
  },
});

export const { addFilter, deleteFilter, deleteFilters } = UserExplorationsFiltersSlice.actions;

export default UserExplorationsFiltersSlice.reducer;

export const {
  selectAll: selectAllFilters,
  selectById: selectFiltersById,
  selectIds: selectFiltersIds,
} = filtersAdapter.getSelectors((state: any) => state.userExploration.userExplorationFilters ?? initialState);

import * as React from 'react';
import { Paper, Popper, Typography } from '@mui/material';
import { GridAlignment, GridRenderCellParams } from '@mui/x-data-grid';

// valueFormatter(v) {
//   return chartFormatter(value.formatter, v.value);
// },
// renderHeader: (params: GridColumnHeaderParams) => (
//   <Tooltip title={<Typography style={{ padding: 8 }}>{translate(`recommendation.items-table.${value.i18n}`)}</Typography>}>
//     <Typography variant="h5" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}>
//       {translate(`recommendation.items-table.${value.i18n}`)}
//     </Typography>
//   </Tooltip>
// ),
// renderCell: renderCellExpand,

interface CellExpandProps {
  value: string;
  width: number;
  align: GridAlignment;
}

const CellExpand = React.memo(function CellExpand(props: CellExpandProps) {
  const { width, value, align } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      style={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: alignToFlex(align),
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div
        ref={cellValue}
        className="cellValue"
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </div>
      {showPopper && (
        <Popper open={showFullCell && anchorEl != null} anchorEl={anchorEl}>
          <Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

export function renderCellExpand(params: GridRenderCellParams) {
  return <CellExpand value={params.formattedValue} width={params.colDef.width} align={params.colDef.align} />;
}

function isOverflown(element: Element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

function alignToFlex(align: string) {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
}

// const usdPrice: GridColTypeDef = {
//   type: 'number',
//   width: 130,
//   valueFormatter: ({ value }) => currencyFormatter.format(value),
//   cellClassName: 'font-tabular-nums',
// };

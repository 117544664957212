import { useAppSelector } from 'app/config/store';
import { patterns } from 'app/shared/util/url-utils';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

export function useRedirectToSettings() {
  const { createdSubscription } = useAppSelector(state => state.subscription);
  const navigate = useNavigate();

  useEffect(() => {
    if (createdSubscription !== null) {
      navigate(generatePath(patterns.product.settings, { productName: createdSubscription.productName }));
    }
  }, [createdSubscription]);
}

import { NoData } from 'app/components/no-data/NoData';
import React, { ComponentType, FC } from 'react';
import { translate } from 'react-jhipster';

const getDisplayName = (WrappedComponent: ComponentType<any>) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export const WithData = <P extends object>(Component: ComponentType<P>, data?: Array<any>): FC<P & { data?: any }> => {
  const withData = props => {
    const dataArray: Array<any> = data ? data : props?.data;
    return !dataArray || dataArray.length === 0 ? <NoData text={translate(`no-data.no-data-available`)} /> : <Component {...props} />;
  };
  withData.displayName = `WithData(${getDisplayName(Component)})`;
  return withData;
};

import React from 'react';

import { useSelectedChart, useSelectedData } from 'app/modules/views/greensights/data/state';
import { Typography } from '@mui/material';
import { translate } from 'react-jhipster';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import LineEchart from './LineEchart';

const LineChart = () => {
  const { selectedData } = useSelectedData();
  const { selectedChart } = useSelectedChart();

  return (
    <LayoutItem
      gridItemProps={{ xs: 12, lg: 8 }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h4" paddingBottom={1}>
              {translate(`greensights.line-chart.${selectedChart.chart.title}`)}
            </Typography>
          ),
          sx: {
            py: 1,
            pb: 0,
          },
        },
        content: { sx: { height: '300px', py: 0, '&:last-child': { py: 0 } } },
      }}
    >
      <LineEchart data={selectedData} selectedChart={selectedChart} />
    </LayoutItem>
  );
};

export default LineChart;

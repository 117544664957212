import React from 'react';

import { useAppSelector } from 'app/config/store';
import { RequestFilterManager } from 'app/components/request-filter/RequestFilterManager';
import {
  addUserExplorationFilter,
  deleteAllUserExplorationFilters,
  deleteUserExplorationFilter,
  getUserExplorationTimerange,
  selectAllUserExplorationFilters,
  selectUserExplorationFiltersIds,
} from 'app/shared/reducers/views/user-exploration';

export const UserExplorationRequestFilters = () => {
  const timerange = useAppSelector(getUserExplorationTimerange);
  const filter = useAppSelector(selectAllUserExplorationFilters);
  return (
    <RequestFilterManager
      timerange={timerange}
      filters={filter}
      filterActions={{
        deleteFilters: deleteAllUserExplorationFilters,
        addFilter: addUserExplorationFilter,
        deleteFilter: deleteUserExplorationFilter,
        selectAllFilters: selectAllUserExplorationFilters,
        selectFiltersIds: selectUserExplorationFiltersIds,
      }}
    />
  );
};

import React from 'react';
import * as echarts from 'echarts';
import world from 'app/shared/util/world.json';
import { UsersMapChart } from '../chart/UsersMapChart';
import { getWorldMapData } from 'app/shared/util/worldMapData';
import { IUsersByCountry } from 'app/shared/model/user-exploration/users-by-country.model';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';
import { useAppSelector } from 'app/config/store';
import { getUserExplorationTimerange } from 'app/shared/reducers/views/user-exploration';
import { UsersLiveMapChart } from '../chart/UsersLiveMapChart';

interface UsersByCountryProps {
  users_by_country: IUsersByCountry[];
  isFetching: boolean;
  isError: boolean;
  error: unknown;
}

export const UsersByCountry = ({ users_by_country, isFetching, isError, error }: UsersByCountryProps) => {
  echarts.registerMap('world', world);
  const timerange = useAppSelector(getUserExplorationTimerange);

  const data: {
    name: string;
    value: number;
    code: string;
  }[] = users_by_country?.map(element => {
    let obj = {} as { name: string; value: number; code: string };
    getWorldMapData().forEach(function (itemOpt) {
      if (element.countryCode === itemOpt.code) {
        obj = {
          name: itemOpt.name,
          code: element.countryCode,
          value: element.bucket.buckets.USER_COUNT.count,
        };
      }
    });
    return obj;
  });
  if (timerange && timerange.live) {
    return <UsersLiveMapChart data={data} />;
  }
  return <UsersMapChart data={data} />;
};

export default WithLoadingHOC(UsersByCountry);

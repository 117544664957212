import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IUserInfo } from 'app/shared/model/visitor-profile/userInfo.model';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';

const usersExplorationUrl = DATA_HUB_API_URL + '/exploration/users';

export const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  user_info: null as IUserInfo,
  user: null,
};

export type UserInfoState = Readonly<typeof initialState>;

// Actions

export const getUserInfo = createAsyncThunk(
  'userInfo/getUserInfo',
  async ({ filter, userId }: { filter: any; userId: any }) => {
    return microserviceHttpClient(
      `${usersExplorationUrl}/${userId}`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    );
  },
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const UserInfoSlice = createSlice({
  name: 'userInfo',
  initialState: initialState as UserInfoState,
  reducers: {
    resetUserInfo(state) {
      state.user_info = {} as IUserInfo;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserInfo.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.user_info = action.payload.data as IUserInfo;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.user_info = {} as IUserInfo;
      });
  },
});

// Reducer
export default UserInfoSlice.reducer;

export const { resetUserInfo, setUser } = UserInfoSlice.actions;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { serializeAxiosError } from '../reducer.utils';

const dataExplorationUrl = DATA_HUB_API_URL + '/exploration/data';

const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  users: null,
};

export type UsersState = Readonly<typeof initialState>;

export const getUser = createAsyncThunk(
  'dataHub/FETCH_USERS',
  async ({ from, to, step }: any) => {
    return microserviceHttpClient(
      `${dataExplorationUrl}/kpisHistory?timeStep=${step}&from=${from}&to=${to}&kpis=USER_COUNT`,
      'post',
      JSON.stringify({
        filterEntryList: [],
      })
    );
  },
  { serializeError: serializeAxiosError }
);

export const UsersSlice = createSlice({
  name: 'users',
  initialState: initialState as UsersState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUser.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data.data.date.map(element => {
          if (typeof element.buckets['USER_COUNT']['count'] !== 'number') return [new Date(element.date).getTime(), 0];
          return [new Date(element.date).getTime(), Number(element.buckets['USER_COUNT']['count']).toFixed()];
        });
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.users = null;
      });
  },
});

export default UsersSlice.reducer;

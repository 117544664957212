import React, { Children } from 'react';

import { TabsProps } from '@mui/material';
import { TabItem, Tabulate } from 'app/components/tabulation';
import { LayoutItemRaw } from '../../functions/components/LayoutItemRaw';
import { toLayoutItemProps } from '../../functions/converter/toLayoutItemProps';
import { LayoutProps, CoreLayoutProps, RenderElementType } from '../../models/models';
import { LayoutItemProps } from '../LayoutItem';
import { mergeToLayoutItemProps } from '../../functions/converter/mergeToLayoutItemProps';
import { RawStackLayout } from './StackLayout';

export function TabsLayout({
  children,
  renderChild = LayoutItemRaw,
  renderProps,
  cardOptions,
  accordionOptions,
  ...containerProps
}: LayoutProps<TabsProps>): JSX.Element {
  if (!children) {
    return null;
  }

  const tabulations: RenderElementType = Children.map(children, toLayoutItemProps).map((childProps: LayoutItemProps) => {
    const {
      key,
      cardOptions: { header: { title, avatar } = {} } = {},
      tabItemProps: { tab, panel } = { tab: {} },
    }: LayoutItemProps = childProps;
    const tabTitle = avatar ? (
      <RawStackLayout direction="row">
        {avatar}
        {title}
      </RawStackLayout>
    ) : (
      title
    );
    return (
      <TabItem key={key} tab={{ label: tabTitle, ...tab }} panel={{ ...panel }}>
        {renderChild(mergeToLayoutItemProps(accordionOptions, cardOptions, childProps, renderProps))}
      </TabItem>
    );
  });

  return <Tabulate {...containerProps}>{tabulations}</Tabulate>;
}

export const RawTabsLayout = (props: CoreLayoutProps<TabsProps>): JSX.Element => {
  return <TabsLayout {...props} renderChild={LayoutItemRaw} />;
};

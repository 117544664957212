import React, { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import {
  useBootstrapRatingsMutation,
  useDisableDemoMutation,
  useEnableDemoMutation,
  useFindAllCorporaQuery,
  useRunningSimulationQuery,
  useStartSimulationMutation,
  useStopSimulationMutation,
} from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { addFlex, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import { dynamicHeightProps } from 'app/components/data-grid/rows/rows-props';
import { toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import {
  Delete,
  DeleteForever,
  Edit,
  PauseCircle,
  PlayCircleFilledWhite,
  RestartAlt,
  StopCircle,
  ToggleOff,
  ToggleOn,
} from '@mui/icons-material';
import { translate } from 'react-jhipster';
import { Chip, Tooltip } from '@mui/material';
import { ConfirmDeleteCorpus } from './ConfirmDeleteCorpus';
import { Box } from '@mui/system';
import { CustomPagination, CustomToolbar, NoCorpusFound } from './CustomComponents';
import { EditCorpus } from './EditCorpus';
export const AllCorpus = () => {
  const [bootstrapRatings, { isLoading }] = useBootstrapRatingsMutation();
  const [enableDemo, { isLoading: isEnableLoading }] = useEnableDemoMutation();
  const [disableDemo, { isLoading: isDisableLoading }] = useDisableDemoMutation();
  const [startSimulation, { isLoading: isStarting }] = useStartSimulationMutation();
  const [stopSimulation, { isLoading: isStoping }] = useStopSimulationMutation();
  const [deleteCorpus, setDeleteCorpus] = useState(null);
  const [editCorpus, setEditCorpus] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});

  const { data: corpusList = {}, isFetching } = useFindAllCorporaQuery(
    accountProfile.accountId
      ? { accountId: accountProfile.accountId.substring(0, 8), page: paginationModel.page, size: paginationModel.pageSize }
      : skipToken
  );

  const [rowCountState, setRowCountState] = useState(corpusList.totalElements || 0);

  useEffect(() => {
    setRowCountState(prevRowCountState => (corpusList.totalElements !== undefined ? corpusList.totalElements : prevRowCountState));
  }, [corpusList.totalElements, setRowCountState]);

  const columns: GridColDef[] = [
    { field: 'id' },
    { field: 'name' },
    { field: 'itemIndices' },
    {
      field: 'bootstrapping_date',
      type: 'string',
      valueGetter: ({ value }) => value && new Date(value).toLocaleString(),
    },
    {
      field: 'item_count',
    },
    {
      field: 'filter_query',
    },
    {
      field: 'filter_query_fields',
    },
    {
      field: 'filter_query_source_includes',
    },
    {
      field: 'filter_query_source_excludes',
    },
    {
      field: 'bootstrapper_max_doc_freq',
    },
    {
      field: 'bootstrapper_max_ratings_per_item',
    },
    {
      field: 'demo',
      renderCell(params) {
        const { isError, isSuccess, isUninitialized } = useRunningSimulationQuery({
          accountId: accountProfile.accountId.substring(0, 8),
          corpusId: params.id.toString(),
        });
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {params.row.demo_enabled && !isUninitialized ? (
              <Tooltip
                title={
                  isSuccess
                    ? translate('recommendation.corpus.corpus-table.stop-simulation', null, 'Stop Simulation')
                    : translate('recommendation.corpus.corpus-table.run-simulation', null, 'Run Simulation')
                }
              >
                <Chip
                  label={
                    isSuccess
                      ? translate('recommendation.corpus.corpus-table.running', null, 'Running')
                      : translate('recommendation.corpus.corpus-table.stopped', null, 'Stopped')
                  }
                  size={'small'}
                  variant="outlined"
                  color={isSuccess ? 'success' : 'error'}
                  icon={isSuccess ? <StopCircle /> : <PlayCircleFilledWhite />}
                  onClick={() => {
                    if (!isSuccess) {
                      startSimulation({ accountId: accountProfile.accountId.substring(0, 8), corpusId: params.id.toString() });
                    } else {
                      stopSimulation({ accountId: accountProfile.accountId.substring(0, 8), corpusId: params.id.toString() });
                    }
                  }}
                />
              </Tooltip>
            ) : (
              <Chip
                label={translate('recommendation.corpus.corpus-table.disabled', null, 'Disabled')}
                size={'small'}
                variant="outlined"
                color={'default'}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions(params: GridRowParams) {
        return [
          <GridActionsCellItem
            key="edit"
            icon={
              <Tooltip title={translate('recommendation.corpus.edit-corpus', null, 'Edit Corpus')}>
                <Edit
                  sx={{
                    ':hover': {
                      color: theme => theme.palette.primary.light,
                    },
                  }}
                />
              </Tooltip>
            }
            onClick={() => {
              setEditCorpus(params.row);
            }}
            label={translate('recommendation.items.corpus-table.edit-corpus', null, 'Edit Corpus')}
          />,
          <GridActionsCellItem
            key="demo"
            icon={
              params.row.demo_enabled ? (
                <Tooltip title={translate('recommendation.corpus.corpus-table.disable-demo', null, 'Disable Demo')}>
                  <ToggleOn
                    sx={{
                      ':hover': {
                        color: theme => theme.palette.success.light,
                      },
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={translate('recommendation.corpus.corpus-table.enable-demo', null, 'Enable Demo')}>
                  <ToggleOff
                    sx={{
                      ':hover': {
                        color: theme => theme.palette.error.light,
                      },
                    }}
                  />
                </Tooltip>
              )
            }
            onClick={() => {
              if (params.row.demo_enabled) {
                disableDemo({ accountId: accountProfile.accountId.substring(0, 8), corpusId: params.id.toString() });
              } else {
                enableDemo({ accountId: accountProfile.accountId.substring(0, 8), corpusId: params.id.toString() });
              }
            }}
            label={translate('recommendation.corpus.corpus-table.demo', null, 'Demo')}
          />,
          <GridActionsCellItem
            key="edit"
            icon={
              <Tooltip title={translate('recommendation.corpus.corpus-table.bootstrap-ratings', null, 'Bootstrap Ratings')}>
                <RestartAlt
                  sx={{
                    ':hover': {
                      color: theme => theme.palette.primary.light,
                    },
                  }}
                />
              </Tooltip>
            }
            onClick={() => {
              bootstrapRatings({ accountId: accountProfile.accountId.substring(0, 8), id: params.id.toString() });
            }}
            label={translate('recommendation.corpus.corpus-table.bootstrap-ratings', null, 'Bootstrap Ratings')}
          />,
          <GridActionsCellItem
            key="delete"
            icon={
              <Tooltip title={translate('recommendation.corpus.corpus-table.delete-corpus', null, 'Delete Corpus')}>
                <DeleteForever
                  sx={{
                    ':hover': {
                      color: theme => theme.palette.error.light,
                    },
                  }}
                />
              </Tooltip>
            }
            onClick={() => {
              setDeleteCorpus(params.row);
            }}
            label={translate('recommendation.corpus.corpus-table.delete-corpus', null, 'Delete Corpus')}
          />,
        ];
      },
    },
  ];

  return (
    <>
      {editCorpus && <EditCorpus corpus={editCorpus} setOpen={setEditCorpus} />}
      <ConfirmDeleteCorpus corpus={deleteCorpus} setOpen={setDeleteCorpus} />
      <DataGrid
        rows={corpusList.content || []}
        columns={columns.map(addFlex).map(translateHeaders('recommendation.corpus.corpus-table'))}
        getRowId={row => row.id}
        {...toolbarProps()}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25]}
        density="compact"
        disableColumnMenu
        loading={isFetching || isLoading || isEnableLoading || isDisableLoading || isStarting || isStoping}
        rowCount={rowCountState}
        paginationMode="server"
        disableRowSelectionOnClick
        slots={{
          pagination: CustomPagination,
          toolbar: CustomToolbar,
          noResultsOverlay: NoCorpusFound,
          noRowsOverlay: NoCorpusFound,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              name: true,
              itemIndices: false,
              bootstrapping_date: true,
              item_count: true,
              filter_query: true,
              filter_query_fields: false,
              filter_query_source_includes: false,
              filter_query_source_excludes: false,
              bootstrapper_max_doc_freq: false,
              bootstrapper_max_ratings_per_item: false,
            },
          },
        }}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
        }}
      />
    </>
  );
};

import { WarningAmber } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export const ErrorComponent = ({ error }) => {
  return (
    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <WarningAmber color="error" sx={{ mr: 1 }} />
      <Typography>{error?.data.title}</Typography>
    </Box>
  );
};

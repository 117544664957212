export const KPIS = {
  ACTIVE_USER_COUNT: {
    name: 'ACTIVE_USER_COUNT',
    value: 'count',
    type: 'number',
    i18n: 'Active Users',
    formatter: 'number',
    displayOn: 'lg',
  },
  USER_COUNT: {
    name: 'USER_COUNT',
    value: 'count',
    type: 'number',
    i18n: 'Users',
    formatter: 'number',
    displayOn: 'md',
  },
  SESSION_COUNT: {
    name: 'SESSION_COUNT',
    value: 'count',
    type: 'number',
    i18n: 'Sessions',
    formatter: 'number',
    displayOn: 'sm',
  },
  SESSIONS_PER_USER: {
    name: 'SESSIONS_PER_USER',
    value: '',
    type: 'number',
    i18n: 'Sessions/Users',
    formatter: 'numberFixed2',
    displayOn: 'lg',
  },
  PAGEVIEWS_COUNT: {
    name: 'PAGEVIEWS_COUNT',
    value: 'sum',
    type: 'number',
    i18n: 'PageViews',
    formatter: 'number',
    displayOn: 'md',
  },
  PAGEVIEWS_PER_SESSION: {
    name: 'PAGEVIEWS_PER_SESSION',
    value: '',
    type: 'number',
    i18n: 'PageViews/Sessions',
    formatter: 'numberFixed2',
    displayOn: 'lg',
  },
  AVG_SESSION_DURATION: {
    name: 'AVG_SESSION_DURATION',
    value: 'avg',
    type: 'number',
    i18n: 'Avg. Session duration',
    formatter: 'durationToString',
    displayOn: 'lg',
  },
  BOUNCE_RATE: {
    name: 'BOUNCE_RATE',
    value: 'rate',
    type: 'number',
    i18n: 'Bounce Rate',
    formatter: 'numberFixed2percentage',
    displayOn: 'md',
  },
};

import React from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { useCsvList } from '../../hooks/useCsvList';
import { useDatagridColumns } from '../../hooks/useDatagridColumns';
import { addFlex, translateHeaders } from 'app/components/data-grid/columns/column-helpers';

export const FilesGrid = () => {
  const { list, loading } = useCsvList();
  const columns = useDatagridColumns();

  return (
    <DataGrid
      rows={list || []}
      columns={columns.map(addFlex).map(translateHeaders('web-analytics.export.columns'))}
      getRowId={row => row.fileName}
      initialState={{
        sorting: {
          sortModel: [{ field: 'creation', sort: 'desc' }],
        },
      }}
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
      }}
      loading={loading}
      density="compact"
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
    />
  );
};

export const recommenderStrategy = [
  'collaborative-filtering',
  'content-based-filtering',
  'similar-items',
  'frequently-bought-together',
  'popular',
  'trending',
  'trending-short',
  'trending-long',
  'random',
];

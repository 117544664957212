import { useState } from 'react';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';

export interface IDialogsState {
  openAccountEditForm: boolean;
  setOpenAccountEditForm: (open: boolean) => void;
  openBillingEditForm: boolean;
  setOpenBillingEditForm: (open: boolean) => void;
  openInviteMemberForm: boolean;
  setOpenInviteMemberForm: (open: boolean) => void;
  openAddPaymentMethodeForm: boolean;
  setOpenAddPaymentMethodeForm: (open: boolean) => void;
}

export const useAccountPage = () => {
  const { billingAccount, accountProfile } = useAppAccountStates();

  const [openAccountEditForm, setOpenAccountEditForm] = useState(false);
  const [openBillingEditForm, setOpenBillingEditForm] = useState(false);
  const [openInviteMemberForm, setOpenInviteMemberForm] = useState(false);
  const [openAddPaymentMethodeForm, setOpenAddPaymentMethodeForm] = useState(false);

  return {
    billingAccount,
    accountProfile,
    dialogsState: {
      openAccountEditForm,
      setOpenAccountEditForm,
      openBillingEditForm,
      setOpenBillingEditForm,
      openInviteMemberForm,
      setOpenInviteMemberForm,
      openAddPaymentMethodeForm,
      setOpenAddPaymentMethodeForm,
    },
  };
};

import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { translate } from 'react-jhipster';
import { IDialogsState } from '../../hooks/useAccountPage';
import PaymentMethodList from '../payment-methods/components/PaymentMethodList';
import { NoPaymentMethodeSaved } from '../payment-methods/NoPaymentMethodeSaved';
import { TabWithAction } from './common/TabWithAction';
import { paymentMethodApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/payment-method';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';

export const PaymentMethodTab = ({ dialogsState }: { dialogsState: IDialogsState }): JSX.Element => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { data: paymentMethods } = paymentMethodApi.endpoints.getPaymentMethodsUsingGet.useQueryState({
    accountKey: accountProfile.accountId,
    withPluginInfo: true,
  });

  return (
    <TabWithAction
      action={{
        text: translate('account.account-page.add-new-payment-method'),
        icon: <AddIcon />,
        onClick: () => dialogsState.setOpenAddPaymentMethodeForm(true),
      }}
      sxContainer={{
        width: '50%',
        marginX: 'auto',
      }}
    >
      {paymentMethods && paymentMethods.length > 0 ? <PaymentMethodList paymentMethodsList={paymentMethods} /> : <NoPaymentMethodeSaved />}
    </TabWithAction>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { serializeAxiosError } from '../../../reducer.utils';

const listCSVUrl = DATA_HUB_API_URL + '/download/csv/list';

interface FileCSV {
  fileName: string;
  from: number;
  to: number;
  creation: number;
  length: number;
}

const initialState = {
  errorMessage: null as unknown as string, // Errors returned from server side
  loading: false,

  list: [] as FileCSV[],
};

// Actions
export const getListCSVFiles = createAsyncThunk(
  'webAnalyticsTools/getListCSVFiles',
  async () => {
    return microserviceHttpClient(`${listCSVUrl}`).then(resp => resp.data);
  },
  { serializeError: serializeAxiosError }
);

export type ListCSVData = Readonly<typeof initialState>;

// Slice
export const ListCSVSlice = createSlice({
  name: 'listCSV',
  initialState: initialState as ListCSVData,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getListCSVFiles.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getListCSVFiles.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload as FileCSV[];
      })
      .addCase(getListCSVFiles.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.list = [] as FileCSV[];
      });
  },
});

export default ListCSVSlice.reducer;

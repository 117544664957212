import { useAppSelector } from 'app/config/store';
import { dataExplorationApi } from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { getDataExplorationTimerange, selectAllDataExplorationFilters } from 'app/shared/reducers/views/data-exploration';
import {
  bucketingDimensionsMappingList,
  getAnalyticsData,
  getSecondaryDimensions,
  getSessionsByCountry,
  kpisValuesMapping,
} from 'app/shared/util/data-hub-utils';

export const useDataExplorationKpisPerKpi = () => {
  const timerange = useAppSelector(getDataExplorationTimerange);
  const filter = useAppSelector(selectAllDataExplorationFilters);

  const dataExplorationKpisPerKpiQueryParams = {
    bucketingDimensions: `${bucketingDimensionsMappingList.map(element => element.value).join()},COUNTRY_CODE` as any,
    kpis: 'USER_COUNT,SESSION_COUNT,PAGEVIEWS_COUNT,AVG_SESSION_DURATION,SUM_SESSION_DURATION,BOUNCE_RATE,BOUNCE_COUNT,ACTIVE_USER_COUNT',
    requestFilters: {
      filterEntryList: filter,
    },
    from: timerange?.fromDate,
    to: timerange?.toDate,
  };

  const dataExplorationKpisPerKpiQueryOptions = {
    pollingInterval: timerange?.refreshRate,
    selectFromResult(res) {
      return res.currentData
        ? {
            ...res,
            secondary_dimensions: getSecondaryDimensions(res.currentData, kpisValuesMapping),
            analytics_table_data: getAnalyticsData(res.currentData, kpisValuesMapping),
            sessions_by_country: getSessionsByCountry(res.currentData),
          }
        : {
            ...res,
            secondary_dimensions: null,
            analytics_table_data: [],
            sessions_by_country: [],
          };
    },
  };

  const { secondary_dimensions, analytics_table_data, sessions_by_country, error, isFetching, isError } =
    dataExplorationApi.endpoints.getKpisPerKpiInTimeRangeUsingPost.useQueryState(
      dataExplorationKpisPerKpiQueryParams,
      dataExplorationKpisPerKpiQueryOptions
    );

  return {
    secondary_dimensions,
    analytics_table_data,
    sessions_by_country,
    error,
    isFetching,
    isError,
  };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type appState = {
  appStarting: boolean;
  appLoading: boolean;
};

const initialState: appState = {
  appStarting: true,
  appLoading: true,
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setAppLoading(state, action: PayloadAction<boolean>) {
      state.appLoading = action.payload;
    },
    toggleAppStarting(state, action: PayloadAction<string>) {
      state.appStarting = false;
    },
  },
});

export const { toggleAppStarting, setAppLoading } = appStateSlice.actions;

export default appStateSlice.reducer;

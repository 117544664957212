import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IUsers } from 'app/shared/model/users.model';
import { lastWeek } from 'app/modules/views/module-root/views/web-analytics/components/pie-charts/actions/timeranges';

const usersScoresUrl = DATA_HUB_API_URL + '/scores/users';

export const initialState = {
  errorMessage: null as unknown as string, // Errors returned from server side
  loading: false,
  splitBy: 'sessions-by',

  params: lastWeek,

  data: null as IUsers[],
};

export type PieChartsState = Readonly<typeof initialState>;

// Actions

export const getPieChartsData = createAsyncThunk(
  'webAnalyticsHome/getPieCharts',
  async ({ from, to, filter }: { from: string; to: string; filter: any }) => {
    return microserviceHttpClient(
      `${usersScoresUrl}/timeframe?to=${to}&from=${from}`,
      'post',
      JSON.stringify({
        filters: {
          filterEntryList: filter,
        },
      })
    );
  },
  {
    serializeError: serializeAxiosError,
  }
);

// Slice
export const PieChartsSlice = createSlice({
  name: 'PieCharts',
  initialState: initialState as PieChartsState,
  reducers: {
    setParams(state, action) {
      state.params = action.payload;
    },
    setSplit(state, action) {
      state.splitBy = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPieChartsData.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getPieChartsData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data as IUsers[];
      })
      .addCase(getPieChartsData.rejected, (state, action) => {
        state.loading = false;
        state.data = [] as Array<IUsers>;
      });
  },
});

export const { setParams, setSplit } = PieChartsSlice.actions;
export default PieChartsSlice.reducer;

import React from 'react';

import { GridLayout, RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { documentationUrl, sitemap } from 'app/shared/util/url-utils';
import { CardList } from '../../components/list/CardList';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { translate, Translate } from 'react-jhipster';
import { Divider, Typography } from '@mui/material';
import { ImageCard } from '../../components/cards/ImageCard';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { RecommendationsAccordion } from '../../components/accordion/recommendations/RecommendationsAccordion';

export function RecommendationsSummary() {
  return <RecommendationsAccordion />;

  return (
    <RawGridLayout>
      <LayoutItem gridItemProps={{ xs: 4, display: 'grid' }}>
        <ImageCard
          title={
            <Typography variant="h5">
              <Translate contentKey="module-factory.dashboard">Dashboard</Translate>
            </Typography>
          }
          icon={ManageSearchIcon}
          links={[{ title: translate(`module-factory.explorer`, null, 'Explorer'), to: sitemap.recommendations.strategies }]}
        />
      </LayoutItem>
      <LayoutItem gridItemProps={{ xs: 4, display: 'grid' }}>
        <ImageCard
          title={
            <Typography variant="h5">
              <Translate contentKey="module-factory.tools">Tools</Translate>
            </Typography>
          }
          icon={HomeRepairServiceIcon}
          links={[
            {
              title: translate(`module-factory.catalog-management`, null, 'Catalog Management'),
              to: sitemap.recommendations.corpus,
            },
          ]}
        />
      </LayoutItem>
      <LayoutItem gridItemProps={{ xs: 4, display: 'grid' }}>
        <ImageCard
          title={
            <Typography variant="h5">
              <Translate contentKey="module-factory.documentation">Documentation</Translate>
            </Typography>
          }
          icon={MenuBookIcon}
          links={[
            {
              title: translate(`module-factory.quickstart`, null, 'Quickstart'),
              to: documentationUrl(sitemap.ext.docs.recommendations),
              target: '_blank',
            },
          ]}
        />
      </LayoutItem>
    </RawGridLayout>
  );
}

export const getUsersOverview = usersList => {
  return usersList.map(element => {
    return {
      id: element.id,
      userId: element.userId,
      engagementScore: element.engagementScore,
      sessionCount: Number(element.sessionCount).toFixed(),
      eventsCounterSum: Number(element.eventsCounterSum).toFixed(),
      pageviewsCounterSum: Number(element.pageviewsCounterSum).toFixed(),
      sessionDurationSum: Number(element.sessionDurationSum).toFixed(),
      bounceCount: Number(element.bounceCount).toFixed(),
      firstUserVisitEpochSeconds: element.firstUserVisitEpochSeconds,
      lastEventEpochSeconds: element.lastEventEpochSeconds,
      timeSinceLastVisit: element.timeSinceLastVisit,
      countrySplit: element.countrySplit[0] ? element.countrySplit[0].key : 'no data',
      citySplit: element.citySplit[0] ? element.citySplit[0].key : 'no data',
      continentSplit: element.continentSplit[0] ? element.continentSplit[0].key : 'no data',
      firstVisitedPageSplit: element.firstVisitedPageSplit[0] ? element.firstVisitedPageSplit[0].key : 'no data',
      lastVisitedPageSplit: element.lastVisitedPageSplit[0] ? element.lastVisitedPageSplit[0].key : 'no data',
      refererSplit: element.refererSplit[0] ? element.refererSplit[0].key : 'no data',
      fqdnSplit: element.fqdnSplit[0] ? element.fqdnSplit[0].key : 'no data',
      deviceCategorySplit: element.deviceCategorySplit[0] ? element.deviceCategorySplit[0].key : 'no data',
      browserFamilySplit: element.browserFamilySplit[0] ? element.browserFamilySplit[0].key : 'no data',
      osFamilySplit: element.osFamilySplit[0] ? element.osFamilySplit[0].key : 'no data',
    };
  });
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { RECO_ENGINE_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { getCorpus } from './corpus';

export const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  uploaded: null,
};

export type UploadInventoryState = Readonly<typeof initialState>;

// Actions

export const uploadInventoryFiles = createAsyncThunk(
  'reco/uploadInventoryFiles',
  async ({ files, corpusName, fileType = 'json' }: { files: any; corpusName: string; fileType?: 'json' | 'csv' }, thunkAPI) => {
    await files.forEach(file => {
      const formData: FormData = new FormData();
      formData.append('file', file);
      microserviceHttpClient(`${RECO_ENGINE_API_URL}/update-inventory/${fileType}?bootstrap=true&corpus=${corpusName}`, 'post', formData, {
        'Content-Type': 'multipart/form-data',
      }).then(_resp => thunkAPI.dispatch(getCorpus()));
    });
  },
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const uploadInventorySlice = createSlice({
  name: 'uploadInventory',
  initialState: initialState as UploadInventoryState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(uploadInventoryFiles.pending, state => {
        state.loading = true;
        state.errorMessage = null;
        state.uploaded = null;
      })
      .addCase(uploadInventoryFiles.fulfilled, (state, action) => {
        state.loading = false;
        state.uploaded = action.payload;
      })
      .addCase(uploadInventoryFiles.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.uploaded = null;
      });
  },
});

// Reducer
export default uploadInventorySlice.reducer;

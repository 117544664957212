import React, { useEffect } from 'react';

import { useData } from 'app/modules/views/greensights/data/api';
import { useSelectedDimension } from 'app/modules/views/greensights/data/dimension';
import { ResponseWrapper } from 'app/modules/views/greensights/model/responseWrapper';
import MetricResponse from 'app/modules/views/greensights/model/metricResponse';
import PieEchart from './PieEchart';
import {
  ResponseWrapperMetricResponse,
  useLazyLoadQuery,
} from 'app/shared/reducers/api/greensights-data-hub-cloud-services/store/greensights-data-hub';

interface PieChartProps {
  fromDate;
  toDate;
  chart: any;
}

function getCallback(key) {
  return (response: ResponseWrapperMetricResponse) => {
    if (response.content.status === 'success') {
      const map = {};
      response.content.data.result.forEach(result => {
        const name = result.metric[key] || 'other';
        if (!map[name]) {
          map[name] = {
            value: 0,
            name,
          };
        }
        result.values.forEach(value => {
          map[name].value += parseFloat('' + value[1]);
        });
      });
      const data = Object.values(map);
      return data.length > 0 ? data.slice(0, 10) : data;
    }
    return [];
  };
}

const PieChart = ({ chart, fromDate, toDate }: PieChartProps) => {
  const { selectedDimension } = useSelectedDimension();

  const [load, { data = [], loading }] = useLazyLoadQuery({
    selectFromResult(response) {
      return { data: response.data ? getCallback(selectedDimension.key)(response.data) : [], loading: response.isLoading };
    },
  });

  useEffect(() => {
    load({
      accountId: '93090364',
      metricRequest: {
        start: fromDate,
        end: toDate,
        query: `sum_over_time(sum (${chart.key}{#filters}) by (${selectedDimension.key}))`,
        filters: {
          scope: chart.scope,
        },
      },
    });
  }, [selectedDimension, fromDate, toDate]);

  return <PieEchart data={data} loading={loading} />;
};

export default PieChart;

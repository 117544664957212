import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
  TextField,
  Tooltip,
  Box,
  IconButton,
} from '@mui/material';
import { Translate, translate } from 'react-jhipster';
import { ItemDoc } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { Add, Clear } from '@mui/icons-material';
import { useUpdateItem } from '../hooks/useUpdateItem';

export const UpdateItems = ({ setOpen, item }: { setOpen: any; item: ItemDoc }) => {
  const { register, errors, fields, methods, remove, append, submitButton, cancelButton, discardButton } = useUpdateItem(item, setOpen);

  return (
    <Dialog
      open={item !== null}
      onClose={() => {
        setOpen(null);
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle display="flex" typography="h4">
        <Translate contentKey="recommendation.items.items-table.edit-item">Edit items</Translate> :<Typography ml={2}>{item.id}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id="title"
              label={translate('recommendation.items.title', null, 'Title')}
              multiline
              maxRows={2}
              size="small"
              variant="outlined"
              {...register('title', { required: 'Title is required here' })}
              error={!!errors.title}
              helperText={errors.title?.message}
              sx={{ width: '100%' }}
              // fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <TextField
              id="description"
              label={translate('recommendation.items.description', null, 'Description')}
              multiline
              maxRows={10}
              size="small"
              variant="outlined"
              {...register('description')}
              sx={{ width: '100%' }}
              // fullWidth
            />
          </Grid>
          {fields.map((field, index) => {
            return (
              <Grid item container key={index} xs={12}>
                <Grid item xs={3}>
                  <TextField
                    label={translate('recommendation.items.key', null, 'Key')}
                    size="small"
                    fullWidth
                    id={`key-${index}`}
                    variant="outlined"
                    {...register(`attributes.${Number(index)}.key`, { required: true })}
                  />
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={translate('recommendation.items.value', null, 'Value')}
                    size="small"
                    fullWidth
                    id={`value-${index}`}
                    variant="outlined"
                    {...register(`attributes.${Number(index)}.value`, { required: true })}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Tooltip title={<Translate contentKey="recommendation.items.delete">Delete</Translate>}>
                    <Box>
                      <IconButton color="error" size="small" onClick={() => remove(index)}>
                        <Clear />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </Grid>
              </Grid>
            );
          })}

          <Grid item xs={2}>
            <Button startIcon={<Add />} size="small" onClick={() => append({ key: '', value: '' })} disabled={!methods.formState.isValid}>
              <Translate contentKey="recommendation.items.add-attribute">Add Attribute</Translate>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingX: 3 }}>
        {cancelButton}
        {discardButton}
        {submitButton}
      </DialogActions>
    </Dialog>
  );
};

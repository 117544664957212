import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import { useForm } from 'react-hook-form';
import { Translate } from 'react-jhipster';
import { accessTokenAttributes } from './form-attributes/accessTokenAttributes';
import { useCreateApiTokenUsingPostMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useAccessToken } from '../hooks/useAccessToken';

export const AccessTokenForm = () => {
  const accessTokenList = useAccessToken();
  const [createAccessToken] = useCreateApiTokenUsingPostMutation();
  const schema = validationUtils.getFormSchema(accessTokenAttributes(accessTokenList));
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  const submitButton = (
    <Button
      sx={{ mt: 1 }}
      variant="contained"
      onClick={() => {
        methods.handleSubmit((data: { name: string }) => {
          createAccessToken({ apiKeyRequestBom: { username: data.name } });
          methods.reset();
        })();
      }}
      disabled={!methods.formState.isDirty || !methods.formState.isValid}
      size="small"
    >
      <Translate contentKey="account.create-acount-acces-token">Create account access token</Translate>
    </Button>
  );

  return (
    <FormBuilder
      fields={accessTokenAttributes(accessTokenList)}
      defaultValue={{
        name: '',
      }}
      methods={methods}
      containerProps={{ my: 2, spacing: 2 }}
    >
      {submitButton}
    </FormBuilder>
  );
};

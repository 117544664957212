import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTopMetricsAggregation } from 'app/shared/reducers/web-analytics/top-metrics';

export const useTopMetrics = () => {
  const dispatch = useAppDispatch();
  const { params, data } = useAppSelector(state => state.webAnalyticsOverview.topMetrics);
  useEffect(() => {
    dispatch(getTopMetricsAggregation(params));
  }, [params]);
  return {
    data,
  };
};

import { translate } from 'react-jhipster';
import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';
import { enqueueSnackbar } from 'notistack';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getAccountsUsingGet: build.query<GetAccountsUsingGetApiResponse, GetAccountsUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          accountWithBalance: queryArg.accountWithBalance,
          accountWithBalanceAndCBA: queryArg.accountWithBalanceAndCba,
          audit: queryArg.audit,
        },
      }),
    }),
    createAccountUsingPost: build.mutation<CreateAccountUsingPostApiResponse, CreateAccountUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts`,
        method: 'POST',
        body: queryArg.accountDtoReq,
        params: { createAccountProfile: queryArg.createAccountProfile },
      }),
      invalidatesTags: result => (result ? ['USER_PROFILE', 'ACCOUNT_LIST', 'BILLING_ACCOUNT', 'ACCOUNT_PROFILE'] : []),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar(translate('account-created-successfully', null, 'account created successfully !'));
        } catch (err) {
          enqueueSnackbar(`Async error! : ${translate('account.failed-to-create-account', null, 'failed to create a new account ')}`, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    invalidateCacheAccountKeyUsingPost: build.mutation<
      InvalidateCacheAccountKeyUsingPostApiResponse,
      InvalidateCacheAccountKeyUsingPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/billing/accounts/invalidateCacheAccountKey/${queryArg.accountKey}`, method: 'POST' }),
    }),
    getPaginatedAccountsUsingGet: build.query<GetPaginatedAccountsUsingGetApiResponse, GetPaginatedAccountsUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/pagination`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          accountWithBalance: queryArg.accountWithBalance,
          accountWithBalanceAndCBA: queryArg.accountWithBalanceAndCba,
          audit: queryArg.audit,
        },
      }),
    }),
    getAccountByKeyUsingGet: build.query<GetAccountByKeyUsingGetApiResponse, GetAccountByKeyUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}`,
        params: {
          accountWithBalance: queryArg.accountWithBalance,
          accountWithBalanceAndCBA: queryArg.accountWithBalanceAndCba,
          audit: queryArg.audit,
        },
      }),
      providesTags: ['BILLING_ACCOUNT'],
    }),
    updateAccountUsingPut: build.mutation<UpdateAccountUsingPutApiResponse, UpdateAccountUsingPutApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}`,
        method: 'PUT',
        body: queryArg.accountDtoReq,
        params: { treatNullAsReset: queryArg.treatNullAsReset },
      }),
      invalidatesTags: ['BILLING_ACCOUNT', 'ACCOUNT_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar('account information updated successfully !', { variant: 'success' });
        } catch (err) {
          enqueueSnackbar(`Async error! : failed to update account informations `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    getAccountBundlesUsingGet: build.query<GetAccountBundlesUsingGetApiResponse, GetAccountBundlesUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}/bundles`,
        params: { bundlesFilter: queryArg.bundlesFilter, audit: queryArg.audit },
      }),
      providesTags: ['SUBSCRIPTION_LIST', 'BILLING_ACCOUNT'],
    }),
    getAccountInvoicesUsingGet: build.query<GetAccountInvoicesUsingGetApiResponse, GetAccountInvoicesUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}/invoices`,
        params: {
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
          withMigrationInvoices: queryArg.withMigrationInvoices,
          unpaidInvoicesOnly: queryArg.unpaidInvoicesOnly,
          includeVoidedInvoices: queryArg.includeVoidedInvoices,
          invoicesFilter: queryArg.invoicesFilter,
          audit: queryArg.audit,
        },
      }),
      providesTags: ['INVOICES_LIST', 'BILLING_ACCOUNT'],
    }),
    getPaymentMethodsUsingGet: build.query<GetPaymentMethodsUsingGetApiResponse, GetPaymentMethodsUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}/paymentMethods`,
        params: { withPluginInfo: queryArg.withPluginInfo, includedDeleted: queryArg.includedDeleted, audit: queryArg.audit },
      }),
      providesTags: ['PAYMENT_METHOD', 'BILLING_ACCOUNT'],
    }),
    addPaymentMethodUsingPost: build.mutation<AddPaymentMethodUsingPostApiResponse, AddPaymentMethodUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}/paymentMethods`,
        method: 'POST',
        body: queryArg.paymentMethodDto,
        params: {
          isDefault: queryArg.isDefault,
          payAllUnpaidInvoices: queryArg.payAllUnpaidInvoices,
          controlPluginName: queryArg.controlPluginName,
        },
      }),
      invalidatesTags: ['PAYMENT_METHOD'],
    }),
    setDefaultUsingPut: build.mutation<SetDefaultUsingPutApiResponse, SetDefaultUsingPutApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}/paymentMethods/${queryArg.paymentMethodId}/setDefault`,
        method: 'PUT',
        params: { payAllUnpaidInvoices: queryArg.payAllUnpaidInvoices },
      }),
      invalidatesTags: ['PAYMENT_METHOD'],
    }),
    getAccountPaymentsUsingGet: build.query<GetAccountPaymentsUsingGetApiResponse, GetAccountPaymentsUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}/payments`,
        params: { withAttempts: queryArg.withAttempts, withPluginInfo: queryArg.withPluginInfo, audit: queryArg.audit },
      }),
    }),
    getPaymentMethodsUsingGet1: build.query<GetPaymentMethodsUsingGet1ApiResponse, GetPaymentMethodsUsingGet1ApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/paymentMethods/pagination`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          pluginName: queryArg.pluginName,
          withPluginInfo: queryArg.withPluginInfo,
          audit: queryArg.audit,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as accountApi };
export type GetAccountsUsingGetApiResponse = /** status 200 OK */ AccountDtoRes[];
export type GetAccountsUsingGetApiArg = {
  /** offset */
  offset?: number;
  /** limit */
  limit?: number;
  /** accountWithBalance */
  accountWithBalance?: boolean;
  /** accountWithBalanceAndCBA */
  accountWithBalanceAndCba?: boolean;
  /** audit */
  audit?: string;
};
export type CreateAccountUsingPostApiResponse = /** status 200 OK */ AccountDtoRes | /** status 201 Created */ undefined;
export type CreateAccountUsingPostApiArg = {
  /** createAccountProfile */
  createAccountProfile?: boolean;
  accountDtoReq?: AccountDtoReq;
};
export type InvalidateCacheAccountKeyUsingPostApiResponse = unknown;
export type InvalidateCacheAccountKeyUsingPostApiArg = {
  /** accountKey */
  accountKey: string;
};
export type GetPaginatedAccountsUsingGetApiResponse = /** status 200 OK */ AccountDtoRes[];
export type GetPaginatedAccountsUsingGetApiArg = {
  /** offset */
  offset?: number;
  /** limit */
  limit?: number;
  /** accountWithBalance */
  accountWithBalance?: boolean;
  /** accountWithBalanceAndCBA */
  accountWithBalanceAndCba?: boolean;
  /** audit */
  audit?: string;
};
export type GetAccountByKeyUsingGetApiResponse = /** status 200 OK */ AccountDtoRes;
export type GetAccountByKeyUsingGetApiArg = {
  /** accountKey */
  accountKey: string;
  /** accountWithBalance */
  accountWithBalance?: boolean;
  /** accountWithBalanceAndCBA */
  accountWithBalanceAndCba?: boolean;
  /** audit */
  audit?: string;
};
export type UpdateAccountUsingPutApiResponse = unknown;
export type UpdateAccountUsingPutApiArg = {
  /** accountKey */
  accountKey: string;
  /** treatNullAsReset */
  treatNullAsReset?: boolean;
  accountDtoReq: AccountDtoReq;
};
export type GetAccountBundlesUsingGetApiResponse = /** status 200 OK */ BundleDto[];
export type GetAccountBundlesUsingGetApiArg = {
  /** accountKey */
  accountKey: string;
  /** bundlesFilter */
  bundlesFilter?: string;
  /** audit */
  audit?: string;
};
export type GetAccountInvoicesUsingGetApiResponse = /** status 200 OK */ InvoiceDto[];
export type GetAccountInvoicesUsingGetApiArg = {
  /** accountKey */
  accountKey: string;
  /** startDate */
  startDate?: string;
  /** endDate */
  endDate?: string;
  /** withMigrationInvoices */
  withMigrationInvoices?: boolean;
  /** unpaidInvoicesOnly */
  unpaidInvoicesOnly?: boolean;
  /** includeVoidedInvoices */
  includeVoidedInvoices?: boolean;
  /** invoicesFilter */
  invoicesFilter?: string;
  /** audit */
  audit?: string;
};
export type GetPaymentMethodsUsingGetApiResponse = /** status 200 OK */ PaymentMethodDto[];
export type GetPaymentMethodsUsingGetApiArg = {
  /** accountKey */
  accountKey: string;
  /** withPluginInfo */
  withPluginInfo?: boolean;
  /** includedDeleted */
  includedDeleted?: boolean;
  /** audit */
  audit?: string;
};
export type AddPaymentMethodUsingPostApiResponse = /** status 200 OK */ PaymentMethodDto | /** status 201 Created */ undefined;
export type AddPaymentMethodUsingPostApiArg = {
  /** accountKey */
  accountKey: string;
  /** isDefault */
  isDefault?: boolean;
  /** payAllUnpaidInvoices */
  payAllUnpaidInvoices?: boolean;
  /** controlPluginName */
  controlPluginName?: string;
  paymentMethodDto: PaymentMethodDto;
};
export type SetDefaultUsingPutApiResponse = unknown;
export type SetDefaultUsingPutApiArg = {
  /** accountKey */
  accountKey: string;
  /** paymentMethodId */
  paymentMethodId: string;
  /** payAllUnpaidInvoices */
  payAllUnpaidInvoices?: boolean;
};
export type GetAccountPaymentsUsingGetApiResponse = /** status 200 OK */ PaymentDto[];
export type GetAccountPaymentsUsingGetApiArg = {
  /** accountKey */
  accountKey: string;
  /** withAttempts */
  withAttempts?: boolean;
  /** withPluginInfo */
  withPluginInfo?: boolean;
  /** audit */
  audit?: string;
};
export type GetPaymentMethodsUsingGet1ApiResponse = /** status 200 OK */ PaymentMethodDto[];
export type GetPaymentMethodsUsingGet1ApiArg = {
  /** offset */
  offset?: number;
  /** limit */
  limit?: number;
  /** pluginName */
  pluginName?: string;
  /** withPluginInfo */
  withPluginInfo?: boolean;
  /** audit */
  audit?: string;
};
export type AuditingEntityDto = {
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
};
export type AccountProfileDto = {
  accountId?: string;
  auditInfo?: AuditingEntityDto;
  name?: string;
  status?:
    | 'ACTIVE'
    | 'BOOTSTRAP'
    | 'KB_EXCEPTION'
    | 'MISSING_ADDRESS'
    | 'MISSING_CONTACT'
    | 'MISSING_DETAILS'
    | 'MISSING_KB_ACCOUNT'
    | 'MISSING_PAYMENT_METHOD'
    | 'READY';
};
export type AuditLog = {
  changeDate?: string;
  changeType?: string;
  changedBy?: string;
  comments?: string;
  history?: object;
  objectId?: string;
  objectType?:
    | 'ACCOUNT'
    | 'ACCOUNT_EMAIL'
    | 'BLOCKING_STATES'
    | 'BUNDLE'
    | 'CUSTOM_FIELD'
    | 'INVOICE'
    | 'INVOICE_ITEM'
    | 'INVOICE_PAYMENT'
    | 'PAYMENT'
    | 'PAYMENT_ATTEMPT'
    | 'PAYMENT_METHOD'
    | 'SERVICE_BROADCAST'
    | 'SUBSCRIPTION'
    | 'SUBSCRIPTION_EVENT'
    | 'TAG'
    | 'TAG_DEFINITION'
    | 'TENANT'
    | 'TENANT_KVS'
    | 'TRANSACTION';
  reasonCode?: string;
  userToken?: string;
};
export type AccountDtoRes = {
  accountBalance?: number;
  accountCBA?: number;
  address1?: string;
  address2?: string;
  auditLogs?: AuditLog[];
  billCycleDayLocal?: number;
  city?: string;
  company?: string;
  country?: string;
  currency: 'EUR' | 'GBP' | 'USD';
  email?: string;
  externalKey?: string;
  firstNameLength?: number;
  isMigrated?: boolean;
  isPaymentDelegatedToParent?: boolean;
  locale?: string;
  name?: string;
  notes?: string;
  parentAccountExternalKey?: string;
  paymentMethodId?: string;
  phone?: string;
  postalCode?: string;
  referenceTime?: string;
  state?: string;
  statusEnum?:
    | 'ACTIVE'
    | 'BOOTSTRAP'
    | 'KB_EXCEPTION'
    | 'MISSING_ADDRESS'
    | 'MISSING_CONTACT'
    | 'MISSING_DETAILS'
    | 'MISSING_KB_ACCOUNT'
    | 'MISSING_PAYMENT_METHOD'
    | 'READY';
  timeZone?: string;
};
export type AccountDtoReq = {
  accountBalance?: number;
  accountCBA?: number;
  address1?: string;
  address2?: string;
  auditLogs?: AuditLog[];
  billCycleDayLocal?: number;
  city?: string;
  company?: string;
  country?: string;
  currency: 'EUR' | 'GBP' | 'USD';
  email?: string;
  externalKey?: string;
  firstNameLength?: number;
  isMigrated?: boolean;
  isPaymentDelegatedToParent?: boolean;
  locale?: string;
  name?: string;
  notes?: string;
  parentAccountExternalKey?: string;
  paymentMethodId?: string;
  phone?: string;
  postalCode?: string;
  referenceTime?: string;
  state?: string;
  timeZone?: string;
};
export type EventSubscription = {
  auditLogs?: AuditLog[];
  billingPeriod?:
    | 'ANNUAL'
    | 'BIANNUAL'
    | 'BIENNIAL'
    | 'BIMESTRIAL'
    | 'BIWEEKLY'
    | 'DAILY'
    | 'MONTHLY'
    | 'NINETY_DAYS'
    | 'NO_BILLING_PERIOD'
    | 'QUARTERLY'
    | 'SIXTY_DAYS'
    | 'THIRTY_DAYS'
    | 'TRIANNUAL'
    | 'WEEKLY';
  effectiveDate?: string;
  eventId?: string;
  eventType?:
    | 'CHANGE'
    | 'PAUSE_BILLING'
    | 'PAUSE_ENTITLEMENT'
    | 'PHASE'
    | 'RESUME_BILLING'
    | 'RESUME_ENTITLEMENT'
    | 'SERVICE_STATE_CHANGE'
    | 'START_BILLING'
    | 'START_ENTITLEMENT'
    | 'STOP_BILLING'
    | 'STOP_ENTITLEMENT';
  isBlockedBilling?: boolean;
  isBlockedEntitlement?: boolean;
  phase?: string;
  plan?: string;
  priceList?: string;
  product?: string;
  serviceName?: string;
  serviceStateName?: string;
};
export type BlockPrice = {
  max?: number;
  price?: number;
  size?: number;
  unitName?: string;
};
export type TierPrice = {
  blockPrices?: BlockPrice[];
};
export type UsagePrice = {
  billingMode?: 'IN_ADVANCE' | 'IN_ARREAR';
  tierBlockPolicy?: 'ALL_TIERS' | 'TOP_TIER';
  tierPrices?: TierPrice[];
  usageName?: string;
  usageType?: 'CAPACITY' | 'CONSUMABLE';
};
export type PhasePrice = {
  fixedPrice?: number;
  phaseName?: string;
  phaseType?: string;
  planName?: string;
  recurringPrice?: number;
  usagePrices?: UsagePrice[];
};
export type SubscriptionDto = {
  accountExternalKey?: string;
  auditLogs?: AuditLog[];
  billCycleDayLocal?: number;
  billingEndDate?: string;
  billingPeriod?:
    | 'ANNUAL'
    | 'BIANNUAL'
    | 'BIENNIAL'
    | 'BIMESTRIAL'
    | 'BIWEEKLY'
    | 'DAILY'
    | 'MONTHLY'
    | 'NINETY_DAYS'
    | 'NO_BILLING_PERIOD'
    | 'QUARTERLY'
    | 'SIXTY_DAYS'
    | 'THIRTY_DAYS'
    | 'TRIANNUAL'
    | 'WEEKLY';
  billingStartDate?: string;
  bundleExternalKey?: string;
  bundleId?: string;
  cancelledDate?: string;
  chargedThroughDate?: string;
  events?: EventSubscription[];
  externalKey?: string;
  phaseType?: 'DISCOUNT' | 'EVERGREEN' | 'FIXEDTERM' | 'TRIAL';
  planName?: string;
  priceList?: string;
  priceOverrides?: PhasePrice[];
  prices?: PhasePrice[];
  productCategory?: 'ADD_ON' | 'BASE' | 'STANDALONE';
  productName?: string;
  sourceType?: 'MIGRATED' | 'NATIVE' | 'TRANSFERRED';
  startDate?: string;
  state?: 'ACTIVE' | 'BLOCKED' | 'CANCELLED' | 'EXPIRED' | 'PENDING';
  subscriptionId?: string;
};
export type BundleTimelineDto = {
  accountExternalKey?: string;
  auditLogs?: AuditLog[];
  bundleId?: string;
  events?: EventSubscription[];
  externalKey?: string;
};
export type BundleDto = {
  accountExternalKey?: string;
  auditLogs?: AuditLog[];
  bundleId?: string;
  externalKey?: string;
  subscriptions?: SubscriptionDto[];
  timeline?: BundleTimelineDto;
};
export type InvoiceItemDto = {
  accountExternalKey?: string;
  amount?: number;
  auditLogs?: AuditLog[];
  bundleId?: string;
  catalogEffectiveDate?: string;
  childAccountExternalKey?: string;
  childItems?: InvoiceItemDto[];
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BRL'
    | 'BSD'
    | 'BTC'
    | 'BTN'
    | 'BWP'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GGP'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'IMP'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JEP'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LTL'
    | 'LVL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SPL'
    | 'SRD'
    | 'STD'
    | 'SVC'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TVD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XCD'
    | 'XDR'
    | 'XOF'
    | 'XPF'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
    | 'ZWD';
  description?: string;
  endDate?: string;
  invoiceId?: string;
  invoiceItemId?: string;
  itemDetails?: string;
  itemType?:
    | 'CBA_ADJ'
    | 'CREDIT_ADJ'
    | 'EXTERNAL_CHARGE'
    | 'FIXED'
    | 'ITEM_ADJ'
    | 'PARENT_SUMMARY'
    | 'RECURRING'
    | 'REPAIR_ADJ'
    | 'TAX'
    | 'USAGE';
  linkedInvoiceItemId?: string;
  phaseName?: string;
  planName?: string;
  prettyPhaseName?: string;
  prettyPlanName?: string;
  prettyProductName?: string;
  prettyUsageName?: string;
  productName?: string;
  quantity?: number;
  rate?: number;
  startDate?: string;
  subscriptionId?: string;
  usageName?: string;
};
export type InvoiceDto = {
  accountExternalKey?: string;
  amount?: number;
  auditLogs?: AuditLog[];
  balance?: number;
  bundleKeys?: string;
  creditAdj?: number;
  credits?: InvoiceItemDto[];
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BRL'
    | 'BSD'
    | 'BTC'
    | 'BTN'
    | 'BWP'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GGP'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'IMP'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JEP'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LTL'
    | 'LVL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SPL'
    | 'SRD'
    | 'STD'
    | 'SVC'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TVD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XCD'
    | 'XDR'
    | 'XOF'
    | 'XPF'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
    | 'ZWD';
  invoiceDate?: string;
  invoiceId?: string;
  invoiceNumber?: string;
  isParentInvoice?: boolean;
  items?: InvoiceItemDto[];
  parentAccountExternalKey?: string;
  parentInvoiceId?: string;
  refundAdj?: number;
  status?: 'COMMITTED' | 'DRAFT' | 'VOID';
  targetDate?: string;
  trackingIds?: string[];
};
export type PluginProperty = {
  isUpdatable?: boolean;
  key?: string;
  value?: string;
};
export type PaymentMethodPluginDetail = {
  externalPaymentMethodId?: string;
  isDefaultPaymentMethod?: boolean;
  properties?: PluginProperty[];
};
export type PaymentMethodDto = {
  accountExternalKey?: string;
  auditLogs?: AuditLog[];
  externalKey?: string;
  isDefault?: boolean;
  paymentMethodId?: string;
  pluginInfo?: PaymentMethodPluginDetail;
  pluginName?: string;
  pluginProperty?: {
    [key: string]: string;
  };
};
export type PaymentAttemptDto = {
  accountExternalKey?: string;
  amount?: number;
  auditLogs?: AuditLog[];
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BRL'
    | 'BSD'
    | 'BTC'
    | 'BTN'
    | 'BWP'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GGP'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'IMP'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JEP'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LTL'
    | 'LVL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SPL'
    | 'SRD'
    | 'STD'
    | 'SVC'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TVD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XCD'
    | 'XDR'
    | 'XOF'
    | 'XPF'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
    | 'ZWD';
  effectiveDate?: string;
  paymentExternalKey?: string;
  paymentMethodId?: string;
  pluginName?: string;
  pluginProperties?: PluginProperty[];
  stateName?: string;
  transactionExternalKey?: string;
  transactionId?: string;
  transactionType?: 'AUTHORIZE' | 'CAPTURE' | 'CHARGEBACK' | 'CREDIT' | 'PURCHASE' | 'REFUND' | 'VOID';
};
export type PaymentTransaction = {
  amount?: number;
  auditLogs?: AuditLog[];
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BRL'
    | 'BSD'
    | 'BTC'
    | 'BTN'
    | 'BWP'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GGP'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'IMP'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JEP'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LTL'
    | 'LVL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SPL'
    | 'SRD'
    | 'STD'
    | 'SVC'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TVD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XCD'
    | 'XDR'
    | 'XOF'
    | 'XPF'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
    | 'ZWD';
  effectiveDate?: string;
  firstPaymentReferenceId?: string;
  gatewayErrorCode?: string;
  gatewayErrorMsg?: string;
  paymentExternalKey?: string;
  paymentId?: string;
  processedAmount?: number;
  processedCurrency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BRL'
    | 'BSD'
    | 'BTC'
    | 'BTN'
    | 'BWP'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GGP'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'IMP'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JEP'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LTL'
    | 'LVL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SPL'
    | 'SRD'
    | 'STD'
    | 'SVC'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TVD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XCD'
    | 'XDR'
    | 'XOF'
    | 'XPF'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
    | 'ZWD';
  properties?: PluginProperty[];
  secondPaymentReferenceId?: string;
  status?: 'PAYMENT_FAILURE' | 'PAYMENT_SYSTEM_OFF' | 'PENDING' | 'PLUGIN_FAILURE' | 'SUCCESS' | 'UNKNOWN';
  transactionExternalKey?: string;
  transactionId?: string;
  transactionType?: 'AUTHORIZE' | 'CAPTURE' | 'CHARGEBACK' | 'CREDIT' | 'PURCHASE' | 'REFUND' | 'VOID';
};
export type PaymentDto = {
  accountExternalKey?: string;
  auditLogs?: AuditLog[];
  authAmount?: number;
  capturedAmount?: number;
  creditedAmount?: number;
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BRL'
    | 'BSD'
    | 'BTC'
    | 'BTN'
    | 'BWP'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GGP'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'IMP'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JEP'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LTL'
    | 'LVL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SPL'
    | 'SRD'
    | 'STD'
    | 'SVC'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TVD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XCD'
    | 'XDR'
    | 'XOF'
    | 'XPF'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
    | 'ZWD';
  paymentAttempts?: PaymentAttemptDto[];
  paymentExternalKey?: string;
  paymentId?: string;
  paymentMethodId?: string;
  paymentNumber?: string;
  purchasedAmount?: number;
  refundedAmount?: number;
  transactions?: PaymentTransaction[];
};
export const {
  useGetAccountsUsingGetQuery,
  useCreateAccountUsingPostMutation,
  useInvalidateCacheAccountKeyUsingPostMutation,
  useGetPaginatedAccountsUsingGetQuery,
  useGetAccountByKeyUsingGetQuery,
  useLazyGetAccountByKeyUsingGetQuery,
  useUpdateAccountUsingPutMutation,
  useGetAccountBundlesUsingGetQuery,
  useLazyGetAccountBundlesUsingGetQuery,
  useGetAccountInvoicesUsingGetQuery,
  useLazyGetAccountInvoicesUsingGetQuery,
  useGetPaymentMethodsUsingGetQuery,
  useAddPaymentMethodUsingPostMutation,
  useSetDefaultUsingPutMutation,
  useGetAccountPaymentsUsingGetQuery,
  useGetPaymentMethodsUsingGet1Query,
} = injectedRtkApi;

import React from 'react';

import { IconButton, MenuItem, Menu, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Translate } from 'react-jhipster';
import { Settings } from '@mui/icons-material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ApiIcon from '@mui/icons-material/Api';
import { useProductMenu } from './hooks/useProductMenu';
import { Link } from 'react-router-dom';
import { sitemap } from 'app/shared/util/url-utils';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

export const ProductMenu = ({ product }) => {
  const { anchorEl, open, handleCancel, handleClick, handleClose } = useProductMenu(product.name);

  return (
    <>
      <IconButton onClick={handleClick}>
        <Tooltip title={<Translate contentKey="products.product-menu">Product Menu</Translate>}>
          <MoreVertIcon />
        </Tooltip>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ dense: true }}>
        <MenuItem component={Link} to={sitemap.setup} state={{ product }}>
          <ListItemIcon>
            <ArrowCircleUpIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Translate contentKey="products.upgrade">Upgrade</Translate>
          </ListItemText>
        </MenuItem>
        <MenuItem component={Link} to={sitemap && sitemap[product.name] && sitemap[product.name].settings}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Translate contentKey="module-factory.settings">Setting</Translate>
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ApiIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Translate contentKey="products.api-doc">Api Doc</Translate>
          </ListItemText>
        </MenuItem>
        <MenuItem divider>
          <ListItemIcon>
            <HelpCenterIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Translate contentKey="products.help">Help</Translate>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCancel();
          }}
        >
          <ListItemIcon>
            <HighlightOffIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Translate contentKey="account.cancel">Cancel</Translate>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    jsScriptUrlUsingGet: build.query<JsScriptUrlUsingGetApiResponse, JsScriptUrlUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/clickstream/js-script-url/${queryArg.bundleId}` }),
      providesTags: ['USER_PROFILE'],
    }),
    statusUsingGet: build.query<StatusUsingGetApiResponse, StatusUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/clickstream/status/${queryArg.bundleId}` }),
      providesTags: ['USER_PROFILE'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as divolteApi };
export type JsScriptUrlUsingGetApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type JsScriptUrlUsingGetApiArg = {
  /** bundleId */
  bundleId: string;
};
export type StatusUsingGetApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type StatusUsingGetApiArg = {
  /** bundleId */
  bundleId: string;
};
export const { useJsScriptUrlUsingGetQuery, useStatusUsingGetQuery } = injectedRtkApi;

export const typographyStyles = `
  .sj-typo-h1 {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    letter-spacing: 0.05em;
    line-height: 1.2;
  }

  .sj-typo-h2 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1rem;
    letter-spacing: 0.05em;
    line-height: 1.3;
  }

  .sj-typo-h3 {
    font-size: 1.75rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
    letter-spacing: 0.03em;
    line-height: 1.4;
  }

  .sj-typo-h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    letter-spacing: 0.03em;
    line-height: 1.5;
  }

  .sj-typo-h5 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    letter-spacing: 0.02em;
    line-height: 1.6;
  }

  .sj-typo-h6 {
    font-size: 1rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
    letter-spacing: 0.02em;
    line-height: 1.7;
  }

  .sj-typo-p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
    letter-spacing: 0.01em;
    line-height: 1.5;
  }

  .sj-typo-caption {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    letter-spacing: 0.01em;
    line-height: 1.4;
  }

  .sj-typo-overline {
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
    letter-spacing: 0.02em;
    line-height: 1.3;
  }

  .sj-typo-span {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 1.5;
  }  

  .no-style-anchor {
    color: inherit; /* Inherit the color from the parent */
    text-decoration: none; /* Remove underline */
  }
`;

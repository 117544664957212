import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IUserStats } from 'app/shared/model/user-exploration/user-stats.model';
import { serializeAxiosError } from '../reducer.utils';

const userStatExplorationUrl = DATA_HUB_API_URL + '/exploration/userStats';

const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  users_stats: null as unknown as IUserStats,
};

export type UserStatsState = Readonly<typeof initialState>;

export const getUserStats = createAsyncThunk(
  'dataHub/FETCH_USER_STATS',
  async ({ filter, from, to }: any) => {
    return microserviceHttpClient(
      `${userStatExplorationUrl}?kpis=AVG_SESSION_DURATION,MAX_USER_AGENT_NAME,PAGEVIEWS_COUNT,EVENTS_COUNT,SESSION_COUNT,BOUNCE_RATE,MAX_REFERER,CITY_NAME,COUNTRY_CODE,SUBDIVISION_NAMES&from=${from}&to=${to}`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    );
  },
  { serializeError: serializeAxiosError }
);

export const UserStatsSlice = createSlice({
  name: 'UsersStats',
  initialState: initialState as UserStatsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserStats.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getUserStats.fulfilled, (state, action) => {
        state.loading = false;
        state.users_stats = action.payload.data.data.USER_ID as IUserStats;
      })
      .addCase(getUserStats.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.users_stats = null as unknown as IUserStats;
      });
  },
});

export default UserStatsSlice.reducer;

import axios, {AxiosRequestConfig} from "axios";
import {useState} from "react";
import {GREENSIGHTS_DATA_HUB_API_URL} from "app/shared/api/constants";

const oneTimes = [];

export function useRequests(callback?: (responses: any[]) => any) {

  const [data, setData] = useState([]);

  function load(requests: Array<{ key: number; method: string; url: string; oneTime?: boolean; body?: any; config?: AxiosRequestConfig }>) {
    const calls = requests.filter((request, index) => {
      return !(request.oneTime && oneTimes[request.key]);
    }).map(request => {
      const call = () => {
        if (request.body && request.body.start && request.body.start.startsWith('now') && ['now-1w/d', 'now-1M/d', 'now-6M/d'].indexOf(request.body.start) === -1) {
          return Promise.resolve();
        }
        return axios[request.method](
          `${GREENSIGHTS_DATA_HUB_API_URL}${request.url}`,
          request.method === 'get' ? request.config : request.body,
          request.config
        );
      };

      return call();
    });
    Promise.all(calls).then(
      responses => {
        requests.forEach(request => {
          if (request.oneTime && responses[request.key]) {
            oneTimes[request.key] = responses[request.key].data;
          }
        });
        const rs = responses.map(response => (response && response.data ? response.data : undefined));
        for (const r of rs) {
          if (!r) {
            return;
          }
        }
        oneTimes.forEach((d, index) => {
          if (d) {
            rs[index] = d;
          }
        })
        setData(callback ? callback(rs) : rs);
      },
      reasons => {
        console.error(reasons);
      });
  }

  return {data, load};
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IUsers } from 'app/shared/model/users.model';
import { IUsersOverview } from 'app/shared/model/users-overview.model';
import { lastWeek } from 'app/modules/views/module-root/views/web-analytics/components/top-users/actions/timeranges';

const usersScoresUrl = DATA_HUB_API_URL + '/scores/users';

export const initialState = {
  errorMessage: null as unknown as string, // Errors returned from server side
  loading: false,

  params: lastWeek,
  data: [] as IUsersOverview[],
};

export type TopUsersState = Readonly<typeof initialState>;

// Actions

export const getTopUsers = createAsyncThunk(
  'webAnalyticsHome/getTopUsers',
  async ({ from, to, filter }: { from: string; to: string; filter: any }) => {
    return microserviceHttpClient(
      `${usersScoresUrl}/timeframe?to=${to}&limit=5&from=${from}`,
      'post',
      JSON.stringify({
        filters: {
          filterEntryList: filter,
        },
      })
    );
  },
  {
    serializeError: serializeAxiosError,
  }
);

// Slice
export const TopUsersSlice = createSlice({
  name: 'topUsers',
  initialState: initialState as TopUsersState,
  reducers: {
    setParams(state, action) {
      state.params = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTopUsers.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getTopUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = getUsersOverview(action.payload.data) as IUsersOverview[];
      })
      .addCase(getTopUsers.rejected, (state, action) => {
        state.loading = false;
        state.data = [] as IUsersOverview[];
      });
  },
});

export const { setParams } = TopUsersSlice.actions;
export default TopUsersSlice.reducer;

const getUsersOverview = (usersList: IUsers[]) => {
  return usersList.map(element => {
    return {
      id: element.id,
      userId: element.userId,
      engagementScore: element.engagementScore,
      sessionCount: Number(element.sessionCount).toFixed(),
      countrySplit: element.countrySplit[0] ? element.countrySplit[0].key : 'no data',
      citySplit: element.citySplit[0] ? element.citySplit[0].key : 'no data',
      deviceCategorySplit: element.deviceCategorySplit[0] ? element.deviceCategorySplit[0].key : 'no data',
      browserFamilySplit: element.browserFamilySplit[0] ? element.browserFamilySplit[0].key : 'no data',
      osFamilySplit: element.osFamilySplit[0] ? element.osFamilySplit[0].key : 'no data',
    };
  });
};

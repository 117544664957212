import { Stack } from '@mui/material';
import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { PropertiesGrid } from 'app/components/properties/properties-grid';
import { TabItem, TabItemProps, Tabulate } from 'app/components/tabulation';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getBundleId } from 'app/shared/layout/restricted/side-bar/menu-items';
import { getSubscriptionSettings } from 'app/shared/reducers/subscription-settings/settings';
import { getSubscriptionSettingsCatalog } from 'app/shared/reducers/subscription-settings/settingsCatalog';
import { sitemap } from 'app/shared/util/url-utils';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { translate } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import SettingsForm from './SettingsForm';
import { useFindSubscriptionSettingsByIdUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/subscription-settings';
import { useGetAccountBundlesUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetMuiFormBuilderForPlanUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/muiFormBuilder';

export const SubscriptionSettings = props => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { data: bundles = [], isFetching: subscriptionlLoading } = useGetAccountBundlesUsingGetQuery(
    accountProfile.accountId ? { accountKey: accountProfile.accountId } : skipToken
  );

  const { productName } = useParams();
  const bundleId = getBundleId(bundles, productName);

  const bundle = bundles.filter(item => item.bundleId === bundleId).pop();
  const baseSubscription = bundle ? bundle.subscriptions.filter(item => item.productCategory === 'BASE').pop() : undefined;
  const { settings, settingsId } = useFindSubscriptionSettingsByIdUsingGetQuery(
    baseSubscription ? { subscriptionId: baseSubscription.subscriptionId } : skipToken,
    {
      selectFromResult(res) {
        return {
          settings: res.data ? res.data.settings : [],
          settingsId: res.data ? res.data.subscriptionId : null,
        };
      },
    }
  );
  const { settingsCatalog, settingsCatalogId } = useGetMuiFormBuilderForPlanUsingGetQuery(
    baseSubscription ? { subscriptionId: baseSubscription.subscriptionId } : skipToken,
    {
      selectFromResult(res) {
        return {
          settingsCatalog: res.data ? res.data.fields : [],
          settingsCatalogId: res.data ? res.data.subscriptionId : null,
        };
      },
    }
  );

  const planDetails = {
    'settings.since': baseSubscription?.billingStartDate ? dayjs(baseSubscription.billingStartDate).fromNow() : '-',
    'settings.plan-name': baseSubscription?.planName ? baseSubscription.planName : '-',
    'settings.billing-period': baseSubscription?.billingPeriod ? baseSubscription.billingPeriod : '-',
    'settings.product': baseSubscription?.productName ? baseSubscription.productName : '-',
    'settings.phase': baseSubscription?.phaseType ? baseSubscription.phaseType : '-',
    'settings.state': baseSubscription?.state ? baseSubscription.state : '-',
  };

  // const addonList = bundle ? bundle.subscriptions.filter(item => item.productCategory !== 'BASE') : [];
  // const addons = addonList;

  const tabulations: TabItemProps[] = [
    {
      tab: { label: translate('profile.tab.profile', null, 'Settings') },
      children: settingsCatalog && Object.keys(settingsCatalog).length > 0 && (
        <SettingsForm settings={settings} settingsCatalog={settingsCatalog} settingsCatalogId={settingsCatalogId} settingsId={settingsId} />
      ),
    },
    {
      tab: { label: 'Plan overview' },
      children: (
        <PropertiesGrid properties={Object.entries(planDetails).map<[string, string]>(([key, value]) => [translate(key), value])} />
      ),
    },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} paddingBottom={3}>
        <BreadcrumbGenerator />
      </Stack>

      <Tabulate>
        {tabulations.map((el, index) => (
          <TabItem key={index} {...el} />
        ))}
      </Tabulate>
    </>
  );
};

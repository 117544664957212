import React, { ReactNode } from 'react';
import { Chip } from '@mui/material';
import { currencyFormatter } from 'app/components/data-grid/columns/column-helpers';
import { useAppSelector } from 'app/config/store';
import dayjs from 'dayjs';
import { Translate } from 'react-jhipster';
import { Box } from '@mui/system';

export const transactionsInfos = transaction => {
  const { currentLocale }: any = useAppSelector(state => state.locale);
  return [
    {
      title: 'Transaction ID',
      i18n: 'account.transaction-property.transaction-id',
      value: transaction.transactionId,
    },
    {
      title: 'Payment ID',
      i18n: 'account.transaction-property.payment-id',
      value: transaction.paymentId,
    },
    {
      title: 'Transaction Type',
      i18n: 'account.transaction-property.transaction-type',
      value: transaction.transactionType,
      renderTransactionInfo(info: { title: string; i18n: string; value: any }, index: number): [ReactNode, ReactNode] {
        return [
          <Translate key={index} contentKey={info.i18n}>
            {info.title}
          </Translate>,
          <Box key={index} display="flex">
            <Chip sx={{ ml: 'auto' }} label={info.value} variant="outlined" size="small" />
          </Box>,
        ];
      },
    },
    {
      title: 'Currency',
      i18n: 'account.transaction-property.currency',
      value: transaction.currency,
      renderTransactionInfo(info: { title: string; i18n: string; value: any }, index: number): [ReactNode, ReactNode] {
        return [
          <Translate key={index} contentKey={info.i18n}>
            {info.title}
          </Translate>,
          <Box key={index} display="flex">
            <Chip sx={{ ml: 'auto' }} label={info.value} variant="outlined" size="small" />
          </Box>,
        ];
      },
    },
    {
      title: 'Amount',
      i18n: 'account.transaction-property.amount',
      value: currencyFormatter(transaction.currency).format(Number(transaction.amount)),
    },
    {
      title: 'Processed Currency',
      i18n: 'account.transaction-property.processedCurrency',
      value: transaction.processedCurrency,
      renderTransactionInfo(info: { title: string; i18n: string; value: any }, index: number): [ReactNode, ReactNode] {
        return [
          <Translate key={index} contentKey={info.i18n}>
            {info.title}
          </Translate>,
          <Box key={index} display="flex">
            <Chip sx={{ ml: 'auto' }} label={info.value} variant="outlined" size="small" />
          </Box>,
        ];
      },
    },
    {
      title: 'Processed Amount',
      i18n: 'account.transaction-property.processedAmount',
      value: currencyFormatter(transaction.processedCurrency).format(Number(transaction.processedAmount)),
    },
    {
      title: 'Effective Date',
      i18n: 'account.transaction-property.effectiveDate',
      value: dayjs(transaction.effectiveDate).locale(currentLocale).format('llll'),
    },
    {
      title: 'Status',
      i18n: 'account.transaction-property.status',
      value: transaction.status,
      renderTransactionInfo(info: { title: string; i18n: string; value: any }, index: number): [ReactNode, ReactNode] {
        return [
          <Translate key={index} contentKey={info.i18n}>
            {info.title}
          </Translate>,
          <Box key={index} display="flex">
            <Chip
              sx={{ ml: 'auto' }}
              label={info.value}
              variant="outlined"
              color={info.value === 'SUCCESS' ? 'success' : 'default'}
              size="small"
            />
          </Box>,
        ];
      },
    },
  ];
};

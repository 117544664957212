import React from 'react';
import { Box, Typography } from '@mui/material';

export const ActionPercentageBigArrow = ({ data, typographyProps = { variant: 'h4' } as any }) => {
  return (
    <Box>
      <Box
        sx={{
          borderRight: theme => `30px solid ${theme.palette.primary.main}`,
          borderLeft: theme => `30px solid ${theme.palette.primary.main}`,
          borderTop: '25px solid transparent',
          width: 0,
          height: 0,
        }}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '60px',
          height: '30px',
          backgroundColor: theme => theme.palette.primary.main,
        }}
      >
        <Typography {...typographyProps} color="white">
          {data} %
        </Typography>
      </Box>
      <Box
        sx={{
          borderRight: '30px solid transparent',
          borderLeft: '30px solid transparent',
          borderTop: theme => `25px solid ${theme.palette.primary.main}`,
          width: 0,
          height: 0,
        }}
      />
    </Box>
  );
};

import React from 'react';

import { Box, Divider, Stack } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { RecommendationsCount } from './RecommendationsCount';
import RecommendationToActionChart from './RecommendationToActionChart';

export const RecommendationsFunnel = ({ data }) => {
  return (
    <BasicCardLayout card={{ sx: { overflow: 'visible' } }} content={{ sx: { p: 1 } }}>
      <Stack direction="row" spacing={2} height="250px">
        <RecommendationsCount data={data} />
        <Divider orientation="vertical" flexItem />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-around" width="60%">
          <RecommendationToActionChart data={data.actions.actionBuckets} actions={data.actions} isFetching={data.actions.isFetching} />
        </Box>
      </Stack>
    </BasicCardLayout>
  );
};

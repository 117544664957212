import React from 'react';
import { StackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Translate, translate } from 'react-jhipster';
import { Container, Typography } from '@mui/material';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';

export const RecommendationsAlgorithms = () => {
  return (
    <Container maxWidth="xl">
      <BasicCardLayout
        variant="div"
        header={{
          disableTypography: true,
          title: (
            <Typography variant="h3" textAlign="center" my={5}>
              <Translate contentKey="recommendation.recommmendation-algorithms">Recommmendation Algorithms</Translate>
            </Typography>
          ),
        }}
      >
        <StackLayout spacing={2}>
          <LayoutItem
            cardOptions={{
              header: {
                disableTypography: true,
                title: <Typography variant="h4">{translate(`recommendation.strategies.random`)}</Typography>,
              },
            }}
          >
            <Typography variant="subtitle1">{translate(`recommendation.algos-page.random`)}</Typography>
          </LayoutItem>
          <LayoutItem
            cardOptions={{
              header: {
                disableTypography: true,
                title: <Typography variant="h4">{translate(`recommendation.strategies.popularity`)}</Typography>,
              },
            }}
          >
            <Typography variant="subtitle1">{translate(`recommendation.algos-page.popularity`)}</Typography>
          </LayoutItem>

          <LayoutItem
            cardOptions={{
              header: {
                disableTypography: true,
                title: <Typography variant="h4">{translate(`recommendation.strategies.trending`)}</Typography>,
              },
            }}
          >
            <Typography variant="subtitle1">{translate(`recommendation.algos-page.trending`)}</Typography>
            <Typography variant="subtitle1" mt={1}>
              {translate(`recommendation.algos-page.trending_short`)}
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              {translate(`recommendation.algos-page.trending_long`)}
            </Typography>
          </LayoutItem>

          <LayoutItem
            cardOptions={{
              header: {
                disableTypography: true,
                title: <Typography variant="h4">{translate(`recommendation.strategies.als`)}</Typography>,
              },
            }}
          >
            <Typography variant="subtitle1">{translate(`recommendation.algos-page.als`)}</Typography>
            <Typography variant="subtitle1" mt={1}>
              {translate(`recommendation.algos-page.als_1`)}
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              {translate(`recommendation.algos-page.als_2`)}
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              {translate(`recommendation.algos-page.als_3`)}
            </Typography>
          </LayoutItem>

          <LayoutItem
            cardOptions={{
              header: {
                disableTypography: true,
                title: <Typography variant="h4">{translate(`recommendation.strategies.boosted-terms`)}</Typography>,
              },
            }}
          >
            <Typography variant="subtitle1">{translate(`recommendation.algos-page.boosted-terms`)}</Typography>
          </LayoutItem>
        </StackLayout>
      </BasicCardLayout>
    </Container>
  );
};

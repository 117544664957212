import React from 'react';

import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getChartColorPalette, colorPaletteMode } from 'app/shared/util/color-palette-calculator';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';

export const UserPieChart = ({ title, data }) => {
  const theme = useTheme();
  const config: EChartsOption = {
    color: getChartColorPalette(theme.palette.secondary.main, colorPaletteMode.MAIN_LIGHT_DARK),
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        type: 'pie',
        stillShowZeroSum: true,
        radius: ['60%', '90%'],
        data,
        label: {
          show: false,
        },
      },
    ],
  };

  return (
    <BasicCardLayout
      header={{
        disableTypography: true,
        title: (
          <Typography variant="subtitle1" color="textSecondary">
            {title}
          </Typography>
        ),
      }}
      content={{ sx: { p: 0 } }}
      card={{ sx: { width: '25%', overflow: 'visible' } }}
    >
      <ReactECharts style={{ height: '100%' }} option={config} />
    </BasicCardLayout>
  );
};

import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useDeleteItemByQueryMutation } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { Translate, translate } from 'react-jhipster';
import { setItemsList } from 'app/shared/reducers/views/recommendations/items';
import { useAppDispatch } from 'app/config/store';

export const ConfirmDeleteItemsByQuery = ({ open, setOpen, itemCount, methods, searchItemByQuery }) => {
  const dispatch = useAppDispatch();
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const [deleteItemsByQuery] = useDeleteItemByQueryMutation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle typography="h4">
        <Translate contentKey="recommendation.items.delete-matching-items">Delete Matching Items</Translate>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Translate contentKey="recommendation.items.confirm-delete-matching-items">Confirm deleting matching items :</Translate>
          {itemCount ? ` ${itemCount} items` : ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translate('recommendation.cancel', null, 'Cancel')}</Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            deleteItemsByQuery({ accountId: accountProfile.accountId.substring(0, 8), query: methods.watch('search-text') })
              .unwrap()
              .then(response => {
                searchItemByQuery({ accountId: accountProfile?.accountId.substring(0, 8), query: methods.watch('search-text') })
                  // .unwrap()
                  .then(res => {
                    dispatch(setItemsList(res.data));
                  });
              });
            handleClose();
          }}
        >
          {translate('recommendation.items.delete-matching-items', null, 'Delete Matching Items')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

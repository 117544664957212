import React from 'react';

import { Typography, Avatar, Icon, Divider, Chip, CardMedia, Stack } from '@mui/material';
import { Translate } from 'react-jhipster';
import { productsModules } from 'app/modules/views/module-root/config/productModules';
import { ProductMenu } from '../menus/ProductMenu';
import CollapsibleProductCard from './CollapsibleProductCard';

export const images = {
  'web-analytics': 'content/img/landing-page/audience.png',
  recommendations: 'content/img/landing-page/reco.png',
  greensights: 'content/img/landing-page/greensights.png',
};

export const EnrolledProducts = ({ uniqueActiveProducts }) => {
  return (
    <>
      <Divider>
        <Chip
          label={
            <Typography variant="h4" textAlign="center">
              <Translate contentKey="products.your-products">Your Products</Translate>
            </Typography>
          }
        />
      </Divider>
      <Stack m={2}>
        {uniqueActiveProducts.map((product, index) => {
          return (
            <CollapsibleProductCard
              key={index}
              header={{
                title: (
                  <Typography variant="h5">
                    <Translate contentKey={`landing.products.${product.name}.title`}>{product.name}</Translate>
                  </Typography>
                ),
                subheader: <Translate contentKey="products.already-subscribed">Already subscribed</Translate>,
                avatar: (
                  <Avatar sx={{ bgcolor: theme => theme.palette.primary.light }}>
                    <Icon>{productsModules[product.name]?.sidebar?.icon}</Icon>
                  </Avatar>
                ),
                action: <ProductMenu product={product} />,
              }}
              card={{
                sx: {
                  backgroundImage: `linear-gradient(150deg,  #f9e7d1, #cedcfb)`,
                  '&:hover': {
                    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                  },
                },
              }}
            >
              <CardMedia sx={{ height: 140, mb: 1 }} image={images[product.name]} />
              <Typography whiteSpace="normal">
                <Translate contentKey={`landing.products.${product.name}.summary`}>{product.name}</Translate>
              </Typography>
            </CollapsibleProductCard>
          );
        })}
      </Stack>
    </>
  );
};

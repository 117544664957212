import { CardContentProps, CardHeaderProps, CardProps } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { CoreCardLayoutProps } from '../components/layout/card/BasicCardLayout';

export type VariantName = 'div';

export type VariantsMap = Record<VariantName, CoreCardLayoutProps>;

export function useVariants(): VariantsMap {
  const theme = useTheme();

  return {
    div: {
      card: divCardProps(theme),
      header: divHeaderProps(theme),
      content: divContentProps(theme),
    },
  } as Record<VariantName, CoreCardLayoutProps>;
}

function divContentProps(theme: Theme): CardContentProps {
  return {
    sx: { padding: theme.spacing(0.5), '&:last-child': { paddingBottom: theme.spacing(0.5) } },
  };
}

function divHeaderProps(theme: Theme): CardHeaderProps {
  return {
    sx: { padding: theme.spacing(0), paddingBottom: theme.spacing(2) },
    titleTypographyProps: { variant: 'h3' },
  };
}

function divCardProps(theme: Theme): CardProps {
  return {
    variant: 'outlined',
    sx: {
      padding: theme.spacing(0),
      backgroundColor: 'transparent',
      border: 'none',
    },
  };
}

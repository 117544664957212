import React from 'react';

import { RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { documentationUrl, sitemap } from 'app/shared/util/url-utils';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { translate, Translate } from 'react-jhipster';
import { ImageCard } from '../../components/cards/ImageCard';
import { Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { GreensightsAccordion } from '../../components/accordion/greensights/GreensightsAccordion';

export function GreensightsSummary() {
  return <GreensightsAccordion />;

  return (
    <RawGridLayout>
      <LayoutItem gridItemProps={{ xs: 4, display: 'grid' }}>
        <ImageCard
          title={
            <Typography variant="h5">
              <Translate contentKey="module-factory.dashboard">Dashboard</Translate>
            </Typography>
          }
          icon={DashboardIcon}
          links={[{ title: translate(`module-factory.board`, null, 'Board'), to: sitemap.greensights.board }]}
        />
      </LayoutItem>
      <LayoutItem gridItemProps={{ xs: 4, display: 'grid' }}>
        <ImageCard
          title={
            <Typography variant="h5">
              <Translate contentKey="module-factory.documentation">Documentation</Translate>
            </Typography>
          }
          icon={MenuBookIcon}
          links={[
            {
              title: translate(`module-factory.quickstart`, null, 'Quickstart'),
              to: documentationUrl(sitemap.ext.docs.greensights),
              target: '_blank',
            },
          ]}
        />
      </LayoutItem>
    </RawGridLayout>
  );
}

import { accountApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { catalogApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/catalog';

export const useProductsStates = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { data: catalog = [] } = catalogApi.endpoints.getCatalogJsonUsingGet.useQueryState();
  const { uniqueActiveProducts = [], otherProducts = [] } = accountApi.endpoints.getAccountBundlesUsingGet.useQueryState(
    { accountKey: accountProfile.accountId },
    {
      selectFromResult({ data }) {
        const subscribedProducts = data
          ?.flatMap(value => {
            return value.subscriptions;
          })
          .filter(sub => sub.state === 'ACTIVE' && sub.productCategory === 'BASE');
        const activeProducts = subscribedProducts?.map(sub => {
          return catalog[0] ? catalog[0].products.filter(p => p.name === sub.productName)[0] : [];
        });
        return {
          uniqueActiveProducts: [...new Set(activeProducts)],
          otherProducts: catalog[0]?.products.filter(p => !subscribedProducts?.map(sub => sub.productName).includes(p.name)),
        };
      },
    }
  );
  return {
    uniqueActiveProducts,
    otherProducts,
  };
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { activeUser, session } from 'app/modules/views/module-root/views/web-analytics/components/map-chart/actions/KPIToggler';
import { lastWeek } from 'app/modules/views/module-root/views/web-analytics/components/map-chart/actions/timeranges';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { ISessionsByCountry } from 'app/shared/model/kpis-aggregation-.model';
import { serializeAxiosError } from '../reducer.utils';

const kpisAggregationUrl = DATA_HUB_API_URL + '/exploration/data';

const initialState = {
  errorMessage: null as unknown as string, // Errors returned from server side
  loading: false,

  data: null as unknown as Array<ISessionsByCountry>,

  params: lastWeek,
  selectedMapChart: activeUser,
};

export type MapChartState = Readonly<typeof initialState>;

// Actions
export const getMapChartAggregation = createAsyncThunk(
  'webAnalyticsHome/FETCH_MAP_KPIS_AGGREGATION',
  async ({ filter, from, to }: any) => {
    return microserviceHttpClient(
      `${kpisAggregationUrl}/kpisPerBucket?from=${from}&to=${to}&bucketingDimensions=COUNTRY_CODE&kpis=USER_COUNT,SESSION_COUNT`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    ).then(resp => resp.data);
  },
  { serializeError: serializeAxiosError }
);

// Slice
export const MapChartSlice = createSlice({
  name: 'mapChartKpis',
  initialState: initialState as MapChartState,
  reducers: {
    setParams(state, action) {
      state.params = action.payload;
    },
    setSelectedMapChart(state, action) {
      state.selectedMapChart = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMapChartAggregation.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getMapChartAggregation.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.COUNTRY_CODE.map(element => {
          return { countryCode: element.countryCode, buckets: element.buckets };
        }) as Array<ISessionsByCountry>;
      })
      .addCase(getMapChartAggregation.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;

        state.data = null as unknown as Array<ISessionsByCountry>;
      });
  },
});

export const { setSelectedMapChart, setParams } = MapChartSlice.actions;

export default MapChartSlice.reducer;

import { KPIS } from 'app/shared/util/reducer-utils';

export const getPieChartList = (secondary_dimensions, bucketingDimensions) => {
  const getDataset = (name, percentage = true) => {
    const dataset = secondary_dimensions ? secondary_dimensions[bucketingDimensions][name] || { total: 0, values: [] } : null;
    if (dataset && percentage) {
      return getValuesAsPercentage(dataset);
    }
    return dataset;
  };

  return [
    {
      title: KPIS.ACTIVE_USER_COUNT.i18n,
      data: getDataset(KPIS.ACTIVE_USER_COUNT.name),
      formatter: KPIS.ACTIVE_USER_COUNT.formatter,
    },
    {
      title: KPIS.USER_COUNT.i18n,
      data: getDataset(KPIS.USER_COUNT.name),
      formatter: KPIS.USER_COUNT.formatter,
    },
    {
      title: KPIS.SESSION_COUNT.i18n,
      data: getDataset(KPIS.SESSION_COUNT.name),
      formatter: KPIS.SESSION_COUNT.formatter,
    },
    {
      title: KPIS.PAGEVIEWS_COUNT.i18n,
      data: getDataset(KPIS.PAGEVIEWS_COUNT.name),
      formatter: KPIS.PAGEVIEWS_COUNT.formatter,
    },
    {
      title: KPIS.BOUNCE_RATE.i18n,
      data:
        getDataset('BOUNCE_COUNT') &&
        getDataset('SESSION_COUNT', false) &&
        normalizeTotal(getDataset('BOUNCE_COUNT'), getDataset('SESSION_COUNT', false).total / 100),
      formatter: KPIS.BOUNCE_RATE.formatter,
    },
    {
      title: KPIS.AVG_SESSION_DURATION.i18n,
      data:
        getDataset('SUM_SESSION_DURATION') &&
        getDataset('SESSION_COUNT', false) &&
        normalizeTotal(getDataset('SUM_SESSION_DURATION'), getDataset('SESSION_COUNT', false).total),
      formatter: KPIS.AVG_SESSION_DURATION.formatter,
    },
  ];
};

const getValuesAsPercentage = ({ total, values }) => {
  return {
    total,
    values: values.map(v => {
      return {
        ...v,
        value: Number(v.value) / total,
      };
    }),
  };
};

const normalizeTotal = ({ total, values }, referenceTotal) => {
  return {
    total: total / referenceTotal,
    values,
  };
};

import React from 'react';

import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { RecommendationsKpisForm } from './components/RecommendationsKpisForm';
import { RecommendationsFunnel } from './components/RecommendationsFunnel';
import { useRecommendationsKpis } from './hooks/useRecommendationsKpis';
import { RecommendationsKpisTimerangeFilter } from './components/RecommendationsKpisTimerangeFilter';
import { Divider } from '@mui/material';
import { ActionsNumbers } from './components/ActionsNumbers';
import ActionsCountChart from './components/ActionsCountChart';
import ActionsByTime from './components/ActionsByTime';
import ActionsCountChartByType from './components/ActionsCountChartByType';
import RecommendationsByTimeChart from './components/RecommendationsByTime';

export function RecommendationsHome() {
  const { fields, formParams, data, timerange } = useRecommendationsKpis();

  return (
    <BasicCardLayout
      variant="div"
      header={{
        disableTypography: true,
        title: <RecommendationsKpisForm formParams={formParams} fields={fields} />,
        action: <RecommendationsKpisTimerangeFilter timerange={timerange} />,
        sx: { p: 1, '.MuiCardHeader-action': { alignSelf: 'auto' } },
      }}
      content={{ sx: { p: 0 } }}
      card={{ sx: { overflow: 'visible' } }}
    >
      <RawStackLayout divider={<Divider flexItem />}>
        <BasicCardLayout
          variant="div"
          header={{
            title: 'Recommendations KPIs',
          }}
          content={{ sx: { p: 0 } }}
          card={{ sx: { overflow: 'visible' } }}
        >
          <RawStackLayout>
            <RecommendationsFunnel data={data} />
            <BasicCardLayout content={{ sx: { height: '300px', p: 0, '&:last-child': { p: 0 } } }} card={{ sx: { overflow: 'visible' } }}>
              <RecommendationsByTimeChart
                data={data.impressions.timeseries}
                impressions={data.impressions}
                isFetching={data.impressions.isFetching}
              />
            </BasicCardLayout>
          </RawStackLayout>
        </BasicCardLayout>

        <BasicCardLayout
          variant="div"
          header={{
            title: 'Actions KPIs',
          }}
          content={{ sx: { p: 0 } }}
          card={{ sx: { overflow: 'visible' } }}
        >
          <RawStackLayout>
            <ActionsNumbers data={data} />
            <BasicCardLayout card={{ sx: { overflow: 'visible' } }}>
              <ActionsCountChart data={data.actions.actionBuckets} actions={data.actions} isFetching={data.actions.isFetching} />
            </BasicCardLayout>
            <BasicCardLayout content={{ sx: { height: '300px', p: 0, '&:last-child': { p: 0 } } }}>
              <ActionsCountChartByType data={data.actions.actionBuckets} actions={data.actions} isFetching={data.actions.isFetching} />
            </BasicCardLayout>
            <BasicCardLayout content={{ sx: { height: '300px', p: 0, '&:last-child': { p: 0 } } }} card={{ sx: { overflow: 'visible' } }}>
              <ActionsByTime data={data.actions.actionBuckets} actions={data.actions} isFetching={data.actions.isFetching} />
            </BasicCardLayout>
          </RawStackLayout>
        </BasicCardLayout>
      </RawStackLayout>
    </BasicCardLayout>
  );
}

import React from 'react';

import { Box, Button, Popover, Tooltip } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import { useKpisWithAllBucketingDimensions } from './hooks/useKpisWithAllBucketingDimensions';
import { translate } from 'react-jhipster';
import { RequestFilterChips } from './components/RequestFilterChips';
import { RequestFilterManagerPopover } from './components/RequestFilterManagerPopover';
import { useRequestFilterManager } from './hooks/useRequestFilterManager';

export const RequestFilterManager = ({ timerange, filters, filterActions }) => {
  const {
    anchorEl,
    editFilter,
    setEditFilter,
    openFilterForm,
    setOpenFilterForm,
    openFilterPopover,
    handleClickFilterPopover,
    handleCloseFilterPopover,
    handleChipClick,
    handleChipDelete,
  } = useRequestFilterManager(filters, filterActions.deleteFilter);

  const { filterValues } = useKpisWithAllBucketingDimensions(timerange, filters, editFilter);

  return (
    <Box
      sx={{ border: theme => `1px solid ${theme.palette.primary.light}`, borderRadius: 1 }}
      display="flex"
      alignItems="center"
      width="fit-content"
    >
      <Tooltip title={translate('web-analytics.request-filter.filter', null, 'Filter')}>
        <Button
          size="small"
          startIcon={<FilterAlt fontSize="small" />}
          onClick={e => {
            handleClickFilterPopover(e);
          }}
          disableTouchRipple
        >
          {filters.length === 0 && translate('web-analytics.request-filter.filter', null, 'Filter')}
        </Button>
      </Tooltip>
      <RequestFilterChips
        handleChipClick={handleChipClick}
        handleChipDelete={handleChipDelete}
        handleClickFilterPopover={handleClickFilterPopover}
        filters={filters}
        maxDisplay={1}
      />

      <Popover
        open={openFilterPopover}
        anchorEl={anchorEl}
        onClose={handleCloseFilterPopover}
        anchorOrigin={{
          vertical: 35,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'visible',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-7px',
            left: '30px',
            width: '0px',
            height: '0px',
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: theme => `8px solid ${theme.palette.background.paper}`,
          }}
        />

        <RequestFilterManagerPopover
          filters={filters}
          filterValues={filterValues}
          openFilterForm={openFilterForm}
          setOpenFilterForm={setOpenFilterForm}
          editFilter={editFilter}
          setEditFilter={setEditFilter}
          filterActions={filterActions}
        />
      </Popover>
    </Box>
  );
};

import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AvatarGroup from '@mui/lab/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, green, pink } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import React from 'react';

export default function AvatarExample() {
  const theme = useTheme();

  const classes = {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    avatarSet: {
      display: 'flex',
      margin: theme.spacing(2),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    pink: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
    },
    green: {
      color: '#fff',
      backgroundColor: green[500],
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  };

  return (
    <div style={classes.root}>
      <div style={classes.avatarSet}>
        <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
        <Avatar alt="Travis Howard" src="https://material-ui.com/static/images/avatar/2.jpg" />
        <Avatar alt="Cindy Baker" src="https://material-ui.com/static/images/avatar/3.jpg" />
      </div>
      <div style={classes.avatarSet}>
        <Avatar>H</Avatar>
        <Avatar sx={classes.orange}>N</Avatar>
        <Avatar sx={classes.purple}>OP</Avatar>
      </div>
      <div style={classes.avatarSet}>
        <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" sx={classes.small} />
        <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
        <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" sx={classes.large} />
      </div>
      <div style={classes.avatarSet}>
        <Avatar>
          <FolderIcon />
        </Avatar>
        <Avatar sx={classes.pink}>
          <PageviewIcon />
        </Avatar>
        <Avatar sx={classes.green}>
          <AssignmentIcon />
        </Avatar>
      </div>
      <div style={classes.avatarSet}>
        <AvatarGroup max={4}>
          <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
          <Avatar alt="Travis Howard" src="https://material-ui.com/static/images/avatar/2.jpg" />
          <Avatar alt="Cindy Baker" src="https://material-ui.com/static/images/avatar/3.jpg" />
          <Avatar alt="Agnes Walker" src="https://material-ui.com/static/images/avatar/4.jpg" />
          <Avatar alt="Trevor Henderson" src="https://material-ui.com/static/images/avatar/5.jpg" />
        </AvatarGroup>
      </div>
    </div>
  );
}

import React from 'react';

import { Stack, Typography } from '@mui/material';
import { Translate } from 'react-jhipster';
import { useRedirectToSettings } from './hooks/useRedirectToSettings';
import { ProductSubscription } from './components/product-subscription/ProductSubscription';
import { ProductTabs } from './components/ProductTabs';
import { ProductContextProvider } from './context/ProductContext';

export const Products = () => {
  useRedirectToSettings();

  return (
    <ProductContextProvider>
      <Stack>
        <ProductSubscription />

        {/* <QuickStartGuides /> */}

        <Typography variant="h3" textAlign="center">
          <Translate contentKey="products.presentation">
            Explore ScoringJoe products and drive your business to the success it deserves
          </Translate>
        </Typography>

        <ProductTabs />
      </Stack>
    </ProductContextProvider>
  );
};

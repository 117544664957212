export const percentageToggleButtonOption: {
  id: number;
  value: string;
  text: string;
}[] = [
  {
    id: 0,
    value: 'count',
    text: 'count',
  },
  {
    id: 1,
    value: 'percentage',
    text: 'percentage',
  },
];
export const timestepToggleButtonOption: {
  id: number;
  value: string;
  text: string;
}[] = [
  {
    id: 0,
    value: 'DAY',
    text: 'day',
  },
  {
    id: 1,
    value: 'WEEK',
    text: 'week',
  },
  {
    id: 2,
    value: 'MONTH',
    text: 'month',
  },
  {
    id: 3,
    value: 'YEAR',
    text: 'year',
  },
];
export const numberTimestepOption: number[] = [3, 4, 5, 6, 7, 8, 9, 10];

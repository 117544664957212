import { useAppDispatch, useAppSelector } from 'app/config/store';
import { checkIfOtherYearExist, getDates, mapCohortDataIfNoOtherYearExist, mapCohortDataIfOtherYearExist } from '../functions/cohortHelper';
import { translate } from 'react-jhipster';
import { ICohort } from 'app/shared/model/user-exploration/cohort.model';
import {
  getUserExplorationCohortState,
  setUserExplorationSelectedCellDates,
  setUserExplorationSelectedCohortCell,
} from 'app/shared/reducers/views/user-exploration';

export const useCohortUtils = cohortData => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const dispatch = useAppDispatch();
  const cohortState = useAppSelector(getUserExplorationCohortState);
  const values: number[][] = [];
  let maxValue = 0;

  cohortData.forEach((row: ICohort, rowIndex: number) => {
    const yearExist = checkIfOtherYearExist(row.data);

    if (yearExist.isYearExist) {
      const { rowValues, maxRowValues } = mapCohortDataIfOtherYearExist(row, rowIndex, yearExist.index, cohortState.displayedValueType);
      values.push(...rowValues);
      if (maxRowValues > maxValue) maxValue = maxRowValues;
    } else {
      const { rowValues, maxRowValues } = mapCohortDataIfNoOtherYearExist(row, rowIndex, cohortState.displayedValueType);
      values.push(...rowValues);
      if (maxRowValues > maxValue) maxValue = maxRowValues;
    }
  });

  let usedYear: number = new Date().getFullYear();

  const yAxisData: string[] = cohortData.map((item: ICohort, itemIndex: number) => {
    if (cohortState.timeStep === 'YEAR') return item.startDate;
    if (itemIndex === 0 || item.startDate < cohortData[itemIndex - 1].startDate) {
      return getDates(usedYear, item.startDate, cohortState.timeStep, currentLocale);
    } else {
      usedYear = usedYear - 1;
      return getDates(usedYear, item.startDate, cohortState.timeStep, currentLocale);
    }
  });

  const xAxisData: string[] = cohortData.map((item: ICohort, itemIndex: number) => {
    if (cohortState.timeStep === 'DAY') return `${translate('user-exploration.cohort.day')} ${itemIndex}`;
    if (cohortState.timeStep === 'WEEK') return `${translate('user-exploration.cohort.week')} ${itemIndex}`;
    if (cohortState.timeStep === 'MONTH') return `${translate('user-exploration.cohort.month')} ${itemIndex}`;
    if (cohortState.timeStep === 'YEAR') return `${translate('user-exploration.cohort.year')} ${itemIndex}`;
  });

  const onChartClick = params => {
    dispatch(setUserExplorationSelectedCohortCell([params.value[4], params.value[1]]));
    dispatch(setUserExplorationSelectedCellDates([yAxisData[params.value[1]], yAxisData[params.value[1] - params.value[0]]]));
  };

  const onEvents: {
    click: (params: any) => void;
  } = {
    click: onChartClick,
  };

  return {
    xAxisData,
    yAxisData,
    values,
    maxValue,
    onEvents,
  };
};

import { useEffect } from 'react';
import { useGetCohortAnalysisMatrixQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-exploration';
import { useAppSelector } from 'app/config/store';
import {
  getUserExplorationCohortState,
  getUserExplorationTimerange,
  selectAllUserExplorationFilters,
} from 'app/shared/reducers/views/user-exploration';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const useUserCohort = () => {
  const cohortState = useAppSelector(getUserExplorationCohortState);
  const timerange = useAppSelector(getUserExplorationTimerange);
  const filter = useAppSelector(selectAllUserExplorationFilters);
  const {
    cohort_analysis = null,
    isUninitialized: isCohortAnalysisMatrixUninitialized,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetCohortAnalysisMatrixQuery(
    timerange
      ? {
          requestFilters: {
            filterEntryList: filter,
          },
          timeStep: cohortState.timeStep,
          nbTimeSteps: cohortState.stepNumber,
        }
      : skipToken,
    {
      selectFromResult(res) {
        return {
          ...res,
          cohort_analysis: res.data?.slice().sort(function (a, b) {
            return Number(Object.keys(a.data).length) - Number(Object.keys(b.data).length);
          }),
        };
      },
      pollingInterval: timerange?.refreshRate,
    }
  );

  useEffect(() => {
    if (!isCohortAnalysisMatrixUninitialized) {
      refetch();
    }
  }, [timerange?.refresh]);

  return { cohort_analysis, isFetching, isError, error };
};

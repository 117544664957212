import React from 'react';

import { IconButton } from '@mui/material';
import { Menu2 } from 'tabler-icons-react';

export const MenuButton = ({ handleMenu }) => {
  return (
    <IconButton
      sx={{
        display: { xs: 'flex', md: 'none' },
        mr: 2,
        borderRadius: 0,
      }}
      disableRipple
      onClick={handleMenu}
    >
      <Menu2 />
    </IconButton>
  );
};

export const HiddenMenuButton = () => {
  return (
    <IconButton
      sx={{
        display: { xs: 'flex', md: 'none' },
        visibility: 'hidden',
      }}
    >
      <Menu2 />
    </IconButton>
  );
};

import React from 'react';

import { buildEChartOptions } from '../utils/EChartOptionsBuilder';
import StatsCard from './StatsCard';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { getSunburstChartData } from '../utils/actions-data-transformation';
import humanNumber from 'human-number';
import { WithData } from 'app/modules/loading-lib/WithData';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

const ActionsCountChart = ({ data, actions, isFetching }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Box width="40%">
        <StatsCard
          variant="div"
          options={buildEChartOptions({
            title: {
              text: actions.total && humanNumber(actions.total.all, n => Number.parseFloat(n).toFixed(1)),
              subtext: 'All Actions',
              left: 'center',
              top: 'center',
              textStyle: {
                fontSize: '2.1422rem',
              },
              subtextStyle: {
                fontSize: 20,
              },
            },
            grid: {
              left: '10%',
              right: '10%',
              top: '10%',
              bottom: '10%',
              containLabel: true,
            },
            series: {
              type: 'sunburst',
              data: getSunburstChartData(actions),
              radius: ['40%', '100%'],
              itemStyle: {
                borderRadius: 7,
                borderWidth: 2,
              },
              label: {
                show: false,
              },
            },
          })}
          content={{ sx: { height: '500px', p: 0, '&:last-child': { p: 0 } } }}
          card={{ sx: { overflow: 'visible' } }}
        />
      </Box>
      <Box width="60%">
        <StatsCard
          variant="div"
          options={buildEChartOptions({
            legend: {
              data: ['Actions', 'Actions From Recommendations'],
            },
            dataset: [
              {
                dimensions: ['key', 'doc_count'],
                source: actions.withoutRecoId.actionBuckets ?? [],
              },
              {
                dimensions: ['key', 'doc_count'],
                source: actions.withRecoId.actionBuckets ?? [],
              },
            ],
            yAxis: {
              type: 'category',
              axisLabel: { interval: 0 },
            },
            xAxis: {},
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true,
            },
            series: [
              {
                name: 'Actions',
                datasetIndex: 0,
                type: 'bar',
                label: {
                  show: true,
                  position: 'inside',
                },
                stack: 'action',
              },
              {
                name: 'Actions From Recommendations',
                datasetIndex: 1,
                type: 'bar',
                label: {
                  show: true,
                  position: 'inside',
                },
                stack: 'action',
              },
            ],
          })}
          content={{ sx: { height: '500px', p: 0, '&:last-child': { p: 0 } } }}
          card={{ sx: { overflow: 'visible' } }}
        />
      </Box>
    </Stack>
  );
};

export default WithLoadingHOC(WithData(ActionsCountChart));

import { IPaymentMethods } from 'app/shared/model/payment-methods.model';
import { PaymentMethodDto } from 'app/shared/reducers/api/config-manager-cloud-services/store/payment-method';

export const OBJECT_PROPERTY = 'object';
export const BANK_ACCOUNT_TYPE = 'bank_account';
export const OBJECT_TYPE = 'payment_method';
export const SEPA_DEBIT_TYPE = 'sepa_debit';
export const CARD_TYPE = 'card';
export const UNKNOWN_TYPE = 'unknown';

export const getPaymentMethodType = (paymentMethod: PaymentMethodDto) => {
  if (paymentMethod.pluginInfo.properties.find(item => item.key === OBJECT_PROPERTY)) {
    if (paymentMethod.pluginInfo.properties.find(item => item.key === OBJECT_PROPERTY).value === BANK_ACCOUNT_TYPE)
      return BANK_ACCOUNT_TYPE;
    else if (paymentMethod.pluginInfo.properties.find(item => item.key === OBJECT_PROPERTY).value === OBJECT_TYPE) {
      if (paymentMethod.pluginInfo.properties.find(item => item.key === 'type').value === SEPA_DEBIT_TYPE) return SEPA_DEBIT_TYPE;
      else if (paymentMethod.pluginInfo.properties.find(item => item.key === 'type').value === CARD_TYPE) return CARD_TYPE;
      else return UNKNOWN_TYPE;
    }
  } else {
    return undefined;
  }
};

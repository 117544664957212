import React, { useEffect } from 'react';

import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { TableRow, TableCell, IconButton, Collapse, Typography } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import dayjs from 'dayjs';
import { Box } from '@mui/system';
import { SessionRow } from './SessionRow';
import { getVisitorProfileOpenAll } from 'app/shared/reducers/views/visitor-profile';

export const SessionsPerDayRow = ({ sessions, userEvents }) => {
  const { currentLocale }: any = useAppSelector(state => state.locale);
  const openAll = useAppSelector(getVisitorProfileOpenAll);
  const [open, setOpen] = React.useState(false);
  // useEffect(() => {
  //   setOpen(false);
  // }, [sessions]);
  useEffect(() => {
    setOpen(openAll);
  }, [openAll]);
  return (
    <>
      <TableRow sx={{ backgroundColor: theme => theme.palette.action.hover }}>
        <TableCell
          sx={{
            width: '70%',
          }}
        >
          <Typography variant="h5"> {dayjs(sessions.date).locale(currentLocale).format('ll')}</Typography>
        </TableCell>
        <TableCell
          align="right"
          sx={{
            width: '20%',
          }}
        >
          <Typography variant="h5">
            {sessions.sessions.length} {sessions.sessions.length === 1 ? 'Session' : 'Sessions'}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: '10%',
          }}
        >
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <SessionRow sessions={sessions.sessions} userEvents={userEvents} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

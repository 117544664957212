export const buildAccounts = (profileAccounts, currentAccounts) => {
  if (Array.isArray(profileAccounts) && Array.isArray(currentAccounts)) {
    return currentAccounts.map(acc => {
      const account = profileAccounts.find(a => a.accountId === acc.accountId);

      return {
        ...acc,
        roles: account?.roles || [],
      };
    });
  }
  return currentAccounts || [];
};

import React from 'react';

import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';

interface UsersMapChartProps {
  data: {
    name: string;
    value: number;
    code: string;
  }[];
}

export const UsersMapChart = ({ data }: UsersMapChartProps) => {
  const theme = useTheme();
  const option = {
    tooltip: {
      show: true,
      trigger: 'item',
      formatter(params) {
        return params.name + ' : ' + params.value;
      },
    },
    dataRange: {
      show: false,
      min: 0,
      max: 10,
      realtime: false,
      text: ['High', 'Low'],
      calculable: true,
      color: [theme.palette.primary.dark, theme.palette.primary.light],
    },
    series: {
      type: 'map',
      map: 'world',
      roam: true,
      scaleLimit: {
        max: 4,
        min: 1,
      },
      data,
    },
  };

  return <ReactECharts style={{ width: '100%', height: '300px' }} option={option} />;
};

import React from 'react';

import { Button, Collapse, Typography } from '@mui/material';

import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { sitemap } from 'app/shared/util/url-utils';
import { Translate } from 'react-jhipster';
import { TimeToggle } from './actions/TimeToggle';
import { useTopMetrics } from './hooks/useTopMetrics';
import TopMetricsCard from './components/TopMetricsCard';
import { WithData } from 'app/modules/loading-lib/WithData';

const TopMetricsGrid = () => {
  const { data } = useTopMetrics();

  const topMetric = () => (
    <RawGridLayout>
      {data.map((metric, index) => (
        <LayoutItem key={index} gridItemProps={{ lg: 3, height: '182px' }}>
          <Collapse in={true}>
            <TopMetricsCard metric={metric} />
          </Collapse>
        </LayoutItem>
      ))}
    </RawGridLayout>
  );

  return (
    <BasicCardLayout
      variant="div"
      header={{
        title: (
          <Typography variant="h5">
            <Translate contentKey="module-factory.top-metrics">Top Metrics</Translate>
          </Typography>
        ),
        action: <TimeToggle />,
      }}
      content={{ sx: { px: 0, height: '166px' } }}
      card={{ sx: { overflow: 'visible' } }}
      // actions={
      //   <Button variant="outlined" href={sitemap.webAnalitycs.home}>
      //     <Translate contentKey="module-factory.see-dashboard-overview">See Dashboard Overview</Translate>
      //   </Button>
      // }
      actionsProps={{ sx: { flexDirection: 'row-reverse', px: 0 } }}
    >
      {WithData(topMetric, data)({})}
    </BasicCardLayout>
  );
};

export default TopMetricsGrid;

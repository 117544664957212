import { Alert, Button, Stack } from '@mui/material';
import { sitemap } from 'app/shared/util/url-utils';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReportProblemOutlined } from '@mui/icons-material';
import { translate } from 'react-jhipster';

export const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  if (!location.state) {
    navigate(sitemap.home);
  }

  return (
    <Stack m={10} justifyContent="center" alignItems="center">
      {location.state && (
        <>
          <ReportProblemOutlined color="neutral" sx={{ fontSize: '100px' }} />
          <Alert
            sx={{ width: '50%' }}
            severity="error"
            action={
              <>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    navigate(sitemap.home);
                  }}
                >
                  {translate('error-page.retry', null, 'Retry')}
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    navigate(sitemap.logout);
                  }}
                >
                  {translate('error-page.logout', null, 'Logout')}
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  {translate('error-page.scoringjoe-home', null, 'Scoring Joe Home')}
                </Button>
              </>
            }
          >
            {location?.state.message}
          </Alert>
        </>
      )}
    </Stack>
  );
};

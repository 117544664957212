import React from 'react';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import latlong from 'app/modules/views/module-root/views/web-analytics/components/map-chart/latlong';
import { getName } from 'country-list';

interface UsersLiveMapChartProps {
  data: {
    name: string;
    value: number;
    code: string;
  }[];
}

export const UsersLiveMapChart = ({ data }: UsersLiveMapChartProps) => {
  const theme = useTheme();

  let max = -Infinity;
  let min = Infinity;
  data?.forEach(function (element) {
    if (element.value > max) {
      max = element.value;
    }
    if (element.value < min) {
      min = element.value;
    }
  });

  const liveOption = {
    tooltip: {
      trigger: 'item',
      formatter(params) {
        return params.name + ' : ' + params.value[2];
      },
    },
    visualMap: {
      show: false,
      min: 0,
      max,
      inRange: {
        symbolSize: [6, 20],
      },
    },
    geo: {
      type: 'map',
      map: 'world',
      roam: true,
      scaleLimit: {
        max: 4,
        min: 1,
      },
      label: {
        emphasis: {
          show: false,
        },
      },
    },
    series: {
      type: 'effectScatter',
      coordinateSystem: 'geo',
      data: data?.map(function (element) {
        let obj = {};
        for (const [key, value] of Object.entries(latlong)) {
          if (element.code === key)
            obj = {
              name: getName(key),
              value: [latlong[key].longitude, latlong[key].latitude, element.value],
            };
        }
        return obj;
      }),
    },
  };

  return <ReactECharts style={{ width: '100%', height: '300px' }} option={liveOption} />;
};

import React, { useContext } from 'react';

import { StackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { RawTabsLayout } from 'app/modules/layout-builder-lib/components/layout/TabsLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate } from 'react-jhipster';
import { ProductContext, ProductContextType } from '../context/ProductContext';
import { ProductList } from './product-card/ProductList';

export const ProductTabs = () => {
  const { uniqueActiveProducts, otherProducts } = useContext<ProductContextType>(ProductContext);

  const layout = otherProducts?.length > 0 && uniqueActiveProducts?.length > 0 ? RawTabsLayout : StackLayout;

  return layout({
    children: [
      otherProducts?.length > 0 && (
        <LayoutItem
          key="available"
          cardOptions={{
            variant: 'div',
            header: {
              title: <Translate contentKey="products.available-products">Available applications</Translate>,
            },
          }}
        >
          <ProductList productList={otherProducts} />
        </LayoutItem>
      ),
      uniqueActiveProducts?.length > 0 && (
        <LayoutItem
          key="subscribed"
          cardOptions={{
            variant: 'div',
            header: {
              title: <Translate contentKey="products.your-products">Your applications</Translate>,
            },
          }}
        >
          <ProductList productList={uniqueActiveProducts} />
        </LayoutItem>
      ),
    ],
  });
};

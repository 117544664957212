import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { useState } from 'react';

export const useDeleteCsv = fileName => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteCSVFile = async () => {
    setLoading(true);
    const response = await microserviceHttpClient(`${DATA_HUB_API_URL + '/download/csv'}/${fileName}`, 'delete');
    const res = await response.data;
    setData(res);
    setLoading(false);
  };

  return { data, loading, deleteCSVFile };
};

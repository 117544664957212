import React from 'react';

import { LinearProgress, Typography } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Translate } from 'react-jhipster';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { WithData } from 'app/modules/loading-lib/WithData';
import { LoadingStackSkeleton } from 'app/modules/loading-lib/component/LoadingStackSkeleton';

const TopMetricsCard = ({ metric }) => {
  const theme = useTheme();
  return (
    <BasicCardLayout
      header={{
        title: (
          <Typography variant="h5">
            <Translate contentKey={`open-analytics.bucketingNameMapping.${metric.kpi}`}>{metric.kpi}</Translate>
          </Typography>
        ),
      }}
      actions={
        <>
          <Box width="100%">
            <LinearProgress variant="determinate" value={Number(metric.percentage)} />
          </Box>
          <Typography variant="h6" color={theme.palette.success.main} ml={1}>
            {metric.percentage}%
          </Typography>
        </>
      }
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          {metric.kpi === 'COUNTRY_CODE' ? (
            <FlagWithCountryName country={metric.name} typographyProps={{ variant: 'h4' }} />
          ) : (
            <Typography variant="h4">{metric.name}</Typography>
          )}
        </Box>
        <Typography variant="h1" color={theme.palette.primary.main}>
          {metric.count}
        </Typography>
      </Box>
    </BasicCardLayout>
  );
};

export default WithLoading(TopMetricsCard, state => state.webAnalyticsOverview.topMetrics.loading, {
  loader() {
    return <LoadingStackSkeleton />;
  },
});

import React from 'react';

import { Delete, DragIndicator } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useAppDispatch } from 'app/config/store';
import StandardTextField from 'app/modules/form-builder-lib/Components/StandardTextField';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { deleteCardStructure, updateCardStructure } from 'app/shared/reducers/views/recommendations/widget';
import { useElementStyleForm } from '../hooks/forms/useElementStyleForm';
import { iconSxHoverError } from '../utils/CommonPopoverStyles';
import { StylesActionBar } from './StylesActionBar';

export const ItemPropertyCard = ({ element, index }) => {
  const dispatch = useAppDispatch();
  const {
    methods,
    methods: { register },
    style,
  } = useElementStyleForm(element, updateCardStructure, data => {
    return { index, element: data };
  });

  const onDelete = () => {
    dispatch(deleteCardStructure({ index }));
  };

  return (
    <BasicCardLayout
      header={{
        avatar: <DragIndicator />,
        title: (
          <Box display="flex" alignItems="center" marginX={3}>
            <StandardTextField
              methods={methods}
              field={{
                component: 'text-field',
                attribute: 'value',
                label: 'Property name',
                props: {
                  sx: { width: '50%' },
                  ...(!element.value ? { color: 'warning', focused: true } : {}),
                },
              }}
            />
          </Box>
        ),
        action: (
          <Stack direction="row" spacing={1}>
            <StylesActionBar methods={methods} style={style} hideButtons={['link']} />
            <Tooltip title="Delete">
              <IconButton onClick={onDelete}>
                <Delete sx={iconSxHoverError} />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
        sx: {
          paddingY: 1.5,
        },
      }}
      card={{ sx: { m: 2 } }}
    />
  );
};

import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { DATA_HUB_SERVICE_URL } from 'app/shared/api/constants';
import { axiosBaseQuery } from 'app/shared/api/microservices';

const staggeredBaseQuery = retry(axiosBaseQuery({ baseUrl: DATA_HUB_SERVICE_URL }), {
  maxRetries: 0,
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptyDataHubCloudServicesApi = createApi({
  reducerPath: 'dataHubCloudServicesApi',
  baseQuery: staggeredBaseQuery,
  tagTypes: ['USER_PROFILE', 'EXPORT_CSV'],
  endpoints: () => ({}),
});

import React, { Children } from 'react';

import { Stack, StackProps } from '@mui/material';
import { LayoutItemAccordion } from '../../functions/components/LayoutItemAccordion';
import { LayoutItemCard } from '../../functions/components/LayoutItemCard';
import { LayoutItemRaw } from '../../functions/components/LayoutItemRaw';
import { mergeToLayoutItemProps } from '../../functions/converter/mergeToLayoutItemProps';
import { toLayoutItemProps } from '../../functions/converter/toLayoutItemProps';
import { CoreLayoutProps, LayoutProps } from '../../models/models';
import { LayoutItemProps } from '../LayoutItem';

export function StackLayout({
  children,
  renderChild = LayoutItemCard,
  renderProps,
  cardOptions,
  accordionOptions,
  ...containerProps
}: LayoutProps<StackProps>): JSX.Element {
  if (!children) {
    return null;
  }

  return (
    <Stack {...containerProps}>
      {Children.map(children, toLayoutItemProps).map((childProps: LayoutItemProps) =>
        renderChild(mergeToLayoutItemProps(accordionOptions, cardOptions, childProps, renderProps))
      )}
    </Stack>
  );
}

export const RawStackLayout = (props: CoreLayoutProps<StackProps>): JSX.Element => {
  return <StackLayout {...props} renderChild={LayoutItemRaw} />;
};

export const ListLayout = (props: CoreLayoutProps<StackProps>): JSX.Element => {
  return <StackLayout spacing={0} {...props} renderChild={LayoutItemAccordion} />;
};

export const GroupedListLayout = (props: CoreLayoutProps<StackProps>): JSX.Element => {
  const [expanded, setExpanded] = React.useState<React.Key>(undefined);

  function handleChange(key: number) {
    return (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? key : undefined);
    };
  }

  return (
    <StackLayout
      spacing={0}
      {...props}
      renderProps={{ expanded, handleChange, defaultExpanded: false }}
      renderChild={LayoutItemAccordion}
    />
  );
};

import StoreIcon from '@mui/icons-material/Store';
import { Autocomplete, Typography, TextField, ListItemText, ListItemIcon, ListItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { translate } from 'react-jhipster';

interface AccountSectionProps {
  accountOptions: any;
  selectedAccount: any;
  handleChange: (_event: any, newAccount: any) => void;
}

export const AccountSectionPresentation = ({ accountOptions, selectedAccount, handleChange }: AccountSectionProps) => {
  return (
    <Autocomplete
      sx={{ width: '270px', mx: 1 }}
      disableClearable={true}
      disablePortal={true}
      size="small"
      color="secondary"
      autoHighlight={true}
      blurOnSelect={true}
      options={accountOptions}
      value={selectedAccount}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          key={params.id}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <StoreIcon fontSize="medium" color="secondary" sx={{ mr: 1 }} />
                <Typography variant="subtitle1">{translate('account.account')} :</Typography>
              </>
            ),
          }}
          variant="outlined"
          placeholder="Account"
        />
      )}
      renderOption={(props, option) => {
        return option.id === '-1' ? (
          <ListItem {...props} key={option.id}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Create account" />
          </ListItem>
        ) : (
          <ListItem {...props} key={option.id}>
            <ListItemText inset primary={option.label} />
          </ListItem>
        );
      }}
      onChange={handleChange}
    />
  );
};

import React from 'react';

import { useTheme } from '@mui/material/styles';
import ActiveUsersChart from './charts/ActiveUsersChart';
import { useActiveUsersData } from './hooks/useActiveUsersData';

export const ActiveUsers = () => {
  const { data } = useActiveUsersData();

  return <ActiveUsersChart data={data} />;
};

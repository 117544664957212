import { FieldProp } from 'app/modules/form-builder-lib';

export function fieldPropsBuilder(type, required: boolean) {
  switch (type) {
    case 'List':
      return {
        component: 'autocomplete',
        props: {
          multiple: true,
          freeSolo: true,
        },
        validationType: 'array',
        validations: [
          ...(required
            ? [
                ['required', true],
                ['min', 1],
              ]
            : [['nullable', true]]),
        ],
      };
    case 'Number':
      return {
        component: 'text-field',
        props: {
          variant: 'outlined',
        },
        validationType: 'number',
        validations: [...(required ? [['required', true]] : [['nullable', true]])],
      };
    case 'Boolean':
      return {
        component: 'text-field',
        props: {
          variant: 'outlined',
        },
        validationType: 'boolean',
        validations: [...(required ? [['required', true]] : [['nullable', true]])],
      };
    case 'String':
      return {
        component: 'text-field',
        props: {
          variant: 'outlined',
        },
        validationType: 'string',
        validations: [...(required ? [['required', true]] : [['nullable', true]])],
      };
    default:
      break;
  }
}

export const formAttributes: Record<string, { priority: number; fieldProps?: Partial<FieldProp> }> = {
  'clickstream.domains': { priority: 999 },
  'clickstream.consent.cookie.name': { priority: 9 },
  'clickstream.pageType.rules': { priority: 9 },
  'greensight.google.analytics.id': { priority: 999 },
};

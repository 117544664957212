import { Button, Typography } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { sitemap } from 'app/shared/util/url-utils';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { StepProps } from '../../../components/stepper/WizardStepper';

export const steps: StepProps[] = [
  {
    label: 'Clickstream configuration',
    actions(index, { methods: { isStepOptional }, buttons }) {
      return (
        <Button color="primary" variant="contained" component={Link} to={sitemap['web-analytics'].settings}>
          Settings
        </Button>
      );
    },
    children: 'To collect the clickstream, a server endpoint must be configured. Please provide your settings using the dedicated form',
  },
  {
    label: 'Tag manager quick setup',
    optional: <Typography variant="caption">Optional</Typography>,
    actions(index, { methods: { isStepOptional }, buttons }) {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            // LinkComponent={Link}
            // href={`data:text/json;chatset=utf-8,${encodeURIComponent(innerText)}`}
            // download="tag-manager.json"
          >
            <Translate contentKey="web-analytics.export.download">Download</Translate>
          </Button>
          {/* {index > 0 && buttons.BackButton({ step: index })}
        {isStepOptional(index) && buttons.SkipButton()}
        {buttons.NextButton({ step: index })} */}
        </>
      );
    },
    children: (
      <BasicCardLayout
        variant="div"
        card={{ sx: { m: 5 } }}
        content={{ sx: { display: 'flex', flexDirection: 'row', alignItems: 'center' } }}
      >
        <Typography variant="h5" ml={1}>
          Step 2 content
        </Typography>
      </BasicCardLayout>
    ),
  },
  // {
  //   label: 'Step 3',
  //   children: (
  //     <BasicCardLayout
  //       variant="div"
  //       card={{ sx: { m: 5 } }}
  //       content={{ sx: { display: 'flex', flexDirection: 'row', alignItems: 'center' } }}
  //     >
  //       <Typography variant="h5" ml={1}>
  //         Step 3 content
  //       </Typography>
  //     </BasicCardLayout>
  //   ),
  // },
  // {
  //   label: 'Status',
  //   children: (
  //     <BasicCardLayout
  //       variant="div"
  //       card={{ sx: { m: 5 } }}
  //       content={{ sx: { display: 'flex', flexDirection: 'row', alignItems: 'center' } }}
  //     >
  //       <Avatar sx={{ backgroundColor: '#e3f7e0' }}>
  //         <CheckCircleOutline color="success" />
  //       </Avatar>
  //       <Typography variant="h5" ml={1}>
  //         All steps completed - you&apos;re finished
  //       </Typography>
  //     </BasicCardLayout>
  //   ),
  //   sx: { display: 'none' },
  // },
];

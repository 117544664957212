import React from 'react';

import { buildEChartOptions } from '../utils/EChartOptionsBuilder';
import { useTheme } from '@mui/material/styles';
import { WithData } from 'app/modules/loading-lib/WithData';
import ReactECharts from 'echarts-for-react';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

const ActionsCountChartByType = ({ data, actions, isFetching }) => {
  const theme = useTheme();
  return (
    <ReactECharts
      style={{ height: '100%', width: '100%' }}
      option={buildEChartOptions({
        title: { text: 'Actions By Type', textStyle: { color: theme.palette.text.primary } },
        legend: {
          data: ['Actions', 'Actions From Recommendations'],
        },
        dataset: [
          {
            dimensions: ['key', 'value'],
            source: [
              {
                key: 'Demonstration of Interest',
                value: actions.withoutRecoId.total.demonstrationOfInterest ?? [],
              },
              {
                key: 'Conversion',
                value: actions.withoutRecoId.total.conversion ?? [],
              },
              {
                key: 'Others',
                value: actions.withoutRecoId.total.other ?? [],
              },
            ],
          },
          {
            dimensions: ['key', 'value'],
            source: [
              {
                key: 'Demonstration of Interest',
                value: actions.withRecoId.total.demonstrationOfInterest ?? [],
              },
              {
                key: 'Conversion',
                value: actions.withRecoId.total.conversion ?? [],
              },
              {
                key: 'Others',
                value: actions.withRecoId.total.other ?? [],
              },
            ],
          },
        ],
        yAxis: {
          type: 'category',
          axisLabel: { interval: 0 },
        },
        xAxis: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        series: [
          {
            name: 'Actions',
            datasetIndex: 0,
            type: 'bar',
            label: {
              show: true,
              position: 'inside',
            },
            stack: 'action',
          },
          {
            name: 'Actions From Recommendations',
            datasetIndex: 1,
            type: 'bar',
            label: {
              show: true,
              position: 'inside',
            },
            stack: 'action',
          },
        ],
      })}
    />
  );
};

export default WithLoadingHOC(WithData(ActionsCountChartByType));

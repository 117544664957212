import React from 'react';

import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { SessionRowBody } from './SessionRowBody';

export const SessionRow = ({ sessions, userEvents }) => {
  return (
    <Table sx={{ tableLayout: 'fixed' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              width: '25%',
            }}
          >
            First visited page
          </TableCell>
          <TableCell>Duration</TableCell>
          <TableCell>Events</TableCell>
          <TableCell>Page Views</TableCell>
          <TableCell
            sx={{
              width: '25%',
            }}
          >
            Last visited page
          </TableCell>
          <TableCell>ID</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {sessions.map((session, index) => (
          <SessionRowBody key={index} session={session} userEvents={userEvents} />
        ))}
      </TableBody>
    </Table>
  );
};

import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale from './locale';
import authentication from './authentication';
import applicationProfile from './application-profile';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from './user-management';
import userExploration from './user-exploration/index';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

import userProfile from './user-profile';
import timeRange from './timeRange';
import billingAccount from './billing-account/billing-account';
import subscriptionBundles from './subscription-bundles/index';
import subscriptionSettings from './subscription-settings/index';
import invoices from './invoices/index';
import members from './account-profiles/members/index';
import users from './users/index';
import visitorProfile from './visitor-profile/index';
import paymentMethods from './payment-methods';
import recommendations from './recommendations';
import accountProfile from './account-profiles';
import catalog from './catalog';
import subscription from './subscription';
import webAnalytics from './web-analytics';
import appState from './appState';
import mainLayouts from './main-layouts';
import views from './views';

const staticReducers = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  userProfile,
  billingAccount,
  accountProfile,
  subscriptionBundles,
  subscriptionSettings,
  invoices,
  paymentMethods,
  members,
  users,
  catalog,
  subscription,
  userExploration,
  visitorProfile,
  timeRange,
  recommendations,
  webAnalyticsOverview: webAnalytics,
  appState,
  mainLayouts,
  views,
};

export default staticReducers;

import React from 'react';

import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useAppSelector } from 'app/config/store';
import plugin from 'dayjs/plugin/duration';
import { translate } from 'react-jhipster';
import { Tooltip } from '@mui/material';

interface RefreshRateProps {
  list: plugin.Duration[];
  isLive: any;
  refresh: any;
  refreshRate: any;
  setRefreshRate: any;
  setRefresh: any;
  disabled?: any;
}

export const RefreshRate = ({ list, isLive, refresh, refreshRate, setRefreshRate, setRefresh, disabled = false }: RefreshRateProps) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const hasRefreshOptions = list && list.length > 0;

  return (
    <Box display="flex" alignSelf="stretch">
      <Tooltip title={translate('web-analytics.refresh', null, 'Refresh')}>
        <Box display="flex">
          <Button
            disableRipple
            sx={
              hasRefreshOptions && {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
              }
            }
            disabled={isLive || disabled}
            variant="outlined"
            onClick={event => {
              setRefresh(!refresh);
            }}
            size="small"
          >
            <SyncIcon fontSize="small" />
          </Button>
        </Box>
      </Tooltip>
      {hasRefreshOptions && (
        <Tooltip title={translate('web-analytics.select-refresh-rate', null, 'Select Refresh Rate')} PopperProps={{ sx: { zIndex: 0 } }}>
          <Box display="flex">
            <Button
              disableRipple
              sx={{
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                '& .MuiOutlinedInput-root': {
                  background: 'none !important',
                },
              }}
              disabled={isLive || disabled}
              variant="outlined"
              onClick={event => {
                setOpen(!open);
                if (!open) setAnchorEl(event.currentTarget);
                else setAnchorEl(null);
              }}
              color="primary"
              size="small"
            >
              <Select
                disabled={isLive || disabled}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-input': {
                    p: 0,
                    minWidth: 5,
                  },
                  '& .MuiSelect-select': {
                    pr: 2,
                    color: theme.palette.primary.main,
                    fontSize: '0.825rem',
                    fontWeight: '500',
                  },
                  '& .MuiList-root .MuiMenu-list': {
                    p: `0 !important`,
                  },
                }}
                open={open}
                MenuProps={{
                  anchorEl,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                }}
                SelectDisplayProps={{}}
                onChange={(event: SelectChangeEvent) => {
                  setRefreshRate(event.target.value);
                }}
                size="small"
                label="Rate"
                value={refreshRate}
                color="primary"
              >
                <MenuItem value={0}>
                  <em>{translate(`web-analytics.DatePicker.Off`, null, 'Off')}</em>
                </MenuItem>
                {list.map(frequency => {
                  return (
                    <MenuItem key={frequency.asMilliseconds()} value={frequency.asMilliseconds()}>
                      {frequency.locale(currentLocale).humanize(true)}
                    </MenuItem>
                  );
                })}
              </Select>
            </Button>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

import { DataGrid, GridColDef, GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';
import {
  addFlex,
  arraycChipString as arrayChipString,
  chipString,
  translateHeaders,
} from 'app/components/data-grid/columns/column-helpers';
import { sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import { commonToolbars, toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import { Select, MenuItem, SelectChangeEvent, OutlinedInput, Chip } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';

function SelectEditInputCell(props: GridRenderCellParams) {
  // WIP
  const { id, value, field, row } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select
      input={<OutlinedInput id="select-multiple-chip" label="Add role" />}
      multiple
      value={value}
      onChange={e => {
        handleChange(e);
      }}
      size="small"
      color="primary"
      sx={{ height: 1, '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
      renderValue={selected => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map(chip => (
            <Chip key={chip} label={chip} />
          ))}
        </Box>
      )}
    >
      {['GUEST', 'ADMIN', 'CONTRIBUTOR', 'HURENCE_ADMIN'].map((option, index) => {
        return (
          <MenuItem disabled={value.includes(option)} key={index} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
}

const renderSelectEditInputCell: GridColDef['renderCell'] = params => {
  return <SelectEditInputCell {...params} />;
};

const columns: GridColDef[] = [
  {
    field: 'name',
    headerAlign: 'left',
    align: 'left',
    valueGetter: params => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  {
    field: 'email',
  },
  {
    field: 'status',
    ...chipString(params => {
      return { variant: 'outlined', size: 'small', color: params.value === 'ACTIVE' ? 'success' : 'error' };
    }),
  },
  {
    field: 'roles',
    editable: true,
    renderEditCell: renderSelectEditInputCell,
    ...arrayChipString(),
    flex: 2,
  },
];

export const MembersDataGrid = ({ membersList }) => {
  return (
    <DataGrid
      rows={membersList || []}
      columns={columns.map(addFlex).map(translateHeaders('account.member-table'))}
      {...toolbarProps(commonToolbars.readOnlyLeft)}
      {...sizedPagination()}
      density="compact"
      getRowId={row => row.auditInfo.createdDate}
      disableRowSelectionOnClick
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
      }}
    />
  );
};

import { useEffect } from 'react';
import { useAggregateImpressionsData } from './useAggregateImpressionsData';
import {
  getImpressionsTimeseries,
  getImpressionsTimeseriesByType,
  getImpressionsTotalByType,
  getTotalImpressions,
} from '../../utils/impressions-data-transformation';

export const useImpressionsKpis = (timerange, requestParams, accountProfile) => {
  const { refresh } = timerange;

  const {
    currentData: impressionsCount,
    refetch: refetchImpressionsCount,
    isUninitialized: isImpressionsCountUninitialized,
    isFetching: isImpressionsCountFetching,
  } = useAggregateImpressionsData(timerange, requestParams, accountProfile);

  const {
    currentData: aggregateImpressions,
    refetch: refetchAgggregateImpressions,
    isUninitialized: isAgggregateImpressionsUninitialized,
    isFetching: isAgggregateImpressionsFetching,
  } = useAggregateImpressionsData(timerange, requestParams, accountProfile, { aggregateBy: ['recommender_type'] });

  useEffect(() => {
    if (!isAgggregateImpressionsUninitialized) refetchAgggregateImpressions();
    if (!isImpressionsCountUninitialized) refetchImpressionsCount();
  }, [refresh]);

  return {
    total: getTotalImpressions(impressionsCount),
    timeseries: getImpressionsTimeseries(impressionsCount),
    byRecommenderType: {
      total: getImpressionsTotalByType(aggregateImpressions),
      timeseries: getImpressionsTimeseriesByType(aggregateImpressions),
    },
    isFetching: isImpressionsCountFetching || isAgggregateImpressionsFetching,
  };
};

import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { lastWeek } from '../../../utils/timeParams';

const selectedTimeParams$ = new BehaviorSubject(lastWeek);

const setSelectedTimeParams = (params: any) => {
  selectedTimeParams$.next(params);
};

export const useSelectedTimeParams = () => {
  const [timeParams, setTimeParams] = useState(selectedTimeParams$.value);
  useEffect(() => {
    const subscription = selectedTimeParams$.subscribe(value => setTimeParams(value));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { timeParams, setSelectedTimeParams };
};

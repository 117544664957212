import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IUsersByCity } from 'app/shared/model/user-exploration/users-by-city.model';
import { serializeAxiosError } from '../reducer.utils';

const dataExplorationUrl = DATA_HUB_API_URL + '/exploration/data';

const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  users_by_city: null as unknown as IUsersByCity,
};

export type UsersByCityState = Readonly<typeof initialState>;

export const getUsersByCity = createAsyncThunk(
  'dataHub/FETCH_USERS_BY_CITY',
  async ({ filter, from, to }: any) => {
    return microserviceHttpClient(
      `${dataExplorationUrl}/kpisPerBucket?from=${from}&to=${to}&bucketingDimensions=CITY_NAME&kpis=USER_COUNT`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    );
  },
  { serializeError: serializeAxiosError }
);

export const UsersByCitySlice = createSlice({
  name: 'usersByCity',
  initialState: initialState as UsersByCityState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsersByCity.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getUsersByCity.fulfilled, (state, action) => {
        state.loading = false;
        state.users_by_city = action.payload.data.data.CITY_NAME.map(element => {
          return { cityName: element.name, users: element.buckets['USER_COUNT']['count'] } as IUsersByCity;
        });
      })
      .addCase(getUsersByCity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.users_by_city = null as unknown as IUsersByCity;
      });
  },
});

export default UsersByCitySlice.reducer;

import { IUsersByCountry } from 'app/shared/model/user-exploration/users-by-country.model';
import { useEffect } from 'react';
import { useGetKpisPerKpiInTimeRangeUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { useAppSelector } from 'app/config/store';
import { selectAllUserExplorationFilters, getUserExplorationTimerange } from 'app/shared/reducers/views/user-exploration';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const useUsersMap = (): { usersMapData: IUsersByCountry[]; isFetching: boolean; isError: boolean; error: unknown } => {
  const filter = useAppSelector(selectAllUserExplorationFilters);
  const timerange = useAppSelector(getUserExplorationTimerange);
  const {
    users_by_country = null,
    isFetching,
    isUninitialized: isKpisPerKpiUninitialized,
    isError,
    error,
    refetch,
  } = useGetKpisPerKpiInTimeRangeUsingPostQuery(
    timerange
      ? {
          requestFilters: {
            filterEntryList: filter,
          },
          from: timerange?.fromDate,
          to: timerange?.toDate,
          bucketingDimensions: ['COUNTRY_CODE'].join() as any,
          kpis: ['USER_COUNT'].join(),
        }
      : skipToken,
    {
      selectFromResult(res) {
        return {
          ...res,
          users_by_country: res.currentData?.data['COUNTRY_CODE'].map(element => {
            return { countryCode: element.countryCode, bucket: element };
          }),
        };
      },
      pollingInterval: timerange?.refreshRate,
    }
  );

  useEffect(() => {
    if (!isKpisPerKpiUninitialized) refetch();
  }, [timerange?.refresh]);

  return {
    usersMapData: users_by_country,
    isFetching,
    isError,
    error,
  };
};

import { Translate } from '@mui/icons-material';
import { IconButton, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Box } from '@mui/system';
import { languages, locales } from 'app/config/translation';
import React from 'react';
import { translate } from 'react-jhipster';

interface LanguageMenuProps {
  theme: any;
  handleClick: any;
  anchorEl: any;
  handleClose: any;
  handleLocaleChange: any;
  currentLocale: any;
}

export const LanguageMenuPresentation = ({
  theme,
  handleClick,
  anchorEl,
  handleClose,
  handleLocaleChange,
  currentLocale,
}: LanguageMenuProps) => {
  // choose whathere use Menu or Popper to display card
  // maybe redo this component

  return (
    <Box>
      {Object.keys(languages).length > 1 ? (
        <>
          <Tooltip title={translate('profile-section.choose-language')}>
            <IconButton color="neutral" onClick={handleClick}>
              <Translate fontSize="small" />
            </IconButton>
          </Tooltip>
          <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            {locales.map(locale => (
              <ListItemButton selected={locale === currentLocale} key={locale} onClick={() => handleLocaleChange(locale)}>
                <ListItemText
                  primary={
                    <Typography variant="body2" sx={{ ...(locale === currentLocale && { color: 'primary.main' }) }}>
                      {`${languages[locale].name}`}
                    </Typography>
                  }
                />
              </ListItemButton>

              // <MenuItem
              //   key={locale}
              //   onClick={() => handleLocaleChange(locale)}
              //   selected={currentLocale === locale}
              // >
              //   {languages[locale].name}
              //   {/* <ListItemText primary={`${languages[locale].name}`} /> */}
              // </MenuItem>
            ))}
          </StyledMenu>
        </>
      ) : null}
    </Box>
  );
};

const StyledMenu = (props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{
      marginTop: '10px',
      minWidth: '140px',
    }}
    {...props}
  />
);

// const StyledMenuItem = withStyles(theme => ({
//   root: {
//     '&:focus': {
//       backgroundColor: theme.palette.primary.main,
//       '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//         color: theme.palette.common.white,
//       },
//     },
//   },
// }))(MenuItem);

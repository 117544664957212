import React from 'react';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useUserStates } from 'app/shared/layout/restricted/hooks/selectors/useUserStates';
import { AccountSection } from '../components/header/account-section';
import { ProfileSection } from '../components/header/profile-section';

export const UserProfileSection = () => {
  const { userProfile } = useAppAccountStates();
  const { accountOptions, selectedAccount } = useUserStates();
  return (
    <>
      <AccountSection email={userProfile?.email} accountOptions={accountOptions} selectedAccount={selectedAccount} />
      <ProfileSection />
    </>
  );
};

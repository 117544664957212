import React from 'react';
import { useTheme } from '@mui/material/styles';
import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { durationFormats, durationToString } from 'app/shared/util/date-utils';
import 'echarts/i18n/langFR';
import { useAppSelector } from 'app/config/store';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';
import { chartFormatter } from 'app/shared/util/chartFormatter-utils';
import { KPIS } from 'app/shared/util/reducer-utils';
import { ITimerange } from 'app/components/filter-wrapper/models/timerange';
import { parseEsDateString } from 'app/components/filter-wrapper/useTimeRangeFilter';

interface LineChartProps {
  timerange: ITimerange;
  data: any;
  kpi: any;
  isFetching?: boolean;
  isError: boolean;
  error: any;
}

export const KpiLineChart = ({ timerange, data, kpi, isFetching, error = null, isError = false }: LineChartProps) => {
  const theme = useTheme();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const config: EChartsOption = {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    series: [
      {
        type: 'line',
        data,
        areaStyle: {},
        smooth: 0.3,
        name: KPIS[kpi].i18n,
      },
    ],
    xAxis: {
      type: 'time',
      min: parseEsDateString(timerange?.fromDate)?.toDate(),
      max: parseEsDateString(timerange?.toDate)?.toDate(),
      axisLine: {
        lineStyle: {
          color: theme.palette.divider,
        },
      },
      axisLabel: {
        formatter: {
          year: '{yearStyle|{yyyy}}',
          day: '{d} {MMMM}',
          month: '{monthStyle|{MMM}}',
        },
        rich: {
          monthStyle: {
            fontWeight: 'bold',
          },
          yearStyle: {
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
        fontSize: 12,
      },
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12px',
        formatter(value) {
          switch (kpi) {
            case 'AVG_SESSION_DURATION': {
              return durationToString(value, durationFormats.TRUNC_SINGLE_DENSE_TIME);
            }
            case 'BOUNCE_RATE': {
              return `${value} %`;
            }
            default: {
              return value;
            }
          }
        },
      },
    },
    tooltip: {
      borderRadius: 20,
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      valueFormatter(value) {
        return chartFormatter(KPIS[kpi].formatter, value);
      },
    },
    legend: {},
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar', 'stack'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    grid: {
      left: 40,
      right: 40,
      top: 30,
      bottom: 30,
    },
    textStyle: {
      color: theme.palette.text.primary,
    },
  } as EChartsOption;

  const lineChart = () => <ReactECharts style={{ height: '100%', width: '100%' }} option={config} opts={{ locale: currentLocale }} />;

  return WithLoadingHOC(lineChart)({});
};

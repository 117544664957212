import React from 'react';

import { Typography } from '@mui/material';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { translate } from 'react-jhipster';
import PieChart from '.';
import { pieChartList } from './list';

export const PieGrids = ({ fromDate, toDate }) => {
  return (
    <GridLayout>
      {pieChartList.map((chart, index) => (
        <LayoutItem
          cardOptions={{
            header: {
              title: (
                <Typography
                  variant="h4"
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  component="span"
                  title={chart.title}
                  pb={1}
                >
                  {translate(`greensights.pie-chart.${chart.title}`)}
                </Typography>
              ),
              sx: {
                '.MuiCardHeader-content': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 1 },
                p: 0,
              },
            },
            content: { sx: { height: '165px', py: 0, '&:last-child': { py: 0 } } },
          }}
          gridItemProps={{ xs: 6, lg: 2.4, sm: 4 }}
          key={`pieChart${index}`}
        >
          <PieChart chart={chart} fromDate={fromDate} toDate={toDate} />
        </LayoutItem>
      ))}
    </GridLayout>
  );
};

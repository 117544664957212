import React from 'react';

import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { Translate } from 'react-jhipster';
import { AccessTokenForm } from '../../account-forms/AccessTokenForm';
import { AccessTokenDataGrid } from '../data-grids/access-token/AccessTokenDataGrid';
import { Typography } from '@mui/material';
import { NewAccessToken } from '../NewAccessToken';
import { useTheme } from '@mui/material/styles';
import { useCreateApiTokenUsingPostMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { NewAccessTokenError } from '../NewAccessTokenError';

export const SecurityTab = () => {
  const theme = useTheme();
  return (
    <RawStackLayout sx={{ [theme.breakpoints.up('md')]: { width: '50%' }, marginX: 'auto' }}>
      <NewAccessTokenError />
      <NewAccessToken />

      <BasicCardLayout
        header={{
          title: (
            <Typography variant="h4">
              <Translate contentKey="account.access-token.add-access-token">Add an account access token</Translate>
            </Typography>
          ),
        }}
      >
        <Translate contentKey="account.access-token.enter-name">
          Enter the name of your application, and we will return a unique project access token.
        </Translate>
        <AccessTokenForm />
      </BasicCardLayout>

      <BasicCardLayout
        header={{
          title: (
            <Typography variant="h4">
              <Translate contentKey="account.access-token.active-tokens">Active Account Access Tokens</Translate>
            </Typography>
          ),
        }}
      >
        <AccessTokenDataGrid />
      </BasicCardLayout>
    </RawStackLayout>
  );
};

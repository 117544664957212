import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { BrandChrome, BrandFirefox, BrandSafari } from 'tabler-icons-react';

export const SessionBrowser = ({ browser }) => {
  let icon;

  if (String(browser) === 'Chrome') icon = <BrandChrome size={26} strokeWidth={1.5} color={'black'} />;
  else if (String(browser) === 'Safari') icon = <BrandSafari size={26} strokeWidth={1.5} color={'black'} />;
  else if (String(browser) === 'Chrome Mobile') icon = <BrandChrome size={26} strokeWidth={1.5} color={'black'} />;
  else if (String(browser) === 'Firefox') icon = <BrandFirefox size={26} strokeWidth={1.5} color={'black'} />;

  return (
    <Box display="flex" alignItems="center" justifyContent="end">
      {icon}
      <Typography>{browser}</Typography>
    </Box>
  );
};

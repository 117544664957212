import React from 'react';

import { useUserCohort } from './hooks/useUserCohort';
import CohortChart from './chart/CohortChart';
import { useCohortUtils } from './hooks/useCohortUtils';
import { useAppSelector } from 'app/config/store';
import { getUserExplorationCohortState } from 'app/shared/reducers/views/user-exploration';

const UserCohort = () => {
  const cohortState = useAppSelector(getUserExplorationCohortState);
  const { cohort_analysis, isFetching, isError, error } = useUserCohort();
  const { xAxisData, yAxisData, values, maxValue, onEvents } = useCohortUtils(cohort_analysis || []);

  return (
    <CohortChart
      xAxisData={xAxisData}
      yAxisData={yAxisData}
      values={values}
      cohortSecondaryState={cohortState}
      maxValues={maxValue}
      onEvents={onEvents}
      isFetching={isFetching}
      isError={isError}
      error={error}
    />
  );
};

export default React.memo(UserCohort);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { serializeAxiosError } from '../reducer.utils';

const userExplorationUrl = DATA_HUB_API_URL + '/exploration/user';

const initialState = {
  userOverviewLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  user_overview: null,
};

export type UserOverviewState = Readonly<typeof initialState>;

export const getUserOverview = createAsyncThunk(
  'dataHub/FETCH_USER_OVERVIEW',
  async ({ filter, timeStep, nbTimeStepsFirstVisit, nbTimeStepsMatrixCell }: any) => {
    return microserviceHttpClient(
      `${userExplorationUrl}/cohortAnalysis/users?timeStep=${timeStep}&nbTimeStepsFirstVisit=${nbTimeStepsFirstVisit}&nbTimeStepsMatrixCell=${nbTimeStepsMatrixCell}`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    );
  },
  { serializeError: serializeAxiosError }
);

export const UserOverveiwSlice = createSlice({
  name: 'userOverveiw',
  initialState: initialState as UserOverviewState,
  reducers: {
    resetUserOverveiw(state) {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserOverview.pending, state => {
        state.userOverviewLoading = true;
        state.errorMessage = null;
      })
      .addCase(getUserOverview.fulfilled, (state, action) => {
        state.userOverviewLoading = false;
        state.user_overview = action.payload.data;
      })
      .addCase(getUserOverview.rejected, (state, action) => {
        state.userOverviewLoading = false;
        state.errorMessage = action.error.message;
        state.user_overview = null;
      });
  },
});

export const { resetUserOverveiw } = UserOverveiwSlice.actions;

export default UserOverveiwSlice.reducer;

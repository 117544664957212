import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useState } from 'react';
import { ITimerange } from './models/timerange';

dayjs.extend(duration);

const patternNow = /^now(-(\d+)([yMwdhm]))?(\/([yMwdhm]))?$/;
const patternDate = /^((\d{4}).(\d{2}).(\d{2}))\|\|(-(\d+)([yMwdhm]))?(\/([yMwdhm]))?$/;
const patternOffset = /.*([-+])(\d+)([yMwdhm])(\/([yMwdhm]))?$/;
const patternTruncate = /.*(\/([yMwdhm]))$/;

export const timeStepList = [
  { name: 'Auto', value: 'Auto' },
  { name: 'minute', value: 'PT1M' },
  { name: 'hour', value: 'PT1H' },
  { name: 'day', value: 'P1D' },
];

function parseDateString(time: string) {
  const matcherNow = patternNow.exec(time);
  const matcherDate = patternDate.exec(time);
  if (matcherNow && matcherNow.length > 0) {
    return dayjs();
  } else if (matcherDate && matcherDate.length > 0) {
    return dayjs(matcherDate[1]);
  }
  return undefined;
}

export function parseEsDateString(esTime: string): dayjs.Dayjs {
  let result: dayjs.Dayjs = undefined;
  if (esTime && esTime.replace) {
    const time = esTime.replace(/%7C/g, '|');
    result = parseDateString(time);
    const matcherOffset = patternOffset.exec(time);
    const matcherTruncate = patternTruncate.exec(time);
    if (result && matcherOffset && matcherOffset.length > 0) {
      if (matcherOffset[1] === '-') {
        result = result.subtract(dayjs.duration(Number(matcherOffset[2]), matcherOffset[3] as duration.DurationUnitType));
      } else {
        result = result.add(dayjs.duration(Number(matcherOffset[2]), matcherOffset[3] as duration.DurationUnitType));
      }
    }
    if (result && matcherTruncate && matcherTruncate.length > 0) {
      result = result.startOf(matcherTruncate[2] as dayjs.OpUnitType);
    }
  }
  return result;
}

export const stepOptions: plugin.Duration[] = [dayjs.duration(1, 'minute'), dayjs.duration(1, 'hour'), dayjs.duration(1, 'day')];

export function stepCalculator(fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) {
  const window = dayjs.duration(toDate?.diff(fromDate));
  const stepRange = stepOptions.filter((step, index) => index === 0 || step.asMilliseconds() < window.asMilliseconds()).pop();
  return stepRange.toISOString();
}

const defaultValues = {
  chosenRange: undefined,
  fromDate: undefined,
  toDate: 'now',
  refreshRate: 0,
  refresh: false,
  step: 'Auto',
  live: undefined,
};

const defaultLiveValues = {
  fromDate: 'now-1h',
  toDate: 'now',
  refreshRate: 15000,
  step: 'PT1M',
};

export function useTimeRange(initValues?, liveValues?) {
  const mergedValues = { ...defaultValues, ...initValues };
  const mergedLiveValues = { ...defaultLiveValues, ...liveValues };

  const [chosenRange, setChosenRange] = useState<string | Date[]>(mergedValues.chosenRange);
  const [fromDate, setFromDate] = useState(mergedValues.fromDate);
  const [toDate, setToDate] = useState(mergedValues.toDate);
  const [step, setStep] = useState(mergedValues.step);
  const [refreshRate, setRefreshRate] = useState(mergedValues.refreshRate);
  const [refresh, setRefresh] = useState(mergedValues.refresh);
  const [live, setLive] = useState(mergedValues.live);

  function setValidToDate(value) {
    if (value) {
      setToDate(value);
    } else {
      setToDate('now');
    }
  }

  function setValidStep(value) {
    if (value) {
      setStep(value);
    } else {
      setStep('Auto');
    }
  }

  function getStep() {
    if (step && step === 'Auto') {
      return stepCalculator(parseEsDateString(fromDate), parseEsDateString(toDate));
    }
    return step;
  }

  function isLive() {
    return live !== undefined;
  }

  function setLiveOn() {
    setLive({ fromDate, toDate, refreshRate, step });
    setFromDate(mergedLiveValues.fromDate);
    setToDate(mergedLiveValues.toDate);
    setRefreshRate(mergedLiveValues.refreshRate);
    setStep(mergedLiveValues.step);
  }

  function setLiveOff() {
    setFromDate(live.fromDate);
    setToDate(live.toDate);
    setRefreshRate(live.refreshRate);
    setStep(live.step);
    setLive(undefined);
  }

  function liveHandler(event) {
    if (isLive()) {
      setLiveOff();
    } else {
      setLiveOn();
    }
  }

  return {
    chosenRange,
    setChosenRange,
    fromDate,
    setFromDate,
    toDate,
    setToDate: setValidToDate,
    refresh,
    setRefresh,
    refreshRate,
    setRefreshRate,
    step,
    setStep: setValidStep,
    getStep,
    setLiveOn,
    setLiveOff,
    isLive,
    liveHandler,
  } as ITimerange;
}

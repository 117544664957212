import React from 'react';

import { ListItemButton, Typography, Tooltip, ListItemIcon, useMediaQuery } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Translate } from 'react-jhipster';
import { EnrolledProducts } from './components/EnrolledProducts';
import { AvailableProducts } from './components/AvailableProducts';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { MiniProductsPanel } from './mini/MiniProductsPanel';
import { Box } from '@mui/system';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { setOpen } from 'app/shared/reducers/main-layouts/right-sidebar';
import { Theme } from '@mui/material/styles';
import { useProductsStates } from 'app/shared/layout/restricted/hooks/selectors/useProductsStates';
import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';

export const ProductsPanel = () => {
  const dispatch = useAppDispatch();
  const { uniqueActiveProducts, otherProducts } = useProductsStates();
  const { isSubscriptionListError } = useSubscriptionsStates();

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { open } = useAppSelector(state => state.mainLayouts.rightSidebar);

  if (isSubscriptionListError) return null;

  if (open && matches)
    return (
      <>
        <Box display="flex" flexDirection="column" flex={1} sx={{ overflowY: 'auto' }}>
          <BasicCardLayout
            variant="div"
            header={{
              title: (
                <Typography variant="h3" textAlign="center" m={2}>
                  <Translate contentKey="products.presentation-short">Explore ScoringJoe products</Translate>
                </Typography>
              ),
            }}
            card={{ sx: { display: 'flex', flexDirection: 'column' } }}
            content={{ sx: { p: 0, overflowY: 'scroll' } }}
          >
            {uniqueActiveProducts.length > 0 && <EnrolledProducts uniqueActiveProducts={uniqueActiveProducts} />}
            {otherProducts.length > 0 && <AvailableProducts otherProducts={otherProducts} />}
          </BasicCardLayout>
          <Box flexGrow={1} />
        </Box>
        <Box>
          <ListItemButton
            onClick={() => {
              dispatch(setOpen(false));
            }}
            sx={{ px: 1, justifyContent: 'center' }}
          >
            <Tooltip title={<Translate contentKey="module-factory.collapse-sidebar">Collapse sidebar</Translate>} placement="right" arrow>
              <ListItemIcon>
                <ChevronRight />
              </ListItemIcon>
            </Tooltip>
          </ListItemButton>
        </Box>
      </>
    );

  return (
    <>
      <Box display="flex" flexDirection="column" flex={1} sx={{ overflowY: 'auto' }}>
        <MiniProductsPanel uniqueActiveProducts={uniqueActiveProducts} otherProducts={otherProducts} />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <ListItemButton
          onClick={() => {
            dispatch(setOpen(true));
          }}
          sx={{ px: 1, justifyContent: 'center' }}
        >
          <Tooltip title={<Translate contentKey="module-factory.expand-sidebar">Expand sidebar</Translate>} placement="right" arrow>
            <ListItemIcon>
              <ChevronLeft />
            </ListItemIcon>
          </Tooltip>
        </ListItemButton>
      </Box>
    </>
  );
};

import React from 'react';

import { GroupedListLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { ClickStreamConfig } from '../accordion-items/ClickStreamConfig';

export const WebAnalyticsAccordionWithNotReacdyStatus = () => {
  return (
    <GroupedListLayout>
      <ClickStreamConfig />
    </GroupedListLayout>
  );
};

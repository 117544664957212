import React from 'react';
import { ToggleButtonProps } from '@mui/material';
import { ControlledToggleButton } from 'app/components/buttons/ControlledToggleButton';
import { TOP_N_COUNT } from '../utils/impressions-data-transformation';

export const RecommendationsTopNToggleButton = ({ topNToggle, setTopNToggle }) => {
  const buttons: ToggleButtonProps[] = [
    {
      value: false,
      children: 'All',
    },
    {
      value: true,
      children: `Top ${TOP_N_COUNT}`,
    },
  ];
  return (
    <ControlledToggleButton
      buttons={buttons}
      value={topNToggle}
      onChange={(_event, value) => value !== null && setTopNToggle(value)}
      size="small"
      color="primary"
      sx={{ m: 1, height: 32 }}
      exclusive
    />
  );
};

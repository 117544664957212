import { Theme } from '@mui/material';

abstract class ThemeBuilder {
  customization;

  constructor(customization) {
    this.customization = customization;
  }

  abstract palette();

  abstract mixins();

  abstract typography();

  abstract components(theme: Theme);

  updateTheme(theme: Theme) {
    return theme;
  }
}

export default ThemeBuilder;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IUsersByCountry } from 'app/shared/model/user-exploration/users-by-country.model';
import { serializeAxiosError } from '../reducer.utils';

const dataExplorationUrl = DATA_HUB_API_URL + '/exploration/data';

const initialState = {
  usersByCountryLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  users_by_country: null as unknown as Array<IUsersByCountry>,
};

export type UsersByCountryState = Readonly<typeof initialState>;

export const getUsersByCountry = createAsyncThunk(
  'dataHub/FETCH_USERS_BY_COUNTRY',
  async ({ filter, from, to }: any) => {
    return microserviceHttpClient(
      `${dataExplorationUrl}/kpisPerBucket?from=${from}&to=${to}&bucketingDimensions=COUNTRY_CODE&kpis=USER_COUNT`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    );
  },
  { serializeError: serializeAxiosError }
);

export const UsersByCountrySlice = createSlice({
  name: 'usersByCountry',
  initialState: initialState as UsersByCountryState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsersByCountry.pending, state => {
        state.usersByCountryLoading = true;
        state.errorMessage = null;
      })
      .addCase(getUsersByCountry.fulfilled, (state, action) => {
        state.usersByCountryLoading = false;
        state.users_by_country = action.payload.data.data.COUNTRY_CODE.map(element => {
          return { countryCode: element.countryCode, bucket: element };
        }) as Array<IUsersByCountry>;
      })
      .addCase(getUsersByCountry.rejected, (state, action) => {
        state.usersByCountryLoading = false;
        state.errorMessage = action.error.message;
        state.users_by_country = null as unknown as Array<IUsersByCountry>;
      });
  },
});

export default UsersByCountrySlice.reducer;

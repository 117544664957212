import React from 'react';

import { Typography } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { getDataExplorationSelectedChart, getDataExplorationTimerange } from 'app/shared/reducers/views/data-exploration';
import { translate } from 'react-jhipster';
import MapChart from './MapChart';
import { useDataExplorationKpisPerKpi } from '../../hooks/useDataExplorationKpisPerKpi';
import LiveMapChart from './LiveMapChart';

export const MapCard = () => {
  const selectedChart = useAppSelector(getDataExplorationSelectedChart);
  const { sessions_by_country, error, isFetching, isError } = useDataExplorationKpisPerKpi();
  const timerange = useAppSelector(getDataExplorationTimerange);

  return (
    <LayoutItem
      gridItemProps={{ xs: 12, lg: 4 }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h4">
              {translate(`open-analytics.mapChart.${selectedChart.name}`)} {translate(`open-analytics.sessionsByCountry.byCountry`)}
            </Typography>
          ),
          sx: {
            py: 1,
            pb: 0,
          },
        },
        content: { sx: { height: '300px', py: 0, '&:last-child': { py: 0 } } },
      }}
    >
      {timerange && timerange.live ? (
        <LiveMapChart data={sessions_by_country} selectedChart={selectedChart} isFetching={isFetching} isError={isError} error={error} />
      ) : (
        <MapChart data={sessions_by_country} selectedChart={selectedChart} isFetching={isFetching} isError={isError} error={error} />
      )}
    </LayoutItem>
  );
};

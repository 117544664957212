import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppSidebar } from '../layout/main-layouts/left-sidebar';
import { sitemap } from '../util/url-utils';

export const AppSidebarRoutes = () => {
  return (
    <Routes>
      <Route index element={null} />
      <Route path={sitemap.login} element={null} />
      <Route path={sitemap.logout} element={null} />
      <Route path={sitemap.recommendationAlgorithms} element={null} />
      <Route path={sitemap.error} element={null} />
      <Route path="*" element={<AppSidebar />} />
    </Routes>
  );
};

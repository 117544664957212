import React from 'react';

import { useSessionsTablePagination } from './hooks/useSessionsTablePagination';
import { useSessionsTableData } from './hooks/useSessionsTableData';
import SessionsTable from './SessionsTable';

export const SessionsTableWrapper = () => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useSessionsTablePagination();
  const { userEvents, userInfos, groupedSessions, isFetching, isError, error } = useSessionsTableData(page, rowsPerPage);
  return (
    <SessionsTable
      groupedSessions={groupedSessions}
      userEvents={userEvents}
      userInfos={userInfos}
      page={page}
      handleChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isFetching={isFetching}
      isError={isError}
      error={error}
    />
  );
};

import { Grid, Typography, Container, Stack } from '@mui/material';
import { Image } from 'mui-image';
import React, { ComponentProps } from 'react';
import { Translate } from 'react-jhipster';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type GridProps = ComponentProps<typeof Grid>;
type ImageProps = ComponentProps<typeof Image>;
type SliderProps = ComponentProps<typeof Slider>;
type TypographyProps = ComponentProps<typeof Typography>;

export const SummarySectionCard = (props: {
  gridProps?: GridProps;
  summaryProps?: GridProps;
  title: {
    translateKey: string;
    interpolate?: any;
    defaultText: string;
    props?: TypographyProps;
  };
  secondary?: JSX.Element;
  infoChip?: JSX.Element;
  summary?: {
    translateKey: string;
    interpolate?: any;
    defaultText: string;
    props?: TypographyProps;
  };
  images?: {
    position: 'before' | 'after';
    props: ImageProps[];
    containerProps?: GridProps;
    sliderProps?: Partial<SliderProps>;
  };
  children?: React.ReactNode;
}) => {
  const { gridProps, summaryProps, title, secondary, infoChip, summary, images, children } = props;

  const addImages = () => {
    return (
      <Grid item {...images.containerProps}>
        {images.props.length > 1 ? (
          <Slider {...images.sliderProps}>
            {images.props.map((imageProps, index) => (
              <Image key={index} {...imageProps} />
            ))}
          </Slider>
        ) : (
          images.props.map((imageProps, index) => <Image key={index} {...imageProps} />)
        )}
      </Grid>
    );
  };

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="space-between" paddingY="10vh" {...gridProps}>
        {/* Left image */}
        {images?.position === 'before' && addImages()}
        {/* Text item */}
        <Grid item alignSelf="center" {...summaryProps}>
          {/* Title and secondary elements */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h3" {...title.props}>
              <Translate contentKey={title.translateKey} interpolate={title.interpolate}>
                {title.defaultText}
              </Translate>
            </Typography>
            {secondary && secondary}
          </Stack>
          {infoChip && infoChip}
          {/* Summary */}
          {summary && (
            <Typography variant="subtitle1" {...summary.props}>
              <Translate contentKey={summary.translateKey} interpolate={summary.interpolate}>
                {summary.defaultText}
              </Translate>
            </Typography>
          )}
          {/* Children */}
          {children}
        </Grid>
        {/* Right image */}
        {images?.position === 'after' && addImages()}
      </Grid>
    </Container>
  );
};

import React from 'react';

import { useTheme } from '@mui/material/styles';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useAppSelector } from 'app/config/store';
import { WithData } from 'app/modules/loading-lib/WithData';

const LineEchart = ({ data, selectedChart }) => {
  const theme = useTheme();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const config: EChartsOption = {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    series: [
      {
        type: 'line',
        data,
        areaStyle: {},
        smooth: 0.3,
      },
    ],
    xAxis: {
      type: 'time',
      axisLine: {
        lineStyle: {
          color: theme.palette.divider,
        },
      },
      axisLabel: {
        formatter: {
          year: '{yearStyle|{yyyy}}',
          day: '{d} {MMMM}',
          month: '{monthStyle|{MMM}}',
        },
        rich: {
          monthStyle: {
            fontWeight: 'bold',
          },
          yearStyle: {
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
        fontSize: 12,
      },
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12px',
      },
    },
    tooltip: {
      borderRadius: 20,
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
    },
    grid: {
      left:
        selectedChart.chart.title === 'transferred-bytes'
          ? '11%'
          : selectedChart.chart.title === 'page-size_bytes' || selectedChart.chart.title === 'avg-page-size_bytes'
          ? '9%'
          : '5%',
      right: '2%',
      top: '3%',
      bottom: '10%',
    },
    textStyle: {
      color: theme.palette.text.primary,
    },
  } as EChartsOption;

  return <ReactECharts style={{ height: '100%', width: '100%' }} option={config} opts={{ locale: currentLocale }} />;
};

export default WithData(LineEchart);

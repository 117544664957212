import React, { useState } from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Button, IconButton, Typography } from '@mui/material';
import { East, LineAxisOutlined, VisibilityOff } from '@mui/icons-material';
import { RouterLink } from 'app/config/modules/react-router-dom';
import { sitemap } from 'app/shared/util/url-utils';
import { Translate } from 'react-jhipster';

export const Dashboards = () => {
  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.analytics-dashboards">Analytics Dashboards</Translate>
            </Typography>
          ),
          avatar: <LineAxisOutlined color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
            transition: 'all 500ms linear',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        actions: (
          <>
            <Button
              size="small"
              variant="outlined"
              component={RouterLink}
              to={`${sitemap['web-analytics'].home}`}
              endIcon={<East />}
              key="overview"
            >
              <Translate contentKey="module-factory.overview">Overview</Translate>
            </Button>
            <Button
              size="small"
              variant="outlined"
              component={RouterLink}
              to={`${sitemap['web-analytics'].audience}`}
              endIcon={<East />}
              key="audience"
            >
              <Translate contentKey="module-factory.audience">Audience</Translate>
            </Button>
            <Button
              size="small"
              variant="outlined"
              component={RouterLink}
              to={`${sitemap['web-analytics'].sessions}`}
              endIcon={<East />}
              key="sessions"
            >
              <Translate contentKey="module-factory.sessions">Sessions</Translate>
            </Button>
          </>
        ),
      }}
    >
      <Typography variant="subtitle1">
        <Translate contentKey="module-factory.accordion.analytics-dashboards-text">
          Browse analytics pages to follow users activities and more.
        </Translate>
      </Typography>
    </LayoutItem>
  );
};

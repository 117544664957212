import React from 'react';

import { useTheme } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';
import { WithData } from 'app/modules/loading-lib/WithData';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';

const MapEchart = ({ data, loading }) => {
  const theme = useTheme();
  const option = {
    tooltip: {
      show: true,
      trigger: 'item',
      formatter(params) {
        return params.name + ' : ' + params.value;
      },
    },
    dataRange: {
      show: false,
      min: 0,
      max: data.map(c => c.value).sort()[data.length - 1],
      realtime: false,
      text: ['High', 'Low'],
      calculable: true,
      color: [theme.palette.primary.dark, theme.palette.primary.light],
    },
    series: {
      type: 'map',
      name: `metrics by country`,
      map: 'world',
      roam: true,
      data,
    },
  };

  return <ReactECharts style={{ height: '100%', width: '100%' }} option={option} />;
};

export default WithLoading(WithData(MapEchart));

import { DataGridProps } from '@mui/x-data-grid';

export function dynamicHeightProps(compactPaddingY = 1, standardPaddingY = '15px', comfortablePaddingY = '22px'): Partial<DataGridProps> {
  return {
    getRowHeight: () => 'auto',
    sx: {
      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
        py: compactPaddingY,
      },
      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
        py: standardPaddingY,
      },
      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
        py: comfortablePaddingY,
      },
    },
  };
}

import React, { useState } from 'react';

import { translate } from 'react-jhipster';
import { MenuItem, ToggleButton, ToggleButtonGroup, Box, Popper, MenuList, Paper, ClickAwayListener, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useTheme } from '@mui/material/styles';
import { bucketingDimensionsMappingList } from 'app/shared/util/data-hub-utils';
import { getDataExplorationBucketingDimension, setDataExplorationBucketingDimension } from 'app/shared/reducers/views/data-exploration';

const BucketingDimensionToggler = () => {
  const dispatch = useAppDispatch();
  const bucketingDimension = useAppSelector(getDataExplorationBucketingDimension);
  const theme = useTheme();

  const [openBucketing, setOpenBucketing] = useState(false);
  const [otherBucketsButton, setOtherBucketsButton] = React.useState<HTMLButtonElement | null>(null);

  const onChangeHandlerToggle = (event, currentBucket) => {
    const bucket = bucketingDimensionsMappingList.find(i => i.value === currentBucket);
    if (bucket) {
      dispatch(setDataExplorationBucketingDimension(bucket.value));
      setOtherBucketsButton(null);
    }
    if (currentBucket === '') {
      setOpenBucketing(true);
      setOtherBucketsButton(event.target);
    }
  };

  const onCloseBucketing = () => {
    setOpenBucketing(false);
  };

  const onChangeHandler = (event, b) => {
    dispatch(setDataExplorationBucketingDimension(b));
    setOpenBucketing(false);
  };

  return (
    <>
      <ToggleButtonGroup exclusive value={bucketingDimension} onChange={onChangeHandlerToggle} size="small">
        {bucketingDimensionsMappingList
          .filter(b => (b.displayOn ? useMediaQuery(theme.breakpoints.up(b.displayOn)) : false))
          .map(bucket => {
            return (
              <ToggleButton color="primary" key={bucket.value} value={bucket.value} sx={{ paddingBlock: 0 }}>
                <Typography>{translate(`open-analytics.bucketingNameMapping.${bucket.value}`)}</Typography>
              </ToggleButton>
            );
          })}

        <ToggleButton color="primary" disableRipple={true} value="" sx={{ paddingBlock: 0 }}>
          <Typography>{translate(`open-analytics.Others`)}</Typography>
          <ArrowDropDownIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <Popper open={openBucketing} anchorEl={otherBucketsButton} placement="bottom-end">
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
          <Paper>
            <ClickAwayListener onClickAway={onCloseBucketing}>
              <MenuList>
                {bucketingDimensionsMappingList
                  .filter(b => (b.displayOn ? useMediaQuery(theme.breakpoints.down(b.displayOn)) : true))
                  .map(bucket => {
                    return (
                      <MenuItem
                        key={bucket.value}
                        selected={bucketingDimension === bucket.value}
                        value={bucket.value}
                        onClick={event => onChangeHandler(event, bucket.value)}
                      >
                        {translate(`open-analytics.bucketingNameMapping.${bucket.value}`)}
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Box>
      </Popper>
    </>
  );
};

export default BucketingDimensionToggler;

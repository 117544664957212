import { IDialogsState } from '../hooks/useAccountPage';
import { accountEditAttributes } from './form-attributes/accountEditAttributes';
import { billingEditAttributes } from './form-attributes/billingEditAttributes';
import { inviteMemberAttributes } from './form-attributes/inviteMemberAttributes';
import { FieldProp } from 'app/modules/form-builder-lib';
import { AccountDtoRes, useUpdateAccountUsingPutMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import {
  AccountProfileDto,
  useInviteMemberUsingPostMutation,
} from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';

export const accountFormsList = (
  billingAccount: AccountDtoRes,
  accountProfile: AccountProfileDto,
  dialogsState: IDialogsState
): {
  title: string;
  setOpen: (open: boolean) => void;
  attributes: FieldProp[];
  defaultValues: {
    [x: string]: string;
  };
  onClick: (data: any) => void;
  open: boolean;
}[] => {
  const [updateBillingAccount] = useUpdateAccountUsingPutMutation();
  const [inviteMemberToCurrentAccount] = useInviteMemberUsingPostMutation();
  return [
    {
      title: 'update-account-infos',
      setOpen: dialogsState.setOpenAccountEditForm,
      attributes: accountEditAttributes,
      defaultValues: {
        name: billingAccount.name ? billingAccount.name : '',
        locale: billingAccount.locale ? billingAccount.locale : '',
        company: billingAccount.company ? billingAccount.company : '',
        phone: billingAccount.phone ? billingAccount.phone : '',
        notes: billingAccount.notes ? billingAccount.notes : '',
      },
      onClick(data) {
        if (accountProfile.accountId) {
          // maybe JSON.strigfy(data) here
          updateBillingAccount({ accountKey: accountProfile.accountId, accountDtoReq: data });
          dialogsState.setOpenAccountEditForm(false);
        }
      },
      open: dialogsState.openAccountEditForm,
    },
    {
      title: 'update-billing-infos',
      setOpen: dialogsState.setOpenBillingEditForm,
      attributes: billingEditAttributes,
      defaultValues: {
        email: billingAccount.email ? billingAccount.email : '',
        country: billingAccount.country ? billingAccount.country : '',
        address1: billingAccount.address1 ? billingAccount.address1 : '',
        city: billingAccount.city ? billingAccount.city : '',
        postalCode: billingAccount.postalCode ? billingAccount.postalCode : '',
      },
      onClick(data) {
        if (accountProfile.accountId) {
          // maybe JSON.strigfy(data) here
          updateBillingAccount({ accountKey: accountProfile.accountId, accountDtoReq: data });
          dialogsState.setOpenBillingEditForm(false);
        }
      },
      open: dialogsState.openBillingEditForm,
    },
    {
      title: 'invite-member',
      setOpen: dialogsState.setOpenInviteMemberForm,
      attributes: inviteMemberAttributes,
      defaultValues: {
        role: 'GUEST',
      },
      onClick(data) {
        inviteMemberToCurrentAccount({ userEmail: data.email, roles: data.role });
        dialogsState.setOpenInviteMemberForm(false);
      },
      open: dialogsState.openInviteMemberForm,
    },
  ];
};

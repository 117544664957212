import {
  LibraryBooksTwoTone,
  TimelapseTwoTone,
  CurrencyExchangeTwoTone,
  LocalPhoneTwoTone,
  BusinessTwoTone,
  AccountBalanceTwoTone,
  AccountBalanceWalletTwoTone,
  LanguageTwoTone,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { translate } from 'react-jhipster';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';

export const DetailsList = () => {
  const { billingAccount } = useAppAccountStates();

  return [
    {
      icon: LibraryBooksTwoTone,
      key: 'account.account-page.account',
      value: billingAccount.name ? billingAccount.name : 'Unnamed account',
    },
    {
      icon: TimelapseTwoTone,
      key: 'account.account-page.since',
      value: billingAccount.referenceTime ? dayjs(billingAccount.referenceTime).fromNow() : '-',
    },
    {
      icon: BusinessTwoTone,
      key: 'account.account-page.company',
      value: billingAccount.company ? billingAccount.company : '-',
    },
    {
      icon: CurrencyExchangeTwoTone,
      key: 'account.account-page.currency',
      value: billingAccount.currency ? billingAccount.currency : '-',
    },
    {
      icon: LocalPhoneTwoTone,
      key: 'account.account-page.phone',
      value: billingAccount.phone ? billingAccount.phone : '-',
    },

    {
      icon: AccountBalanceTwoTone,
      key: 'account.account-page.balance',
      value: billingAccount.accountBalance ? billingAccount.accountBalance : '-',
    },
    {
      icon: LanguageTwoTone,
      key: 'account.account-page.locale',
      value: billingAccount.locale ? translate(`language.${billingAccount.locale}`) : '-',
    },
    {
      icon: AccountBalanceWalletTwoTone,
      key: 'account.account-page.credit',
      value: billingAccount.accountCBA ? billingAccount.accountCBA : '-',
    },
  ];
};

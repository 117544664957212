import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  openMobileVersion: false,
};

export type RightSidebarState = Readonly<typeof initialState>;

export const RightSidebarSlice = createSlice({
  name: 'rightSidebar',
  initialState: initialState as RightSidebarState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
    setOpenMobileVersion(state, action) {
      state.openMobileVersion = action.payload;
    },
  },
});

export const { setOpen, setOpenMobileVersion } = RightSidebarSlice.actions;

export default RightSidebarSlice.reducer;

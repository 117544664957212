import React from 'react';

import { Skeleton } from '@mui/material';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';

export const LoadingStackSkeleton = () => {
  return (
    <RawStackLayout height="100%" justifyContent="space-evenly">
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
    </RawStackLayout>
  );
};

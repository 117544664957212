import React from 'react';

import { LinearProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { WithData } from 'app/modules/loading-lib/WithData';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

const RankingBox = ({ data }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">{data.name}</Typography>
        <Typography variant="h5" color={data.name === 'Others' ? 'secondary' : 'primary'}>
          {data.value}
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={data.percentage} {...(data.name === 'Others' && { color: 'secondary' })} />
    </Box>
  );
};
const RecommendationsRanking = ({ data, total, isFetching }) => {
  const rankingData = data.map(e => {
    const percentage = total ? (e.value * 100) / total : 0;
    return {
      name: e.name,
      value: `${e.value} (${Number(percentage).toFixed(1)} %)`,
      percentage,
    };
  });
  return (
    <Stack width="100%" spacing={1} justifyContent="space-between">
      {rankingData.map((r, index) => (
        <RankingBox key={index} data={r} />
      ))}
    </Stack>
  );
};

export default WithLoadingHOC(WithData(RecommendationsRanking));

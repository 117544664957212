import { useAppDispatch } from 'app/config/store';
import { useState, useEffect } from 'react';

export const useRequestFilterManager = (filters, deleteFilter) => {
  const dispatch = useAppDispatch();
  const [editFilter, setEditFilter] = useState(null);
  const [openFilterForm, setOpenFilterForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickFilterPopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilterPopover = () => {
    setAnchorEl(null);
  };
  const handleChipClick = (e, filter) => {
    setEditFilter(filter);
    handleClickFilterPopover(e);
  };
  const handleChipDelete = filter => {
    dispatch(deleteFilter(filter.key));
  };
  const openFilterPopover = Boolean(anchorEl);

  useEffect(() => {
    if (filters.length === 0) {
      setOpenFilterForm(true);
    }
  }, [filters.length]);
  useEffect(() => {
    if (editFilter) {
      setOpenFilterForm(true);
    }
  }, [editFilter]);
  return {
    anchorEl,
    editFilter,
    setEditFilter,
    openFilterForm,
    setOpenFilterForm,
    openFilterPopover,
    handleClickFilterPopover,
    handleCloseFilterPopover,
    handleChipClick,
    handleChipDelete,
  };
};

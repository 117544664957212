import React from 'react';

import { Welcome } from '../../../welcome/Welcome';
import { AccountStatus } from 'app/shared/reducers/account-profiles/account-profile';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { translate } from 'react-jhipster';
import { APPLICATIONS, MANAGEMENT } from 'app/shared/reducers/main-layouts/menubar';
import { ControlledTabulate, TabItem } from 'app/components/tabulation';
import { ManagementView } from './components/ManagementView';
import { ApplicationsView } from './components/ApplicationsView';
import { Apps, ManageAccounts } from '@mui/icons-material';
import { useHomeModule } from './hooks/useHomeModule';

export function HomeModule(props) {
  const { accountProfile, homeView, onChangeHandlerToggle } = useHomeModule();
  const { subscribedProducts } = props;

  if (accountProfile.status === AccountStatus.BOOTSTRAP) return <Welcome />;

  if (subscribedProducts.length === 0) {
    return (
      <BasicCardLayout variant="div" card={{ sx: { overflow: 'visible' } }}>
        <ManagementView />
      </BasicCardLayout>
    );
  }

  return (
    <ControlledTabulate tabValue={homeView} setTabValue={onChangeHandlerToggle} centered>
      <TabItem
        tab={{
          label: translate(`module-factory.applications`),
          value: APPLICATIONS,
          icon: <Apps />,
          iconPosition: 'start',
        }}
      >
        <ApplicationsView subscribedProducts={subscribedProducts} />
      </TabItem>
      <TabItem
        tab={{
          label: translate(`module-factory.management`),
          value: MANAGEMENT,
          icon: <ManageAccounts />,
          iconPosition: 'end',
        }}
      >
        <ManagementView />
      </TabItem>
    </ControlledTabulate>
  );
}

import React from 'react';

import { TablePaginationProps, Chip, Box, Alert } from '@mui/material';
import {
  useGridApiContext,
  useGridSelector,
  gridPageSizeSelector,
  gridFilteredTopLevelRowCountSelector,
  useGridRootProps,
  GridPagination,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { useAppSelector } from 'app/config/store';
import { FilterAlt } from '@mui/icons-material';
import { translate } from 'react-jhipster';

const getPageCount = (rowCount: number, pageSize: number): number => {
  if (pageSize > 0 && rowCount > 0) {
    return Math.ceil(rowCount / pageSize);
  }

  return 0;
};

function Pagination({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
  const rootProps = useGridRootProps();
  const pageCount = getPageCount(rootProps.rowCount ?? visibleTopLevelRowCount, pageSize);

  return (
    <MuiPagination
      color="primary"
      variant="outlined"
      className={className}
      count={pageCount}
      page={page + 1}
      boundaryCount={2}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

export function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export const CustomToolbar = () => {
  const { searchedItemsList, searchType, searchText } = useAppSelector(state => state.views.recoItems);
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton key="columns" />
      <GridToolbarFilterButton key="filter" />
      <GridToolbarDensitySelector key="density" />
      <Box key="filler" flex={1} />
      <Chip
        icon={<FilterAlt fontSize="small" color="primary" />}
        color="primary"
        variant="outlined"
        label={searchType + searchText}
        sx={{ mr: 2, ...(searchedItemsList.length === 0 && { visibility: 'hidden' }) }}
      />
    </GridToolbarContainer>
  );
};

export const NoItemsFound = () => {
  return (
    <Box display="flex" height="100%">
      <Alert variant="outlined" severity="warning" sx={{ width: '30%', mx: 'auto', my: 'auto' }}>
        {translate('recommendation.items.no-items-found', null, 'No Items Found')}
      </Alert>
    </Box>
  );
};

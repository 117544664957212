import React, { useEffect, useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import { FieldProp, FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Translate, translate } from 'react-jhipster';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import {
  useCreateCorpusMutation,
  useCountItemsQuery,
  useUpdateCorpusMutation,
} from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { useForm } from 'react-hook-form';
import { Add, Edit } from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const EditCorpus = ({ corpus, setOpen }) => {
  const { accountProfile } = useAppAccountStates();
  const [editCorpus, { isLoading }] = useUpdateCorpusMutation();
  const [filterQuery, setFilterQuery] = useState('');

  const getFields: Array<FieldProp> = [
    {
      component: 'text-field',
      attribute: 'corpus',
      label: translate('recommendation.corpus.corpus-name', null, 'Corpus name'),
      validationType: 'string',
      validations: [['required', true]],
    },
    {
      component: 'text-field',
      attribute: 'filter-query',
      label: 'Filter query',
      validationType: 'string',
    },
  ];

  const schema = validationUtils.getFormSchema(getFields);
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: { corpus: corpus.name, 'filter-query': corpus.filter_query },
  });

  const { currentData: itemCount = null, isFetching } = useCountItemsQuery(
    accountProfile.accountId ? { accountId: accountProfile.accountId.substring(0, 8), filterQuery } : skipToken
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => setFilterQuery(String(methods.watch('filter-query'))), 1000);
    return () => clearTimeout(timeOutId);
  }, [methods.watch('filter-query')]);

  const submitButton = (
    <LoadingButton
      disabled={!methods.formState.isValid || !methods.formState.isDirty}
      startIcon={<Edit />}
      variant="contained"
      color="primary"
      loading={isLoading || isFetching}
      loadingPosition="start"
      sx={{ marginBottom: 1.5, marginTop: 1, marginX: 2 }}
      onClick={() => {
        methods.handleSubmit((data: { corpus: string; 'filter-query': string }) => {
          editCorpus({
            accountId: accountProfile.accountId.substring(0, 8),
            id: corpus.id,
            queryParameters: {
              corpus: data?.corpus,
              filter_query: data['filter-query'],
              filter_query_fields: '*',
              filter_query_source_includes: '*',
              filter_query_source_excludes: 'id',
              debug: false,
            },
          }).then(res => {
            setOpen(false);
          });
        })();
      }}
    >
      <Translate contentKey="recommendation.corpus.edit">Edit</Translate> {itemCount && `   (${itemCount})`}
    </LoadingButton>
  );

  const cancelButton = (
    <Button color="error" onClick={() => setOpen(false)}>
      <Translate contentKey="recommendation.cancel">Cancel</Translate>
    </Button>
  );

  return (
    <Dialog
      open={corpus !== null}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle typography="h4">
        <Translate contentKey="recommendation.corpus.edit-corpus">Edit corpus</Translate>
      </DialogTitle>
      <DialogContent dividers>
        <BasicCardLayout variant="div" content={{ sx: { p: 0 } }} card={{ sx: { overflow: 'visible' } }}>
          <FormBuilder
            fields={getFields}
            defaultValue={{ corpus: corpus.name, 'filter-query': corpus.filter_query }}
            methods={methods}
            optionalMarkerDefault={true}
            containerProps={{ marginY: 0.5, paddingX: 2 }}
          />
        </BasicCardLayout>
      </DialogContent>
      <DialogActions sx={{ paddingX: 3 }}>
        {cancelButton}
        {submitButton}
      </DialogActions>
    </Dialog>
  );
};

import { useAccountState } from './useAccountState';
import { useAppStarting } from './useAppStarting';
import { useFetchCatalogSubscriptions } from './useFetchCatalogSubscriptions';
import { useFetchUserAccounts } from './useFetchUserAccounts';

export const useRestrictedPage = () => {
  useAppStarting();
  useFetchUserAccounts();
  useFetchCatalogSubscriptions();
  useAccountState();
};

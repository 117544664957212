import { useEffect, useState } from 'react';

import { useFindAllItemsQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from 'app/config/store';

export const useAllItemsDataGrid = () => {
  const { searchedItemsList, loading } = useAppSelector(state => state.views.recoItems);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { data: itemsList = {}, isFetching: isAllItemsFetching } = useFindAllItemsQuery(
    accountProfile.accountId
      ? { accountId: accountProfile.accountId.substring(0, 8), page: paginationModel.page, size: paginationModel.pageSize }
      : skipToken
  );

  const [rowCountState, setRowCountState] = useState(
    searchedItemsList.totalElements ? searchedItemsList.totalElements : itemsList.totalElements || 0
  );

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      searchedItemsList.totalElements
        ? searchedItemsList.totalElements
        : itemsList.totalElements !== undefined
        ? itemsList.totalElements
        : prevRowCountState
    );
  }, [searchedItemsList, itemsList.totalElements, setRowCountState]);

  return { itemsList, searchedItemsList, paginationModel, setPaginationModel, isFetching: isAllItemsFetching || loading, rowCountState };
};

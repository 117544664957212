import React, { Fragment } from 'react';

import {
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardActions,
  CardActionsProps,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardMedia,
  CardMediaProps,
  CardProps,
} from '@mui/material';
import { toCardContentLayoutProps } from 'app/modules/layout-builder-lib/functions/converter/toCardContentLayoutProps';
import { ifExistThen } from 'app/modules/layout-builder-lib/functions/generics/ifExistThen';
import { useVariants, VariantName, VariantsMap } from 'app/modules/layout-builder-lib/hooks/useVariants';
import { merge } from 'merge-anything';
import { removeUndefined } from 'app/modules/layout-builder-lib/functions/generics/removeUndefined';

export interface CardContentLayoutProps {
  media?: CardMediaProps;
  content?: CardContentProps;
  children?: React.ReactNode;
}

export interface ClickableCardContentLayoutProps extends CardContentLayoutProps {
  actionArea?: CardActionAreaProps;
}

export interface BasicCardLayoutProps extends ClickableCardContentLayoutProps {
  variant?: VariantName;
  card?: CardProps;
  header?: CardHeaderProps;
  actions?: React.ReactNode;
  actionsProps?: CardActionsProps;
}

export type CardContentFunctionType = (props: ClickableCardContentLayoutProps) => JSX.Element;

export type CoreCardLayoutProps = Omit<BasicCardLayoutProps, 'children'>;

export function BasicCardLayout({
  variant,
  card,
  header,
  actionArea,
  actionsProps,
  actions,
  media,
  content,
  children,
}: BasicCardLayoutProps): JSX.Element {
  const variants: VariantsMap = useVariants();

  const variantProps = variants[variant] ?? {};
  const { card: variantCardProps, header: variantHeader, actionsProps: variantActions }: CoreCardLayoutProps = variantProps;

  const cardContentLayout: CardContentFunctionType = actionArea ? ClickableCardContentLayout : CardContentLayout;

  return (
    <Card {...merge({}, ...removeUndefined(variantCardProps, card))}>
      {ifExistThen(header, <CardHeader {...merge({}, ...removeUndefined(variantHeader, header))} />)}
      {cardContentLayout(toCardContentLayoutProps({ actionArea, media, content, children }, variantProps))}
      {ifExistThen(actions, <CardActions {...merge({}, ...removeUndefined(variantActions, actionsProps))}>{actions}</CardActions>)}
    </Card>
  );
}

export function CardContentLayout({ children, media, content }: CardContentLayoutProps): JSX.Element {
  return (
    <Fragment>
      {media && <CardMedia {...media} />}
      {ifExistThen(children, <CardContent {...content}>{children}</CardContent>)}
    </Fragment>
  );
}

export function ClickableCardContentLayout({ actionArea, ...props }: ClickableCardContentLayoutProps): JSX.Element {
  return (
    <CardActionArea {...actionArea}>
      <CardContentLayout {...props} />
    </CardActionArea>
  );
}

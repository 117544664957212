import { green } from '@mui/material/colors';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useTheme } from '@mui/system';
import React from 'react';

function HomeIcon(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon {...props} sx={{ margin: theme.spacing(2) }}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

export default function IconExample() {
  return (
    <div>
      <HomeIcon />
      <HomeIcon color="primary" />
      <HomeIcon color="secondary" />
      <HomeIcon color="action" />
      <HomeIcon color="disabled" />
      <HomeIcon style={{ color: green[500] }} />
    </div>
  );
}

import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

interface LiveButtonProps {
  isLive: any;
  liveClickHandler: any;
  disabled: any;
}

const LiveButton = ({ isLive, liveClickHandler, disabled }: LiveButtonProps) => {
  return (
    <Box display="flex" alignSelf="stretch">
      <Button
        disabled={disabled}
        disableRipple
        sx={{
          ...(!isLive
            ? {
                textTransform: 'none',
              }
            : {
                textTransform: 'uppercase',
              }),
        }}
        variant={isLive ? 'contained' : 'outlined'}
        onClick={liveClickHandler}
        size="small"
      >
        Live
      </Button>
    </Box>
  );
};

export default LiveButton;

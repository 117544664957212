import dayjs from 'dayjs';

export const presetRanges = [
  'customPreset',
  'thisWeek',
  'thisMonth',
  'lastMonth',
  'thisYear',
  'lastYear',
  'oneMonthAgo',
  'thisYearSoFar',
  'lastWeek',
  'thisDayLastWeek',
  'thisDayTwoWeeksAgo',
  // 'thisHour',
  // 'lastHour',
  // 'thisMinute',
  // 'lastMinute',
  // 'lastFiveMinutes',
  // 'lastFifteenMinutes',
  // 'lastThirtyMinutes',
  // 'thisDay',
  // 'yesterday',
];

export const handlePresetChange = (event, methods) => {
  methods.setValue('preset-ranges', event.target.value);
  if (event.target.value === 'customPreset') methods.setValue('filename', 'webevents');
  else methods.setValue('filename', event.target.value);

  switch (event.target.value) {
    case 'thisWeek':
      methods.setValue('startDate', dayjs().startOf('week').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().endOf('week').format('YYYY-MM-DD'));
      break;
    case 'thisMonth':
      methods.setValue('startDate', dayjs().startOf('month').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().endOf('month').format('YYYY-MM-DD'));
      break;
    case 'lastMonth':
      methods.setValue('startDate', dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
      break;
    case 'thisYear':
      methods.setValue('startDate', dayjs().startOf('year').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().endOf('year').format('YYYY-MM-DD'));
      break;
    case 'lastYear':
      methods.setValue('startDate', dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
      break;
    case 'oneMonthAgo':
      methods.setValue('startDate', dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
      break;
    case 'thisYearSoFar':
      methods.setValue('startDate', dayjs().startOf('year').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().endOf('day').format('YYYY-MM-DD'));
      break;
    case 'lastWeek':
      methods.setValue('startDate', dayjs().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'));
      break;
    case 'thisDayLastWeek':
      methods.setValue('startDate', dayjs().subtract(1, 'week').startOf('day').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().subtract(1, 'week').endOf('day').format('YYYY-MM-DD'));
      break;
    case 'thisDayTwoWeeksAgo':
      methods.setValue('startDate', dayjs().subtract(2, 'week').startOf('day').format('YYYY-MM-DD'));
      methods.setValue('endDate', dayjs().subtract(2, 'week').endOf('day').format('YYYY-MM-DD'));
      break;
    // case 'thisHour':
    //   methods.setValue('startDate', dayjs().startOf('hour').format('YYYY-MM-DDTHH:mm'));
    //   methods.setValue('endDate', dayjs().endOf('hour').format('YYYY-MM-DDTHH:mm'));
    //   break;
    // case 'lastHour':
    //   methods.setValue('startDate', dayjs().subtract(1, 'hour').startOf('hour'));
    //   methods.setValue('endDate', dayjs().subtract(1, 'hour').endOf('hour'));
    //   break;
    // case 'thisMinute':
    //   methods.setValue('startDate', dayjs().startOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   methods.setValue('endDate', dayjs().endOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   break;
    // case 'lastMinute':
    //   methods.setValue('startDate', dayjs().subtract(1, 'minute').startOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   methods.setValue('endDate', dayjs().subtract(1, 'minute').endOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   break;
    // case 'lastFiveMinutes':
    //   methods.setValue('startDate', dayjs().subtract(5, 'minute').startOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   methods.setValue('endDate', dayjs().endOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   break;
    // case 'lastFifteenMinutes':
    //   methods.setValue('startDate', dayjs().subtract(15, 'minute').startOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   methods.setValue('endDate', dayjs().endOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   break;
    // case 'lastThirtyMinutes':
    //   methods.setValue('startDate', dayjs().subtract(30, 'minute').startOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   methods.setValue('endDate', dayjs().endOf('minute').format('YYYY-MM-DDTHH:mm'));
    //   break;
    // case 'thisDay':
    //   methods.setValue('startDate', dayjs().startOf('day').format('YYYY-MM-DDTHH:mm'));
    //   methods.setValue('endDate', dayjs().endOf('day').format('YYYY-MM-DDTHH:mm'));
    //   break;
    // case 'yesterday':
    //   methods.setValue('startDate', dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm'));
    //   methods.setValue('endDate', dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DDTHH:mm'));
    //   break;
    default:
      break;
  }
};

import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { addFlex, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import { sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

function processEventDataUniquePageViews(events) {
  const sortedEvents = events.slice().sort((a, b) => a.timestamp - b.timestamp);

  const processedEvents = [];
  let clickCount = 0;
  let currentRefreshCounter = 0;
  let lastEvent = null;
  let summaryEvent = null;

  for (let i = 0; i < sortedEvents.length; i++) {
    const currentEvent = sortedEvents[i];
    if (currentEvent.location !== lastEvent?.location) {
      // Different page event
      lastEvent && processedEvents.push({ ...summaryEvent, clickCount, refreshCounter: currentRefreshCounter });
      // if (currentEvent.eventType.toLowerCase() === 'pageview' || currentEvent.reasonForNewSession || lastEvent == null) {
      summaryEvent = currentEvent;
      clickCount = 0;
      currentRefreshCounter = 0;
      // }
    } else {
      // Same page event
      if (currentEvent.eventType.toLowerCase() === 'pageview') {
        currentRefreshCounter++;
      }
      if (currentEvent?.clickTarget || currentEvent?.clickText || currentEvent?.clickUrl) {
        clickCount++;
      }
    }

    lastEvent = currentEvent;
  }
  summaryEvent && processedEvents.push({ ...summaryEvent, clickCount, refreshCounter: currentRefreshCounter });

  return processedEvents;
}

const columns: GridColDef[] = [
  {
    field: 'timestamp',
    valueFormatter: ({ value }) => value && dayjs(value).format('LTS'),
  },
  {
    field: 'locationPath',
    renderCell: ({ value, row }) =>
      value && <Link to={row.location}>{row.locationQueryString ? value + '?' + row.locationQueryString : value}</Link>,
  },
  {
    field: 'clickCount',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'refreshCounter',
    headerAlign: 'center',
    align: 'center',
  },
];

export const SessionEvents = ({ events }) => {
  return (
    <DataGrid
      rows={processEventDataUniquePageViews(events) || []}
      columns={columns.map(addFlex).map(translateHeaders('visitor-profile.events'))}
      {...sizedPagination()}
      density="compact"
      getRowId={row => row._id}
      disableRowSelectionOnClick
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        sorting: {
          sortModel: [{ field: 'timestamp', sort: 'desc' }],
        },
      }}
      sx={{
        mr: 1,
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
      }}
    />
  );
};

import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useGetRecosMutation } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';

export const useGetRecos = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const [getRecos, resReco] = useGetRecosMutation();
  const handleGetRecos = data => {
    getRecos({
      accountId: accountProfile.accountId.substring(0, 8),
      itemId: data.itemId !== '' ? data.itemId : undefined,
      profileId: data.profileId !== '' ? data.profileId : undefined,
      queryParameters: {
        corpus: data.corpus,
        recommender_type: data.strategy === 'trending' ? data.trendingScale : data.strategy,
        filter_query_fields: '*',
        filter_query_source_includes: '*',
        filter_query_source_excludes: '_class',
      },
    });
  };
  return { resReco, handleGetRecos };
};

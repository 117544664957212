import React from 'react';
import { DetailsList } from './DetailsList';
import { ListItemIcon, ListItemText, ListItemSecondaryAction, Typography, ListItemButton } from '@mui/material';
import { translate } from 'react-jhipster';
import { EditAttributes } from './EditAttributes';

export const DetailsAttributes = () => {
  return {
    display: DetailsList().map((item, index) => {
      return {
        component: 'custom',
        attribute: item.key,
        customComponent() {
          const Icon = item.icon;
          return (
            <ListItemButton key={index} divider>
              <ListItemIcon>
                <Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={translate(item.key)} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" color="textSecondary">
                  {item.value}
                </Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
          );
        },
        col: {
          md: 6,
        },
      };
    }),
    edit: EditAttributes(),
  };
};

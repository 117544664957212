import React from 'react';
import { useTheme } from '@mui/material/styles';
import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

interface TrendChartProps {
  data: any;
  kpi: any;
  selectedChart: any;
  isFetching?: boolean;
  isError?: boolean;
  error?: any;
}

const TrendChart = ({ data, kpi, selectedChart, error = null, isFetching, isError = false }: TrendChartProps) => {
  const theme = useTheme();

  const config: EChartsOption = {
    backgroundColor: 'transparent',

    ...(selectedChart.name !== kpi && {
      color: theme.palette.primary.light,
    }),
    ...(selectedChart.name === kpi && {
      color: theme.palette.primary.dark,
    }),
    series: [
      {
        type: 'line',
        data,
        areaStyle: {},
        smooth: 0.3,
        symbol: 'none',
        cursor: 'pointer',
        lineStyle: {
          width: 3,
        },
      },
    ],
    xAxis: {
      type: 'time',
      show: false,
      splitNumber: 2,
      axisLine: {
        lineStyle: {
          color: theme.palette.divider,
        },
      },
      axisLabel: {
        formatter: {
          year: '{yearStyle|{yyyy}}',
          day: '{dd} {MMM}',
        },
        rich: {
          yearStyle: {
            fontSize: 10,
            fontWeight: 'bold',
          },
        },
        fontSize: 10,
      },
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    grid: {
      left: '0%',
      right: '0%',
      top: '3%',
      bottom: '0%',
    },
  } as EChartsOption;

  return <ReactECharts style={{ height: '100%', width: '100%' }} option={config} />;
};

export default WithLoadingHOC(TrendChart);

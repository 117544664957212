import React from 'react';

import { Avatar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { getAlphaColor } from 'app/shared/util/color-palette-calculator';
import { Translate } from 'react-jhipster';
import { variables } from '../LandingPage';

import { Security, Beenhere, PendingActions, HowToReg } from '@mui/icons-material';

const icons = [Security, Beenhere, PendingActions, HowToReg];

export const WhyScoringJoe = () => {
  return (
    <LayoutItem
      cardOptions={{
        variant: 'div',
        card: {
          id: 'WhyScoringJoe',
          sx: {
            backgroundColor: theme => getAlphaColor(theme.palette.primary.light, 0.4),
            py: '10vh',
          },
        },
        content: { sx: { my: 'auto', mx: 'auto' } },
        header: {
          disableTypography: true,
          title: (
            <Typography fontSize="2.2rem" textAlign="center">
              <Translate contentKey={'landing.reasons.title'} interpolate={{ ...variables }}>
                Why choose ScoringJoe
              </Translate>
            </Typography>
          ),
          sx: {
            pb: '5vh',
          },
        },
      }}
    >
      <Container maxWidth="xl">
        <GridLayout justifyContent="space-evenly" spacing={{ xs: 7, md: 2, lg: 5 }}>
          {['a', 'b', 'c', 'd'].map((element, index) => {
            const Icon = icons[index];
            return (
              <LayoutItem
                key={index}
                gridItemProps={{
                  xs: 12,
                  md: 3,
                  sm: 6,
                  display: 'grid',
                }}
                cardOptions={{
                  // variant: 'div',
                  card: {
                    sx: {
                      borderRadius: 5,
                      backgroundColor: theme => theme.palette.background.paper,
                    },
                    elevation: 3,
                  },
                  header: {
                    disableTypography: true,
                    avatar: (
                      <Avatar variant="rounded" sx={{ bgcolor: theme => theme.palette.primary.main, borderRadius: 5 }}>
                        {<Icon />}
                      </Avatar>
                    ),
                    title: (
                      <Typography fontSize="1.5rem">
                        <Translate contentKey={`landing.reasons.${element}.title`} interpolate={{ ...variables }}>
                          Reason {element}
                        </Translate>
                      </Typography>
                    ),
                    sx: {
                      p: 3.5,
                      justifyContent: 'center',
                      '.MuiCardHeader-content': { flex: 'initial' },
                    },
                  },
                  content: { sx: { p: 3.5, pt: 0, '&:last-child': { p: 3.5, pt: 0 } } },
                }}
              >
                <Typography fontSize="1.1rem" textAlign="justify">
                  <Translate contentKey={`landing.reasons.${element}.summary`} interpolate={{ ...variables }}>
                    Analize, score, recommend, convert more with the full solution
                  </Translate>
                </Typography>
              </LayoutItem>
            );
          })}
        </GridLayout>
      </Container>
    </LayoutItem>
  );
};

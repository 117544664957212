import React from 'react';

import { MembersDataGrid } from 'app/modules/views/account-page/components/data-grids/MembersDataGrid';
import { TabWithAction } from './common/TabWithAction';
import { translate } from 'react-jhipster';
import AddIcon from '@mui/icons-material/Add';
import { IDialogsState } from '../../hooks/useAccountPage';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useUserStates } from 'app/shared/layout/restricted/hooks/selectors/useUserStates';

export const MembersTab = ({ dialogsState }: { dialogsState: IDialogsState }): JSX.Element => {
  const { membersList } = useAppAccountStates();
  const { currentUserRoles } = useUserStates();
  return (
    <TabWithAction
      action={{
        text: translate('account.account-page.invite-member'),
        icon: <AddIcon />,
        onClick: () => dialogsState.setOpenInviteMemberForm(true),
      }}
    >
      <MembersDataGrid
        membersList={currentUserRoles.includes('HURENCE_ADMIN') ? membersList : membersList.filter(m => !m.roles.includes('HURENCE_ADMIN'))}
      />
    </TabWithAction>
  );
};

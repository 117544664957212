import React from 'react';

import { TabItemProps } from 'app/components/tabulation';
import { LineKPIChart } from '../charts/LineKPIChart';
import { lineChartList } from './lineChartsList';
import { translate } from 'react-jhipster';

export const tabulations = (): TabItemProps[] => {
  return lineChartList.map(chart => {
    return {
      tab: { label: translate(`greensights.line-chart.${chart.title}`) },
      children: <LineKPIChart chart={chart} />,
      panel: {
        sx: {
          flexGrow: 1,
          p: 0,
        },
      },
    };
  });
};

import 'react-toastify/dist/ReactToastify.css';
// import './app.scss';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import 'app/config/dayjs';

import React, { StrictMode, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import ErrorBoundary from 'app/shared/error/error-boundary-catcher';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';
import { theme } from 'app/shared/themes/index';
import { languages } from './config/translation';
import dayjs from 'dayjs';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { SnackbarProvider } from 'notistack';
import AppRoutes from './routes';
import { CollapsibleErrorSnackbar } from './components/snackbar/CollapsibleErrorSnackbar';
import { HelmetProvider } from 'react-helmet-async';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  // Keep the local variable to reload full page on language change
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  dayjs.locale(currentLocale);

  const tagManagerArgs: TagManagerArgs = {
    gtmId: 'GTM-P5L7BSK',
  };

  TagManager.initialize(tagManagerArgs);

  const customization = {
    themeName: 'scoringJoe',
    palette: {
      // mode: 'dark'
      // variant: 'forest',
    },
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  return (
    <StrictMode>
      <StyledEngineProvider injectFirst>
        <HelmetProvider>
          <ThemeProvider theme={theme(customization, languages[currentLocale]?.mui)}>
            <CssBaseline />
            <BrowserRouter basename={baseHref}>
              <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} className="toastify-container" toastClassName="toastify-toast" />
              <SnackbarProvider
                Components={{
                  collapsible: CollapsibleErrorSnackbar,
                }}
              >
                <ErrorBoundary>
                  <AppRoutes />
                </ErrorBoundary>
              </SnackbarProvider>
            </BrowserRouter>
          </ThemeProvider>
        </HelmetProvider>
      </StyledEngineProvider>
    </StrictMode>
  );
};

export default App;

declare module 'notistack' {
  interface VariantOverrides {
    collapsible: {
      error: any;
    };
  }
}

import React from 'react';

import { Add, Menu } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import {
  addCardStructure,
  getRecoWidget,
  propertyCardInitialState,
  setCardStructure,
  setContainerStyle,
  setItemBoxStyle,
  setLayoutStyle,
} from 'app/shared/reducers/views/recommendations/widget';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useElementStyleForm } from '../hooks/forms/useElementStyleForm';
import { useMediasStyleForm } from '../hooks/forms/useMediasStyleForm';
import { badgeCircular, iconSxHover, popoverPosition } from '../utils/CommonPopoverStyles';
import { cssLayoutTypes, layoutIcons } from '../utils/CssLayout';
import { ItemPropertyCard } from './ItemPropertyCard';
import { LayoutFormBuilder } from './LayoutFormBuilder';
import { StylesActionBar, countModifiedValues } from './StylesActionBar';
import { StrictModeDroppable } from './dnd/StrictModeDroppable';
import { IconButtonPopover } from './popover/IconButtonPopover';

export const WidgetBuilder = () => {
  const dispatch = useAppDispatch();

  const { layoutStyle, containerStyle: containerBoxStyle, itemBoxStyle, cardStructure, mediaScreenType } = useAppSelector(getRecoWidget);

  const { methods: layoutMethods, style: layoutStyles } = useElementStyleForm(layoutStyle, setLayoutStyle);
  const { methods: containerMethods, style: containerStyles } = useElementStyleForm(containerBoxStyle, setContainerStyle);
  const { methods: itemMethods, style: itemStyles } = useElementStyleForm(itemBoxStyle, setItemBoxStyle);
  const { style: layoutItemStyle } = useMediasStyleForm(layoutMethods.control, 'itemStyle');

  const layoutIcon = layoutIcons[layoutStyle.value[mediaScreenType]] ?? <Menu />;
  const layoutIndex = cssLayoutTypes.indexOf(layoutStyle.value[mediaScreenType]);
  const modifiedValueCount =
    countModifiedValues(layoutStyles[mediaScreenType].fields[layoutIndex]) +
    countModifiedValues(layoutItemStyle[mediaScreenType].fields[layoutIndex]);

  const handleAddElement = () => {
    dispatch(addCardStructure(propertyCardInitialState));
  };

  const handleDragDrop = result => {
    const { source, destination, type } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;
    if (type === 'group') {
      const reorderedCard = [...cardStructure];
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      const [removedCard] = reorderedCard.splice(sourceIndex, 1);
      reorderedCard.splice(destinationIndex, 0, removedCard);
      return dispatch(setCardStructure(reorderedCard));
    }
  };

  return (
    <RawStackLayout margin={2}>
      <BasicCardLayout
        header={{
          title: <Typography variant="h5">Widget Layout</Typography>,
          action: (
            <Stack direction="row" spacing={1}>
              <StylesActionBar methods={containerMethods} style={containerStyles} hideButtons={['link', 'tag']} />
              <IconButtonPopover
                icon={layoutIcon}
                badge={{ ...badgeCircular, badgeContent: modifiedValueCount }}
                tooltip={{ title: 'Layout Settings' }}
                popover={popoverPosition}
              >
                <LayoutFormBuilder methods={layoutMethods} style={layoutStyles} itemStyle={layoutItemStyle} />
              </IconButtonPopover>
            </Stack>
          ),
          sx: { paddingY: 1.5, paddingX: 2 },
        }}
      />
      <BasicCardLayout
        header={{
          title: <Typography variant="h5">Item Card</Typography>,
          action: (
            <Stack direction="row" spacing={1}>
              <StylesActionBar methods={itemMethods} style={itemStyles} hideButtons={['tag']} />
              <Tooltip title="Add property">
                <span>
                  <IconButton disabled={!cardStructure.every(card => card.value?.length > 0)} onClick={handleAddElement}>
                    <Add sx={iconSxHover} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          ),
          sx: { paddingY: 1.5, paddingX: 2, backgroundColor: 'white' },
        }}
        content={{
          sx: { backgroundColor: 'transparent', padding: 0, '&:last-child': { paddingBottom: 0 } },
        }}
        card={{
          sx: { backgroundColor: 'transparent' },
        }}
      >
        <DragDropContext onDragEnd={handleDragDrop}>
          <div>
            <StrictModeDroppable droppableId="ROOT" type="group">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={snapshot.isDraggingOver ? { border: '1px dashed gray' } : { border: '1px solid lightgray' }}
                  {...provided.droppableProps}
                >
                  {cardStructure.map((element, i) => (
                    <Draggable draggableId={`${i}`} key={i} index={i}>
                      {p => (
                        <div {...p.dragHandleProps} {...p.draggableProps} ref={p.innerRef}>
                          <ItemPropertyCard element={element} index={i} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </div>
        </DragDropContext>
      </BasicCardLayout>
    </RawStackLayout>
  );
};

import React, { useState } from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Button, Chip, IconButton, Typography } from '@mui/material';
import { Translate, translate } from 'react-jhipster';
import { East, Stream, VisibilityOff, WarningAmber } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { sitemap } from 'app/shared/util/url-utils';
import { useWebAnalyticsStatus } from '../hooks/useWebAnalyticsStatus';

export const ClickStreamConfig = () => {
  const status = useWebAnalyticsStatus();

  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.click-stream-configuration">Click Stream Configuration</Translate>
            </Typography>
          ),
          avatar: <Stream color="primary" />,
          disableTypography: true,
          action: status && (
            <Chip
              label={`${translate('account.account-page.status', null, 'Status')}: ${status.replace(/_/g, ' ')}`}
              variant="outlined"
              icon={status.startsWith('NOT_READY') ? <WarningAmber color="warning" fontSize="small" /> : null}
              color={status.startsWith('READY') ? 'success' : 'error'}
            />
          ),
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
            transition: 'all 500ms linear',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        ...(status &&
          status.startsWith('NOT_READY') && {
            actions: (
              <Button size="small" variant="outlined" component={Link} to={`${sitemap['web-analytics'].settings}`} endIcon={<East />}>
                <Translate contentKey="module-factory.accordion.go-to-settings">Go to settings</Translate>
              </Button>
            ),
          }),
      }}
    >
      {status && (
        <Typography variant="subtitle1">
          {status.startsWith('NOT_READY') ? (
            <Translate contentKey="module-factory.accordion.click-stream-configuration-text">
              To collect the clickstream, a server endpoint must be configured. Please provide your settings using the dedicated form.
            </Translate>
          ) : (
            <Translate contentKey="module-factory.accordion.click-stream-configuration-ready">
              Your clickstream endpoint is configured and ready to use.
            </Translate>
          )}
        </Typography>
      )}
    </LayoutItem>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { serializeAxiosError } from '../../../reducer.utils';

const generateCSVUrl = DATA_HUB_API_URL + '/download/csv/generate';

const initialState = {
  errorMessage: null as unknown as string, // Errors returned from server side
  loading: false,

  fileName: null,
};

// Actions
export const generateCSVFile = createAsyncThunk(
  'webAnalyticsTools/generateCSVFile',
  async ({ from, to, filename }: { from: string; to: string; filename: string }) => {
    const fromParams = from !== '' ? from + '%7C%7C' : null;
    const toParams = to !== '' ? to + '%7C%7C' : null;
    let url = `${generateCSVUrl}`;
    if (from && from !== '') {
      url = url + `?from=${fromParams}`;
      if (toParams) {
        url = url + `&to=${toParams}`;
      }
      if (filename !== '' && filename !== 'webevents') {
        url = url + `&filename=${filename}`;
      }
    } else if (to && to !== '') {
      url = url + `?to=${toParams}`;
      if (filename !== '' && filename !== 'webevents') {
        url = url + `&filename=${filename}`;
      }
    } else if (filename !== '' && filename !== 'webevents') {
      url = url + `?filename=${filename}`;
    }
    return microserviceHttpClient(url).then(resp => resp.data);
  },
  { serializeError: serializeAxiosError }
);

export type GenerateCSVData = Readonly<typeof initialState>;

// Slice
export const GenerateCSVSlice = createSlice({
  name: 'generateCSV',
  initialState: initialState as GenerateCSVData,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(generateCSVFile.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(generateCSVFile.fulfilled, (state, action) => {
        state.loading = false;
        state.fileName = action.payload;
      })
      .addCase(generateCSVFile.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.fileName = null;
      });
  },
});

export default GenerateCSVSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { RECO_ENGINE_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IItems } from 'app/shared/model/recommendations/recommendations.model';

const recommendationsUrl = RECO_ENGINE_API_URL + '/items';

export const initialState = {
  recommendationsLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  similar: null as Array<IItems>,
};

export type RecoRecommendationsState = Readonly<typeof initialState>;

// Actions

export const getRecommendationsForItem = createAsyncThunk(
  'reco/getRecommendationsForItem',
  async ({ corpus, itemId }: { corpus: any; itemId: any }) =>
    microserviceHttpClient(`${recommendationsUrl}/${itemId}/similar-items?corpus=${corpus}`, 'post'),
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const recoRecommendationsSlice = createSlice({
  name: 'recommendationsForItem',
  initialState: initialState as RecoRecommendationsState,
  reducers: {
    updateSimilar(state, action) {
      state.similar = action.payload as Array<IItems>;
    },
    invalidateSimilar(state) {
      state.similar = null as Array<IItems>;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRecommendationsForItem.pending, state => {
        state.recommendationsLoading = true;
        state.errorMessage = null;
      })
      .addCase(getRecommendationsForItem.fulfilled, (state, action) => {
        state.recommendationsLoading = false;
        state.similar = action.payload.data as Array<IItems>;
      })
      .addCase(getRecommendationsForItem.rejected, (state, action) => {
        state.recommendationsLoading = false;
        state.errorMessage = action.error.message;
        state.similar = null as Array<IItems>;
      });
  },
});

export const { updateSimilar, invalidateSimilar } = recoRecommendationsSlice.actions;

// Reducer
export default recoRecommendationsSlice.reducer;

import { yupResolver } from '@hookform/resolvers/yup';
import { FieldProp, validationUtils } from 'app/modules/form-builder-lib';
import { useForm } from 'react-hook-form';
import { translate } from 'react-jhipster';
import { recommenderStrategy } from '../../utils/recommender-strategy';
import { useCorpusListQuery } from '../../../hooks/useCorpusListQuery';

export const useRecoParamsForm = values => {
  const { corpusList, isSuccess } = useCorpusListQuery();

  const getFields = (): Array<FieldProp> => {
    return [
      {
        component: 'autocomplete',
        options: corpusList.sort(),
        attribute: 'corpusId',
        label: 'Corpus ID',
      },
      {
        component: 'select',
        options: recommenderStrategy.map(item => {
          return {
            value: item,
            label: item,
          };
        }),
        attribute: 'strategy',
        label: translate('recommendation.form-attributes.strategy', undefined, 'Strategy'),
        optionConfig: { value: 'value', key: 'value', label: 'label' },
      },
      {
        component: 'counter',
        attribute: 'maxItemCount',
        label: 'Max Item Count',
        inputMin: 1,
        inputMax: 100,
      },
      {
        component: 'text-field',
        attribute: 'itemId',
        label: translate('recommendation.form-attributes.itemId', undefined, 'Item Id'),
      },
      {
        component: 'text-field',
        attribute: 'profileId',
        label: translate('recommendation.form-attributes.profileId', undefined, 'Profile Id'),
      },
    ];
  };

  const schema = validationUtils.getFormSchema(getFields());

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: values,
    values,
  });

  return {
    getFields,
    corpusList,
    methods,
    isSuccess,
  };
};

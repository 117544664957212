export const trendChartList = [
  {
    title: 'energy-impact_kwh',
    key: 'energy_impact_kwh',
    scope: 'country'
  },
  {
    title: 'co2-eq_kg',
    key: 'co2_eq_kg',
    scope: 'country'
  },
  {
    title: 'page-size_bytes',
    key: 'page_size_bytes',
    scope: 'metric'
  },
  {
    title: 'avg-page-size_bytes',
    key: 'avg_page_size_bytes',
    scope: 'country'
  },
  {
    title: 'co2-eq-by-page_kg',
    key: 'co2_eq_by_page_kg',
    scope: 'country'
  },
  {
    title: 'page-views',
    key: 'page_views',
    scope: 'country'
  },
  {
    title: 'avg-time-on-page_sec',
    key: 'avg_time_on_page_sec',
    scope: 'metric'
  },
  {
    title: 'transferred-bytes',
    key: 'transferred_bytes',
    scope: 'country'
  },
];

import React from 'react';
import { BigNumberCard } from './BigNumberCard';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Stack } from '@mui/material';
import { ThumbUpOffAlt, Visibility, Comment, FavoriteBorder, ShoppingBasket, Share } from '@mui/icons-material';
import humanNumber from 'human-number';

export const ActionsNumbers = ({ data }) => {
  return (
    <BasicCardLayout content={{ sx: { p: 2, '&:last-child': { p: 2 } } }}>
      <Stack direction="row" justifyContent="space-around">
        {[
          { icon: <Visibility sx={{ fontSize: 50 }} color="secondary" />, name: 'View' },
          { icon: <ThumbUpOffAlt sx={{ fontSize: 50 }} color="secondary" />, name: 'Like' },
          { icon: <Comment sx={{ fontSize: 50 }} color="secondary" />, name: 'Comment' },
          { icon: <FavoriteBorder sx={{ fontSize: 50 }} color="secondary" />, name: 'Favorite' },
          { icon: <ShoppingBasket sx={{ fontSize: 50 }} color="secondary" />, name: 'Basket' },
          { icon: <Share sx={{ fontSize: 50 }} color="secondary" />, name: 'Share' },
        ].map((interaction, index) => {
          const interactionValue = data.actions.actionBuckets?.find(bucket => bucket.key === interaction.name.toUpperCase());

          return (
            <BigNumberCard
              key={index}
              variant={'div'}
              icon={interaction.icon}
              cardHeaderProps={{ sx: { p: 0, '&:last-child': { p: 0 } } }}
              options={{
                value: interaction.name,
                key: interactionValue ? humanNumber(interactionValue.doc_count, n => Number.parseFloat(n).toFixed(1)) : 0,
              }}
            />
          );
        })}
      </Stack>
    </BasicCardLayout>
  );
};

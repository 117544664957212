import { createSlice } from '@reduxjs/toolkit';

export const APPLICATIONS = 'Applications';
export const MANAGEMENT = 'Management';
export const SETTINGS = 'settings';
export const DASHBOARDS = 'dashboard';

type menubarState = {
  expanded: boolean;
  homeView: 'Applications' | 'Management';

  ['web-analytics']: 'settings' | 'dashboard';
  recommendations: 'settings' | 'dashboard';
  greensights: 'settings' | 'dashboard';
};

const initialState: menubarState = {
  expanded: false,
  homeView: APPLICATIONS,

  ['web-analytics']: 'settings',
  recommendations: 'settings',
  greensights: 'settings',
};

export type MenubarState = Readonly<typeof initialState>;

export const MenubarSlice = createSlice({
  name: 'menubar',
  initialState: initialState as MenubarState,
  reducers: {
    setExpanded(state, action) {
      state.expanded = action.payload;
    },
    setHomeView(state, action) {
      state.homeView = action.payload;
    },
    setWebAnalyticsView(state, action) {
      state['web-analytics'] = action.payload;
    },
    setRecoView(state, action) {
      state.recommendations = action.payload;
    },
    setGreensightsView(state, action) {
      state.greensights = action.payload;
    },
  },
});

export const { setExpanded, setHomeView, setWebAnalyticsView, setRecoView, setGreensightsView } = MenubarSlice.actions;

export default MenubarSlice.reducer;

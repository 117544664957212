import { useAppSelector } from 'app/config/store';

const hasAnyAuthority = (accountAuthorities: string[], hasAnyAuthorities: string[] = []) => {
  if (hasAnyAuthorities.length > 0) {
    if (accountAuthorities && accountAuthorities.length > 0) {
      return hasAnyAuthorities.some(auth => accountAuthorities.includes(auth));
    }
    return false;
  }
  return true;
};

export const useRequireAuth = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const sessionHasBeenFetched = useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const accountAuthorities = useAppSelector(state => state.authentication.account.authorities);
  const isAuthorized = hasAnyAuthority(accountAuthorities);

  return { isAuthenticated, sessionHasBeenFetched, isAuthorized };
};

import React, { useState } from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Button, Typography, Link, IconButton } from '@mui/material';
import { Translate } from 'react-jhipster';
import { East, FileDownload, FileUploadOutlined, HighlightOff, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { RouterLink } from 'app/config/modules/react-router-dom';
import { sitemap } from 'app/shared/util/url-utils';
import { useExportForm } from 'app/modules/views/web-analytics-tools/export/hooks/useExportForm';
import { FormBuilder } from 'app/modules/form-builder-lib';
import { getFormFields } from 'app/modules/views/web-analytics-tools/export/components/form/formFields';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { useDeleteCsv } from 'app/modules/views/web-analytics-tools/export/hooks/state/useDeleteCsv';
import { useAppDispatch } from 'app/config/store';
import { getListCSVFiles } from 'app/shared/reducers/web-analytics/tools/export/list';

const FileManagementButtons = ({ fileName, setFileDownload }) => {
  const { loading: deleteLoading, deleteCSVFile } = useDeleteCsv(fileName);
  const dispatch = useAppDispatch();

  return (
    <>
      <LoadingButton
        key="download"
        loadingPosition="start"
        color="primary"
        size="small"
        variant="outlined"
        startIcon={<FileDownload />}
        LinkComponent={Link}
        href={`${DATA_HUB_API_URL + '/download/csv'}/${fileName}`}
        download={fileName}
        target="_blank"
        onClick={() => {
          setFileDownload(null);
        }}
        sx={{ mr: 1 }}
      >
        <Translate contentKey="account.download">Download</Translate>
      </LoadingButton>
      <LoadingButton
        key="delete"
        loading={deleteLoading}
        loadingPosition="start"
        color="error"
        size="small"
        variant="outlined"
        startIcon={<HighlightOff />}
        onClick={() => {
          deleteCSVFile()
            .then(res => {
              dispatch(getListCSVFiles());
            })
            .then(del => setFileDownload(null));
        }}
      >
        <Translate contentKey="web-analytics.export.columns.delete">Delete</Translate>
      </LoadingButton>
    </>
  );
};

export const ExportRawData = () => {
  const { loading, methods, defaultValue, onSubmit, fileDownload, setFileDownload } = useExportForm();

  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.export-raw-analytics-data">Export Raw Analytics Data</Translate>
            </Typography>
          ),
          avatar: <FileUploadOutlined color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
            transition: 'all 500ms linear',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        actions: (
          <>
            {fileDownload ? (
              <FileManagementButtons fileName={fileDownload} setFileDownload={setFileDownload} />
            ) : (
              <LoadingButton
                startIcon={<FileDownload />}
                size="small"
                variant="contained"
                color="primary"
                loading={loading}
                loadingPosition="start"
                onClick={() => {
                  onSubmit();
                }}
                key="export"
              >
                <Translate contentKey="web-analytics.export.export">Export</Translate>
              </LoadingButton>
            )}
            <Button
              size="small"
              sx={{ ml: 2 }}
              variant="outlined"
              component={RouterLink}
              to={`${sitemap['web-analytics'].export}`}
              endIcon={<East />}
              key="export-page"
            >
              <Translate contentKey="web-analytics.export.export-page">Export page</Translate>
            </Button>
          </>
        ),
      }}
    >
      <Typography variant="subtitle1" mb={4}>
        <Translate contentKey="module-factory.accordion.export-raw-analytics-data-text">
          Export your analytics raw data to use it for more exploration.
        </Translate>
      </Typography>
      <FormBuilder fields={getFormFields(methods)} defaultValue={defaultValue} methods={methods} />
    </LayoutItem>
  );
};

import React from 'react';

import { GroupedListLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { ServiceAccount } from './accordion-items/ServiceAccount';
import { Dashboard } from './accordion-items/Dashboard';

export const GreensightsAccordion = () => {
  return (
    <GroupedListLayout>
      <ServiceAccount />
      <Dashboard />
    </GroupedListLayout>
  );
};

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { trendChartList } from './trendChartList';
import { Typography } from '@mui/material';
import { Translate, translate } from 'react-jhipster';
import TrendChart from '.';
import { KPIS } from 'app/shared/util/reducer-utils';
import { getDataExplorationSelectedChart, setDataExplorationSelectedChart } from 'app/shared/reducers/views/data-exploration';
import { useDataExplorationKpisHistory } from '../../hooks/useDataExplorationKpisHistory';

export const TrendsGrid = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const selectedChart = useAppSelector(getDataExplorationSelectedChart);
  const { kpisHistoryInTimerangeData, error, isFetching, isError } = useDataExplorationKpisHistory();

  return (
    <GridLayout columns={8} spacing={3} width="100%">
      {trendChartList.map(trendChart => {
        return (
          <LayoutItem
            cardOptions={{
              card: {
                sx: {
                  cursor: 'pointer',
                  ...(selectedChart.name === trendChart.kpi && {
                    borderStyle: 'solid',
                    borderWidth: 'thin',
                    borderColor: 'secondary.dark',
                    boxShadow: `0 0 5px ${theme.palette.secondary.dark} inset`,
                  }),
                },
                onClick() {
                  dispatch(setDataExplorationSelectedChart(KPIS[trendChart.kpi]));
                },
              },
              header: {
                title: (
                  <Typography
                    variant="h4"
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 0 }}
                    title={translate(`open-analytics.chart.${trendChart.title}`)}
                    p={1}
                  >
                    <Translate contentKey={`open-analytics.chart.${trendChart.title}`}>{trendChart.title}</Translate>
                  </Typography>
                ),
                sx: {
                  '.MuiCardHeader-content': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 1 },
                  p: 0,
                },
              },
              content: { sx: { height: '100px', p: 0, '&:last-child': { p: 0 } } },
            }}
            gridItemProps={{ xs: 4, lg: 1, sm: 2 }}
            key={trendChart.title}
          >
            <TrendChart
              data={kpisHistoryInTimerangeData[trendChart.kpi]}
              kpi={trendChart.kpi}
              selectedChart={selectedChart}
              isFetching={isFetching}
              isError={isError}
              error={error}
            />
          </LayoutItem>
        );
      })}
    </GridLayout>
  );
};

import { enqueueSnackbar } from 'notistack';
import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    createSubscriptionUsingPost: build.mutation<CreateSubscriptionUsingPostApiResponse, CreateSubscriptionUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/subscriptions`,
        method: 'POST',
        body: queryArg.subscriptionDto,
        params: { entitlementDate: queryArg.entitlementDate, billingDate: queryArg.billingDate },
      }),
      invalidatesTags: ['SUBSCRIPTION_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar('subscription created successfully !', { variant: 'success' });
        } catch (err) {
          enqueueSnackbar(`Async error! : failed to create a subscription `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    getSubscriptionByIdUsingGet: build.query<GetSubscriptionByIdUsingGetApiResponse, GetSubscriptionByIdUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/billing/subscriptions/${queryArg.subscriptionId}` }),
    }),
    changeSubscriptionPlanUsingPut: build.mutation<ChangeSubscriptionPlanUsingPutApiResponse, ChangeSubscriptionPlanUsingPutApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/subscriptions/${queryArg.subscriptionId}`,
        method: 'PUT',
        body: queryArg.subscriptionDto,
        params: { requestedDate: queryArg.requestedDate, billingActionPolicy: queryArg.billingActionPolicy },
      }),
    }),
    cancelSubscriptionUsingDelete: build.mutation<CancelSubscriptionUsingDeleteApiResponse, CancelSubscriptionUsingDeleteApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/subscriptions/${queryArg.subscriptionId}`,
        method: 'DELETE',
        params: {
          requestedDateStr: queryArg.requestedDateStr,
          entitlementPolicy: queryArg.entitlementPolicy,
          billingActionPolicy: queryArg.billingActionPolicy,
          useRequestedDateForBilling: queryArg.useRequestedDateForBilling,
        },
      }),
      invalidatesTags: ['SUBSCRIPTION_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar('subscription canceled successfully !', { variant: 'success' });
        } catch (err) {
          enqueueSnackbar(`Async error! : failed to cancel a subscription `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as subscriptionApi };
export type CreateSubscriptionUsingPostApiResponse = /** status 200 OK */ SubscriptionDto | /** status 201 Created */ undefined;
export type CreateSubscriptionUsingPostApiArg = {
  /** entitlementDate */
  entitlementDate?: string;
  /** billingDate */
  billingDate?: string;
  subscriptionDto: SubscriptionDto;
};
export type GetSubscriptionByIdUsingGetApiResponse = /** status 200 OK */ SubscriptionDto;
export type GetSubscriptionByIdUsingGetApiArg = {
  /** subscriptionId */
  subscriptionId: string;
};
export type ChangeSubscriptionPlanUsingPutApiResponse = unknown;
export type ChangeSubscriptionPlanUsingPutApiArg = {
  /** subscriptionId */
  subscriptionId: string;
  /** requestedDate */
  requestedDate?: string;
  /** billingActionPolicy */
  billingActionPolicy?: 'END_OF_TERM' | 'ILLEGAL' | 'IMMEDIATE' | 'START_OF_TERM';
  subscriptionDto: SubscriptionDto;
};
export type CancelSubscriptionUsingDeleteApiResponse = unknown;
export type CancelSubscriptionUsingDeleteApiArg = {
  /** subscriptionId */
  subscriptionId: string;
  /** requestedDateStr */
  requestedDateStr?: string;
  /** entitlementPolicy */
  entitlementPolicy?: 'END_OF_TERM' | 'IMMEDIATE';
  /** billingActionPolicy */
  billingActionPolicy?: 'END_OF_TERM' | 'ILLEGAL' | 'IMMEDIATE' | 'START_OF_TERM';
  /** useRequestedDateForBilling */
  useRequestedDateForBilling?: boolean;
};
export type AuditLog = {
  changeDate?: string;
  changeType?: string;
  changedBy?: string;
  comments?: string;
  history?: object;
  objectId?: string;
  objectType?:
    | 'ACCOUNT'
    | 'ACCOUNT_EMAIL'
    | 'BLOCKING_STATES'
    | 'BUNDLE'
    | 'CUSTOM_FIELD'
    | 'INVOICE'
    | 'INVOICE_ITEM'
    | 'INVOICE_PAYMENT'
    | 'PAYMENT'
    | 'PAYMENT_ATTEMPT'
    | 'PAYMENT_METHOD'
    | 'SERVICE_BROADCAST'
    | 'SUBSCRIPTION'
    | 'SUBSCRIPTION_EVENT'
    | 'TAG'
    | 'TAG_DEFINITION'
    | 'TENANT'
    | 'TENANT_KVS'
    | 'TRANSACTION';
  reasonCode?: string;
  userToken?: string;
};
export type EventSubscription = {
  auditLogs?: AuditLog[];
  billingPeriod?:
    | 'ANNUAL'
    | 'BIANNUAL'
    | 'BIENNIAL'
    | 'BIMESTRIAL'
    | 'BIWEEKLY'
    | 'DAILY'
    | 'MONTHLY'
    | 'NINETY_DAYS'
    | 'NO_BILLING_PERIOD'
    | 'QUARTERLY'
    | 'SIXTY_DAYS'
    | 'THIRTY_DAYS'
    | 'TRIANNUAL'
    | 'WEEKLY';
  effectiveDate?: string;
  eventId?: string;
  eventType?:
    | 'CHANGE'
    | 'PAUSE_BILLING'
    | 'PAUSE_ENTITLEMENT'
    | 'PHASE'
    | 'RESUME_BILLING'
    | 'RESUME_ENTITLEMENT'
    | 'SERVICE_STATE_CHANGE'
    | 'START_BILLING'
    | 'START_ENTITLEMENT'
    | 'STOP_BILLING'
    | 'STOP_ENTITLEMENT';
  isBlockedBilling?: boolean;
  isBlockedEntitlement?: boolean;
  phase?: string;
  plan?: string;
  priceList?: string;
  product?: string;
  serviceName?: string;
  serviceStateName?: string;
};
export type BlockPrice = {
  max?: number;
  price?: number;
  size?: number;
  unitName?: string;
};
export type TierPrice = {
  blockPrices?: BlockPrice[];
};
export type UsagePrice = {
  billingMode?: 'IN_ADVANCE' | 'IN_ARREAR';
  tierBlockPolicy?: 'ALL_TIERS' | 'TOP_TIER';
  tierPrices?: TierPrice[];
  usageName?: string;
  usageType?: 'CAPACITY' | 'CONSUMABLE';
};
export type PhasePrice = {
  fixedPrice?: number;
  phaseName?: string;
  phaseType?: string;
  planName?: string;
  recurringPrice?: number;
  usagePrices?: UsagePrice[];
};
export type SubscriptionDto = {
  accountExternalKey?: string;
  auditLogs?: AuditLog[];
  billCycleDayLocal?: number;
  billingEndDate?: string;
  billingPeriod?:
    | 'ANNUAL'
    | 'BIANNUAL'
    | 'BIENNIAL'
    | 'BIMESTRIAL'
    | 'BIWEEKLY'
    | 'DAILY'
    | 'MONTHLY'
    | 'NINETY_DAYS'
    | 'NO_BILLING_PERIOD'
    | 'QUARTERLY'
    | 'SIXTY_DAYS'
    | 'THIRTY_DAYS'
    | 'TRIANNUAL'
    | 'WEEKLY';
  billingStartDate?: string;
  bundleExternalKey?: string;
  bundleId?: string;
  cancelledDate?: string;
  chargedThroughDate?: string;
  events?: EventSubscription[];
  externalKey?: string;
  phaseType?: 'DISCOUNT' | 'EVERGREEN' | 'FIXEDTERM' | 'TRIAL';
  planName?: string;
  priceList?: string;
  priceOverrides?: PhasePrice[];
  prices?: PhasePrice[];
  productCategory?: 'ADD_ON' | 'BASE' | 'STANDALONE';
  productName?: string;
  sourceType?: 'MIGRATED' | 'NATIVE' | 'TRANSFERRED';
  startDate?: string;
  state?: 'ACTIVE' | 'BLOCKED' | 'CANCELLED' | 'EXPIRED' | 'PENDING';
  subscriptionId?: string;
};
export const {
  useCreateSubscriptionUsingPostMutation,
  useGetSubscriptionByIdUsingGetQuery,
  useChangeSubscriptionPlanUsingPutMutation,
  useCancelSubscriptionUsingDeleteMutation,
} = injectedRtkApi;

import { Add, Check, Close, CopyAll, Delete, Edit, Replay } from '@mui/icons-material';
import { Autocomplete, Button, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useAppDispatch } from 'app/config/store';
import StandardTextField from 'app/modules/form-builder-lib/Components/StandardTextField';
import { resetRecoWidget } from 'app/shared/reducers/views/recommendations/widget';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useLocalStorage from '../hooks/storage/useLocalStorage';
import { iconSxHover, iconSxHoverError, iconSxHoverSuccess } from '../utils/CommonPopoverStyles';
import { EmbedCodeWidget } from './EmbedCodeWidget';

export const WidgetMenuBar = ({ handleExpand, isCollapsed }) => {
  const dispatch = useAppDispatch();
  const { widgetIds, widgetId, handleSelectWidget, handleCreateWidget, handleDuplicateWidget, handleRenameWidget, handleDeleteWidget } =
    useLocalStorage();
  const toOption = opt => ({ label: opt.split('|')[1], id: opt });

  const methods = useForm({
    defaultValues: { isEditMode: false } as any,
  });
  const { setValue, watch, handleSubmit } = methods;
  const isEditMode = watch('isEditMode');

  useEffect(() => setValue('name', toOption(widgetId).label, { shouldDirty: false }), [widgetId]);

  return (
    <Stack direction={'row'} spacing={1} display={'flex'} alignItems={'center'} marginBottom={2}>
      <Tooltip title={'New widget'}>
        <IconButton sx={iconSxHover} size="small" onClick={handleCreateWidget}>
          <Add />
        </IconButton>
      </Tooltip>

      <Tooltip title={'Duplicate widget'}>
        <IconButton sx={iconSxHover} size="small" onClick={handleDuplicateWidget}>
          <CopyAll />
        </IconButton>
      </Tooltip>

      {isEditMode ? (
        <StandardTextField
          methods={methods}
          field={{
            component: 'text-field',
            attribute: 'name',
            label: 'Widget ID',
            props: {
              sx: { width: '350px' },
            },
          }}
        />
      ) : (
        <Autocomplete
          sx={{ width: '350px' }}
          disableClearable
          size="small"
          options={widgetIds.map(toOption)}
          value={toOption(widgetId)}
          renderInput={params => <TextField {...params} label="Widget ID" />}
          onChange={(event, value) => {
            handleSelectWidget(value.id);
          }}
        />
      )}

      {isEditMode && (
        <Tooltip title={'Cancel'}>
          <IconButton sx={iconSxHoverError} size="small" onClick={() => setValue('isEditMode', false)}>
            <Close />
          </IconButton>
        </Tooltip>
      )}

      {isEditMode ? (
        <Tooltip title={'Submit'}>
          <IconButton
            sx={iconSxHoverSuccess}
            size="small"
            onClick={() => {
              handleSubmit(data => data.name && data.name !== toOption(widgetId) && handleRenameWidget(data.name))();
              setValue('isEditMode', false);
            }}
          >
            <Check />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={'Change widget ID'}>
          <IconButton sx={iconSxHover} size="small" onClick={() => setValue('isEditMode', true)}>
            <Edit />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={'Reset widget'}>
        <IconButton sx={iconSxHover} size="small" onClick={() => dispatch(resetRecoWidget())}>
          <Replay />
        </IconButton>
      </Tooltip>

      <Tooltip title={'Delete widget'}>
        <IconButton sx={iconSxHoverError} size="small" onClick={handleDeleteWidget}>
          <Delete />
        </IconButton>
      </Tooltip>

      <Box flexGrow={1} />

      {isCollapsed && (
        <Button variant="outlined" size="small" onClick={handleExpand}>
          Preview
        </Button>
      )}

      <EmbedCodeWidget />
    </Stack>
  );
};

import { ifExistThen } from 'app/modules/layout-builder-lib/functions/generics/ifExistThen';
import { merge } from 'lodash';
import { BasicCardLayoutProps, ClickableCardContentLayoutProps, CoreCardLayoutProps } from '../../components/layout/card/BasicCardLayout';

export function toCardContentLayoutProps(
  { actionArea, media, content, children }: BasicCardLayoutProps,
  { actionArea: variantActionArea, media: variantMedia, content: variantContentProps }: CoreCardLayoutProps
): ClickableCardContentLayoutProps {
  return {
    actionArea: ifExistThen(actionArea, { ...merge({}, variantActionArea, actionArea) }),
    media: ifExistThen(media, { ...merge({}, variantMedia, media) }),
    content: ifExistThen(content, { ...merge({}, variantContentProps, content) }),
    children,
  };
}

import React from 'react';
import AccordionExample from './Accordion';
import AppBarExample from './AppBar';
import AvatarExample from './Avatar';
import BadgeExample from './Badge';
import BottomNavigationExample from './BottomNavigation';
import ButtonExample from './Button';
import CardExample from './Card';
import CheckboxesExample from './Checkboxes';
import ChipExample from './Chip';
import DialogExample from './Dialog';
import FabExample from './FAB';
import IconExample from './Icon';
import ListExample from './List';
import MenuExample from './Menu';
import ProgressExample from './Progress';
import RadioExample from './RadioGroup';
import SelectExample from './Select';
import SliderExample from './Slider';
import SnackbarExample from './Snackbar';
import StepperExample from './Stepper';
import SwitchExample from './Switch';
import TableExample from './Table';
import TabsExample from './Tabs';
import TextFieldExample from './TextField';
import TooltipExample from './Tooltip';
import TypographyExample from './Typography';

// items skipped for now:
// Button Group
// Date/Time inputs
// Transfer List
// Drawer
// Datagrid

// Alphabetically sorted list of all Material-UI component examples
export default [
  {
    id: 'Accordion',
    title: 'Accordion',
    component: <AccordionExample />,
    docs: 'https://material-ui.com/components/accordion/',
  },
  {
    id: 'Appbar',
    title: 'App Bar',
    component: <AppBarExample />,
    docs: 'https://material-ui.com/components/app-bar/',
  },
  {
    id: 'Avatar',
    title: 'Avatar',
    component: <AvatarExample />,
    docs: 'https://material-ui.com/components/avatars/',
  },
  {
    id: 'Badge',
    title: 'Badge',
    component: <BadgeExample />,
    docs: 'https://material-ui.com/components/badges/',
  },
  {
    id: 'BottomNavigation',
    title: 'Bottom Navigation',
    component: <BottomNavigationExample />,
    docs: 'https://material-ui.com/components/bottom-navigation/',
  },
  {
    id: 'Buttons',
    title: 'Buttons',
    component: <ButtonExample />,
    docs: 'https://material-ui.com/components/buttons/',
  },
  {
    id: 'Card',
    title: 'Card',
    component: <CardExample />,
    docs: 'https://material-ui.com/components/cards/',
  },
  {
    id: 'Checkboxes',
    title: 'Checkboxes',
    component: <CheckboxesExample />,
    docs: 'https://material-ui.com/components/checkboxes/',
  },
  {
    id: 'Chip',
    title: 'Chip',
    component: <ChipExample />,
    docs: 'https://material-ui.com/components/chips/',
  },
  {
    id: 'Dialog',
    title: 'Dialog',
    component: <DialogExample />,
    docs: 'https://material-ui.com/components/dialogs/',
  },
  {
    id: 'FloatingActionButton',
    title: 'Floating Action Button',
    component: <FabExample />,
    docs: 'https://material-ui.com/components/floating-action-button/',
  },
  {
    id: 'Icon',
    title: 'Icon',
    component: <IconExample />,
    docs: 'https://material-ui.com/components/icons/',
  },
  {
    id: 'List',
    title: 'List',
    component: <ListExample />,
    docs: 'https://material-ui.com/components/lists/',
  },
  {
    id: 'Menu',
    title: 'Menu',
    component: <MenuExample />,
    docs: 'https://material-ui.com/components/menus/',
  },
  {
    id: 'Progress',
    title: 'Progress',
    component: <ProgressExample />,
    docs: 'https://material-ui.com/components/progress/',
  },
  {
    id: 'Radio',
    title: 'Radio',
    component: <RadioExample />,
    docs: 'https://material-ui.com/components/radio-buttons/',
  },
  {
    id: 'Select',
    title: 'Select',
    component: <SelectExample />,
    docs: 'https://material-ui.com/components/selects/',
  },
  {
    id: 'Slider',
    title: 'Slider',
    component: <SliderExample />,
    docs: 'https://material-ui.com/components/slider/',
  },
  {
    id: 'Snackbar',
    title: 'Snackbar',
    component: <SnackbarExample />,
    docs: 'https://material-ui.com/components/snackbars/',
  },
  {
    id: 'Stepper',
    title: 'Stepper',
    component: <StepperExample />,
    docs: 'https://material-ui.com/components/steppers/',
  },
  {
    id: 'Switch',
    title: 'Switch',
    component: <SwitchExample />,
    docs: 'https://material-ui.com/components/switches/',
  },
  {
    id: 'Table',
    title: 'Table',
    component: <TableExample />,
    docs: 'https://material-ui.com/components/tables/',
  },
  {
    id: 'Tabs',
    title: 'Tabs',
    component: <TabsExample />,
    docs: 'https://material-ui.com/components/tabs/',
  },
  {
    id: 'TextField',
    title: 'TextField',
    component: <TextFieldExample />,
    docs: 'https://material-ui.com/components/text-fields/',
  },
  {
    id: 'Tooltip',
    title: 'Tooltip',
    component: <TooltipExample />,
    docs: 'https://material-ui.com/components/tooltips/',
  },
  {
    id: 'Typography',
    title: 'Typography',
    component: <TypographyExample />,
    docs: 'https://material-ui.com/components/typography/',
  },
];

import { createApi } from '@reduxjs/toolkit/query/react';
import { GREENSIGHTS_DATA_HUB_SERVICE_URL } from 'app/shared/api/constants';
import { axiosBaseQuery } from 'app/shared/api/microservices';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptyGreensightsDataHubCloudServicesApi = createApi({
  reducerPath: 'greensightsDataHubCloudServicesApi',
  baseQuery: axiosBaseQuery({ baseUrl: GREENSIGHTS_DATA_HUB_SERVICE_URL }),
  tagTypes: ['USER_PROFILE'],
  endpoints: () => ({}),
});

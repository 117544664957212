import { Brush, Css, FormatShapes, TextFormat } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { TabItemProps } from 'app/components/tabulation';
import { FieldProp } from 'app/modules/form-builder-lib';
import React from 'react';

export const typographyFields: FieldProp[] = [
  {
    attribute: 'font-family',
    component: 'text-field',
    label: 'Font family',
  },
  {
    attribute: 'font-size',
    component: 'text-field',
    label: 'Font size',
  },
  {
    attribute: 'font-weight',
    component: 'text-field',
    label: 'Font weight',
  },
  {
    attribute: 'font-style',
    component: 'text-field',
    label: 'Font style',
  },
  {
    attribute: 'color',
    component: 'text-field',
    label: 'Color',
  },
  {
    attribute: 'line-height',
    component: 'text-field',
    label: 'Line height',
  },
  {
    attribute: 'letter-spacing',
    component: 'text-field',
    label: 'Letter spacing',
  },
  {
    attribute: 'text-align',
    component: 'text-field',
    label: 'Text align',
  },
  {
    attribute: 'text-decoration',
    component: 'text-field',
    label: 'Text decoration',
  },
  {
    attribute: 'text-shadow',
    component: 'text-field',
    label: 'Text shadow',
  },
  // {
  //   attribute: 'text-transform',
  //   component: 'text-field',
  //   label: 'Text transform',
  // },
];

export const dimensionFields: FieldProp[] = [
  {
    attribute: 'width',
    component: 'text-field',
    label: 'Width',
  },
  {
    attribute: 'height',
    component: 'text-field',
    label: 'Height',
  },
  {
    attribute: 'max-width',
    component: 'text-field',
    label: 'Max Width',
  },
  {
    attribute: 'min-height',
    component: 'text-field',
    label: 'Min Height',
  },
  {
    attribute: 'margin',
    component: 'text-field',
    label: 'Margin',
  },
  {
    attribute: 'padding',
    component: 'text-field',
    label: 'Padding',
  },
];

export const decorationsFields: FieldProp[] = [
  {
    attribute: 'opacity',
    component: 'text-field',
    label: 'Opacity',
  },
  {
    attribute: 'border-radius',
    component: 'text-field',
    label: 'Border Radius',
  },
  {
    attribute: 'border',
    component: 'text-field',
    label: 'Border',
  },
  {
    attribute: 'box-shadow',
    component: 'text-field',
    label: 'Box Shadows',
  },
  {
    attribute: 'background',
    component: 'text-field',
    label: 'Background',
  },
];

// export const extraFields: FieldProp[] = [
//   {
//     attribute: 'opacity',
//     component: 'text-field',
//     label: 'Opacity',
//   },
//   {
//     attribute: 'border-radius',
//     component: 'text-field',
//     label: 'Border Radius',
//   },
//   {
//     attribute: 'border',
//     component: 'text-field',
//     label: 'Border',
//   },
//   {
//     attribute: 'box-shadow',
//     component: 'text-field',
//     label: 'Box Shadows',
//   },
//   {
//     attribute: 'background',
//     component: 'text-field',
//     label: 'Background',
//   },
// ];

export const cssStylesTabs: Partial<TabItemProps & { fields: FieldProp[] }>[] = [
  {
    tab: {
      value: 'dimension',
      icon: (
        <Tooltip title="Dimensions">
          <FormatShapes />
        </Tooltip>
      ),
    },
    fields: dimensionFields,
  },
  {
    tab: {
      value: 'typography',
      icon: (
        <Tooltip title="Typography">
          <TextFormat />
        </Tooltip>
      ),
    },
    fields: typographyFields,
  },
  {
    tab: {
      value: 'decorations',
      icon: (
        <Tooltip title="Decorations">
          <Brush />
        </Tooltip>
      ),
    },
    fields: decorationsFields,
  },
  // {
  //   tab: { value: 'extra', icon: <Tooltip title="Extra"><Css /></Tooltip> },
  //   fields: extraFields,
  // },
];

// export const gridStyleFields: FieldProp[] = [
//   {
//     attribute: 'grid-template-columns',
//     value: 'repeat(auto-fit, minmax(250px, 1fr))',
//     label: 'Grid Template Columns',
//   },
//   {
//     attribute: 'gap',
//     value: '20px',
//     label: 'Gap',
//   },
// ];

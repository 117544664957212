import React, { Fragment, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { FormControl, FormHelperText, InputLabel, ListItemIcon, ListSubheader, MenuItem, Select, SelectProps } from '@mui/material';

import { translate } from 'react-jhipster';
import { shuffleArray } from '../utils';
import { getSelectOptionFromConfig, SelectOption } from '../utils/options';
import { CommonFieldProps, MultiOptionFieldProps } from './props/FieldProps';
import { Title } from './Widgets/Title';

export interface StandardSelectProps<TOption> extends CommonFieldProps<'select', TOption> {
  attribute: Required<CommonFieldProps<'select', TOption>>['attribute'];
  options: MultiOptionFieldProps<TOption>['options'];
  optionConfig?: MultiOptionFieldProps<TOption>['optionConfig'];
  randomizeOptions?: MultiOptionFieldProps<TOption>['randomizeOptions'];
  enableNoneOption?: boolean;
}

export default function StandardSelect<TOption>(props: {
  field: StandardSelectProps<TOption>;
  methods: UseFormReturn;
  hideTitle?: boolean;
}) {
  const {
    field: fieldConfig,
    methods: {
      control,
      getValues,
      setValue,
      trigger,
      formState: { errors },
    },
    hideTitle,
  } = props;

  const options: Array<SelectOption> = useMemo(() => {
    let opt = fieldConfig.options || [];
    if (fieldConfig.randomizeOptions) {
      opt = shuffleArray(fieldConfig.options || []);
    }
    return opt.map(option => getSelectOptionFromConfig(option, fieldConfig.optionConfig));
  }, [fieldConfig.options, fieldConfig.optionConfig]);

  const componentProps = (fieldConf: StandardSelectProps<TOption>, value: string | number): SelectProps => {
    return {
      ...fieldConf.props,
      id: fieldConf.attribute,
      native: false,
      size: 'small',
      inputProps: {
        name: fieldConf.attribute,
        id: fieldConf.attribute,
      },
      value: fieldConf?.props?.value ? fieldConf.props.value : value ?? '',
      onChange(event) {
        setValue(fieldConf.attribute, event.target.value);
        if (fieldConf.props && fieldConf.props.onChange) fieldConf.props.onChange(event, false);
      },
      onBlur() {
        trigger(fieldConf.attribute);
      },
      label: fieldConf.label && translate(fieldConf.label, null, fieldConf.label),
      labelId: `${fieldConf.attribute}-label`,
    };
  };

  return (
    <Controller
      name={fieldConfig.attribute}
      control={control}
      render={({ field }) => (
        <Fragment>
          {!hideTitle && fieldConfig.title && <Title field={fieldConfig} />}
          <FormControl variant="outlined" fullWidth error={!!errors[fieldConfig.attribute]}>
            <InputLabel
              id={`${fieldConfig.attribute}-label`}
              sx={{
                // this is to set the label position correctly.
                // size is currently not a prop for InputLabel
                top: '-8px',
              }}
              htmlFor={fieldConfig.attribute}
            >
              {translate(fieldConfig.label, null, fieldConfig.label)}
            </InputLabel>
            <Select {...componentProps(fieldConfig, field.value)}>
              {fieldConfig.enableNoneOption && (
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              )}
              {fieldConfig.props && fieldConfig.props.children
                ? fieldConfig.props.children
                : options.map(option =>
                    option.isGroup ? (
                      <ListSubheader key={option.key}>{translate(option.label, null, option.label)}</ListSubheader>
                    ) : (
                      <MenuItem key={option.key} value={option.value}>
                        {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                        {translate(option.label, null, option.label)}
                      </MenuItem>
                    )
                  )}
            </Select>
            {errors[fieldConfig.attribute]?.message && (
              <FormHelperText>
                <>{errors[fieldConfig.attribute]?.message}</>
              </FormHelperText>
            )}
          </FormControl>
        </Fragment>
      )}
    />
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IUsers } from 'app/shared/model/users.model';
import { IUsersOverview } from 'app/shared/model/users-overview.model';

const usersScoresUrl = DATA_HUB_API_URL + '/scores/users';

export const initialState = {
  usersListLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  usersList: [] as IUsers[],
  usersOverviewWithScore: [] as IUsersOverview[],
};

export type UsersState = Readonly<typeof initialState>;

// Actions

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async ({ from, to, limit, filter }: { from: string; to: string; limit: number; filter: any }) => {
    if (limit !== 0)
      return microserviceHttpClient(
        `${usersScoresUrl}/timeframe?to=${to}&limit=${limit}&from=${from}`,
        'post',
        JSON.stringify({
          filters: filter,
        })
      );
    else
      return microserviceHttpClient(
        `${usersScoresUrl}/timeframe?to=${to}&from=${from}`,
        'post',
        JSON.stringify({
          filters: filter,
        })
      );
  },
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const UsersSlice = createSlice({
  name: 'users',
  initialState: initialState as UsersState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, state => {
        state.usersListLoading = true;
        state.errorMessage = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.usersListLoading = false;
        state.usersList = action.payload.data as IUsers[];
        state.usersOverviewWithScore = getUsersOverview(state.usersList) as IUsersOverview[];
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.usersListLoading = false;
        state.errorMessage = action.error.message;
        state.usersList = [] as Array<IUsers>;
        state.usersOverviewWithScore = [] as IUsersOverview[];
      });
  },
});

// Reducer
export default UsersSlice.reducer;

export const getUsersOverview = usersList => {
  return usersList.map(element => {
    return {
      id: element.id,
      userId: element.userId,
      engagementScore: element.engagementScore,
      sessionCount: Number(element.sessionCount).toFixed(),
      eventsCounterSum: Number(element.eventsCounterSum).toFixed(),
      pageviewsCounterSum: Number(element.pageviewsCounterSum).toFixed(),
      sessionDurationSum: Number(element.sessionDurationSum).toFixed(),
      bounceCount: Number(element.bounceCount).toFixed(),
      firstUserVisitEpochSeconds: element.firstUserVisitEpochSeconds,
      lastEventEpochSeconds: element.lastEventEpochSeconds,
      timeSinceLastVisit: element.timeSinceLastVisit,
      countrySplit: element.countrySplit[0] ? element.countrySplit[0].key : 'no data',
      citySplit: element.citySplit[0] ? element.citySplit[0].key : 'no data',
      continentSplit: element.continentSplit[0] ? element.continentSplit[0].key : 'no data',
      firstVisitedPageSplit: element.firstVisitedPageSplit[0] ? element.firstVisitedPageSplit[0].key : 'no data',
      lastVisitedPageSplit: element.lastVisitedPageSplit[0] ? element.lastVisitedPageSplit[0].key : 'no data',
      refererSplit: element.refererSplit[0] ? element.refererSplit[0].key : 'no data',
      fqdnSplit: element.fqdnSplit[0] ? element.fqdnSplit[0].key : 'no data',
      deviceCategorySplit: element.deviceCategorySplit[0] ? element.deviceCategorySplit[0].key : 'no data',
      browserFamilySplit: element.browserFamilySplit[0] ? element.browserFamilySplit[0].key : 'no data',
      osFamilySplit: element.osFamilySplit[0] ? element.osFamilySplit[0].key : 'no data',
    };
  });
};

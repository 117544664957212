import React from 'react';

import { reduceElementsAfterN } from 'app/shared/util/array-tools';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import { colorPaletteMode, getChartColorPalette } from 'app/shared/util/color-palette-calculator';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { WithData } from 'app/modules/loading-lib/WithData';
import { LoadingStackSkeleton } from 'app/modules/loading-lib/component/LoadingStackSkeleton';

const PieChart = ({
  dataset,
  data,
}: {
  dataset: {
    split: {
      key: string;
      count: number;
    }[];
    total: number;
  };
  data: any;
}) => {
  const theme = useTheme();

  const splitData = reduceElementsAfterN(
    dataset.split.map(element => {
      return { name: element.key, value: element.count };
    }),
    9
  );

  const config: EChartsOption = {
    color: getChartColorPalette(theme.palette.secondary.main, colorPaletteMode.MAIN_LIGHT_DARK),
    tooltip: {
      trigger: 'item',
      position: 'inside',
      formatter(params) {
        return `${params.name}<br/>${Number((params.value * 100) / dataset.total).toFixed(2)} %`;
      },
    },
    series: [
      {
        type: 'pie',
        stillShowZeroSum: true,
        radius: ['60%', '90%'],
        data: splitData,
        label: {
          show: false,
        },
      },
    ],
  };
  return <ReactECharts style={{ height: '100%' }} option={config} />;
};

export default WithLoading(WithData(PieChart), state => state.webAnalyticsOverview.pieCharts.loading, {
  loader() {
    return <LoadingStackSkeleton />;
  },
});

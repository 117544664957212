import { responsiveFontSizes, Theme } from '@mui/material';
import { PaletteColor } from '@mui/material/styles';
import ThemeBuilder from 'app/shared/themes/builders/themeBuilder';
import { getAlphaColor } from 'app/shared/util/color-palette-calculator';
import { merge } from 'lodash';

const mixins = {
  toolbar: {
    minHeight: '48px',
    padding: '16px',
    '@media (min-width: 600px)': {
      minHeight: '48px',
    },
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    neutral: PaletteColor;
  }
  interface PaletteOptions {
    neutral: PaletteColor;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true;
  }
}
declare module '@mui/material/Avatar' {
  interface AvatarPropsVariantOverrides {
    iconButton: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    neutral: true;
  }
}

class ScoringJoe extends ThemeBuilder {
  colors = {
    // ocean: {
    //   primary: {
    //     main: '#1f5eb3',
    //   },
    //   secondary: {
    //     main: '#197f68',
    //   }
    // },
    ocean: {
      primary: {
        // main: '#0d5980'
        main: '#0F6B99',
        // main: '#127db3'
        // main: '#148FCC'
        // main: '#128298',
        // "#2b6860"
        // "#7ca49c"
        // "#2e4862"
      },
      secondary: {
        // main: '#6d5310'
        main: '#d98c21',
        // main: '#b36212'
        // main: '#CC633D'
        // main: '#FF895E'
        // main: '#D99816'
        // main: '#e5b85c'
        // main: '#f2b155'
        // main: '#00A666',
        // "#c6b99f"
        // "#be9987"
        // "#d1a57b"
      },
    },
    mountain: {
      primary: {
        main: '#128298',
      },
      secondary: {
        main: '#8f6400',
      },
    },
    forest: {
      primary: {
        main: '#004f00',
      },
      secondary: {
        // main: '#8f6400',
        main: '#d98c21',
        // main: '#FF895E'
      },
    },
    sunset: {
      primary: {
        main: '#4100d9',
      },
      secondary: {
        main: '#8f6400',
      },
    },
  };

  palette() {
    return {
      mode: this.customization?.palette?.mode,
      /* contrastThreshold: 4.5, */
      tonalOffset: {
        light: 0.2,
        dark: 0.2,
      },
      background: {
        paper: '#fff',
        default: '#eee',
      },
      ...this.colors[this.customization?.palette?.variant || 'ocean'],
    };
  }

  mixins() {
    return mixins;
  }

  typography() {
    const weight = this.customization.weight || 0;

    return {
      fontFamily: this.customization?.fontFamily,
      h6: {
        fontWeight: 500 + weight * 100,
        // color: theme?.heading,
        fontSize: '0.75rem',
      },
      h5: {
        fontSize: '0.875rem',
        // color: theme?.heading,
        fontWeight: 500 + weight * 100,
      },
      h4: {
        fontSize: '1rem',
        // color: theme?.heading,
        fontWeight: 600 + weight * 100,
      },
      h3: {
        fontSize: '1.25rem',
        // color: theme?.heading,
        fontWeight: 600 + weight * 100,
      },
      h2: {
        fontSize: '1.5rem',
        // color: theme?.heading,
        fontWeight: 700 + weight * 100,
      },
      h1: {
        fontSize: '2.125rem',
        // color: theme?.heading,
        fontWeight: 700 + weight * 100,
      },
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 500 + weight * 100,
        // color: theme?.textDark,
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 400 + weight * 100,
        // color: theme?.darkTextSecondary,
      },
      caption: {
        fontSize: '0.75rem',
        // color: theme?.darkTextSecondary,
        fontWeight: 400 + weight * 100,
      },
      body1: {
        fontSize: '0.875rem',
        fontWeight: 400 + weight * 100,
        lineHeight: '1.334em',
      },
      body2: {
        letterSpacing: '0em',
        fontWeight: 400 + weight * 100,
        lineHeight: '1.5em',
        // color: theme?.darkTextPrimary,
      },
      button: {
        textTransform: 'capitalize',
      },
      customInput: {
        marginTop: 1,
        marginBottom: 1,
        '& > label': {
          top: 23,
          left: 0,
          // color: theme?.grey500,
          '&[data-shrink="false"]': {
            top: 5,
          },
        },
        '& > div > input': {
          padding: '30.5px 14px 11.5px !important',
        },
        '& legend': {
          display: 'none',
        },
        '& fieldset': {
          top: 0,
        },
      },
      mainContent: {
        // backgroundColor: theme?.background,
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginTop: '88px',
        marginRight: '20px',
        borderRadius: `${this.customization?.borderRadius}px`,
      },
      menuCaption: {
        fontSize: '0.875rem',
        fontWeight: 500 + weight * 100,
        // color: theme?.heading,
        padding: '6px',
        textTransform: 'capitalize',
        marginTop: '10px',
      },
      subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 500 + weight * 100,
        // color: theme?.darkTextSecondary,
        textTransform: 'capitalize',
      },
      commonAvatar: {
        cursor: 'pointer',
        borderRadius: '8px',
      },
      smallAvatar: {
        width: '22px',
        height: '22px',
        fontSize: '1rem',
      },
      mediumAvatar: {
        width: '34px',
        height: '34px',
        fontSize: '1.2rem',
      },
      largeAvatar: {
        width: '44px',
        height: '44px',
        fontSize: '1.5rem',
      },
    };
  }

  components(theme: Theme) {
    return {
      MuiStack: {
        defaultProps: {
          spacing: 3,
        },
      },
      MuiGrid: {
        defaultProps: {
          spacing: 3,
        },
      },
      MuiDataGrid: {
        defaultProps: {
          autoHeight: true,
        },
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            // border: 'none'
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '30px',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          inset: {
            paddingLeft: '30px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-root': {
              borderRadius: theme.shape.borderRadius,
              background: 'rgb(250, 250, 250) none repeat scroll 0% 0%',
            },
          },
        },
      },
      MuiAvatar: {
        variants: [
          {
            props: { variant: 'iconButton' as const },
            style: {
              cursor: 'pointer',
              borderRadius: '4px',
              width: '34px',
              height: '34px',
              fontSize: '1.2rem',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.contrastText,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.contrastText,
              },
            },
          },
        ],
      },
      MuiButton: {
        styleOverrides: {
          textNeutral: {
            '&:hover': {
              background: getAlphaColor(theme.palette.neutral.main, 0.2),
              color: theme.palette.primary.main,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          colorNeutral: {
            '&:hover': {
              background: getAlphaColor(theme.palette.neutral.main, 0.2),
              color: theme.palette.primary.main,
            },
          },
        },
      },
      // MuiPaper: {
      //   styleOverrides: {
      //     rounded: {
      //       borderRadius: `${this.customization?.borderRadius}px`,
      //     },
      //   },
      // },
      // MuiCardHeader: {
      //   styleOverrides: {
      //     root: {
      //       // color: theme?.textDark,
      //       padding: '24px',
      //     },
      //     action: {
      //       alignSelf: 'center',
      //       paddingRight: '8px',
      //     },
      //     title: {
      //       fontSize: '1.125rem',
      //     },
      //   },
      // },
      // MuiCardContent: {
      //   styleOverrides: {
      //     root: {
      //       padding: '24px',
      //     },
      //   },
      // },
      // MuiDataGrid: {
      //   styleOverrides: {
      //     footerContainer: {
      //       '&.MuiTablePagination-root': {
      //         '&.MuiToolBar-root': {
      //           padding: '0px',
      //         },
      //       },
      //     },
      //   },
      // },
      // MuiToolBar: {
      //   styleOverrides: {
      //     root: {
      //       padding: '0px',
      //     },
      //   },
      // },
      // MuiListItemButton: {
      //   styleOverrides: {
      //     root: {
      //       // color: theme?.darkTextPrimary,
      //       paddingTop: '10px',
      //       paddingBottom: '10px',
      //       // '&.Mui-selected': {
      //       //   color: theme?.menuSelected,
      //       //   backgroundColor: theme?.menuSelectedBack,
      //       //   '&:hover': {
      //       //     backgroundColor: theme?.menuSelectedBack,
      //       //   },
      //       //   '& .MuiListItemIcon-root': {
      //       //     color: theme?.menuSelected,
      //       //   },
      //       // },
      //       // '&:hover': {
      //       //   backgroundColor: theme?.menuSelectedBack,
      //       //   color: theme?.menuSelected,
      //       //   '& .MuiListItemIcon-root': {
      //       //     color: theme?.menuSelected,
      //       //   },
      //       // },
      //     },
      //   },
      // },
      // MuiList: {
      //   styleOverrides: {
      //     root: {
      //       paddingLeft: '0px !important',
      //     },
      //   },
      // },
      // MuiListItemIcon: {
      //   styleOverrides: {
      //     root: {
      //       // color: theme?.darkTextPrimary,
      //       minWidth: '30px',
      //     },
      //   },
      // },
      // MuiListItemText: {
      //   styleOverrides: {
      //     inset: {
      //       paddingLeft: '30px',
      //     },
      //     primary: {
      //       // color: theme?.textDark,
      //     },
      //   },
      // },
      // MuiSlider: {
      //   styleOverrides: {
      //     root: {
      //       '&.Mui-disabled': {
      //         // color: theme?.colors?.grey300,
      //       },
      //     },
      //     mark: {
      //       // backgroundColor: theme?.paper,
      //       width: '4px',
      //     },
      //     valueLabel: {
      //       // color: theme?.colors?.primaryLight,
      //     },
      //   },
      // },
      // MuiDivider: {
      //   styleOverrides: {
      //     root: {
      //       // borderColor: theme?.divider,
      //       opacity: 1,
      //     },
      //   },
      // },
      // MuiChip: {
      //   styleOverrides: {
      //     root: {
      //       '&.MuiChip-deletable .MuiChip-deleteIcon': {
      //         color: 'inherit',
      //       },
      //     },
      //   },
      // },
      // MuiTooltip: {
      //   styleOverrides: {
      //     tooltip: {
      //       color: theme?.paper,
      //       background: theme?.colors?.grey700,
      //     },
      //   },
      // },
      /* MuiTextField: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-notchedOutline': {
              legend: {
                float: 'unset !important',
              },
            },
          },
        },
      }, */
      /* MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            legend: {
              float: 'unset !important',
            },
          },
        },
      }, */
      /* MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            padding: '8px 0 !important',
          },
          popper: {
            boxShadow: '0 0 40px rgba(0, 0, 0, 0.25)',
          },
        },
      }, */
      //     input: {
      //       // color: theme?.textDark,
      //       fontWeight: 500,
      //       '&::placeholder': {
      //         // color: theme?.darkTextSecondary,
      //         fontSize: '0.875rem',
      //       },
      //     },
      //   },
      // },
    };
  }

  override updateTheme(theme: Theme) {
    const result = responsiveFontSizes(theme);
    result.palette = {
      ...result.palette,
      ...{ neutral: result.palette.augmentColor({ color: { main: result.palette.grey[500] } }) },
    };
    // result.palette.background.default = result.palette.grey[100];
    result.components = merge(result.components, this.components(result));
    return result;
  }
}

export default ScoringJoe;

import React from 'react';

import { Avatar, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { getAlphaColor } from 'app/shared/util/color-palette-calculator';
import { Translate } from 'react-jhipster';
import { BetaRegisterButton } from './components/BetaRegisterButton';
import { TargetArrow, Discount } from 'tabler-icons-react';

export const BetaProgram = () => {
  return (
    <LayoutItem
      cardOptions={{
        variant: 'div',
        card: {
          id: 'beta-program',
          sx: {
            backgroundColor: theme => getAlphaColor(theme.palette.secondary.light, 0.4),
            py: '10vh',
          },
        },
        header: {
          disableTypography: true,
          title: (
            <Typography fontSize="2.2rem" textAlign="center">
              <Translate contentKey={'landing.services.beta-program'}>Beta Program</Translate>
            </Typography>
          ),
          sx: {
            pb: '5vh',
          },
        },
        actions: (
          <BetaRegisterButton variant="contained">
            <Translate contentKey="landing.actions.apply-beta-program">Apply</Translate>
          </BetaRegisterButton>
        ),
        actionsProps: { sx: { justifyContent: 'center', mt: '5vh', p: 0 } },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <Container maxWidth="xl">
        <GridLayout alignItems="flex-start" justifyContent={{ lg: 'space-evenly', xs: 'none' }}>
          <LayoutItem
            gridItemProps={{
              xs: 12,
              sm: 6,
              lg: 4,
              sx: {
                textAlign: { xs: 'center' },
              },
              alignSelf: 'stretch',
            }}
            cardOptions={{
              header: {
                disableTypography: true,
                title: (
                  <Typography fontSize="1.5rem">
                    <Translate contentKey={'landing.beta-program.objectives.title'}>Objectives</Translate>
                  </Typography>
                ),
                avatar: (
                  <Avatar variant="rounded" sx={{ bgcolor: theme => theme.palette.primary.main, borderRadius: 5 }}>
                    <TargetArrow />
                  </Avatar>
                ),
                sx: {
                  p: 3.5,
                  justifyContent: 'center',
                  '.MuiCardHeader-content': { flex: 'initial' },
                },
              },
              card: {
                sx: {
                  borderRadius: 5,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                },
                elevation: 3,
              },
              content: { sx: { pt: 0, pb: 3.5, '&:last-child': { pt: 0, pb: 3.5 }, my: 'auto' } },
            }}
          >
            <List sx={{ mx: 'auto', textAlign: 'center' }} disablePadding>
              {['a', 'b', 'c', 'd'].map((element, index) => {
                return (
                  <ListItem key={index} sx={{ textAlign: 'center' }}>
                    <ListItemText
                      primary={
                        <Typography fontSize="1.1rem">
                          <Translate contentKey={`landing.beta-program.objectives.${element}`}>objectif {element}</Translate>
                        </Typography>
                      }
                      sx={{ fontSize: '1.1rem' }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </LayoutItem>
          <LayoutItem
            gridItemProps={{
              xs: 12,
              sm: 6,
              lg: 4,
              sx: {
                textAlign: { xs: 'center' },
              },
              alignSelf: 'stretch',
            }}
            cardOptions={{
              header: {
                disableTypography: true,
                title: (
                  <Typography fontSize="1.5rem">
                    <Translate contentKey={'landing.beta-program.benefices.title'}>Benefices</Translate>
                  </Typography>
                ),
                avatar: (
                  <Avatar variant="rounded" sx={{ bgcolor: theme => theme.palette.primary.main, borderRadius: 5 }}>
                    <Discount />
                  </Avatar>
                ),
                sx: {
                  p: 3.5,
                  justifyContent: 'center',
                  '.MuiCardHeader-content': { flex: 'initial' },
                },
              },
              card: {
                sx: {
                  borderRadius: 5,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                },
                elevation: 3,
              },
              content: { sx: { pt: 0, pb: 3.5, '&:last-child': { pt: 0, pb: 3.5 }, my: 'auto' } },
            }}
          >
            <List sx={{ mx: 'auto', textAlign: 'center' }} disablePadding>
              {['a', 'b', 'c', 'd', 'e'].map((element, index) => {
                return (
                  <ListItem key={index} sx={{ textAlign: 'center' }}>
                    <ListItemText
                      primary={
                        <Typography fontSize="1.1rem">
                          <Translate contentKey={`landing.beta-program.benefices.${element}`}>objectif {element}</Translate>
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </LayoutItem>
        </GridLayout>
      </Container>
    </LayoutItem>
  );
};

import React from 'react';

import { Divider } from '@mui/material';
import BucketingToggler from './components/bucketingToggler';
import { useDataExploration } from './hooks/useDataExploration';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { GridLayout, RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { TrendsGrid } from './components/trendChart/TrendsGrid';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { PieGrids } from './components/pieChart/PieGrids';
import { DataExplorationRequestFilters } from './components/filter/DataExplorationRequestFilters';
import { DataExplorationTimerangeFilters } from './components/filter/DataExplorationTimerangeFilters';
import { MapCard } from './components/world-map/MapCard';
import { KpiLineCard } from './components/kpi-line-chart/KpiLineCard';
import { BucketingDatagridCard } from './components/bucketing-datagrid/BucketingDatagridCard';

export const DataExploration = () => {
  useDataExploration();

  return (
    <BasicCardLayout
      variant="div"
      header={{
        disableTypography: true,
        title: <DataExplorationRequestFilters />,
        action: <DataExplorationTimerangeFilters />,
        sx: { p: 1, '.MuiCardHeader-action': { alignSelf: 'auto' } },
      }}
      content={{ sx: { p: 0 } }}
      card={{ sx: { overflow: 'visible' } }}
    >
      <TrendsGrid />

      <GridLayout mt={1} width="100%">
        <MapCard />
        <KpiLineCard />
      </GridLayout>

      <RawGridLayout mt={1}>
        <LayoutItem gridItemProps={{ xs: 12 }}>
          <Divider variant="fullWidth" sx={{ mb: 2 }} />
        </LayoutItem>
      </RawGridLayout>

      <BasicCardLayout
        variant="div"
        header={{ title: <BucketingToggler />, sx: { textAlign: 'center' } }}
        content={{ sx: { p: 0 } }}
        card={{ sx: { mt: 2, overflow: 'visible' } }}
      >
        <PieGrids />
      </BasicCardLayout>

      <BucketingDatagridCard />
    </BasicCardLayout>
  );
};

import { Box, Button, CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const ImageCard = ({ title, description = null, icon, links }) => {
  const MediaIcon = icon;
  return (
    <Card sx={{ backgroundImage: `linear-gradient(150deg,  #f9e7d1, #cedcfb)` }}>
      <Box sx={{ display: 'flex' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          {title}
          {description && description}
        </CardContent>
        <CardMedia>
          <MediaIcon sx={{ fontSize: 100 }} color="primary" />
        </CardMedia>
      </Box>
      <CardActions>
        {links.map((link, indexLink) => {
          return (
            <Button key={indexLink} size="small" color="primary" variant="outlined" component={Link} to={link.to}>
              {link.title}
            </Button>
          );
        })}
      </CardActions>
    </Card>
  );
};

import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getPaymentMethodsUsingGet: build.query<GetPaymentMethodsUsingGetApiResponse, GetPaymentMethodsUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}/paymentMethods`,
        params: { withPluginInfo: queryArg.withPluginInfo, includedDeleted: queryArg.includedDeleted, audit: queryArg.audit },
      }),
      providesTags: ['PAYMENT_METHOD', 'BILLING_ACCOUNT'],
    }),
    addPaymentMethodUsingPost: build.mutation<AddPaymentMethodUsingPostApiResponse, AddPaymentMethodUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/accounts/${queryArg.accountKey}/paymentMethods`,
        method: 'POST',
        body: queryArg.paymentMethodDto,
        params: {
          isDefault: queryArg.isDefault,
          payAllUnpaidInvoices: queryArg.payAllUnpaidInvoices,
          controlPluginName: queryArg.controlPluginName,
        },
      }),
      invalidatesTags: ['PAYMENT_METHOD'],
    }),
    getPaymentMethodsUsingGet1: build.query<GetPaymentMethodsUsingGet1ApiResponse, GetPaymentMethodsUsingGet1ApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/paymentMethods/pagination`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          pluginName: queryArg.pluginName,
          withPluginInfo: queryArg.withPluginInfo,
          audit: queryArg.audit,
        },
      }),
    }),
    getPaymentMethodByIdUsingGet: build.query<GetPaymentMethodByIdUsingGetApiResponse, GetPaymentMethodByIdUsingGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/billing/paymentMethods/${queryArg.paymentMethodId}`,
        params: { includedDeleted: queryArg.includedDeleted, withPluginInfo: queryArg.withPluginInfo, audit: queryArg.audit },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as paymentMethodApi };
export type GetPaymentMethodsUsingGetApiResponse = /** status 200 OK */ PaymentMethodDto[];
export type GetPaymentMethodsUsingGetApiArg = {
  /** accountKey */
  accountKey: string;
  /** withPluginInfo */
  withPluginInfo?: boolean;
  /** includedDeleted */
  includedDeleted?: boolean;
  /** audit */
  audit?: string;
};
export type AddPaymentMethodUsingPostApiResponse = /** status 200 OK */ PaymentMethodDto | /** status 201 Created */ undefined;
export type AddPaymentMethodUsingPostApiArg = {
  /** accountKey */
  accountKey: string;
  /** isDefault */
  isDefault?: boolean;
  /** payAllUnpaidInvoices */
  payAllUnpaidInvoices?: boolean;
  /** controlPluginName */
  controlPluginName?: string;
  paymentMethodDto: PaymentMethodDto;
};
export type GetPaymentMethodsUsingGet1ApiResponse = /** status 200 OK */ PaymentMethodDto[];
export type GetPaymentMethodsUsingGet1ApiArg = {
  /** offset */
  offset?: number;
  /** limit */
  limit?: number;
  /** pluginName */
  pluginName?: string;
  /** withPluginInfo */
  withPluginInfo?: boolean;
  /** audit */
  audit?: string;
};
export type GetPaymentMethodByIdUsingGetApiResponse = /** status 200 OK */ PaymentMethodDto;
export type GetPaymentMethodByIdUsingGetApiArg = {
  /** paymentMethodId */
  paymentMethodId: string;
  /** includedDeleted */
  includedDeleted?: boolean;
  /** withPluginInfo */
  withPluginInfo?: boolean;
  /** audit */
  audit?: string;
};
export type AuditLog = {
  changeDate?: string;
  changeType?: string;
  changedBy?: string;
  comments?: string;
  history?: object;
  objectId?: string;
  objectType?:
    | 'ACCOUNT'
    | 'ACCOUNT_EMAIL'
    | 'BLOCKING_STATES'
    | 'BUNDLE'
    | 'CUSTOM_FIELD'
    | 'INVOICE'
    | 'INVOICE_ITEM'
    | 'INVOICE_PAYMENT'
    | 'PAYMENT'
    | 'PAYMENT_ATTEMPT'
    | 'PAYMENT_METHOD'
    | 'SERVICE_BROADCAST'
    | 'SUBSCRIPTION'
    | 'SUBSCRIPTION_EVENT'
    | 'TAG'
    | 'TAG_DEFINITION'
    | 'TENANT'
    | 'TENANT_KVS'
    | 'TRANSACTION';
  reasonCode?: string;
  userToken?: string;
};
export type PluginProperty = {
  isUpdatable?: boolean;
  key?: string;
  value?: string;
};
export type PaymentMethodPluginDetail = {
  externalPaymentMethodId?: string;
  isDefaultPaymentMethod?: boolean;
  properties?: PluginProperty[];
};
export type PaymentMethodDto = {
  accountExternalKey?: string;
  auditLogs?: AuditLog[];
  externalKey?: string;
  isDefault?: boolean;
  paymentMethodId?: string;
  pluginInfo?: PaymentMethodPluginDetail;
  pluginName?: string;
  pluginProperty?: {
    [key: string]: string;
  };
};
export const {
  useGetPaymentMethodsUsingGetQuery,
  useLazyGetPaymentMethodsUsingGetQuery,
  useAddPaymentMethodUsingPostMutation,
  useGetPaymentMethodsUsingGet1Query,
  useGetPaymentMethodByIdUsingGetQuery,
} = injectedRtkApi;

import React from 'react';

import { Divider, Typography } from '@mui/material';
import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { useTimeRange } from 'app/components/filter-wrapper/useTimeRangeFilter';
import LineChart from 'app/modules/views/greensights/component/lineChart/LineChart';
import BucketingToggler from './component/bucketingToggler';
import MapChart from './component/mapChart/MapChart';
import { GridLayout, RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { GreensightsFilters } from './component/filter';
import { translate } from 'react-jhipster';
import { PieGrids } from './component/pieChart/PieGrids';
import PageGrid from './component/pageGrid';
import { trendChartList } from 'app/modules/views/greensights/component/trendChart/list';
import TrendChart from 'app/modules/views/greensights/component/trendChart';

const Greensights: React.FC = () => {
  const timerange = useTimeRange({ chosenRange: 'web-analytics.DatePicker.SincePeriod', fromDate: 'now-1M/d' });
  return (
    <BasicCardLayout
      variant="div"
      header={{
        disableTypography: true,
        title: <BreadcrumbGenerator />,
        action: <GreensightsFilters timerange={timerange} />,
        sx: { p: 1 },
      }}
      content={{ sx: { p: 0 } }}
      card={{ sx: { overflow: 'visible' } }}
    >
      <GridLayout xs={12} columns={8} spacing={3}>
        {trendChartList.map((chart, index) => (
          <TrendChart key={`trendChart${index}`} chart={chart} fromDate={timerange.fromDate} toDate={timerange.toDate} />
        ))}
      </GridLayout>
      <GridLayout mt={1}>
        <MapChart fromDate={timerange.fromDate} toDate={timerange.toDate} />
        <LineChart />
      </GridLayout>

      <RawGridLayout mt={1}>
        <LayoutItem gridItemProps={{ xs: 12 }}>
          <Divider variant="fullWidth" sx={{ mb: 2 }} />
        </LayoutItem>
      </RawGridLayout>

      <BasicCardLayout
        variant="div"
        header={{ title: <BucketingToggler />, sx: { textAlign: 'center' } }}
        content={{ sx: { p: 0 } }}
        card={{ sx: { mt: 2, overflow: 'visible' } }}
      >
        <PieGrids fromDate={timerange.fromDate} toDate={timerange.toDate} />
      </BasicCardLayout>

      <BasicCardLayout
        header={{
          title: (
            <Typography variant="h4" paddingBottom={1}>
              {translate(`greensights.table.page_title`)}
            </Typography>
          ),
          sx: {
            py: 1,
            pb: 0,
          },
        }}
        content={{ sx: { minHeight: '300px' } }}
        card={{ sx: { mt: 2 } }}
      >
        <PageGrid fromDate={timerange.fromDate} toDate={timerange.toDate} />
      </BasicCardLayout>
    </BasicCardLayout>
  );
};

export default Greensights;

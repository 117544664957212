import React from 'react';

import { BasicCardLayout, BasicCardLayoutProps } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';
import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';

interface DisplayOptions {}

interface StatsCardProps extends BasicCardLayoutProps {
  options?: EChartsOption | DisplayOptions;
}

const StatsCard = ({ options, ...cardProps }: StatsCardProps) => {
  return (
    <BasicCardLayout {...cardProps}>
      <ReactECharts style={{ height: '100%', width: '100%' }} option={options} />
    </BasicCardLayout>
  );
};

export default WithLoadingHOC(StatsCard);

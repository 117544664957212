export const lineChartList = [
  {
    title: 'energy-impact_kwh',
    key: 'energy_impact_kwh',
    scope: 'country',
  },
  {
    title: 'co2-eq_kg',
    key: 'co2_eq_kg',
    scope: 'country',
  },
  {
    title: 'page-size_bytes',
    key: 'page_size_bytes',
    scope: 'metric',
  },
  {
    title: 'avg-page-size_bytes',
    key: 'avg_page_size_bytes',
    scope: 'country',
  },
];

import { Breakpoint } from '@mui/system';

export const getKpiFromPayload = (payload, kpi) => {
  return payload.data.date.map(element => {
    if (typeof element.buckets[kpi.name][kpi.value] !== 'number') return [new Date(element.date).getTime(), 0];
    return [new Date(element.date).getTime(), Number(element.buckets[kpi.name][kpi.value]).toFixed(2)];
  });
};

export const getKpiPerKpiFromPayload = (payload, firstKpi, secondKpi) => {
  return payload.data.date.map(element => {
    if (element.buckets[firstKpi.name][firstKpi.value] === 0 || element.buckets[secondKpi.name][secondKpi.value] === 0)
      return [new Date(element.date).getTime(), 0];
    return [
      new Date(element.date).getTime(),
      Number(element.buckets[firstKpi.name][firstKpi.value] / element.buckets[secondKpi.name][secondKpi.value]).toFixed(2),
    ];
  });
};

export const bucketingDimensionsMappingList: {
  name: string;
  value: string;
  displayOn?: Breakpoint;
}[] = [
  { name: 'active session', value: 'ACTIVE_SESSION' },

  { name: 'referer host-name', value: 'REFERER_HOSTNAME', displayOn: 'sm' },
  { name: 'remote host FQDN', value: 'REMOTE_HOST_FQDN', displayOn: 'md' },

  { name: 'entry page', value: 'FIRST_VISITED_PAGE', displayOn: 'md' },
  { name: 'exit page', value: 'LAST_VISITED_PAGE', displayOn: 'md' },

  { name: 'postal code', value: 'POSTAL_CODE' },
  { name: 'city', value: 'CITY_NAME', displayOn: 'sm' },
  { name: 'country', value: 'COUNTRY_NAME', displayOn: 'xs' },
  { name: 'most specific subdivision name', value: 'MOST_SPECIFIC_SUBDIVISION_NAME' },
  { name: 'continent', value: 'CONTINENT_NAME', displayOn: 'lg' },
  { name: 'timezone', value: 'TIMEZONE' },

  { name: 'content', value: 'SOURCE_OF_TRAFFIC_CONTENT' },
  { name: 'organic search', value: 'SOURCE_OF_TRAFFIC_ORGANIC_SEARCH' },
  { name: 'keyword', value: 'SOURCE_OF_TRAFFIC_KEYWORD' },
  { name: 'referral path', value: 'SOURCE_OF_TRAFFIC_REFERRAL_PATH' },
  { name: 'source', value: 'SOURCE_OF_TRAFFIC_SOURCE', displayOn: 'xs' },
  { name: 'medium', value: 'SOURCE_OF_TRAFFIC_MEDIUM', displayOn: 'lg' },
  { name: 'campaign', value: 'SOURCE_OF_TRAFFIC_CAMPAIGN', displayOn: 'lg' },

  { name: 'browser', value: 'USER_AGENT_NAME', displayOn: 'xs' },
  { name: 'OS', value: 'USER_AGENT_OS_FAMILY', displayOn: 'xs' },
  { name: 'device category', value: 'USER_AGENT_DEVICE_CATEGORY' },
];

export const getSecondaryDimensions = (payload, kpis) => {
  const secondaryDimensions = initial_secondary_dimensions();
  bucketingDimensionsMappingList.forEach((bucket, bucketIndex) => {
    kpis.forEach((kpi, i) => {
      secondaryDimensions[bucket.value][kpi.name] = { total: 0, values: [] };
      payload.data[bucket.value].forEach((element, index) => {
        secondaryDimensions[bucket.value][kpi.name].values.push({
          name: element.name,
          value: Number(element.buckets[kpi.name][kpi.value]).toFixed(2),
        });
      });
      secondaryDimensions[bucket.value][kpi.name].values.sort((a, b) => b.value - a.value);
      secondaryDimensions[bucket.value][kpi.name].total = secondaryDimensions[bucket.value][kpi.name].values.reduce(
        (prev, current) => prev + Number(current.value),
        0
      );
    });
  });
  return secondaryDimensions;
};

export const initial_secondary_dimensions = (): any => {
  const secondary_dimensions_object = {};
  bucketingDimensionsMappingList.forEach((bucket, i) => {
    secondary_dimensions_object[bucket.value] = {};
  });
  return secondary_dimensions_object;
};

export const initial_analytics_table_data = (): any => {
  const analytics_object = {};
  bucketingDimensionsMappingList.forEach((bucket, i) => {
    analytics_object[bucket.value] = [];
  });
  return analytics_object;
};

export const getAnalyticsData = (payload, kpis) => {
  const analyticsData = initial_analytics_table_data();
  bucketingDimensionsMappingList.forEach((bucketingDimension, bucketingIndex) => {
    payload.data[bucketingDimension.value].forEach((element, index) => {
      const raw = {};
      raw['id'] = index;
      raw[bucketingDimension.value] = element.name;
      kpis.forEach((kpi, i) => {
        raw[kpi.name] = Number(element.buckets[kpi.name][kpi.value]).toFixed(2);
      });
      analyticsData[bucketingDimension.value].push(raw);
    });
  });
  return analyticsData;
};

export const getSessionsByCountry = payload => {
  return payload.data.COUNTRY_CODE.map(element => {
    return { countryCode: element.countryCode, buckets: element.buckets };
  });
};

export const kpisValuesMapping = [
  { name: 'PAGEVIEWS_COUNT', value: 'sum' },
  { name: 'BOUNCE_RATE', value: 'rate' },
  { name: 'AVG_SESSION_DURATION', value: 'avg' },
  { name: 'SESSION_COUNT', value: 'count' },
  { name: 'USER_COUNT', value: 'count' },
  { name: 'ACTIVE_USER_COUNT', value: 'count' },
  { name: 'SUM_SESSION_DURATION', value: 'sum' },
  { name: 'BOUNCE_COUNT', value: 'sum' },
];

import React from 'react';

import { IconButton, MenuItem, Menu } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Translate } from 'react-jhipster';
import { useProductCardMenu } from '../../hooks/product-card/useProductCardMenu';

export const ProductCardMenu = ({ productName }) => {
  const { anchorEl, open, handleCancel, handleClick, handleClose } = useProductCardMenu(productName);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleCancel();
          }}
        >
          <HighlightOffIcon color="error" />
          <Translate contentKey="account.cancel">Cancel</Translate>
        </MenuItem>
      </Menu>
    </>
  );
};

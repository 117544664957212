import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useData } from 'app/modules/views/greensights/data/api';
import { useSelectedChart, useSelectedData } from 'app/modules/views/greensights/data/state';
import { Typography } from '@mui/material';
import { Translate, translate } from 'react-jhipster';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { ResponseWrapper } from 'app/modules/views/greensights/model/responseWrapper';
import MetricResponse from 'app/modules/views/greensights/model/metricResponse';
import TrendEChart from './TrendEChart';
import {
  ResponseWrapperMetricResponse,
  useLazyLoadQuery,
} from 'app/shared/reducers/api/greensights-data-hub-cloud-services/store/greensights-data-hub';

interface TrendChartProps {
  fromDate;
  toDate;
  chart: { key: string; title: string; scope?: string };
}

export const callback = (response: ResponseWrapperMetricResponse) => {
  if (response.content.status === 'success') {
    return response.content.data.result[0].values.map(value => [value[0] * 1000, value[1]]);
  }
  return [];
};

const TrendChart = ({ chart, fromDate, toDate }: TrendChartProps) => {
  const theme = useTheme();

  const [load, { data = [], loading, currentData }] = useLazyLoadQuery({
    selectFromResult(response) {
      return { data: response.data ? callback(response.data) : [], loading: response.isLoading, currentData: response.currentData };
    },
  });
  const { selectedChart, setSelectedChart } = useSelectedChart();
  const { setSelectedData } = useSelectedData();

  useEffect(() => {
    load(
      {
        accountId: '93090364',
        metricRequest: {
          start: fromDate,
          end: toDate,
          query: `sum(${chart.key}{#filters})`,
          filters: {
            scope: chart.scope,
          },
        },
      },
      true
    );
  }, [fromDate, toDate]);

  useEffect(() => {
    if (selectedChart.chart.key === chart.key) {
      setSelectedData(data);
    }
  }, [currentData]);

  return (
    <LayoutItem
      cardOptions={{
        card: {
          sx: {
            cursor: 'pointer',
            ...(selectedChart?.chart.key === chart.key && {
              borderStyle: 'solid',
              borderWidth: 'thin',
              borderColor: 'secondary.dark',
              boxShadow: `0 0 5px ${theme.palette.secondary.dark} inset`,
            }),
          },
          onClick() {
            setSelectedChart({ chart });
            setSelectedData(data);
          },
        },
        header: {
          title: (
            <Typography
              variant="h4"
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 0 }}
              title={translate(`greensights.line-chart.${chart.title}`)}
              p={1}
            >
              <Translate contentKey={`greensights.line-chart.${chart.title}`}>{chart.title}</Translate>
            </Typography>
          ),
          sx: {
            '.MuiCardHeader-content': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 1 },
            p: 0,
          },
        },
        content: { sx: { height: '100px', p: 0, '&:last-child': { p: 0 } } },
      }}
      gridItemProps={{ xs: 4, lg: 1, sm: 2 }}
    >
      <TrendEChart chart={chart} data={data} loading={loading} />
    </LayoutItem>
  );
};
export default TrendChart;

import { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteSubscription } from 'app/shared/reducers/subscription';

export const useProductCardMenu = productName => {
  const { accountProfile } = useAppSelector(state => state.accountProfile.accountProfileReducer);
  const { subscriptions } = useAppSelector(state => state.subscriptionBundles);
  const dispatch = useAppDispatch();

  function getSubscriptionId() {
    const subscription = subscriptions.filter(el => el.state === 'ACTIVE' && el.productName === productName);
    return subscription[0]?.subscriptionId;
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCancel = () => {
    dispatch(deleteSubscription({ subscriptionId: getSubscriptionId(), accountId: accountProfile.accountId }));
    setAnchorEl(null);
  };

  return {
    anchorEl,
    open,
    handleCancel,
    handleClick,
    handleClose,
  };
};

import { ArrowCircleUp, Settings, Api, HelpCenter, HighlightOff, Start } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { sitemap } from 'app/shared/util/url-utils';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';

export const AvailableProductsMenu = ({ p }) => {
  return (
    <List disablePadding sx={{ width: '150px' }}>
      <ListItem sx={{ justifyContent: 'center' }} selected divider>
        <Typography fontWeight="bold" textTransform="capitalize">
          <Translate contentKey={`module-factory.${p.name}`}>{p.name}</Translate>
        </Typography>
      </ListItem>

      <ListItemButton component={Link} to={sitemap.setup} state={{ product: p }}>
        <ListItemIcon>
          <Start color="primary" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<Translate contentKey="products.subscribe">Subscribe</Translate>} />
      </ListItemButton>

      <ListItemButton>
        <ListItemIcon>
          <Api fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<Translate contentKey="products.api-doc">Api Doc</Translate>} />
      </ListItemButton>

      <ListItemButton>
        <ListItemIcon>
          <HelpCenter fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={<Translate contentKey="products.help">Help</Translate>} />
      </ListItemButton>
    </List>
  );
};

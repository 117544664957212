import React from 'react';
import { FieldProp } from 'app/modules/form-builder-lib';
import { useCorpusListQuery } from '../../hooks/useCorpusListQuery';
import { demonstrationOfInterestActions, conversionActions, otherActions } from '../utils/actions-types';
import { Box, Checkbox, ListItemText, MenuItem } from '@mui/material';

export const useRecommendationsKpisFormFields = actions => {
  const { corpusList } = useCorpusListQuery();
  const [selectedDemonstrationOfInterestActions, setDemonstrationOfInterestActions] = React.useState(demonstrationOfInterestActions);
  const [selectedConversionActions, setConversionActions] = React.useState(conversionActions);

  const fields: Array<FieldProp> = [
    {
      component: 'autocomplete',
      options: corpusList.sort(),
      attribute: 'corpusId',
      label: 'Corpus ID',
      col: { xs: 2 },
    },

    {
      component: 'select',
      options: actions,
      attribute: 'demonstrationOfInterestActions',
      label: 'Demonstration Of Interest Actions',
      props: {
        multiple: true,
        value: selectedDemonstrationOfInterestActions,
        onChange(event) {
          setDemonstrationOfInterestActions(event.target.value as string[]);
        },
        renderValue(selected) {
          return selectedDemonstrationOfInterestActions.join(', ');
        },
        children: actions.map(name => (
          <MenuItem
            key={name}
            value={name}
            sx={{ p: 0 }}
            disabled={
              selectedConversionActions.indexOf(name) > -1 ||
              (selectedDemonstrationOfInterestActions.length === 1 && selectedDemonstrationOfInterestActions.indexOf(name) > -1)
            }
          >
            <Checkbox checked={selectedDemonstrationOfInterestActions.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        )),
      },
      col: { xs: 3 },
    },

    {
      component: 'select',
      options: actions,
      attribute: 'conversionActions',
      label: 'Conversion Actions',
      props: {
        multiple: true,
        value: selectedConversionActions,
        onChange(event) {
          setConversionActions(event.target.value as string[]);
        },
        renderValue(selected) {
          return selectedConversionActions.join(', ');
        },
        children: actions.map(name => (
          <MenuItem
            key={name}
            value={name}
            sx={{ p: 0 }}
            disabled={
              selectedDemonstrationOfInterestActions.indexOf(name) > -1 ||
              (selectedConversionActions.length === 1 && selectedConversionActions.indexOf(name) > -1)
            }
          >
            <Checkbox checked={selectedConversionActions.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        )),
      },
      col: { xs: 3 },
    },
  ];
  return { fields };
};

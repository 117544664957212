import { EsQueryResponse } from 'app/shared/util/es-query-utils';
import { emptyRecoEngineCloudServicesApi as api } from '../emptyRecoEngineCloudServicesApi';
import { enqueueSnackbar } from 'notistack';
import { translate } from 'react-jhipster';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    aggregateActions: build.query<AggregateActionsApiResponse, AggregateActionsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/actions/stats`,
        params: {
          from: queryArg['from'],
          to: queryArg.to,
          aggregateBy: queryArg.aggregateBy,
          corpusId: queryArg.corpusId,
          existsRecoUuid: queryArg.existsRecoUuid,
          interval: queryArg.interval,
          types: queryArg.types,
          sources: queryArg.sources,
          profileIds: queryArg.profileIds,
          itemIds: queryArg.itemIds,
        },
      }),
      extraOptions: { maxRetries: 3 },
    }),
    findAllCorpora: build.query<FindAllCorporaApiResponse, FindAllCorporaApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
      providesTags: ['CORPUS_LIST'],
    }),
    createCorpus: build.mutation<CreateCorpusApiResponse, CreateCorpusApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus`,
        method: 'POST',
        body: queryArg.queryParameters,
      }),
      invalidatesTags: ['CORPUS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar(translate('recommendation.corpus.corpus-created-successfully', null, 'Corpus created successfully'), {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar(
            `Async error! : ${translate('recommendation.corpus.failed-to-create-corpus', null, 'failed to create corpus')} `,
            {
              variant: 'collapsible',
              error: err.error,
            }
          );
        }
      },
    }),
    disableDemo: build.mutation<DisableDemoApiResponse, DisableDemoApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.corpusId}/demo/disabled`,
        method: 'PUT',
      }),
      invalidatesTags: ['CORPUS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          enqueueSnackbar(translate('recommendation.corpus.demo-disabled-successfully', null, 'Demo disabled successfully'), {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar(`Async error! : ${translate('recommendation.corpus.failed-to-disable-demo', null, 'failed to disable demo')} `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    isDemoEnabled: build.query<IsDemoEnabledApiResponse, IsDemoEnabledApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.corpusId}/demo/enabled`,
      }),
    }),
    enableDemo: build.mutation<EnableDemoApiResponse, EnableDemoApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.corpusId}/demo/enabled`,
        method: 'PUT',
      }),
      invalidatesTags: ['CORPUS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          enqueueSnackbar(translate('recommendation.corpus.demo-enabled-successfully', null, 'Demo enabled successfully'), {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar(`Async error! : ${translate('recommendation.corpus.failed-to-enable-demo', null, 'failed to enable demo')} `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    runningSimulation: build.query<RunningSimulationApiResponse, RunningSimulationApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.corpusId}/demo/running`,
      }),
      providesTags: (result, error, arg) => [{ type: 'SIMULATION' as const, id: arg.corpusId }],
    }),
    startSimulation: build.mutation<StartSimulationApiResponse, StartSimulationApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.corpusId}/demo/start`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'SIMULATION', id: arg.corpusId }],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar(data, { variant: 'success' });
        } catch (err) {
          enqueueSnackbar(
            `Async error! : ${translate('recommendation.corpus.failed-to-start-simulation', null, 'failed to start simulation')} `,
            {
              variant: 'collapsible',
              error: err.error,
            }
          );
        }
      },
    }),
    stopSimulation: build.mutation<StopSimulationApiResponse, StopSimulationApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.corpusId}/demo/stop`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'SIMULATION', id: arg.corpusId }],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar(data, { variant: 'success' });
        } catch (err) {
          enqueueSnackbar(
            `Async error! : ${translate('recommendation.corpus.failed-to-stop-simulation', null, 'failed to stop simulation')} `,
            {
              variant: 'collapsible',
              error: err.error,
            }
          );
        }
      },
    }),
    deleteCorpus: build.mutation<DeleteCorpusApiResponse, DeleteCorpusApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CORPUS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          enqueueSnackbar(translate('recommendation.corpus.corpus-deleted-successfully', null, 'Corpus deleted successfully'), {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar(
            `Async error! : ${translate('recommendation.corpus.failed-to-delete-corpus', null, 'failed to delete corpus')} `,
            {
              variant: 'collapsible',
              error: err.error,
            }
          );
        }
      },
    }),
    findCorpusById: build.query<FindCorpusByIdApiResponse, FindCorpusByIdApiArg>({
      query: queryArg => ({ url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.id}` }),
    }),
    updateCorpus: build.mutation<UpdateCorpusApiResponse, UpdateCorpusApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.queryParameters,
      }),
      invalidatesTags: ['CORPUS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          enqueueSnackbar(translate('recommendation.corpus.corpus-updated-successfully', null, 'Corpus deleted successfully'), {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar(
            `Async error! : ${translate('recommendation.corpus.failed-to-update-corpus', null, 'failed to delete corpus')} `,
            {
              variant: 'collapsible',
              error: err.error,
            }
          );
        }
      },
    }),
    bootstrapRatings: build.mutation<BootstrapRatingsApiResponse, BootstrapRatingsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/corpus/${queryArg.id}/bootstrap-ratings`,
        method: 'POST',
      }),
      invalidatesTags: ['CORPUS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar(data.message, { variant: 'success' });
        } catch (err) {
          enqueueSnackbar(`Async error! : ${translate('recommendation.corpus.failed-to-bootstrap', null, 'failed to bootstrap corpus')} `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    aggregateImpressions: build.query<AggregateImpressionsApiResponse, AggregateImpressionsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/impressions/stats`,
        params: {
          from: queryArg['from'],
          to: queryArg.to,
          aggregateBy: queryArg.aggregateBy,
          corpusId: queryArg.corpusId,
          interval: queryArg.interval,
          fromBot: queryArg.fromBot,
          recommenderTypes: queryArg.recommenderTypes,
          itemIds: queryArg.itemIds,
        },
      }),
      extraOptions: { maxRetries: 3 },
    }),
    deleteAllItems: build.mutation<DeleteAllItemsApiResponse, DeleteAllItemsApiArg>({
      query: queryArg => ({ url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items`, method: 'DELETE' }),
      invalidatesTags: ['ITEMS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar(translate('recommendation.items.all-items-deleted-successfully', null, 'All items deleted successfully'), {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar(
            `Async error! : ${translate('recommendation.items.failed-to-delete-all-items', null, 'failed to delete all items')} `,
            {
              variant: 'collapsible',
              error: err.error,
            }
          );
        }
      },
    }),
    findAllItems: build.query<FindAllItemsApiResponse, FindAllItemsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
      providesTags: ['ITEMS_LIST'],
    }),
    createItem: build.mutation<CreateItemApiResponse, CreateItemApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items`,
        method: 'POST',
        body: queryArg.itemDoc,
      }),
      invalidatesTags: ['ITEMS_LIST'],
    }),
    createItems: build.mutation<CreateItemsApiResponse, CreateItemsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['ITEMS_LIST'],
    }),
    countItems: build.query<CountItemsApiResponse, CountItemsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/count`,
        params: { filter_query: queryArg.filterQuery, corpus: queryArg.corpus },
      }),
      providesTags: ['ITEMS_LIST'],
    }),
    uploadItemsFromCsv: build.mutation<UploadItemsFromCsvApiResponse, UploadItemsFromCsvApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/csv`,
        method: 'POST',
        body: queryArg.body,
        params: {
          delimiter: queryArg.delimiter,
          idField: queryArg.idField,
          titleField: queryArg.titleField,
          descriptionField: queryArg.descriptionField,
        },
      }),
      invalidatesTags: ['ITEMS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar(data, { variant: 'success' });
        } catch (err) {
          enqueueSnackbar(`Async error! : ${translate('recommendation.items.failed-to-upload-items', null, 'failed to upload items')} `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    uploadItemsFromJson: build.mutation<UploadItemsFromJsonApiResponse, UploadItemsFromJsonApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/json`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['ITEMS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          enqueueSnackbar(data, { variant: 'success' });
        } catch (err) {
          enqueueSnackbar(`Async error! : ${translate('recommendation.items.failed-to-upload-items', null, 'failed to upload items')} `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    deleteItemByQuery: build.mutation<DeleteItemByQueryApiResponse, DeleteItemByQueryApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/match`,
        method: 'DELETE',
        params: { query: queryArg.query, fields: queryArg.fields },
      }),
      invalidatesTags: ['ITEMS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          enqueueSnackbar(translate('recommendation.items.item-deleted-successfully', null, 'Item deleted successfully'), {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar(`Async error! : ${translate('recommendation.items.failed-to-delete-item', null, 'failed to delete item')} `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    matchItemByQuery: build.query<MatchItemByQueryApiResponse, MatchItemByQueryApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/match`,
        params: { query: queryArg.query, page: queryArg.page, size: queryArg.size },
      }),
    }),
    findRandomItems: build.mutation<FindRandomItemsApiResponse, FindRandomItemsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/random`,
        method: 'POST',
        body: queryArg.queryParameters,
      }),
    }),
    findItems: build.mutation<FindItemsApiResponse, FindItemsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/search`,
        method: 'POST',
        body: queryArg.queryParameters,
      }),
    }),
    findItemsById: build.mutation<FindItemsByIdApiResponse, FindItemsByIdApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/search/${queryArg.id}`,
        method: 'POST',
        body: queryArg.queryParameters,
      }),
    }),
    deleteItem: build.mutation<DeleteItemApiResponse, DeleteItemApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/${queryArg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ITEMS_LIST'],
    }),
    findItemById: build.query<FindItemByIdApiResponse, FindItemByIdApiArg>({
      query: queryArg => ({ url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/${queryArg.id}` }),
    }),
    updateItem: build.mutation<UpdateItemApiResponse, UpdateItemApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/items/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.itemDoc,
      }),
      invalidatesTags: ['ITEMS_LIST'],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          enqueueSnackbar(translate('recommendation.items.item-updated-successfully', null, 'Item updated successfully'), {
            variant: 'success',
          });
        } catch (err) {
          enqueueSnackbar(`Async error! : ${translate('recommendation.items.failed-to-update-item', null, 'failed to update item')} `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    load: build.mutation<LoadApiResponse, LoadApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/metrics/load`,
        method: 'POST',
        body: queryArg.metricsRequest,
      }),
    }),
    addAction: build.mutation<AddActionApiResponse, AddActionApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/profiles/actions`,
        method: 'POST',
        body: queryArg.action,
      }),
    }),
    addActions: build.mutation<AddActionsApiResponse, AddActionsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/profiles/actions/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    dumpAllProfiles: build.mutation<DumpAllProfilesApiResponse, DumpAllProfilesApiArg>({
      query: queryArg => ({ url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/profiles/dump`, method: 'POST' }),
    }),
    getRandom: build.query<GetRandomApiResponse, GetRandomApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/profiles/random`,
        params: { limit: queryArg.limit, page: queryArg.page },
      }),
    }),
    getRandomIds: build.query<GetRandomIdsApiResponse, GetRandomIdsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/profiles/random/ids`,
        params: { limit: queryArg.limit, page: queryArg.page },
      }),
    }),
    getProfile: build.query<GetProfileApiResponse, GetProfileApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/profiles/${queryArg.id}`,
        params: { cachedProfiles: queryArg.cachedProfiles, partyIdLookup: queryArg.partyIdLookup },
      }),
    }),
    findAllModelDeployments: build.query<FindAllModelDeploymentsApiResponse, FindAllModelDeploymentsApiArg>({
      query: queryArg => ({ url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/ranking/model-deployments` }),
    }),
    saveModelDeployment: build.mutation<SaveModelDeploymentApiResponse, SaveModelDeploymentApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/ranking/model-deployments`,
        method: 'POST',
        body: queryArg.modelDeployment,
      }),
    }),
    findModelDeploymentsById: build.query<FindModelDeploymentsByIdApiResponse, FindModelDeploymentsByIdApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/ranking/model-deployments/${queryArg.id}`,
      }),
    }),
    saveRankingMetrics: build.mutation<SaveRankingMetricsApiResponse, SaveRankingMetricsApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/ranking/ranking-metrics`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    findRatings1: build.query<FindRatings1ApiResponse, FindRatings1ApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/ratings/corpus/${queryArg.corpusId}/items/${
          queryArg.itemId
        }`,
      }),
    }),
    findRatings: build.query<FindRatingsApiResponse, FindRatingsApiArg>({
      query: queryArg => ({ url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/ratings/items/${queryArg.id}` }),
    }),
    getRecos: build.mutation<GetRecosApiResponse, GetRecosApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations`,
        method: 'POST',
        body: queryArg.queryParameters,
        params: { profileId: queryArg.profileId, itemId: queryArg.itemId },
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          enqueueSnackbar(`Async error! : failed to get recommendations `, {
            variant: 'collapsible',
            error: err.error,
          });
        }
      },
    }),
    getAlsRecos: build.query<GetAlsRecosApiResponse, GetAlsRecosApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations/collaborative-filtering/${
          queryArg.profileId
        }`,
        params: {
          corpusId: queryArg.corpusId,
          max_item_count: queryArg.maxItemCount,
          bot_query: queryArg.botQuery,
          dry_run: queryArg.dryRun,
          debug: queryArg.debug,
        },
      }),
    }),
    getBoostedTermsRecos: build.query<GetBoostedTermsRecosApiResponse, GetBoostedTermsRecosApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations/content-based-filtering/${
          queryArg.profileId
        }`,
        params: {
          corpusId: queryArg.corpusId,
          max_item_count: queryArg.maxItemCount,
          bot_query: queryArg.botQuery,
          dry_run: queryArg.dryRun,
          debug: queryArg.debug,
        },
      }),
    }),
    getFrequentItemsetRecos: build.query<GetFrequentItemsetRecosApiResponse, GetFrequentItemsetRecosApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations/frequently-bought-together/${
          queryArg.itemId
        }`,
        params: {
          corpusId: queryArg.corpusId,
          profileId: queryArg.profileId,
          max_item_count: queryArg.maxItemCount,
          bot_query: queryArg.botQuery,
          dry_run: queryArg.dryRun,
          debug: queryArg.debug,
        },
      }),
    }),
    getPopularRecos: build.query<GetPopularRecosApiResponse, GetPopularRecosApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations/popular`,
        params: {
          corpusId: queryArg.corpusId,
          profileId: queryArg.profileId,
          max_item_count: queryArg.maxItemCount,
          bot_query: queryArg.botQuery,
          dry_run: queryArg.dryRun,
          debug: queryArg.debug,
        },
      }),
    }),
    getRandomRecos: build.query<GetRandomRecosApiResponse, GetRandomRecosApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations/random`,
        params: {
          corpusId: queryArg.corpusId,
          profileId: queryArg.profileId,
          max_item_count: queryArg.maxItemCount,
          bot_query: queryArg.botQuery,
          dry_run: queryArg.dryRun,
          debug: queryArg.debug,
        },
      }),
    }),
    getSimilarItemsRecos: build.query<GetSimilarItemsRecosApiResponse, GetSimilarItemsRecosApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations/similar-items/${queryArg.itemId}`,
        params: {
          corpusId: queryArg.corpusId,
          profileId: queryArg.profileId,
          max_item_count: queryArg.maxItemCount,
          bot_query: queryArg.botQuery,
          dry_run: queryArg.dryRun,
          debug: queryArg.debug,
        },
      }),
    }),
    getTrendingRecos: build.query<GetTrendingRecosApiResponse, GetTrendingRecosApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations/trending`,
        params: {
          corpusId: queryArg.corpusId,
          profileId: queryArg.profileId,
          max_item_count: queryArg.maxItemCount,
          bot_query: queryArg.botQuery,
          dry_run: queryArg.dryRun,
          debug: queryArg.debug,
        },
      }),
    }),
    getTrendingRecos1: build.query<GetTrendingRecos1ApiResponse, GetTrendingRecos1ApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/recommendations/trending/${queryArg.scale}`,
        params: {
          corpusId: queryArg.corpusId,
          profileId: queryArg.profileId,
          max_item_count: queryArg.maxItemCount,
          bot_query: queryArg.botQuery,
          dry_run: queryArg.dryRun,
          debug: queryArg.debug,
        },
      }),
    }),
    popularity: build.query<PopularityApiResponse, PopularityApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/stats/popularity/${queryArg.corpusId}`,
        params: { maxItemCount: queryArg.maxItemCount },
      }),
    }),
    trending: build.query<TrendingApiResponse, TrendingApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/stats/trending/${queryArg.corpusId}`,
        params: { maxItemCount: queryArg.maxItemCount },
      }),
    }),
    trending1: build.query<Trending1ApiResponse, Trending1ApiArg>({
      query: queryArg => ({
        url: `/reco-engine-${queryArg.accountId}/api/${queryArg.version ?? 'latest'}/stats/trending/${queryArg.corpusId}/${queryArg.scale}`,
        params: { maxItemCount: queryArg.maxItemCount },
      }),
    }),
    checkHealth: build.query<CheckHealthApiResponse, CheckHealthApiArg>({
      query: queryArg => ({ url: `/reco-engine-${queryArg.accountId}/management/health` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as recommenderEngineApi };

export type AggregateActionsApiResponse = /** status 200 OK */ EsQueryResponse<unknown>;
export type AggregateActionsApiArg = {
  version?: string;
  accountId: string;
  from?: string;
  to?: string;
  aggregateBy?: string[];
  corpusId?: string;
  existsRecoUuid?: boolean;
  interval?: string;
  types?: (
    | 'view'
    | 'buy'
    | 'like'
    | 'search'
    | 'comment'
    | 'share'
    | 'basket'
    | 'ml_recommended'
    | 'favorite'
    | 'click_slides'
    | 'video_engagement'
    | 'page_scroll'
    | 'pageview'
    | 'download_abstract'
    | 'click_nav'
    | 'unknown'
    | 'bookmark_remove'
    | 'video_play_request'
  )[];
  sources?: ('unknown' | 'http_rest' | 'kafka_stream' | 'action_generator')[]; // multi selector
  profileIds?: string[]; // filter
  itemIds?: string[]; // filter
};
export type FindAllCorporaApiResponse = /** status 200 OK */ PageCorpus;
export type FindAllCorporaApiArg = {
  version?: string;
  accountId: string;
  page?: number;
  size?: number;
};
export type CreateCorpusApiResponse = /** status 200 OK */ BootstrapRatingsResponse;
export type CreateCorpusApiArg = {
  version?: string;
  accountId: string;
  queryParameters: QueryParameters;
};
export type DisableDemoApiResponse = /** status 200 OK */ object;
export type DisableDemoApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
};
export type IsDemoEnabledApiResponse = /** status 200 OK */ object;
export type IsDemoEnabledApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
};
export type EnableDemoApiResponse = /** status 200 OK */ object;
export type EnableDemoApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
};
export type RunningSimulationApiResponse = /** status 200 OK */ object;
export type RunningSimulationApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
};
export type StartSimulationApiResponse = /** status 200 OK */ string;
export type StartSimulationApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
};
export type StopSimulationApiResponse = /** status 200 OK */ string;
export type StopSimulationApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
};
export type DeleteCorpusApiResponse = /** status 200 OK */ object;
export type DeleteCorpusApiArg = {
  version?: string;
  accountId: string;
  id: string;
};
export type FindCorpusByIdApiResponse = /** status 200 OK */ Corpus;
export type FindCorpusByIdApiArg = {
  version?: string;
  accountId: string;
  id: string;
};
export type UpdateCorpusApiResponse = /** status 200 OK */ BootstrapRatingsResponse;
export type UpdateCorpusApiArg = {
  version?: string;
  accountId: string;
  id: string;
  queryParameters: QueryParameters;
};
export type BootstrapRatingsApiResponse = /** status 200 OK */ BootstrapRatingsResponse;
export type BootstrapRatingsApiArg = {
  version?: string;
  accountId: string;
  id: string;
};
export type AggregateImpressionsApiResponse = /** status 200 OK */ EsQueryResponse<unknown>;
export type AggregateImpressionsApiArg = {
  version?: string;
  accountId: string;
  from?: string;
  to?: string;
  aggregateBy?: string[];
  corpusId?: string;
  interval?: string;
  fromBot?: boolean;
  recommenderTypes?: (
    | 'als'
    | 'boosted_terms'
    | 'more_like_this'
    | 'frequent_itemset'
    | 'popularity'
    | 'trending'
    | 'trending_short'
    | 'trending_long'
    | 'random'
  )[];
  itemIds?: string[];
};
export type DeleteAllItemsApiResponse = /** status 200 OK */ object;
export type DeleteAllItemsApiArg = {
  version?: string;
  accountId: string;
};
export type FindAllItemsApiResponse = /** status 200 OK */ PageItemDoc;
export type FindAllItemsApiArg = {
  version?: string;
  accountId: string;
  page?: number;
  size?: number;
};
export type CreateItemApiResponse = /** status 200 OK */ ItemDoc;
export type CreateItemApiArg = {
  version?: string;
  accountId: string;
  itemDoc: ItemDoc;
};
export type CreateItemsApiResponse = /** status 200 OK */ string;
export type CreateItemsApiArg = {
  version?: string;
  accountId: string;
  body: ItemDoc[];
};
export type CountItemsApiResponse = /** status 200 OK */ number;
export type CountItemsApiArg = {
  version?: string;
  accountId: string;
  filterQuery: string;
  corpus?: string;
};
export type UploadItemsFromCsvApiResponse = /** status 200 OK */ string;
export type UploadItemsFromCsvApiArg = {
  version?: string;
  accountId: string;
  delimiter?: string;
  idField?: string;
  titleField?: string;
  descriptionField?: string;
  body: any;
};
export type UploadItemsFromJsonApiResponse = /** status 200 OK */ string;
export type UploadItemsFromJsonApiArg = {
  version?: string;
  accountId: string;
  body: any;
};
export type DeleteItemByQueryApiResponse = /** status 200 OK */ BulkByScrollResponse;
export type DeleteItemByQueryApiArg = {
  version?: string;
  accountId: string;
  query: string;
  fields?: string;
};
export type MatchItemByQueryApiResponse = /** status 200 OK */ PageItemDoc;
export type MatchItemByQueryApiArg = {
  version?: string;
  accountId: string;
  query: string;
  page?: number;
  size?: number;
};
export type FindRandomItemsApiResponse = /** status 200 OK */ object;
export type FindRandomItemsApiArg = {
  version?: string;
  accountId: string;
  queryParameters: QueryParameters;
};
export type FindItemsApiResponse = /** status 200 OK */ object;
export type FindItemsApiArg = {
  version?: string;
  accountId: string;
  queryParameters: QueryParameters;
};
export type FindItemsByIdApiResponse = /** status 200 OK */ object;
export type FindItemsByIdApiArg = {
  version?: string;
  accountId: string;
  id: string;
  queryParameters: QueryParameters;
};
export type DeleteItemApiResponse = /** status 200 OK */ object;
export type DeleteItemApiArg = {
  version?: string;
  accountId: string;
  id: string;
};
export type FindItemByIdApiResponse = /** status 200 OK */ ItemDoc;
export type FindItemByIdApiArg = {
  version?: string;
  accountId: string;
  id: string;
};
export type UpdateItemApiResponse = /** status 200 OK */ ItemDoc;
export type UpdateItemApiArg = {
  version?: string;
  accountId: string;
  id: string;
  itemDoc: ItemDoc;
};
export type LoadApiResponse = /** status 200 OK */ object;
export type LoadApiArg = {
  version?: string;
  accountId: string;
  metricsRequest: MetricsRequest;
};
export type AddActionApiResponse = /** status 200 OK */ Profile;
export type AddActionApiArg = {
  version?: string;
  accountId: string;
  action: Action;
};
export type AddActionsApiResponse = /** status 200 OK */ object;
export type AddActionsApiArg = {
  version?: string;
  accountId: string;
  body: Action[];
};
export type DumpAllProfilesApiResponse = /** status 200 OK */ string;
export type DumpAllProfilesApiArg = {
  version?: string;
  accountId: string;
};
export type GetRandomApiResponse = /** status 200 OK */ object;
export type GetRandomApiArg = {
  version?: string;
  accountId: string;
  limit?: number;
  page?: number;
};
export type GetRandomIdsApiResponse = /** status 200 OK */ object;
export type GetRandomIdsApiArg = {
  version?: string;
  accountId: string;
  limit?: number;
  page?: number;
};
export type GetProfileApiResponse = /** status 200 OK */ Profile;
export type GetProfileApiArg = {
  version?: string;
  accountId: string;
  id: string;
  cachedProfiles?: boolean;
  partyIdLookup?: boolean;
};
export type FindAllModelDeploymentsApiResponse = /** status 200 OK */ object;
export type FindAllModelDeploymentsApiArg = {
  version?: string;
  accountId: string;
};
export type SaveModelDeploymentApiResponse = /** status 200 OK */ ModelDeployment;
export type SaveModelDeploymentApiArg = {
  version?: string;
  accountId: string;
  modelDeployment: ModelDeployment;
};
export type FindModelDeploymentsByIdApiResponse = /** status 200 OK */ ModelDeployment;
export type FindModelDeploymentsByIdApiArg = {
  version?: string;
  accountId: string;
  id: string;
};
export type SaveRankingMetricsApiResponse = /** status 200 OK */ object;
export type SaveRankingMetricsApiArg = {
  version?: string;
  accountId: string;
  body: RankingMetrics[];
};
export type FindRatings1ApiResponse = /** status 200 OK */ object;
export type FindRatings1ApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  itemId: string;
};
export type FindRatingsApiResponse = /** status 200 OK */ object;
export type FindRatingsApiArg = {
  version?: string;
  accountId: string;
  id: string;
};
export type GetRecosApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetRecosApiArg = {
  version?: string;
  accountId: string;
  profileId?: string;
  itemId?: string;
  queryParameters: QueryParameters;
};
export type GetAlsRecosApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetAlsRecosApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  profileId: string;
  maxItemCount?: number;
  botQuery?: boolean;
  dryRun?: boolean;
  debug?: boolean;
};
export type GetBoostedTermsRecosApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetBoostedTermsRecosApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  profileId: string;
  maxItemCount?: number;
  botQuery?: boolean;
  dryRun?: boolean;
  debug?: boolean;
};
export type GetFrequentItemsetRecosApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetFrequentItemsetRecosApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  itemId: string;
  profileId?: string;
  maxItemCount?: number;
  botQuery?: boolean;
  dryRun?: boolean;
  debug?: boolean;
};
export type GetPopularRecosApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetPopularRecosApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  profileId?: string;
  maxItemCount?: number;
  botQuery?: boolean;
  dryRun?: boolean;
  debug?: boolean;
};
export type GetRandomRecosApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetRandomRecosApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  profileId?: string;
  maxItemCount?: number;
  botQuery?: boolean;
  dryRun?: boolean;
  debug?: boolean;
};
export type GetSimilarItemsRecosApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetSimilarItemsRecosApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  itemId: string;
  profileId?: string;
  maxItemCount?: number;
  botQuery?: boolean;
  dryRun?: boolean;
  debug?: boolean;
};
export type GetTrendingRecosApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetTrendingRecosApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  profileId?: string;
  maxItemCount?: number;
  botQuery?: boolean;
  dryRun?: boolean;
  debug?: boolean;
};
export type GetTrendingRecos1ApiResponse = /** status 200 OK */ RecommendationResponse;
export type GetTrendingRecos1ApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  scale: 'short_term' | 'long_term';
  profileId?: string;
  maxItemCount?: number;
  botQuery?: boolean;
  dryRun?: boolean;
  debug?: boolean;
};
export type PopularityApiResponse = /** status 200 OK */ object;
export type PopularityApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  maxItemCount?: number;
};
export type TrendingApiResponse = /** status 200 OK */ object;
export type TrendingApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  maxItemCount?: number;
};
export type Trending1ApiResponse = /** status 200 OK */ object;
export type Trending1ApiArg = {
  version?: string;
  accountId: string;
  corpusId: string;
  scale: 'short_term' | 'long_term';
  maxItemCount?: number;
};
export type CheckHealthApiResponse = /** status 200 OK */ object;
export type CheckHealthApiArg = {
  accountId: string;
};
export type Corpus = {
  bootstrapper_max_doc_freq?: number;
  bootstrapper_max_ratings_per_item?: number;
  bootstrapping_date?: string;
  demo_enabled?: boolean;
  filter_query?: string;
  filter_query_fields?: string;
  filter_query_source_excludes?: string;
  filter_query_source_includes?: string;
  id?: string;
  itemIndices?: string;
  item_count?: number;
  name?: string;
};
export type SortObject = {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
};
export type PageableObject = {
  offset?: number;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  sort?: SortObject;
  unpaged?: boolean;
};
export type PageCorpus = {
  content?: Corpus[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject;
  totalElements?: number;
  totalPages?: number;
};
export type QueryParameters = {
  bootstrapper_max_doc_freq?: number;
  bootstrapper_max_ratings_per_item?: number;
  bot_query?: boolean;
  cache_profile?: boolean;
  corpus?: string;
  debug?: boolean;
  demo_enabled?: boolean;
  dry_run?: boolean;
  eligible_action_types?: string[];
  filter_query?: string;
  filter_query_fields?: string;
  filter_query_source_excludes?: string;
  filter_query_source_includes?: string;
  id_field_name?: string;
  item_indices?: string;
  item_max_age_ms?: number;
  max_item_count?: number;
  party_ID_lookup?: boolean;
  profile_indices?: string;
  recommended_item_indices?: string;
  recommender_type?:
    | 'als'
    | 'boosted_terms'
    | 'more_like_this'
    | 'frequent_itemset'
    | 'popularity'
    | 'trending'
    | 'trending_short'
    | 'trending_long'
    | 'random';
  time_limit_field?: string;
  trending_scale?: 'short_term' | 'long_term';
};
export type Rating = {
  bootstrapping_date?: string;
  corpus?: string;
  field?: string;
  id?: string;
  itemId?: string;
  score?: number;
  term?: string;
};
export type BootstrapRatingsResponse = {
  corpus?: Corpus;
  items_count?: number;
  message?: string;
  parameters?: QueryParameters;
  ratings_count?: number;
  sample_ratings?: Rating[];
  start_date?: string;
  took?: number;
};
export type ItemDoc = {
  attributes?: {
    [key: string]: string;
  };
  description?: string;
  id?: string;
  title?: string;
};
export type PageItemDoc = {
  content?: ItemDoc[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: PageableObject;
  size?: number;
  sort?: SortObject;
  totalElements?: number;
  totalPages?: number;
};
export type Failure = {
  aborted?: boolean;
  cause?: {
    cause?: {
      localizedMessage?: string;
      message?: string;
      stackTrace?: {
        classLoaderName?: string;
        className?: string;
        fileName?: string;
        lineNumber?: number;
        methodName?: string;
        moduleName?: string;
        moduleVersion?: string;
        nativeMethod?: boolean;
      }[];
      suppressed?: {
        localizedMessage?: string;
        message?: string;
        stackTrace?: {
          classLoaderName?: string;
          className?: string;
          fileName?: string;
          lineNumber?: number;
          methodName?: string;
          moduleName?: string;
          moduleVersion?: string;
          nativeMethod?: boolean;
        }[];
      }[];
    };
    localizedMessage?: string;
    message?: string;
    stackTrace?: {
      classLoaderName?: string;
      className?: string;
      fileName?: string;
      lineNumber?: number;
      methodName?: string;
      moduleName?: string;
      moduleVersion?: string;
      nativeMethod?: boolean;
    }[];
    suppressed?: {
      localizedMessage?: string;
      message?: string;
      stackTrace?: {
        classLoaderName?: string;
        className?: string;
        fileName?: string;
        lineNumber?: number;
        methodName?: string;
        moduleName?: string;
        moduleVersion?: string;
        nativeMethod?: boolean;
      }[];
    }[];
  };
  fragment?: boolean;
  id?: string;
  index?: string;
  message?: string;
  seqNo?: number;
  status?:
    | 'CONTINUE'
    | 'SWITCHING_PROTOCOLS'
    | 'OK'
    | 'CREATED'
    | 'ACCEPTED'
    | 'NON_AUTHORITATIVE_INFORMATION'
    | 'NO_CONTENT'
    | 'RESET_CONTENT'
    | 'PARTIAL_CONTENT'
    | 'MULTI_STATUS'
    | 'MULTIPLE_CHOICES'
    | 'MOVED_PERMANENTLY'
    | 'FOUND'
    | 'SEE_OTHER'
    | 'NOT_MODIFIED'
    | 'USE_PROXY'
    | 'TEMPORARY_REDIRECT'
    | 'BAD_REQUEST'
    | 'UNAUTHORIZED'
    | 'PAYMENT_REQUIRED'
    | 'FORBIDDEN'
    | 'NOT_FOUND'
    | 'METHOD_NOT_ALLOWED'
    | 'NOT_ACCEPTABLE'
    | 'PROXY_AUTHENTICATION'
    | 'REQUEST_TIMEOUT'
    | 'CONFLICT'
    | 'GONE'
    | 'LENGTH_REQUIRED'
    | 'PRECONDITION_FAILED'
    | 'REQUEST_ENTITY_TOO_LARGE'
    | 'REQUEST_URI_TOO_LONG'
    | 'UNSUPPORTED_MEDIA_TYPE'
    | 'REQUESTED_RANGE_NOT_SATISFIED'
    | 'EXPECTATION_FAILED'
    | 'UNPROCESSABLE_ENTITY'
    | 'LOCKED'
    | 'FAILED_DEPENDENCY'
    | 'TOO_MANY_REQUESTS'
    | 'INTERNAL_SERVER_ERROR'
    | 'NOT_IMPLEMENTED'
    | 'BAD_GATEWAY'
    | 'SERVICE_UNAVAILABLE'
    | 'GATEWAY_TIMEOUT'
    | 'HTTP_VERSION_NOT_SUPPORTED'
    | 'INSUFFICIENT_STORAGE';
  term?: number;
  type?: string;
};
export type SearchFailure = {
  fragment?: boolean;
  index?: string;
  nodeId?: string;
  reason?: {
    localizedMessage?: string;
    message?: string;
    stackTrace?: {
      classLoaderName?: string;
      className?: string;
      fileName?: string;
      lineNumber?: number;
      methodName?: string;
      moduleName?: string;
      moduleVersion?: string;
      nativeMethod?: boolean;
    }[];
    suppressed?: {
      localizedMessage?: string;
      message?: string;
      stackTrace?: {
        classLoaderName?: string;
        className?: string;
        fileName?: string;
        lineNumber?: number;
        methodName?: string;
        moduleName?: string;
        moduleVersion?: string;
        nativeMethod?: boolean;
      }[];
    }[];
  };
  shardId?: number;
  status?:
    | 'CONTINUE'
    | 'SWITCHING_PROTOCOLS'
    | 'OK'
    | 'CREATED'
    | 'ACCEPTED'
    | 'NON_AUTHORITATIVE_INFORMATION'
    | 'NO_CONTENT'
    | 'RESET_CONTENT'
    | 'PARTIAL_CONTENT'
    | 'MULTI_STATUS'
    | 'MULTIPLE_CHOICES'
    | 'MOVED_PERMANENTLY'
    | 'FOUND'
    | 'SEE_OTHER'
    | 'NOT_MODIFIED'
    | 'USE_PROXY'
    | 'TEMPORARY_REDIRECT'
    | 'BAD_REQUEST'
    | 'UNAUTHORIZED'
    | 'PAYMENT_REQUIRED'
    | 'FORBIDDEN'
    | 'NOT_FOUND'
    | 'METHOD_NOT_ALLOWED'
    | 'NOT_ACCEPTABLE'
    | 'PROXY_AUTHENTICATION'
    | 'REQUEST_TIMEOUT'
    | 'CONFLICT'
    | 'GONE'
    | 'LENGTH_REQUIRED'
    | 'PRECONDITION_FAILED'
    | 'REQUEST_ENTITY_TOO_LARGE'
    | 'REQUEST_URI_TOO_LONG'
    | 'UNSUPPORTED_MEDIA_TYPE'
    | 'REQUESTED_RANGE_NOT_SATISFIED'
    | 'EXPECTATION_FAILED'
    | 'UNPROCESSABLE_ENTITY'
    | 'LOCKED'
    | 'FAILED_DEPENDENCY'
    | 'TOO_MANY_REQUESTS'
    | 'INTERNAL_SERVER_ERROR'
    | 'NOT_IMPLEMENTED'
    | 'BAD_GATEWAY'
    | 'SERVICE_UNAVAILABLE'
    | 'GATEWAY_TIMEOUT'
    | 'HTTP_VERSION_NOT_SUPPORTED'
    | 'INSUFFICIENT_STORAGE';
};
export type StatusOrException = {
  exception?: {
    cause?: {
      localizedMessage?: string;
      message?: string;
      stackTrace?: {
        classLoaderName?: string;
        className?: string;
        fileName?: string;
        lineNumber?: number;
        methodName?: string;
        moduleName?: string;
        moduleVersion?: string;
        nativeMethod?: boolean;
      }[];
      suppressed?: {
        localizedMessage?: string;
        message?: string;
        stackTrace?: {
          classLoaderName?: string;
          className?: string;
          fileName?: string;
          lineNumber?: number;
          methodName?: string;
          moduleName?: string;
          moduleVersion?: string;
          nativeMethod?: boolean;
        }[];
      }[];
    };
    localizedMessage?: string;
    message?: string;
    stackTrace?: {
      classLoaderName?: string;
      className?: string;
      fileName?: string;
      lineNumber?: number;
      methodName?: string;
      moduleName?: string;
      moduleVersion?: string;
      nativeMethod?: boolean;
    }[];
    suppressed?: {
      localizedMessage?: string;
      message?: string;
      stackTrace?: {
        classLoaderName?: string;
        className?: string;
        fileName?: string;
        lineNumber?: number;
        methodName?: string;
        moduleName?: string;
        moduleVersion?: string;
        nativeMethod?: boolean;
      }[];
    }[];
  };
  fragment?: boolean;
  status?: Status;
};
export type TimeValue = {
  days?: number;
  daysFrac?: number;
  hours?: number;
  hoursFrac?: number;
  micros?: number;
  microsFrac?: number;
  millis?: number;
  millisFrac?: number;
  minutes?: number;
  minutesFrac?: number;
  nanos?: number;
  seconds?: number;
  secondsFrac?: number;
  stringRep?: string;
};
export type Status = {
  batches?: number;
  bulkRetries?: number;
  created?: number;
  deleted?: number;
  fragment?: boolean;
  noops?: number;
  reasonCancelled?: string;
  requestsPerSecond?: number;
  searchRetries?: number;
  sliceStatuses?: StatusOrException[];
  successfullyProcessed?: number;
  throttled?: TimeValue;
  throttledUntil?: TimeValue;
  total?: number;
  updated?: number;
  versionConflicts?: number;
  writeableName?: string;
};
export type BulkByScrollResponse = {
  batches?: number;
  bulkFailures?: Failure[];
  bulkRetries?: number;
  created?: number;
  deleted?: number;
  fragment?: boolean;
  noops?: number;
  reasonCancelled?: string;
  searchFailures?: SearchFailure[];
  searchRetries?: number;
  status?: Status;
  timedOut?: boolean;
  took?: TimeValue;
  total?: number;
  updated?: number;
  versionConflicts?: number;
};
export type MetricsRequest = {
  end?: string;
  name?: string;
  query?: string;
  range?: boolean;
  start?: string;
  step?: string;
  tags?: {
    [key: string]: string;
  };
};
export type Action = {
  corpus?: string;
  count?: number;
  id?: string;
  item_id?: string;
  profile_id?: string;
  reco_uuid?: string;
  source?: 'unknown' | 'http_rest' | 'kafka_stream' | 'action_generator';
  timestamp?: number;
  type?:
    | 'view'
    | 'buy'
    | 'like'
    | 'search'
    | 'comment'
    | 'share'
    | 'basket'
    | 'ml_recommended'
    | 'favorite'
    | 'click_slides'
    | 'video_engagement'
    | 'page_scroll'
    | 'pageview'
    | 'download_abstract'
    | 'click_nav'
    | 'unknown'
    | 'bookmark_remove'
    | 'video_play_request';
};
export type Profile = {
  actions?: Action[];
  expiration?: number;
  id?: string;
  maxActionsSize?: number;
  recommendRatings?: Rating[];
};
export type ModelDeployment = {
  deployment_date?: string;
  endpoint_url?: string;
  id?: string;
  model_type?: string;
  parameters?: {
    [key: string]: string;
  };
  version?: string;
};
export type RankingMetrics = {
  computation_date?: string;
  coverage?: number;
  id?: string;
  map?: number[];
  model_deployment_id?: string;
  ndcg?: number[];
  novelty?: number;
  personalisation?: number;
  precision?: number[];
  recall?: number[];
};
export type RecommendationResponse = {
  impressions?: object;
  query?: string;
};
export const {
  useAggregateActionsQuery,
  useFindAllCorporaQuery,
  useLazyFindAllCorporaQuery,
  useCreateCorpusMutation,
  useDisableDemoMutation,
  useIsDemoEnabledQuery,
  useEnableDemoMutation,
  useRunningSimulationQuery,
  useStartSimulationMutation,
  useStopSimulationMutation,
  useDeleteCorpusMutation,
  useFindCorpusByIdQuery,
  useUpdateCorpusMutation,
  useBootstrapRatingsMutation,
  useAggregateImpressionsQuery,
  useDeleteAllItemsMutation,
  useFindAllItemsQuery,
  useLazyFindAllItemsQuery,
  useCreateItemMutation,
  useCreateItemsMutation,
  useCountItemsQuery,
  useUploadItemsFromCsvMutation,
  useUploadItemsFromJsonMutation,
  useDeleteItemByQueryMutation,
  useMatchItemByQueryQuery,
  useLazyMatchItemByQueryQuery,
  useFindRandomItemsMutation,
  useFindItemsMutation,
  useFindItemsByIdMutation,
  useDeleteItemMutation,
  useFindItemByIdQuery,
  useLazyFindItemByIdQuery,
  useUpdateItemMutation,
  useLoadMutation,
  useAddActionMutation,
  useAddActionsMutation,
  useDumpAllProfilesMutation,
  useGetRandomQuery,
  useGetRandomIdsQuery,
  useGetProfileQuery,
  useFindAllModelDeploymentsQuery,
  useSaveModelDeploymentMutation,
  useFindModelDeploymentsByIdQuery,
  useSaveRankingMetricsMutation,
  useFindRatings1Query,
  useFindRatingsQuery,
  useGetRecosMutation,
  useGetAlsRecosQuery,
  useGetBoostedTermsRecosQuery,
  useGetFrequentItemsetRecosQuery,
  useGetPopularRecosQuery,
  useGetRandomRecosQuery,
  useGetSimilarItemsRecosQuery,
  useGetTrendingRecosQuery,
  useGetTrendingRecos1Query,
  usePopularityQuery,
  useTrendingQuery,
  useTrending1Query,
  useCheckHealthQuery,
} = injectedRtkApi;

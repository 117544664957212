import React from 'react';

import { TabItemProps } from 'app/components/tabulation';
import { translate } from 'react-jhipster';
import { LineKPIChart } from '../charts/LineKPIChart';
import { useLineChartsTabsData } from '../hooks/useLineChartsTabsData';
import { KPIS } from 'app/shared/util/reducer-utils';
import { getTotal } from '../../../utils/chartsTotalUtils';

export const tabulations = (): TabItemProps[] => {
  const { data, isFetching, error, isError } = useLineChartsTabsData();
  return [
    {
      tab: { label: `${translate('web-analytics.WebInfos.users', null, 'Users')} ${getTotal(data[KPIS.USER_COUNT.name])}` },
      children: (
        <LineKPIChart
          data={data[KPIS.USER_COUNT.name]}
          kpi={KPIS.USER_COUNT.name}
          isFetching={isFetching}
          error={error}
          isError={isError}
        />
      ),
      panel: {
        sx: {
          height: '100%',
          p: 0,
        },
      },
    },
    {
      tab: { label: `${translate('web-analytics.WebInfos.webSessions', null, 'Sessions')} ${getTotal(data[KPIS.SESSION_COUNT.name])}` },
      children: (
        <LineKPIChart
          data={data[KPIS.SESSION_COUNT.name]}
          kpi={KPIS.SESSION_COUNT.name}
          isFetching={isFetching}
          error={error}
          isError={isError}
        />
      ),
      panel: {
        sx: {
          height: '100%',
          p: 0,
        },
      },
    },
    {
      tab: { label: `${translate('web-analytics.WebInfos.bounceRate', null, 'Bounce Rate')}` },
      children: (
        <LineKPIChart
          data={data[KPIS.BOUNCE_RATE.name]}
          kpi={KPIS.BOUNCE_RATE.name}
          isFetching={isFetching}
          error={error}
          isError={isError}
        />
      ),
      panel: {
        sx: {
          height: '100%',
          p: 0,
        },
      },
    },
    {
      tab: { label: `${translate('web-analytics.WebInfos.webSessionsDuration', null, 'Session Duration')} ` },
      children: (
        <LineKPIChart
          data={data[KPIS.AVG_SESSION_DURATION.name]}
          kpi={KPIS.AVG_SESSION_DURATION.name}
          isFetching={isFetching}
          error={error}
          isError={isError}
        />
      ),
      panel: {
        sx: {
          height: '100%',
          p: 0,
        },
      },
    },
  ];
};

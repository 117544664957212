import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FaLinux, FaWindows } from 'react-icons/fa';

export const SessionOs = ({ os }) => {
  let icon;

  if (String(os) === 'Windows') icon = <FaWindows size="22px" color="black" />;
  else if (String(os) === 'Linux') icon = <FaLinux size="22px" color="black" />;

  return (
    <Box display="flex" alignItems="center" justifyContent="end">
      {icon}
      <Typography>{os}</Typography>
    </Box>
  );
};

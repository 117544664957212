import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  fromDate: 'now-7d/d',
  toDate: 'now',
  refreshRate: 60000,
  refresh: false,
  step: 'Auto',
  liveButtonState: {
    isLive: false,
    fromDate: '',
    toDate: '',
    refreshRate: 60000,
    step: 'Auto',
  },
};

export type TimeRangeState = Readonly<typeof initialState>;

export const TimeRangeSlice = createSlice({
  name: 'timeRangeReducer',
  initialState: initialState as TimeRangeState,
  reducers: {
    setFromDate(state, action) {
      state.fromDate = action.payload;
    },
    setToDate(state, action) {
      state.toDate = action.payload;
    },
    setRefreshRate(state, action) {
      state.refreshRate = action.payload;
    },
    setRefresh(state, action) {
      state.refresh = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    setLiveOn(state) {
      state.liveButtonState.isLive = !state.liveButtonState.isLive;
      state.liveButtonState.fromDate = state.fromDate;
      state.liveButtonState.toDate = state.toDate;
      state.liveButtonState.refreshRate = state.refreshRate;
      state.liveButtonState.step = state.step;
      state.fromDate = 'now-1h/h';
      state.toDate = 'now';
      state.refreshRate = 15000;
      state.step = 'PT1M';
    },
    setLiveOff(state) {
      state.fromDate = state.liveButtonState.fromDate;
      state.toDate = state.liveButtonState.toDate;
      state.refreshRate = state.liveButtonState.refreshRate;
      state.step = state.liveButtonState.step;
      state.liveButtonState.isLive = !state.liveButtonState.isLive;
      state.liveButtonState.fromDate = '';
      state.liveButtonState.toDate = '';
      state.liveButtonState.refreshRate = 0;
      state.liveButtonState.step = '';
    },
  },
});

export const { setFromDate, setToDate, setRefreshRate, setRefresh, setStep, setLiveOn, setLiveOff } = TimeRangeSlice.actions;

export default TimeRangeSlice.reducer;

import React from 'react';
import { EmailTwoTone, LocationOnTwoTone, LocationCityTwoTone, MarkunreadMailboxTwoTone, PublicTwoTone } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { translate } from 'react-jhipster';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { FieldProp } from 'app/modules/form-builder-lib';

const countryOptions = ['FR', 'US'];

export const EditAttributes = (): Array<FieldProp> => {
  return [
    {
      component: 'text-field',
      validationType: 'string',
      attribute: 'email',
      label: 'account.account-page.email',
      validations: [['email', true]],
      props: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <EmailTwoTone />
            </InputAdornment>
          ),
        },
      },
      col: {
        md: 6,
      },
    },
    {
      component: 'text-field',
      validationType: 'string',
      attribute: 'address1',
      label: 'account.account-page.address',
      props: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnTwoTone />
            </InputAdornment>
          ),
        },
      },
      /* validations: [['matches', [/[a-z]/i, 'Can only contain letters']]], */
      col: {
        md: 6,
      },
    },
    {
      component: 'text-field',
      col: {
        md: 6,
      },
      validationType: 'string',
      attribute: 'city',
      label: 'account.account-page.city',
      props: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <LocationCityTwoTone />
            </InputAdornment>
          ),
        },
      },
      /* validations: [['matches', [/[a-z]/i, 'Can only contain letters']]], */
    },
    {
      component: 'text-field',
      col: {
        md: 6,
      },
      attribute: 'postalCode',
      label: 'account.account-page.postalCode',
      props: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <MarkunreadMailboxTwoTone />
            </InputAdornment>
          ),
        },
      },
    },
    {
      component: 'autocomplete',
      options: countryOptions,
      attribute: 'country',
      label: 'account.account-page.country',
      props: {
        renderOption: (props, option) => <FlagWithCountryName country={option} {...props} />,
        getOptionLabel(option) {
          if (option !== '') return translate(`countries.${option}`, null, option);
          else return '';
        },
      },
      col: {
        md: 6,
      },
    },
  ];
};

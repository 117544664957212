import React from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Typography } from '@mui/material';
import { VpnKey } from '@mui/icons-material';
import { Translate } from 'react-jhipster';
import { AccessTokenDataGrid } from 'app/modules/views/account-page/components/data-grids/access-token/AccessTokenDataGrid';

export const SecurityItem = () => {
  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.security">Security</Translate>
            </Typography>
          ),
          avatar: <VpnKey color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <AccessTokenDataGrid />
    </LayoutItem>
  );
};

import { Button, Dialog } from '@mui/material';
import React, { useState } from 'react';
import { EmbedCodeWidgetDialog } from './EmbedCodeWidgetDialog';

export const EmbedCodeWidget = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        Embed Code
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <EmbedCodeWidgetDialog setOpen={setOpen} />
      </Dialog>
    </>
  );
};

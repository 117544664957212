import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IMembers } from 'app/shared/model/members.model';

const accountProfilesUrl = CONFIG_MANAGER_API_URL + '/account-profiles';

export const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  membersList: [] as unknown as Array<IMembers>,
};

export type MembersState = Readonly<typeof initialState>;

// Actions

export const getMembersFromCurrentAccount = createAsyncThunk(
  'members/getMembersFromCurrentAccount',
  async () => microserviceHttpClient(`${accountProfilesUrl}/current/members`),
  {
    serializeError: serializeAxiosError,
  }
);

export const inviteMemberToCurrentAccount = createAsyncThunk(
  'accountProfile/inviteMemberFromCurrentAccount',
  async ({ userEmail, roles }: { userEmail: string; roles: string[] }) =>
    microserviceHttpClient(`${accountProfilesUrl}/current/inviteMember?userEmail=${userEmail}&roles=${roles.join(',')}`, 'post'),
  { serializeError: serializeAxiosError }
);

export const inviteMember = createAsyncThunk(
  'accountProfile/inviteMember',
  async ({ accountId, userEmail, roles }: { accountId: any; userEmail: string; roles: string[] }) =>
    microserviceHttpClient(`${accountProfilesUrl}/${accountId}/inviteMember?userEmail=${userEmail}&roles=${roles.join(',')}`, 'post'),
  { serializeError: serializeAxiosError }
);

// Slice

export const membersSlice = createSlice({
  name: 'members',
  initialState: initialState as MembersState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMembersFromCurrentAccount.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getMembersFromCurrentAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.membersList = action.payload.data as Array<IMembers>;
      })
      .addCase(getMembersFromCurrentAccount.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.membersList = [] as unknown as Array<IMembers>;
      });
  },
});

// Reducer
export default membersSlice.reducer;

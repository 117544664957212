import { buildEChartOptions } from './EChartOptionsBuilder';
import humanNumber from 'human-number';

export const getRecommendationsIdOptions = (data, theme) => ({
  color: [theme.palette.primary.main, theme.palette.primary.light],
  dataset: {
    dimensions: [data.type, 'count'],
    source: [
      {
        [data.type]: 'With Reco Id',
        count: data.withRecoId,
      },
      {
        [data.type]: 'Without Reco Id',
        count: data.withoutRecoId,
      },
    ],
  },
  tooltip: {
    trigger: 'item',
    show: true,
    formatter: '{b} : {c} ({d}%)',
  },
  series: [
    {
      type: 'pie',
      stillShowZeroSum: true,
      radius: ['60%', '90%'],
      label: {
        show: false,
      },
      data: [
        { name: 'With Reco Id', value: data.withRecoId },
        { name: 'Without Reco Id', value: data.withoutRecoId },
      ],
    },
  ],
});

export const getRecommendationsByTypeOptions = (data, totalReco, theme) => {
  return buildEChartOptions({
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {c} ({d}%)',
    },
    series: [
      {
        type: 'pie',
        stillShowZeroSum: true,
        radius: ['60%', '90%'],
        label: {
          ...theme.typography.h1,
          fontSize: '2.1422rem',
          position: 'center',
          formatter() {
            return humanNumber(totalReco, n => Number.parseFloat(n).toFixed(1));
          },
        },
        data,
      },
    ],
  });
};

import { combineReducers } from 'redux';

import generateCSV from './generate';
import listCSV from './list';

const exportCSV = combineReducers({
  generateCSV,
  listCSV,
});

export default exportCSV;

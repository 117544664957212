import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import {
  useGetUserEventsQuery,
  useGetUserInfoQuery,
  useGetUserSessionsQuery,
} from 'app/shared/reducers/api/data-hub-cloud-services/store/user-exploration';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

export const useSessionsTableData = (page, rowsPerPage) => {
  const { userId } = useParams();

  const { data: userInfos } = useGetUserInfoQuery(
    userId
      ? {
          userId,
          requestFilters: {
            filterEntryList: [],
          },
        }
      : skipToken
  );
  const { data: userEvents } = useGetUserEventsQuery(
    userId
      ? {
          userId,
          requestFilters: {
            filterEntryList: [],
          },
        }
      : skipToken
  );
  const { groupedSessions, isFetching, isError, error } = useGetUserSessionsQuery(
    userId
      ? {
          userId,
          size: rowsPerPage,
          page,
          requestFilters: {
            filterEntryList: [],
          },
        }
      : skipToken,
    {
      selectFromResult(res) {
        let groupedSessionsArray = [];
        if (res.data) {
          const groupedSessionsObject = res.data?.reduce((acc, session) => {
            const date = dayjs(session.firstEventDateTime).format('YYYY-MM-DD');

            if (!acc[date]) {
              acc[date] = [];
            }

            acc[date].push(session);
            return acc;
          }, {});

          groupedSessionsArray = Object.keys(groupedSessionsObject).map(date => ({
            date,
            sessions: groupedSessionsObject[date],
          }));
        }

        return {
          ...res,
          groupedSessions: groupedSessionsArray,
        };
      },
    }
  );
  return { userEvents, userInfos, groupedSessions, isFetching, isError, error };
};

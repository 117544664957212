import * as yup from 'yup';

import { FieldProp } from '../Components/FormBuilder';
import { Validation } from '../Components/props/FieldProps';

export function getFormSchema(fields: Array<FieldProp>) {
  const formSchema: { [x: string]: SchemaType[SchemaKeyType] } = {};
  for (const field of fields) {
    if (field.attribute) {
      const fieldSchema = getFieldSchema(field.validationType, field.validations, field.label);
      if (!field.hideCondition) {
        formSchema[field.attribute] = fieldSchema;
      }
    }
  }
  const schema = yup.object(formSchema).required();
  return schema;
}

export function getFieldSchema<T extends keyof SchemaType>(schemaType?: T, validations?: Array<Validation>, label?: string): SchemaType[T] {
  let schema: any;

  switch (schemaType) {
    case 'string':
      schema = yup.string();
      break;
    case 'number':
      schema = yup.number();
      break;
    case 'boolean':
      schema = yup.boolean();
      break;
    case 'date':
      schema = yup.date();
      break;
    case 'array':
      schema = yup.array();
      break;
    case 'mixed':
    default:
      schema = yup.mixed();
      break;
  }

  schema = schema.optional().label(label || 'This');

  for (const [key, value] of validations || []) {
    if (value === true) {
      schema = schema[key]();
    } else {
      if (Array.isArray(value) && !['oneOf', 'notOneOf'].includes(key)) {
        schema = schema[key](...value);
      } else {
        schema = schema[key](value);
      }
    }
  }
  return schema;
}

export type SchemaType = {
  string: yup.StringSchema;
  number: yup.NumberSchema;
  boolean: yup.BooleanSchema;
  date: yup.DateSchema;
  array: yup.ArraySchema<any[], yup.AnyObject>;
  mixed: yup.AnySchema;
};

export type SchemaKeyType = keyof SchemaType;

import dayjs from 'dayjs';
import { durationFormats, durationToString } from './date-utils';

export const chartFormatter = (formatter, value) => {
  switch (formatter) {
    case 'number':
      return Number(value);
    case 'numberFixed2':
      return `${Number(value).toFixed(2)}`;
    case 'numberFixed2percentage':
      return `${Number(value).toFixed(2)} %`;
    case 'durationToString':
      return durationToString(Math.floor(Number(value)), durationFormats.TRUNC_SHORT_DENSE_TIME);
    case 'timestamp':
      return dayjs.unix(Number(value)).fromNow();
    default:
      return value;
  }
};

import { BadgeProps, PopoverProps } from '@mui/material';

export const iconSxHover = {
  ':hover': {
    color: theme => theme.palette.primary.light,
  },
};

export const iconSxHoverSuccess = {
  ':hover': {
    color: theme => theme.palette.success.light,
  },
};

export const iconSxHoverError = {
  ':hover': {
    color: theme => theme.palette.error.light,
  },
};

export const badgeCircular = { color: 'secondary', overlap: 'circular' } as Partial<BadgeProps>;

export const popoverPosition = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  sx: {
    '& .MuiPaper-root': {
      overflow: 'visible',
    },
  },
} as Partial<PopoverProps>;

import { FieldProp } from 'app/modules/form-builder-lib';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { translate } from 'react-jhipster';
import { handlePresetChange, presetRanges } from './preset-ranges';

export function getFormFields(methods?: UseFormReturn<FieldValues, any>): Array<FieldProp> {
  const formFields: Array<FieldProp> = [
    {
      component: 'select',
      attribute: 'preset-ranges',
      label: 'web-analytics.export.presetRanges',
      options: presetRanges.map(preset => {
        return { key: preset, value: preset, label: translate(`web-analytics.export.${preset}`, null, preset) };
      }),
      optionConfig: { key: 'key', value: 'value', label: 'label' },
      props: {
        onChange(event) {
          handlePresetChange(event, methods);
        },
      },
      col: {
        md: 2,
      },
      validationType: 'string',
    },
    {
      component: 'date-picker',
      label: 'web-analytics.export.startDate',
      attribute: 'startDate',
      props: {
        disableMaskedInput: true,
        clearable: true,
        inputFormat: 'dd MMM yyyy',
        disableFuture: true,
        openTo: 'day',
        views: ['year', 'month', 'day'],
        onChange(event) {
          methods.setValue('preset-ranges', 'customPreset');
          methods.setValue('filename', 'webevents');
        },
      },
      keyboard: true,
      validationType: 'string',
      // validations: [['required', true]],
      col: {
        md: 2,
      },
    },
    {
      component: 'date-picker',
      label: 'web-analytics.export.endDate',
      attribute: 'endDate',
      props: {
        disableMaskedInput: true,
        clearable: true,
        inputFormat: 'dd MMM yyyy',
        disableFuture: true,
        openTo: 'day',
        views: ['year', 'month', 'day'],
        onChange(event) {
          methods.setValue('preset-ranges', 'customPreset');
          methods.setValue('filename', 'webevents');
        },
      },
      keyboard: true,
      validationType: 'string',
      col: {
        md: 2,
      },
    },
    {
      component: 'text-field',
      attribute: 'filename',
      validationType: 'string',
      label: 'web-analytics.export.fileName',
      col: {
        md: 2,
      },
    },
  ];

  return formFields;
}

import { useTheme } from '@mui/material/styles';
import { ToggleButtonGroup } from '@mui/lab';
import { ToggleButton } from '@mui/material';
import React from 'react';
import { dimensions, useSelectedDimension } from 'app/modules/views/greensights/data/dimension';
import { translate } from 'react-jhipster';

const BucketingToggler = () => {
  const theme = useTheme();

  const { selectedDimension, setSelectedDimension } = useSelectedDimension();

  return (
    <ToggleButtonGroup exclusive value={selectedDimension} onChange={(event, value) => setSelectedDimension(value)} size="small">
      {dimensions.map((dimension, index) => (
        <ToggleButton key={`dimension${index}`} disableRipple={true} value={dimension} color="primary" sx={{ paddingBlock: 0 }}>
          {translate(`greensights.dimensions.${dimension.title}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default BucketingToggler;

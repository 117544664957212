import React from 'react';
import { Button } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';

export function BetaRegisterButton(props) {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const betaRegisterSurvey = {
    en: 'https://forms.gle/6RgN9pRk8orGya6X9',
    fr: 'https://forms.gle/YYCBHCtDVGzPF6CS9',
  };

  return (
    <Button href={betaRegisterSurvey[currentLocale]} target="_blank" {...props}>
      {props.children}
    </Button>
  );
}

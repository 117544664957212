import { FieldProp } from 'app/modules/form-builder-lib';

export const accessTokenAttributes = (accessTokenList): Array<FieldProp> => {
  return [
    {
      component: 'text-field',
      label: 'account.access-token.token-name',
      attribute: 'name',
      validationType: 'string',
      validations: [['notOneOf', accessTokenList.map(access => access.name)]],
    },
  ];
};

import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography, Popper, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import { Box } from '@mui/system';
import PopupState, { bindPopper, bindHover } from 'material-ui-popup-state';

const SidebarItemCollapse = ({ item }) => {
  const [open, setOpen] = useState(false);
  const loaction = useLocation();

  useEffect(() => {
    if (loaction.pathname.includes(item.path)) setOpen(true);
    else setOpen(false);
  }, [loaction.pathname]);

  return item.sidebarProps ? (
    <PopupState variant="popper" popupId="expanded-sidebar-popper">
      {popupState => (
        <>
          <ListItemButton
            {...bindHover(popupState)}
            selected={loaction.pathname.includes(item.path)}
            component={Link}
            to={item.child[0].path}
            sx={{ px: 1 }}
          >
            <ListItemIcon
              sx={{
                mr: 2,
                ...(loaction.pathname.includes(item.path) && {
                  color: theme => theme.palette.primary.main,
                }),
                justifyContent: 'center',
              }}
            >
              {item.sidebarProps.icon && item.sidebarProps.icon}
            </ListItemIcon>
            <ListItemText
              sx={{ my: 0 }}
              primary={
                <Typography fontWeight={loaction.pathname.includes(item.path) && 'bold'}>{item.sidebarProps.displayText}</Typography>
              }
            />
          </ListItemButton>
          <Collapse in={open} timeout="auto">
            <List sx={{ ml: 2 }}>
              {item.child?.map((route, index) =>
                route.sidebarProps ? (
                  route.child ? (
                    <SidebarItemCollapse item={route} key={index} />
                  ) : (
                    <SidebarItem item={route} key={index} />
                  )
                ) : null
              )}
            </List>
          </Collapse>
          {!open && (
            <Popper {...bindPopper(popupState)} placement="right-start" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
              <Paper
                sx={{
                  ml: 1,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '12px',
                    left: '0px',
                    width: '0px',
                    height: '0px',
                    borderTop: '5px solid transparent',
                    borderBottom: '5px solid transparent',
                    borderRight: theme => `8px solid ${theme.palette.primary.main}`,
                  }}
                />
                <List disablePadding sx={{ minWidth: '150px' }}>
                  {item.child.map((child, index) => (
                    <ListItemButton
                      key={index}
                      component={Link}
                      to={child.path}
                      selected={location.pathname === child.path}
                      sx={{
                        borderLeft: theme => `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {child.sidebarProps.displayText}
                    </ListItemButton>
                  ))}
                </List>
              </Paper>
            </Popper>
          )}
        </>
      )}
    </PopupState>
  ) : null;
};

export default SidebarItemCollapse;

import React from 'react';

import { FormBuilder } from 'app/modules/form-builder-lib';
import { Box } from '@mui/material';

export const RecommendationsKpisForm = ({ formParams, fields }) => {
  return (
    <Box width="1000px">
      <FormBuilder
        fields={fields}
        methods={formParams.methods}
        optionalMarkerDefault={true}
        containerProps={{ marginY: 0.5, paddingX: 2 }}
      />
    </Box>
  );
};

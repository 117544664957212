import React from 'react';
import { PropertiesGrid } from 'app/components/properties/properties-grid';
import { Translate, translate } from 'react-jhipster';
import { transactionsInfos } from './transactionsInfos';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';

export const TransactionProperties = ({ payment, rowSelectionModel }) => {
  const renderTransactionInfo = (info: { title: string; i18n: string; value: any }, index: number): [React.ReactNode, string] => {
    return [
      <Translate key={index} contentKey={info.i18n}>
        {info.title}
      </Translate>,
      info.value,
    ];
  };

  return (
    <BasicCardLayout
      variant="div"
      header={{ title: `${translate('account.transaction', null, 'Transaction')} ${rowSelectionModel[0]}` }}
      content={{
        sx: { p: 0, '&:last-child': { p: 0 } },
      }}
    >
      <PropertiesGrid
        gridProps={{ spacing: 1 }}
        properties={transactionsInfos(payment.transactions.find(trans => trans.transactionId === rowSelectionModel[0])).map(
          (transactionInfo, index: number) => {
            return transactionInfo.renderTransactionInfo
              ? transactionInfo.renderTransactionInfo(transactionInfo, index)
              : renderTransactionInfo(transactionInfo, index);
          }
        )}
      />
    </BasicCardLayout>
  );
};

import { Divider, List, ListItem, ListItemButton, ListItemIcon, Tooltip, Typography, Popper, Paper } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import appRoutes from './appRoutes';
import { managementRoutes } from './managementRoutes';
import PopupState, { bindPopper, bindHover } from 'material-ui-popup-state';

export const PopperSidebar = () => {
  const [subRoutes, setSubRoutes] = useState([]);
  const [listHeader, setListHeader] = useState(null);
  const location = useLocation();

  return (
    <PopupState variant="popper" popupId="collapsed-sidebar-popper">
      {popupState => (
        <Box display="flex" flexDirection="column" flex={1} sx={{ overflowY: 'auto' }}>
          <List>
            {appRoutes().map((route, index) => (
              <ListItemButton
                {...(route.child && bindHover(popupState))}
                key={index}
                component={Link}
                to={route.child ? route.child[0].path : route.path}
                selected={location.pathname === route.path}
                onMouseEnter={
                  route.child
                    ? event => {
                        setSubRoutes(route.child);
                        setListHeader(route.sidebarProps.displayText);
                      }
                    : null
                }
                sx={{ px: 1 }}
              >
                <Tooltip title={!route.child && route.sidebarProps.displayText} placement="right" arrow>
                  <ListItemIcon
                    sx={{
                      ...(route.child
                        ? location.pathname.includes(route.path) && { color: theme => theme.palette.primary.light }
                        : location.pathname === route.path && { color: theme => theme.palette.primary.light }),
                      justifyContent: 'center',
                    }}
                  >
                    {route.sidebarProps.icon && route.sidebarProps.icon}
                  </ListItemIcon>
                </Tooltip>
              </ListItemButton>
            ))}
            <Popper {...bindPopper(popupState)} placement="right-start" sx={{ zIndex: theme => theme.zIndex.appBar + 1 }}>
              <Paper
                sx={{
                  ml: 1,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '12px',
                    left: '0px',
                    width: '0px',
                    height: '0px',
                    borderTop: '5px solid transparent',
                    borderBottom: '5px solid transparent',
                    borderRight: theme => `8px solid ${theme.palette.primary.main}`,
                  }}
                />
                <List disablePadding sx={{ minWidth: '150px' }}>
                  <ListItem sx={{ justifyContent: 'center' }} selected divider>
                    <Typography fontWeight="bold">{listHeader && listHeader}</Typography>
                  </ListItem>
                  {subRoutes.map((item, index) => (
                    <ListItemButton
                      key={index}
                      component={Link}
                      to={item.path}
                      selected={location.pathname === item.path}
                      sx={{
                        ...(location.pathname === item.path
                          ? {
                              borderLeft: theme => `3px solid ${theme.palette.primary.main}`,
                            }
                          : {
                              borderLeft: theme => `1px solid ${theme.palette.primary.main}`,
                            }),
                      }}
                    >
                      {item.sidebarProps.displayText}
                    </ListItemButton>
                  ))}
                </List>
              </Paper>
            </Popper>
          </List>
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Box flexGrow={1} />
          <List>
            {managementRoutes().map((route, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={route.path}
                target={route.target}
                selected={location.pathname === route.path}
                sx={{ px: 1 }}
              >
                <Tooltip title={route.sidebarProps.displayText} placement="right" arrow>
                  <ListItemIcon
                    sx={{
                      ...(location.pathname === route.path && { color: theme => theme.palette.primary.light }),
                      justifyContent: 'center',
                    }}
                  >
                    {route.sidebarProps.icon && route.sidebarProps.icon}
                  </ListItemIcon>
                </Tooltip>
              </ListItemButton>
            ))}
          </List>
        </Box>
      )}
    </PopupState>
  );
};

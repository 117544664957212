import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { defaultValue, IAccountProfile } from 'app/shared/model/account-profile.model';

const accountProfilesUrl = CONFIG_MANAGER_API_URL + '/account-profiles';

export const AccountStatus = {
  BOOTSTRAP: 'BOOTSTRAP',
  MISSING_KB_ACCOUNT: 'MISSING_KB_ACCOUNT',
  KB_EXCEPTION: 'KB_EXCEPTION',
  MISSING_DETAILS: 'MISSING_DETAILS',
  MISSING_ADDRESS: 'MISSING_ADDRESS',
  MISSING_CONTACT: 'MISSING_CONTACT',
  MISSING_PAYMENT_METHOD: 'MISSING_PAYMENT_METHOD',
  READY: 'READY',
};

export const initialState = {
  accountProfileLoading: true,
  errorMessage: null as unknown as string, // Errors returned from server side
  accountProfile: defaultValue as IAccountProfile,
};

export type AccountProfileState = Readonly<typeof initialState>;

// Actions

export const getAccountProfile = createAsyncThunk(
  'accountProfile/getAccountProfile',
  async (uuid: string) => {
    return microserviceHttpClient(`${accountProfilesUrl}/${uuid}`);
  },
  { serializeError: serializeAxiosError }
);

export const getCurrentAccountProfile = createAsyncThunk(
  'accountProfile/getCurrentAccountProfile',
  async () => microserviceHttpClient(`${accountProfilesUrl}/current`),
  { serializeError: serializeAxiosError }
);

export const updateAccountProfileStatus = createAsyncThunk(
  'accountProfile/updateAccountProfileStatus',
  async () => microserviceHttpClient(`${accountProfilesUrl}/current/update-status`, 'post'),
  { serializeError: serializeAxiosError }
);

// Slice

export const AccountProfileSlice = createSlice({
  name: 'accountProfileReducer',
  initialState: initialState as AccountProfileState,
  reducers: {
    resetAccountProfile(state) {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAccountProfile.pending, state => {
        state.accountProfileLoading = true;
        state.errorMessage = null;
      })
      .addCase(getAccountProfile.fulfilled, (state, action) => {
        state.accountProfileLoading = false;
        state.accountProfile = action.payload.data as IAccountProfile;
      })
      .addCase(getAccountProfile.rejected, (state, action) => {
        state.accountProfileLoading = false;
        state.errorMessage = action.error.message;
        state.accountProfile = defaultValue as IAccountProfile;
      })

      .addCase(getCurrentAccountProfile.pending, state => {
        state.accountProfileLoading = true;
        state.errorMessage = null;
      })
      .addCase(getCurrentAccountProfile.fulfilled, (state, action) => {
        state.accountProfileLoading = false;
        state.accountProfile = action.payload.data as IAccountProfile;
      })
      .addCase(getCurrentAccountProfile.rejected, (state, action) => {
        state.accountProfileLoading = false;
        state.errorMessage = action.error.message;
        state.accountProfile = defaultValue as IAccountProfile;
      })

      .addCase(updateAccountProfileStatus.pending, state => {
        state.accountProfileLoading = true;
        state.errorMessage = null;
      })
      .addCase(updateAccountProfileStatus.fulfilled, (state, action) => {
        state.accountProfileLoading = false;
        state.accountProfile = action.payload.data as IAccountProfile;
      })
      .addCase(updateAccountProfileStatus.rejected, (state, action) => {
        state.accountProfileLoading = false;
        state.errorMessage = action.error.message;
        state.accountProfile = defaultValue as IAccountProfile;
      });
  },
});

export const { resetAccountProfile } = AccountProfileSlice.actions;

// Reducer
export default AccountProfileSlice.reducer;

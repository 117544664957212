import React, { useContext } from 'react';

import { Button } from '@mui/material';
import { Translate } from 'react-jhipster';
import { ProductContext, ProductContextType } from '../../context/ProductContext';
import { generatePath } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { patterns } from 'app/shared/util/url-utils';

export const SubscriptionButton = () => {
  const { product, subscribedPlan, submitSubscriptionChange } = useContext<ProductContextType>(ProductContext);

  if (subscribedPlan.length > 0)
    return (
      <Button
        startIcon={<SettingsIcon />}
        href={generatePath(patterns.product.settings, { productName: product ? product.name : '' })}
        variant="outlined"
        color="primary"
      >
        <Translate contentKey="products.settings">Settings</Translate>
      </Button>
    );
  else
    return (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          submitSubscriptionChange();
        }}
      >
        <Translate contentKey="products.subscribe">Subscribe</Translate>
      </Button>
    );
};

import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from 'app/config/store';
import { useGetTimeframeUserScoresUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-scores';
import {
  getUserExplorationSplitLimit,
  getUserExplorationTimerange,
  selectAllUserExplorationFilters,
} from 'app/shared/reducers/views/user-exploration';
import { useEffect } from 'react';

export const useUserExploration = () => {
  const timerange = useAppSelector(getUserExplorationTimerange);
  const filter = useAppSelector(selectAllUserExplorationFilters);
  const splitLimit = useAppSelector(getUserExplorationSplitLimit);

  const { refetch: refetchTimeframeUsersScore, isUninitialized: isTimeframeUsersScoreUninitialized } =
    useGetTimeframeUserScoresUsingPostQuery(
      timerange
        ? {
            from: timerange.fromDate,
            to: timerange.toDate,
            // limit: splitLimit.toString(),
            scoreBodyDto: {
              filters: {
                filterEntryList: filter,
              },
            },
          }
        : skipToken
    );

  useEffect(() => {
    if (!isTimeframeUsersScoreUninitialized) refetchTimeframeUsersScore();
  }, [timerange?.refresh]);
};

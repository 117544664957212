import React from 'react';

import { SummarySectionCard } from 'app/components/card/summary-section/SummarySectionCard';
import { ProductCardSecondary } from './ProductCardSecondary';
import { TrialProductChip } from './TrialProductChip';
import { images } from '../products-panel/components/EnrolledProducts';

// export const images = {
//   'web-analytics': 'content/images/scoringjoe/screenshots/web-analytics-session-exploration.png',
//   recommendations: 'content/images/scoringjoe/screenshots/recommendations-inventories.png',
//   greensights: 'content/images/scoringjoe/screenshots/greensight-board.png',
//   erpnext: 'content/images/erp/modules.png',
// };

export const ProductCard = ({ productName, children }) => {
  return (
    <SummarySectionCard
      gridProps={{
        paddingY: undefined,
      }}
      summaryProps={{
        xs: 12,
      }}
      title={{
        translateKey: `landing.products.${productName}.title`,
        defaultText: productName,
      }}
      secondary={<ProductCardSecondary productName={productName} />}
      infoChip={<TrialProductChip productName={productName} />}
      summary={{
        translateKey: `landing.products.${productName}.summary`,
        defaultText: productName,
      }}
      images={{
        position: 'before',
        props: [
          {
            src: images[productName],
            alt: productName,
            style: {
              boxShadow: '0 4px 8px 0 grey',
              borderRadius: 10,
              height: 'auto',
              width: '100%',
            },
          },
        ],
        // sliderProps: {
        //   height: 'auto',
        //   width: '100%',
        // },
        containerProps: {
          xs: 12,
          width: '420px',
          height: '315px',
        },
      }}
    >
      {children}
    </SummarySectionCard>
  );
};

import { useCallback, useRef, useState } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';

export const useWidgetPreviewPanel = () => {
  const maxSize = 80;
  const minSize = 40;
  const defaultSize = (maxSize + minSize) / 2;

  const [isCollapsed, setIsCollapsed] = useState(false);

  const panelHandle = useRef<ImperativePanelHandle | null>(null);

  const handleCollapse = useCallback(() => {
    if (panelHandle.current) {
      panelHandle.current.collapse();
      setIsCollapsed(true);
    }
  }, [setIsCollapsed, panelHandle]);

  const handleExpand = useCallback(() => {
    if (panelHandle.current) {
      panelHandle.current.expand();
      setIsCollapsed(false);
    }
  }, [setIsCollapsed, panelHandle]);

  const handleResize = useCallback(
    size => {
      if (panelHandle.current) {
        panelHandle.current.resize(size);
      }
    },
    [panelHandle]
  );

  return {
    minSize,
    maxSize,
    defaultSize,
    isCollapsed,
    panelHandle,
    handleCollapse,
    handleExpand,
    handleResize,
  };
};

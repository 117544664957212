import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import dayjs from 'dayjs';
import { DurationUnitType } from 'dayjs/plugin/duration';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? dayjs(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const enum durationFormats {
  DENSE_TIME, // HH:?mm:?ss.?SSS
  TIME, // HH:mm:ss.SSS
  SHORT_DENSE_TIME, // HH[h]? mm[m]? ss[s]? SSS[ms]
  SHORT_TIME, // HH[h] mm[m] ss[s] SSS[ms]
  TRUNC_SHORT_DENSE_TIME, // HH[h]? mm[m]? (ss[s]? (SSS[ms]))
  TRUNC_SINGLE_DENSE_TIME, // HH[h]? (mm[m]? (ss[s]? (SSS[ms])))
  HUMANIZE,
}

export function durationToString(value, format, unit: DurationUnitType = 'seconds') {
  const duration = dayjs.duration(Number(value), unit);
  switch (format) {
    case durationFormats.DENSE_TIME: {
      if (duration.asHours() >= 1) {
        return duration.format('H:mm:ss.SSS');
      }
      if (duration.asMinutes() >= 1) {
        return duration.format('m:ss.SSS');
      }
      if (duration.asSeconds() >= 1) {
        return duration.format('s.SSS');
      }
      return duration.format('SSS');
    }
    case durationFormats.TIME: {
      return duration.format('HH:mm:ss.SSS');
    }

    case durationFormats.SHORT_DENSE_TIME: {
      if (duration.asHours() >= 1) {
        return duration.format('H[h] mm[m] ss[s] SSS[ms]');
      }
      if (duration.asMinutes() >= 1) {
        return duration.format('m[m] ss[s] SSS[ms]');
      }
      if (duration.asSeconds() >= 1) {
        return duration.format('s[s] SSS[ms]');
      }
      return duration.format('SSS[ms]');
    }
    case durationFormats.SHORT_TIME: {
      return duration.format('HH[h] mm[m] ss[s] SSS[ms]');
    }

    case durationFormats.TRUNC_SHORT_DENSE_TIME: {
      if (duration.asHours() >= 1) {
        return duration.format('H[h] mm[m]');
      }
      if (duration.asMinutes() >= 1) {
        return duration.format('m[m] ss[s]');
      }
      if (duration.asSeconds() >= 1) {
        return duration.format('s[s] SSS[ms]');
      }
      return duration.format('SSS[ms]');
    }

    case durationFormats.TRUNC_SINGLE_DENSE_TIME: {
      if (duration.asHours() >= 1) {
        return duration.format('H[h]');
      }
      if (duration.asMinutes() >= 1) {
        return duration.format('m[m]');
      }
      if (duration.asSeconds() >= 1) {
        return duration.format('s[s]');
      }
      return duration.format('SSS[ms]');
    }

    case durationFormats.HUMANIZE: {
      return duration.humanize();
    }
    default:
      return duration.toISOString;
  }
}

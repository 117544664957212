import React, { useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { addFlex, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import { dynamicHeightProps } from 'app/components/data-grid/rows/rows-props';
import { ConfirmDeleteItem } from '../ConfirmDeleteItem';
import { UpdateItems } from '../UpdateItem';
import { useAllItemsDataGrid } from '../../hooks/useAllItemsDataGrid';
import { useAllItemsDataGridColumns } from '../../hooks/useAllItemsDataGridColumns';
import { CustomPagination, CustomToolbar, NoItemsFound } from './CustomComponents';

export const AllItemsDataGrid = () => {
  const { itemsList, searchedItemsList, paginationModel, setPaginationModel, isFetching, rowCountState } = useAllItemsDataGrid();
  const [deleteItem, setDeleteItem] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const columns = useAllItemsDataGridColumns(setDeleteItem, setEditItem);

  return (
    <>
      <ConfirmDeleteItem item={deleteItem} setOpen={setDeleteItem} />
      {editItem && <UpdateItems item={editItem} setOpen={setEditItem} />}
      <DataGrid
        rows={searchedItemsList.content ? searchedItemsList.content : itemsList.content || []}
        columns={columns.map(addFlex).map(translateHeaders('recommendation.items.items-table'))}
        getRowId={row => row.id}
        {...dynamicHeightProps()}
        slots={{
          pagination: CustomPagination,
          toolbar: CustomToolbar,
          noResultsOverlay: NoItemsFound,
          noRowsOverlay: NoItemsFound,
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 75, 100]}
        density="compact"
        disableColumnMenu
        disableRowSelectionOnClick
        loading={isFetching}
        rowCount={rowCountState}
        paginationMode="server"
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
        }}
      />
    </>
  );
};

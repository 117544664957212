import React from 'react';
import { ArticleOutlined, Store } from '@mui/icons-material';
import { documentationUrl, sitemap } from 'app/shared/util/url-utils';
import { Translate } from 'react-jhipster';
import AppsIcon from '@mui/icons-material/Apps';
import { UserCircle } from 'tabler-icons-react';

export const managementRoutes = () => {
  return [
    {
      path: sitemap.account,
      sidebarProps: {
        displayText: <Translate contentKey="module-factory.account">Account</Translate>,
        icon: <Store />,
      },
    },
    {
      path: sitemap.user,
      sidebarProps: {
        displayText: <Translate contentKey="module-factory.profile">Profile</Translate>,
        icon: <UserCircle />,
      },
    },
    {
      path: sitemap.setup,
      sidebarProps: {
        displayText: <Translate contentKey="module-factory.applications">Applications</Translate>,
        icon: <AppsIcon />,
      },
    },
    {
      path: documentationUrl(sitemap.ext.docs.root),
      target: '_blank',
      sidebarProps: {
        displayText: <Translate contentKey="module-factory.documentation">Documentation</Translate>,
        icon: <ArticleOutlined />,
      },
    },
  ];
};

import React from 'react';

import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { Translate } from 'react-jhipster';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { LineChartsActions } from './components/line-charts-tabs/actions/LineChartsActions';
import { MapChartsActions } from './components/map-chart/actions/MapChartsActions';
import { LineChartsTabs } from './components/line-charts-tabs';
import { MapKPI } from './components/map-chart';
import { PieCharts } from './components/pie-charts';
import { PieChartsActions } from './components/pie-charts/actions/PieChartsActions';
import SplitToggler from './components/pie-charts/actions/SplitToggler';
import KPIToggler from './components/map-chart/actions/KPIToggler';
import { useAppSelector } from 'app/config/store';
import { TopUsersActions } from './components/top-users/actions/TopUsersActions';
import { TopUsers } from './components/top-users';
import { UsersCohortActions } from './components/users-cohort/actions/UsersCohortActions';
import UsersCohort from './components/users-cohort';
import { ActiveUsers } from './components/active-users';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';

export const WebAnalyticsHome = () => {
  const { selectedMapChart } = useAppSelector(state => state.webAnalyticsOverview.mapChart);

  return (
    <BasicCardLayout
      variant="div"
      header={{
        title: <Translate contentKey="module-factory.dashboard-overview">Dashboard Overview</Translate>,
      }}
    >
      <GridLayout width="100%">
        <LayoutItem
          gridItemProps={{ xs: 12, display: 'grid' }}
          cardOptions={{
            card: { sx: { display: 'flex', flexDirection: 'column' } },
            content: { sx: { height: '300px', display: 'flex', flexDirection: 'column', flexGrow: 1, p: 0, '&:last-child': { p: 0 } } },
            actions: <LineChartsActions />,
            actionsProps: { sx: { py: 0 } },
          }}
        >
          <LineChartsTabs />
        </LayoutItem>

        <LayoutItem
          gridItemProps={{ xs: 6, display: 'grid' }}
          cardOptions={{
            header: { title: <Translate contentKey="module-factory.active-users">Active Users For The Last 15 Minutes</Translate> },
            card: { sx: { height: '400px' } },
            content: { sx: { p: 0, '&:last-child': { p: 0 } } },
          }}
        >
          <ActiveUsers />
        </LayoutItem>
        <LayoutItem
          gridItemProps={{ xs: 6, display: 'grid' }}
          cardOptions={{
            card: { sx: { display: 'flex', flexDirection: 'column' } },
            header: {
              title: (
                <>
                  Active Users{' '}
                  {/* <Translate contentKey={`user-exploration.${selectedMapChart.i18n}`}>{selectedMapChart.i18n}</Translate>{' '} */}
                  <Translate contentKey={'open-analytics.sessionsByCountry.byCountry'}>by Country</Translate>
                </>
              ),
              // action: <KPIToggler />,
            },
            content: { sx: { flexGrow: 1, p: 0, '&:last-child': { p: 0 } } },
            // actions: <MapChartsActions />,
            actionsProps: { sx: { py: 0 } },
          }}
        >
          <MapKPI />
        </LayoutItem>
        <LayoutItem
          gridItemProps={{ xs: 12, display: 'grid' }}
          cardOptions={{
            header: { action: <SplitToggler /> },
            content: { sx: { p: 0, '&:last-child': { p: 0 } } },
            actions: <PieChartsActions />,
            actionsProps: { sx: { py: 0 } },
          }}
        >
          <PieCharts />
        </LayoutItem>

        <LayoutItem
          gridItemProps={{ xs: 12 }}
          cardOptions={{
            header: { title: <Translate contentKey="module-factory.top-users-score">Top Users Score</Translate> },
            actions: <TopUsersActions />,
            actionsProps: { sx: { py: 0 } },
          }}
        >
          <TopUsers />
        </LayoutItem>

        <LayoutItem
          gridItemProps={{ xs: 12 }}
          cardOptions={{
            header: { title: <Translate contentKey="module-factory.users-cohort">Users Cohort</Translate> },
            content: { sx: { p: 0, '&:last-child': { p: 0 } } },
            actions: <UsersCohortActions />,
            actionsProps: { sx: { py: 0 } },
          }}
        >
          <UsersCohort />
        </LayoutItem>
      </GridLayout>
    </BasicCardLayout>
  );
};

import React from 'react';
import { Dialog } from '@mui/material';
import { ItemDetails } from './ItemDetails';

export const ItemsView = ({ itemId, setOpen }) => {
  return (
    <Dialog
      open={itemId !== null}
      onClose={() => {
        setOpen(null);
      }}
      maxWidth="lg"
      fullWidth
    >
      <ItemDetails itemId={itemId} />
    </Dialog>
  );
};

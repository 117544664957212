import { useAppDispatch, useAppSelector } from 'app/config/store';
import { resetRecoWidget, setRecoWidget } from 'app/shared/reducers/views/recommendations/widget';
import { useEffect, useState } from 'react';

export const LOCAL_STORAGE_KEY_PREFIX = 'widget-builder-form|';

function getWidgetIds() {
  const widgetIds: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith(LOCAL_STORAGE_KEY_PREFIX)) {
      widgetIds.push(key);
    }
  }
  return widgetIds.sort();
}

export default function useLocalStorage() {
  const dispatch = useAppDispatch();

  let widgetIds: string[] = getWidgetIds();

  const [isLoading, setIsLoading] = useState(true);
  const [widgetId, setWidgetId] = useState(widgetIds[0] ?? LOCAL_STORAGE_KEY_PREFIX + crypto.randomUUID());

  const recoWidget = useAppSelector(state => state.views.recoWidget);

  const handleSelectWidget = selectedWidgetId => {
    setWidgetId(selectedWidgetId);
  };

  const handleCreateWidget = () => {
    const newWidgetId = LOCAL_STORAGE_KEY_PREFIX + crypto.randomUUID();
    widgetIds.push(newWidgetId);
    setWidgetId(newWidgetId);
  };

  const duplicateWidget = (name?) => {
    const valueToRename = localStorage.getItem(widgetId);

    if (valueToRename !== null) {
      const newName = name ?? crypto.randomUUID();
      const uniqueName = widgetIds.includes(LOCAL_STORAGE_KEY_PREFIX + newName)
        ? newName.replaceAll(/\s\([^)]*\)/g, '') + ` (${crypto.randomUUID()})`
        : newName;
      const newWidgetId = LOCAL_STORAGE_KEY_PREFIX + uniqueName;
      localStorage.setItem(newWidgetId, JSON.stringify({ ...JSON.parse(valueToRename), widgetId: newWidgetId.split('|')[1] }));
      widgetIds = getWidgetIds();
      setWidgetId(newWidgetId);
    } else {
      console.error(`The key "${widgetId}" does not exist in localStorage.`);
      setWidgetId(widgetIds[0] ?? LOCAL_STORAGE_KEY_PREFIX + crypto.randomUUID());
    }
  };

  const handleDuplicateWidget = () => {
    duplicateWidget(widgetId.split('|')[1]);
  };

  const handleRenameWidget = name => {
    const oldWidgetId = widgetId;
    duplicateWidget(name);
    deleteWidget(oldWidgetId);
  };

  const deleteWidget = (id?) => {
    localStorage.removeItem(id ?? widgetId);
    widgetIds = getWidgetIds();
  };

  const handleDeleteWidget = () => {
    deleteWidget();
    setWidgetId(widgetIds[0] ?? LOCAL_STORAGE_KEY_PREFIX + crypto.randomUUID());
  };

  useEffect(() => {
    const jsonValue = localStorage.getItem(widgetId);
    if (jsonValue != null) {
      dispatch(setRecoWidget({ ...JSON.parse(jsonValue), widgetId: widgetId.split('|')[1] }));
    } else {
      dispatch(resetRecoWidget());
    }
  }, [widgetId]);

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem(widgetId, JSON.stringify({ ...recoWidget, widgetId: widgetId.split('|')[1] }));
    } else {
      setIsLoading(false);
    }
  }, [recoWidget, widgetId]);

  return {
    isLoading,
    widgetIds,
    widgetId,
    handleSelectWidget,
    handleCreateWidget,
    handleDuplicateWidget,
    handleRenameWidget,
    handleDeleteWidget,
    recoWidget,
  };
}

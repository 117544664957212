import React from 'react';

import { Clear, DeleteOutline, Search } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FormBuilder } from 'app/modules/form-builder-lib';
import { Translate } from 'react-jhipster';
import { useSearchItemsForm } from '../../hooks/useSearchItemsForm';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setItemsList } from 'app/shared/reducers/views/recommendations/items';
import { useDeleteMatchingItems } from '../../hooks/useDeleteMatchingItems';
import { ConfirmDeleteItemsByQuery } from '../ConfirmDeleteItemsByQuery';

export const SearchItemsForm = () => {
  const dispatch = useAppDispatch();
  const { searchedItemsList } = useAppSelector(state => state.views.recoItems);
  const { getSearchFields, methods, onSubmit, SearchedItemsByQuery, searchItemByQuery } = useSearchItemsForm();
  const { openDeleteMatchingItems, setOpenDeleteMatchingItems } = useDeleteMatchingItems();

  return (
    <>
      <FormBuilder
        fields={getSearchFields(methods)}
        defaultValue={{ 'search-option': 'query', 'search-text': '' }}
        methods={methods}
        containerProps={{ spacing: 2, marginY: 0.5 }}
        boxProps={{ flexGrow: 1 }}
      />
      <Tooltip title={<Translate contentKey="recommendation.items.search">Search item</Translate>}>
        <Box sx={{ my: 'auto', ml: 1 }}>
          <IconButton
            color="primary"
            disabled={!methods.formState.isDirty}
            onClick={() => {
              methods.handleSubmit(data => {
                onSubmit(data);
              })();
            }}
          >
            <Search />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title={<Translate contentKey="recommendation.items.search">Search item</Translate>}>
        <Box my="auto">
          <IconButton
            color="error"
            sx={{ ...(searchedItemsList.length === 0 && { visibility: 'hidden' }) }}
            disabled={!methods.formState.isDirty}
            onClick={() => {
              dispatch(setItemsList([]));
              methods.reset();
            }}
          >
            <Clear />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip title={<Translate contentKey="recommendation.items.delete-matching-items">Delete matching items</Translate>}>
        <Box my="auto">
          <IconButton
            color="error"
            sx={{ ...((searchedItemsList.length === 0 || methods.watch('search-option') !== 'query') && { visibility: 'hidden' }) }}
            disabled={!methods.formState.isDirty || (SearchedItemsByQuery && SearchedItemsByQuery.totalElements === 0)}
            onClick={() => {
              setOpenDeleteMatchingItems(true);
            }}
          >
            <DeleteOutline />
          </IconButton>
        </Box>
      </Tooltip>

      <ConfirmDeleteItemsByQuery
        open={openDeleteMatchingItems}
        setOpen={setOpenDeleteMatchingItems}
        methods={methods}
        itemCount={SearchedItemsByQuery?.totalElements}
        searchItemByQuery={searchItemByQuery}
      />
    </>
  );
};

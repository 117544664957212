import { format, parse } from 'date-fns';
import React, { Fragment, useCallback, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Schedule } from '@mui/icons-material';
import { DesktopTimePicker, LocalizationProvider, MobileTimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';

import { CommonFieldProps, DateTimeFieldProps } from './props/FieldProps';
import { Title } from './Widgets/Title';
import { translate } from 'react-jhipster';

export interface StandardTimePickerProps extends CommonFieldProps<'time-picker'>, DateTimeFieldProps {
  attribute: Required<CommonFieldProps<'time-picker'>>['attribute'];
}

export default function StandardTimePicker(props: { field: StandardTimePickerProps; methods: UseFormReturn; hideTitle?: boolean }) {
  const {
    field: fieldConfig,
    methods: {
      control,
      getValues,
      setValue,
      trigger,
      formState: { errors },
    },
    hideTitle,
  } = props;
  const [open, setOpen] = useState<boolean>();

  const component = useCallback(
    (timePickerProps: TimePickerProps<Date>) => {
      if (fieldConfig.keyboard) {
        return <DesktopTimePicker {...timePickerProps} />;
      }
      // return <MobileTimePicker {...timePickerProps} />;
    },
    [fieldConfig.keyboard]
  );

  const componentProps = (fieldConf: StandardTimePickerProps, value?: string): TimePickerProps<Date> => {
    return {
      ampm: false,
      format: 'HH:mm',
      label: fieldConf.label && translate(fieldConf.label, null, fieldConf.label),
      open: !!open,
      onClose: () => setOpen(false),
      ...fieldConf.props,
      value: value ? parse(value, 'HH:mm:ss', new Date()) : null,
      onChange(valueIn) {
        if (valueIn) {
          try {
            const formatted = format(valueIn, 'HH:mm:00');
            setValue(fieldConf.attribute, formatted);
          } catch (error) {
            window.console.log(error);
          }
        } else {
          setValue(fieldConf.attribute, undefined);
        }
      },
      componentsProps: {
        textField: {
          fullWidth: true,
          size: 'small',
          onClick: () => setOpen(true),
          error: !!errors[fieldConf.attribute],
          helperText: <>{errors[fieldConf.attribute]?.message}</>,
          onKeyDown(event) {
            if (event.key === 'Enter') {
              trigger(fieldConf.attribute);
            }
          },
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="open time picker" size="large">
                  <Schedule />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              pr: fieldConf.keyboard ? undefined : 0,
            },
          },
        },
      },
    };
  };

  return (
    <Controller
      name={fieldConfig.attribute}
      control={control}
      render={({ field }) => (
        <Fragment>
          {!hideTitle && fieldConfig.title && <Title field={fieldConfig} />}
          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>{component(componentProps(fieldConfig, field.value))}</LocalizationProvider>
          </Box>
        </Fragment>
      )}
    />
  );
}

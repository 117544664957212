import get from 'lodash/get';

export interface Option<T> {
  key: string;
  value: T;
  label: string;
  icon?: JSX.Element;
}

export interface SelectOption {
  key: string;
  value: string | number;
  label: string;
  isGroup?: boolean;
  icon?: JSX.Element;
}

export interface OptionConfig {
  key: string;
  value?: string;
  label: string;
  isGroup?: string;
  icon?: string;
}

export function getOptionFromConfig<T>(option: T, config?: OptionConfig): Option<T> {
  if (config) {
    return {
      key: String(get(option, config.key)),
      value: config.value ? get(option, config.value) : option,
      label: String(get(option, config.label)),
      ...(config.isGroup ? { isGroup: get(option, config.isGroup) } : {}),
    };
  }

  return {
    key: String(option),
    value: option,
    label: String(option),
  };
}

export function getSelectOptionFromConfig<T>(option: T, config?: OptionConfig): Option<string | number> {
  if (config) {
    return {
      key: String(get(option, config.key)),
      value: String(config.value ? get(option, config.value) : option),
      label: String(get(option, config.label)),
      icon: get(option, config.icon) as JSX.Element,
      ...(config.isGroup ? { isGroup: get(option, config.isGroup) } : {}),
    };
  }

  return {
    key: String(option),
    value: String(option),
    label: String(option),
  };
}

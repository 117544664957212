import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { ISubscription } from '../model/subscription.model';
import { getSubscriptionBundles } from './subscription-bundles';

const subscriptionUrl = CONFIG_MANAGER_API_URL + '/billing/subscriptions';

export const initialState = {
  isCreating: false,
  isDeleting: false,
  errorMessage: null,
  createdSubscription: null as ISubscription,
};

export type SubscriptionState = Readonly<typeof initialState>;

// Actions

export const createSubscription = createAsyncThunk(
  'subscription/createSubscription',
  async ({ data, accountId }: { data: any; accountId: any }, thunkAPI) =>
    microserviceHttpClient(`${subscriptionUrl}`, 'post', JSON.stringify(data)).then(resp => {
      thunkAPI.dispatch(getSubscriptionBundles({ accountId }));
      return resp;
    }),
  {
    serializeError: serializeAxiosError,
  }
);

export const deleteSubscription = createAsyncThunk(
  'subscription/deleteSubscription',
  async ({ subscriptionId, accountId }: { subscriptionId: string; accountId: string }, thunkAPI) =>
    microserviceHttpClient(`${subscriptionUrl}/${subscriptionId}`, 'delete').then(resp => {
      thunkAPI.dispatch(getSubscriptionBundles({ accountId }));
      return resp;
    }),
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const SubscriptionSlice = createSlice({
  name: 'subscription',
  initialState: initialState as SubscriptionState,
  reducers: {
    resetSubscription(state) {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createSubscription.pending, state => {
        state.isCreating = true;
        state.errorMessage = null;
        state.createdSubscription = null as ISubscription;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.isCreating = false;
        state.createdSubscription = action.payload.data;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.isCreating = false;
        state.errorMessage = action.error.message;
        state.createdSubscription = null as ISubscription;
      })
      .addCase(deleteSubscription.pending, state => {
        state.isDeleting = true;
        state.errorMessage = null;
      })
      .addCase(deleteSubscription.fulfilled, state => {
        state.isDeleting = false;
      })
      .addCase(deleteSubscription.rejected, (state, action) => {
        state.isDeleting = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { resetSubscription } = SubscriptionSlice.actions;

// Reducer
export default SubscriptionSlice.reducer;

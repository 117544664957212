import { mediaScreenTypes } from 'app/shared/reducers/views/recommendations/widget';
import { FieldValues, UseFieldArrayReturn, useFieldArray } from 'react-hook-form';

export type MediaStyleFormType = { [key: string]: UseFieldArrayReturn<FieldValues, `${string}.${string}`, 'id'> };

export const useMediasStyleForm = (control, styleKey: string = 'style') => {
  return {
    style: (mediaScreenTypes as string[]).reduce((acc, item) => {
      acc[item] = useFieldArray({ control, name: `${styleKey}.${item}` });
      return acc;
    }, {} as MediaStyleFormType),
  };
};

import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { getArrayTokenProps } from 'app/shared/reducers/account-profiles/access-token';
import { useListKeysUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { UserProfileDtoRes, userProfilesApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';

export const useAccessToken = () => {
  const { data: userProfile = {} as UserProfileDtoRes } = userProfilesApi.endpoints.getCurrentUserProfileUsingGet.useQueryState({});
  const { accessTokenList } = useListKeysUsingGetQuery(userProfile.currentAccount ? undefined : skipToken, {
    selectFromResult({ data }) {
      return { accessTokenList: data ? getArrayTokenProps(data) : [] };
    },
  });
  return accessTokenList;
};

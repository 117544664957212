import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { RECO_ENGINE_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IItems } from 'app/shared/model/recommendations/recommendations.model';

const recommendationsUrl = RECO_ENGINE_API_URL + '/users';

export const initialState = {
  recommendationsLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  personalization: null as Array<IItems>,
  popularity: null as Array<IItems>,
  trending: null as Array<IItems>,
  random: null as Array<IItems>,
};

export type RecoRecommendationsState = Readonly<typeof initialState>;

// Actions

export const getRecommendationsForUser = createAsyncThunk(
  'reco/getRecommendationsForUser',
  async ({
    corpus,
    profileId,
    recommender_type = 'boosted_terms',
    trendingScale = null,
    max_item_count = null,
    filter_out_seen_items = null,
  }: {
    corpus: any;
    profileId: any;
    recommender_type?: any;
    trendingScale?: string;
    max_item_count?: number;
    filter_out_seen_items?: boolean;
  }) =>
    microserviceHttpClient(
      `${recommendationsUrl}/${profileId}/recommendations`,
      'post',
      JSON.stringify({
        recommender_type,
        corpus,
        ...(trendingScale ? { trendingScale } : {}),
        ...(max_item_count ? { max_item_count } : {}),
        ...(filter_out_seen_items ? { filter_out_seen_items } : {}),
      })
    ),
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const recoRecommendationsSlice = createSlice({
  name: 'recommendationsForUser',
  initialState: initialState as RecoRecommendationsState,
  reducers: {
    resetRecommendationsForUser(state) {
      state.recommendationsLoading = false;
      state.errorMessage = null as unknown as string;
      state.random = null as Array<IItems>;
      state.popularity = null as Array<IItems>;
      state.trending = null as Array<IItems>;
      state.personalization = null as Array<IItems>;
    },
    updateRecommendationsForUser(state, action) {
      switch (action.payload.recommender_type) {
        case 'random':
          state.random = action.payload.data as Array<IItems>;
          break;
        case 'popularity':
          state.popularity = action.payload.data as Array<IItems>;
          break;
        case 'trending':
          state.trending = action.payload.data as Array<IItems>;
          break;
        default:
          state.personalization = action.payload.data as Array<IItems>;
      }
    },
    invalidateRecommendationsForUser(state, action) {
      switch (action.payload.recommender_type) {
        case 'random':
          state.random = null as Array<IItems>;
          break;
        case 'popularity':
          state.popularity = null as Array<IItems>;
          break;
        case 'trending':
          state.trending = null as Array<IItems>;
          break;
        default:
          state.personalization = null as Array<IItems>;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRecommendationsForUser.pending, state => {
        state.recommendationsLoading = true;
        state.errorMessage = null;
      })
      .addCase(getRecommendationsForUser.fulfilled, (state, action) => {
        state.recommendationsLoading = false;
        switch (action.meta.arg.recommender_type) {
          case 'random':
            state.random = action.payload.data as Array<IItems>;
            break;
          case 'popularity':
            state.popularity = action.payload.data as Array<IItems>;
            break;
          case 'trending':
            state.trending = action.payload.data as Array<IItems>;
            break;
          default:
            state.personalization = action.payload.data as Array<IItems>;
        }
      })
      .addCase(getRecommendationsForUser.rejected, (state, action) => {
        state.recommendationsLoading = false;
        state.errorMessage = action.error.message;
        switch (action.meta.arg.recommender_type) {
          case 'random':
            state.random = null as Array<IItems>;
            break;
          case 'popularity':
            state.popularity = null as Array<IItems>;
            break;
          case 'trending':
            state.trending = null as Array<IItems>;
            break;
          default:
            state.personalization = null as Array<IItems>;
        }
      });
  },
});

export const { resetRecommendationsForUser, updateRecommendationsForUser, invalidateRecommendationsForUser } =
  recoRecommendationsSlice.actions;

// Reducer
export default recoRecommendationsSlice.reducer;

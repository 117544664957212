import React from 'react';

import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { addFlex, chipString, currencyAmount, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import dayjs from 'dayjs';
import { useAppSelector } from 'app/config/store';

function getLatestTransaction(payment) {
  let latestTransaction;

  for (const transaction of payment.transactions) {
    if (!latestTransaction || transaction.effectiveDate > latestTransaction.effectiveDate) {
      latestTransaction = transaction;
    }
  }

  return latestTransaction;
}

export const PaymentDataGrid = ({ payment }) => {
  const { currentLocale }: any = useAppSelector(state => state.locale);

  const paymentColumns: GridColDef[] = [
    {
      field: 'paymentNumber',
    },
    {
      field: 'date',
      type: 'date',
      flex: 2,
      valueGetter(params) {
        return new Date(getLatestTransaction(params.row).effectiveDate);
      },
      valueFormatter: ({ value }) => value && dayjs(value).locale(currentLocale).format('llll'),
    },

    {
      field: 'authAmount',
      ...currencyAmount(),
    },
    {
      field: 'capturedAmount',
      ...currencyAmount(),
    },
    {
      field: 'refundedAmount',
      ...currencyAmount(),
    },
    {
      field: 'status',
      valueGetter(params) {
        return getLatestTransaction(params.row).status;
      },
      ...chipString(params => {
        return { variant: 'outlined', size: 'small', color: params.value === 'SUCCESS' ? 'success' : 'error' };
      }),
    },
    {
      field: 'paymentExternalKey',
      flex: 2,
    },
  ];

  return (
    <DataGrid
      rows={[payment]}
      columns={paymentColumns.map(addFlex).map(translateHeaders('account.payment-table'))}
      hideFooterPagination
      hideFooter
      density="compact"
      getRowId={row => row.targetInvoiceId}
      disableRowSelectionOnClick
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
      }}
    />
  );
};

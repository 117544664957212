import webAnalytics from './web-analytics';
import recommendations from './recommendations';
import greensights from './greensights';
import { productGroup } from './product-group';

const productMenus = {
  'web-analytics': webAnalytics,
  greensights,
  recommendations,
};

export const getBundleId = (subscriptions, productName) => {
  const subscription = subscriptions.filter(el =>
    el.subscriptions.some(sub => sub.state === 'ACTIVE' && sub.productCategory === 'BASE' && sub.productName === productName)
  );
  return subscription[0]?.bundleId;
};

export const getMenuItems = (subscriptions, subscribedProducts) => {
  return {
    items: subscribedProducts.map(product => {
      const subMenu = productMenus[product.name];
      if (subMenu !== undefined) {
        return productGroup(product.name, getBundleId(subscriptions, product.name), subMenu);
      }
    }),
  };
};

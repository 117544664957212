import React from 'react';

import { Button, Card, CardContent } from '@mui/material';
import { Box, BoxProps } from '@mui/system';

export const TabCardWithAction = ({
  children,
  actions,
  sxContainer = {},
}: {
  children: JSX.Element;
  actions: JSX.Element;
  sxContainer?: BoxProps;
}): JSX.Element => {
  return (
    <Box sx={{ ...sxContainer }}>
      <Box display="flex" justifyContent="end" mb={1}>
        {actions}
      </Box>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';

const muiFormBuilderUrl = CONFIG_MANAGER_API_URL + '/muiFormBuilder';

export const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  settingsCatalog: {} as unknown as any,
  subscriptionId: null as string,
};

export type SubscriptionSettingsState = Readonly<typeof initialState>;

// Actions

export const getSubscriptionSettingsCatalog = createAsyncThunk(
  'subscriptionSettings/getSubscriptionSettingsCatalog',
  async ({ subscriptionId }: { subscriptionId: any }) => microserviceHttpClient(`${muiFormBuilderUrl}/${subscriptionId}`),
  { serializeError: serializeAxiosError }
);

// Slice

export const SubscriptionSettingsSlice = createSlice({
  name: 'settingsCatalog',
  initialState: initialState as SubscriptionSettingsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSubscriptionSettingsCatalog.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getSubscriptionSettingsCatalog.fulfilled, (state, action) => {
        state.loading = false;
        state.settingsCatalog = action.payload.data.fields;
        state.subscriptionId = action.payload.data.subscriptionId;
      })
      .addCase(getSubscriptionSettingsCatalog.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.settingsCatalog = {} as unknown as any;
        state.subscriptionId = null as string;
      });
  },
});

// Reducer
export default SubscriptionSettingsSlice.reducer;

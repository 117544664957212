import React from 'react';

import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { lastMonth, lastWeek, last6Month } from '../../../utils/timeParams';
import { useSelectedTimeParams } from './timeranges';
import { Translate } from 'react-jhipster';

export const LineChartsActions = () => {
  const { timeParams, setSelectedTimeParams } = useSelectedTimeParams();

  return (
    <Select
      value={timeParams as any}
      variant="standard"
      size="small"
      onChange={(event: SelectChangeEvent) => {
        setSelectedTimeParams(event.target.value as any);
      }}
    >
      <MenuItem value={lastWeek as any}>
        <Translate contentKey="module-factory.timerange.last-week">Last week</Translate>
      </MenuItem>
      <MenuItem value={lastMonth as any}>
        <Translate contentKey="module-factory.timerange.last-month">Last month</Translate>
      </MenuItem>
      <MenuItem value={last6Month as any}>
        <Translate contentKey="module-factory.timerange.last-6-month">Last 6 month</Translate>
      </MenuItem>
    </Select>
  );
};

import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { IPaymentMethods } from 'app/shared/model/payment-methods.model';
import dayjs from 'dayjs';
import { Image } from 'mui-image';
import React from 'react';
import { Translate } from 'react-jhipster';

export const sepaDebitPaymentMethodMap = (paymentMethod: IPaymentMethods, setDefault: () => void, currentLocale: string) => {
  function getProperty(prop) {
    return paymentMethod.pluginInfo.properties.find(item => item.key === prop).value;
  }

  return {
    id: paymentMethod.paymentMethodId,
    paymentMainInfos: {
      icon: <Image src={'/content/images/SepaLogoEN.jpg'} width={32} height={'auto'} />,
      mainInfos: ['SEPA DEBIT', `•••• ${getProperty('sepa_debit_last4')}`],
      chipMainInfos: [
        //   paymentMethod.isDefault ? { color: "primary", size: "small", label: translate('payment-method.default', null, 'Default') } : undefined,
      ],
      isDefault: paymentMethod.isDefault,
      setDefault,
    },
    paymentMethodInfos: [
      {
        title: 'Payment method ID',
        i18n: 'payment-method.payment-method-id',
        value: paymentMethod.paymentMethodId,
      },
      {
        title: 'Country',
        i18n: 'payment-method.country',
        value: getProperty('sepa_debit_country'),
        renderPaymentInfo(info: { title: string; i18n: string; value: any }, index: number) {
          return [
            <Translate key={index} contentKey={info.i18n}>
              {info.title}
            </Translate>,
            <FlagWithCountryName
              key={index}
              country={info.value}
              sxContainer={{ float: 'right' }}
              typographyProps={{ color: 'textSecondary' }}
            />,
          ];
        },
      },
      {
        title: 'Bank code',
        i18n: 'payment-method.bank-code',
        value: getProperty('sepa_debit_bank_code'),
      },
      {
        title: 'Number',
        i18n: 'payment-method.number',
        value: `•••• ${getProperty('sepa_debit_last4')}`,
      },
      // {
      //   title: 'Mandate',
      //   i18n: 'payment-method.mandate',
      //   value: getProperty('sepa_debit_mandate_reference'), // link at 'sepa_debit_mandate_url'
      // },
      {
        title: 'Created',
        i18n: 'payment-method.created',
        value: dayjs(parseInt(getProperty('created'), 10) * 1000)
          .locale(currentLocale)
          .format('lll'),
      },
      {
        title: 'Fingerprint',
        i18n: 'payment-method.fingerprint',
        value: getProperty('sepa_debit_fingerprint'),
      },
    ],
  };
};

import React from 'react';

import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { lastMonth, lastWeek, today, yesterday } from './timeranges';
import { setParams } from 'app/shared/reducers/web-analytics/line-charts-tabs';
import { Translate } from 'react-jhipster';

export const LineChartsActions = () => {
  const { params } = useAppSelector(state => state.webAnalyticsOverview.lineChartsTabs);
  const dispatch = useAppDispatch();

  return (
    <Select
      value={params}
      variant="standard"
      size="small"
      onChange={(event: SelectChangeEvent) => {
        dispatch(setParams(event.target.value));
      }}
    >
      <MenuItem value={today as any}>
        <Translate contentKey="module-factory.timerange.today">Today</Translate>
      </MenuItem>
      <MenuItem value={yesterday as any}>
        <Translate contentKey="module-factory.timerange.yesterday">Yesterday</Translate>
      </MenuItem>
      <MenuItem value={lastWeek as any}>
        <Translate contentKey="module-factory.timerange.last-week">Last week</Translate>
      </MenuItem>
      <MenuItem value={lastMonth as any}>
        <Translate contentKey="module-factory.timerange.last-month">Last month</Translate>
      </MenuItem>
    </Select>
  );
};

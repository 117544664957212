import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { last3Weeks } from 'app/modules/views/module-root/views/web-analytics/components/users-cohort/actions/timeranges';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { ICohort } from 'app/shared/model/user-exploration/cohort.model';
import { serializeAxiosError } from '../reducer.utils';

const userExplorationUrl = DATA_HUB_API_URL + '/exploration/user';

const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  params: last3Weeks,
  data: null as unknown as Array<ICohort>,
};

export type CohortState = Readonly<typeof initialState>;

export const getUsersCohortData = createAsyncThunk(
  'webAnalyticsHome/getUsersCohortData',
  async ({ filter, timeStep, nbTimeSteps }: any) => {
    return microserviceHttpClient(
      `${userExplorationUrl}/cohortAnalysis?timeStep=${timeStep}&nbTimeSteps=${nbTimeSteps}`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    );
  },
  { serializeError: serializeAxiosError }
);

export const UsersCohortSlice = createSlice({
  name: 'usersCohort',
  initialState: initialState as CohortState,
  reducers: {
    setParams(state, action) {
      state.params = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsersCohortData.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getUsersCohortData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.sort(function (a, b) {
          return Number(Object.keys(a.data).length) - Number(Object.keys(b.data).length);
        }) as Array<ICohort>;
      })
      .addCase(getUsersCohortData.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.data = null as unknown as Array<ICohort>;
      });
  },
});

export const { setParams } = UsersCohortSlice.actions;

export default UsersCohortSlice.reducer;

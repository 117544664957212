import { Chip, ChipProps, Grid, Stack, Typography } from '@mui/material';
import { GridColTypeDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IShowMoreLessProps, ShowMoreLess } from 'app/components/buttons/ShowMoreLess';
import React from 'react';
import { translate } from 'react-jhipster';
import { Flag } from 'app/components/country-flag/Flag';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrandChrome, BrandFirefox, BrandSafari } from 'tabler-icons-react';
import { Box } from '@mui/system';
import SvgIcon from '@mui/material/SvgIcon';

export function renderFlag(params) {
  return <Flag country={params.value} tooltip={true} />;
}

export function renderDevice(params) {
  if (String(params.value) === 'Personal computer') return <LaptopWindowsIcon titleAccess="Personal computer" />;
  else if (String(params.value) === 'Smartphone') return <PhoneIphoneIcon titleAccess="Smartphone" />;
}

export function renderBrowser(params) {
  if (String(params.value) === 'Chrome') return <BrandChrome size={26} strokeWidth={1.5} color={'black'} />;
  else if (String(params.value) === 'Safari') return <BrandSafari size={26} strokeWidth={1.5} color={'black'} />;
  else if (String(params.value) === 'Chrome Mobile') return <BrandChrome size={26} strokeWidth={1.5} color={'black'} />;
  else if (String(params.value) === 'Firefox') return <BrandFirefox size={26} strokeWidth={1.5} color={'black'} />;
}

export function renderOs(params) {
  if (String(params.value) === 'Windows') return <FontAwesomeIcon icon="windows" />;
  else if (String(params.value) === 'OS X') return <FontAwesomeIcon icon="windows" />;
}

export function renderEcoIndex(params) {
  return (
    <>
      <SvgIcon width="96px" height="96px" viewBox="0 0 96 96" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-895.000000, -279.000000)" fillRule="nonzero">
            <g transform="translate(895.080408, 279.119034)">
              {(function () {
                if (params.value >= 80) {
                  return (
                    <>
                      <path
                        d="M92.6067756,0.00903678281 C94.316768,-0.134272239 95.9028831,1.4494139 95.7571451,3.15940629 C95.5458251,5.59565966 92.5144749,60.9542345 74.7975935,78.6711159 C57.8191178,95.6495915 37.811721,91.6660865 24.7463104,82.2732732 C24.5884276,82.1591118 24.411113,82.0230896 24.2167957,81.8676358 L12.4387373,93.6286914 C9.59198859,96.4730112 4.97938126,96.4730112 2.13506153,93.6286914 C-0.711687176,90.7819427 -0.711687176,86.1693354 2.13506153,83.3250157 L13.8985461,71.5493862 C13.7406633,71.3526399 13.6046412,71.1753254 13.4904798,71.0174426 C4.1000954,57.9544609 0.114161424,37.9470641 17.092637,20.9661595 C34.8095185,3.24927805 90.1680933,0.215498933 92.6067756,0.00903678281 Z"
                        fill="#1E8F4E"
                      ></path>
                      <path
                        d="M62.1702833,67.8120398 C64.722627,67.8120398 66.5498731,66.1008094 66.5498731,63.6934851 C66.5498731,62.823368 66.4048536,62.0402625 65.9987989,60.9091101 L54.513252,29.7589148 C53.2660841,26.3364539 51.2358106,24.8282508 47.8133497,24.8282508 C44.5069044,24.8282508 42.447627,26.3944617 41.229463,29.7879187 L29.8019239,60.9091101 C29.4248731,61.9822547 29.2218458,62.9683875 29.2218458,63.6934851 C29.2218458,66.216825 30.9330763,67.8120398 33.6594435,67.8120398 C35.979756,67.8120398 37.2849317,66.6518836 38.0970411,63.8675086 L40.301338,57.3416297 L55.4123731,57.3416297 L57.61667,63.9545203 C58.3997755,66.6808875 59.7049513,67.8120398 62.1702833,67.8120398 Z M53.3820997,50.5547156 L42.2155958,50.5547156 L47.6393263,33.413407 L47.8423536,33.413407 L53.3820997,50.5547156 Z"
                        fill="#FFFFFF"
                      ></path>
                    </>
                  );
                } else if (params.value >= 60) {
                  return (
                    <>
                      <path
                        d="M92.6067756,0.00903678281 C94.316768,-0.134272239 95.9028831,1.4494139 95.7571451,3.15940629 C95.5458251,5.59565966 92.5144749,60.9542345 74.7975935,78.6711159 C57.8191178,95.6495915 37.811721,91.6660865 24.7463104,82.2732732 C24.5884276,82.1591118 24.411113,82.0230896 24.2167957,81.8676358 L12.4387373,93.6286914 C9.59198859,96.4730112 4.97938126,96.4730112 2.13506153,93.6286914 C-0.711687176,90.7819427 -0.711687176,86.1693354 2.13506153,83.3250157 L13.8985461,71.5493862 C13.7406633,71.3526399 13.6046412,71.1753254 13.4904798,71.0174426 C4.1000954,57.9544609 0.114161424,37.9470641 17.092637,20.9661595 C34.8095185,3.24927805 90.1705223,0.215498933 92.6067756,0.00903678281 Z"
                        fill="#2ECC71"
                      ></path>
                      <path
                        d="M52.0772278,67.2609656 C61.0104309,67.2609656 66.550177,62.6493445 66.550177,55.2823523 C66.550177,49.7426062 62.3736145,45.5950476 56.6888489,45.1599891 L56.6888489,44.9279578 C61.1264465,44.260868 64.5489075,40.4323523 64.5489075,35.8497351 C64.5489075,29.4398719 59.6182434,25.4083289 51.7581848,25.4083289 L37.9233215,25.4083289 C35.1679504,25.4083289 33.5437317,27.0905555 33.5437317,29.9619422 L33.5437317,62.6783484 C33.5437317,65.5787391 35.1679504,67.2609656 37.9233215,67.2609656 L52.0772278,67.2609656 Z M48.596759,42.8106726 L42.3029114,42.8106726 L42.3029114,31.9342078 L49.4958801,31.9342078 C53.5854309,31.9342078 55.9347473,33.8484656 55.9347473,37.2129187 C55.9347473,40.7513953 53.2663879,42.8106726 48.596759,42.8106726 Z M49.843927,60.7350867 L42.3029114,60.7350867 L42.3029114,48.58245 L49.6408996,48.58245 C54.803595,48.58245 57.6169739,50.6707312 57.6169739,54.5862586 C57.6169739,58.5887976 54.8906067,60.7350867 49.843927,60.7350867 Z"
                        fill="#FFFFFF"
                      ></path>
                    </>
                  );
                } else if (params.value >= 40) {
                  return (
                    <>
                      <path
                        d="M92.6043467,0.00903678281 C94.3143391,-0.134272239 95.9004542,1.4494139 95.7547162,3.15940629 C95.5433961,5.59565966 92.5120459,60.9542345 74.7951645,78.6711159 C57.8142599,95.6495915 37.8092921,91.6660865 24.7438814,82.2732732 C24.5859986,82.1591118 24.4086841,82.0230896 24.2143667,81.8676358 L12.4387373,93.6286914 C9.59198859,96.4730112 4.97938126,96.4730112 2.13506153,93.6286914 C-0.711687176,90.7819427 -0.711687176,86.1693354 2.13506153,83.3250157 L13.8985461,71.5493862 C13.7406633,71.3526399 13.6046412,71.1753254 13.4904798,71.0174426 C4.1000954,57.9544609 0.114161424,37.9470641 17.092637,20.9661595 C34.8070895,3.24927805 90.1656643,0.215498933 92.6043467,0.00903678281 Z"
                        fill="#F5C100"
                      ></path>
                      <path
                        d="M49.1413823,67.9570594 C57.0594487,67.9570594 63.2952885,64.3895789 65.9056401,58.5887976 C66.3406987,57.5736609 66.5727299,56.6455359 66.5727299,55.6594031 C66.5727299,53.3390906 64.9485112,51.7728797 62.4831791,51.7728797 C60.6269291,51.7728797 59.5247807,52.5559851 58.6256596,54.5282508 C56.7404057,58.7338172 53.4339604,60.7350867 49.0253666,60.7350867 C42.3254643,60.7350867 38.1198979,55.2243445 38.1198979,46.2911414 C38.1198979,37.4739539 42.3834721,31.9342078 48.9963627,31.9342078 C53.1729252,31.9342078 56.5953862,34.0804969 58.509644,38.3440711 C59.4667729,40.3743445 60.7139409,41.2444617 62.6862065,41.2444617 C65.006519,41.2444617 66.543726,39.7652625 66.543726,37.4739539 C66.543726,36.4298133 66.253687,35.2986609 65.6736088,34.1965125 C62.8022221,28.1637 56.7404057,24.6832312 48.909351,24.6832312 C36.8727299,24.6832312 29.1866948,32.8333289 29.1866948,46.3201453 C29.1866948,59.8359656 36.7277104,67.9570594 49.1413823,67.9570594 Z"
                        fill="#FFFFFF"
                      ></path>
                    </>
                  );
                } else if (params.value >= 20) {
                  return (
                    <>
                      <path
                        d="M92.6043467,0.00903678281 C94.3143391,-0.134272239 95.9004542,1.4494139 95.7547162,3.15940629 C95.5433961,5.59565966 92.5120459,60.9542345 74.7951645,78.6711159 C57.8166889,95.6495915 37.8092921,91.6660865 24.7438814,82.2732732 C24.5859986,82.1591118 24.4086841,82.0230896 24.2143667,81.8676358 L12.4387373,93.6286914 C9.59198859,96.4730112 4.97938126,96.4730112 2.13506153,93.6286914 C-0.711687176,90.7819427 -0.711687176,86.1693354 2.13506153,83.3250157 L13.8985461,71.5493862 C13.7406633,71.3526399 13.6046412,71.1753254 13.4904798,71.0174426 C4.1000954,57.9544609 0.114161424,37.9470641 17.092637,20.9661595 C34.8070895,3.24927805 90.1656643,0.215498933 92.6043467,0.00903678281 Z"
                        fill="#EF7E1A"
                      ></path>
                      <path
                        d="M48.7885159,67.2609656 C61.3472073,67.2609656 68.7432034,59.4589148 68.7432034,46.1751258 C68.7432034,32.8913367 61.3472073,25.4083289 48.7885159,25.4083289 L37.1869534,25.4083289 C34.4315823,25.4083289 32.8073635,27.0905555 32.8073635,29.9619422 L32.8073635,62.6783484 C32.8073635,65.5787391 34.4315823,67.2609656 37.1869534,67.2609656 L48.7885159,67.2609656 Z M47.7443752,60.0099891 L41.5665432,60.0099891 L41.5665432,32.6303016 L47.7443752,32.6303016 C55.4594143,32.6303016 59.8100002,37.44495 59.8100002,46.2041297 C59.8100002,55.2533484 55.5754299,60.0099891 47.7443752,60.0099891 Z"
                        fill="#FFFFFF"
                      ></path>
                    </>
                  );
                } else {
                  return (
                    <>
                      <path
                        d="M92.6043467,0.00903678281 C94.3143391,-0.134272239 95.9004542,1.4494139 95.7547162,3.15940629 C95.5433961,5.59565966 92.5120459,60.9542345 74.7951645,78.6711159 C57.8142599,95.6495915 37.8092921,91.6660865 24.7438814,82.2732732 C24.5859986,82.1591118 24.4086841,82.0230896 24.2143667,81.8676358 L12.4387373,93.6286914 C9.59198859,96.4730112 4.97938126,96.4730112 2.13506153,93.6286914 C-0.711687176,90.7819427 -0.711687176,86.1693354 2.13506153,83.3250157 L13.8985461,71.5493862 C13.7406633,71.3526399 13.6046412,71.1753254 13.4904798,71.0174426 C4.1000954,57.9544609 0.114161424,37.9470641 17.092637,20.9661595 C34.8046605,3.24927805 90.1656643,0.215498933 92.6043467,0.00903678281 Z"
                        fill="#D93726"
                      ></path>
                      <path
                        d="M59.9202059,67.2609656 C62.3275301,67.2609656 63.893741,65.897782 63.893741,63.6354773 C63.893741,61.3731726 62.2985262,60.0099891 59.9202059,60.0099891 L44.403116,60.0099891 L44.403116,49.4525672 L59.1661043,49.4525672 C61.3704012,49.4525672 62.8205965,48.1763953 62.8205965,46.0881141 C62.8205965,43.9998328 61.3413973,42.7236609 59.1661043,42.7236609 L44.403116,42.7236609 L44.403116,32.6593055 L59.9202059,32.6593055 C62.2985262,32.6593055 63.893741,31.2961219 63.893741,29.0338172 C63.893741,26.7715125 62.3275301,25.4083289 59.9202059,25.4083289 L40.0235262,25.4083289 C37.2681551,25.4083289 35.6439363,27.0905555 35.6439363,29.9619422 L35.6439363,62.6783484 C35.6439363,65.5787391 37.2681551,67.2609656 40.0235262,67.2609656 L59.9202059,67.2609656 Z"
                        fill="#FFFFFF"
                      ></path>
                    </>
                  );
                }
              })()}
            </g>
          </g>
        </g>
      </SvgIcon>
    </>
  );
}

export const currencyFormatter = (currency: string, locale = 'en-US') =>
  currency
    ? new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      })
    : new Intl.NumberFormat(locale);

// Columns types

export const currencyAmount = (locale?: string): GridColTypeDef => {
  return {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter(value[1], locale).format(Number(value[0])),
    valueGetter: params => [params.row[params.field], params.row.currency || undefined],
    cellClassName: 'font-tabular-nums',
  };
};

export const chipString = (renderProps?: (params) => ChipProps): GridColTypeDef => {
  return {
    type: 'string',
    renderCell: params => <Chip label={params.value} {...(renderProps && renderProps(params))} />,
  };
};

export const arraycChipString = (): GridColTypeDef => {
  return {
    renderCell: params => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {params.value.map(chip => (
          <Chip key={chip} label={chip} size={'small'} color={'default'} />
        ))}
      </Box>
    ),
  };
};

export const chipStringStyled = (commonProps?: ChipProps, valueProps?: { [key: string]: ChipProps }): GridColTypeDef => {
  return {
    type: 'string',
    ...chipString(params => {
      return { ...commonProps, ...valueProps[params.value] };
    }),
  };
};

export const subFieldToggle = (subField: string, moreLessProps?: Partial<IShowMoreLessProps>): GridColTypeDef => {
  return {
    renderCell: params => (
      <Stack spacing={1}>
        <Typography>{params.value}</Typography>
        {params.row[subField] && (
          <ShowMoreLess {...moreLessProps} openContent={<Typography color="textSecondary">{params.row[subField]}</Typography>} />
        )}
      </Stack>
    ),
  };
};

export const arrayStrings = (renderProps?: (item) => ChipProps): GridColTypeDef => {
  return {
    sortable: false,
    renderCell: params => (
      <Grid container spacing={1}>
        {params.value.map(item => {
          return (
            <Grid item key={item}>
              <Chip label={item} {...(renderProps && renderProps(item))} />
            </Grid>
          );
        })}
      </Grid>
    ),
  };
};

export const typographyWithTooltip = (value = 'value'): GridColTypeDef => {
  return {
    renderCell: (params: GridRenderCellParams) => (
      <Typography
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}
        component="span"
        title={params[value]}
      >
        {params[value]}
      </Typography>
    ),
  };
};

// Columns enrichment function

export const translateHeaders = i18nPrefix => {
  return value => {
    return {
      headerName: translate(`${i18nPrefix}.${value.field}`, null, value.field),
      ...value,
    };
  };
};

export const addFlex = value => {
  return {
    flex: 1,
    ...value,
  };
};

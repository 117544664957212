import { UserProfileDtoRes, userProfilesApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';
import { buildAccounts } from 'app/shared/util/accounts-utils';
import { useMemo } from 'react';

function sortAlphabetically(a, b) {
  const x = a.label.toLowerCase();
  const y = b.label.toLowerCase();
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
}

export const useUserStates = () => {
  const { data: userProfile = {} as UserProfileDtoRes } = userProfilesApi.endpoints.getCurrentUserProfileUsingGet.useQueryState({});

  const currentUserRolesSelector = res =>
    useMemo(() => {
      const accounts = res.data ? res.data.accounts?.filter(acc => acc.accountId === res.data.currentAccount) : [];
      const firstAccountInfo = accounts.length > 0 ? accounts[0] : {};
      return {
        currentUserRoles: firstAccountInfo.roles ? firstAccountInfo.roles : [],
      };
    }, [res.data]);

  const { currentUserRoles } = userProfilesApi.endpoints.getCurrentUserProfileUsingGet.useQueryState(
    {},
    {
      selectFromResult: currentUserRolesSelector,
    }
  );

  const accountsSelector = res =>
    useMemo(() => {
      return {
        accountOptions: res.data
          ? buildAccounts(userProfile.accounts, res.data)
              .map(i => ({ label: i.name || i.accountId.substring(0, 8), id: i.accountId }))
              .sort(sortAlphabetically)
              .concat([{ label: 'Create New Account', id: '-1' }])
          : [],
        allUserAccounts: res.data ? buildAccounts(userProfile.accounts, res.data) : [],
      };
    }, [res.data]);

  const { accountOptions, allUserAccounts } = userProfilesApi.endpoints.getCurrentUserAccountsUsingGet.useQueryState(undefined, {
    selectFromResult: accountsSelector,
  });
  return {
    userProfile,
    currentUserRoles,
    accountOptions,
    allUserAccounts,
    selectedAccount: accountOptions.find(i => i.id === userProfile.currentAccount) || null,
  };
};

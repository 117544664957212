import React, { useState } from 'react';

import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Skeleton } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useAddPaymentMethodUsingPostMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/payment-method';
import { translate } from 'react-jhipster';
import { Box } from '@mui/system';

export const AddPaymentMethodForm = ({ setOpen, accountProfile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState(false);
  const [addPaymentMethod] = useAddPaymentMethodUsingPostMutation();
  const handleDefaultPayment = () => {
    setDefaultPayment(prevState => !prevState);
  };
  const stripe = useStripe();
  const elements = useElements();

  function handleSubmit(event) {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (accountProfile.accountId) {
      setIsLoading(true);

      stripe
        .confirmSetup({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: window.location.toString(),
          },
          redirect: 'if_required',
        })
        .then(res => {
          if (res.error) {
            return;
          }
          addPaymentMethod({
            accountKey: accountProfile.accountId,
            paymentMethodDto: {
              pluginName: 'killbill-stripe',
              pluginInfo: {
                externalPaymentMethodId: res.setupIntent.payment_method as string,
              },
            },
            isDefault: defaultPayment,
          });
          setOpen(false);
        });

      setIsLoading(false);
    }
  }

  const cancelButton = (
    <Button color="error" onClick={() => setOpen(false)}>
      {translate('account.cancel')}
    </Button>
  );

  const submitButton = (
    <Button disabled={isLoading || !stripe || !elements} variant="contained" type="submit">
      {translate('new-payment.new-payment-button')}
    </Button>
  );

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent dividers>
        {!elements ? (
          <Skeleton variant="rectangular" width="100%" height="20vh" />
        ) : (
          <PaymentElement id="payment-element" options={{ paymentMethodOrder: ['sepa_debit', 'cards'] }} />
        )}
      </DialogContent>
      <DialogActions sx={{ paddingX: 3 }}>
        <FormControlLabel
          control={<Checkbox checked={defaultPayment} onChange={handleDefaultPayment} inputProps={{ 'aria-label': 'controlled' }} />}
          label="As default payment method"
        />
        <Box flexGrow={1} />
        {cancelButton}
        {submitButton}
      </DialogActions>
    </form>
  );
};

const initialOthers = { name: 'Others', value: 0 };

function sumToOthers(others, el) {
  others.value += Number(el.value);
  return others;
}

export function reduceElementsAfterN(array, keepOnlyNElements, func = sumToOthers, initValue = Object.assign({}, initialOthers)) {
  return array?.slice(0, keepOnlyNElements).concat(array.slice(keepOnlyNElements).reduce(func, initValue));
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { lastWeek } from 'app/modules/views/module-root/views/web-analytics/components/line-charts-tabs/actions/timeranges';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { KPIS } from 'app/shared/util/reducer-utils';
import { getKpiFromPayload } from 'app/shared/util/data-hub-utils';
import { serializeAxiosError } from '../reducer.utils';

const kpisAggregationUrl = DATA_HUB_API_URL + '/exploration/data';

const initialState = {
  errorMessage: null as unknown as string, // Errors returned from server side
  loading: false,

  params: lastWeek,

  data: {
    USER_COUNT: null as Array<[number, string]>,
    SESSION_COUNT: null as Array<[number, string]>,
    BOUNCE_RATE: null as Array<[number, string]>,
    AVG_SESSION_DURATION: null as Array<[number, string]>,
  },
};

// Actions
export const getLineChartsTabsData = createAsyncThunk(
  'webAnalyticsHome/FETCH_LINE_CHARTS_TABS_DATA',
  async ({ filter, from, to, step }: any) => {
    return microserviceHttpClient(
      `${kpisAggregationUrl}/kpisHistory?timeStep=${step}&from=${from}&to=${to}&kpis=USER_COUNT,SESSION_COUNT,AVG_SESSION_DURATION,BOUNCE_RATE`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    ).then(resp => resp.data);
  },
  { serializeError: serializeAxiosError }
);

export type LineChartsTabsData = Readonly<typeof initialState>;

// Slice
export const LineChartsTabsSlice = createSlice({
  name: 'lineChartsTabs',
  initialState: initialState as LineChartsTabsData,
  reducers: {
    setParams(state, action) {
      state.params = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLineChartsTabsData.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getLineChartsTabsData.fulfilled, (state, action) => {
        state.loading = false;
        state.data.USER_COUNT = getKpiFromPayload(action.payload, KPIS.USER_COUNT);
        state.data.SESSION_COUNT = getKpiFromPayload(action.payload, KPIS.SESSION_COUNT);
        state.data.BOUNCE_RATE = getKpiFromPayload(action.payload, KPIS.BOUNCE_RATE);
        state.data.AVG_SESSION_DURATION = getKpiFromPayload(action.payload, KPIS.AVG_SESSION_DURATION);
      })
      .addCase(getLineChartsTabsData.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.data.USER_COUNT = null as Array<[number, string]>;
        state.data.SESSION_COUNT = null as Array<[number, string]>;
        state.data.BOUNCE_RATE = null as Array<[number, string]>;
        state.data.AVG_SESSION_DURATION = null as Array<[number, string]>;
      });
  },
});

export const { setParams } = LineChartsTabsSlice.actions;
export default LineChartsTabsSlice.reducer;

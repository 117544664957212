import { Slice, configureStore, createSelector } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import staticReducers from 'app/shared/reducers';
import { rtkMiddlewares, rtkReducers } from 'app/shared/reducers/api';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction, Reducer, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { ThunkAction } from 'redux-thunk';
import type {} from 'redux-thunk/extend-redux';
import errorMiddleware from './error-middleware';

const defaultMiddlewareOpts = {
  serializableCheck: {
    // Ignore these field paths in all actions
    ignoredActionPaths: [
      'payload.config',
      'payload.request',
      'error',
      'meta.arg',
      'payload.headers',
      'meta.baseQueryMeta.request',
      'meta.baseQueryMeta.response',
    ],
  },
};

const initialStore = configureStore({
  reducer: {
    ...rtkReducers,
    ...staticReducers,
  },
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware(defaultMiddlewareOpts),
    ...rtkMiddlewares,
    errorMiddleware,
    createLogger({ collapsed: true }),
    // notificationMiddleware,
    // loadingBarMiddleware()
  ],
  // enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(offline(offlineConfig)),
  // enhancers: compose([monitorReducerEnhancer]),
  devTools: true,
});

class DynamicStoreManager {
  store: ToolkitStore;
  rtkSlices: Record<string, Slice>;
  asyncReducers: Record<string, Reducer>;

  constructor(store: ToolkitStore) {
    // Initial store configuration
    this.store = store;
    this.rtkSlices = {};
    this.asyncReducers = {};
  }

  getDynamicSlice(key: string): Slice {
    return this.rtkSlices[key];
  }

  selectSliceState<T = unknown, R = unknown>(key: string): (state: T) => R {
    return state => {
      const parts = key.split('/');
      return parts.reduce((acc, part) => {
        if (acc && typeof acc === 'object' && part in acc) {
          return acc[part];
        }
        return undefined;
      }, state) as R;
    };
  }

  makeSelectSliceStates = (keys: string[]) =>
    createSelector(
      keys.map(key => this.selectSliceState(key)),
      (...selectedSliceStates) =>
        selectedSliceStates.reduce((result, state, index) => {
          const key = keys[index];
          return {
            ...(result as any),
            [key]: state,
          };
        }, {})
    );

  injectSlice(key: string, rtkSlice: Slice) {
    this.rtkSlices[key] = rtkSlice;
    this.asyncReducers[key] = rtkSlice.reducer;
    this.updateReducers();
  }

  removeSlice(key: string) {
    if (key in this.rtkSlices) {
      delete this.rtkSlices[key];
      delete this.asyncReducers[key];
      this.updateReducers();
    }
  }

  private unflatten(reducers) {
    return Object.entries(reducers).reduce((acc, [key, reducer]) => {
      const parts = key.split('/');
      parts.reduce((nestedAcc, part, index) => {
        if (index === parts.length - 1) {
          nestedAcc[part] = reducer;
        } else {
          nestedAcc[part] = nestedAcc[part] ?? {};
        }
        return nestedAcc[part];
      }, acc);
      return acc;
    }, {});
  }

  private recursiveCombineReducers(reducers) {
    return Object.keys(reducers).reduce((combined, key) => {
      const reducer = reducers[key];
      const combinedReducer = typeof reducer === 'object' ? combineReducers(this.recursiveCombineReducers(reducer)) : reducer;
      return { ...combined, [key]: combinedReducer };
    }, {});
  }

  private updateReducers() {
    const treeReducers = this.unflatten(this.asyncReducers);
    const combinedTreeReducers = this.recursiveCombineReducers(treeReducers);

    this.store.replaceReducer(
      combineReducers({
        ...rtkReducers,
        ...staticReducers,
        ...combinedTreeReducers,
      })
    );
  }
}

// Create an instance of the DynamicStoreManager
export const dynamicStoreManager = new DynamicStoreManager(initialStore);

const getStore = () => dynamicStoreManager.store;

export type IRootState = ReturnType<typeof dynamicStoreManager.store.getState>;
export type AppDispatch = typeof dynamicStoreManager.store.dispatch;

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, AnyAction>;

export default getStore;

import { TabPanelProps } from '@mui/lab';
import { Card, Stack, TabProps } from '@mui/material';
import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { MainCard } from 'app/components/card/main-card/MainCard';
import { ControlledTabulate, TabItem, TabItemProps, Tabulate } from 'app/components/tabulation';
import React from 'react';
import { translate } from 'react-jhipster';
import { useSearchParams } from 'react-router-dom';
import { AccountsTable } from './components/AccountsTable';
import { ProfileForm } from './components/ProfileForm';

export const ProfilePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tabulations: TabItemProps[] = [
    {
      tab: { label: translate('profile.tab.profile', null, 'Profile') },
      children: <ProfileForm />,
    },
    {
      tab: { label: translate('profile.tab.accounts', null, 'Accounts') },
      children: <AccountsTable />,
    },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} paddingBottom={3}>
        <BreadcrumbGenerator />
      </Stack>

      <ControlledTabulate tabValue={searchParams.get('selectTab')} setTabValue={value => setSearchParams({ selectTab: value })}>
        {tabulations.map((el, index) => (
          <TabItem key={index} {...el} />
        ))}
      </ControlledTabulate>
    </>
  );
};

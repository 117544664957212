import React, { useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Snackbar } from '@mui/material';
import { translate, Translate } from 'react-jhipster';

export const ConfirmDeleteToken = ({
  token,
  setToken,
  deleteAccesToken,
}: {
  token: string;
  setToken: (b: boolean) => void;
  deleteAccesToken: () => void;
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  return (
    <>
      <Dialog
        onClose={() => {
          setToken(null);
        }}
        open={token !== null}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography m={1}>
            <Translate contentKey="account.confirm-delete">Confirm Token Removal</Translate>
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography m={1}>
            <Translate contentKey="account.confirm-delete-message">
              Are you sure you want to delete this token? this action is irreversible
            </Translate>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setToken(null);
            }}
          >
            <Translate contentKey="account.cancel">Cancel</Translate>
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              deleteAccesToken();
              setOpenSnackbar(true);
            }}
          >
            <Translate contentKey="account.delete">Delete</Translate>
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        message={translate('account.access-token.token-deleted', null, 'Token has been deleted')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
      />
    </>
  );
};

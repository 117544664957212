import React from 'react';

import { Stack } from '@mui/material';
import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { translate } from 'react-jhipster';
import { useSearchParams } from 'react-router-dom';
import { ControlledTabulate, TabItem, TabItemProps } from 'app/components/tabulation';
import { useAccountPage } from './hooks/useAccountPage';
import { AccountDialogs } from './components/AccountDialogs';
import { accountFormsList } from './account-forms/accountFormsList';
import { AccountDetailsTab } from './components/tabulations/AccountDetailsTab';
import { BillingInfosTab } from './components/tabulations/BillingInfosTab';
import { PaymentMethodTab } from './components/tabulations/PaymentMethodTab';
import { SubscriptionsTab } from './components/tabulations/SubscriptionsTab';
import { InvoicesTab } from './components/tabulations/InvoicesTab';
import { MembersTab } from './components/tabulations/MembersTab';
import { SecurityTab } from './components/tabulations/SecurityTab';

export const AccountPage = () => {
  const { billingAccount, accountProfile, dialogsState } = useAccountPage();

  const [searchParams, setSearchParams] = useSearchParams();

  const tabulations: TabItemProps[] = [
    {
      tab: { label: translate('account.tabulation.details', null, 'Details') },
      children: <AccountDetailsTab dialogsState={dialogsState} />,
    },
    {
      tab: { label: translate('account.tabulation.billing', null, 'Billing') },
      children: <BillingInfosTab dialogsState={dialogsState} />,
    },
    {
      tab: { label: translate('account.tabulation.payment-method', null, 'Payment Methods') },
      children: <PaymentMethodTab dialogsState={dialogsState} />,
    },
    {
      tab: { label: translate('account.tabulation.subscriptions', null, 'Subscriptions') },
      children: <SubscriptionsTab />,
    },
    {
      tab: { label: translate('account.tabulation.invoices', null, 'Invoices') },
      children: <InvoicesTab />,
    },
    {
      tab: { label: translate('account.tabulation.members', null, 'Members') },
      children: <MembersTab dialogsState={dialogsState} />,
    },
    {
      tab: { label: translate('account.tabulation.security', null, 'Security') },
      children: <SecurityTab />,
    },
  ];

  return (
    <>
      <AccountDialogs accountFormsList={accountFormsList(billingAccount, accountProfile, dialogsState)} dialogsState={dialogsState} />

      <Stack direction="row" alignItems="center" spacing={2} paddingBottom={3}>
        <BreadcrumbGenerator />
      </Stack>

      <ControlledTabulate tabValue={searchParams.get('selectTab')} setTabValue={value => setSearchParams({ selectTab: value })}>
        {tabulations.map((el, index) => (
          <TabItem key={index} {...el} />
        ))}
      </ControlledTabulate>
    </>
  );
};

import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { setHomeView } from 'app/shared/reducers/main-layouts/menubar';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';

export const useHomeModule = () => {
  const dispatch = useAppDispatch();

  const { accountProfile } = useAppAccountStates();
  const { homeView } = useAppSelector(state => state.mainLayouts.menubarReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('homeView')) dispatch(setHomeView(searchParams.get('homeView')));
  }, []);

  const onChangeHandlerToggle = currentBucket => {
    if (currentBucket) {
      setSearchParams({ homeView: currentBucket });
      dispatch(setHomeView(currentBucket));
    }
  };

  return {
    accountProfile,
    homeView,
    onChangeHandlerToggle,
  };
};

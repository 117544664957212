import React from 'react';

import { useScrollTrigger, Slide, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { BetaRegisterButton } from 'app/modules/views/public-home/pages/landing-page/sections/components/BetaRegisterButton';
import { Translate } from 'react-jhipster';
import { StyleOnScroll } from '../../index/LandingAppbar';
import { getAlphaColor } from 'app/shared/util/color-palette-calculator';

export const BetaProgramBar = ({ appBarRef }) => {
  return (
    <StyleOnScroll>
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          height: '50px',
          display: { xs: 'flex', md: 'none' },
          backgroundColor: theme => getAlphaColor(theme.palette.background.paper, 0.7),
          backdropFilter: 'blur(5px)',
          position: 'fixed',
          top: appBarRef.current ? appBarRef.current.clientHeight : 0,
          justifyContent: 'center',
          zIndex: theme => theme.zIndex.appBar,
        }}
      >
        <BetaRegisterButton variant="text">
          <Translate contentKey="landing.actions.register-beta-program">Apply to our Beta Program now!</Translate>
        </BetaRegisterButton>
      </Paper>
    </StyleOnScroll>
  );
};

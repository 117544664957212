import React, { useState } from 'react';

import FiltersWrapper from 'app/components/filter-wrapper';
import { timeRangeListBuilder } from 'app/components/refresh-rate/timeRange';

export const GreensightsFilters = ({ timerange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const liveHandler = event => {
    if (timerange.isLive()) {
      timerange.setLiveOff();
    } else {
      timerange.setLiveOn();
    }
  };
  const quickRanges = timeRangeListBuilder(['-1w/d', '-1M/d', '-6M/d'], undefined, undefined, undefined);
  return (
    <FiltersWrapper
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isLive={timerange.isLive()}
      liveClickHandler={liveHandler}
      refreshRateList={[]}
      refresh={timerange.refresh}
      refreshRate={0}
      setRefreshRate={timerange.setRefreshRate}
      setRefresh={timerange.setRefresh}
      step={timerange.step}
      fromDate={timerange.fromDate}
      setFromDate={timerange.setFromDate}
      toDate={timerange.toDate}
      setToDate={timerange.setToDate}
      setStep={timerange.setStep}
      stepOptions={[]}
      quickRanges={quickRanges}
      enableLiveButton={false}
      chosenRange={timerange.chosenRange}
      setChosenRange={timerange.setChosenRange}
    />
  );
};

import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export const dimensions = [
  {
    key: 'country_code',
    title: 'country'
  },
  {
    key: 'zone',
    title: 'zone'
  },
  {
    key: 'device_category',
    title: 'device-category'
  },
  {
    key: 'operating_system',
    title: 'operating_system'
  },
];

const selectedDimension$ = new BehaviorSubject(dimensions[0]);

const setSelectedDimension = (dimension: any) => {
  selectedDimension$.next(dimension);
};

export const useSelectedDimension = () => {
  const [selectedDimension, updateSelectedDimension] = useState(selectedDimension$.value);
  useEffect(() => {
    const subscription = selectedDimension$.subscribe(value => updateSelectedDimension(value));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { selectedDimension, setSelectedDimension };
};

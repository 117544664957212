import { useEffect } from 'react';

import { useAppDispatch } from 'app/config/store';
import { setDataExplorationTimerange } from 'app/shared/reducers/views/data-exploration';

export const useDataExplorationTimerange = timerange => {
  const dispatch = useAppDispatch();
  const { chosenRange, fromDate, toDate, refresh, refreshRate, step } = timerange;
  useEffect(() => {
    dispatch(
      setDataExplorationTimerange({
        chosenRange,
        fromDate,
        toDate,
        refresh,
        refreshRate,
        step: timerange.getStep(),
        live: timerange.isLive(),
      })
    );
  }, [chosenRange, fromDate, toDate, refresh, refreshRate, step]);
};

import { getFormFields } from '../form/requestFilterFormFields';
import { validationUtils } from 'app/modules/form-builder-lib';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'app/config/store';
import { useEffect, useState } from 'react';

export const useRequestFilterForm = (filterValues, filter, selectAllFilters, selectFiltersIds) => {
  const schema = validationUtils.getFormSchema(getFormFields(filterValues, filter, selectAllFilters, selectFiltersIds));
  const dispatch = useAppDispatch();

  const [defaultValue, setDefaultValue] = useState<FieldValues>({});

  const handleAddFilterSubmit = (addFilter, data) => {
    if (['IN_VALUE_RANGE', 'NOT_IN_VALUE_RANGE'].includes(data.filteringKind))
      dispatch(addFilter({ key: data.key, filteringKind: data.filteringKind, value: [data.from, data.to] }));
    else if (['GREATER_THAN', 'LOWER_THAN'].includes(data.filteringKind))
      dispatch(addFilter({ key: data.key, filteringKind: data.filteringKind, value: data.from }));
    else if (['PRESENT_IN_VALUES', 'ABSENT_IN_VALUES'].includes(data.filteringKind))
      dispatch(addFilter({ key: data.key, filteringKind: data.filteringKind, value: data.value }));
  };

  useEffect(() => {
    setDefaultValue({
      key: filter ? filter.key : '',
      filteringKind: filter ? filter.filteringKind : '',
      value: filter ? filter.value : '',
      from: filter
        ? filter.filteringKind === 'IN_VALUE_RANGE' ||
          filter.filteringKind === 'NOT_IN_VALUE_RANGE' ||
          filter.filteringKind === 'GREATER_THAN' ||
          filter.filteringKind === 'LOWER_THAN'
          ? filter.value[0]
          : null
        : null,
      to: filter
        ? filter.filteringKind === 'IN_VALUE_RANGE' || filter.filteringKind === 'NOT_IN_VALUE_RANGE'
          ? filter.value[1]
          : null
        : '',
    } as FieldValues);
  }, [filter]);

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });

  const onSubmit = addFilter => {
    methods.handleSubmit(data => {
      handleAddFilterSubmit(addFilter, data);
    })();
  };
  return {
    methods,
    defaultValue,
    onSubmit,
  };
};

import React from 'react';

import TopUsersDataGrid from './data-grid/TopUsersDataGrid';
import { useTopUsersData } from './hooks/useTopUsersData';

export const TopUsers = () => {
  const { data } = useTopUsersData();

  return <TopUsersDataGrid data={data} />;
};

import React, { useEffect } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetAccountBundlesUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';

export const ErrorProductListDialog = () => {
  const [open, setOpen] = React.useState(false);

  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { refetch, isError } = useGetAccountBundlesUsingGetQuery(
    accountProfile.accountId ? { accountKey: accountProfile.accountId } : skipToken
  );

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isError) setOpen(true);
  }, [isError]);

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Typography>Warning</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>Error fetching products</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Close
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            refetch();
          }}
        >
          Refetch
        </Button>
      </DialogActions>
    </Dialog>
  );
};

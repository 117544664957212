import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';
import { BasicCardLayout } from '../../components/layout/card/BasicCardLayout';
import { LayoutItemProps } from '../../components/LayoutItem';
import { ifExistThen } from '../generics/ifExistThen';

export type AccordionStateProps = {
  expanded?: React.Key;
  defaultExpanded?: boolean;
  handleChange?: (key: React.Key) => (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void;
};

export function LayoutItemAccordion(props: LayoutItemProps<AccordionStateProps>): JSX.Element {
  const {
    children,
    key,
    cardOptions: { variant, card, header, actions, media, content } = {},
    accordionOptions: { accordion, summary, details, actionsProps } = {},
    expanded,
    handleChange,
    defaultExpanded,
  }: LayoutItemProps<AccordionStateProps> = props;

  const stateProps = {
    expanded: expanded !== null && expanded !== undefined ? expanded === key : defaultExpanded,
    onChange: handleChange ? handleChange(key) : undefined,
  };

  return (
    <Accordion key={key} {...stateProps} {...accordion}>
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ flexDirection: 'row-reverse', gap: 2 }} {...summary}>
        <BasicCardLayout
          variant={variant}
          header={{ sx: { padding: 0, paddingBottom: 0 }, ...header }}
          card={{ sx: { width: '100%', boxShadow: 'none' }, ...card }}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: 'transparent' }} {...details}>
        <BasicCardLayout variant={variant} card={{ sx: { width: '100%', boxShadow: 'none' }, ...card }} media={media} content={content}>
          {children}
        </BasicCardLayout>
      </AccordionDetails>
      {ifExistThen(actions, <AccordionActions {...actionsProps}>{actions}</AccordionActions>)}
    </Accordion>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { RECO_ENGINE_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IItems } from 'app/shared/model/recommendations/recommendations.model';

const itemsUrl = RECO_ENGINE_API_URL + '/items';

export const initialState = {
  itemsLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  items: null as Array<IItems>,
};

export type RecoItemsState = Readonly<typeof initialState>;

// Actions

export const getItems = createAsyncThunk(
  'reco/getItems',
  async ({ corpus }: { corpus: any }) => microserviceHttpClient(`${itemsUrl}?corpus=${corpus}`),
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const recoItemsSlice = createSlice({
  name: 'items',
  initialState: initialState as RecoItemsState,
  reducers: {
    resetItems(state) {
      state.itemsLoading = false;
      state.errorMessage = null as unknown as string;
      state.items = null as Array<IItems>;
    },
    updateItems(state, action) {
      state.items = action.payload as Array<IItems>;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getItems.pending, state => {
        state.itemsLoading = true;
        state.errorMessage = null;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.itemsLoading = false;
        state.items = action.payload.data as Array<IItems>;
      })
      .addCase(getItems.rejected, (state, action) => {
        state.itemsLoading = false;
        state.errorMessage = action.error.message;
        state.items = null as Array<IItems>;
      });
  },
});

export const { resetItems, updateItems } = recoItemsSlice.actions;

// Reducer
export default recoItemsSlice.reducer;

import { merge } from 'lodash';
import { CoreCardLayoutProps } from '../../components/layout/card/BasicCardLayout';
import { AccordionLayoutProps, LayoutItemProps } from '../../components/LayoutItem';

export function mergeToLayoutItemProps(
  accordionOptions: AccordionLayoutProps,
  cardOptions: CoreCardLayoutProps,
  childProps: LayoutItemProps,
  renderProps
): LayoutItemProps {
  return {
    ...renderProps,
    ...childProps,
    accordionOptions: merge({}, childProps?.accordionOptions, accordionOptions),
    cardOptions: merge({}, childProps?.cardOptions, cardOptions),
  };
}

import React from 'react';
import { Translate } from 'react-jhipster';
import { Navigate } from 'react-router-dom';
import { sitemap } from '../../util/url-utils';
import { AppBackdropWrapper } from './AppBackdropWrapper';
import { useRequireAuth } from '../hooks/useRequireAuth';

export const RequireAuth = () => {
  const { isAuthenticated, sessionHasBeenFetched, isAuthorized } = useRequireAuth();

  const checkAuthorities = () => {
    if (isAuthorized) {
      return <AppBackdropWrapper />;
    } else {
      return (
        <div className="insufficient-authority">
          <div className="alert alert-danger">
            <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
          </div>
        </div>
      );
    }
  };

  if (!sessionHasBeenFetched) {
    return <div />;
  } else {
    if (isAuthenticated) {
      return checkAuthorities();
    } else {
      return <Navigate replace to={`${sitemap.login}?redirect_uri=${location.href}`} state={{ from: location.pathname }} />;
    }
  }
};

import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsersCohortData } from 'app/shared/reducers/web-analytics/users-cohort';
import { useGetCohortAnalysisMatrixQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-exploration';

export const useUsersCohortData = () => {
  const { params } = useAppSelector(state => state.webAnalyticsOverview.usersCohort);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUsersCohortData(params));
  }, [params]);

  const { data = null } = useGetCohortAnalysisMatrixQuery(
    {
      requestFilters: {
        filterEntryList: [],
      },
      timeStep: params.timeStep as 'DAY' | 'MONTH' | 'WEEK' | 'YEAR',
      nbTimeSteps: params.nbTimeSteps,
    },
    {
      selectFromResult(res) {
        return {
          data: res.data?.slice().sort(function (a, b) {
            return Number(Object.keys(a.data).length) - Number(Object.keys(b.data).length);
          }),
        };
      },
    }
  );

  return {
    data,
  };
};

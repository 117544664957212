import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IInvoice } from 'app/shared/model/invoice.model';

const accountsUrl = CONFIG_MANAGER_API_URL + '/billing/accounts';
const invoicesUrl = CONFIG_MANAGER_API_URL + '/billing/invoices';

export const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  invoiceList: [] as unknown as IInvoice[],
  invoiceHtml: null as string,
};

export type InvoicesState = Readonly<typeof initialState>;

// Actions

export const getInvoices = createAsyncThunk(
  'invoices/getInvoices',
  async ({ accountId }: { accountId: any }) => microserviceHttpClient(`${accountsUrl}/${accountId}/invoices`),
  { serializeError: serializeAxiosError }
);

// Async calls (No state)

export const getInvoiceHtml = async ({ invoiceId }: { invoiceId: any }) => microserviceHttpClient(`${invoicesUrl}/${invoiceId}/html`);

// Slice

export const InvoicesSlice = createSlice({
  name: 'invoices',
  initialState: initialState as InvoicesState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInvoices.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.invoiceList = action.payload.data as IInvoice[];
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.invoiceList = [] as unknown as IInvoice[];
      });
  },
});

// Reducer
export default InvoicesSlice.reducer;

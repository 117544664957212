import React from 'react';

import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { addFlex, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import { commonToolbars, toolbarProps } from '../../data-grid/toolbar/toolbar-props';
import { Chip, Tooltip, Typography } from '@mui/material';
import { DeleteForever, Edit } from '@mui/icons-material';
import { translate } from 'react-jhipster';
import { useAppDispatch } from 'app/config/store';
import { Box } from '@mui/system';

export const FilterManagerDataGrid = ({ filters, deleteFilter, setEditFilter }) => {
  const dispatch = useAppDispatch();
  const columns: GridColDef[] = [
    {
      field: 'key',
      headerAlign: 'left',
      align: 'left',
      renderCell(params) {
        return translate(`web-analytics.request-filter.bucketingDimensions.${params.value}`, null, params.value);
      },
    },
    {
      field: 'filteringKind',
      renderCell(params) {
        return translate(`web-analytics.request-filter.filter-operators.${params.value}`, null, params.value);
      },
    },
    {
      field: 'value',
      renderCell(params) {
        if (['GREATER_THAN', 'LOWER_THAN'].includes(params.row.filteringKind)) return params.value;
        else if (['IN_VALUE_RANGE', 'NOT_IN_VALUE_RANGE'].includes(params.row.filteringKind))
          return `[${params.value[0]},${params.value[1]}]`;
        else
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {params.value.map(chip => (
                <Tooltip key={chip} title={chip}>
                  <Chip label={chip} size={'small'} color={'default'} sx={{ maxWidth: '150px' }} />
                </Tooltip>
              ))}
            </Box>
          );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key="delete"
          icon={
            <Tooltip title={translate('web-analytics.request-filter.delete', null, 'Delete Filter')}>
              <DeleteForever color="error" />
            </Tooltip>
          }
          onClick={() => {
            dispatch(deleteFilter(params.row.key));
          }}
          label={translate('web-analytics.request-filter.delete', null, 'Delete Filter')}
        />,
        <GridActionsCellItem
          key="edit"
          icon={
            <Tooltip title={translate('web-analytics.request-filter.edit', null, 'Delete Filter')}>
              <Edit color="primary" />
            </Tooltip>
          }
          onClick={() => {
            setEditFilter(params.row);
          }}
          label={translate('web-analytics.request-filter.edit', null, 'Delete Filter')}
        />,
      ],
    },
  ];
  function CustomNoRowsOverlay() {
    return (
      <Typography textAlign="center" mt={2}>
        {translate('web-analytics.request-filter.no-filter-applied', null, 'No Filter Applied')}
      </Typography>
    );
  }

  return (
    <DataGrid
      rows={filters || []}
      columns={columns.map(addFlex).map(translateHeaders('web-analytics.request-filter.filter-data-grid'))}
      {...toolbarProps(commonToolbars.readOnlyLeft)}
      hideFooter
      density="compact"
      getRowId={row => row.key}
      disableRowSelectionOnClick
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
        width: '600px',
      }}
      getRowHeight={() => 'auto'}
      getEstimatedRowHeight={() => 80}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
      }}
    />
  );
};

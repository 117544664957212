import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getMuiFormBuilderForPlanUsingGet: build.query<GetMuiFormBuilderForPlanUsingGetApiResponse, GetMuiFormBuilderForPlanUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/muiFormBuilder/${queryArg.subscriptionId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as muiFormBuilder };
export type GetMuiFormBuilderForPlanUsingGetApiResponse = /** status 200 OK */ SettingsCatalogDto;
export type GetMuiFormBuilderForPlanUsingGetApiArg = {
  /** subscriptionId */
  subscriptionId: string;
};
export type SettingsCatalogConfigurationDto = {
  defaultValue?: object;
  keyName?: string;
  options?: object[];
  required?: boolean;
  valueType?: string;
};
export type SettingsCatalogDto = {
  fields?: {
    [key: string]: SettingsCatalogConfigurationDto;
  };
  subscriptionId?: string;
};
export const { useGetMuiFormBuilderForPlanUsingGetQuery } = injectedRtkApi;

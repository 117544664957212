import Data from 'app/modules/views/greensights/model/data';
import { emptyGreensightsDataHubCloudServicesApi as api } from '../emptyGreensightsDataHubCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    load: build.query<LoadApiResponse, LoadApiArg>({
      query: queryArg => ({ url: `/api/v1/metric/load/${queryArg.accountId}`, method: 'POST', body: queryArg.metricRequest }),
      providesTags: ['USER_PROFILE'],
    }),
    compute: build.mutation<ComputeApiResponse, ComputeApiArg>({
      query: queryArg => ({ url: `/api/v1/metric/compute`, method: 'POST', body: queryArg.computeRequest }),
    }),
    getAllAccountIds: build.mutation<GetAllAccountIdsApiResponse, GetAllAccountIdsApiArg>({
      query: queryArg => ({ url: `/api/v1/gcloud/get-analytics-ids`, method: 'POST', body: queryArg.getAnalyticsIdsRequest }),
    }),
    create: build.mutation<CreateApiResponse, CreateApiArg>({
      query: queryArg => ({ url: `/api/v1/gcloud/create-service-account`, method: 'POST', body: queryArg.serviceAccountCreateRequest }),
    }),
    getList: build.query<GetListApiResponse, GetListApiArg>({
      query: () => ({ url: `/api/v1/web-page-analytics` }),
    }),
    progress: build.query<ProgressApiResponse, ProgressApiArg>({
      query: queryArg => ({ url: `/api/v1/metric/compute/progress/${queryArg.id}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as greensightsDataHubApi };
export type LoadApiResponse = /** status 200 OK */ ResponseWrapperMetricResponse;
export type LoadApiArg = {
  /** The google account id */
  accountId: string;
  metricRequest: MetricRequest;
};
export type ComputeApiResponse = /** status 200 OK */ ResponseWrapperObject;
export type ComputeApiArg = {
  computeRequest: ComputeRequest;
};
export type GetAllAccountIdsApiResponse = /** status 200 OK */ ResponseWrapperListString;
export type GetAllAccountIdsApiArg = {
  getAnalyticsIdsRequest: GetAnalyticsIdsRequest;
};
export type CreateApiResponse = /** status 200 OK */ ResponseWrapperServiceAccountCreateResponse;
export type CreateApiArg = {
  serviceAccountCreateRequest: ServiceAccountCreateRequest;
};
export type GetListApiResponse = /** status 200 OK */ WebPageAnalysis[];
export type GetListApiArg = void;
export type ProgressApiResponse = /** status 200 OK */ string;
export type ProgressApiArg = {
  id: string;
};
export type MetricResponse = {
  status?: string;
  data?: Data;
};
export type Warning = {
  code?: string;
  message?: string;
};
export type ResponseWrapperMetricResponse = {
  content?: MetricResponse;
  warnings?: Warning[];
};
export type MetricRequest = {
  name?: string;
  start?: string;
  end?: string;
  filters?: {
    [key: string]: string;
  };
  query?: string;
};
export type ResponseWrapperObject = {
  content?: object;
  warnings?: Warning[];
};
export type ComputeRequest = {
  startDate?: string;
  endDate?: string;
  doSaveMeasures?: boolean;
  doSaveMetrics?: boolean;
  doComputeDayByDay?: boolean;
  rootUrlFilters?: string[];
  accountFilters?: string[];
};
export type ResponseWrapperListString = {
  content?: string[];
  warnings?: Warning[];
};
export type GetAnalyticsIdsRequest = {
  accountId?: string;
};
export type ServiceAccountCreateResponse = {
  message?: string;
  email?: string;
};
export type ResponseWrapperServiceAccountCreateResponse = {
  content?: ServiceAccountCreateResponse;
  warnings?: Warning[];
};
export type ServiceAccountCreateRequest = {
  accountId?: string;
  displayName?: string;
  description?: string;
};
export type WebPageAnalysis = {
  url?: string;
  viewId?: string;
  pageSizeInBytes?: number;
  pageNodes?: number;
  pageType?: string;
  numRequests?: number;
  ecoIndexGrade?: string;
  ecoIndexScore?: number;
  ecoIndexGES?: number;
  ecoIndexWater?: number;
  downloadDuration?: number;
  computationDate?: string;
  grade?: string;
};
export const { useLazyLoadQuery, useComputeMutation, useGetAllAccountIdsMutation, useCreateMutation, useGetListQuery, useProgressQuery } =
  injectedRtkApi;

import { combineReducers } from 'redux';
import dataExploration from './data-exploration';
import userExploration from './user-exploration';
import recoItems from './recommendations/items';
import visitorProfile from './visitor-profile';
import recoWidget from './recommendations/widget';

const views = combineReducers({ dataExploration, userExploration, recoItems, visitorProfile, recoWidget });

export default views;

import React from 'react';

import { Typography, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, List } from '@mui/material';
import { translate } from 'react-jhipster';
import { TabCardWithAction } from './TabCardWithAction';
import { BoxProps } from '@mui/system';

export const CustomListTabWithAction = ({
  list,
  actions,
  sxContainer = {},
}: {
  list: {
    icon: any;
    key: string;
    value: string | JSX.Element;
  }[];
  actions: JSX.Element;
  sxContainer?: BoxProps;
}): JSX.Element => {
  return (
    <TabCardWithAction actions={actions} sxContainer={sxContainer}>
      <List>
        {list.map(listItem => {
          const Icon = listItem.icon;
          return (
            <ListItem key={listItem.key}>
              {listItem.icon && (
                <ListItemIcon>
                  <Icon fontSize="small" />
                </ListItemIcon>
              )}
              <ListItemText primary={translate(listItem.key)} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" color="textSecondary">
                  {listItem.value}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </TabCardWithAction>
  );
};

import React from 'react';

import { Add, DeleteForever } from '@mui/icons-material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Button, Collapse } from '@mui/material';
import { AddRequestFilterForm } from './AddRequestFilterForm';
import { FilterManagerDataGrid } from './FilterManagerDataGrid';
import { useAppDispatch } from 'app/config/store';
import { translate } from 'react-jhipster';

export const RequestFilterManagerPopover = ({
  filters,
  filterValues,
  openFilterForm,
  setOpenFilterForm,
  editFilter,
  setEditFilter,
  filterActions,
}) => {
  const dispatch = useAppDispatch();
  return (
    <BasicCardLayout
      variant="div"
      actions={
        !openFilterForm || filters.length > 0 ? (
          <>
            {!openFilterForm && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<Add />}
                onClick={e => {
                  setOpenFilterForm(true);
                }}
                disableTouchRipple
              >
                {translate('web-analytics.request-filter.add-filter', null, 'Add Filter')}
              </Button>
            )}
            {filters.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => {
                  dispatch(filterActions.deleteFilters());
                  setEditFilter(null);
                }}
                startIcon={<DeleteForever />}
              >
                {translate('web-analytics.request-filter.delete-all', null, 'Delete All')}
              </Button>
            )}
          </>
        ) : null
      }
      // card={{ sx: { minWidth: '700px' } }}
      content={{ sx: { p: 2, '&:last-child': { p: 2 } } }}
      actionsProps={{
        sx: {
          p: 2,
          pt: 0,
        },
      }}
    >
      {filters.length > 0 && (
        <FilterManagerDataGrid filters={filters} deleteFilter={filterActions.deleteFilter} setEditFilter={setEditFilter} />
      )}
      <Collapse in={openFilterForm} timeout="auto" unmountOnExit>
        <AddRequestFilterForm
          filter={editFilter}
          filterValues={filterValues}
          handleClose={() => {
            setOpenFilterForm(false);
          }}
          setEditFilter={setEditFilter}
          filterActions={filterActions}
        />
      </Collapse>
    </BasicCardLayout>
  );
};

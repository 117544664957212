import React, { useState } from 'react';

import { Dialog, DialogTitle, DialogContent, ToggleButtonGroup, ToggleButton, DialogActions, Typography, Button } from '@mui/material';
import { FieldProp, FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import { Translate, translate } from 'react-jhipster';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import {
  useUploadItemsFromCsvMutation,
  useUploadItemsFromJsonMutation,
} from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { useForm } from 'react-hook-form';
import { FileUpload, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const getFields = (fileType, extraOptions = false): Array<FieldProp> => {
  return [
    {
      component: 'file-upload',
      attribute: 'inventories',
      label: translate('recommendation.browse', null, 'Browse'),
      validations: [['required', '']],
      acceptTypes: fileType === 'json' ? ['.json', '.jsonl'] : fileType === 'csv' ? ['.csv'] : ['.json', '.jsonl', '.csv'],
      maxSizeMb: 100,
      fileType: 'file',
    },
    {
      component: 'text-field',
      attribute: 'delimiter',
      label: translate('recommendation.csv.delimiter', null, 'Delimiter'),
      hideCondition: fileType !== 'csv' || !extraOptions,
    },
    {
      component: 'text-field',
      attribute: 'idField',
      label: translate('recommendation.csv.id', null, 'Id'),
      hideCondition: fileType !== 'csv' || !extraOptions,
    },
    {
      component: 'text-field',
      attribute: 'title',
      label: translate('recommendation.csv.title', null, 'Title'),
      hideCondition: fileType !== 'csv' || !extraOptions,
    },
    {
      component: 'text-field',
      attribute: 'description',
      label: translate('recommendation.csv.description', null, 'Description'),
      hideCondition: fileType !== 'csv' || !extraOptions,
    },
  ];
};

export const UploadItemsPopover = ({ fileType, handleCloseFilterPopover }) => {
  const { accountProfile } = useAppAccountStates();
  const [uploadJson, { isLoading: isJsonLoading }] = useUploadItemsFromJsonMutation();
  const [uploadCsv, { isLoading: isCsvLoading }] = useUploadItemsFromCsvMutation();

  const [open, setOpen] = useState(false);

  const schema = validationUtils.getFormSchema(getFields(fileType));
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const handleExtraOptionsCollapse = () => {
    setOpen(prev => !prev);
  };

  const submitButton = (
    <LoadingButton
      disabled={!methods.formState.isDirty}
      startIcon={<FileUpload />}
      variant="contained"
      color="primary"
      size="small"
      loading={isJsonLoading || isCsvLoading}
      loadingPosition="start"
      sx={{ marginBottom: 1.5, marginTop: 1, marginX: 2 }}
      onClick={() => {
        methods.handleSubmit(
          (data: { inventories: any; delimiter?: string; idField?: string; titleField?: string; descriptionField?: string }) => {
            data?.inventories.files.forEach(file => {
              const formData = new FormData();
              formData.append('file', file);
              if (fileType === 'json') uploadJson({ accountId: accountProfile?.accountId.substring(0, 8), body: formData });
              else if (fileType === 'csv')
                uploadCsv({
                  accountId: accountProfile?.accountId.substring(0, 8),
                  body: formData,
                  delimiter: data?.delimiter,
                  idField: data?.idField,
                  descriptionField: data?.descriptionField,
                });
            });
            handleCloseFilterPopover();
          }
        )();
      }}
    >
      <Translate contentKey="recommendation.upload">Upload</Translate>
    </LoadingButton>
  );

  const cancelButton = (
    <Button color="error" size="small" onClick={() => handleCloseFilterPopover()}>
      <Translate contentKey="recommendation.cancel">Cancel</Translate>
    </Button>
  );

  const extraOptionsButton = (
    <Button
      startIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      color="primary"
      size="small"
      onClick={() => handleExtraOptionsCollapse()}
    >
      <Translate contentKey="recommendation.options">Options</Translate>
    </Button>
  );

  return (
    <>
      <DialogTitle typography="h5">
        <Translate contentKey={`recommendation.items.upload-${fileType}-items`}>Upload {fileType} items</Translate>
      </DialogTitle>
      <DialogContent>
        <FormBuilder
          fields={getFields(fileType, open)}
          defaultValue={{}}
          methods={methods}
          optionalMarkerDefault={true}
          containerProps={{ marginY: 0.5, paddingX: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ paddingX: 3 }}>
        {extraOptionsButton}
        {cancelButton}
        {submitButton}
      </DialogActions>
    </>
  );
};

import { combineReducers } from 'redux';
import accountProfileReducer from './account-profile';
import members from './members/index';
import admins from './roles/admins';
import contributors from './roles/contributors';
import guests from './roles/guests';
import accessToken from './access-token';

const accountProfile = combineReducers({
  accountProfileReducer,
  members,
  admins,
  contributors,
  guests,
  accessToken,
});

export default accountProfile;

import { ArrowDownward } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Menu, Popper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box } from '@mui/system';
import { useAppSelector } from 'app/config/store';
import React, { useMemo } from 'react';
import { translate } from 'react-jhipster';
import { parseEsDateString, timeStepList } from '../filter-wrapper/useTimeRangeFilter';
import Transitions from '../transition';
import { TimePickerPopper } from './TimePickerPopper';

interface TimePickerProps {
  isLive: any;
  step: any;
  fromDate: string;
  setFromDate: any;
  toDate: string;
  setToDate: any;
  setStep: any;
  disabled?: any;
  chosenRange: any;
  setChosenRange: any;
  stepOptions?: any;
  quickRanges?: any[];
}

export const TimePicker = ({
  isLive,
  step,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setStep,
  disabled = false,
  chosenRange,
  setChosenRange,
  stepOptions = timeStepList,
  quickRanges,
}: TimePickerProps) => {
  const [anchorPopper, setAnchorPopper] = React.useState(null);
  const [anchorStepList, setAnchorStepList] = React.useState(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openStepList, setOpenStepList] = React.useState(false);
  const [from, setFrom] = React.useState(parseEsDateString(fromDate));
  const [to, setTo] = React.useState(parseEsDateString(toDate));
  const { currentLocale }: any = useAppSelector(state => state.locale);
  const start = isLive ? parseEsDateString(fromDate) : from;
  const end = isLive ? parseEsDateString(toDate) : to;

  const theme = useTheme();
  const hasStepOptions = stepOptions && stepOptions.length > 0;

  const dateString = useMemo(() => {
    const timeRange = start && start.locale(currentLocale).from(end, true);
    const timeRangeLabel = start && start.locale(currentLocale).format('ll') + ' ➜ ' + end.locale(currentLocale).format('ll');
    if (chosenRange && Array.isArray(chosenRange)) {
      return timeRangeLabel;
    } else {
      return chosenRange ? translate(chosenRange, { timeRange }, timeRange) : timeRange;
    }
  }, [currentLocale, fromDate, toDate]);

  const dateStringTooltip = useMemo(() => {
    if (start && end) {
      return (
        <Stack alignItems="center" p={1} spacing={0}>
          <Typography>{start.locale(currentLocale).format('llll')}</Typography>
          <ArrowDownward fontSize="small" />
          <Typography>{end.locale(currentLocale).format('llll')}</Typography>
        </Stack>
      );
    } else {
      return 'select time range';
    }
  }, [currentLocale, fromDate, toDate]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpenPopper(false);
        setOpenStepList(false);
      }}
    >
      <Box display="flex" alignSelf="stretch">
        <Tooltip title={dateStringTooltip}>
          <span>
            <Button
              disableRipple
              variant="outlined"
              disabled={isLive || disabled}
              onClick={event => {
                setAnchorPopper(event.currentTarget);
                setOpenPopper(!openPopper);
              }}
              size="small"
              sx={{
                ...(hasStepOptions && {
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                }),
              }}
            >
              <DateRangeIcon fontSize="small" sx={{ mr: 1 }} />
              {dateString}
              {/* {isLive ? translate('web-analytics.DatePicker.Last1Hour') : dateString} */}
            </Button>
          </span>
        </Tooltip>
        {hasStepOptions && (
          <Tooltip title={translate('web-analytics.select-step', null, 'Select Step')} PopperProps={{ sx: { zIndex: 0 } }}>
            <Box display="flex">
              <Button
                disableRipple
                sx={{
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                  '& .MuiOutlinedInput-root': {
                    background: 'none !important',
                  },
                }}
                disabled={isLive || disabled}
                variant="outlined"
                onClick={event => {
                  setOpenStepList(!openStepList);
                  if (!openStepList) setAnchorStepList(event.currentTarget);
                  else setAnchorStepList(null);
                }}
                color="primary"
                size="small"
              >
                <Select
                  disabled={isLive || disabled}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '& .MuiOutlinedInput-input': {
                      p: 0,
                      minWidth: 5,
                    },
                    '& .MuiSelect-select': {
                      pr: 2,
                      color: theme.palette.primary.main,
                      fontSize: '0.825rem',
                      fontWeight: '500',
                    },
                    '& .MuiList-root .MuiMenu-list': {
                      p: `0 !important`,
                    },
                  }}
                  open={openStepList}
                  MenuProps={{
                    anchorEl: anchorStepList,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  }}
                  onChange={(event: SelectChangeEvent) => {
                    setStep(event.target.value);
                  }}
                  size="small"
                  label="Step"
                  value={step}
                  color="primary"
                >
                  {stepOptions.map(option => {
                    return (
                      <MenuItem key={option.value} value={option.value}>
                        {translate(`web-analytics.DatePicker.${option.name}`, null, option.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Button>
            </Box>
          </Tooltip>
        )}

        <Popper open={openPopper} placement="bottom" anchorEl={anchorPopper} transition>
          {({ TransitionProps }) => (
            <Transitions in={openPopper} {...TransitionProps} position="top" timeout={200}>
              <TimePickerPopper
                fromDate={from}
                toDate={to}
                step={step}
                setFrom={setFrom}
                setTo={setTo}
                setChosenRange={setChosenRange}
                setFromDate={setFromDate}
                setToDate={setToDate}
                setStep={setStep}
                quickRanges={quickRanges}
                closePopper={() => setOpenPopper(false)}
              />
            </Transitions>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

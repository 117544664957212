export const lastWeek = {
  fromDate: 'now-1w/d',
  toDate: 'now',
};

export const lastMonth = {
  fromDate: 'now-1M/d',
  toDate: 'now',
};

export const last6Month = {
  fromDate: 'now-6M/d',
  toDate: 'now',
};

import { Dashboard, ViewCarousel, ViewList, ViewQuilt, Window, Image, Title, TextFields } from '@mui/icons-material';
import { PiTextHOne, PiTextHTwo, PiTextHThree, PiTextHFour, PiTextHFive, PiTextHSix, PiTextT, PiImage } from 'react-icons/pi';
import { BsTextParagraph } from 'react-icons/bs';
import { Tooltip } from '@mui/material';
import { TabItemProps } from 'app/components/tabulation';
import { FieldProp } from 'app/modules/form-builder-lib';
import React from 'react';

export const gridParameters: FieldProp[] = [
  {
    component: 'text-field',
    attribute: 'display',
    label: 'Display',
  },
  {
    component: 'text-field',
    attribute: 'grid-template-columns',
    label: 'Grid Template Columns',
  },
  {
    component: 'text-field',
    attribute: 'grid-template-rows',
    label: 'Grid Template Rows',
  },
  {
    component: 'text-field',
    attribute: 'grid-gap',
    label: 'Grid Gap',
  },
  {
    component: 'text-field',
    attribute: 'grid-auto-columns',
    label: 'Grid Auto Columns',
  },
  {
    component: 'text-field',
    attribute: 'grid-auto-rows',
    label: 'Grid Auto Rows',
  },
  {
    component: 'text-field',
    attribute: 'grid-auto-flow',
    label: 'Grid Auto Flow',
  },
  {
    component: 'text-field',
    attribute: 'grid-column',
    label: 'Grid Column',
  },
  {
    component: 'text-field',
    attribute: 'grid-row',
    label: 'Grid Row',
  },
  {
    component: 'text-field',
    attribute: 'justify-items',
    label: 'Justify Items',
  },
  {
    component: 'text-field',
    attribute: 'align-items',
    label: 'Align Items',
  },
];

export const listParameters: FieldProp[] = [
  {
    component: 'text-field',
    attribute: 'display',
    label: 'Display',
  },
  {
    component: 'text-field',
    attribute: 'grid-template-columns',
    label: 'Grid Template Columns',
  },
  {
    component: 'text-field',
    attribute: 'grid-gap',
    label: 'Grid Gap',
  },
];

export const cardParameters: FieldProp[] = [
  {
    component: 'text-field',
    attribute: 'display',
    label: 'Display',
  },
  {
    component: 'text-field',
    attribute: 'flex-direction',
    label: 'Flex Direction',
  },
  {
    component: 'text-field',
    attribute: 'flex-wrap',
    label: 'Flex Wrap',
  },
  {
    component: 'text-field',
    attribute: 'gap',
    label: 'Gap',
  },
  {
    component: 'text-field',
    attribute: 'justify-content',
    label: 'Justify Content',
  },
  {
    component: 'text-field',
    attribute: 'align-items',
    label: 'Align Items',
  },
];

export const cardItemParameters: FieldProp[] = [
  {
    attribute: 'width',
    component: 'text-field',
    label: 'Width',
  },
];

export const masonryParameters: FieldProp[] = [
  {
    component: 'text-field',
    attribute: 'column-count',
    label: 'Column Count',
  },
  {
    component: 'text-field',
    attribute: 'column-gap',
    label: 'Column Gap',
  },
];

export const masonryItemParameters: FieldProp[] = [
  {
    component: 'text-field',
    attribute: 'break-inside',
    label: 'Break Inside',
  },
  {
    attribute: 'margin',
    component: 'text-field',
    label: 'Margin',
  },
];

export const carouselParameters: FieldProp[] = [
  {
    component: 'text-field',
    attribute: 'overflow',
    label: 'Overflow',
  },
  {
    component: 'text-field',
    attribute: 'white-space',
    label: 'White Space',
  },
];

export const carouselItemParameters: FieldProp[] = [
  {
    component: 'text-field',
    attribute: 'display',
    label: 'Display',
  },
  {
    attribute: 'width',
    component: 'text-field',
    label: 'Width',
  },
];

export const layoutIcons = {
  grid: <Window />,
  list: <ViewList />,
  flex: <ViewQuilt />,
  masonry: <Dashboard />,
  // carousel: <ViewCarousel />,
};

export const tagIcons = {
  img: <PiImage />,
  h1: <PiTextHOne />,
  h2: <PiTextHTwo />,
  h3: <PiTextHThree />,
  h4: <PiTextHFour />,
  h5: <PiTextHFive />,
  h6: <PiTextHSix />,
  p: <BsTextParagraph />,
  span: <PiTextT />,
};

type LayoutIconsType = typeof layoutIcons;

export type LayoutTypes = keyof LayoutIconsType;

export type CssLayoutTabsType = Partial<TabItemProps & { containerFields?: FieldProp[]; itemFields?: FieldProp[] }>[];

export const cssLayoutTabs: CssLayoutTabsType = [
  {
    tab: { value: 'grid', icon: <Tooltip title="Grid">{layoutIcons['grid']}</Tooltip> },
    containerFields: gridParameters,
  },
  {
    tab: { value: 'list', icon: <Tooltip title="List">{layoutIcons['list']}</Tooltip> },
    containerFields: listParameters,
  },
  {
    tab: { value: 'flex', icon: <Tooltip title="Flexbox">{layoutIcons['flex']}</Tooltip> },
    containerFields: cardParameters,
    itemFields: cardItemParameters,
  },
  {
    tab: { value: 'masonry', icon: <Tooltip title="Masonry">{layoutIcons['masonry']}</Tooltip> },
    containerFields: masonryParameters,
    itemFields: masonryItemParameters,
  },
  // {
  //   tab: { value: 'carousel', icon: <Tooltip title="Carousel">{layoutIcons['carousel']}</Tooltip> },
  //   containerFields: carouselParameters,
  //   itemFields: carouselItemParameters,
  // },
];

export const cssLayoutTypes: LayoutTypes[] = cssLayoutTabs.map(el => el.tab.value);

import React, { useState } from 'react';

import FiltersWrapper from 'app/components/filter-wrapper';
import { refreshRateList } from 'app/components/refresh-rate/timeRange';
import { useTimeRange } from 'app/components/filter-wrapper/useTimeRangeFilter';
import { useUserExplorationTimerange } from '../../hooks/useDataExplorationTimerange';

export const UserExplorationTimeRangeFilters = () => {
  const timerange = useTimeRange({ chosenRange: 'web-analytics.DatePicker.SincePeriod', fromDate: 'now-1M' });
  useUserExplorationTimerange(timerange);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FiltersWrapper
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isLive={timerange.isLive()}
      liveClickHandler={timerange.liveHandler}
      refreshRateList={refreshRateList}
      refresh={timerange.refresh}
      refreshRate={timerange.refreshRate}
      setRefreshRate={timerange.setRefreshRate}
      setRefresh={timerange.setRefresh}
      step={timerange.step}
      fromDate={timerange.fromDate}
      setFromDate={timerange.setFromDate}
      toDate={timerange.toDate}
      setToDate={timerange.setToDate}
      setStep={timerange.setStep}
      chosenRange={timerange.chosenRange}
      setChosenRange={timerange.setChosenRange}
    />
  );
};

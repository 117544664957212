import ThemeBuilder from 'app/shared/themes/builders/themeBuilder';

class MuiDefault extends ThemeBuilder {
  palette() {
    return { mode: this.customization?.palette?.mode };
  }

  mixins() {
    return {};
  }

  typography() {
    return {};
  }

  components(theme) {
    return {};
  }
}

export default MuiDefault;

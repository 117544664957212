import React from 'react';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { paymentMethodApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/payment-method';
import PaymentMethodList from '../../../payment-methods/components/PaymentMethodList';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { translate } from 'react-jhipster';

export const PaymentMethod = ({ payment }) => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { data: paymentMethods } = paymentMethodApi.endpoints.getPaymentMethodsUsingGet.useQueryState({
    accountKey: accountProfile.accountId,
    withPluginInfo: true,
  });
  return (
    <BasicCardLayout variant="div" header={{ title: translate('account.payment-method', null, 'Payment Method') }}>
      <PaymentMethodList
        paymentMethodsList={paymentMethods.filter(paymentMethod => paymentMethod.paymentMethodId === payment.paymentMethodId)}
      />
    </BasicCardLayout>
  );
};

import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { translate } from 'react-jhipster';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { LoadingStackSkeleton } from 'app/modules/loading-lib/component/LoadingStackSkeleton';

interface CohortChartProps {
  xAxisData: string[];
  yAxisData: string[];
  values: number[][];
  maxValues: number;
}

const CohortChart = ({ xAxisData, yAxisData, values, maxValues }: CohortChartProps) => {
  const theme = useTheme();
  const option: EChartsOption = {
    xAxis: {
      show: true,
      type: 'category',
      position: 'top',
      data: xAxisData,
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: 'black',
        fontSize: 12,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      show: true,
      type: 'category',
      data: yAxisData,
      axisLabel: {
        color: 'black',
        fontSize: 12,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    tooltip: {
      show: true,
      formatter(params) {
        return `${translate('user-exploration.cohort.count')}: ${params.value[3]}`;
      },
      textStyle: {
        fontWeight: 'bold',
      },
    },
    visualMap: [
      {
        dimension: 2,
        min: 0,
        max: maxValues,
        inRange: {
          color: [theme.palette.primary.light, theme.palette.primary.dark],
        },
        show: false,
      },
    ],
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '0%',
      containLabel: true,
    },
    series: [
      {
        name: 'cohort',
        type: 'heatmap',
        data: values,
        label: {
          show: true,
          fontSize: 14,
          fontWeight: 'bold',
          formatter(param) {
            return `${param.value[2]}`;
          },
        },
        itemStyle: {
          borderColor: 'white',
          borderWidth: 1,
        },
      },
    ],
  };

  return <ReactECharts option={option} style={{ width: '100%', padding: '5px' }} />;
};

export default WithLoading(CohortChart, state => state.webAnalyticsOverview.usersCohort.loading, {
  loader() {
    return <LoadingStackSkeleton />;
  },
});

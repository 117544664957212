/* JoeHtmlAlchemy integration */

window.JoeHtmlAlchemy =
  window.JoeHtmlAlchemy ??
  function (...args) {
    (window.JoeHtmlAlchemy.q = window.JoeHtmlAlchemy.q ?? []).push(...args);
  };

const useJoeHtmlAlchemy = () => {
  return window.JoeHtmlAlchemy;
};

export default useJoeHtmlAlchemy;

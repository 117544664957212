import { useEffect } from 'react';
import { useGetMatrixCellUsersQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-exploration';
import { useAppSelector } from 'app/config/store';
import {
  selectAllUserExplorationFilters,
  getUserExplorationSelectedCohortCell,
  getUserExplorationCohortState,
  getUserExplorationTimerange,
} from 'app/shared/reducers/views/user-exploration';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const useUsersOverview = () => {
  const filter = useAppSelector(selectAllUserExplorationFilters);
  const timerange = useAppSelector(getUserExplorationTimerange);
  const selectedCohortCell = useAppSelector(getUserExplorationSelectedCohortCell);
  const cohortState = useAppSelector(getUserExplorationCohortState);
  const {
    currentData: user_overview = null,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetMatrixCellUsersQuery(
    timerange
      ? {
          requestFilters: {
            filterEntryList: filter,
          },
          timeStep: cohortState.timeStep,
          nbTimeStepsFirstVisit: selectedCohortCell[1],
          nbTimeStepsMatrixCell: selectedCohortCell[0],
        }
      : skipToken
  );

  useEffect(() => {
    refetch();
  }, [timerange.refresh]);

  return { user_overview, isFetching, isError, error };
};

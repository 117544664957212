import React from 'react';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { addFlex, chipString, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import { sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import { commonToolbars, toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import { useCancelSubscriptionUsingDeleteMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/subscription';
import { translate } from 'react-jhipster';

export const SubscriptionDataGrid = ({ subscriptionsList }) => {
  const [deleteSubscription] = useCancelSubscriptionUsingDeleteMutation();

  const columns: GridColDef[] = [
    { field: 'productName', headerAlign: 'left', type: 'string', align: 'left' },
    {
      field: 'productCategory',
      ...chipString(params => {
        return { variant: 'outlined', size: 'small', color: 'default' };
      }),
      type: 'string',
    },
    { field: 'startDate', type: 'date', valueGetter: ({ value }) => value && new Date(value) },
    {
      field: 'state',
      ...chipString(params => {
        return { variant: 'outlined', size: 'small', color: params.value === 'ACTIVE' ? 'success' : 'error' };
      }),
    },
    { field: 'billingStartDate', type: 'date', valueGetter: ({ value }) => value && new Date(value) },
    {
      field: 'billingPeriod',
      type: 'string',
      renderCell(params: GridRenderCellParams) {
        return (
          <Typography variant="body1" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {translate(`account.subscription-table.${params.value}`)}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          disabled={params.row.state === 'CANCELLED'}
          key="cancel"
          icon={<HighlightOffIcon color="error" />}
          onClick={() => {
            deleteSubscription({ subscriptionId: String(params.id) });
          }}
          label={translate('account.cancel')}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={subscriptionsList || []}
        columns={columns.map(addFlex).map(translateHeaders('account.subscription-table'))}
        {...toolbarProps(commonToolbars.readOnlyLeft)}
        {...sizedPagination()}
        density="compact"
        getRowId={row => row.subscriptionId}
        sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
      />
    </div>
  );
};

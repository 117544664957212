import { emptyDataHubCloudServicesApi as api } from '../emptyDataHubCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getAvailableAttributesUsingGet: build.query<GetAvailableAttributesUsingGetApiResponse, GetAvailableAttributesUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/exploration/data/availableAttributes`, params: { showAll: queryArg.showAll } }),
    }),
    getAvailableAggregationsUsingGet: build.query<GetAvailableAggregationsUsingGetApiResponse, GetAvailableAggregationsUsingGetApiArg>({
      query: () => ({ url: `/api/v1/exploration/data/availableKpis` }),
    }),
    getKpisHistoryInTimeRangeUsingPost: build.query<
      GetKpisHistoryInTimeRangeUsingPostApiResponse,
      GetKpisHistoryInTimeRangeUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/exploration/data/kpisHistory`,
        method: 'POST',
        body: queryArg.requestFilters,
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
          timeStep: queryArg.timeStep,
          from: queryArg['from'],
          to: queryArg.to,
          kpis: queryArg.kpis,
        },
      }),
      extraOptions: { maxRetries: 3 },
      providesTags: ['USER_PROFILE'],
    }),
    getKpisPerKpiInTimeRangeUsingPost: build.query<GetKpisPerKpiInTimeRangeUsingPostApiResponse, GetKpisPerKpiInTimeRangeUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/exploration/data/kpisPerBucket`,
        method: 'POST',
        body: queryArg.requestFilters,
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
          from: queryArg['from'],
          to: queryArg.to,
          bucketingDimensions: queryArg.bucketingDimensions,
          kpis: queryArg.kpis,
        },
      }),
      extraOptions: { maxRetries: 3 },
      providesTags: ['USER_PROFILE'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as dataExplorationApi };
export type GetAvailableAttributesUsingGetApiResponse = /** status 200 OK */ string[];
export type GetAvailableAttributesUsingGetApiArg = {
  /** showAll */
  showAll?: boolean;
};
export type GetAvailableAggregationsUsingGetApiResponse = /** status 200 OK */ EsKpi[];
export type GetAvailableAggregationsUsingGetApiArg = void;
export type GetKpisHistoryInTimeRangeUsingPostApiResponse = /** status 200 OK */ MultiAggsSerieDto | /** status 201 Created */ undefined;
export type GetKpisHistoryInTimeRangeUsingPostApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** timeStep */
  timeStep?: string;
  /** from */
  from?: string;
  /** to */
  to?: string;
  /** kpis */
  kpis: string;
  requestFilters: RequestFilters;
};
export type GetKpisPerKpiInTimeRangeUsingPostApiResponse = /** status 200 OK */ MultiAggsSerieDto | /** status 201 Created */ undefined;
export type GetKpisPerKpiInTimeRangeUsingPostApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** from */
  from?: string;
  /** to */
  to?: string;
  /** bucketingDimensions */
  bucketingDimensions:
    | 'ACTIVE_SESSION'
    | 'CITY_ID'
    | 'CITY_NAME'
    | 'CONTINENT_CODE'
    | 'CONTINENT_ID'
    | 'CONTINENT_NAME'
    | 'COUNTRY_CODE'
    | 'COUNTRY_ID'
    | 'COUNTRY_NAME'
    | 'EVENT_COUNTER'
    | 'FIRST_EVENT_EPOCH_SECONDS'
    | 'FIRST_USER_VISIT_EPOCH_SECONDS'
    | 'FIRST_VISITED_PAGE'
    | 'INTRANET_IP'
    | 'INTRANET_TAGGING'
    | 'IS_FIRST_SESSION_OF_USER'
    | 'LAST_EVENT_EPOCH_SECONDS'
    | 'LAST_VISITED_PAGE'
    | 'METRO_CODE'
    | 'MOST_SPECIFIC_SUBDIVISION_CODE'
    | 'MOST_SPECIFIC_SUBDIVISION_ID'
    | 'MOST_SPECIFIC_SUBDIVISION_NAME'
    | 'PAGEVIEWS_COUNTER'
    | 'POSTAL_CODE'
    | 'REFERER'
    | 'REFERER_HOSTNAME'
    | 'REMOTE_HOST'
    | 'REMOTE_HOST_FQDN'
    | 'SESSION_ID'
    | 'SOURCE_OF_TRAFFIC_CAMPAIGN'
    | 'SOURCE_OF_TRAFFIC_CONTENT'
    | 'SOURCE_OF_TRAFFIC_KEYWORD'
    | 'SOURCE_OF_TRAFFIC_MEDIUM'
    | 'SOURCE_OF_TRAFFIC_ORGANIC_SEARCH'
    | 'SOURCE_OF_TRAFFIC_REFERRAL_PATH'
    | 'SOURCE_OF_TRAFFIC_SOURCE'
    | 'SUBDIVISION_CODES'
    | 'SUBDIVISION_IDS'
    | 'SUBDIVISION_NAMES'
    | 'TIMEZONE'
    | 'USER_AGENT_DEVICE_CATEGORY'
    | 'USER_AGENT_FAMILY'
    | 'USER_AGENT_NAME'
    | 'USER_AGENT_OS_FAMILY'
    | 'USER_AGENT_OS_VENDOR'
    | 'USER_AGENT_OS_VERSION'
    | 'USER_AGENT_TYPE'
    | 'USER_AGENT_VENDOR'
    | 'USER_AGENT_VERSION'
    | 'USER_ID'
    | 'UTM_CAMPAIGN'
    | 'UTM_CONTENT'
    | 'UTM_MEDIUM'
    | 'UTM_SOURCE'
    | 'UTM_TERM';
  /** kpis */
  kpis: string;
  requestFilters: RequestFilters;
};
export type PipelineAggregationBuilder = {
  bucketsPaths?: string[];
  fragment?: boolean;
  name?: string;
  type?: string;
  writeableName?: string;
};
export type AggregationBuilder = {
  fragment?: boolean;
  metadata?: object;
  name?: string;
  outputFieldNames?: string[];
  pipelineAggregations?: PipelineAggregationBuilder[];
  subAggregations?: AggregationBuilder[];
  type?: string;
  writeableName?: string;
};
export type AbstractAggregationBuilderOfobject = {
  fragment?: boolean;
  metadata?: object;
  name?: string;
  outputFieldNames?: string[];
  pipelineAggregations?: PipelineAggregationBuilder[];
  subAggregations?: AggregationBuilder[];
  type?: string;
  writeableName?: string;
};
export type EsKpi = {
  aggregationBuilders?: AbstractAggregationBuilderOfobject[];
  pipelineAggregation?: boolean;
  pipelineAggregationBuilder?: PipelineAggregationBuilder;
  serieKey?:
    | 'AGENT'
    | 'COUNT'
    | 'COUNTRY'
    | 'DATE'
    | 'DAYOFWEEK'
    | 'DURATION'
    | 'HOUR'
    | 'ID'
    | 'NAME'
    | 'NAMES'
    | 'PAGE'
    | 'RATE'
    | 'SOURCE_OF_TRAFFIC';
};
export type AggregationResult = object;

export type MultiHistoryAggsSerieItemOfobject = {
  buckets?: {
    [key: string]: AggregationResult;
  };
  docCount?: number;
  date: string;
};
export type MultiAggsSerieItemOfobject = {
  buckets?: {
    [key: string]: AggregationResult;
  };
  docCount?: number;
};
export type MultiAggsSerieDto = {
  bucketingDimensions?: string[];
  data?: {
    date: MultiHistoryAggsSerieItemOfobject[];
  };
  kpis?: string[];
};
export type RequestFilterEntry = {
  filteringKind?: 'ABSENT_IN_VALUES' | 'GREATER_THAN' | 'IN_VALUE_RANGE' | 'LOWER_THAN' | 'NOT_IN_VALUE_RANGE' | 'PRESENT_IN_VALUES';
  key?:
    | 'ACTIVE_SESSION'
    | 'CITY_ID'
    | 'CITY_NAME'
    | 'CONTINENT_CODE'
    | 'CONTINENT_ID'
    | 'CONTINENT_NAME'
    | 'COUNTRY_CODE'
    | 'COUNTRY_ID'
    | 'COUNTRY_NAME'
    | 'EVENT_COUNTER'
    | 'FIRST_EVENT_EPOCH_SECONDS'
    | 'FIRST_USER_VISIT_EPOCH_SECONDS'
    | 'FIRST_VISITED_PAGE'
    | 'INTRANET_IP'
    | 'INTRANET_TAGGING'
    | 'IS_FIRST_SESSION_OF_USER'
    | 'LAST_EVENT_EPOCH_SECONDS'
    | 'LAST_VISITED_PAGE'
    | 'METRO_CODE'
    | 'MOST_SPECIFIC_SUBDIVISION_CODE'
    | 'MOST_SPECIFIC_SUBDIVISION_ID'
    | 'MOST_SPECIFIC_SUBDIVISION_NAME'
    | 'PAGEVIEWS_COUNTER'
    | 'POSTAL_CODE'
    | 'REFERER'
    | 'REFERER_HOSTNAME'
    | 'REMOTE_HOST'
    | 'REMOTE_HOST_FQDN'
    | 'SESSION_ID'
    | 'SOURCE_OF_TRAFFIC_CAMPAIGN'
    | 'SOURCE_OF_TRAFFIC_CONTENT'
    | 'SOURCE_OF_TRAFFIC_KEYWORD'
    | 'SOURCE_OF_TRAFFIC_MEDIUM'
    | 'SOURCE_OF_TRAFFIC_ORGANIC_SEARCH'
    | 'SOURCE_OF_TRAFFIC_REFERRAL_PATH'
    | 'SOURCE_OF_TRAFFIC_SOURCE'
    | 'SUBDIVISION_CODES'
    | 'SUBDIVISION_IDS'
    | 'SUBDIVISION_NAMES'
    | 'TIMEZONE'
    | 'USER_AGENT_DEVICE_CATEGORY'
    | 'USER_AGENT_FAMILY'
    | 'USER_AGENT_NAME'
    | 'USER_AGENT_OS_FAMILY'
    | 'USER_AGENT_OS_VENDOR'
    | 'USER_AGENT_OS_VERSION'
    | 'USER_AGENT_TYPE'
    | 'USER_AGENT_VENDOR'
    | 'USER_AGENT_VERSION'
    | 'USER_ID'
    | 'UTM_CAMPAIGN'
    | 'UTM_CONTENT'
    | 'UTM_MEDIUM'
    | 'UTM_SOURCE'
    | 'UTM_TERM';
  value?: object;
};
export type RequestFilters = {
  filterEntryList?: RequestFilterEntry[];
};
export const {
  useGetAvailableAttributesUsingGetQuery,
  useGetAvailableAggregationsUsingGetQuery,
  useGetKpisHistoryInTimeRangeUsingPostQuery,
  useGetKpisPerKpiInTimeRangeUsingPostQuery,
} = injectedRtkApi;

import { Refresh } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useWidgetPreview } from '../hooks/preview/useWidgetPreview';
import WidgetPreviewResizeButtons from './WidgetPreviewResizeButtons';

export default function WidgetPreviewPanel({ panelHandleState }) {
  const { joeHtmlAlchemy, j } = useWidgetPreview();
  const { panelHandle } = panelHandleState;

  useEffect(() => {
    if (joeHtmlAlchemy && panelHandle?.current?.getSize() && j) {
      joeHtmlAlchemy(j);
    } else {
      const parentNode = document.getElementById('widget-preview');
      while (parentNode.firstChild) {
        parentNode.firstChild.remove();
      }
    }
  }, [panelHandle, j]);

  return (
    <Stack spacing={0}>
      <Box display="flex" justifyContent="end" marginY={1}>
        <Tooltip title="Refresh">
          <IconButton sx={{ alignSelf: 'end' }} onClick={() => joeHtmlAlchemy(j)}>
            <Refresh />
          </IconButton>
        </Tooltip>
        <WidgetPreviewResizeButtons panelHandleState={panelHandleState} />
      </Box>
      <Box id="widget-preview" className="widget-preview" sx={{ padding: 2 }} />
      <Helmet>
        <script type="text/javascript" src="/services/reco-widget/widget-loader.js"></script>
      </Helmet>
    </Stack>
  );
}

import React, { useEffect, useState } from 'react';

import { MiniWebAnalytics, WebAnalytics } from './WebAnalytics';
import { Typography, useMediaQuery } from '@mui/material';
import { Analytics, BubbleChart, Recommend } from '@mui/icons-material';
import { Greensights, MiniGreensights } from './Greensights';
import { MiniRecommendations, Recommendations } from './Recommendations';
import { ControlledTabulate, TabItem } from 'app/components/tabulation';
import { Translate } from 'react-jhipster';
import { Box } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const ProductSlider = () => {
  const [selectedTab, setSelectedTab] = useState('0');
  const [hover, setHover] = useState(null);
  // const theme = useTheme();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const mobileVersion = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const swipeTab = () => {
    if (!hover) {
      if (selectedTab === '2') setSelectedTab('0');
      else setSelectedTab(String(Number(selectedTab) + 1));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => swipeTab(), mobileVersion ? 2000 : 4000);

    return () => clearInterval(interval);
  }, [selectedTab, hover]);

  return (
    <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} sx={{ height: mobileVersion ? '40vh' : '80vh' }}>
      <ControlledTabulate tabValue={selectedTab} setTabValue={value => setSelectedTab(value)} variant="fullWidth">
        <TabItem
          tab={{
            label: (
              <Typography fontSize={{ xs: '1rem', md: '1.5rem' }}>
                <Translate contentKey="module-factory.web-analytics">Web Analytcis</Translate>
              </Typography>
            ),
            ...(matches && {
              icon: <Analytics />,
              iconPosition: 'start',
            }),
            sx: {
              borderBottom: theme => `${theme.palette.divider} solid 1px`,
            },
          }}
        >
          {mobileVersion ? <WebAnalytics /> : <MiniWebAnalytics />}
        </TabItem>
        <TabItem
          tab={{
            label: (
              <Typography fontSize={{ xs: '1rem', md: '1.5rem' }}>
                <Translate contentKey="module-factory.recommendations">Recommendations</Translate>
              </Typography>
            ),
            ...(matches && {
              icon: <Recommend />,
              iconPosition: 'start',
            }),
            sx: {
              borderBottom: theme => `${theme.palette.divider} solid 1px`,
            },
          }}
        >
          {mobileVersion ? <Recommendations /> : <MiniRecommendations />}
        </TabItem>
        <TabItem
          tab={{
            label: (
              <Typography fontSize={{ xs: '1rem', md: '1.5rem' }}>
                <Translate contentKey="module-factory.greensights">Greensights</Translate>
              </Typography>
            ),
            ...(matches && {
              icon: <BubbleChart />,
              iconPosition: 'start',
            }),
            sx: {
              borderBottom: theme => `${theme.palette.divider} solid 1px`,
            },
          }}
        >
          {mobileVersion ? <Greensights /> : <MiniGreensights />}
        </TabItem>
      </ControlledTabulate>
    </Box>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { ICatalog } from '../model/catalog.model';

const catalogUrl = CONFIG_MANAGER_API_URL + '/billing/catalog';

export const initialState = {
  catalogLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  catalog: [] as Array<ICatalog>,
};

export type CatalogState = Readonly<typeof initialState>;

// Actions

export const getCatalogList = createAsyncThunk('catalog/getCatalogList', async () => microserviceHttpClient(`${catalogUrl}`), {
  serializeError: serializeAxiosError,
});

// Slice

export const CatalogSlice = createSlice({
  name: 'catalog',
  initialState: initialState as CatalogState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCatalogList.pending, state => {
        state.catalogLoading = true;
        state.errorMessage = null;
      })
      .addCase(getCatalogList.fulfilled, (state, action) => {
        state.catalogLoading = false;
        state.catalog = action.payload.data;
      })
      .addCase(getCatalogList.rejected, (state, action) => {
        state.catalogLoading = false;
        state.errorMessage = action.error.message;
      });
  },
});

// Reducer
export default CatalogSlice.reducer;

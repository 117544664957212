import { FitScreen, Laptop, Monitor, Smartphone, Tablet } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FormBuilder } from 'app/modules/form-builder-lib';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import {
  getRecoWidgetMediaScreenType,
  getRecoWidgetStrategy,
  mediaScreenTypes,
  setMediaScreenType,
  setRecoParams,
} from 'app/shared/reducers/views/recommendations/widget';
import React, { memo, useEffect } from 'react';
import { useRecoParamsForm } from '../hooks/forms/useRecoParamsForm';

export const mediaScreenTypeIcons = {
  None: <FitScreen />,
  Desktop: <Monitor />,
  Laptop: <Laptop />,
  Tablet: <Tablet />,
  Smartphone: <Smartphone />,
};

export const RecoParamsForm = () => {
  const recoParams = useAppSelector(getRecoWidgetStrategy);
  const mediaScreenType = useAppSelector(getRecoWidgetMediaScreenType);
  const { getFields, methods, corpusList } = useRecoParamsForm({ ...recoParams });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!recoParams.corpusId && corpusList.length > 0) {
      methods.setValue('corpusId', corpusList[0]);
    }
  }, [recoParams, corpusList]);

  useEffect(() => {
    const subscription = methods.watch(data => {
      dispatch(setRecoParams(data));
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  if (recoParams.corpusId) {
    return (
      <RawStackLayout padding={2}>
        <ToggleButtonGroup
          exclusive
          value={mediaScreenType}
          onChange={(event, value) => {
            dispatch(setMediaScreenType(value));
          }}
          size="small"
          color="primary"
          sx={{ backgroundColor: 'white', marginLeft: 1 }}
        >
          {mediaScreenTypes.map(m => {
            return (
              <ToggleButton key={m} value={m}>
                <Tooltip title={m}>{mediaScreenTypeIcons[m]}</Tooltip>
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        <FormBuilder fields={getFields()} methods={methods} containerProps={{ spacing: 1.5 }} boxProps={{ padding: 2 }} />
      </RawStackLayout>
    );
  }
  return null;
};

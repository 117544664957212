import React from 'react';

import { Typography, Avatar, Icon, Divider, Chip, Button, CardMedia, Stack } from '@mui/material';
import { Translate } from 'react-jhipster';
import { productsModules } from 'app/modules/views/module-root/config/productModules';
import CollapsibleProductCard from './CollapsibleProductCard';
import { ProductActions } from '../menus/ProductActions';
import { sitemap } from 'app/shared/util/url-utils';
import { Link } from 'react-router-dom';
import { images } from './EnrolledProducts';

export const AvailableProducts = ({ otherProducts }) => {
  return (
    <>
      <Divider>
        <Chip
          label={
            <Typography variant="h4" textAlign="center">
              <Translate contentKey="products.available-products">Available applications</Translate>
            </Typography>
          }
        />
      </Divider>
      <Stack m={2}>
        {otherProducts.map((product, index) => {
          return (
            <CollapsibleProductCard
              key={product.name}
              header={{
                title: (
                  <Typography variant="h5">
                    <Translate contentKey={`landing.products.${product.name}.title`}>{product.name}</Translate>
                  </Typography>
                ),
                subheader: (
                  <Typography whiteSpace="normal">
                    <Translate contentKey="products.subscribe-now">Subscribe now to explore product</Translate>
                  </Typography>
                ),
                avatar: (
                  <Avatar sx={{ bgcolor: theme => theme.palette.primary.light }}>
                    <Icon>{productsModules[product.name]?.sidebar?.icon}</Icon>
                  </Avatar>
                ),
                action: <ProductActions product={product} />,
              }}
              actions={
                <Button variant="outlined" component={Link} to={sitemap.setup} state={{ product }}>
                  <Translate contentKey="products.subscribe">Subscribe</Translate>
                </Button>
              }
              card={{
                sx: {
                  backgroundImage: `linear-gradient(150deg,  #f9e7d1, #cedcfb)`,
                  '&:hover': {
                    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                  },
                  '.MuiCardActions-root': { justifyContent: 'space-between' },
                },
              }}
            >
              <CardMedia sx={{ height: 140, mb: 1 }} image={images[product.name]} />
              <Typography whiteSpace="normal">
                <Translate contentKey={`landing.products.${product.name}.summary`}>{product.name}</Translate>
              </Typography>
            </CollapsibleProductCard>
          );
        })}
      </Stack>
    </>
  );
};

import React, { useEffect } from 'react';

import { useAppDispatch } from 'app/config/store';
import { getListCSVFiles } from 'app/shared/reducers/web-analytics/tools/export/list';
import { LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useProgress } from '../../hooks/state/useProgress';
import prettyBytes from 'pretty-bytes';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        {props.value === 0 ? (
          <LinearProgress />
        ) : (
          <LinearProgress
            sx={{
              '.MuiLinearProgress-bar': {
                transition: 'none',
              },
            }}
            variant="determinate"
            {...props}
          />
        )}
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function Progress({ params }) {
  if (params.value !== 0) return prettyBytes(params.value) as any;

  const dispatch = useAppDispatch();
  const { progress, getProgress } = useProgress();
  useEffect(() => {
    getProgress(params.row.fileName);
  }, []);
  useEffect(() => {
    if (progress === 100) dispatch(getListCSVFiles());
  }, [progress]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}

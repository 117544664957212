import { BoxProps } from '@mui/material';
import { Box } from '@mui/system';
import { PaymentMethodAccordion } from 'app/components/payment-method-accordion/PaymentMethodAccordion';
import { useAppSelector } from 'app/config/store';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { IPaymentMethods } from 'app/shared/model/payment-methods.model';
import React from 'react';
import { BANK_ACCOUNT_TYPE, CARD_TYPE, getPaymentMethodType, SEPA_DEBIT_TYPE } from '../payment-method-utils';
import { bankAccountPaymentMethodMap } from '../payment-methods-types/BankAccount';
import { cardPaymentMethodMap } from '../payment-methods-types/Card';
import { sepaDebitPaymentMethodMap } from '../payment-methods-types/SepaDebit';
import { unknownPaymentMethodMap } from '../payment-methods-types/Unknown';
import { GetPaymentMethodsUsingGetApiResponse } from 'app/shared/reducers/api/config-manager-cloud-services/store/payment-method';
import { useSetDefaultUsingPutMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';

const getPaymentMethodMap = {
  [BANK_ACCOUNT_TYPE]: bankAccountPaymentMethodMap,
  [SEPA_DEBIT_TYPE]: sepaDebitPaymentMethodMap,
  [CARD_TYPE]: cardPaymentMethodMap,
};

const PaymentMethodList = ({
  paymentMethodsList,
  containerProps = {},
}: {
  paymentMethodsList: GetPaymentMethodsUsingGetApiResponse;
  containerProps?: BoxProps;
}) => {
  const { currentLocale }: any = useAppSelector(state => state.locale);
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const [setDefaultPaymentMethod] = useSetDefaultUsingPutMutation();

  function setDefault(paymentMethod) {
    return () => {
      setDefaultPaymentMethod({ accountKey: accountProfile.accountId, paymentMethodId: paymentMethod.paymentMethodId });
    };
  }

  return (
    <Box mt={1} {...containerProps}>
      {paymentMethodsList.map(paymentMethod => {
        const paymentMethodType = getPaymentMethodType(paymentMethod);
        const paymentMethodMapGetter = getPaymentMethodMap[paymentMethodType] || unknownPaymentMethodMap;
        const paymentMethodMap = paymentMethodMapGetter(paymentMethod, setDefault(paymentMethod), currentLocale);
        return paymentMethodMap.id ? <PaymentMethodAccordion key={paymentMethodMap.id} paymentMethodMap={paymentMethodMap} /> : null;
      })}
    </Box>
  );
};

export default WithLoading(PaymentMethodList, state => state.paymentMethods.paymentMethodLoading);

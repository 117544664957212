import { TabContext, TabList, TabPanel, TabPanelProps } from '@mui/lab';
import { Tab, TabProps, TabsProps } from '@mui/material';
import React, { Children } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface TabItemProps {
  tab: TabProps;
  panel?: Partial<TabPanelProps>;
  children: React.ReactNode | JSX.Element;
}

export function TabItem(props: TabItemProps) {
  return null;
}

interface TabulateProps extends Partial<TabsProps> {
  children?: React.ReactNode;
}

interface SearchParamsTabulateProps extends TabulateProps {
  paramName: string;
}

interface ControlledTabulateProps extends TabulateProps {
  tabValue: string;
  setTabValue: React.Dispatch<string>;
}

function renderTab(item: TabItemProps, index: number): JSX.Element {
  return <Tab key={'tab' + index} {...item.tab} value={item.tab.value || String(index)} />;
}

function renderTabPanel(item: TabItemProps, index: number): JSX.Element {
  return (
    <TabPanel key={'panel' + index} {...item.panel} value={item.tab.value || String(index)}>
      {item.children}
    </TabPanel>
  );
}

function getTabItems(item: React.ReactNode): TabItemProps {
  if (!React.isValidElement(item)) {
    return null;
  }

  if (item.type !== TabItem) {
    return null;
  }

  return item.props;
}

export function ControlledTabulate({ children, tabValue, setTabValue, ...tabsProps }: ControlledTabulateProps): JSX.Element {
  const tabsRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new ResizeObserver(entries => {
      window.dispatchEvent(new CustomEvent('resize'));
    });

    if (tabsRef.current) {
      observer.observe(tabsRef.current);
    }

    // Cleanup function
    return () => observer.disconnect();
  }, [tabsRef.current]);

  function onChangeTabs(event: React.SyntheticEvent, newValue: string): void {
    setTabValue(newValue);
  }

  const tabItems = Children.map(children, getTabItems).filter(v => v !== null && v.tab !== undefined);
  const defaultTabValue: string = tabItems[0]?.tab?.value || '0';

  return (
    <TabContext value={tabValue || defaultTabValue}>
      <TabList ref={tabsRef} onChange={onChangeTabs} textColor="primary" indicatorColor="primary" {...tabsProps}>
        {tabItems?.map(renderTab)}
      </TabList>
      {tabItems?.map(renderTabPanel)}
    </TabContext>
  );
}

export function Tabulate(props: TabulateProps) {
  const [tabValue, setTabValue] = React.useState(undefined);

  return <ControlledTabulate tabValue={tabValue} setTabValue={setTabValue} {...props} />;
}

export const SearchParamsTabulate = ({ paramName, ...rest }: SearchParamsTabulateProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <ControlledTabulate tabValue={searchParams.get(paramName)} setTabValue={value => setSearchParams({ [paramName]: value })} {...rest} />
  );
};

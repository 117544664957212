import { useAppSelector } from 'app/config/store';
import { useEffect } from 'react';
import {
  useGetKpisHistoryInTimeRangeUsingPostQuery,
  useGetKpisPerKpiInTimeRangeUsingPostQuery,
} from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { getDataExplorationTimerange, selectAllDataExplorationFilters } from 'app/shared/reducers/views/data-exploration';
import { bucketingDimensionsMappingList } from 'app/shared/util/data-hub-utils';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const useDataExploration = () => {
  const timerange = useAppSelector(getDataExplorationTimerange);
  const filter = useAppSelector(selectAllDataExplorationFilters);

  const { refetch: refetchKpisHistory, isUninitialized: isKpisHistoryUninitialized } = useGetKpisHistoryInTimeRangeUsingPostQuery(
    timerange
      ? {
          kpis: 'USER_COUNT,SESSION_COUNT,AVG_SESSION_DURATION,BOUNCE_RATE,ACTIVE_USER_COUNT,PAGEVIEWS_COUNT',
          requestFilters: {
            filterEntryList: filter,
          },
          from: timerange?.fromDate,
          to: timerange?.toDate,
          timeStep: timerange?.step,
        }
      : skipToken
  );

  const { refetch: refetchKpisPerKpi, isUninitialized: isKpisPerKpiUninitialized } = useGetKpisPerKpiInTimeRangeUsingPostQuery(
    timerange
      ? {
          bucketingDimensions: `${bucketingDimensionsMappingList.map(element => element.value).join()},COUNTRY_CODE` as any,
          kpis: 'USER_COUNT,SESSION_COUNT,PAGEVIEWS_COUNT,AVG_SESSION_DURATION,SUM_SESSION_DURATION,BOUNCE_RATE,BOUNCE_COUNT,ACTIVE_USER_COUNT',
          requestFilters: {
            filterEntryList: filter,
          },
          from: timerange?.fromDate,
          to: timerange?.toDate,
        }
      : skipToken
  );

  useEffect(() => {
    if (!isKpisHistoryUninitialized) refetchKpisHistory();
    if (!isKpisPerKpiUninitialized) refetchKpisPerKpi();
  }, [timerange]);
};

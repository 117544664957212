import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useUpdateItemMutation } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { useForm, useFieldArray } from 'react-hook-form';
import { Translate } from 'react-jhipster';
import { Edit, FileUpload } from '@mui/icons-material';
import { Button } from '@mui/material';

type FormValues = {
  title: string;
  description: string;
  attributes: { key: string; value: string }[];
};
export const useUpdateItem = (item, setOpen) => {
  const { accountProfile } = useAppAccountStates();
  const [updateItem, { isLoading }] = useUpdateItemMutation();

  const methods = useForm<FormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      title: item.title,
      description: item.description,
      attributes: Object.entries<string>(item.attributes).map(([k, v]) => {
        return { key: k, value: v };
      }),
    },
  });

  const { register, formState, control } = methods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attributes',
  });

  const submitButton = (
    <LoadingButton
      disabled={!methods.formState.isDirty || !methods.formState.isValid}
      startIcon={<Edit />}
      variant="contained"
      color="primary"
      loading={isLoading}
      loadingPosition="start"
      sx={{ marginBottom: 1.5, marginTop: 1, marginX: 2 }}
      onClick={() => {
        methods.handleSubmit((data: FormValues) => {
          const attributes = data.attributes.reduce((acc, element) => {
            acc[element.key] = element.value;
            return acc;
          }, {});
          updateItem({
            accountId: accountProfile?.accountId.substring(0, 8),
            id: item.id,
            itemDoc: { ...data, attributes, id: item.id },
          }).then(res => setOpen(false));
        })();
      }}
    >
      <Translate contentKey="recommendation.items.edit">Edit</Translate>
    </LoadingButton>
  );

  const cancelButton = (
    <Button color="error" onClick={() => setOpen(false)}>
      <Translate contentKey="recommendation.cancel">Cancel</Translate>
    </Button>
  );
  const discardButton = (
    <Button onClick={() => methods.reset()} sx={{ ...(!methods.formState.isDirty && { display: 'none' }) }}>
      <Translate contentKey="recommendation.items.discard-changes">Discard Changes</Translate>
    </Button>
  );
  return { register, errors, fields, methods, remove, append, submitButton, cancelButton, discardButton };
};

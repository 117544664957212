import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

const SidebarItem = ({ item }) => {
  const location = useLocation();
  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      target={item.target}
      to={item.path}
      selected={location.pathname === item.path}
      sx={{
        ...(!item.sidebarProps.icon && {
          ...(location.pathname === item.path
            ? {
                borderLeft: theme => `3px solid ${theme.palette.primary.main}`,
              }
            : {
                borderLeft: theme => `1px solid ${theme.palette.primary.main}`,
              }),
        }),
        ...(!item.sidebarProps.icon && {
          ml: 2,
        }),
        px: 1,
      }}
    >
      {item.sidebarProps.icon && (
        <ListItemIcon
          sx={{
            ...(location.pathname === item.path && {
              color: theme => theme.palette.primary.main,
            }),
            justifyContent: 'center',
          }}
        >
          {item.sidebarProps.icon}
        </ListItemIcon>
      )}
      <ListItemText
        sx={{
          ml: 2,
          my: 0,
        }}
        primary={<Typography fontWeight={location.pathname === item.path && 'bold'}>{item.sidebarProps.displayText}</Typography>}
      />
    </ListItemButton>
  ) : null;
};

export default SidebarItem;

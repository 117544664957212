import { useAppDispatch, useAppSelector } from 'app/config/store';
import { resetSucceededToken } from 'app/shared/reducers/account-profiles/access-token';
import { useEffect, useState } from 'react';

export const useNewAccessToken = () => {
  const [showToken, setShowToken] = useState(false);
  const [copyToClipboard, setCopyToClipboard] = useState(false);
  const { token } = useAppSelector(state => state.accountProfile.accessToken);

  const handleClickShowToken = () => {
    setShowToken((prevState: boolean) => !prevState);
  };

  const handleCopyToClipboard = () => {
    setCopyToClipboard(true);
    navigator.clipboard.writeText(token);
  };
  const handleCloseCopyToClipboard = () => {
    setCopyToClipboard(false);
  };
  return {
    token,
    showToken,
    copyToClipboard,
    handleClickShowToken,
    handleCopyToClipboard,
    handleCloseCopyToClipboard,
  };
};

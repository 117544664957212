import { useAppSelector } from 'app/config/store';
import useJoeHtmlAlchemy from 'app/shared/components/joeHtmlAlchemy';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { MediaScreenTypes, getRecoWidget } from 'app/shared/reducers/views/recommendations/widget';
import * as beautify from 'js-beautify';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { LayoutTypes, cssLayoutTypes } from '../../utils/CssLayout';
import { typographyStyles } from '../../utils/CssStyles';
import { generateHtmlElement } from '../../utils/generateHtmlElements';

function updateStyleArray(target, source, styleKey: string = 'style') {
  const noneLayoutIndex = cssLayoutTypes.indexOf(source.value['None']);
  const unsetNoneLayout = Object.keys(source[styleKey]['None'][noneLayoutIndex]).reduce((acc, next) => {
    acc[next] = 'inherit';
    return acc;
  }, {});
  Object.entries(source.value).forEach(([key, value]: [MediaScreenTypes, LayoutTypes]) => {
    const layoutIndex = cssLayoutTypes.indexOf(value);
    target.style[key] = [{ ...unsetNoneLayout, ...source[styleKey][key][layoutIndex] }, ...(target.style[key] ?? [])];
  });
}

export const useWidgetPreview = () => {
  let j;
  const joeHtmlAlchemy = useJoeHtmlAlchemy();
  const { data: accountProfile } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { widgetId, recoParams, layoutStyle, containerStyle, itemBoxStyle, cardStructure } = useAppSelector(getRecoWidget);
  const widgetBoxStyle = cloneDeep(containerStyle);
  const widgetItemStyle = cloneDeep(itemBoxStyle);

  if (accountProfile != null) {
    const generateCard = cardStructure.filter(card => Boolean(card.value)).map(card => generateHtmlElement(card));

    updateStyleArray(widgetItemStyle, layoutStyle, 'itemStyle');
    const itemBoxElement = generateHtmlElement(widgetItemStyle, generateCard.map(card => card.template).join(`\n`));

    updateStyleArray(widgetBoxStyle, layoutStyle);
    const containerElement = generateHtmlElement(widgetBoxStyle, `\n{{#items}}\n${itemBoxElement.template}\n{{/items}}\n`);

    j = {
      widgetId,
      builder: {
        type: 'custom',
        template: beautify.html(containerElement.template),
        style: beautify.css(
          `${typographyStyles}\n${containerElement.style}\n${itemBoxElement.style}\n${generateCard
            .map(card => card.style)
            .filter(Boolean)
            .join('\n')}`
        ),
      },
      recommendations: {
        ...recoParams,
        clientRest: {
          clientOpts: {
            baseUrl: `/services/reco-engine-${accountProfile.accountId.substring(0, 8)}/`,
          },
          accountId: accountProfile.accountId,
        },
      },
      elementId: 'widget-preview',
    };
  }

  const containerId = useMemo(() => crypto.randomUUID(), []);

  return {
    accountId: accountProfile?.accountId,
    containerId,
    joeHtmlAlchemy,
    j: recoParams.corpusId && j,
  };
};

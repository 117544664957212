import React from 'react';

import { Button } from '@mui/material';
import { Box, BoxProps } from '@mui/system';

export const TabWithAction = ({
  children,
  action,
  sxContainer = {},
}: {
  children: JSX.Element;
  action: { text: string; icon: any; onClick: () => void };
  sxContainer?: BoxProps;
}): JSX.Element => {
  return (
    <Box sx={{ ...sxContainer }}>
      <Box display="flex" justifyContent="end" mb={1}>
        <Button variant="outlined" size="small" startIcon={action.icon} onClick={() => action.onClick()}>
          {action.text}
        </Button>
      </Box>
      {children}
    </Box>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { serializeAxiosError } from '../reducer.utils';

const dataExplorationUrl = DATA_HUB_API_URL + '/exploration/data';

const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  referer: null,
};

export type RefererState = Readonly<typeof initialState>;

export const getUsersByReferer = createAsyncThunk(
  'dataHub/FETCH_REFERER_DATA',
  async ({ from, to }: any) => {
    return microserviceHttpClient(
      `${dataExplorationUrl}/kpisPerBucket?from=${from}&to=${to}&bucketingDimensions=REFERER&kpis=USER_COUNT`,
      'post',
      JSON.stringify({
        filterEntryList: [],
      })
    );
  },
  { serializeError: serializeAxiosError }
);

export const UsersByRefererSlice = createSlice({
  name: 'usersByReferer',
  initialState: initialState as RefererState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsersByReferer.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getUsersByReferer.fulfilled, (state, action) => {
        state.loading = false;
        state.referer = action.payload.data.data.REFERER.map(element => {
          return { name: element.name, value: element.buckets['USER_COUNT']['count'] };
        });
      })
      .addCase(getUsersByReferer.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.referer = null;
      });
  },
});

export default UsersByRefererSlice.reducer;

import React from 'react';

import { Download, Payment, RemoveRedEye } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { currencyAmount } from 'app/components/data-grid/columns/column-helpers';
import { useAppSelector } from 'app/config/store';
import dayjs from 'dayjs';
import { translate } from 'react-jhipster';
import { InvoiceDocument } from '../components/data-grids/invoices/payment/InvoiceDocument';
import { getInvoiceDetail } from '../components/data-grids/invoices/payment/getInvoiceDetails';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { AccountDtoRes, accountApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';

export const useInvoicesColumns = (setInvoice, setOpenInvoiceDetailsDialog, setPayment, setOpenPaymentDetailsDialog): GridColDef[] => {
  const { currentLocale }: any = useAppSelector(state => state.locale);
  const { userProfile, accountProfile } = useAppAccountStates();
  const { data: billingAccount = {} as AccountDtoRes } = accountApi.endpoints.getAccountByKeyUsingGet.useQueryState({
    accountKey: userProfile ? userProfile.currentAccount : null,
  });

  return [
    { field: 'invoiceNumber', headerAlign: 'left', type: 'number', align: 'left' },
    {
      field: 'invoiceDate',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) => value && dayjs(value).locale(currentLocale).format('llll'),
    },
    {
      field: 'status',
      renderCell(params) {
        let status;
        let color;
        if (params.row.balance === 0) {
          status = 'paid';
          color = 'success';
        } else if (params.row.balance === params.row.amount) {
          status = 'to-pay';
          color = 'error';
        } else {
          status = 'partially-paid';
          color = 'warning';
        }
        return <Chip label={translate(`account.invoice-table.${status}`)} variant="outlined" size="small" color={color} />;
      },
    },
    { field: 'amount', ...currencyAmount() },
    { field: 'balance', ...currencyAmount() },
    {
      field: 'actions',
      type: 'actions',
      getActions(params: GridRowParams) {
        return [
          <GridActionsCellItem
            key="details"
            icon={
              <Tooltip title={translate('account.invoice-table.details')}>
                <RemoveRedEye />
              </Tooltip>
            }
            onClick={() => {
              setInvoice(params.row);
              setOpenInvoiceDetailsDialog(true);
            }}
            label={translate('account.invoice-table.details')}
          />,
          <GridActionsCellItem
            key="download"
            icon={
              <PDFDownloadLink
                document={
                  <InvoiceDocument invoice={getInvoiceDetail(params.row, userProfile, accountProfile, billingAccount, currentLocale)} />
                }
                fileName={`${getInvoiceDetail(params.row, userProfile, accountProfile, billingAccount, currentLocale).id}.pdf`}
              >
                <Download color="neutral" />
              </PDFDownloadLink>
            }
            label={translate('account.invoice-table.download')}
          />,
          <GridActionsCellItem
            key="payments"
            icon={
              <Tooltip title={translate('account.invoice-table.payments')}>
                <Payment />
              </Tooltip>
            }
            onClick={() => {
              setPayment(params.row.payment);
              setOpenPaymentDetailsDialog(true);
            }}
            disabled={!params.row.payment}
            label={translate('account.invoice-table.payments')}
          />,
        ];
      },
    },
  ];
};

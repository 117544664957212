import React from 'react';

import { RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Product } from 'app/shared/model/catalog.model';
import { ProductButtons } from './ProductButtons';
import { ProductCard } from './ProductCard';

export const ProductList = ({ productList }: { productList: Product[] }) => {
  return (
    <RawGridLayout spacing={2}>
      {productList?.map(product => {
        return (
          <LayoutItem key={product.name} gridItemProps={{ xs: 12, md: 6, lg: 4 }}>
            <ProductCard productName={product.name}>
              <ProductButtons product={product} />
            </ProductCard>
          </LayoutItem>
        );
      })}
    </RawGridLayout>
  );
};

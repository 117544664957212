import { Accordion } from '@mui/material';
import React from 'react';
import { PaymentMethodAccordionDetails } from './PaymentMethodAccordionDetails';
import { IPaymentMainInfos, PaymentMethodAccordionSummary } from './PaymentMethodAccordionSummary';

export interface IPaymentMethodMapProps {
  id: string;
  paymentMainInfos: IPaymentMainInfos;
  paymentMethodInfos:
    | {
        title: string;
        i18n: string;
        value: any;
        renderPaymentInfo?: (info: { title: string; i18n: string; value: any }, index: number) => [React.ReactNode, string];
      }[]
    | React.ReactNode[];
}
interface IPaymentMethodAccordionProps {
  paymentMethodMap: IPaymentMethodMapProps;
}

export const PaymentMethodAccordion = ({ paymentMethodMap, ...rest }: IPaymentMethodAccordionProps) => {
  return (
    <Accordion key={paymentMethodMap.id} {...rest}>
      <PaymentMethodAccordionSummary paymentMainInfos={paymentMethodMap.paymentMainInfos} />
      <PaymentMethodAccordionDetails paymentMethodInfos={paymentMethodMap.paymentMethodInfos} />
    </Accordion>
  );
};

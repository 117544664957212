import React from 'react';
import { List, ListItemIcon, ListItemText, Typography, ListItemButton, Slide } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import { translate } from 'react-jhipster';
import { languages, locales } from 'app/config/translation';
import { useAppSelector } from 'app/config/store';

export const ProfileLanguageMenu = ({ handleLocaleChange, setActiveMenu, activeMenu, containerRef, OnMenuEnter }) => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  return (
    <Slide
      in={activeMenu === 'language'}
      hidden={activeMenu !== 'language'}
      direction="left"
      container={containerRef.current}
      appear={false}
      timeout={500}
      onEnter={OnMenuEnter}
    >
      <List
        component="nav"
        sx={{
          width: '220px',
          maxWidth: '350px',
          paddingLeft: '0px !important',
          borderRadius: '10px',
        }}
      >
        <ListItemButton
          onClick={() => {
            setActiveMenu('main');
          }}
        >
          <ListItemIcon>
            <ArrowLeftIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">{translate('profile-section.choose-language')}</Typography>} />
        </ListItemButton>
        {Object.keys(languages).length > 1 ? (
          <>
            {locales.map(locale => (
              <ListItemButton selected={locale === currentLocale} key={locale} onClick={event => handleLocaleChange(event, locale)}>
                <ListItemText
                  primary={
                    <Typography variant="body2" sx={{ ml: 4, ...(locale === currentLocale && { color: 'secondary.main' }) }}>
                      {`${languages[locale].name}`}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </>
        ) : null}
      </List>
    </Slide>
  );
};

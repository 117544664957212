import React from 'react';

import { Typography } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { translate } from 'react-jhipster';
import PieChart from '.';
import { getPieChartList } from './pieChartList';
import { getDataExplorationBucketingDimension } from 'app/shared/reducers/views/data-exploration';
import { useDataExplorationKpisPerKpi } from '../../hooks/useDataExplorationKpisPerKpi';

export const PieGrids = () => {
  const bucketingDimension = useAppSelector(getDataExplorationBucketingDimension);
  const { secondary_dimensions, isFetching, error, isError } = useDataExplorationKpisPerKpi();

  return (
    <GridLayout width="100%">
      {getPieChartList(secondary_dimensions, bucketingDimension).map(pieChart => {
        return (
          <LayoutItem
            cardOptions={{
              header: {
                title: (
                  <Typography variant="h4" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} p={0}>
                    {translate(`open-analytics.chart.${pieChart.title}`)}
                  </Typography>
                ),
                sx: {
                  '.MuiCardHeader-content': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', p: 1 },
                  p: 0,
                },
              },
              card: {
                sx: { overflow: 'visible' },
              },
              content: { sx: { height: '165px', py: 0, '&:last-child': { py: 0 } } },
            }}
            gridItemProps={{ xs: 6, lg: 2, sm: 4 }}
            key={pieChart.title}
          >
            <PieChart
              data={pieChart.data?.values}
              dataset={pieChart.data || []}
              formatter={pieChart.formatter}
              isFetching={isFetching}
              isError={isError}
              error={error}
            />
          </LayoutItem>
        );
      })}
    </GridLayout>
  );
};

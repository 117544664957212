import cohort from './cohort';
import newUsers from './new-users';
import referer from './referer';
import userOverview from './user-overview';
import userStats from './user-stats';
import usersByCity from './users-by-city';
import usersByCountry from './users-by-country';
import users from './users';
import usersTimerange from './timeRange';
import { combineReducers } from 'redux';
import userExplorationFilters from './filters';

const userExploration = combineReducers({
  cohort,
  newUsers,
  referer,
  userOverview,
  userStats,
  usersByCity,
  usersByCountry,
  users,
  usersTimerange,
  userExplorationFilters,
});

export default userExploration;

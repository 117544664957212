import React from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Typography } from '@mui/material';
import { Groups } from '@mui/icons-material';
import { Translate } from 'react-jhipster';
import { MembersDataGrid } from 'app/modules/views/account-page/components/data-grids/MembersDataGrid';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useUserStates } from 'app/shared/layout/restricted/hooks/selectors/useUserStates';

export const MembersItem = () => {
  const { membersList } = useAppAccountStates();
  const { currentUserRoles } = useUserStates();
  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.members">Members</Translate>
            </Typography>
          ),
          avatar: <Groups color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <MembersDataGrid
        membersList={currentUserRoles.includes('HURENCE_ADMIN') ? membersList : membersList.filter(m => !m.roles.includes('HURENCE_ADMIN'))}
      />
    </LayoutItem>
  );
};

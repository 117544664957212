import { AccountDtoRes, accountApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { UserProfileDtoRes, userProfilesApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';
import { useMemo } from 'react';

export const useAppAccountStates = () => {
  const { data: userProfile = {} as UserProfileDtoRes } = userProfilesApi.endpoints.getCurrentUserProfileUsingGet.useQueryState({});
  const { data: billingAccount = {} as AccountDtoRes } = accountApi.endpoints.getAccountByKeyUsingGet.useQueryState({
    accountKey: userProfile ? userProfile.currentAccount : null,
  });
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});

  const invoiceSelector = res =>
    useMemo(() => {
      return { ...res, invoiceList: res.data ? res.data.filter(invoice => invoice.status === 'COMMITTED') : [] };
    }, [res.data]);

  const { invoiceList } = accountApi.endpoints.getAccountInvoicesUsingGet.useQueryState(
    { accountKey: accountProfile.accountId },
    {
      selectFromResult: invoiceSelector,
    }
  );

  const { data: membersList = [] } = accountProfileApi.endpoints.getCurrentAccountMembersUsingGet.useQueryState();

  return {
    userProfile,
    billingAccount,
    accountProfile,
    invoiceList,
    membersList,
  };
};

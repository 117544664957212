import React, { useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import 'echarts/i18n/langFR';
import { useAppSelector } from 'app/config/store';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { WithData } from 'app/modules/loading-lib/WithData';
import { ResponseWrapper } from 'app/modules/views/greensights/model/responseWrapper';
import MetricResponse from 'app/modules/views/greensights/model/metricResponse';
import { useData } from '../../../data/api';
import { useSelectedTimeParams } from '../actions/timeranges';
import {
  ResponseWrapperMetricResponse,
  useLazyLoadQuery,
} from 'app/shared/reducers/api/greensights-data-hub-cloud-services/store/greensights-data-hub';

export const callback = (response: ResponseWrapperMetricResponse) => {
  if (response.content.status === 'success') {
    return response.content.data.result[0].values.map(value => [value[0] * 1000, value[1]]);
  }
  return [];
};

export const LineKPIChart = ({ chart }) => {
  const theme = useTheme();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const { timeParams } = useSelectedTimeParams();

  // const { data, load, loading } = useData(`chart_${chart.key}`, callback);

  const [load, { data = [], loading }] = useLazyLoadQuery({
    selectFromResult(response) {
      return { data: response.data ? callback(response.data) : [], loading: response.isLoading };
    },
  });

  useEffect(() => {
    load({
      accountId: '93090364',
      metricRequest: {
        start: timeParams.fromDate,
        end: timeParams.toDate,
        query: `sum(${chart.key}{#filters})`,
        filters: {
          scope: chart.scope,
        },
      },
    });
  }, [timeParams]);

  const config: EChartsOption = {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    series: [
      {
        type: 'line',
        data,
        symbol: 'none',
      },
    ],
    xAxis: {
      type: 'time',
      axisLine: {
        lineStyle: {
          color: theme.palette.divider,
        },
      },
      axisLabel: {
        formatter: {
          year: '{yearStyle|{yyyy}}',
          day: '{d} {MMM}',
          month: '{monthStyle|{MMM}}',
        },
        rich: {
          monthStyle: {
            fontWeight: 'bold',
          },
          yearStyle: {
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
        fontSize: 12,
      },
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12px',
      },
    },
    tooltip: {
      borderRadius: 20,
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
    },
    textStyle: {
      color: theme.palette.text.primary,
    },
    grid: {
      left: '10%',
      right: '3%',
      top: '5%',
      bottom: '12%',
    },
  } as EChartsOption;

  const lineChart = () => <ReactECharts style={{ height: '100%', width: '100%' }} option={config} opts={{ locale: currentLocale }} />;
  return WithLoading(WithData(lineChart, data), loading)({});
};

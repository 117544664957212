import React from 'react';

import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { KPIS } from 'app/shared/util/reducer-utils';
import { DashboardCard } from './DashboardCard';

export const WebAnalyticsDashboards = () => {
  return (
    <GridLayout>
      {[KPIS.USER_COUNT.name, KPIS.SESSION_COUNT.name, KPIS.PAGEVIEWS_COUNT.name, KPIS.BOUNCE_RATE.name].map((kpi, index) => {
        return <DashboardCard key={index} kpi={kpi} />;
      })}
    </GridLayout>
  );
};

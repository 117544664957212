export const trendChartList = [
  {
    title: 'Users',
    kpi: 'USER_COUNT',
  },
  {
    title: 'Sessions',
    kpi: 'SESSION_COUNT',
  },
  {
    title: 'Sessions/Users',
    kpi: 'SESSIONS_PER_USER',
  },
  {
    title: 'Avg. Session duration',
    kpi: 'AVG_SESSION_DURATION',
  },
  {
    title: 'PageViews',
    kpi: 'PAGEVIEWS_COUNT',
  },
  {
    title: 'PageViews/Sessions',
    kpi: 'PAGEVIEWS_PER_SESSION',
  },
  {
    title: 'Bounce Rate',
    kpi: 'BOUNCE_RATE',
  },
  {
    title: 'Active Users',
    kpi: 'ACTIVE_USER_COUNT',
  },
];

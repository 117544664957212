import L, { LatLngExpression, divIcon, point } from 'leaflet';
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import './SessionsLeafletMap.css';
import { useGetUserSessionsQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-exploration';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import MarkerClusterGroup from 'react-leaflet-cluster';

function transformLocationsArray(locationsArray) {
  const locationCountMap = {};

  locationsArray.forEach(location => {
    const locationKey = JSON.stringify(location);
    if (locationCountMap[locationKey]) {
      locationCountMap[locationKey]++;
    } else {
      locationCountMap[locationKey] = 1;
    }
  });

  const transformedArray = [];
  for (const locationKey in locationCountMap) {
    if (Object.prototype.hasOwnProperty.call(locationCountMap, locationKey)) {
      const location = JSON.parse(locationKey);
      const count = locationCountMap[locationKey];
      transformedArray.push([count, location]);
    }
  }

  return transformedArray.sort((a, b) => b[0] - a[0]);
}

export const SessionsLeafletMap = () => {
  const { userId } = useParams();
  const {
    data = [],
    isFetching,
    isError,
    error,
  } = useGetUserSessionsQuery(
    userId
      ? {
          userId,

          requestFilters: {
            filterEntryList: [],
          },
        }
      : skipToken
  );
  const userGeoLocations = data ? (data.map(session => Object.values(session.geolocation)) as LatLngExpression[]) : [];

  const createClusterCustomIcon = function (cluster) {
    return divIcon({
      html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
      className: 'custom-marker-cluster',
      iconSize: point(33, 33, true),
    });
  };
  if (userGeoLocations.length > 0)
    return (
      <MapContainer center={userGeoLocations[0]} zoom={13} /* scrollWheelZoom={false} */>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup chunckedLoading iconCreateFunction={createClusterCustomIcon}>
          {transformLocationsArray(userGeoLocations).map((locationArray, index) => (
            <Marker position={locationArray[1]} key={index} title={locationArray[0]}>
              <Popup>{locationArray[0] === 1 ? '1 Session' : `${locationArray[0]} Sessions`}</Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    );
  else return <></>;
};

const DefaultIcon = L.icon({
  iconUrl: 'src/main/webapp/app/modules/views/visitor-profile/components/marker-icon.png',
  iconAnchor: [12, 41],
  popupAnchor: [2, -40],
});
L.Marker.prototype.options.icon = DefaultIcon;

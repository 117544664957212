import React, { Children } from 'react';

import { Grid, GridProps } from '@mui/material';
import { LayoutItemCard } from '../../functions/components/LayoutItemCard';
import { LayoutItemRaw } from '../../functions/components/LayoutItemRaw';
import { toLayoutItemProps } from '../../functions/converter/toLayoutItemProps';
import { LayoutProps, CoreLayoutProps } from '../../models/models';
import { LayoutItemProps } from '../LayoutItem';
import { mergeToLayoutItemProps } from '../../functions/converter/mergeToLayoutItemProps';

export function GridLayout({
  children,
  renderChild = LayoutItemCard,
  renderProps,
  cardOptions,
  accordionOptions,
  ...containerProps
}: LayoutProps<GridProps>): JSX.Element {
  if (!children) {
    return null;
  }

  return (
    <Grid container {...containerProps}>
      {Children.map(children, toLayoutItemProps).map((childProps: LayoutItemProps) => (
        <Grid item key={childProps.key} {...childProps.gridItemProps}>
          {renderChild(mergeToLayoutItemProps(accordionOptions, cardOptions, childProps, renderProps))}
        </Grid>
      ))}
    </Grid>
  );
}

export const RawGridLayout = (props: CoreLayoutProps<GridProps>): JSX.Element => {
  return <GridLayout {...props} renderChild={LayoutItemRaw} />;
};

import React, { useEffect, useState } from 'react';

import { Login, Logout, OpenInBrowser } from '@mui/icons-material';
import { Paper, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, List, useScrollTrigger } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { sitemap } from 'app/shared/util/url-utils';
import { Translate } from 'react-jhipster';
import TranslateIcon from '@mui/icons-material/Translate';
import { LanguageMenu } from './languageMenu';

export const LandingMenu = ({ appBarRef, open, setOpen }) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [openLang, setOpenLang] = useState(false);

  return (
    <Collapse
      sx={{
        display: { xs: 'block', md: 'none' },
        position: 'fixed',
        top: appBarRef.current ? appBarRef.current.clientHeight : 0,
        width: '100%',
        zIndex: theme => theme.zIndex.appBar,
      }}
      in={open}
      unmountOnExit
    >
      <Paper sx={{ width: '100%', borderRadius: 0 }} elevation={4}>
        <List>
          {isAuthenticated && (
            <ListItem>
              <ListItemButton href={sitemap.home}>
                <ListItemIcon>
                  <OpenInBrowser />
                </ListItemIcon>
                <ListItemText primary={<Translate contentKey="root.menu.workspace">Workspace</Translate>} />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem>
            <ListItemButton href={isAuthenticated ? sitemap.logout : sitemap.home}>
              <ListItemIcon>{isAuthenticated ? <Logout /> : <Login />}</ListItemIcon>
              <ListItemText
                primary={
                  <>
                    {isAuthenticated ? (
                      <Translate contentKey="root.menu.logout">Logout</Translate>
                    ) : (
                      <Translate contentKey="root.menu.login">Login</Translate>
                    )}
                  </>
                }
              />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => {
                setOpenLang(prev => !prev);
              }}
            >
              <ListItemIcon>
                <TranslateIcon />
              </ListItemIcon>
              <ListItemText primary={<Translate contentKey="root.menu.workspace">Language</Translate>} />
            </ListItemButton>
          </ListItem>

          <LanguageMenu openLang={openLang} setOpen={setOpen} setOpenLang={setOpenLang} />
        </List>
      </Paper>
    </Collapse>
  );
};

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getListCSVFiles } from 'app/shared/reducers/web-analytics/tools/export/list';
import { useEffect } from 'react';

export const useCsvList = () => {
  const { list, loading } = useAppSelector(state => state.webAnalyticsOverview.exportCSV.listCSV);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getListCSVFiles());
    // const timer = setInterval(() => {
    //   dispatch(getListCSVFiles());
    // }, 1000);
    // return () => {
    //   clearInterval(timer);
    // };
  }, []);
  return { list, loading };
};

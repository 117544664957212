import React from 'react';

import { Login, OpenInBrowser } from '@mui/icons-material';
import { Button, CardMedia, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useAppSelector } from 'app/config/store';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { sitemap } from 'app/shared/util/url-utils';
import { Translate } from 'react-jhipster';
import { ScheduleDemoButton } from './components/ScheduleDemoButton';

export const ScoringJoeSummary = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  return (
    <LayoutItem
      cardOptions={{
        variant: 'div',
        card: {
          id: 'ScoringJoeSummary',
          sx: {
            display: 'flex',
            py: '15vh',
          },
        },
        content: { sx: { mx: 'auto' } },
      }}
    >
      <Container maxWidth="xl">
        <GridLayout justifyContent={{ lg: 'space-between', xs: 'none' }}>
          <LayoutItem
            gridItemProps={{
              xs: 12,
              lg: 5,
              md: 6,
              sx: {
                textAlign: { xs: 'center', md: 'left' },
                paddingBottom: { xs: '6vh', sm: '8vh', md: '0' },
              },
              alignSelf: 'center',
            }}
            cardOptions={{
              variant: 'div',
              header: {
                disableTypography: true,
                title: (
                  <Typography fontSize={'2.5rem'} textAlign="justify">
                    <Translate contentKey="landing.banner.title">Elevate Your Business with ScoringJoe&apos;s Advanced Solutions</Translate>
                  </Typography>
                ),
              },
              actions: (
                <>
                  {/* Login OR Workspace button */}
                  <Button
                    variant="contained"
                    color="primary"
                    href={sitemap.home}
                    startIcon={isAuthenticated ? <OpenInBrowser /> : <Login />}
                  >
                    <>
                      {isAuthenticated ? (
                        <Translate contentKey="root.menu.workspace">Workspace</Translate>
                      ) : (
                        <Translate contentKey="root.menu.login">Login</Translate>
                      )}
                    </>
                  </Button>
                  <ScheduleDemoButton />
                </>
              ),
              content: { sx: { p: 0, '&:last-child': { p: 0, pr: 8 } } },
              actionsProps: { sx: { p: 0, justifyContent: { xs: 'center', md: 'left' } } },
            }}
          >
            <Typography fontSize="1.25rem" sx={{ mt: '3vh', mb: '5vh' }} textAlign="justify">
              <Translate contentKey="landing.banner.summary">
                Unlock your business&apos;s potential with ScoringJoe&apos;s web analytics, recommendations, and green impact tracking to
                drive success and impact.
              </Translate>
            </Typography>
          </LayoutItem>
          <LayoutItem
            gridItemProps={{
              xs: 12,
              lg: 5,
              md: 6,
            }}
            cardOptions={{
              variant: 'div',
              card: { sx: { overflow: 'visible' } },
              content: { sx: { p: 0, '&:last-child': { p: 0 } } },
            }}
          >
            <CardMedia sx={{ aspectRatio: '1.6' }} image="content/img/landing-page/capture-1.png" />
            {/* <CardMedia
              sx={{
                position: 'absolute',
                left: '-30px',
                bottom: '-30px',
                backgroundRepeat: 'round',
                height: 100,
                width: 200,
                borderRadius: '5%',
                boxShadow: '0 4px 8px 0 grey',
              }}
              image="content/img/landing-page/user-cohort.png"
            />
            <CardMedia
              sx={{
                position: 'absolute',
                right: '-30px',
                top: '-30px',
                backgroundRepeat: 'round',
                height: 100,
                width: 200,
                borderRadius: '5%',
                boxShadow: '0 4px 8px 0 grey',
              }}
              image="content/img/landing-page/user-map.png"
            /> */}
            {/* <Image src={'content/img/landing-page/home-page.png'} style={{ borderRadius: '5%', boxShadow: '0 4px 8px 0 grey' }} /> */}
          </LayoutItem>
        </GridLayout>
      </Container>
    </LayoutItem>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'app/config/store';
import { FieldProp, validationUtils } from 'app/modules/form-builder-lib';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useLazyFindItemByIdQuery, useLazyMatchItemByQueryQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { setItemsList, setSearchLoading, setSearchText, setSearchType } from 'app/shared/reducers/views/recommendations/items';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { translate } from 'react-jhipster';

export const useSearchItemsForm = () => {
  const dispatch = useAppDispatch();
  const { data: accountProfile } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const [searchItemById, { isFetching: isSearchByIdLoading }] = useLazyFindItemByIdQuery();
  const [searchItemByQuery, { isFetching: isSearchByQueryLoading, currentData: SearchedItemsByQuery }] = useLazyMatchItemByQueryQuery();

  const searchOptions = [
    { label: translate('recommendation.items.id-search', null, 'ID search'), value: 'id' },
    { label: translate('recommendation.items.query-search', null, 'Query search'), value: 'query' },
  ];

  useEffect(() => {
    dispatch(setSearchLoading(isSearchByIdLoading || isSearchByQueryLoading));
  }, [isSearchByIdLoading || isSearchByQueryLoading]);

  const getSearchFields = (methods?): Array<FieldProp> => {
    return [
      {
        component: 'select',
        options: searchOptions,
        attribute: 'search-option',
        label: 'Type',
        optionConfig: { value: 'value', key: 'value', label: 'label' },
        containerProps: { mb: 1 },
        validationType: 'string',
        validations: [['required', true]],
        col: { xs: 6 },
      },
      {
        component: 'text-field',
        attribute: 'search-text',
        label:
          methods && methods?.watch('search-option')
            ? methods.watch('search-option') === 'id'
              ? translate('recommendation.items.id', null, 'ID')
              : methods.watch('search-option') === 'query'
              ? translate('recommendation.items.query', null, 'Query')
              : null
            : null,
        containerProps: { mb: 1 },
        validationType: 'string',
        col: { xs: 6 },
      },
    ];
  };

  const schema = validationUtils.getFormSchema(getSearchFields());
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: { 'search-option': 'query', 'search-text': '' },
  });

  const onSubmit = data => {
    if (data['search-text'] !== '')
      if (data['search-option'] === 'id') {
        searchItemById({ accountId: accountProfile?.accountId.substring(0, 8), id: data['search-text'] }).then(res => {
          dispatch(setItemsList({ content: res.data ? [res.data] : [], totalElements: 1 }));
          dispatch(setSearchType('ID : '));
          dispatch(setSearchText(data['search-text']));
        });
      } else {
        searchItemByQuery({ accountId: accountProfile?.accountId.substring(0, 8), query: data['search-text'] }).then(res => {
          dispatch(setItemsList(res.data));
          dispatch(setSearchType('Query : '));
          dispatch(setSearchText(data['search-text']));
        });
      }
  };
  return { getSearchFields, methods, onSubmit, SearchedItemsByQuery, searchItemByQuery };
};

import React from 'react';

import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { Chip, IconButton, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { AllItemsDataGrid } from './components/datagrid/AllItemsDataGrid';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Translate, translate } from 'react-jhipster';
import { SearchItemsForm } from './components/search';
import { Box } from '@mui/system';
import { useItemsManagement } from './hooks/useItemsManagement';
import { useUploadItemsFile } from './hooks/useUploadItemsFile';
import { BsFiletypeCsv, BsFiletypeJson } from 'react-icons/bs';
import { UploadItemsPopover } from './components/UploadItemsPopover';
import { DeleteForever } from '@mui/icons-material';
import { useDeleteAllItems } from './hooks/useDeleteAllItems';
import { ConfirmDeleteAllItems } from './components/ConfirmDeleteAllItems';

export const ItemsManagement = () => {
  const { itemCount } = useItemsManagement();
  const { openUploadPopover, anchorEl, fileType, handleCloseFilterPopover, setType } = useUploadItemsFile();
  const { openDeleteAllItems, setOpenDeleteAllItems } = useDeleteAllItems();

  return (
    <>
      <Stack direction="row" alignItems="center" paddingBottom={3}>
        <BreadcrumbGenerator />
      </Stack>

      <BasicCardLayout
        header={{
          title: (
            <Box display="flex" alignItems="baseline">
              <Typography variant="h4">
                <Translate contentKey="recommendation.items.all-items">All Items</Translate>
              </Typography>
              {itemCount && (
                <Chip label={`${translate('recommendation.items.total-count', null, 'Total count')}${itemCount}`} sx={{ ml: 2 }} />
              )}
            </Box>
          ),
          disableTypography: true,
          sx: {
            py: 0,
          },
          action: (
            <>
              <SearchItemsForm />

              <Tooltip title={<Translate contentKey="recommendation.items.upload-json-items">Upload Json items</Translate>}>
                <IconButton
                  color="primary"
                  sx={{ my: 'auto', ml: 5 }}
                  onClick={e => {
                    setType(e, 'json');
                  }}
                >
                  <BsFiletypeJson />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Translate contentKey="recommendation.items.upload-csv-items">Upload Csv items</Translate>}>
                <IconButton
                  color="primary"
                  sx={{ my: 'auto', mr: 2 }}
                  onClick={e => {
                    setType(e, 'csv');
                  }}
                >
                  <BsFiletypeCsv />
                </IconButton>
              </Tooltip>

              <Tooltip title={<Translate contentKey="recommendation.items.delete-all-items">Delete all items</Translate>}>
                <IconButton
                  color="error"
                  sx={{ my: 'auto', mr: 1 }}
                  disabled={!itemCount || itemCount === -1}
                  onClick={() => {
                    setOpenDeleteAllItems(true);
                  }}
                >
                  <DeleteForever />
                </IconButton>
              </Tooltip>

              <ConfirmDeleteAllItems open={openDeleteAllItems} setOpen={setOpenDeleteAllItems} />

              <Popover
                open={openUploadPopover}
                anchorEl={anchorEl}
                onClose={handleCloseFilterPopover}
                anchorOrigin={{
                  vertical: 35,
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    overflow: 'visible',
                    width: '300px',
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-7px',
                    left: '280px',
                    width: '0px',
                    height: '0px',
                    borderLeft: '5px solid transparent',
                    borderRight: '5px solid transparent',
                    borderBottom: theme => `8px solid ${theme.palette.background.paper}`,
                  }}
                />
                <UploadItemsPopover fileType={fileType} handleCloseFilterPopover={handleCloseFilterPopover} />
              </Popover>
            </>
          ),
        }}
        content={{ sx: { pt: 0, '&:last-child': { pt: 0 } } }}
        card={{ sx: { mt: 3, '.MuiCardHeader-action': { display: 'flex', flexGrow: 1 }, '.MuiCardHeader-content': { flexGrow: 4 } } }}
      >
        <AllItemsDataGrid />
      </BasicCardLayout>
    </>
  );
};

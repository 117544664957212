import React from 'react';

import { useTheme } from '@mui/material/styles';
import { ProfileSectionPresentation } from './ProfileSection';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useUserStates } from 'app/shared/layout/restricted/hooks/selectors/useUserStates';

export const ProfileSection = () => {
  const theme: any = useTheme();
  const { userProfile } = useAppAccountStates();
  const { currentUserRoles } = useUserStates();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <ProfileSectionPresentation
      theme={theme}
      anchorRef={anchorRef}
      userProfile={userProfile}
      userRoles={currentUserRoles}
      handleToggle={handleToggle}
      handleClose={handleClose}
      open={open}
    />
  );
};

import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetKpisPerKpiInTimeRangeUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { bucketingDimensions } from 'app/shared/util/data-exploration-utils';
import { useEffect } from 'react';

interface FilterValue {
  value: string;
  count: number;
  percent: number;
}

interface Filter {
  values: FilterValue[];
  operations: string[];
}

function transformEndpointResponse(endpointResponse): { [key: string]: Filter } {
  const filters: { [key: string]: Filter } = {};
  const totalCount = endpointResponse.ACTIVE_SESSION.reduce((acc, cur) => acc + cur.docCount, 0);
  for (const filterKey in endpointResponse) {
    if (Object.prototype.hasOwnProperty.call(endpointResponse, filterKey)) {
      const filter = endpointResponse[filterKey];
      const values: FilterValue[] = [];

      for (const value of filter) {
        const count = value.docCount;
        values.push({
          value: value.name ? value.name : value.countryCode,
          count,
          percent: (count / totalCount) * 100,
        });
      }

      let operations = ['PRESENT_IN_VALUES', 'ABSENT_IN_VALUES'];

      if (filterKey.endsWith('_COUNTER')) {
        operations = ['PRESENT_IN_VALUES', 'ABSENT_IN_VALUES', 'IN_VALUE_RANGE', 'NOT_IN_VALUE_RANGE', 'GREATER_THAN', 'LOWER_THAN'];
      }

      filters[filterKey] = { values, operations };
    }
  }

  return filters;
}

export const useKpisWithAllBucketingDimensions = (timerange, filter, editFilter) => {
  const { filterValues, refetch, isUninitialized } = useGetKpisPerKpiInTimeRangeUsingPostQuery(
    timerange
      ? {
          bucketingDimensions: bucketingDimensions.join() as any,
          kpis: '',
          requestFilters: {
            filterEntryList: editFilter ? filter.filter(f => f.key !== editFilter.key) : filter,
          },
          from: timerange?.fromDate,
          to: timerange?.toDate,
        }
      : skipToken,
    {
      selectFromResult(res) {
        return {
          ...res,
          filterValues: res.data ? transformEndpointResponse(res.data.data) : [],
        };
      },
    }
  );

  useEffect(() => {
    if (!isUninitialized) refetch();
  }, [timerange?.refresh]);

  return {
    filterValues,
  };
};

import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { FieldProp } from 'app/modules/form-builder-lib';
import { AccountForm } from '../account-forms/AccountForm';
import { AddPaymentMethodForm } from '../account-forms/AddPaymentMethodForm';
import { IDialogsState } from '../hooks/useAccountPage';
import { useKillBillStripe } from '../hooks/useKillBillStripe';

type AccountDialogsProps = {
  accountFormsList: {
    title: string;
    setOpen: (open: boolean) => void;
    attributes: FieldProp[];
    defaultValues: {
      [x: string]: string;
    };
    onClick: (data: any) => void;
    open: boolean;
  }[];
  dialogsState: IDialogsState;
};

export const AccountDialogs = ({ accountFormsList, dialogsState }: AccountDialogsProps): JSX.Element => {
  const { stripePromise, clientSecret, accountProfile } = useKillBillStripe();

  return (
    <>
      {accountFormsList.map(form => (
        <Dialog
          key={form.title}
          onClose={() => {
            form.setOpen(false);
          }}
          open={form.open}
          maxWidth="sm"
        >
          <AccountForm
            title={form.title}
            setOpen={form.setOpen}
            attributes={form.attributes}
            defaultValues={form.defaultValues}
            onClick={form.onClick}
          />
        </Dialog>
      ))}

      <Dialog
        onClose={() => {
          dialogsState.setOpenAddPaymentMethodeForm(false);
        }}
        open={dialogsState.openAddPaymentMethodeForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add payment method</DialogTitle>
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <AddPaymentMethodForm setOpen={dialogsState.setOpenAddPaymentMethodeForm} accountProfile={accountProfile} />
        </Elements>
      </Dialog>
    </>
  );
};

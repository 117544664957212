import React from 'react';

import { AccountStatus } from '../reducers/account-profiles/account-profile';
import { Route, Routes } from 'react-router-dom';
import { SidebarContainer } from '../layout/main-layouts/right-sidebar/components/SidebarContainer';
import { ProductsPanel } from 'app/modules/views/setup/components/products-panel';
import { useAppAccountStates } from '../layout/restricted/hooks/selectors/useAppAccountStates';

export const RightSidebarRoutes = () => {
  const { accountProfile } = useAppAccountStates();
  if (accountProfile.status === AccountStatus.BOOTSTRAP) return null;

  return (
    <Routes>
      <Route element={<SidebarContainer />}>
        <Route path={'/home'} element={<ProductsPanel />} />
      </Route>
      <Route path="*" element={null} />
    </Routes>
  );
};

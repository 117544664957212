import React from 'react';

import { East, SwitchAccount } from '@mui/icons-material';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Button, Typography } from '@mui/material';
import { Translate } from 'react-jhipster';
import { RouterLink } from 'app/config/modules/react-router-dom';
import { sitemap } from 'app/shared/util/url-utils';

export const AccountItem = () => {
  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.accounts">Accounts</Translate>
            </Typography>
          ),
          avatar: <SwitchAccount color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        actions: (
          <Button size="small" variant="outlined" component={RouterLink} to={`${sitemap.user}?selectTab=1`} endIcon={<East />}>
            <Translate contentKey="module-factory.accordion.accounts">Accounts</Translate>
          </Button>
        ),
      }}
    >
      <Typography variant="subtitle1">
        <Translate contentKey="module-factory.accordion.check-your-user-account-switch-accounts-if-needed">
          Check your user account, switch accounts if needed.
        </Translate>
      </Typography>
    </LayoutItem>
  );
};

import React from 'react';

import { UsersWithScore } from './UsersWithScore';
import { useUserExplorationTimeframeUserScores } from '../../hooks/useUserExplorationTimeframeUserScores';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate } from 'react-jhipster';
import { UsersSecondary } from './UsersSecondary';
import { Typography } from '@mui/material';
import { ErrorComponent } from 'app/modules/loading-lib/component/ErrorComponent';

export const UsersDatagridCard = () => {
  const { usersOverviewWithScore, isFetching, isError, error } = useUserExplorationTimeframeUserScores();

  return (
    <LayoutItem
      cardOptions={{
        header: {
          title: (
            <Typography variant="h4">
              <Translate contentKey="user-exploration.users-overview">Users Overview</Translate>
            </Typography>
          ),
          disableTypography: true,
          // action: <UsersSecondary />,
          sx: {
            py: 1,
            pb: 0,
          },
        },
        content: {
          sx: {
            minHeight: '200px',
          },
        },
      }}
      gridItemProps={{ xs: 12, display: 'grid' }}
    >
      {isError ? (
        <ErrorComponent error={error} />
      ) : (
        <UsersWithScore usersStats={usersOverviewWithScore} loading={isFetching} isError={isError} />
      )}
    </LayoutItem>
  );
};

import React from 'react';

import { Link, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { getAlphaColor } from 'app/shared/util/color-palette-calculator';
import { StackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import dayjs from 'dayjs';
import { FooterIcons } from './components/FooterIcons';

export const Footer = () => {
  return (
    <LayoutItem
      cardOptions={{
        variant: 'div',
        card: {
          sx: {
            backgroundColor: theme => getAlphaColor(theme.palette.primary.dark, 0.8),
          },
        },
      }}
    >
      <Container maxWidth="xl">
        <StackLayout direction="row" justifyContent="space-between" alignItems="center" marginY={2}>
          <LayoutItem
            cardOptions={{
              variant: 'div',
              content: { sx: { p: 0, '&:last-child': { p: 0 } } },
            }}
          >
            <Typography color={theme => theme.palette.primary.contrastText}>
              Copyright © {dayjs().year()} by{' '}
              <Link color="inherit" underline="hover" href="https://www.hurence.com/" target="_blank">
                Hurence
              </Link>
            </Typography>
          </LayoutItem>
          <LayoutItem
            cardOptions={{
              variant: 'div',
              content: { sx: { p: 0, '&:last-child': { p: 0 } } },
            }}
          >
            <FooterIcons />
          </LayoutItem>
        </StackLayout>
      </Container>
    </LayoutItem>
  );
};

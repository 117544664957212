import { IUsers } from '../model/users.model';

export const getSplitBySessions = (data: IUsers[], splitKpi: string) => {
  let sessionsTotal = 0;
  const split = [] as {
    key: string;
    count: number;
  }[];
  data.forEach((user: IUsers, indexUser: number) => {
    user[splitKpi].forEach(
      (
        userSplitElement: {
          key: string;
          count: number;
        },
        indexCountry: number
      ) => {
        if (
          split
            .map(splitElement => {
              return splitElement.key;
            })
            .includes(userSplitElement.key)
        ) {
          split[indexCountry] = {
            key: split[indexCountry].key,
            count: split[indexCountry].count + userSplitElement.count,
          };
          /* countrySplit[indexCountry].count = countrySplit[indexCountry].count + country.count; */
        } else split.push(userSplitElement);
      }
    );
    sessionsTotal = sessionsTotal + user.sessionCount;
  });
  return { split, total: sessionsTotal };
};

export const getSplitByUsers = (data: IUsers[], splitKpi: string) => {
  let usersTotal = 0;
  const split = [] as {
    key: string;
    count: number;
  }[];
  data.forEach((user: IUsers, indexUser: number) => {
    user[splitKpi].forEach(
      (
        userSplitElement: {
          key: string;
          count: number;
        },
        indexCountry: number
      ) => {
        if (
          split
            .map(splitElement => {
              return splitElement.key;
            })
            .includes(userSplitElement.key)
        ) {
          split[indexCountry] = {
            key: split[indexCountry].key,
            count: split[indexCountry].count + 1,
          };
        } else
          split.push({
            key: userSplitElement.key,
            count: 1,
          });
      }
    );
    usersTotal = usersTotal + 1;
  });
  return { split, total: usersTotal };
};

export const getSplit = (
  splitBy: string
): ((
  data: IUsers[],
  splitKpi: string
) => {
  split: {
    key: string;
    count: number;
  }[];
  total: number;
}) => {
  if (splitBy === 'sessions-by') return getSplitBySessions;
  else if (splitBy === 'users-by') return getSplitByUsers;
};

export const splitLimitOptions = [0, 10, 20, 50, 100];

import React from 'react';

import * as echarts from 'echarts';
import world from 'app/shared/util/world.json';
import ReactECharts from 'echarts-for-react';
import { getWorldMapData } from 'app/shared/util/worldMapData';
import { useTheme } from '@mui/material/styles';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

interface MapChartProps {
  data: any;
  selectedChart: any;
  isFetching?: boolean;
  isError: boolean;
  error: any;
}

const MapChart = ({ data, selectedChart, error, isFetching, isError }: MapChartProps) => {
  echarts.registerMap('world', world);
  const theme = useTheme();
  const option = {
    tooltip: {
      show: true,
      trigger: 'item',
      formatter(params) {
        return params.name + ' : ' + params.value;
      },
    },
    dataRange: {
      show: false,
      min: 0,
      max: 10,
      realtime: false,
      text: ['High', 'Low'],
      calculable: true,
      color: [theme.palette.primary.dark, theme.palette.primary.light],
    },
    series: {
      type: 'map',
      name: `kpi by country`,
      map: 'world',
      roam: true,
      scaleLimit: {
        max: 4,
        min: 1,
      },
      data: data
        ? data.map(element => {
            let obj = {};
            getWorldMapData().forEach(function (itemOpt) {
              if (element.countryCode === itemOpt.code)
                if (selectedChart.name === 'SESSIONS_PER_USER')
                  obj = {
                    name: itemOpt.name,
                    value: element.buckets.SESSION_COUNT.count,
                  };
                else if (selectedChart.name === 'PAGEVIEWS_PER_SESSION')
                  obj = {
                    name: itemOpt.name,
                    value: element.buckets.PAGEVIEWS_COUNT.sum,
                  };
                else
                  obj = {
                    name: itemOpt.name,
                    value: element.buckets[selectedChart.name][selectedChart.value],
                  };
            });
            return obj;
          })
        : [],
    },
  };

  return <ReactECharts style={{ height: '100%', width: '100%' }} option={option} />;
};

export default WithLoadingHOC(MapChart);

import React from 'react';

import { Stack } from '@mui/material';
import { UserKpiCard } from './UserKpiCard';
import { UserPieChart } from './UserPieChart';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetUserInfoQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-exploration';
import { useParams } from 'react-router-dom';

export const UserKpis = () => {
  const { userId } = useParams();
  const { data: userInfos } = useGetUserInfoQuery(
    userId
      ? {
          userId,
          requestFilters: {
            filterEntryList: [],
          },
        }
      : skipToken
  );

  return (
    <>
      {userInfos && userInfos.series && (
        <Stack>
          <Stack direction="row" spacing={1}>
            <UserKpiCard title={'Sessions'} value={userInfos.extendedStats.pageviewsCounterStats.count || 0} />
            <UserKpiCard title={'Events'} value={userInfos.extendedStats.eventsCounterStats.sum} />
            <UserKpiCard title={'Page Views'} value={userInfos.extendedStats.pageviewsCounterStats.sum || 0} />
            <UserKpiCard title={'Sum Sessions Duration'} value={userInfos.extendedStats.sessionDurationStats.sum || 0} />
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="center">
            <UserPieChart title={'Country'} data={userInfos.series.find(serie => serie.name === 'COUNTRY_NAME').data} />
            <UserPieChart title={'City'} data={userInfos.series.find(serie => serie.name === 'CITY_NAME').data} />
            <UserPieChart title={'First Visited Page'} data={userInfos.series.find(serie => serie.name === 'FIRST_VISITED_PAGE').data} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <UserPieChart title={'Referer'} data={userInfos.series.find(serie => serie.name === 'REFERER').data} />
            <UserPieChart title={'Device'} data={userInfos.series.find(serie => serie.name === 'USER_AGENT_DEVICE_CATEGORY').data} />
            <UserPieChart title={'Browser'} data={userInfos.series.find(serie => serie.name === 'USER_AGENT_NAME').data} />
            <UserPieChart title={'OS'} data={userInfos.series.find(serie => serie.name === 'USER_AGENT_OS_FAMILY').data} />
          </Stack>
        </Stack>
      )}
    </>
  );
};

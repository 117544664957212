import React from 'react';

import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, ToggleButtonProps } from '@mui/material';

interface ControlledToggleButtonProps extends ToggleButtonGroupProps {
  buttons: ToggleButtonProps[];
}

export const ControlledToggleButton = ({ buttons, value, onChange, ...rest }: ControlledToggleButtonProps): JSX.Element => {
  return (
    <ToggleButtonGroup value={value} onChange={onChange} {...rest}>
      {buttons && buttons.map((buttonProps, index) => <ToggleButton key={index} {...buttonProps} />)}
    </ToggleButtonGroup>
  );
};

import React from 'react';

import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { translate, Translate } from 'react-jhipster';
import { Typography, OutlinedInput, InputAdornment, IconButton, Snackbar, Alert, Collapse, AlertTitle } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNewAccessToken } from '../hooks/useNewAccessToken';
import { clearToken } from 'app/shared/reducers/account-profiles/access-token';
import { useAppDispatch } from 'app/config/store';

export const NewAccessToken = () => {
  const dispatch = useAppDispatch();
  const { token, showToken, copyToClipboard, handleClickShowToken, handleCopyToClipboard, handleCloseCopyToClipboard } =
    useNewAccessToken();

  return (
    <Collapse in={token}>
      <Alert
        variant="outlined"
        severity="success"
        sx={{ '.MuiAlert-message': { width: '100%' } }}
        onClose={() => {
          dispatch(clearToken());
        }}
      >
        <AlertTitle>
          <Typography variant="h4">
            <Translate contentKey="account.access-token.new-token-created">Your access token created successfully</Translate>
          </Typography>
        </AlertTitle>
        <BasicCardLayout
          variant="div"
          content={{
            sx: { px: 0 },
          }}
        >
          <OutlinedInput
            fullWidth
            value={token}
            readOnly
            type={showToken ? 'text' : 'password'}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowToken}
                  size="small"
                  title={
                    showToken
                      ? translate('account.access-token.hide-token', null, 'Hide token')
                      : translate('account.access-token.show-token', null, 'Show token')
                  }
                >
                  {showToken ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <IconButton
                  onClick={handleCopyToClipboard}
                  size="small"
                  title={translate('account.access-token.copy-token', null, 'Copy token')}
                >
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <Snackbar
            message={translate('account.access-token.token-copied', null, 'Token copied to clipboard')}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={2000}
            onClose={() => handleCloseCopyToClipboard()}
            open={copyToClipboard}
          />
        </BasicCardLayout>
      </Alert>
    </Collapse>
  );
};

import { Box, Snackbar, Stack } from '@mui/material';
import React from 'react';
import LiveButton from '../buttons/LiveButton';
import { RefreshRate } from '../refresh-rate/RefreshRate';
import { TimePicker } from '../time-picker/TimePicker';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface FiltersWrapperProps {
  isOpen: any;
  setIsOpen: any;
  isLive: any;
  liveClickHandler: any;
  refreshRateList: any;
  refresh: any;
  refreshRate: any;
  setRefreshRate: any;
  setRefresh: any;
  step: any;
  fromDate: string;
  setFromDate: any;
  toDate: any;
  setToDate: any;
  setStep: any;
  disabled?: any;
  chosenRange?: any;
  setChosenRange?: any;
  enableLiveButton?: boolean;
  stepOptions?: any;
  quickRanges?: any[];
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FiltersWrapper = ({
  isOpen,
  setIsOpen,
  isLive,
  liveClickHandler,
  refreshRateList,
  refresh,
  refreshRate,
  setRefreshRate,
  setRefresh,
  step,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setStep,
  disabled = false,
  chosenRange,
  setChosenRange,
  enableLiveButton = true,
  stepOptions,
  quickRanges,
}: FiltersWrapperProps) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(true);
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <Stack direction="row" alignItems="baseline">
      <TimePicker
        isLive={isLive}
        step={step}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        setStep={setStep}
        disabled={disabled}
        chosenRange={chosenRange}
        setChosenRange={setChosenRange}
        stepOptions={stepOptions}
        quickRanges={quickRanges}
      />
      <RefreshRate
        list={refreshRateList}
        isLive={isLive}
        refresh={refresh}
        refreshRate={refreshRate}
        setRefreshRate={setRefreshRate}
        setRefresh={setRefresh}
        disabled={disabled}
      />
      {enableLiveButton && <LiveButton isLive={isLive} liveClickHandler={liveClickHandler} disabled={disabled} />}
    </Stack>
  );
};

export default FiltersWrapper;

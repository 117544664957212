// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholder-marker {
  background-color: #d98c21; /* Highlight color */
  position: absolute;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/views/recommendations/widget/ace-styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAA,EAAA,oBAAA;EACA,kBAAA;EACA,UAAA;AACF","sourcesContent":[".placeholder-marker {\n  background-color: #d98c21; /* Highlight color */\n  position: absolute;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';

const accountProfilesUrl = CONFIG_MANAGER_API_URL + '/account-profiles';

export const initialState = {
  adminsLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  admins: null,
};

export type AdminsState = Readonly<typeof initialState>;

// Actions

export const getAdmins = createAsyncThunk(
  'accountProfile/getAdmins',
  async ({ accountId }: { accountId: any }) => microserviceHttpClient(`${accountProfilesUrl}/${accountId}/admins`),
  { serializeError: serializeAxiosError }
);
export const addAdmins = createAsyncThunk(
  'accountProfile/addAdmins',
  async ({ accountId, userEmail }: { accountId: any; userEmail: any }) =>
    microserviceHttpClient(`${accountProfilesUrl}/${accountId}/admins/${userEmail}`, 'put'),
  { serializeError: serializeAxiosError }
);

// Slice

export const AdminsSlice = createSlice({
  name: 'admins',
  initialState: initialState as AdminsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAdmins.pending, state => {
        state.adminsLoading = true;
        state.errorMessage = null;
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.adminsLoading = false;
        state.admins = action.payload.data;
      })
      .addCase(getAdmins.rejected, (state, action) => {
        state.adminsLoading = false;
        state.errorMessage = action.error.message;
        state.admins = null;
      })
      .addCase(addAdmins.pending, state => {
        state.adminsLoading = true;
        state.errorMessage = null;
      })
      .addCase(addAdmins.fulfilled, (state, action) => {
        state.adminsLoading = false;
      })
      .addCase(addAdmins.rejected, (state, action) => {
        state.adminsLoading = false;
        state.errorMessage = action.error.message;
      });
  },
});

// Reducer
export default AdminsSlice.reducer;

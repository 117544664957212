import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useGetAccountInvoicePaymentsUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/invoice-payments';

export const useInvoices = () => {
  const { invoiceList, accountProfile } = useAppAccountStates();
  const { data: invoicePayments = [] } = useGetAccountInvoicePaymentsUsingGetQuery(
    accountProfile.accountId ? { accountKey: accountProfile.accountId } : skipToken
  );
  const invoicesWithPayment = invoiceList.map(invoice => {
    const correspondingPayment = invoicePayments.find(payment => payment.targetInvoiceId === invoice.invoiceId);
    return { ...invoice, payment: correspondingPayment || null };
  });
  return { invoicesWithPayment };
};

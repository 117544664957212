import React from 'react';

import { Button } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import { useNavigate } from 'react-router-dom';
import { resetUserInfo } from 'app/shared/reducers/visitor-profile/userInfo';
import { Translate } from 'react-jhipster';
import { resetSessionInfo } from 'app/shared/reducers/visitor-profile/sessionInfo';

export const VisitorSecondaryAction = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      onClick={() => {
        dispatch(resetUserInfo());
        dispatch(resetSessionInfo());
        navigate('/web-analytics/audience');
      }}
      size="small"
    >
      <Translate contentKey="visitor-profile.return-to-audience">Return To Audience Page</Translate>
    </Button>
  );
};

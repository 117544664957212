import React from 'react';

import { TableRow, TableCell, Typography, Collapse, IconButton, Tooltip } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { ContentCopy, KeyboardArrowDown, KeyboardArrowUp, TimerOutlined } from '@mui/icons-material';
import { durationToString, durationFormats } from 'app/shared/util/date-utils';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { SessionInfoPopup } from './SessionInfoPopup';
import { Box } from '@mui/system';
import { SessionEvents } from './SessionEvents';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SessionMap } from './SessionMap';
import { SessionLeafletMap } from './SessionLeafletMap';

function extractLocationPath(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname;
  } catch (error) {
    console.error('Error parsing URL:', error);
    return null;
  }
}

export const SessionRowBody = ({ session, userEvents }) => {
  const { currentLocale }: any = useAppSelector(state => state.locale);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow>
        <TableCell>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {dayjs(session.firstEventEpochSeconds * 1000)
              .locale(currentLocale)
              .format('LT')}
            <Link to={extractLocationPath(session.firstVisitedPage)}> {extractLocationPath(session.firstVisitedPage)}</Link>
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex">
            <TimerOutlined fontSize="small" />{' '}
            <>{durationToString(Math.floor(Number(session.sessionDuration)), durationFormats.TRUNC_SHORT_DENSE_TIME)}</>
          </Box>
        </TableCell>
        <TableCell>{session.eventsCounter || '-'}</TableCell>
        <TableCell>{session.pageviewsCounter || '-'}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {dayjs(session.lastEventEpochSeconds * 1000)
              .locale(currentLocale)
              .format('LT')}
            <Link to={extractLocationPath(session.lastVisitedPage)}> {extractLocationPath(session.lastVisitedPage)}</Link>
          </Box>
        </TableCell>
        <TableCell>
          <CopyToClipboard text={session.sessionId} onCopy={() => session.sessionId}>
            <Tooltip title="Copy ID">
              <ContentCopy fontSize="small" sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </CopyToClipboard>
        </TableCell>
        <TableCell>
          <SessionInfoPopup session={session} />
        </TableCell>
        <TableCell align="center">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: 'flex' }}>
              {userEvents && <SessionEvents events={userEvents.filter(event => event.sessionId === session.sessionId)} />}
              <Box width="50%"> {session.geolocation && <SessionLeafletMap geoLocation={session.geolocation} />}</Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

import { ITimerange } from 'app/components/filter-wrapper/models/timerange';
import { useAppSelector } from 'app/config/store';
import { dataExplorationApi } from 'app/shared/reducers/api/data-hub-cloud-services/store/data-exploration';
import { getDataExplorationTimerange, selectAllDataExplorationFilters } from 'app/shared/reducers/views/data-exploration';
import { getKpiFromPayload, getKpiPerKpiFromPayload } from 'app/shared/util/data-hub-utils';
import { KPIS } from 'app/shared/util/reducer-utils';

export const useDataExplorationKpisHistory = () => {
  const timerange: ITimerange = useAppSelector(getDataExplorationTimerange);
  const filter = useAppSelector(selectAllDataExplorationFilters);

  const dataExplorationKpiHistoryQueryParams = {
    kpis: 'USER_COUNT,SESSION_COUNT,AVG_SESSION_DURATION,BOUNCE_RATE,ACTIVE_USER_COUNT,PAGEVIEWS_COUNT',
    requestFilters: {
      filterEntryList: filter,
    },
    from: timerange?.fromDate,
    to: timerange?.toDate,
    timeStep: timerange?.step,
  };
  const dataExplorationKpiHistoryQueryOptions = {
    pollingInterval: timerange?.refreshRate,
    selectFromResult(res) {
      return {
        ...res,
        kpisHistoryInTimerangeData: res.data
          ? {
              USER_COUNT: getKpiFromPayload(res.data, KPIS.USER_COUNT),
              PAGEVIEWS_COUNT: getKpiFromPayload(res.data, KPIS.PAGEVIEWS_COUNT),
              ACTIVE_USER_COUNT: getKpiFromPayload(res.data, KPIS.ACTIVE_USER_COUNT),
              BOUNCE_RATE: getKpiFromPayload(res.data, KPIS.BOUNCE_RATE),
              SESSION_COUNT: getKpiFromPayload(res.data, KPIS.SESSION_COUNT),
              AVG_SESSION_DURATION: getKpiFromPayload(res.data, KPIS.AVG_SESSION_DURATION),
              SESSIONS_PER_USER: getKpiPerKpiFromPayload(res.data, KPIS.SESSION_COUNT, KPIS.USER_COUNT),
              PAGEVIEWS_PER_SESSION: getKpiPerKpiFromPayload(res.data, KPIS.PAGEVIEWS_COUNT, KPIS.SESSION_COUNT),
            }
          : {
              USER_COUNT: [],
              PAGEVIEWS_COUNT: [],
              ACTIVE_USER_COUNT: [],
              BOUNCE_RATE: [],
              SESSION_COUNT: [],
              AVG_SESSION_DURATION: [],
              SESSIONS_PER_USER: [],
              PAGEVIEWS_PER_SESSION: [],
            },
      };
    },
  };
  const { kpisHistoryInTimerangeData, error, isFetching, isError } =
    dataExplorationApi.endpoints.getKpisHistoryInTimeRangeUsingPost.useQueryState(
      dataExplorationKpiHistoryQueryParams,
      dataExplorationKpiHistoryQueryOptions
    );

  return { timerange, kpisHistoryInTimerangeData, error, isFetching, isError };
};

import { Avatar, ButtonBase } from '@mui/material';
import { Menu2 } from 'tabler-icons-react';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setOpen } from 'app/shared/reducers/main-layouts/left-sidebar';

export const SideBarButton = () => {
  const dispatch = useAppDispatch();
  const { open } = useAppSelector(state => state.mainLayouts.leftSidebar);
  return (
    <ButtonBase sx={{ display: { xs: 'block', md: 'none' }, mr: 2 }}>
      <Avatar
        variant="iconButton"
        onClick={() => {
          dispatch(setOpen(!open));
        }}
      >
        <Menu2 />
      </Avatar>
    </ButtonBase>
  );
};

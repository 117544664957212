import React from 'react';

import { Chip } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { ProductNames } from 'app/shared/model/catalog.model';
import { translate } from 'react-jhipster';

export const TrialProductChip = ({ productName }: { productName: ProductNames }) => {
  const { subscribedProducts } = useAppSelector(state => state.subscriptionBundles);
  const subscription = subscribedProducts.filter(sub => sub.productName === productName);

  if (subscription.length > 0 && subscription[0].billingPeriod === 'NO_BILLING_PERIOD')
    return (
      <Chip
        variant="outlined"
        color="secondary"
        label={translate(`module-factory.${subscription[0].billingPeriod}`)}
        sx={{ fontWeight: 'bold', mx: 2, my: 1 }}
        size="small"
      />
    );
  else return null;
};

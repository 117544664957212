import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import { sitemap } from 'app/shared/util/url-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { Link } from 'react-router-dom';

export const RecommendationsStrategiesInfos = ({ algo }) => {
  return (
    <Alert severity="info" sx={{ alignSelf: 'baseline', width: '100%' }}>
      <AlertTitle>{translate(`recommendation.strategies.${algo}`)}</AlertTitle>
      <Typography my={1}>{translate(`recommendation.algos.${algo}`)}</Typography>
      <Button component={Link} to={`${sitemap.recommendationAlgorithms}`} target="_blank">
        {translate(`recommendation.see-more`)}
      </Button>
    </Alert>
  );
};

import { useAppSelector } from 'app/config/store';
import { useGetTimeframeUserScoresUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-scores';

export const usePieChartsData = () => {
  const { params, splitBy } = useAppSelector(state => state.webAnalyticsOverview.pieCharts);

  const { data = null } = useGetTimeframeUserScoresUsingPostQuery({
    from: params.from,
    to: params.to,
    scoreBodyDto: { filters: { filterEntryList: params.filter } },
  });

  return {
    data,
    splitBy,
  };
};

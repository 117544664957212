import { createSelector } from '@reduxjs/toolkit';
import { usePersistedForm } from 'app/modules/form-builder-lib/Hooks/usePersistedForm';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { demonstrationOfInterestActions, conversionActions } from '../utils/actions-types';

export const usePresistedRecommendationsKpisForm = () => {
  const { form: formParams, selector: selectParams } = usePersistedForm(
    'forms/recoHome/params',
    { corpusId: undefined, demonstrationOfInterestActions, conversionActions },
    {}
  );

  const selectArgs = createSelector(selectParams, (...selectedSliceStates) => ({
    ...selectedSliceStates[0],
  }));

  const requestParams = useSelector(selectArgs);

  useEffect(() => {
    let timer: number;
    const subscription = formParams.methods.watch(() => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        formParams.onSubmitHandler();
      }, 1000);
    });
    return () => {
      subscription.unsubscribe();
      clearTimeout(timer);
    };
  }, [formParams.methods.watch]);

  return { requestParams, formParams };
};

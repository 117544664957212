import React from 'react';

import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Typography } from '@mui/material';
import { Subscriptions } from '@mui/icons-material';
import { Translate } from 'react-jhipster';
import { SubscriptionDataGrid } from 'app/modules/views/account-page/components/data-grids/SubscriptionDataGrid';
import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';

export const SubscriptionsItem = () => {
  const { subscriptionsList } = useSubscriptionsStates();
  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.subscriptions">Subscriptions</Translate>
            </Typography>
          ),
          avatar: <Subscriptions color="primary" />,
          disableTypography: true,
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <SubscriptionDataGrid subscriptionsList={subscriptionsList} />
    </LayoutItem>
  );
};

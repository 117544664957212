import React from 'react';

import { StackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { BetaProgram } from './sections/BetaProgram';
import { Footer } from './sections/Footer';
import { OtherServices } from './sections/OtherServices';
import { ProductList } from './sections/ProductList';
import { ScoringJoeSummary } from './sections/ScoringJoeSummary';
import { WhyScoringJoe } from './sections/WhyScoringJoe';

export const variables = {
  productName: 'ScoringJoe',
};

export const LandingPage = () => {
  return (
    <StackLayout spacing={0}>
      <ScoringJoeSummary />
      <WhyScoringJoe />
      <ProductList />
      <BetaProgram />
      <OtherServices />
      <Footer />
    </StackLayout>
  );
};

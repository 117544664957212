import React, { useContext } from 'react';

import { ToggleButtonProps } from '@mui/material';
import { Plan } from 'app/shared/model/catalog.model';
import { Translate } from 'react-jhipster';
import { ProductContext, ProductContextType } from '../../context/ProductContext';
import { ControlledToggleButton } from 'app/components/buttons/ControlledToggleButton';

export const PlanToggleButton = () => {
  const { plan, setPlan, product } = useContext<ProductContextType>(ProductContext);

  const buttons: ToggleButtonProps[] = product?.plans.map((_plan: Plan) => {
    return { value: _plan, children: <Translate contentKey={`products.${_plan.billingPeriod}`}>{_plan.billingPeriod}</Translate> };
  });

  return (
    <ControlledToggleButton
      buttons={buttons}
      value={plan}
      onChange={(_event, value) => value !== null && setPlan(value)}
      size="small"
      color="primary"
      sx={{ m: 1 }}
      exclusive
    />
  );
};

import React, { DetailedHTMLProps, Fragment } from 'react';

import { HelpOutline } from '@mui/icons-material';
import { Box, BoxProps, IconButton, Stack, Tooltip, TooltipProps, Typography, TypographyProps } from '@mui/material';
import { Validation } from '../props/FieldProps';

export interface TitleProps {
  title?: string;
  titleTooltip?: string;
  titleProps?: TypographyProps;
  titleContainerProps?: BoxProps;
  titleTooltipProps?: TooltipProps;
  titleSuffixComponent?: React.ReactNode;
  titleSuffix?: string;
  titleSuffixProps?: DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
  titleRequiredMarker?: boolean;
  titleOptionalMarker?: boolean;
  validations?: Array<Validation>;
}

const Title = (props: { field: TitleProps }) => {
  const {
    field: {
      title,
      titleTooltip,
      titleProps,
      titleContainerProps,
      titleTooltipProps,
      titleSuffixComponent,
      titleSuffix,
      titleSuffixProps,
      titleRequiredMarker,
      titleOptionalMarker,
      validations,
    },
  } = props;

  const isRequired = validations && validations.filter(f => f[0] === 'required').length > 0;

  return (
    <Box
      {...titleContainerProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 1,
        ...titleContainerProps?.sx,
      }}
    >
      <Stack direction="row" spacing={0.5}>
        {titleRequiredMarker && isRequired && <Typography color="red">*</Typography>}
        <Typography {...titleProps}>
          {title?.split('\n').map((text, index) => {
            if (index === 0) {
              return <span key={index}>{text}</span>;
            }
            return (
              <Fragment key={index}>
                <br />
                <span>{text}</span>
              </Fragment>
            );
          })}{' '}
          {titleSuffixComponent || (titleSuffix && <span {...titleSuffixProps}>{titleSuffix}</span>)}
        </Typography>
      </Stack>
      <Box flexGrow={1}></Box>
      {titleOptionalMarker && !isRequired && <Typography color="gray">(Optional)</Typography>}
      {titleTooltip && (
        <Tooltip {...titleTooltipProps} title={titleTooltip} sx={{ ...titleTooltipProps?.sx }}>
          <IconButton>
            <HelpOutline fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export { Title };

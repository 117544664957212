import { emptyDataHubCloudServicesApi as api } from '../emptyDataHubCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    computeUserScoresUsingPost: build.mutation<ComputeUserScoresUsingPostApiResponse, ComputeUserScoresUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/scores/users/compute`,
        method: 'POST',
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
          from: queryArg['from'],
          to: queryArg.to,
          timeStep: queryArg.timeStep,
          ownerAccount: queryArg.ownerAccount,
        },
      }),
    }),
    getLifetimeUserScoresUsingPost: build.mutation<GetLifetimeUserScoresUsingPostApiResponse, GetLifetimeUserScoresUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/scores/users/lifetime`,
        method: 'POST',
        body: queryArg.scoreWeightsDto,
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
          to: queryArg.to,
          bundleId: queryArg.bundleId,
          limit: queryArg.limit,
        },
      }),
    }),
    getTimeframePercentilesUsingPost: build.mutation<GetTimeframePercentilesUsingPostApiResponse, GetTimeframePercentilesUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/scores/users/percentiles`,
        method: 'POST',
        body: queryArg.scoreBodyDto,
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
          from: queryArg['from'],
          to: queryArg.to,
          bundleId: queryArg.bundleId,
        },
      }),
    }),
    getTimeframeUserScoresUsingPost: build.query<GetTimeframeUserScoresUsingPostApiResponse, GetTimeframeUserScoresUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/scores/users/timeframe`,
        method: 'POST',
        body: queryArg.scoreBodyDto,
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
          from: queryArg['from'],
          to: queryArg.to,
          bundleId: queryArg.bundleId,
          limit: queryArg.limit,
        },
      }),
      extraOptions: { maxRetries: 3 },
      providesTags: ['USER_PROFILE'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userScoresApi };
export type ComputeUserScoresUsingPostApiResponse = unknown;
export type ComputeUserScoresUsingPostApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** from */
  from: string;
  /** to */
  to: string;
  /** timeStep */
  timeStep?: string;
  /** ownerAccount */
  ownerAccount?: string;
};
export type GetLifetimeUserScoresUsingPostApiResponse = /** status 200 OK */ UserScore[] | /** status 201 Created */ undefined;
export type GetLifetimeUserScoresUsingPostApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** to */
  to: string;
  /** bundleId */
  bundleId?: string;
  /** limit */
  limit?: string;
  scoreWeightsDto: ScoreWeightsDto;
};
export type GetTimeframePercentilesUsingPostApiResponse =
  /** status 200 OK */
  PairOfstringAndMapOfdoubleAnddouble[] | /** status 201 Created */ undefined;
export type GetTimeframePercentilesUsingPostApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** from */
  from: string;
  /** to */
  to: string;
  /** bundleId */
  bundleId?: string;
  scoreBodyDto: ScoreBodyDto;
};
export type GetTimeframeUserScoresUsingPostApiResponse = /** status 200 OK */ UserScore[] | /** status 201 Created */ undefined;
export type GetTimeframeUserScoresUsingPostApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** from */
  from: string;
  /** to */
  to: string;
  /** bundleId */
  bundleId?: string;
  /** limit */
  limit?: string;
  scoreBodyDto: ScoreBodyDto;
};
export type TermSplit = {
  count?: number;
  key?: string;
};
export type Number = object;
export type MatchStats = {
  avg?: object;
  sigma?: object;
  sigma2?: object;
  sigma3?: object;
};
export type UserScore = {
  bounceCount?: number;
  bounceScore?: number;
  brandScore?: number;
  browserFamilySplit?: TermSplit[];
  bundleId?: string;
  citySplit?: TermSplit[];
  clickDepthScore?: MatchStats;
  continentSplit?: TermSplit[];
  countrySplit?: TermSplit[];
  deviceCategorySplit?: TermSplit[];
  durationScore?: MatchStats;
  engagementScore?: number;
  eventsCounterSum?: number;
  firstUserVisitEpochSeconds?: number;
  firstUserVisitEpochSecondsDistinct?: number;
  firstVisitedPageSplit?: TermSplit[];
  fqdnSplit?: TermSplit[];
  frequencyScore?: number;
  id?: string;
  isNewUser?: boolean;
  lastEventEpochSeconds?: number;
  lastVisitedPageSplit?: TermSplit[];
  matchBrand?: number;
  matchClickDepth?: MatchStats;
  matchDuration?: MatchStats;
  osFamilySplit?: TermSplit[];
  ownerAccount?: string;
  pageviewsCounterSum?: number;
  refererSplit?: TermSplit[];
  returningScore?: number;
  sessionCount?: number;
  sessionDurationSum?: number;
  timeSinceLastVisit?: number;
  timestamp?: number;
  userId?: string;
};
export type ScoreWeightsDto = {
  bounceWeight?: number;
  brandWeight?: number;
  clickDepthStat?: 'AVG' | 'SIGMA' | 'SIGMA2' | 'SIGMA3';
  clickDepthWeight?: number;
  durationStat?: 'AVG' | 'SIGMA' | 'SIGMA2' | 'SIGMA3';
  durationWeight?: number;
  frequencyWeight?: number;
  returningWeight?: number;
  total?: number;
};
export type PairOfstringAndMapOfdoubleAnddouble = {
  first?: string;
  second?: {
    [key: string]: number;
  };
};
export type RequestFilterEntry = {
  filteringKind?: 'ABSENT_IN_VALUES' | 'GREATER_THAN' | 'IN_VALUE_RANGE' | 'LOWER_THAN' | 'NOT_IN_VALUE_RANGE' | 'PRESENT_IN_VALUES';
  key?:
    | 'ACTIVE_SESSION'
    | 'CITY_ID'
    | 'CITY_NAME'
    | 'CONTINENT_CODE'
    | 'CONTINENT_ID'
    | 'CONTINENT_NAME'
    | 'COUNTRY_CODE'
    | 'COUNTRY_ID'
    | 'COUNTRY_NAME'
    | 'EVENT_COUNTER'
    | 'FIRST_EVENT_EPOCH_SECONDS'
    | 'FIRST_USER_VISIT_EPOCH_SECONDS'
    | 'FIRST_VISITED_PAGE'
    | 'INTRANET_IP'
    | 'INTRANET_TAGGING'
    | 'IS_FIRST_SESSION_OF_USER'
    | 'LAST_EVENT_EPOCH_SECONDS'
    | 'LAST_VISITED_PAGE'
    | 'METRO_CODE'
    | 'MOST_SPECIFIC_SUBDIVISION_CODE'
    | 'MOST_SPECIFIC_SUBDIVISION_ID'
    | 'MOST_SPECIFIC_SUBDIVISION_NAME'
    | 'PAGEVIEWS_COUNTER'
    | 'POSTAL_CODE'
    | 'REFERER'
    | 'REFERER_HOSTNAME'
    | 'REMOTE_HOST'
    | 'REMOTE_HOST_FQDN'
    | 'SESSION_ID'
    | 'SOURCE_OF_TRAFFIC_CAMPAIGN'
    | 'SOURCE_OF_TRAFFIC_CONTENT'
    | 'SOURCE_OF_TRAFFIC_KEYWORD'
    | 'SOURCE_OF_TRAFFIC_MEDIUM'
    | 'SOURCE_OF_TRAFFIC_ORGANIC_SEARCH'
    | 'SOURCE_OF_TRAFFIC_REFERRAL_PATH'
    | 'SOURCE_OF_TRAFFIC_SOURCE'
    | 'SUBDIVISION_CODES'
    | 'SUBDIVISION_IDS'
    | 'SUBDIVISION_NAMES'
    | 'TIMEZONE'
    | 'USER_AGENT_DEVICE_CATEGORY'
    | 'USER_AGENT_FAMILY'
    | 'USER_AGENT_NAME'
    | 'USER_AGENT_OS_FAMILY'
    | 'USER_AGENT_OS_VENDOR'
    | 'USER_AGENT_OS_VERSION'
    | 'USER_AGENT_TYPE'
    | 'USER_AGENT_VENDOR'
    | 'USER_AGENT_VERSION'
    | 'USER_ID'
    | 'UTM_CAMPAIGN'
    | 'UTM_CONTENT'
    | 'UTM_MEDIUM'
    | 'UTM_SOURCE'
    | 'UTM_TERM';
  value?: object;
};
export type RequestFilters = {
  filterEntryList?: RequestFilterEntry[];
};
export type ScoreBodyDto = {
  filters?: RequestFilters;
  scoreWeights?: ScoreWeightsDto;
};
export const {
  useComputeUserScoresUsingPostMutation,
  useGetLifetimeUserScoresUsingPostMutation,
  useGetTimeframePercentilesUsingPostMutation,
  useGetTimeframeUserScoresUsingPostQuery,
} = injectedRtkApi;

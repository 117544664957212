import { patterns, sitemap } from 'app/shared/util/url-utils';
import React, { ReactNode } from 'react';
import { HomeOutlined, Analytics, Recommend, BubbleChart } from '@mui/icons-material';

import { Translate, translate } from 'react-jhipster';
import { generatePath } from 'react-router-dom';
import { useModuleState } from 'app/modules/views/module-root/hooks/useModuleState';
import { useWebAnalyticsStatus } from 'app/modules/views/module-root/components/accordion/web-analytics/hooks/useWebAnalyticsStatus';

export type RouteType = {
  element: ReactNode;
  state: string;
  index?: boolean;
  path?: string;
  child?: RouteType[];
  sidebarProps?: {
    displayText: string;
    icon?: ReactNode;
  };
};

const appRoutes = () => {
  const { isSubscribedProduct } = useModuleState();
  const status = useWebAnalyticsStatus();

  return [
    {
      path: sitemap.home,
      sidebarProps: {
        displayText: <Translate contentKey="module-factory.home">Home</Translate>,
        icon: <HomeOutlined />,
      },
    },
    ...(isSubscribedProduct('web-analytics')
      ? [
          {
            path: 'web-analytics',
            sidebarProps: {
              displayText: <Translate contentKey="module-factory.web-analytics">Web Analytics</Translate>,
              icon: <Analytics />,
            },
            child: [
              ...(status && status.startsWith('READY')
                ? [
                    {
                      path: generatePath(patterns.home, { moduleName: 'web-analytics' }),
                      sidebarProps: {
                        displayText: <Translate contentKey="module-factory.overview">Overview</Translate>,
                      },
                    },
                    {
                      path: sitemap['web-analytics'].audience,
                      sidebarProps: {
                        displayText: <Translate contentKey="module-factory.audience">Audience</Translate>,
                      },
                    },
                    {
                      path: sitemap['web-analytics'].sessions,
                      sidebarProps: {
                        displayText: <Translate contentKey="module-factory.sessions">Sessions</Translate>,
                      },
                    },
                    {
                      path: sitemap['web-analytics'].export,
                      sidebarProps: {
                        displayText: <Translate contentKey="module-factory.export-data">Export Data</Translate>,
                      },
                    },
                    {
                      path: sitemap['web-analytics'].tagManager,
                      sidebarProps: {
                        displayText: <Translate contentKey="module-factory.tag-manager">Tag Manager</Translate>,
                      },
                    },
                    {
                      path: generatePath(sitemap['web-analytics'].settings),
                      sidebarProps: {
                        displayText: translate('module-factory.settings', null, 'Settings'),
                      },
                    },
                  ]
                : [
                    {
                      path: generatePath(sitemap['web-analytics'].settings),
                      sidebarProps: {
                        displayText: translate('module-factory.settings', null, 'Settings'),
                      },
                    },
                  ]),
            ],
          },
        ]
      : []),
    ...(isSubscribedProduct('recommendations')
      ? [
          {
            path: 'recommendations',
            sidebarProps: {
              displayText: <Translate contentKey="module-factory.recommendations">Recommendations</Translate>,
              icon: <Recommend />,
            },
            child: [
              {
                path: generatePath(patterns.home, { moduleName: 'recommendations' }),
                sidebarProps: {
                  displayText: <Translate contentKey="module-factory.overview">Overview</Translate>,
                },
              },
              {
                path: sitemap.recommendations.strategies,
                sidebarProps: {
                  displayText: <Translate contentKey="module-factory.strategies">Strategies</Translate>,
                },
              },
              {
                path: sitemap.recommendations.corpus,
                sidebarProps: {
                  displayText: <Translate contentKey="module-factory.corpus">Corpus</Translate>,
                },
              },
              {
                path: sitemap.recommendations.itemsManagement,
                sidebarProps: {
                  displayText: <Translate contentKey="module-factory.items">Items</Translate>,
                },
              },
              {
                path: sitemap.recommendations.widget,
                sidebarProps: {
                  displayText: <Translate contentKey="module-factory.widget">Widget</Translate>,
                },
              },
            ],
          },
        ]
      : []),
    ...(isSubscribedProduct('greensights')
      ? [
          {
            path: sitemap.greensights.home,
            sidebarProps: {
              displayText: <Translate contentKey="module-factory.greensights">Greensights</Translate>,
              icon: <BubbleChart />,
            },
          },
        ]
      : []),
  ];
};

export default appRoutes;

const greensights = [
  {
    id: 'board',
    title: 'board',
    type: 'item',
    url: '/greensights/board',
  },
];

export default greensights;

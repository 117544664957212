import { MenuItem, Select, ToggleButton, ToggleButtonGroup, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { translate } from 'react-jhipster';
import { useTheme } from '@mui/material/styles';
import { numberTimestepOption, percentageToggleButtonOption, timestepToggleButtonOption } from './cohortOptions';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getUserExplorationCohortState,
  setUserExplorationCohortState,
  setUserExplorationSelectedCohortCell,
  setUserExplorationSelectedCellDates,
} from 'app/shared/reducers/views/user-exploration';

const UserCohortSecondary = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const cohortState = useAppSelector(getUserExplorationCohortState);
  return (
    <Stack direction="row" alignItems="center">
      <ToggleButtonGroup
        size="small"
        exclusive
        value={cohortState.displayedValueType}
        onChange={(event, currentAccount) => {
          if (currentAccount !== null)
            dispatch(
              setUserExplorationCohortState({
                ...cohortState,
                displayedValueType: currentAccount,
              })
            );
        }}
        sx={{
          marginRight: '5px',
        }}
      >
        {percentageToggleButtonOption.map(toggleButton => {
          return (
            <ToggleButton
              key={toggleButton.id}
              sx={{
                textTransform: 'none',
                paddingBlock: 0,
              }}
              disableRipple={true}
              value={toggleButton.value}
              color="primary"
            >
              {translate(`user-exploration.cohort.${toggleButton.text}`)}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <ToggleButtonGroup
        size="small"
        exclusive
        value={cohortState.timeStep}
        onChange={(event, currentAccount) => {
          if (currentAccount !== null) {
            dispatch(
              setUserExplorationCohortState({
                ...cohortState,
                timeStep: currentAccount,
              })
            );
            dispatch(setUserExplorationSelectedCohortCell([]));
            dispatch(setUserExplorationSelectedCellDates([]));
          }
        }}
      >
        {timestepToggleButtonOption.map(toggleButton => {
          return (
            <ToggleButton
              key={toggleButton.id}
              sx={{ textTransform: 'none', paddingBlock: 0 }}
              disableRipple={true}
              value={toggleButton.value}
              color="primary"
            >
              {translate(`user-exploration.cohort.${toggleButton.text}`)}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <Tooltip title={translate('web-analytics.select-step', null, 'Select Step')}>
        <Select
          sx={{
            '& .MuiOutlinedInput-input': {
              p: 0.2,
              minWidth: 5,
            },
            '& .MuiSelect-select': {
              pr: 2,
              pl: 0.5,
              color: theme.palette.primary.main,
              fontSize: '0.825rem',
              fontWeight: '500',
            },
            '& .MuiList-root .MuiMenu-list': {
              p: `0 !important`,
            },
          }}
          value={cohortState.stepNumber}
          onChange={e => {
            dispatch(setUserExplorationCohortState({ ...cohortState, stepNumber: Number(e.target.value) }));
            dispatch(setUserExplorationSelectedCohortCell([]));
            dispatch(setUserExplorationSelectedCellDates([]));
          }}
          color="primary"
        >
          {numberTimestepOption.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </Stack>
  );
};

export default UserCohortSecondary;

import React, { useState } from 'react';

import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { PaymentDataGrid } from './payment/PaymentDataGrid';
import { TransactionsDataGrid } from './payment/TransactionsDataGrid';
import { TransactionProperties } from './payment/TransactionProperties';
import { PaymentMethod } from './payment/PaymentMethod';
import { Translate } from 'react-jhipster';

export const PaymentDetails = ({ payment, setOpen }) => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([payment.transactions[0].transactionId]);

  return (
    <>
      <DialogTitle typography="h4">
        <Translate contentKey="account.payment-details">Payment Details</Translate>
      </DialogTitle>
      <DialogContent dividers>
        <Stack>
          <PaymentDataGrid payment={payment} />
          <TransactionsDataGrid payment={payment} rowSelectionModel={rowSelectionModel} setRowSelectionModel={setRowSelectionModel} />
          <TransactionProperties payment={payment} rowSelectionModel={rowSelectionModel} />
          <PaymentMethod payment={payment} />
        </Stack>
      </DialogContent>
    </>
  );
};

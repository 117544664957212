import React from 'react';
import {
  LibraryBooksTwoTone,
  TimelapseTwoTone,
  CurrencyExchangeTwoTone,
  LocalPhoneTwoTone,
  BusinessTwoTone,
  AccountBalanceTwoTone,
  AccountBalanceWalletTwoTone,
} from '@mui/icons-material';
import { InputAdornment, ListItemIcon, ListItemText, ListItemSecondaryAction, Typography, ListItemButton } from '@mui/material';
import { translate } from 'react-jhipster';
import dayjs from 'dayjs';
import { Box } from '@mui/system';
import { FieldProp } from 'app/modules/form-builder-lib';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';

const localeOptions = ['en_US', 'fr_FR'];

export const EditAttributes = (): Array<FieldProp> => {
  const { billingAccount } = useAppAccountStates();
  return [
    {
      component: 'text-field',
      attribute: 'name',
      label: 'account.account-page.name',
      validationType: 'string',
      props: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <LibraryBooksTwoTone />
            </InputAdornment>
          ),
        },
      },
      validations: [
        ['required', true],
        ['matches', [/[a-z]/i, 'Can only contain letters']],
      ],
      col: {
        md: 6,
      },
    },
    {
      component: 'custom',
      attribute: 'since',
      customComponent() {
        return (
          <ListItemButton divider>
            <ListItemIcon>
              <TimelapseTwoTone fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('account.account-page.since')} />
            <ListItemSecondaryAction>
              <Typography variant="subtitle2" color="textSecondary">
                {billingAccount.referenceTime ? dayjs(billingAccount.referenceTime).fromNow() : '-'}
              </Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      },
      col: {
        md: 6,
      },
    },
    {
      component: 'text-field',
      attribute: 'company',
      label: 'account.account-page.company',
      props: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <BusinessTwoTone />
            </InputAdornment>
          ),
        },
      },
      col: {
        md: 6,
      },
    },
    {
      component: 'custom',
      attribute: 'currency',
      customComponent() {
        return (
          <ListItemButton divider>
            <ListItemIcon>
              <CurrencyExchangeTwoTone fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('account.account-page.currency')} />
            <ListItemSecondaryAction>
              <Typography variant="subtitle2" color="textSecondary">
                {billingAccount.currency ? billingAccount.currency : '-'}
              </Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      },
      col: {
        md: 6,
      },
    },

    {
      component: 'text-field',
      attribute: 'phone',
      label: 'account.account-page.phone',
      props: {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <LocalPhoneTwoTone />
            </InputAdornment>
          ),
        },
      },
      col: {
        md: 6,
      },
    },

    {
      component: 'custom',
      attribute: 'balance',
      customComponent() {
        return (
          <ListItemButton divider>
            <ListItemIcon>
              <AccountBalanceTwoTone fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('account.account-page.balance')} />
            <ListItemSecondaryAction>
              <Typography variant="subtitle2" color="textSecondary">
                {billingAccount.accountBalance ? billingAccount.accountBalance : '-'}
              </Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      },
      col: {
        md: 6,
      },
    },
    {
      component: 'autocomplete',
      options: localeOptions,
      attribute: 'locale',
      label: 'account.account-page.locale',
      props: {
        renderOption: (props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {translate(`language.${option}`)}
          </Box>
        ),
        getOptionLabel(option) {
          if (option !== '') return translate(`language.${option}`);
          else return '';
        },
      },
      col: {
        md: 6,
      },
    },
    {
      component: 'custom',
      attribute: 'credit',
      customComponent() {
        return (
          <ListItemButton divider>
            <ListItemIcon>
              <AccountBalanceWalletTwoTone fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('account.account-page.credit')} />
            <ListItemSecondaryAction>
              <Typography variant="subtitle2" color="textSecondary">
                {billingAccount.accountCBA ? billingAccount.accountCBA : '-'}
              </Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      },
      col: {
        md: 6,
      },
    },
  ];
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { buildAccounts } from '../util/accounts-utils';
import { getBillingAccount } from './billing-account/billing-account';

const userProfilesUrl = CONFIG_MANAGER_API_URL + '/user-profiles';

export interface UserProfile {
  auditInfo: any;
  keycloakId: string;
  email: string;
  firstName: string;
  lastName: string;
  accounts: any[];
  currentAccount: string;
  locale: string;
  timeZone: string;
  theme: string;
}

export interface AccountInfo {
  auditInfo: any;
  accountId: string;
  name: string;
  status: string;
  roles: string[];
}

export const initialState = {
  userProfileLoading: true,
  errorMessage: null as unknown as string, // Errors returned from server side
  userProfile: {} as UserProfile,
  allUserAccounts: [] as AccountInfo[],
  currentUserRoles: [] as Array<any>,
};

export type UserProfileState = Readonly<typeof initialState>;

// Actions

export const getUserProfile = createAsyncThunk(
  'userProfile/getUserProfile',
  async () => microserviceHttpClient(`${userProfilesUrl}/current`),
  { serializeError: serializeAxiosError }
);

export const getUserAccounts = createAsyncThunk(
  'userProfile/getUserAccounts',
  async () => microserviceHttpClient(`${userProfilesUrl}/current/accounts`),
  { serializeError: serializeAxiosError }
);

export const setCurrentAccount = createAsyncThunk(
  'userProfile/setCurrentAccount',
  async ({ email, accountId }: { email: string; accountId: string }, thunkAPI) =>
    microserviceHttpClient(`${userProfilesUrl}/${email}/accounts/${accountId}/setCurrent`, 'put').then(resp => {
      /* thunkAPI.dispatch(getUserProfile());
      thunkAPI.dispatch(getBillingAccount({ accountId })); */
    }),
  { serializeError: serializeAxiosError }
);

// Slice

export const UserProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialState as UserProfileState,
  reducers: {
    resetUserProfile(state) {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserProfile.pending, state => {
        state.userProfileLoading = true;
        state.errorMessage = null;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfileLoading = false;
        const userProfile = action.payload.data;
        state.userProfile = userProfile;
        const accounts = userProfile.accounts ? userProfile.accounts.filter(acc => acc.accountId === userProfile.currentAccount) : [];
        const firstAccountInfo = accounts.length > 0 ? accounts[0] : {};
        state.currentUserRoles = firstAccountInfo.roles ? firstAccountInfo.roles : initialState.currentUserRoles;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.userProfileLoading = false;
        state.errorMessage = action.error.message;
        state.userProfile = initialState.userProfile;
        state.currentUserRoles = initialState.currentUserRoles;
      })
      .addCase(setCurrentAccount.pending, state => {
        state.userProfileLoading = true;
        state.errorMessage = null;
        /* state.userProfile.currentAccount = null; */
      })
      .addCase(setCurrentAccount.fulfilled, (state, action) => {
        state.userProfileLoading = false;
      })
      .addCase(setCurrentAccount.rejected, (state, action) => {
        state.userProfileLoading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getUserAccounts.pending, state => {
        state.userProfileLoading = true;
        state.errorMessage = null;
      })
      .addCase(getUserAccounts.fulfilled, (state, action) => {
        state.userProfileLoading = false;
        state.allUserAccounts = buildAccounts(state.userProfile.accounts, action.payload.data);
      })
      .addCase(getUserAccounts.rejected, (state, action) => {
        state.userProfileLoading = false;
        state.errorMessage = action.error.message;
        state.allUserAccounts = initialState.allUserAccounts;
      });
  },
});

export const { resetUserProfile } = UserProfileSlice.actions;

// Reducer
export default UserProfileSlice.reducer;

import React from 'react';

import { useAppSelector } from 'app/config/store';
import {
  addDataExplorationFilter,
  deleteAllDataExplorationFilters,
  deleteDataExplorationFilter,
  getDataExplorationTimerange,
  selectAllDataExplorationFilters,
  selectDataExplorationFiltersIds,
} from 'app/shared/reducers/views/data-exploration';
import { RequestFilterManager } from 'app/components/request-filter/RequestFilterManager';

export const DataExplorationRequestFilters = () => {
  const timerange = useAppSelector(getDataExplorationTimerange);
  const filter = useAppSelector(selectAllDataExplorationFilters);

  return (
    <RequestFilterManager
      timerange={timerange}
      filters={filter}
      filterActions={{
        deleteFilter: deleteDataExplorationFilter,
        addFilter: addDataExplorationFilter,
        deleteFilters: deleteAllDataExplorationFilters,
        selectAllFilters: selectAllDataExplorationFilters,
        selectFiltersIds: selectDataExplorationFiltersIds,
      }}
    />
  );
};

import React from 'react';
import { useAppDispatch } from 'app/config/store';
import { setOpenAll } from 'app/shared/reducers/views/visitor-profile';
import { Button } from '@mui/material';
import { UnfoldLessDouble, UnfoldMoreDouble } from '@mui/icons-material';

export const SessionsActions = () => {
  const dispatch = useAppDispatch();
  const onExpandAllHandler = () => {
    dispatch(setOpenAll(null));
    dispatch(setOpenAll(true));
  };
  const onCollapseAllHandler = () => {
    dispatch(setOpenAll(null));
    dispatch(setOpenAll(false));
  };
  return (
    <>
      <Button startIcon={<UnfoldMoreDouble />} onClick={onExpandAllHandler} sx={{ mr: 2 }}>
        Expand All
      </Button>
      <Button startIcon={<UnfoldLessDouble />} onClick={onCollapseAllHandler}>
        Collapse All
      </Button>
    </>
  );
};

import { Button, Step, StepButton, StepContent, StepLabel, Stepper, StepperProps } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import React from 'react';
import { StepperState, useStepperState } from './useStepperState';

export type StepProps = {
  label: string | React.ReactNode;
  optional?: React.ReactNode;
  actions?: (index: number, stepperState: StepperState) => React.ReactNode;
  children: React.ReactNode;
};

interface WizardStepperProps extends StepperProps {
  steps: StepProps[];
  useComplete?: () => any;
}

export const WizardStepper = ({ steps, ...rest }: WizardStepperProps) => {
  const stepperState = useStepperState(steps);
  const {
    activeStep,
    methods: { handleStep, isStepCompleted },
  } = stepperState;

  function buildLabelComponent(step: any, index: number): React.ReactNode {
    return rest.nonLinear ? (
      <StepButton color="inherit" onClick={handleStep(index)} optional={step.optional}>
        {step.label}
      </StepButton>
    ) : (
      <StepLabel optional={step.optional}>{step.label}</StepLabel>
    );
  }

  return (
    <Stepper activeStep={activeStep} {...rest}>
      {steps.map((step, index) => {
        return (
          <Step key={index} completed={isStepCompleted(index)}>
            {buildLabelComponent(step, index)}
            <StepContent>
              <BasicCardLayout variant="div" actions={step.actions?.(index, stepperState)}>
                {step.children}
              </BasicCardLayout>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
};

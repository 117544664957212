import React, { useContext, useEffect } from 'react';

import { Typography, Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { ProductPlan } from './ProductPlan';
import { PlanToggleButton } from './PlanToggleButton';
import { SubscriptionButton } from './SubscriptionButton';
import { Translate } from 'react-jhipster';
import { ProductContext, ProductContextType } from '../../context/ProductContext';
import { useLocation } from 'react-router-dom';

export const ProductSubscription = () => {
  const { isProductDialogOpen, product, resetProductDialog, setProduct } = useContext<ProductContextType>(ProductContext);

  const location = useLocation();

  useEffect(() => {
    if (location.state?.product) setProduct(location.state.product);
  }, []);

  return (
    <Dialog onClose={resetProductDialog} open={isProductDialogOpen} sx={{ textAlign: 'center', m: 2 }} fullWidth={true} maxWidth="md">
      <DialogTitle>
        <Typography variant="h4" m={1} key={product?.name}>
          {product?.prettyName.toUpperCase()}{' '}
          <Translate contentKey="products.plans" key={product?.name}>
            Plans
          </Translate>
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <PlanToggleButton />
        <ProductPlan />
      </DialogContent>

      <DialogActions>
        <Button color="error" onClick={() => resetProductDialog()}>
          <Translate contentKey="products.cancel">Cancel</Translate>
        </Button>
        <SubscriptionButton />
      </DialogActions>
    </Dialog>
  );
};

import { Divider } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import React from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { RecoParamsForm } from './components/RecoParamsForm';
import { WidgetBuilder } from './components/WidgetBuilder';
import { WidgetMenuBar } from './components/WidgetMenuBar';
import WidgetPreviewPanel from './components/WidgetPreviewPanel';
import { useWidgetPreviewPanel } from './hooks/preview/useWidgetPreviewPanel';

export const Widget = () => {
  const { appBarHeight } = useAppSelector(state => state.mainLayouts.appbarReducer);

  const panelHandleState = useWidgetPreviewPanel();
  const { minSize, maxSize, defaultSize, isCollapsed, panelHandle, handleExpand } = panelHandleState;

  return (
    <PanelGroup direction="vertical" style={{ height: `calc(100vh - ${appBarHeight}px)`, overflow: 'visible' }}>
      <WidgetMenuBar handleExpand={handleExpand} isCollapsed={isCollapsed} />
      <Divider />
      <Panel minSize={20} style={{ overflow: 'auto' }}>
        <PanelGroup direction="horizontal">
          <Panel minSize={70} style={{ overflow: 'auto' }}>
            <WidgetBuilder />
          </Panel>
          <Divider orientation="vertical" />
          <Panel minSize={30} style={{ overflow: 'auto' }}>
            <RecoParamsForm />
          </Panel>
        </PanelGroup>
      </Panel>

      <PanelResizeHandle>
        <Divider />
      </PanelResizeHandle>
      <Panel
        ref={panelHandle}
        collapsible={true}
        maxSize={maxSize}
        minSize={minSize}
        defaultSize={defaultSize}
        style={{ overflow: 'auto' }}
      >
        <WidgetPreviewPanel panelHandleState={panelHandleState} />
      </Panel>
    </PanelGroup>
  );
};

import React, { useEffect } from 'react';
import { REDIRECT_URL } from 'app/shared/util/url-utils';
import { useLocation } from 'react-router-dom';

export const LoginRedirect = props => {
  // const location = useLocation();

  useEffect(() => {
    // sessionStorage.setItem(REDIRECT_URL, location.state['from']);
    window.location.reload();
  });

  return null;
};

export default LoginRedirect;

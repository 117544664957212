import React from 'react';

import { Stream, WarningAmber } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useCheckHealthQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { Translate, translate } from 'react-jhipster';

export const ClickStreamConfig = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});

  const {
    data = {},
    isSuccess,
    isUninitialized,
    isLoading,
  } = useCheckHealthQuery(accountProfile.accountId ? { accountId: accountProfile.accountId.substring(0, 8) } : skipToken);

  const status = isSuccess && data['status'] === 'UP' ? 'READY' : undefined;

  return (
    <LayoutItem
      accordionOptions={{
        summary: {
          expandIcon: undefined,
        },
      }}
      cardOptions={{
        header: {
          title: (
            <Typography variant="h5" mr={1}>
              <Translate contentKey="module-factory.accordion.click-stream-configuration">Click Stream Configuration</Translate>
            </Typography>
          ),
          avatar: <Stream color="primary" />,
          disableTypography: true,
          action: !isUninitialized && !isLoading && (
            <Chip
              label={`${translate('account.account-page.status', null, 'Status')}: ${status ? status.replace(/_/g, ' ') : 'Not Ready'}`}
              variant="outlined"
              icon={!isSuccess && <WarningAmber color="warning" fontSize="small" />}
              color={status ? 'success' : 'error'}
            />
          ),
        },
        card: {
          sx: {
            overflow: 'visible',
            width: '100%',
            boxShadow: 'none',
            transition: 'all 500ms linear',
          },
        },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        // ...(status &&
        //   status.startsWith('NOT_READY') && {
        //     actions: (
        //       <Button size="small" variant="outlined" component={Link} to={`${sitemap.recommendations.settings}`} endIcon={<East />}>
        //         <Translate contentKey="module-factory.accordion.go-to-settings">Go to settings</Translate>
        //       </Button>
        //     ),
        //   }),
      }}
    >
      {status && (
        <Typography variant="subtitle1">
          {status.startsWith('NOT_READY') ? (
            <Translate contentKey="module-factory.accordion.click-stream-configuration-text">
              To collect the clickstream, a server endpoint must be configured. Please provide your settings using the dedicated form.
            </Translate>
          ) : (
            <Translate contentKey="module-factory.accordion.click-stream-configuration-ready">
              Your clickstream endpoint is configured and ready to use.
            </Translate>
          )}
        </Typography>
      )}
    </LayoutItem>
  );
};

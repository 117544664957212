import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useGetAccountInvoicesUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import {
  accountProfileApi,
  useGetCurrentAccountMembersUsingGetQuery,
} from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useGetPaymentMethodsUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/payment-method';
import { UserProfileDtoRes, userProfilesApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';

export const useAccountState = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { data: userProfile = {} as UserProfileDtoRes } = userProfilesApi.endpoints.getCurrentUserProfileUsingGet.useQueryState({});
  useGetPaymentMethodsUsingGetQuery(accountProfile.accountId ? { accountKey: accountProfile.accountId, withPluginInfo: true } : skipToken);
  useGetAccountInvoicesUsingGetQuery(accountProfile.accountId ? { accountKey: accountProfile.accountId } : skipToken);
  useGetCurrentAccountMembersUsingGetQuery(userProfile.currentAccount ? undefined : skipToken);
};

import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { FindAllCorporaApiResponse, useFindAllCorporaQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { useMemo } from 'react';

export const useCorpusListQuery = () => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const corpusListSelector = (res: TypedUseQueryStateResult<FindAllCorporaApiResponse, unknown, any>) =>
    useMemo(() => {
      return {
        ...res,
        corpusList: res.data ? res.data.content.map(corpus => corpus.id) : [],
      };
    }, [res.data]);
  const { corpusList, ...rest } = useFindAllCorporaQuery(
    accountProfile.accountId ? { accountId: accountProfile.accountId.substring(0, 8) } : skipToken,
    {
      selectFromResult: corpusListSelector,
    }
  );
  return { corpusList, ...rest };
};

import React from 'react';

import { IconButton, MenuItem, Menu, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Translate } from 'react-jhipster';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ApiIcon from '@mui/icons-material/Api';
import { useProductMenu } from './hooks/useProductMenu';

export const ProductActions = ({ product }) => {
  const { anchorEl, open, handleClick, handleClose } = useProductMenu(product.productName);

  return (
    <>
      <IconButton onClick={handleClick}>
        <Tooltip title={<Translate contentKey="products.product-menu">Product Menu</Translate>}>
          <MoreVertIcon />
        </Tooltip>
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ dense: true }}>
        <MenuItem>
          <ListItemIcon>
            <ApiIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Translate contentKey="products.api-doc">Api Doc</Translate>
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <HelpCenterIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Translate contentKey="products.help">Help</Translate>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

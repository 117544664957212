import { ContentCopy, Done } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Ace } from 'ace-builds';
import { omit } from 'lodash';
import React, { useState } from 'react';
import AceEditor from 'react-ace';
import { IMarker } from 'react-ace/lib/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Translate } from 'react-jhipster';
import { useWidgetPreview } from '../hooks/preview/useWidgetPreview';

import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-html_elixir';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/webpack-resolver';
import '../ace-styles.css';

const embedSnippetText = ({
  accountId,
  containerId,
  snippetCode,
}) => `<script>window.JoeHtmlAlchemy = window.JoeHtmlAlchemy || function () { (JoeHtmlAlchemy.q = JoeHtmlAlchemy.q ?? []).push(arguments); }; </script>
<script>
    JoeHtmlAlchemy({
        "apiKey": API_KEY,
        "accountId": "${accountId}",
        "apiHost": "https://www.scoringjoe.com/",
    });
    JoeHtmlAlchemy(${JSON.stringify(snippetCode, null, '\t').replace(/\n/g, '\n\t')});
</script>
<script type="text/javascript" src="https://scoringjoe.com/services/reco-widget/widget-loader.iife.js"></script>
<div id="${containerId}"></div>`;

export const EmbedCodeWidgetDialog = ({ setOpen }) => {
  const { accountId, containerId, j } = useWidgetPreview();
  const [copied, setCopied] = useState(false);

  const { widgetId, builder, recommendations } = j ?? {};
  const snippetCode = j && {
    widgetId,
    builder,
    recommendations: omit(recommendations, ['clientRest', 'itemId', 'profileId']),
    elementId: containerId,
  };
  const valueText = snippetCode && embedSnippetText({ accountId, containerId, snippetCode });

  // Create a marker object
  const placeholders = [{ value: 'API_KEY', annotation: 'Replace API_KEY with your api key from ScoringJoe' }];
  const decorations: { marker: IMarker; annotation: Ace.Annotation }[] = placeholders
    .map(key => {
      if (valueText) {
        const placeholderIndex = valueText.indexOf(key.value);
        const startValue = valueText.substring(0, placeholderIndex).split('\n');
        const startRow = startValue.length - 1;

        return {
          marker: {
            startRow,
            startCol: startValue[startRow].length,
            endRow: valueText.substring(0, placeholderIndex + key.value.length).split('\n').length - 1,
            endCol: startValue[startRow].length + key.value.length,
            className: 'placeholder-marker',
            type: 'text',
          } as IMarker,
          annotation: {
            row: startRow,
            text: key.annotation,
            type: 'info',
          },
        };
      }
      return undefined;
    })
    .filter(Boolean);

  if (valueText) {
    return (
      <>
        <DialogTitle display="flex" typography="h4">
          Embed Code
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle2">
            To add this widget to your website, copy following code and paste it to the exact location in the source code, where it should
            be displayed.
          </Typography>
          <AceEditor
            mode="html_elixir"
            theme="chrome"
            readOnly
            // wrapEnabled={false}
            highlightActiveLine={false}
            setOptions={{
              foldStyle: 'manual',
              highlightGutterLine: false,
            }}
            value={valueText}
            height="320px"
            width="100%"
            style={{ margin: '10px' }}
            annotations={decorations.map(deco => deco.annotation)}
            markers={decorations.map(deco => deco.marker)}
          />
        </DialogContent>
        <DialogActions sx={{ paddingX: 3 }}>
          <Button color="error" onClick={() => setOpen(false)}>
            <Translate contentKey="recommendation.cancel">Cancel</Translate>
          </Button>
          <CopyToClipboard
            text={valueText}
            onCopy={() => {
              setCopied(true);
            }}
          >
            <Button startIcon={copied ? <Done /> : <ContentCopy />}>{copied ? 'Copied to clipboard' : 'Copy to clipboard'}</Button>
          </CopyToClipboard>
        </DialogActions>
      </>
    );
  }
  return null;
};

import { emptyDataHubCloudServicesApi } from '../../data-hub-cloud-services/emptyDataHubCloudServicesApi';
import { emptyGreensightsDataHubCloudServicesApi } from '../../greensights-data-hub-cloud-services/emptyGreensightsDataHubCloudServicesApi';
import { emptyRecoEngineCloudServicesApi } from '../../reco-engine/emptyRecoEngineCloudServicesApi';
import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getAllUserProfilesUsingGet: build.query<GetAllUserProfilesUsingGetApiResponse, GetAllUserProfilesUsingGetApiArg>({
      query: () => ({ url: `/api/v1/user-profiles` }),
    }),
    updateUserProfileByNameUsingPut: build.mutation<UpdateUserProfileByNameUsingPutApiResponse, UpdateUserProfileByNameUsingPutApiArg>({
      query: queryArg => ({
        url: `/api/v1/user-profiles`,
        method: 'PUT',
        body: queryArg.userProfileDtoReq,
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
        },
      }),
    }),
    createUserProfileUsingPost: build.mutation<CreateUserProfileUsingPostApiResponse, CreateUserProfileUsingPostApiArg>({
      query: queryArg => ({ url: `/api/v1/user-profiles`, method: 'POST', body: queryArg.userProfilePostDto }),
    }),
    patchUserProfileByNameUsingPatch: build.mutation<PatchUserProfileByNameUsingPatchApiResponse, PatchUserProfileByNameUsingPatchApiArg>({
      query: queryArg => ({
        url: `/api/v1/user-profiles`,
        method: 'PATCH',
        body: queryArg.userProfileDtoReq,
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
        },
      }),
    }),
    getCurrentUserProfileUsingGet: build.query<GetCurrentUserProfileUsingGetApiResponse, GetCurrentUserProfileUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/user-profiles/current`, params: { createIfMissing: queryArg.createIfMissing } }),
      extraOptions: { maxRetries: 3 },
      providesTags: ['USER_PROFILE'],
    }),
    getCurrentUserAccountsUsingGet: build.query<GetCurrentUserAccountsUsingGetApiResponse, GetCurrentUserAccountsUsingGetApiArg>({
      query: () => ({ url: `/api/v1/user-profiles/current/accounts` }),
      providesTags: ['ACCOUNT_LIST'],
    }),
    setLocaleUsingPut: build.mutation<SetLocaleUsingPutApiResponse, SetLocaleUsingPutApiArg>({
      query: queryArg => ({ url: `/api/v1/user-profiles/current/locale/${queryArg.locale}`, method: 'PUT' }),
    }),
    updateUserProfileByIdUsingPut: build.mutation<UpdateUserProfileByIdUsingPutApiResponse, UpdateUserProfileByIdUsingPutApiArg>({
      query: queryArg => ({
        url: `/api/v1/user-profiles/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.userProfileDtoReq,
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
        },
      }),
    }),
    getUserProfileUsingGet: build.query<GetUserProfileUsingGetApiResponse, GetUserProfileUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/user-profiles/${queryArg.userEmail}` }),
    }),
    getUserAccountsUsingGet: build.query<GetUserAccountsUsingGetApiResponse, GetUserAccountsUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/user-profiles/${queryArg.userEmail}/accounts` }),
    }),
    setCurrentAccountUsingPut: build.mutation<SetCurrentAccountUsingPutApiResponse, SetCurrentAccountUsingPutApiArg>({
      query: queryArg => ({
        url: `/api/v1/user-profiles/${queryArg.userEmail}/accounts/${queryArg.accountId}/setCurrent`,
        method: 'PUT',
        params: {
          authenticated: queryArg.authenticated,
          authorities: queryArg.authorities,
          credentials: queryArg.credentials,
          details: queryArg.details,
          principal: queryArg.principal,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled.then(res => {
          dispatch(emptyDataHubCloudServicesApi.util.invalidateTags(['USER_PROFILE']));
          dispatch(emptyGreensightsDataHubCloudServicesApi.util.invalidateTags(['USER_PROFILE']));
          dispatch(emptyRecoEngineCloudServicesApi.util.invalidateTags(['USER_PROFILE']));
        });
      },
      invalidatesTags: ['USER_PROFILE', 'ACCOUNT_PROFILE'],
    }),
    setUserAccountRelationStatusUsingPut1: build.mutation<
      SetUserAccountRelationStatusUsingPut1ApiResponse,
      SetUserAccountRelationStatusUsingPut1ApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/user-profiles/${queryArg.userEmail}/accounts/${queryArg.accountId}/setStatus`,
        method: 'PUT',
        params: { memberStatus: queryArg.memberStatus },
      }),
    }),
    getUserCurrentAccountUsingGet: build.query<GetUserCurrentAccountUsingGetApiResponse, GetUserCurrentAccountUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/user-profiles/${queryArg.userEmail}/currentAccount` }),
    }),
    getUserFiltersUsingGet: build.query<GetUserFiltersUsingGetApiResponse, GetUserFiltersUsingGetApiArg>({
      query: queryArg => ({ url: `/api/v1/user-profiles/${queryArg.userEmail}/dataRequestFilters` }),
    }),
    addOrReplaceUserFiltersUsingPost: build.mutation<AddOrReplaceUserFiltersUsingPostApiResponse, AddOrReplaceUserFiltersUsingPostApiArg>({
      query: queryArg => ({
        url: `/api/v1/user-profiles/${queryArg.userEmail}/dataRequestFilters`,
        method: 'POST',
        body: queryArg.dataHubRequestFilterDtoReq,
      }),
    }),
    setUserAccountRelationStatusUsingPut: build.mutation<
      SetUserAccountRelationStatusUsingPutApiResponse,
      SetUserAccountRelationStatusUsingPutApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/user-profiles/${queryArg.userEmail}/dataRequestFilters/rename`,
        method: 'PUT',
        params: { oldName: queryArg.oldName, newName: queryArg.newName },
      }),
    }),
    deleteRequestFilterUsingDelete: build.mutation<DeleteRequestFilterUsingDeleteApiResponse, DeleteRequestFilterUsingDeleteApiArg>({
      query: queryArg => ({ url: `/api/v1/user-profiles/${queryArg.userEmail}/dataRequestFilters/${queryArg.name}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userProfilesApi };
export type GetAllUserProfilesUsingGetApiResponse = /** status 200 OK */ UserProfileDtoRes[];
export type GetAllUserProfilesUsingGetApiArg = void;
export type UpdateUserProfileByNameUsingPutApiResponse = /** status 200 OK */ UserProfileDtoRes | /** status 201 Created */ undefined;
export type UpdateUserProfileByNameUsingPutApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  userProfileDtoReq: UserProfileDtoReq;
};
export type CreateUserProfileUsingPostApiResponse = /** status 200 OK */ UserProfileDtoRes | /** status 201 Created */ undefined;
export type CreateUserProfileUsingPostApiArg = {
  userProfilePostDto: UserProfilePostDto;
};
export type PatchUserProfileByNameUsingPatchApiResponse = /** status 200 OK */ UserProfileDtoRes | /** status 204 No Content */ undefined;
export type PatchUserProfileByNameUsingPatchApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  userProfileDtoReq: UserProfileDtoReq;
};
export type GetCurrentUserProfileUsingGetApiResponse = /** status 200 OK */ UserProfileDtoRes;
export type GetCurrentUserProfileUsingGetApiArg = {
  /** createIfMissing */
  createIfMissing?: boolean;
};
export type GetCurrentUserAccountsUsingGetApiResponse = /** status 200 OK */ AccountProfileDto[];
export type GetCurrentUserAccountsUsingGetApiArg = void;
export type SetLocaleUsingPutApiResponse = /** status 200 OK */ string | /** status 201 Created */ undefined;
export type SetLocaleUsingPutApiArg = {
  /** locale */
  locale: string;
};
export type UpdateUserProfileByIdUsingPutApiResponse = /** status 200 OK */ UserProfileDtoRes | /** status 201 Created */ undefined;
export type UpdateUserProfileByIdUsingPutApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** id */
  id: string;
  userProfileDtoReq: UserProfileDtoReq;
};
export type GetUserProfileUsingGetApiResponse = /** status 200 OK */ UserProfileDtoRes;
export type GetUserProfileUsingGetApiArg = {
  /** userEmail */
  userEmail: string;
};
export type GetUserAccountsUsingGetApiResponse = /** status 200 OK */ AccountProfileDto[];
export type GetUserAccountsUsingGetApiArg = {
  /** userEmail */
  userEmail: string;
};
export type SetCurrentAccountUsingPutApiResponse = unknown;
export type SetCurrentAccountUsingPutApiArg = {
  authenticated?: boolean;
  authorities?: {
    '0'?: string;
  };
  credentials?: object;
  details?: object;
  principal?: object;
  /** userEmail */
  userEmail: string;
  /** accountId */
  accountId: string;
};
export type SetUserAccountRelationStatusUsingPut1ApiResponse = unknown;
export type SetUserAccountRelationStatusUsingPut1ApiArg = {
  /** userEmail */
  userEmail: string;
  /** accountId */
  accountId: string;
  /** memberStatus */
  memberStatus: 'ACTIVE' | 'BLOCKED' | 'DISABLED' | 'INVITED';
};
export type GetUserCurrentAccountUsingGetApiResponse = /** status 200 OK */ string;
export type GetUserCurrentAccountUsingGetApiArg = {
  /** userEmail */
  userEmail: string;
};
export type GetUserFiltersUsingGetApiResponse = /** status 200 OK */ DataHubRequestFilterDtoRes[];
export type GetUserFiltersUsingGetApiArg = {
  /** userEmail */
  userEmail: string;
};
export type AddOrReplaceUserFiltersUsingPostApiResponse = unknown;
export type AddOrReplaceUserFiltersUsingPostApiArg = {
  /** userEmail */
  userEmail: string;
  dataHubRequestFilterDtoReq: DataHubRequestFilterDtoReq;
};
export type SetUserAccountRelationStatusUsingPutApiResponse = unknown;
export type SetUserAccountRelationStatusUsingPutApiArg = {
  /** userEmail */
  userEmail: string;
  /** oldName */
  oldName: string;
  /** newName */
  newName: string;
};
export type DeleteRequestFilterUsingDeleteApiResponse = unknown;
export type DeleteRequestFilterUsingDeleteApiArg = {
  /** userEmail */
  userEmail: string;
  /** name */
  name: string;
};
export type AccountPropertiesDto = {
  accountId?: string;
  roles?: ('ADMIN' | 'CONTRIBUTOR' | 'GUEST' | 'HURENCE_ADMIN')[];
  status?: 'ACTIVE' | 'BLOCKED' | 'DISABLED' | 'INVITED';
};
export type AuditingEntityDto = {
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
};
export type UserProfileDtoRes = {
  accounts?: AccountPropertiesDto[];
  auditInfo?: AuditingEntityDto;
  currentAccount?: string;
  email: string;
  firstName?: string;
  keycloakId?: string;
  lastName?: string;
  locale?: string;
  theme?: string;
  timeZone?: string;
};
export type AbstractDocument = {
  createdBy?: string;
  createdDate?: string;
  id?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  properties?: object;
};
export type UserProfileDtoReq = {
  accounts?: AccountPropertiesDto[];
  auditInfo?: AuditingEntityDto;
  auditingAttributes?: AbstractDocument;
  currentAccount?: string;
  email: string;
  firstName?: string;
  keycloakId?: string;
  lastName?: string;
  locale?: string;
  theme?: string;
  timeZone?: string;
};
export type UserProfilePostDto = {
  accounts?: AccountPropertiesDto[];
  currentAccount?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  locale?: string;
  theme?: string;
  timeZone?: string;
};
export type AccountProfileDto = {
  accountId?: string;
  auditInfo?: AuditingEntityDto;
  name?: string;
  status?:
    | 'ACTIVE'
    | 'BOOTSTRAP'
    | 'KB_EXCEPTION'
    | 'MISSING_ADDRESS'
    | 'MISSING_CONTACT'
    | 'MISSING_DETAILS'
    | 'MISSING_KB_ACCOUNT'
    | 'MISSING_PAYMENT_METHOD'
    | 'READY';
};
export type DataHubRequestFilterDtoRes = {
  auditInfo?: AuditingEntityDto;
  filter: object[];
  name: string;
  userId?: string;
};
export type DataHubRequestFilterDtoReq = {
  auditInfo?: AuditingEntityDto;
  auditingAttributes?: AbstractDocument;
  filter: object[];
  name: string;
  userId?: string;
};
export const {
  useGetAllUserProfilesUsingGetQuery,
  useUpdateUserProfileByNameUsingPutMutation,
  useCreateUserProfileUsingPostMutation,
  usePatchUserProfileByNameUsingPatchMutation,
  useGetCurrentUserProfileUsingGetQuery,
  useLazyGetCurrentUserProfileUsingGetQuery,
  useGetCurrentUserAccountsUsingGetQuery,
  useLazyGetCurrentUserAccountsUsingGetQuery,
  useSetLocaleUsingPutMutation,
  useUpdateUserProfileByIdUsingPutMutation,
  useGetUserProfileUsingGetQuery,
  useGetUserAccountsUsingGetQuery,
  useSetCurrentAccountUsingPutMutation,
  useSetUserAccountRelationStatusUsingPut1Mutation,
  useGetUserCurrentAccountUsingGetQuery,
  useGetUserFiltersUsingGetQuery,
  useAddOrReplaceUserFiltersUsingPostMutation,
  useSetUserAccountRelationStatusUsingPutMutation,
  useDeleteRequestFilterUsingDeleteMutation,
} = injectedRtkApi;

import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    findAllSubscriptionSettingsUsingGet: build.query<
      FindAllSubscriptionSettingsUsingGetApiResponse,
      FindAllSubscriptionSettingsUsingGetApiArg
    >({
      query: () => ({ url: `/api/v1/subscriptionSettings` }),
    }),
    putSubscriptionSettingsUsingPut: build.mutation<PutSubscriptionSettingsUsingPutApiResponse, PutSubscriptionSettingsUsingPutApiArg>({
      query: queryArg => ({ url: `/api/v1/subscriptionSettings`, method: 'PUT', body: queryArg.subscriptionSettingsDtoReq }),
      invalidatesTags: ['SUBSCRIPTION_SETTINGS'],
    }),
    postSubscriptionSettingsUsingPost: build.mutation<
      PostSubscriptionSettingsUsingPostApiResponse,
      PostSubscriptionSettingsUsingPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/subscriptionSettings`, method: 'POST', body: queryArg.subscriptionSettingsDtoReq }),
      invalidatesTags: ['SUBSCRIPTION_SETTINGS'],
    }),
    findSubscriptionSettingsByIdUsingGet: build.query<
      FindSubscriptionSettingsByIdUsingGetApiResponse,
      FindSubscriptionSettingsByIdUsingGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/subscriptionSettings/${queryArg.subscriptionId}` }),
      providesTags: ['SUBSCRIPTION_SETTINGS'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as subscriptionSettingsApi };
export type FindAllSubscriptionSettingsUsingGetApiResponse = /** status 200 OK */ SubscriptionSettingsDtoRes[];
export type FindAllSubscriptionSettingsUsingGetApiArg = void;
export type PutSubscriptionSettingsUsingPutApiResponse =
  /** status 200 OK */
  SubscriptionSettingsDtoRes | /** status 201 Created */ undefined;
export type PutSubscriptionSettingsUsingPutApiArg = {
  subscriptionSettingsDtoReq: SubscriptionSettingsDtoReq;
};
export type PostSubscriptionSettingsUsingPostApiResponse =
  /** status 200 OK */
  SubscriptionSettingsDtoRes | /** status 201 Created */ undefined;
export type PostSubscriptionSettingsUsingPostApiArg = {
  subscriptionSettingsDtoReq: SubscriptionSettingsDtoReq;
};
export type FindSubscriptionSettingsByIdUsingGetApiResponse = /** status 200 OK */ SubscriptionSettingsDtoRes;
export type FindSubscriptionSettingsByIdUsingGetApiArg = {
  /** subscriptionId */
  subscriptionId: string;
};
export type AuditingEntityDto = {
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
};
export type SubscriptionSettingsDtoRes = {
  auditInfo?: AuditingEntityDto;
  settings?: object;
  subscriptionId?: string;
};
export type AbstractDocument = {
  createdBy?: string;
  createdDate?: string;
  id?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  properties?: object;
};
export type SubscriptionSettingsDtoReq = {
  auditInfo?: AuditingEntityDto;
  auditingAttributes?: AbstractDocument;
  settings?: object;
  subscriptionId?: string;
};
export const {
  useFindAllSubscriptionSettingsUsingGetQuery,
  usePutSubscriptionSettingsUsingPutMutation,
  usePostSubscriptionSettingsUsingPostMutation,
  useFindSubscriptionSettingsByIdUsingGetQuery,
} = injectedRtkApi;

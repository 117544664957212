import React, { useEffect } from 'react';

import * as echarts from 'echarts';
import world from 'app/shared/util/world.json';
import { useTheme } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';
import { getWorldMapData } from 'app/shared/util/worldMapData';
import { ResponseWrapper } from 'app/modules/views/greensights/model/responseWrapper';
import MetricResponse from 'app/modules/views/greensights/model/metricResponse';
import { WithData } from 'app/modules/loading-lib/WithData';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { useData } from '../../../data/api';
import { useSelectedTimeParams } from '../actions/timeranges';

const wordMap = {};
getWorldMapData().forEach(value => (wordMap[value.code] = value.name));
const callback = (response: ResponseWrapper<MetricResponse>) => {
  if (response.content.status === 'success') {
    const map = {};
    response.content.data.result.forEach(result => {
      const code = result.metric['country_code'];
      map[code] = 0;
      result.values.forEach(value => {
        map[code] += parseFloat('' + value[1]);
      });
    });
    return getWorldMapData().map(country => ({ name: country.name, value: map[country.code] || 0 }));
  }
  return [];
};

const WorldMapChart = () => {
  const { timeParams } = useSelectedTimeParams();
  const { data, load, loading } = useData('wort_map_chart', callback);

  useEffect(() => {
    load('93090364', {
      start: timeParams.fromDate,
      end: timeParams.toDate,
      query: `sum(sum_over_time(energy_impact_kwh{#filters})) by (country_code)`,
      filters: {
        scope: 'country',
      },
    });
  }, [timeParams]);

  echarts.registerMap('world', world);
  const theme = useTheme();
  const option = {
    tooltip: {
      show: true,
      trigger: 'item',
      formatter(params) {
        return params.name + ' : ' + params.value;
      },
    },
    dataRange: {
      show: false,
      min: 0,
      max: data.map(c => c.value).sort()[data.length - 1],
      realtime: false,
      text: ['High', 'Low'],
      calculable: true,
      color: [theme.palette.primary.dark, theme.palette.primary.light],
    },
    series: {
      type: 'map',
      name: `metrics by country`,
      map: 'world',
      roam: true,
      data,
    },
  };

  const mapChart = () => <ReactECharts style={{ height: '100%', width: '100%' }} option={option} />;

  return WithLoading(WithData(mapChart, data), loading)({});
};

export default WorldMapChart;

import { Box, Divider, List, Stack, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { translate } from 'react-jhipster';
import { parseEsDateString } from '../filter-wrapper/useTimeRangeFilter';

interface StickySubHeaderListProps {
  list: any;
  setRange: any;
  setFrom: any;
  setTo: any;
  setFromDate: any;
  setToDate: any;
  setStep: any;
  closePopper: () => void;
}

export const StickySubHeaderList = ({
  list,
  setRange,
  setFrom,
  setTo,
  setFromDate,
  setToDate,
  setStep,
  closePopper,
}: StickySubHeaderListProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState('');

  const handleListItemClick = (event, index, item) => {
    setSelectedIndex(item.value);
    setFrom(parseEsDateString(item.from));
    setFromDate(item.from);
    setTo(parseEsDateString(item.to || 'now'));
    setToDate(item.to);
    setStep(item.step);
    setRange(item.i18nKey);
    closePopper();
  };

  return (
    <Box sx={{ overflowX: 'hidden', overflowY: 'scroll' }}>
      <Stack p={2}>
        {list.map((elem, index) => (
          <Stack key={`list-${index}`} spacing={0}>
            <Typography variant="subtitle1">{translate(`web-analytics.DatePicker.ListTitle.${elem.title}`, null, elem.title)}</Typography>
            <Divider sx={{ marginY: '3px' }} />
            <List dense>
              {elem.list.map((item, itemIndex) => {
                const value = item.value || itemIndex;
                const toDate = item.to || 'now';
                const timeRange = parseEsDateString(item.from).from(parseEsDateString(toDate), true);
                const label = item.i18nKey ? translate(item.i18nKey, { timeRange }, timeRange) : timeRange;
                return (
                  <ListItemButton
                    disableGutters
                    key={value}
                    selected={selectedIndex === value}
                    onClick={event => handleListItemClick(event, itemIndex, item)}
                  >
                    <ListItemText primary={label} />
                  </ListItemButton>
                );
              })}
            </List>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

import React, { Fragment } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';

import { TextField, TextFieldProps } from '@mui/material';

import { CommonFieldProps } from './props/FieldProps';
import { Title } from './Widgets/Title';
import { translate } from 'react-jhipster';

export interface StandardTextFieldProps extends CommonFieldProps<'text-field'> {
  attribute: Required<CommonFieldProps<'text-field'>>['attribute'];
}

export default function StandardTextField(props: { field: StandardTextFieldProps; methods: UseFormReturn; hideTitle?: boolean }) {
  const {
    field: fieldConfig,
    methods: {
      control,
      getValues,
      setValue,
      trigger,
      formState: { errors },
    },
    hideTitle,
  } = props;

  const componentProps = (fieldConf: StandardTextFieldProps, field: ControllerRenderProps): TextFieldProps => {
    return {
      id: fieldConf.attribute,
      fullWidth: true,
      size: 'small',
      variant: 'outlined',
      label: fieldConf.label && translate(fieldConf.label, null, fieldConf.label),
      error: !!errors[fieldConf.attribute],
      helperText: <>{errors[fieldConf.attribute]?.message}</>,
      onKeyDown(event) {
        if (event.key === 'Enter') {
          trigger(fieldConf.attribute);
        }
      },
      ...fieldConf.props,
      ...field,
      value: field.value || '',
    };
  };

  return (
    <Controller
      name={fieldConfig.attribute}
      control={control}
      render={({ field }) => (
        <Fragment>
          {!hideTitle && fieldConfig.title && <Title field={fieldConfig} />}
          <TextField {...componentProps(fieldConfig, field)} />
        </Fragment>
      )}
    />
  );
}

import React from 'react';

import { Button, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate } from 'react-jhipster';
import { variables } from '../LandingPage';
import { getAlphaColor } from 'app/shared/util/color-palette-calculator';

export const OtherServices = () => {
  return (
    <LayoutItem
      cardOptions={{
        variant: 'div',
        card: {
          sx: {
            backgroundColor: theme => getAlphaColor(theme.palette.grey[300], 0.4),
            py: '10vh',
          },
        },
        header: {
          disableTypography: true,
          title: (
            <Typography fontSize="2.2rem" textAlign="center">
              <Translate contentKey={'landing.services.title'} interpolate={{ ...variables }}>
                Why choose ScoringJoe
              </Translate>
            </Typography>
          ),
          sx: {
            pb: '5vh',
          },
        },
        actions: (
          <Button variant="outlined" href="mailto:contact@hurence.com" target="_blank" size="large" sx={{ width: 'max-content' }}>
            <Translate contentKey="landing.actions.contactUs">Contact us</Translate>
          </Button>
        ),
        actionsProps: { sx: { justifyContent: 'center', mt: '5vh', p: 0 } },
        content: { sx: { p: 0, '&:last-child': { p: 0 } } },
      }}
    >
      <Container maxWidth="xl">
        <GridLayout spacing={{ xs: 7, md: 12 }} rowSpacing="5vh">
          {['a', 'b', 'c', 'd', 'e', 'f'].map((element, index) => {
            return (
              <LayoutItem
                key={index}
                gridItemProps={{
                  xs: 12,
                  sm: 6,
                  md: 4,
                  // alignSelf: 'center',
                  paddingY: 0,
                }}
                cardOptions={{
                  header: {
                    disableTypography: true,
                    title: (
                      <Typography fontSize="1.5rem" textAlign="left">
                        <Translate contentKey={`landing.services.${element}.title`} interpolate={{ ...variables }}>
                          Reason {element}
                        </Translate>
                      </Typography>
                    ),
                    sx: { p: 3.5 },
                  },
                  card: {
                    sx: {
                      borderRadius: 5,
                      height: '100%',
                    },
                    elevation: 3,
                  },
                  content: { sx: { p: 3.5, pt: 0, '&:last-child': { p: 3.5, pt: 0 } } },
                }}
              >
                <Typography fontSize="1.1rem" sx={{ textAlign: 'justify' }}>
                  <Translate contentKey={`landing.services.${element}.summary`} interpolate={{ ...variables }}>
                    Analize, score, recommend, convert more with the full solution
                  </Translate>
                </Typography>
              </LayoutItem>
            );
          })}
        </GridLayout>
      </Container>
    </LayoutItem>
  );
};

import { Dashboard, Settings } from '@mui/icons-material';
import { ToggleButtonProps, Tooltip } from '@mui/material';
import { ControlledToggleButton } from 'app/components/buttons/ControlledToggleButton';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { DASHBOARDS, SETTINGS } from 'app/shared/reducers/main-layouts/menubar';
import { setWebAnalyticsView } from 'app/shared/reducers/main-layouts/menubar';
import React from 'react';
import { Translate } from 'react-jhipster';
import { useWebAnalyticsStatus } from '../../components/accordion/web-analytics/hooks/useWebAnalyticsStatus';

export const WebAnalyticsActions = () => {
  const status = useWebAnalyticsStatus();

  const view = useAppSelector(state => state.mainLayouts.menubarReducer['web-analytics']);
  const dispatch = useAppDispatch();
  const buttons: ToggleButtonProps[] = [
    {
      value: SETTINGS,
      children: (
        <Tooltip title={<Translate contentKey={`module-factory.${SETTINGS}`}>{SETTINGS}</Translate>}>
          <Settings />
        </Tooltip>
      ),
    },
    {
      value: DASHBOARDS,
      children: (
        <Tooltip title={<Translate contentKey={`module-factory.${DASHBOARDS}`}>{DASHBOARDS}</Translate>}>
          <Dashboard />
        </Tooltip>
      ),
    },
  ];
  if (status && status.startsWith('READY')) {
    return (
      <ControlledToggleButton
        buttons={buttons}
        value={view}
        onChange={(_event, value) => value !== null && dispatch(setWebAnalyticsView(value))}
        size="small"
        color="primary"
        sx={{ m: 1 }}
        exclusive
      />
    );
  }
  return <></>;
};

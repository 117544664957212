export const filterOperators = [
  'PRESENT_IN_VALUES',
  'ABSENT_IN_VALUES',
  'IN_VALUE_RANGE',
  'NOT_IN_VALUE_RANGE',
  'GREATER_THAN',
  'LOWER_THAN',
];

export const availableKpis = [
  'USER_COUNT',
  'PAGEVIEWS_COUNT',
  'EVENTS_COUNT',
  'ACTIVE_USER_COUNT',
  'SESSION_COUNT',
  'AVG_SESSION_DURATION',
  'SUM_SESSION_DURATION',
  'SINGLE_PAGE_VISIT_RATE',
  'BOUNCE_RATE',
  'BOUNCE_COUNT',
  'EVENT_COUNTER',
  'ACTIVE_SESSION',
  'PAGEVIEWS_COUNTER',
  'REFERER',
  'REFERER_HOSTNAME',
  'REMOTE_HOST',
  'REMOTE_HOST_FQDN',
  'INTRANET_IP',
  'INTRANET_TAGGING',
  'UTM_SOURCE',
  'UTM_CAMPAIGN',
  'UTM_MEDIUM',
  'UTM_CONTENT',
  'UTM_TERM',
  'FIRST_USER_VISIT_EPOCH_SECONDS',
  'IS_FIRST_SESSION_OF_USER',
  'FIRST_EVENT_EPOCH_SECONDS',
  'LAST_EVENT_EPOCH_SECONDS',
  'FIRST_VISITED_PAGE',
  'LAST_VISITED_PAGE',
  'SESSION_ID',
  'SOURCE_OF_TRAFFIC_SOURCE',
  'SOURCE_OF_TRAFFIC_CAMPAIGN',
  'SOURCE_OF_TRAFFIC_MEDIUM',
  'SOURCE_OF_TRAFFIC_CONTENT',
  'SOURCE_OF_TRAFFIC_KEYWORD',
  'SOURCE_OF_TRAFFIC_ORGANIC_SEARCH',
  'SOURCE_OF_TRAFFIC_REFERRAL_PATH',
  'USER_AGENT_NAME',
  'USER_AGENT_FAMILY',
  'USER_AGENT_VENDOR',
  'USER_AGENT_TYPE',
  'USER_AGENT_VERSION',
  'USER_AGENT_DEVICE_CATEGORY',
  'USER_AGENT_OS_FAMILY',
  'USER_AGENT_OS_VERSION',
  'USER_AGENT_OS_VENDOR',
  'USER_ID',
  'CITY_ID',
  'CITY_NAME',
  'CONTINENT_CODE',
  'CONTINENT_ID',
  'CONTINENT_NAME',
  'COUNTRY_CODE',
  'COUNTRY_ID',
  'COUNTRY_NAME',
  'METRO_CODE',
  'TIMEZONE',
  'MOST_SPECIFIC_SUBDIVISION_CODE',
  'MOST_SPECIFIC_SUBDIVISION_ID',
  'MOST_SPECIFIC_SUBDIVISION_NAME',
  'POSTAL_CODE',
  'SUBDIVISION_CODES',
  'SUBDIVISION_IDS',
  'SUBDIVISION_NAMES',
];
export const bucketingDimensions = [
  'ACTIVE_SESSION',
  'CITY_ID',
  'CITY_NAME',
  'CONTINENT_CODE',
  'CONTINENT_ID',
  'CONTINENT_NAME',
  'COUNTRY_CODE',
  'COUNTRY_ID',
  'COUNTRY_NAME',
  'EVENT_COUNTER',
  'FIRST_EVENT_EPOCH_SECONDS',
  'FIRST_USER_VISIT_EPOCH_SECONDS',
  'FIRST_VISITED_PAGE',
  'INTRANET_IP',
  'INTRANET_TAGGING',
  'IS_FIRST_SESSION_OF_USER',
  'LAST_EVENT_EPOCH_SECONDS',
  'LAST_VISITED_PAGE',
  'METRO_CODE',
  'MOST_SPECIFIC_SUBDIVISION_CODE',
  'MOST_SPECIFIC_SUBDIVISION_ID',
  'MOST_SPECIFIC_SUBDIVISION_NAME',
  'PAGEVIEWS_COUNTER',
  'POSTAL_CODE',
  'REFERER',
  'REFERER_HOSTNAME',
  'REMOTE_HOST',
  'REMOTE_HOST_FQDN',
  'SESSION_ID',
  'SOURCE_OF_TRAFFIC_CAMPAIGN',
  'SOURCE_OF_TRAFFIC_CONTENT',
  'SOURCE_OF_TRAFFIC_KEYWORD',
  'SOURCE_OF_TRAFFIC_MEDIUM',
  'SOURCE_OF_TRAFFIC_ORGANIC_SEARCH',
  'SOURCE_OF_TRAFFIC_REFERRAL_PATH',
  'SOURCE_OF_TRAFFIC_SOURCE',
  'SUBDIVISION_CODES',
  'SUBDIVISION_IDS',
  'SUBDIVISION_NAMES',
  'TIMEZONE',
  'USER_AGENT_DEVICE_CATEGORY',
  'USER_AGENT_FAMILY',
  'USER_AGENT_NAME',
  'USER_AGENT_OS_FAMILY',
  'USER_AGENT_OS_VENDOR',
  'USER_AGENT_OS_VERSION',
  'USER_AGENT_TYPE',
  'USER_AGENT_VENDOR',
  'USER_AGENT_VERSION',
  'USER_ID',
  'UTM_CAMPAIGN',
  'UTM_CONTENT',
  'UTM_MEDIUM',
  'UTM_SOURCE',
  'UTM_TERM',
];

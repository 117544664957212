import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'app/shared/api/microservices';

import { RECO_ENGINE_URL } from 'app/shared/api/constants';

const staggeredBaseQuery = retry(
  axiosBaseQuery({
    baseUrl: RECO_ENGINE_URL,
  }),
  {
    maxRetries: 0,
  }
);

export const emptyRecoEngineCloudServicesApi = createApi({
  reducerPath: 'recoEngineCloudServicesApi',
  baseQuery: staggeredBaseQuery,
  tagTypes: ['ITEMS_LIST', 'CORPUS_LIST', 'SIMULATION', 'SEARCH_ITEM', 'USER_PROFILE'],
  endpoints: () => ({}),
});

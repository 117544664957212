import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';

const subscriptionSettingsUrl = CONFIG_MANAGER_API_URL + '/subscriptionSettings';

export const initialState = {
  loading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  settings: {} as unknown as any,
  subscriptionId: null as string,
};

export type SubscriptionSettingsState = Readonly<typeof initialState>;

// Actions

export const getSubscriptionSettings = createAsyncThunk(
  'subscriptionSettings/getSubscriptionSettings',
  async ({ subscriptionId }: { subscriptionId: any }) => microserviceHttpClient(`${subscriptionSettingsUrl}/${subscriptionId}`),
  { serializeError: serializeAxiosError }
);

export const saveSubscriptionSettings = createAsyncThunk(
  'subscriptionSettings/saveSubscriptionSettings',
  async ({ body, method }: { body: any; method: string }) => microserviceHttpClient(`${subscriptionSettingsUrl}`, method, body),
  { serializeError: serializeAxiosError }
);

// Slice

export const SubscriptionSettingsSlice = createSlice({
  name: 'subscriptionSettings',
  initialState: initialState as SubscriptionSettingsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSubscriptionSettings.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getSubscriptionSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload.data.settings;
        state.subscriptionId = action.payload.data.subscriptionId;
      })
      .addCase(getSubscriptionSettings.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.settings = {} as unknown as any;
        state.subscriptionId = null as string;
      })
      .addCase(saveSubscriptionSettings.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(saveSubscriptionSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload.data.settings;
        state.subscriptionId = action.payload.data.subscriptionId;
      })
      .addCase(saveSubscriptionSettings.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.settings = {} as unknown as any;
        state.subscriptionId = null as string;
      });
  },
});

// Reducer
export default SubscriptionSettingsSlice.reducer;

import { Close } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { TbWindowMaximize, TbWindowMinimize } from 'react-icons/tb';

export default function WidgetPreviewResizeButtons({ panelHandleState }) {
  const { minSize, maxSize, panelHandle, handleCollapse, handleResize } = panelHandleState;
  const [isMaxSize, setIsMaxSize] = useState(panelHandle.current?.getSize() >= maxSize);
  const [isMinSize, setIsMinSize] = useState(panelHandle.current?.getSize() <= minSize);

  return (
    <Stack direction={'row'} spacing={0}>
      {!isMinSize && (
        <Tooltip title={'Minimize'}>
          <IconButton
            onClick={() => {
              handleResize(minSize);
              setIsMaxSize(panelHandle.current?.getSize() >= maxSize);
              setIsMinSize(panelHandle.current?.getSize() <= minSize);
            }}
          >
            <TbWindowMinimize />
          </IconButton>
        </Tooltip>
      )}
      {!isMaxSize && (
        <Tooltip title={'Maximize'}>
          <IconButton
            onClick={() => {
              handleResize(maxSize);
              setIsMaxSize(panelHandle.current?.getSize() >= maxSize);
              setIsMinSize(panelHandle.current?.getSize() <= minSize);
            }}
          >
            <TbWindowMaximize />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Close Panel">
        <IconButton onClick={handleCollapse}>
          <Close />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

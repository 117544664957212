import React from 'react';

import { TableBody, TablePagination, Table } from '@mui/material';
import { Box } from '@mui/system';
import { SessionsPerDayRow } from './SessionsPerDayRow';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

const SessionsTable = ({
  groupedSessions,
  userEvents,
  userInfos,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  isFetching,
  isError,
  error,
}) => {
  return (
    <>
      <Table sx={{ height: 'fit-content', tableLayout: 'fixed' }} size="small">
        <TableBody>
          {groupedSessions.map((sessions, index) => (
            <SessionsPerDayRow key={index} sessions={sessions} userEvents={userEvents} />
          ))}
        </TableBody>
      </Table>
      <Box display="flex">
        <TablePagination
          component="div"
          count={userInfos ? userInfos.extendedStats.pageviewsCounterStats.count : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ ml: 'auto' }}
        />
      </Box>
    </>
  );
};

export default WithLoadingHOC(SessionsTable);

import React from 'react';

import { Stack, Typography, Chip } from '@mui/material';
import { Box } from '@mui/system';

export const ProfileTitle = ({ theme, userProfile, userRoles, width }) => {
  return (
    <Stack sx={{ textAlign: 'center', width }}>
      <Typography component="span" color="secondary" variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
        {userProfile && (
          <Typography variant="h3" color="inherit" sx={{ marginBottom: theme.spacing(1) }}>
            {!userProfile.firstName && !userProfile.lastName ? userProfile.email : `${userProfile.firstName} ${userProfile.lastName}`}
          </Typography>
        )}
        <Box display="inline-grid">
          {userRoles?.map(item => {
            return <Chip key={item} size="small" label={item} variant="outlined" color="primary" sx={{ mb: 1 }} />;
          })}
        </Box>
      </Typography>
    </Stack>
  );
};

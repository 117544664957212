import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import React from 'react';
import { useForm } from 'react-hook-form';
import { translate } from 'react-jhipster';

export const AccountForm = ({ title, setOpen, attributes, defaultValues, onClick }) => {
  const schema = validationUtils.getFormSchema(attributes);
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const cancelButton = (
    <Button color="error" onClick={() => setOpen(false)}>
      {translate('account.cancel')}
    </Button>
  );

  const submitButton = (
    <Button
      variant="contained"
      onClick={() => {
        methods.handleSubmit(data => onClick(data))();
      }}
    >
      {translate('account.submit')}
    </Button>
  );

  return (
    <>
      <DialogTitle>{translate(`account.${title}`)}</DialogTitle>
      <DialogContent dividers>
        <FormBuilder
          fields={attributes}
          defaultValue={defaultValues}
          methods={methods}
          optionalMarkerDefault={true}
          containerProps={{ marginY: 0.5, spacing: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ paddingX: 3 }}>
        {cancelButton}
        {submitButton}
      </DialogActions>
    </>
  );
};

import React from 'react';

import { Edit } from 'tabler-icons-react';
import { translate } from 'react-jhipster';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import { CustomListTabWithAction } from './common/CustomListTabWithAction';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';
import { IDialogsState } from '../../hooks/useAccountPage';
import { userProfilesApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';
import { AccountDtoRes, accountApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { Button } from '@mui/material';

export const BillingInfosTab = ({ dialogsState }: { dialogsState: IDialogsState }): JSX.Element => {
  const { data: userProfile } = userProfilesApi.endpoints.getCurrentUserProfileUsingGet.useQueryState({});
  const { data: billingAccount = {} as AccountDtoRes } = accountApi.endpoints.getAccountByKeyUsingGet.useQueryState({
    accountKey: userProfile ? userProfile.currentAccount : null,
  });
  const billingInfosList = [
    {
      icon: EmailIcon,
      key: 'account.account-page.email',
      value: billingAccount.email ? billingAccount.email : '-',
    },
    {
      icon: LocationOnIcon,
      key: 'account.account-page.address',
      value: billingAccount.address1 ? billingAccount.address1 : '-',
    },
    { icon: LocationCityIcon, key: 'account.account-page.city', value: billingAccount.city ? billingAccount.city : '-' },
    {
      icon: MarkunreadMailboxIcon,
      key: 'account.account-page.postalCode',
      value: billingAccount.postalCode ? billingAccount.postalCode : '-',
    },
    {
      icon: PublicIcon,
      key: 'account.account-page.country',
      value: billingAccount.country ? <FlagWithCountryName country={billingAccount.country} /> : '-',
    },
  ];

  return (
    <CustomListTabWithAction
      list={billingInfosList}
      actions={
        <Button
          startIcon={<Edit />}
          sx={{ ml: 2 }}
          variant="outlined"
          size="small"
          onClick={() => {
            dialogsState.setOpenBillingEditForm(true);
          }}
        >
          {translate('account.account-page.edit')}
        </Button>
      }
      sxContainer={{
        width: '50%',
        marginX: 'auto',
      }}
    />
  );
};

import React from 'react';

import { DonutLarge, Sort } from '@mui/icons-material';
import { ToggleButtonProps, Tooltip } from '@mui/material';
import { ControlledToggleButton } from 'app/components/buttons/ControlledToggleButton';

export const PIE_CHART_VIEW = 'pie';
export const TOP_N_VIEW = 'top';

export const RecommendationsCountToggleButton = ({ view, setView }) => {
  const buttons: ToggleButtonProps[] = [
    {
      value: PIE_CHART_VIEW,
      children: (
        <Tooltip title="Pie Chart View">
          <DonutLarge sx={{ fontSize: 16 }} />
        </Tooltip>
      ),
    },
    {
      value: TOP_N_VIEW,
      children: (
        <Tooltip title="Ranking View">
          <Sort sx={{ fontSize: 16 }} />
        </Tooltip>
      ),
    },
  ];
  return (
    <ControlledToggleButton
      buttons={buttons}
      value={view}
      onChange={(_event, value) => value !== null && setView(value)}
      size="small"
      color="primary"
      sx={{ m: 1 }}
      exclusive
    />
  );
};

import React from 'react';

import { EChartsOption } from 'echarts';
import { colorPaletteMode, getChartColorPalette } from 'app/shared/util/color-palette-calculator';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import { WithData } from 'app/modules/loading-lib/WithData';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { LoadingStackSkeleton } from 'app/modules/loading-lib/component/LoadingStackSkeleton';

const PieEchart = ({ data, loading }) => {
  const theme = useTheme();

  const config: EChartsOption = {
    color: getChartColorPalette(theme.palette.secondary.main, colorPaletteMode.MAIN_LIGHT_DARK),
    tooltip: {
      trigger: 'item',
      position: 'inside',
      formatter(params) {
        if (params.value > 100000 || params.value < 0.000001) {
          return `${Number(params.value).toExponential(2)}`;
        }
        return `${Number(params.value).toFixed(2)}`;
      },
    },
    series: [
      {
        type: 'pie',
        stillShowZeroSum: true,
        radius: ['60%', '90%'],
        data,
        label: {
          color: theme.palette.primary.main,
          fontSize: theme.typography.h3.fontSize,
          fontWeight: 'bold',
          position: 'center',
          formatter(params) {
            if (Number(params.value) > 100000 || Number(params.value) < 0.000001) {
              return `${Number(params.value).toExponential(2)}`;
            }
            return `${Number(params.value).toFixed(2)}`;
          },
        },
      },
    ],
  };

  return <ReactECharts style={{ height: '100%' }} option={config} />;
};

export default WithLoading(WithData(PieEchart), undefined, {
  loader() {
    return <LoadingStackSkeleton />;
  },
});

import { reduceElementsAfterN } from 'app/shared/util/array-tools';
import { DATE_HISTOGRAM } from './actions-data-transformation';

const CARDINALITY_UNIQUE_RECOS = 'cardinality#uniqueRecos';
const STERMS_RECOMMENDER_TYPE = 'sterms#recommender_type';

export const TOP_N_COUNT = 3;

export const getTotalImpressions = data => {
  return data?.aggregations[CARDINALITY_UNIQUE_RECOS].value;
};

export const getImpressionsTimeseries = data => {
  return data?.aggregations[DATE_HISTOGRAM].buckets.map(bucket => ({
    time: bucket.key_as_string,
    value: bucket[CARDINALITY_UNIQUE_RECOS].value,
  }));
};

export const getImpressionsTotalByType = data => {
  return data
    ? data.aggregations[STERMS_RECOMMENDER_TYPE].buckets.map(bucket => ({
        key: bucket.key,
        value: bucket[CARDINALITY_UNIQUE_RECOS].value,
      }))
    : [];
};

export const getImpressionsTimeseriesByType = data => {
  return data?.aggregations[STERMS_RECOMMENDER_TYPE].buckets.map(bucket => ({
    key: bucket.key,
    data:
      bucket[DATE_HISTOGRAM]?.buckets?.map(b => ({
        time: b.key_as_string,
        value: b[CARDINALITY_UNIQUE_RECOS]?.value,
      })) || [],
  }));
};

export const getImpressionsDataTopNToggler = (topNToggle, data) => {
  return topNToggle ? reduceElementsAfterN(data, TOP_N_COUNT) : data;
};

export const getRecommendationsCountData = data => {
  return data.impressions.byRecommenderType.total.map(e => ({ name: e.key, value: e.value })).sort((a, b) => a.value < b.value);
};

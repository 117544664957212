import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useCountItemsQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { useState } from 'react';

export const useItemsManagement = () => {
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const { currentData: itemCount = null } = useCountItemsQuery(
    accountProfile.accountId ? { accountId: accountProfile.accountId.substring(0, 8), filterQuery: '' } : skipToken
  );
  return {
    openUploadDialog,
    setOpenUploadDialog,
    itemCount,
  };
};

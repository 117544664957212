import React, { forwardRef, useCallback, useState } from 'react';

import { IconButton, Typography, Collapse, Alert } from '@mui/material';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { Close, ExpandMore } from '@mui/icons-material';

interface CollapsibleErrorSnackbarProps extends CustomContentProps {
  error?: any;
}

export const CollapsibleErrorSnackbar = forwardRef<HTMLDivElement, CollapsibleErrorSnackbarProps>(({ id, error, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded(oldExpanded => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} role="alert" {...props}>
      <Alert
        severity="error"
        action={
          <>
            <IconButton
              aria-label="Show more"
              size="small"
              sx={{
                padding: '8px 8px',
                transform: 'rotate(0deg)',
                transition: 'all .2s',
                ...(expanded && { transform: 'rotate(180deg)' }),
              }}
              onClick={handleExpandClick}
            >
              <ExpandMore />
            </IconButton>
            <IconButton size="small" sx={{ padding: '8px 8px', transform: 'rotate(0deg)', transition: 'all .2s' }} onClick={handleDismiss}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      >
        {props.message}
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ p: 1 }}>
          <Typography gutterBottom variant="caption" style={{ display: 'block' }}>
            Title: {error.data.title}
          </Typography>
          <Typography gutterBottom variant="caption" style={{ display: 'block' }}>
            Status: {error.status}
          </Typography>
          <Typography gutterBottom variant="caption" style={{ display: 'block' }}>
            Detail: {error.data.detail}
          </Typography>
          <Typography gutterBottom variant="caption" style={{ display: 'block' }}>
            Message: {error.data.message}
          </Typography>
        </Collapse>
      </Alert>
    </SnackbarContent>
  );
});

import React from 'react';

import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { RawGridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { UserInfoList } from './components/UserInfoList';
import { VisitorSecondaryAction } from './components/VisitorSecondaryAction';
import { Stack, Typography } from '@mui/material';
import { UserKpis } from './components/UserKpis';
import { SessionsTableWrapper } from './components/sessions-table/SessionsTableWrapper';
import { SessionsActions } from './components/sessions-table/SessionsActions';
import { SessionsLeafletMap } from './components/SessionsLeafletMap';

export const VisitorProfile = () => {
  return (
    <BasicCardLayout
      variant="div"
      header={{
        disableTypography: true,
        title: <BreadcrumbGenerator />,
        action: <VisitorSecondaryAction />,
        sx: { p: 1 },
      }}
    >
      <RawGridLayout>
        <LayoutItem gridItemProps={{ xs: 12, lg: 4 }}>
          <BasicCardLayout>
            <Stack alignItems="center" mb={1}>
              <AccountCircleIcon sx={{ height: '150px', width: '150px', color: theme => theme.palette.neutral.light }} />
              <UserInfoList />
            </Stack>
          </BasicCardLayout>
        </LayoutItem>
        <LayoutItem gridItemProps={{ xs: 12, lg: 8 }}>
          <UserKpis />
        </LayoutItem>
        <LayoutItem gridItemProps={{ xs: 12 }}>
          <SessionsLeafletMap />
        </LayoutItem>
        <LayoutItem gridItemProps={{ xs: 12 }}>
          <BasicCardLayout
            header={{
              title: <Typography variant="h4">Sessions Details</Typography>,
              action: <SessionsActions />,
            }}
            content={{ sx: { p: 0, '&:last-child': { p: 0 } } }}
          >
            <SessionsTableWrapper />
          </BasicCardLayout>
        </LayoutItem>
        {/* <LayoutItem gridItemProps={{ xs: 12, lg: 6 }}>
          <SessionTimeline />
        </LayoutItem> */}
      </RawGridLayout>
      {/* <TimeLine1 /> */}
    </BasicCardLayout>
  );
};

import React from 'react';

import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { colorPaletteMode, getChartColorPalette } from 'app/shared/util/color-palette-calculator';
import { useTheme } from '@mui/material/styles';
import { reduceElementsAfterN } from 'app/shared/util/array-tools';
import { chartFormatter } from 'app/shared/util/chartFormatter-utils';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

interface PieChartProps {
  data: any;
  dataset: {
    total: any;
    values: any;
  };
  formatter: any;
  isFetching?: boolean;
  isError: boolean;
  error: any;
}

const PieChart = ({ dataset, formatter, isFetching, error = null, isError = false }: PieChartProps) => {
  const theme = useTheme();

  const data = Array.isArray(dataset?.values) ? reduceElementsAfterN(dataset?.values, 9) : [];

  const config: EChartsOption = {
    color: getChartColorPalette(theme.palette.secondary.main, colorPaletteMode.MAIN_LIGHT_DARK),
    tooltip: {
      trigger: 'item',
      position: 'inside',
      formatter(params) {
        return `${params.name}<br/>${Number(params.value * 100).toFixed(2)} %`;
      },
    },
    series: [
      {
        type: 'pie',
        stillShowZeroSum: true,
        radius: ['60%', '90%'],
        data,
        label: {
          color: theme.palette.primary.main,
          fontSize: theme.typography.h3.fontSize,
          fontWeight: 'bold',
          position: 'center',
          formatter() {
            return chartFormatter(formatter, dataset.total);
          },
        },
      },
    ],
  };

  return <ReactECharts style={{ height: '100%', width: '100%' }} option={config} />;
};

export default WithLoadingHOC(PieChart);

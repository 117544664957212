import React from 'react';

import { HomeOutlined } from '@mui/icons-material';

import { sitemap } from 'app/shared/util/url-utils';
import { ModuleProps, productsModules } from './config/productModules';
import { useModuleState } from './hooks/useModuleState';
import { HomeModule } from './views/home/HomeModule';

export const ModuleRoot = props => {
  const { moduleName, isSubscribedProduct } = useModuleState();

  const filteredModules = Object.fromEntries(Object.entries(productsModules).filter(([name, module]) => isSubscribedProduct(name)));

  const moduleMap = {
    home: {
      sidebar: { icon: <HomeOutlined />, href: sitemap.home },
      full: <HomeModule subscribedProducts={Object.keys(filteredModules)} />,
    },
    ...filteredModules,
  } as Record<string, ModuleProps>;

  return moduleMap[moduleName]?.full;
};

import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useDeleteAllItemsMutation, useCountItemsQuery } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { Translate, translate } from 'react-jhipster';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

export const ConfirmDeleteAllItems = ({ open, setOpen }) => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const [deleteAllItems] = useDeleteAllItemsMutation();
  const { currentData: itemCount = null } = useCountItemsQuery(
    accountProfile.accountId ? { accountId: accountProfile.accountId.substring(0, 8), filterQuery: '' } : skipToken
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle typography="h4">
        <Translate contentKey="recommendation.items.delete-all-items">Delete All Items</Translate>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Translate contentKey="recommendation.items.confirm-delete-all-items">Confirm deleting all items :</Translate>
          {itemCount ? ` ${itemCount} items` : ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translate('recommendation.cancel', null, 'Cancel')}</Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            deleteAllItems({ accountId: accountProfile?.accountId.substring(0, 8) });
            handleClose();
          }}
        >
          {translate('recommendation.items.delete-all-items', null, 'Delete All Items')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';

import CohortChart from './charts/CohortChart';
import { useCohortUtils } from './hooks/useCohortUtils';

import { useUsersCohortData } from './hooks/useUsersCohortData';

const UsersCohort = () => {
  const { data } = useUsersCohortData();
  const { xAxisData, yAxisData, maxValue, values } = useCohortUtils(data || []);

  return <CohortChart xAxisData={xAxisData} yAxisData={yAxisData} values={values} maxValues={maxValue} />;
};

export default React.memo(UsersCohort);

import React, { useEffect } from 'react';

import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { translate } from 'react-jhipster';
import { KPIS } from 'app/shared/util/reducer-utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { renderCellExpand } from 'app/shared/util/data-grid-utils';
import { chartFormatter } from 'app/shared/util/chartFormatter-utils';
import { addFlex } from 'app/components/data-grid/columns/column-helpers';
import { toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import { sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import { IAnalyticsTable } from 'app/shared/model/kpis-aggregation-.model';
import { bucketingDimensionsMappingList } from 'app/shared/util/data-hub-utils';

interface BucketingDatagridProps {
  data: IAnalyticsTable;
  bucketingDimensions: any;
  selectedChart: any;
  loading?: boolean;
}

export const BucketingDatagrid = ({ data, bucketingDimensions, selectedChart, loading }: BucketingDatagridProps) => {
  const theme = useTheme();

  const columns: GridColDef[] = [];
  columns.push({
    field: bucketingDimensions,
    headerName: translate(
      `open-analytics.bucketingNameMapping.${
        bucketingDimensionsMappingList.filter(element => element.value === bucketingDimensions)[0].value
      }`,
      null,
      bucketingDimensionsMappingList.filter(element => element.value === bucketingDimensions)[0].name
    ),
    flex: 3,
    renderCell: renderCellExpand,
  });

  Object.entries(KPIS).forEach(([key, value]) => {
    columns.push({
      field: key,
      headerName: translate(`open-analytics.chart.${value.i18n}`, null, value.i18n),
      headerAlign: 'center',
      align: 'right',
      type: value.type,
      valueFormatter(v) {
        return chartFormatter(value.formatter, v.value);
      },
      renderCell: renderCellExpand,
    });
  });

  const rows =
    data && data[bucketingDimensions] && Array.isArray(data[bucketingDimensions])
      ? data[bucketingDimensions]?.map(
          (
            row: {
              [x: string]: any;
              USER_COUNT: number;
              SESSION_COUNT: number;
              ACTIVE_USER_COUNT: any;
              PAGEVIEWS_COUNT: number;
              AVG_SESSION_DURATION: any;
              BOUNCE_RATE: any;
            },
            index: any
          ) => {
            return {
              [bucketingDimensions]: row[bucketingDimensions],
              id: index,
              [KPIS.USER_COUNT.name]: Number(row.USER_COUNT),
              [KPIS.SESSIONS_PER_USER.name]: Number(row.SESSION_COUNT / row.USER_COUNT).toPrecision(2),
              [KPIS.ACTIVE_USER_COUNT.name]: Number(row.ACTIVE_USER_COUNT),
              [KPIS.SESSION_COUNT.name]: Number(row.SESSION_COUNT),
              [KPIS.PAGEVIEWS_COUNT.name]: Number(row.PAGEVIEWS_COUNT),
              [KPIS.PAGEVIEWS_PER_SESSION.name]: Number(row.PAGEVIEWS_COUNT / row.SESSION_COUNT).toPrecision(2),
              [KPIS.AVG_SESSION_DURATION.name]: Number(row.AVG_SESSION_DURATION),
              [KPIS.BOUNCE_RATE.name]: Number(row.BOUNCE_RATE),
            };
          }
        )
      : [];

  const columnVisibility = columns
    .filter(c => KPIS[c.field] !== undefined)
    .map(c => {
      return { [c.field]: KPIS[c.field].displayOn ? useMediaQuery(theme.breakpoints.up(KPIS[c.field].displayOn)) : false };
    })
    .reduce((a, b) => {
      return { ...a, ...b };
    }, {});

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    [bucketingDimensions]: true,
    ...columnVisibility,
    [selectedChart.name]: true,
  });

  useEffect(() => {
    setColumnVisibilityModel({
      [bucketingDimensions]: true,
      ...columnVisibility,
      [selectedChart.name]: true,
    });
  }, [bucketingDimensions, selectedChart]);

  return (
    <DataGrid
      autoHeight
      density="compact"
      {...toolbarProps()}
      {...sizedPagination()}
      disableColumnMenu
      disableRowSelectionOnClick
      onResize={() =>
        setColumnVisibilityModel({
          [bucketingDimensions]: true,
          ...columnVisibility,
          [selectedChart.name]: true,
        })
      }
      sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
      rows={rows}
      columns={columns.map(addFlex)}
      loading={loading}
    />
  );
};

import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Avatar, Breadcrumbs, BreadcrumbsProps, Link, Tooltip, Typography } from '@mui/material';
import { RouterLink } from 'app/config/modules/react-router-dom';
import { breadcrumbs, joinPath, sitemap } from 'app/shared/util/url-utils';
import React from 'react';
import { translate } from 'react-jhipster';
import { useLocation } from 'react-router-dom';

interface ISegment {
  to?: string;
  name: string;
  i18n?: string;
  render?: (ISegment, number) => JSX.Element;
}

interface IBreadcrumbsProps extends BreadcrumbsProps {
  segments?: ISegment[];
}

export const BreadcrumbsFactory = ({ segments, ...rest }: IBreadcrumbsProps) => {
  const location = useLocation();

  function render(value, index) {
    const last = index === segments.length - 1;
    const color = last ? 'text.primary' : 'inherit';

    return value.to ? (
      <Link component={RouterLink} underline="hover" color={color} to={value.to} key={value.to || index}>
        {translate(value.i18n, null, value.name)}
      </Link>
    ) : (
      <Typography color={color} key={last ? location.pathname : index}>
        {translate(value.i18n, null, value.name)}
      </Typography>
    );
  }

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      sx={{
        '.MuiBreadcrumbs-li': {
          zIndex: 1,
        },
      }}
      aria-label="breadcrumb"
      {...rest}
    >
      {segments.map((value, index) => {
        return value.render ? value.render(value, index) : render(value, index);
      })}
    </Breadcrumbs>
  );
};

const homeIconSegment = {
  to: sitemap.home,
  name: 'Home',
  i18n: 'breadcrumbs.home',
  render(value, index) {
    return (
      <Tooltip key={value.to || index} title={translate(value.i18n, null, value.name)}>
        <Link component={RouterLink} to={value.to}>
          <Avatar variant="iconButton">
            <HomeIcon />
          </Avatar>
        </Link>
      </Tooltip>
    );
  },
};

export const BreadcrumbGenerator = (props: BreadcrumbsProps) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  const segments = pathnames.map((value, index) => {
    const last = index === pathnames.length - 1;
    const to = joinPath(pathnames.slice(0, index + 1));
    const innerSegment = {
      ...(last ? {} : { to }),
      name: value.replace('-', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
      i18n: `breadcrumbs.${value}`,
    };
    return {
      ...innerSegment,
      ...breadcrumbs[value],
    };
  });

  return <BreadcrumbsFactory segments={[homeIconSegment, ...segments]} {...props} />;
};

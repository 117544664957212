import { useAppSelector } from 'app/config/store';
import { userScoresApi } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-scores';
import {
  getUserExplorationTimerange,
  selectAllUserExplorationFilters,
  getUserExplorationSplitLimit,
} from 'app/shared/reducers/views/user-exploration';
import { getUsersOverview } from 'app/shared/util/user-exploration-utils';

export const useUserExplorationTimeframeUserScores = () => {
  const timerange = useAppSelector(getUserExplorationTimerange);
  const filter = useAppSelector(selectAllUserExplorationFilters);
  const splitLimit = useAppSelector(getUserExplorationSplitLimit);

  const userExplorationTimeframeUserScoresQueryParams = {
    from: timerange?.fromDate,
    to: timerange?.toDate,
    // limit: splitLimit.toString(),
    scoreBodyDto: {
      filters: {
        filterEntryList: filter,
      },
    },
  };

  const userExplorationTimeframeUserScoresQueryOptions = {
    selectFromResult(res) {
      return {
        ...res,
        usersList: res.currentData,
        usersOverviewWithScore: res.currentData ? getUsersOverview(res.currentData) : null,
      };
    },
    pollingInterval: timerange?.refreshRate,
  };

  const {
    usersList = null,
    usersOverviewWithScore = [],
    isFetching,
    isLoading,
    isError,
    error,
  } = userScoresApi.endpoints.getTimeframeUserScoresUsingPost.useQueryState(
    userExplorationTimeframeUserScoresQueryParams,
    userExplorationTimeframeUserScoresQueryOptions
  );

  return {
    usersList,
    usersOverviewWithScore,
    isFetching,
    isLoading,
    isError,
    error,
  };
};

import { Stripe } from '@stripe/stripe-js';
import { useAppSelector } from 'app/config/store';
import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { getStripePromise } from 'app/shared/util/stripe-utils';
import { useEffect, useMemo, useState } from 'react';

export function useKillBillStripe() {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = useMemo<Promise<Stripe>>(() => getStripePromise(currentLocale), [currentLocale]);

  const [clientSecret, setClientSecret] = useState(undefined);

  useEffect(() => {
    microserviceHttpClient(
      CONFIG_MANAGER_API_URL + '/billing/plugins/killbill-stripe/setup-intent?paymentMethodTypes=card&paymentMethodTypes=sepa_debit',
      'post'
    ).then(res => setClientSecret(res.data.client_secret));
  }, []);

  return { stripePromise, clientSecret, accountProfile };
}

import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { translate } from 'react-jhipster';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

interface CohortChartProps {
  xAxisData: string[];
  yAxisData: string[];
  values: number[][];
  cohortSecondaryState: {
    timeStep: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
    stepNumber: number;
    displayedValueType: 'count' | 'percentage';
  };
  maxValues: number;
  onEvents: {
    click: (params: any) => void;
  };
  isFetching: boolean;
  isError: boolean;
  error: any;
}

export const CohortChart = ({
  xAxisData,
  yAxisData,
  values,
  cohortSecondaryState,
  maxValues,
  onEvents,
  isFetching,
  isError,
  error,
}: CohortChartProps) => {
  const theme = useTheme();
  const option: EChartsOption = {
    xAxis: {
      show: true,
      type: 'category',
      position: 'top',
      data: xAxisData,
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: 'black',
        // fontWeight: 'bold',
        fontSize: 12,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      show: true,
      type: 'category',
      data: yAxisData,
      axisLabel: {
        color: 'black',
        // fontWeight: 'bold',
        fontSize: 12,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    tooltip: {
      show: true,
      formatter(params) {
        if (cohortSecondaryState.displayedValueType === 'count')
          return `${translate('user-exploration.cohort.percentage')}: ${params.value[3]} %`;
        else if (cohortSecondaryState.displayedValueType === 'percentage')
          return `${translate('user-exploration.cohort.count')}: ${params.value[3]}`;
      },
      textStyle: {
        fontWeight: 'bold',
      },
    },
    visualMap: [
      {
        dimension: 2,
        min: 0,
        max: cohortSecondaryState.displayedValueType === 'count' ? maxValues : 100,
        inRange: {
          color: [theme.palette.primary.light, theme.palette.primary.dark],
        },
        show: false,
      },
    ],
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '0%',
      containLabel: true,
    },
    series: [
      {
        name: 'cohort',
        type: 'heatmap',
        data: values,
        label: {
          show: true,
          fontSize: cohortSecondaryState.stepNumber < 6 ? 15 : 13,
          fontWeight: 'bold',
          formatter(param) {
            if (cohortSecondaryState.displayedValueType === 'count') return `${param.value[2]}`;
            else return `${param.value[2]} %`;
          },
        },
        itemStyle: {
          borderColor: 'white',
          borderWidth: 1,
        },
      },
    ],
  };

  return <ReactECharts option={option} style={{ width: '100%', padding: '5px' }} onEvents={onEvents} />;
};

export default WithLoadingHOC(CohortChart);

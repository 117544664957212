import React from 'react';
import { buildEChartOptions } from '../utils/EChartOptionsBuilder';
import { useTheme } from '@mui/material/styles';
import { WithData } from 'app/modules/loading-lib/WithData';
import ReactECharts from 'echarts-for-react';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

const ActionsByTime = ({ data, actions, isFetching }) => {
  const theme = useTheme();
  return (
    <ReactECharts
      style={{ height: '100%', width: '100%' }}
      option={buildEChartOptions({
        title: { text: 'Actions By Time', textStyle: { color: theme.palette.text.primary } },
        legend: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        dataset: [
          {
            dimensions: ['time', 'value'],
            source: actions.withoutRecoId.timeseries.all ?? [],
          },
          {
            dimensions: ['time', 'value'],
            source: actions.withRecoId.timeseries.all ?? [],
          },
          {
            dimensions: ['time', 'value'],
            source: actions.timeseries.demonstrationOfInterest ?? [],
          },
          {
            dimensions: ['time', 'value'],
            source: actions.timeseries.conversion ?? [],
          },
          {
            dimensions: ['time', 'value'],
            source: actions.timeseries.other ?? [],
          },
        ],
        xAxis: {
          type: 'time',
        },
        yAxis: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        series: [
          {
            name: 'Actions',
            type: 'bar',
            emphasis: {
              focus: 'series',
            },
            encode: {
              x: 'time',
              y: 'value',
            },
            stack: 'actions',
            barWidth: 10,
            datasetIndex: 0,
          },
          {
            name: 'Actions From Recommendations',
            type: 'bar',
            emphasis: {
              focus: 'series',
            },
            encode: {
              x: 'time',
              y: 'value',
            },
            stack: 'actions',
            datasetIndex: 1,
          },

          {
            name: 'Demonstration Of Interest Actions',
            type: 'bar',
            emphasis: {
              focus: 'series',
            },
            encode: {
              x: 'time',
              y: 'value',
            },
            stack: 'actionsType',
            barWidth: 20,
            datasetIndex: 2,
          },
          {
            name: 'conversion Actions',
            type: 'bar',
            emphasis: {
              focus: 'series',
            },
            encode: {
              x: 'time',
              y: 'value',
            },
            stack: 'actionsType',
            datasetIndex: 3,
          },
          {
            name: 'Other Actions',
            type: 'bar',
            emphasis: {
              focus: 'series',
            },
            encode: {
              x: 'time',
              y: 'value',
            },
            stack: 'actionsType',
            datasetIndex: 4,
          },
        ],
      })}
    />
  );
};

export default WithLoadingHOC(WithData(ActionsByTime));

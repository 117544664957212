import React, { Fragment, SyntheticEvent, useState } from 'react';

import { Badge, BadgeProps, Popover, PopoverProps, Tooltip, TooltipProps } from '@mui/material';

export type SetAnchorType = (el: SyntheticEvent<HTMLElement, Event>) => void;

export interface ControlledPopoverProps {
  action: (onOpen: SetAnchorType) => JSX.Element;
  tooltip?: Omit<TooltipProps, 'children'>;
  badge?: Partial<BadgeProps>;
  popover?: Partial<PopoverProps>;
  children: React.ReactNode;
}

export const ControlledPopover = ({ action, tooltip, badge, popover, children }: ControlledPopoverProps) => {
  const { onClose: customClose, ...popoverProps } = popover;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const onOpen: SetAnchorType = el => setAnchorEl(el.currentTarget);
  const onClose = (event, reason) => {
    customClose?.(event, reason);
    setAnchorEl(null);
  };

  const actionNode = action(onOpen);
  const badgeNode = badge ? <Badge {...badge}>{actionNode}</Badge> : actionNode;
  const tooltipNode = tooltip ? <Tooltip {...tooltip}>{badgeNode}</Tooltip> : badgeNode;

  return (
    <Fragment>
      {tooltipNode}

      <Popover open={openPopover} anchorEl={anchorEl} onClose={onClose} {...popoverProps}>
        {children}
      </Popover>
    </Fragment>
  );
};

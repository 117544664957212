import React from 'react';

import UsersOverviewTable from './components/UsersOverviewTable';
import { useUsersOverview } from './hooks/useUsersOverview';

export const UserOverviewList = () => {
  const { user_overview, isFetching, isError, error } = useUsersOverview();

  return <UsersOverviewTable data={user_overview} isFetching={isFetching} isError={isError} error={error} />;
};

import React from 'react';

import { GridColDef, GridRowParams, GridActionsCellItem } from '@mui/x-data-grid';
import { subFieldToggle } from 'app/components/data-grid/columns/column-helpers';
import { CollapsiblePropertiesGrid, PropertiesType } from 'app/components/properties/properties-grid';
import { ContentCopy, DeleteForever, Edit } from '@mui/icons-material';
import { translate } from 'react-jhipster';
import { Tooltip } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function sortProperties(properties): PropertiesType {
  return Object.entries<string>(properties).sort((a, b) => (a[0] < b[0] ? -1 : 1));
}

export const useAllItemsDataGridColumns = (setDeleteItem, setEditItem): GridColDef[] => {
  return [
    { field: 'id', flex: 1 },
    {
      field: 'title',
      flex: 4,
      ...subFieldToggle('description', {
        openLabel: 'recommendation.items-table.show-description',
        closeLabel: 'recommendation.items-table.hide-description',
      }),
    },
    {
      field: 'attributes',
      flex: 2,
      sortable: false,
      renderCell: params => (
        <CollapsiblePropertiesGrid properties={sortProperties(params.value)} hideMoreThan={2} gridProps={{ spacing: 1 }} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions(params: GridRowParams) {
        return [
          <GridActionsCellItem
            key="edit"
            icon={
              <Tooltip title={translate('recommendation.items.items-table.edit-item', null, 'Edit Item')}>
                <Edit
                  sx={{
                    ':hover': {
                      color: theme => theme.palette.primary.light,
                    },
                  }}
                />
              </Tooltip>
            }
            onClick={() => {
              setEditItem(params.row);
            }}
            label={translate('recommendation.items.items-table.edit-item', null, 'Edit Item')}
          />,
          <GridActionsCellItem
            key="copyClipboard"
            icon={
              <CopyToClipboard text={params.id} onCopy={() => params.id}>
                <Tooltip title={translate('recommendation.items.items-table.copy-item-id', null, 'Copy Item ID')}>
                  <ContentCopy
                    sx={{
                      ':hover': {
                        color: theme => theme.palette.primary.light,
                      },
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
            }
            label={translate('recommendation.items.items-table.copy-item-id', null, 'Copy Item ID')}
          />,
          <GridActionsCellItem
            key="delete"
            icon={
              <Tooltip title={translate('recommendation.items.items-table.delete-item', null, 'Delete Item')}>
                <DeleteForever
                  sx={{
                    ':hover': {
                      color: theme => theme.palette.error.light,
                    },
                  }}
                />
              </Tooltip>
            }
            onClick={() => {
              setDeleteItem(params.row);
            }}
            label={translate('recommendation.items.items-table.delete-item', null, 'Delete Item')}
          />,
        ];
      },
    },
  ];
};

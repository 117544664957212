import React, { useContext } from 'react';

import UserCohortSecondary from '../cohort/secondary/UserCohortSecondary';
import { Button } from '@mui/material';
import { Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUserExplorationSelectedCohortCell, setUserExplorationSelectedCohortCell } from 'app/shared/reducers/views/user-exploration';

export const UsersCohortSecondary = () => {
  const selectedCohortCell = useAppSelector(getUserExplorationSelectedCohortCell);
  const dispatch = useAppDispatch();

  return (
    <>
      {selectedCohortCell.length > 0 ? (
        <Button variant="outlined" onClick={() => dispatch(setUserExplorationSelectedCohortCell([]))} size="small">
          <Translate contentKey="user-exploration.users-table.return-to-cohort">Return to Cohort</Translate>
        </Button>
      ) : (
        <UserCohortSecondary />
      )}
    </>
  );
};

import React from 'react';

import { useAppbarRef } from '../hooks/useAppbarRef';
import { AppBar, Toolbar } from '@mui/material';
import { AppLogo } from '../components/AppLogo';
import { Box } from '@mui/system';
import { SideBarButton } from '../components/header/side-bar-button/SideBarButton';
import { Outlet } from 'react-router-dom';

export const WorkspaceAppbar = () => {
  const appBarRef = useAppbarRef();

  return (
    <AppBar position="fixed" color="primary" ref={appBarRef} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} elevation={1}>
      <Toolbar>
        <SideBarButton />
        <AppLogo />
        <Box sx={{ flexGrow: 1 }} />
        <Outlet />
      </Toolbar>
    </AppBar>
  );
};

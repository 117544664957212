import { generatePath } from 'react-router-dom';

export const REDIRECT_URL = 'redirectURL';

export function joinPath(segments: string[], isAbsolute = true, separator = '/') {
  const relativePath = segments.join(separator);
  return isAbsolute ? '/'.concat(relativePath) : relativePath;
}

const segments = {
  home: 'home',
  user: 'user',
  webAnalytics: 'web-analytics',
  recommendations: 'recommendations',
  greensights: 'greensights',
};

export const patterns = {
  home: joinPath([':moduleName', segments.home]),
  user: joinPath([segments.user, ':userId']),
  product: {
    settings: joinPath([':productName', 'settings']),
  },
};

export const sitemap = {
  root: joinPath([]),
  login: joinPath(['oauth2', 'authorization', 'oidc']),
  register: joinPath(['api', 'register']),
  logout: joinPath(['logout']),
  recommendationAlgorithms: joinPath(['algorithms']),
  // error: generatePath(['error']),

  // Global application links
  home: joinPath([segments.home]),
  welcome: joinPath(['welcome']),
  account: joinPath(['account']),
  user: joinPath([segments.user]),
  setup: joinPath(['applications']),
  error: joinPath(['error']),

  'web-analytics': {
    home: generatePath(patterns.home, { moduleName: segments.webAnalytics }),
    settings: joinPath([segments.webAnalytics, 'settings']),
    audience: joinPath([segments.webAnalytics, 'audience']),
    sessions: joinPath([segments.webAnalytics, 'sessions']),
    visitor: joinPath([segments.webAnalytics, 'audience', ':userId']),
    export: joinPath([segments.webAnalytics, 'export']),
    tagManager: joinPath([segments.webAnalytics, 'tag-manager']),
  },

  recommendations: {
    home: generatePath(patterns.home, { moduleName: segments.recommendations }),
    settings: joinPath([segments.recommendations, 'settings']),
    strategies: joinPath([segments.recommendations, 'strategies']),
    corpus: joinPath([segments.recommendations, 'corpus']),
    itemsManagement: joinPath([segments.recommendations, 'items']),
    widget: joinPath([segments.recommendations, 'widget']),
  },

  greensights: {
    home: generatePath(patterns.home, { moduleName: segments.greensights }),
    settings: joinPath([segments.greensights, 'settings']),
    board: joinPath([segments.greensights, 'board']),
  },

  ext: {
    docs: {
      root: '',
      webAnalytics: 'docs/category/web-analytics',
      greensights: 'docs/category/greensights',
      recommendations: 'docs/category/recommendations',
    },
    erp: {},
  },

  // Only for Hurence DEV profile
  devOnly: {
    admin: joinPath(['admin']), // Legacy administration page generated from JHipster
    muiComponentSamples: joinPath(['mui-component-samples']), // MUI component samples
  },
};

export const breadcrumbs = {
  [segments.webAnalytics]: {
    to: sitemap['web-analytics'].home,
  },
  [segments.recommendations]: {
    to: sitemap.recommendations.home,
  },
  [segments.greensights]: {
    to: sitemap.greensights.home,
  },
};

export const getLoginUrl = () => {
  const port = location.port ? `:${location.port}` : '';

  // If you have configured multiple OIDC providers, then, you can update this URL to /login.
  // It will show a Spring Security generated login page with links to configured OIDC providers.
  return `//${location.hostname}${port}${location.pathname}oauth2/authorization/oidc`;
};

export const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    return location.protocol + '//' + location.host; // (or whatever)
  } else {
    return SERVER_API_URL;
  }
};

export function documentationUrl(path: string = '', baseUrl: string = getBaseUrl()) {
  try {
    const url = new URL(baseUrl);

    // Check if the current host starts with "www." and replace it if needed
    if (url.host.startsWith('www.')) {
      url.host = `support.${url.host.slice(4)}`;
    } else {
      url.host = `support.${url.host}`;
    }

    return joinPath([url.origin, path], url.protocol == null);
  } catch (error) {
    // Handle invalid URLs
    console.error('Error parsing the base URL:', error);
    return baseUrl;
  }
}

export function registerUrl(path: string, baseUrl: string = getBaseUrl()) {
  return joinPath([sitemap.register, 'redirect_uri=' + baseUrl + path], false, '?');
}

// login: '/oauth2/authorization/oidc',
// logout: '/logout',
// admin: '/admin',
// muiComponentSamples: '/mui-component-samples',
// welcome: '/welcome',
// account: '/account',
// profile: '/profile',
// setup: '/setup',
// setting: '/subscription/:id',
// overview: '/web-analytics/overview',
// audience: '/web-analytics/audience',
// sessions: '/web-analytics/sessions',
// visitor: '/web-analytics/visitor',
// reco: '/recommendations/inventories',
// greensights: '/greensights/board',
// moduleRoot: moduleName => `/home/${moduleName}`,
// recoStrategies: `/recommendations/strategies`,

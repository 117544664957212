import { combineReducers } from 'redux';

import usersCohort from './users-cohort';
import lineChartsTabs from './line-charts-tabs';
import mapChart from './map-chart';
import pieCharts from './pie-charts';
import topUsers from './top-users';
import activeUsers from './active-users';
import topMetrics from './top-metrics';
import exportCSV from './tools/export/index';

const webAnalytics = combineReducers({
  usersCohort,
  lineChartsTabs,
  mapChart,
  pieCharts,
  topUsers,
  activeUsers,
  topMetrics,
  exportCSV,
});

export default webAnalytics;

import React, { useState } from 'react';

import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { BreadcrumbGenerator } from 'app/components/breadcrumb/breadcrumb-factory';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { Translate, translate } from 'react-jhipster';
import { AllCorpus } from './components/AllCorpus';
import { Add } from '@mui/icons-material';
import { CreateCorpus } from './components/CreateCorpus';

export const CatalogManagement = () => {
  const [openNewCorpus, setOpenNewCorpus] = useState(false);

  return (
    <>
      <Stack direction="row" alignItems="center" paddingBottom={3}>
        <BreadcrumbGenerator />
      </Stack>

      <BasicCardLayout
        header={{
          title: (
            <Typography variant="h4">
              <Translate contentKey="recommendation.corpus.all-corpus">All Corpus</Translate>
            </Typography>
          ),
          action: (
            <Tooltip title={translate('recommendation.corpus.create-corpus', null, 'Create new corpus')}>
              <IconButton
                color="primary"
                onClick={() => {
                  setOpenNewCorpus(prev => !prev);
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          ),
        }}
        card={{ sx: { mt: 3 } }}
      >
        <CreateCorpus open={openNewCorpus} setOpen={setOpenNewCorpus} />
        <AllCorpus />
      </BasicCardLayout>
    </>
  );
};

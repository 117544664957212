import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestFilterEntry } from '../api/data-hub-cloud-services/store/data-exploration';

const filtersAdapter = createEntityAdapter<RequestFilterEntry>({
  selectId: filter => filter.key,
  sortComparer: (a, b) => a.key.localeCompare(b.key),
});

interface ITimerange {
  chosenRange: string | Date[];
  fromDate: string;
  toDate: string;
  refresh: boolean;
  refreshRate: number;
  step: string;
  live: any;
}

const initialState = {
  bucketingDimension: 'FIRST_VISITED_PAGE',
  filter: filtersAdapter.getInitialState(),
  timerange: undefined as ITimerange,
  selectedChart: {
    name: 'USER_COUNT',
    value: 'count',
    type: 'number',
    i18n: 'Users',
    formatter: 'number',
    displayOn: 'md',
  },
};

export type DataExplorationState = Readonly<typeof initialState>;

export const dataExplorationSlice = createSlice({
  name: 'dataExploration',
  initialState: initialState as DataExplorationState,
  reducers: {
    setDataExplorationTimerange(state, action) {
      state.timerange = action.payload;
    },
    setDataExplorationSelectedChart(state, action) {
      state.selectedChart = action.payload;
    },
    setDataExplorationBucketingDimension(state, action) {
      state.bucketingDimension = action.payload;
    },
    addDataExplorationFilter(state, action) {
      filtersAdapter.setOne(state.filter, action.payload);
    },
    deleteDataExplorationFilter(state, action) {
      filtersAdapter.removeOne(state.filter, action.payload);
    },
    deleteAllDataExplorationFilters(state, action) {
      filtersAdapter.removeAll(state.filter);
    },
  },
});

export const {
  selectAll: selectAllDataExplorationFilters,
  selectById: selectDataExplorationFiltersById,
  selectIds: selectDataExplorationFiltersIds,
} = filtersAdapter.getSelectors((state: any) => state.views.dataExploration.filter);
export const getDataExplorationBucketingDimension = state => state.views.dataExploration.bucketingDimension;
export const getDataExplorationSelectedChart = state => state.views.dataExploration.selectedChart;
export const getDataExplorationTimerange = state => state.views.dataExploration.timerange;

export const {
  setDataExplorationTimerange,
  setDataExplorationSelectedChart,
  setDataExplorationBucketingDimension,
  addDataExplorationFilter,
  deleteDataExplorationFilter,
  deleteAllDataExplorationFilters,
} = dataExplorationSlice.actions;

export default dataExplorationSlice.reducer;

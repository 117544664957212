import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useCreateAccountUsingPostMutation,
  useGetAccountByKeyUsingGetQuery,
} from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { useGetCurrentAccountProfileUsingGetQuery } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import {
  GetCurrentUserProfileUsingGetApiResponse,
  useGetCurrentUserAccountsUsingGetQuery,
  useGetCurrentUserProfileUsingGetQuery,
  useSetCurrentAccountUsingPutMutation,
} from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';
import { sitemap } from 'app/shared/util/url-utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useFetchUserAccounts = () => {
  const navigate = useNavigate();
  const { data: userProfile = {} as GetCurrentUserProfileUsingGetApiResponse, isError: isUserProfileError } =
    useGetCurrentUserProfileUsingGetQuery({});
  const { isError: isCurrentAccountProfileError } = useGetCurrentAccountProfileUsingGetQuery(userProfile.currentAccount ? {} : skipToken);
  const { isError: isAccountBundlesError } = useGetAccountByKeyUsingGetQuery(
    userProfile.currentAccount ? { accountKey: userProfile.currentAccount } : skipToken
  );
  const { isError: isCurrentUserAccountsError } = useGetCurrentUserAccountsUsingGetQuery(
    userProfile.currentAccount ? undefined : skipToken
  );

  const [setCurrentAccount] = useSetCurrentAccountUsingPutMutation();
  const [createEmptyBillingAccount] = useCreateAccountUsingPostMutation({});

  if (isUserProfileError) {
    navigate(sitemap.error, { state: { message: 'Failed to fetech user profile' } });
  } else if (isCurrentAccountProfileError) {
    navigate(sitemap.error, { state: { message: 'Failed to fetech current account profile' } });
  } else if (isAccountBundlesError) {
    navigate(sitemap.error, { state: { message: 'Failed to fetech account bundles' } });
  } else if (isCurrentUserAccountsError) {
    navigate(sitemap.error, { state: { message: 'Failed to fetech current user accounts' } });
  }

  useEffect(() => {
    if (!userProfile.currentAccount && userProfile.accounts) {
      if (userProfile.accounts.length > 0) {
        setCurrentAccount({ userEmail: userProfile.email, accountId: userProfile.accounts[0].accountId });
      } else {
        createEmptyBillingAccount({ accountDtoReq: { currency: 'EUR' } });
      }
    }
  }, [userProfile]);
};

import React from 'react';

import { List, ListItemButton, ListItemIcon, ListItemText, Typography, Tooltip } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Translate } from 'react-jhipster';

export const CollapseSidebar = ({ open, handleDrawerToggle }) => {
  return (
    <List sx={{ display: { xs: 'none', md: 'block' } }}>
      {open ? (
        <ListItemButton sx={{ px: 1 }} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <ChevronLeftIcon />
          </ListItemIcon>
          <ListItemText
            sx={{
              ml: 2,
              my: 0,
            }}
            primary={
              <Typography>
                <Translate contentKey="module-factory.collapse-sidebar">Collapse sidebar</Translate>
              </Typography>
            }
          />
        </ListItemButton>
      ) : (
        <ListItemButton onClick={handleDrawerToggle} sx={{ px: 1, justifyContent: 'center' }}>
          <Tooltip title={<Translate contentKey="module-factory.expand-sidebar">Expand sidebar</Translate>} placement="right" arrow>
            <ListItemIcon>
              <ChevronRightIcon />
            </ListItemIcon>
          </Tooltip>
        </ListItemButton>
      )}
    </List>
  );
};

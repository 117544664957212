import React from 'react';
import { AccordionDetails, AccordionDetailsProps } from '@mui/material';
import { PropertiesGrid } from 'app/components/properties/properties-grid';
import { Translate } from 'react-jhipster';

interface IPaymentMethodInfo {
  title: string;
  i18n: string;
  value: any;
  renderPaymentInfo?: (info: { title: string; i18n: string; value: any }, index: number) => [React.ReactNode, string];
  // renderPaymentInfo?: (index: number) => [React.ReactNode, string];
}

interface IPaymentMethodAccordionDetailsProps extends AccordionDetailsProps {
  paymentMethodInfos: IPaymentMethodInfo[] | React.ReactNode[];
}

export const PaymentMethodAccordionDetails = ({ paymentMethodInfos, ...rest }: IPaymentMethodAccordionDetailsProps) => {
  const renderPaymentInfo = (info: { title: string; i18n: string; value: any }, index: number): [React.ReactNode, string] => {
    return [
      <Translate key={index} contentKey={info.i18n}>
        {info.title}
      </Translate>,
      info.value,
    ];
  };

  return (
    <AccordionDetails {...rest}>
      <PropertiesGrid
        gridProps={{ spacing: 1 }}
        properties={paymentMethodInfos.map((paymentMethodInfo, index: number) => {
          return paymentMethodInfo.renderPaymentInfo
            ? paymentMethodInfo.renderPaymentInfo(paymentMethodInfo, index)
            : renderPaymentInfo(paymentMethodInfo, index);
        })}
      />
    </AccordionDetails>
  );
};

import React from 'react';

import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { bucketingDimensionsMappingList } from 'app/shared/util/data-hub-utils';
import { translate } from 'react-jhipster';
import { BucketingDatagrid } from '../BucketingDatagrid';
import { Typography } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { getDataExplorationBucketingDimension, getDataExplorationSelectedChart } from 'app/shared/reducers/views/data-exploration';
import { useDataExplorationKpisPerKpi } from '../../hooks/useDataExplorationKpisPerKpi';
import { ErrorComponent } from 'app/modules/loading-lib/component/ErrorComponent';

export const BucketingDatagridCard = () => {
  const bucketingDimension = useAppSelector(getDataExplorationBucketingDimension);
  const selectedChart = useAppSelector(getDataExplorationSelectedChart);
  const { analytics_table_data, isFetching, error, isError } = useDataExplorationKpisPerKpi();
  return (
    <BasicCardLayout
      header={{
        title: (
          <Typography variant="h4">
            {translate(`open-analytics.splitBy`) +
              translate(
                `open-analytics.bucketingNameMapping.${bucketingDimensionsMappingList.find(i => i.value === bucketingDimension).value}`
              )}
          </Typography>
        ),
        sx: {
          py: 1,
          pb: 0,
        },
      }}
      content={{ sx: { minHeight: '300px' } }}
      card={{ sx: { mt: 2 } }}
    >
      {isError ? (
        <ErrorComponent error={error} />
      ) : (
        <BucketingDatagrid
          data={analytics_table_data}
          bucketingDimensions={bucketingDimension}
          selectedChart={selectedChart}
          loading={isFetching}
        />
      )}
    </BasicCardLayout>
  );
};

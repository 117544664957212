import { FieldProp } from 'app/modules/form-builder-lib';
import React from 'react';
import { Box } from '@mui/system';
import Flag from 'react-world-flags';
import { translate } from 'react-jhipster';
import { FlagWithCountryName } from 'app/components/country-flag/Flag';

const countryOptions = ['FR', 'US'];
const localeOptions = ['en_US', 'fr_FR'];

export function accountDetailsAttributes(): Array<FieldProp> {
  return [
    {
      component: 'text-field',
      label: 'welcome.form.name',
      attribute: 'name',
      validationType: 'string',
      props: {
        autoFocus: true,
      },
      /* validations: [
        ['required', true],
        ['matches', [/[a-z]/i, 'Can only contain letters']],
      ], */
    },
    {
      component: 'autocomplete',
      options: localeOptions.map(i => {
        const label = translate(`language.${i}`, null, i);
        return { value: i, label };
      }),
      attribute: 'locale',
      label: 'welcome.form.locale',
      optionConfig: {
        value: 'value',
        label: 'label',
      },
    },
    {
      component: 'text-field',
      attribute: 'company',
      label: 'welcome.form.company',
    },
    {
      component: 'text-field',
      attribute: 'phone',
      label: 'welcome.form.phone',
    },
  ];
}

export function accountAddressAttributes(): Array<FieldProp> {
  return [
    {
      component: 'text-field',
      validationType: 'string',
      attribute: 'email',
      label: 'welcome.form.email',
      validations: [['email', true]],
    },
    {
      component: 'text-field',
      validationType: 'string',
      attribute: 'address1',
      label: 'welcome.form.address',
    },
    {
      component: 'text-field',
      col: {
        xs: 9,
      },
      validationType: 'string',
      attribute: 'city',
      label: 'welcome.form.city',
    },
    {
      component: 'text-field',
      col: {
        xs: 3,
      },
      attribute: 'postalCode',
      label: 'welcome.form.postalCode',
      // TODO: Add validation of postal code (lenght, chars) by country?
    },
    {
      component: 'autocomplete',
      options: countryOptions.map(i => {
        const label = translate(`countries.${i}`, null, i);
        return { value: i, label };
      }),
      optionConfig: {
        value: 'value',
        label: 'label',
      },
      attribute: 'country',
      label: 'welcome.form.country',
      props: {
        renderOption: (props, option) => <FlagWithCountryName country={option.value} {...props} />,
      },
    },
  ];
}

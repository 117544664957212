import { Clear } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { ControlledTabulate, TabItem } from 'app/components/tabulation';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { RawStackLayout } from 'app/modules/layout-builder-lib/components/layout/StackLayout';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { getRecoWidgetMediaScreenType, setLayoutStyleValue } from 'app/shared/reducers/views/recommendations/widget';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { MediaStyleFormType } from '../hooks/forms/useMediasStyleForm';
import { CssLayoutTabsType, cssLayoutTabs } from '../utils/CssLayout';
import { StylesFormBuilder } from './StylesFormBuilder';

export const LayoutFormBuilder = ({
  methods,
  style,
  itemStyle,
}: {
  methods: UseFormReturn<any, any>;
  style: MediaStyleFormType;
  itemStyle: MediaStyleFormType;
}) => {
  const { getValues, setValue } = methods;
  const mediaScreenType = useAppSelector(getRecoWidgetMediaScreenType);
  const layout = getValues('value')[mediaScreenType] ?? 'false';
  const dispatch = useAppDispatch();

  const layoutTabs: CssLayoutTabsType = [
    ...cssLayoutTabs,
    ...(mediaScreenType === 'None'
      ? []
      : [
          {
            tab: {
              value: 'false',
              icon: (
                <Tooltip title="Reset">
                  <Clear />
                </Tooltip>
              ),
            },
            panel: { children: <Typography>{mediaScreenType} will use the default layout</Typography> },
          },
        ]),
  ];

  return (
    <ControlledTabulate
      variant="fullWidth"
      tabValue={layout}
      setTabValue={value => {
        setValue('value', value);
        dispatch(setLayoutStyleValue({ value, mediaScreenType }));
      }}
    >
      {layoutTabs?.map((item, index) => (
        <TabItem key={index} tab={item.tab} panel={{ sx: { width: '25vw' }, ...item.panel }}>
          {item.panel?.children || (
            <RawStackLayout>
              {item.containerFields && (
                <BasicCardLayout>
                  <StylesFormBuilder index={index} style={style[mediaScreenType]} fieldProps={item.containerFields} />
                </BasicCardLayout>
              )}
              {item.itemFields && (
                <BasicCardLayout>
                  <StylesFormBuilder index={index} style={itemStyle[mediaScreenType]} fieldProps={item.itemFields} />
                </BasicCardLayout>
              )}
            </RawStackLayout>
          )}
        </TabItem>
      ))}
    </ControlledTabulate>
  );
};

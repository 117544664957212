import React from 'react';

import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { EnrolledProducts } from './EnrolledProducts';
import { AvailableProducts } from './AvailableProducts';

export const MiniProductsPanel = ({ uniqueActiveProducts, otherProducts }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1}>
      <EnrolledProducts uniqueActiveProducts={uniqueActiveProducts} />
      {uniqueActiveProducts.length > 0 && otherProducts.length > 0 && <Divider sx={{ my: 5 }} flexItem />}
      <AvailableProducts otherProducts={otherProducts} />
    </Box>
  );
};

import React from 'react';

import { CardMedia, Typography } from '@mui/material';
import { GridLayout } from 'app/modules/layout-builder-lib/components/layout/GridLayout';
import { LayoutItem } from 'app/modules/layout-builder-lib/components/LayoutItem';
import { Translate } from 'react-jhipster';
import { variables } from '../../LandingPage';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';

export const Greensights = () => {
  return (
    <GridLayout justifyContent={{ lg: 'space-between', xs: 'none' }}>
      <LayoutItem
        gridItemProps={{
          xs: 12,
          lg: 5,
          md: 6,
        }}
        cardOptions={{
          variant: 'div',
          card: { sx: { overflow: 'visible' } },
          content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        }}
      >
        <CardMedia
          sx={{
            backgroundRepeat: 'round',
            aspectRatio: '1.8',
            borderRadius: '2%',
            boxShadow: '0 4px 8px 0 grey',
            height: '25vh',
            mx: 'auto',
          }}
          image="content/img/landing-page/greensights.png"
        />
      </LayoutItem>
      <LayoutItem
        gridItemProps={{
          xs: 12,
          lg: 4,
          md: 6,
          sx: {
            textAlign: { xs: 'center', md: 'left' },
          },
          alignSelf: 'center',
        }}
        cardOptions={{
          variant: 'div',
          header: {
            disableTypography: true,
            title: (
              <Typography fontSize={'2.2rem'}>
                <Translate contentKey={'landingproducts.greensights.title'} interpolate={{ ...variables }}>
                  Greensights
                </Translate>
              </Typography>
            ),
          },
          content: { sx: { p: 0, '&:last-child': { p: 0 } } },
        }}
      >
        <Typography fontSize="1.1rem" sx={{ mt: '3vh', mb: '5vh', textAlign: 'justify' }}>
          <Translate contentKey={'landing.products.greensights.summary'} interpolate={{ ...variables }}>
            Insights over your CO2 impact
          </Translate>
        </Typography>
      </LayoutItem>
    </GridLayout>
  );
};

export const MiniGreensights = () => {
  return (
    <BasicCardLayout
      variant="div"
      header={{
        disableTypography: true,
        title: (
          <Typography fontSize={'2.2rem'} textAlign="center" mb="5vh">
            <Translate contentKey={'landingproducts.greensights.title'} interpolate={{ ...variables }}>
              Greensights
            </Translate>
          </Typography>
        ),
      }}
      card={{ sx: { overflow: 'visible' } }}
      content={{ sx: { p: 0, '&:last-child': { p: 0 } } }}
      media={{
        // sx: { width: '70%', mx: 'auto' },
        children: (
          <CardMedia
            sx={{
              backgroundRepeat: 'round',
              aspectRatio: '1.8',
              borderRadius: '2%',
              boxShadow: '0 4px 8px 0 grey',
              maxHeight: '40vh',
              mx: 'auto',
            }}
            image="content/img/landing-page/greensights.png"
          />
        ),
      }}
    >
      <Typography fontSize="1.1rem" sx={{ mt: '3vh', mb: '5vh', textAlign: 'justify' }}>
        <Translate contentKey={'landing.products.greensights.summary'} interpolate={{ ...variables }}>
          Insights over your CO2 impact
        </Translate>
      </Typography>
    </BasicCardLayout>
  );
};

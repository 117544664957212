import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

import getStore from './config/store';
import { registerLocale } from './config/translation';
import setupAxiosInterceptors from './config/axios-interceptor';
import { clearAuthentication } from './shared/reducers/authentication';
import ErrorBoundary from './shared/error/error-boundary-catcher';
import AppComponent from './app';
import { loadIcons } from './config/icon-loader';
import axios from 'axios';
import { arrayParamsInterceptor } from './shared/api/microservices';

const store = getStore();
registerLocale(store);

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

axios.interceptors.request.use(
  config => arrayParamsInterceptor(config),
  error => Promise.reject(error)
);

loadIcons();

const rootEl = document.getElementById('root');

const root = ReactDOMClient.createRoot(rootEl);

const render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        <Component />
      </Provider>
    </ErrorBoundary>
  );

render(AppComponent);

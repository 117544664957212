import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { useRestrictedPage } from 'app/shared/layout/restricted/hooks/useRestrictedPage';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import { AccountStatus } from 'app/shared/reducers/account-profiles/account-profile';
import { sitemap } from 'app/shared/util/url-utils';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';

export const AppBackdropWrapper = () => {
  useRestrictedPage();
  const { appLoading, appStarting } = useAppSelector(state => state.appState);
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const location = useLocation();

  if (accountProfile.status === AccountStatus.BOOTSTRAP && location.pathname !== sitemap.home)
    return <Navigate replace to={sitemap.home} state={{ from: location.pathname }} />;

  return (
    <Box sx={{ m: 2 }}>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={appLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!appStarting && <Outlet />}
    </Box>
  );
};

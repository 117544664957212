import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { MenuToolbar } from '../layout/main-layouts/menubar';

export const MenubarRoutes = () => {
  return (
    <Routes>
      <Route element={<MenuToolbar />}></Route>
      <Route path="*" element={null} />
    </Routes>
  );
};

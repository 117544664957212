import React, { Fragment, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Box, ButtonBase, ButtonBaseProps, ImageList, ImageListProps, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useDimensions } from '../Hooks/useDimensions';
import { CommonFieldProps, ImageColMap, ImagePickerFieldProps, ImagePickerObject } from './props/FieldProps';
import ErrorText from './Widgets/ErrorText';
import { Title } from './Widgets/Title';

export interface StandardImagePickerProps extends CommonFieldProps<'image-picker'>, ImagePickerFieldProps {
  attribute: Required<CommonFieldProps<'image-picker'>>['attribute'];
}

type StyledProps = {
  fieldConfig: StandardImagePickerProps;
};

const ImageListRoot = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  overflow: 'hidden',
  cursor: 'pointer',
}));

const StyledImageList = styled(ImageList)(() => ({
  width: '100%',
  marginBottom: `2px !important`,
  // Promote into its own layer. Maintains high FPS at a memory cost
  transform: 'translateZ(0)',
}));

const ImageContainerRoot = styled('div')(() => ({
  display: 'inline-block',
  position: 'relative',
  margin: '2px',
  width: '100%',
}));

const ImageContainerSizer = styled('div')<StyledProps>(({ fieldConfig }) => ({
  marginTop: `${(Number(fieldConfig.aspectRatio?.[1] || 1) / Number(fieldConfig.aspectRatio?.[0] || 1)) * 100}%`,
}));

const ImageContainer = styled(Box)(() => ({
  position: 'absolute',
  inset: 0,
}));

const Image = styled('img')(() => ({
  borderRadius: '4px',
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
}));

const LabelContainer = styled('div')<StyledProps>(({ fieldConfig }) => ({
  margin: '4px',
  overflow: 'hidden',
  height: (fieldConfig.labelLines || 2) * (getLabelFontSize(fieldConfig) + 2),
}));

const Label = styled(Typography)<StyledProps>(({ fieldConfig }) => ({
  display: '-webkit-box',
  boxOrient: 'vertical',
  lineClamp: fieldConfig.labelLines,
  lineHeight: `${getLabelFontSize(fieldConfig) + 2}px`,
  textAlign: 'center',
}));

const SubLabelContainer = styled('div')<StyledProps>(({ fieldConfig }) => ({
  margin: '4px',
  overflow: 'hidden',
  height: (fieldConfig.subLabelLines || 2) * (getSubLabelFontSize(fieldConfig) + 2),
}));

const SubLabel = styled(Typography)<StyledProps>(({ fieldConfig }) => ({
  display: '-webkit-box',
  boxOrient: 'vertical',
  lineClamp: fieldConfig.subLabelLines || 2,
  lineHeight: `${getSubLabelFontSize(fieldConfig) + 2}px`,
  textAlign: 'center',
}));

const getLabelFontSize = (fieldConfig: StandardImagePickerProps): number =>
  fieldConfig.labelProps?.style?.fontSize ? Number(String(fieldConfig.labelProps?.style?.fontSize).replace('px', '')) : 14;

const getSubLabelFontSize = (fieldConfig: StandardImagePickerProps): number =>
  fieldConfig.subLabelProps?.style?.fontSize ? Number(String(fieldConfig.subLabelProps?.style?.fontSize).replace('px', '')) : 14;

function sanitizeImageCols(col?: ImageColMap): ImageColMap {
  const copy = { ...(col || { xs: 2 }) };
  copy.sm = col?.sm || copy.xs;
  copy.md = col?.md || copy.sm;
  copy.lg = col?.lg || copy.md;
  copy.xl = col?.xl || copy.lg;
  return copy;
}

export default function StandardImagePicker(props: { field: StandardImagePickerProps; methods: UseFormReturn; hideTitle?: boolean }) {
  const {
    field: fieldConfig,
    methods: {
      control,
      getValues,
      setValue,
      trigger,
      formState: { errors },
    },
    hideTitle,
  } = props;
  const theme = useTheme();
  const { widthType } = useDimensions();

  const getValueKey = useMemo(() => {
    if (fieldConfig.getValueKey) {
      return fieldConfig.getValueKey;
    }
    return (value: ImagePickerObject) => value?.label || value?.src;
  }, [fieldConfig.getValueKey]);

  const getOptionKey = useMemo(() => {
    if (fieldConfig.getOptionKey) {
      return fieldConfig.getOptionKey;
    }
    return (option: ImagePickerObject) => option?.label || option?.src;
  }, [fieldConfig.getOptionKey]);

  const handleClick = (option: ImagePickerObject, valueIn: ImagePickerObject | Array<ImagePickerObject>) => {
    if (fieldConfig.multiple && Array.isArray(valueIn)) {
      const index = valueIn?.findIndex(value => getValueKey(value) === getOptionKey(option));
      if (index >= 0) {
        // option is currently selected, so remove it
        let copy: Array<ImagePickerObject> | undefined = [...valueIn];
        copy.splice(index, 1);
        if (copy.length === 0) {
          copy = undefined;
        }
        setValue(fieldConfig.attribute, copy);
        return;
      }
      setValue(fieldConfig.attribute, [...(valueIn || []), option]);
    } else {
      if (getValueKey(valueIn as ImagePickerObject) === getOptionKey(option)) {
        // option currently selected, so remove it
        setValue(fieldConfig.attribute, undefined);
        return;
      }
      setValue(fieldConfig.attribute, option);
    }
  };

  const isSelected = (
    fieldConf: StandardImagePickerProps,
    option: ImagePickerObject,
    value: ImagePickerObject | Array<ImagePickerObject>
  ) => {
    if (fieldConf.multiple && Array.isArray(value)) {
      return value?.findIndex(v => getValueKey(v) === getOptionKey(option)) >= 0;
    } else {
      return getValueKey(value as ImagePickerObject) === getOptionKey(option);
    }
  };

  const componentProps = (
    fieldConf: StandardImagePickerProps,
    option: ImagePickerObject,
    value: ImagePickerObject | Array<ImagePickerObject>
  ): ButtonBaseProps<'div', { component: 'div' }> => {
    return {
      id: fieldConf.attribute,
      sx: {
        width: `calc(100% - ${isSelected(fieldConf, option, value) ? '12px' : '8px'})`,
        margin: '2px',
        border: isSelected(fieldConf, option, value) ? `2px solid ${theme.palette.primary.main}` : undefined,
        borderRadius: '4px',
        flexDirection: 'column',
      },
      ...fieldConf.props,
      component: 'div',
      onClick: fieldConf.props?.onClick ? fieldConf.props.onClick(option, value) : () => handleClick(option, value),
    };
  };

  const containerProps = (fieldConf: StandardImagePickerProps): ImageListProps | undefined => fieldConf.groupContainerProps;

  return (
    <Controller
      name={fieldConfig.attribute}
      control={control}
      render={({ field }) => (
        <Fragment>
          {!hideTitle && fieldConfig.title && <Title field={fieldConfig} />}
          <ImageListRoot>
            <StyledImageList {...containerProps(fieldConfig)} cols={sanitizeImageCols(fieldConfig.imageCols)[widthType]} rowHeight="auto">
              {fieldConfig.images?.map((image, index) => (
                <ButtonBase key={index} {...componentProps(fieldConfig, image, field.value)}>
                  <ImageContainerRoot>
                    <ImageContainerSizer fieldConfig={fieldConfig} />
                    <ImageContainer {...fieldConfig.imageProps}>
                      {image.customComponent ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          {image.customComponent}
                        </Box>
                      ) : (
                        <Image src={image.src} alt={image.alt || image.label} title={image.label || image.alt} loading="lazy" />
                      )}
                    </ImageContainer>
                  </ImageContainerRoot>
                  {image.label && (
                    <LabelContainer fieldConfig={fieldConfig}>
                      <Label fieldConfig={fieldConfig} {...fieldConfig.labelProps}>
                        {image.label}
                      </Label>
                    </LabelContainer>
                  )}
                  {image.subLabel && (
                    <SubLabelContainer fieldConfig={fieldConfig}>
                      <SubLabel fieldConfig={fieldConfig} {...fieldConfig.subLabelProps}>
                        {image.subLabel}
                      </SubLabel>
                    </SubLabelContainer>
                  )}
                </ButtonBase>
              ))}
            </StyledImageList>
          </ImageListRoot>
          {!!errors[fieldConfig.attribute] && <ErrorText error={String(errors[fieldConfig.attribute]?.message)} />}
        </Fragment>
      )}
    />
  );
}

import React from 'react';

import { InvoicesDataGrid } from 'app/modules/views/account-page/components/data-grids/invoices/InvoicesDataGrid';
import { useInvoices } from '../../hooks/useInvoices';

export const InvoicesTab = (): JSX.Element => {
  const { invoicesWithPayment } = useInvoices();

  return <InvoicesDataGrid invoiceList={invoicesWithPayment} />;
};

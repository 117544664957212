import React, { useCallback } from 'react';

import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import { Button, Stack, Typography } from '@mui/material';
import { Translate } from 'react-jhipster';
import { AccountFormStepper } from './AccountFormStepper';
import { useUpdateAccountProfileStatusUsingPostMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useUpdateAccountUsingPutMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';

export const Welcome = () => {
  const { accountProfile, billingAccount, userProfile } = useAppAccountStates();
  const [updateAccountProfileStatus] = useUpdateAccountProfileStatusUsingPostMutation();
  const [updateBillingAccount] = useUpdateAccountUsingPutMutation();
  const skipButtonClick = useCallback(() => {
    updateBillingAccount({
      accountKey: accountProfile.accountId,
      accountDtoReq: { email: billingAccount.email ? billingAccount.email : userProfile.email } as any,
    });
    updateAccountProfileStatus();
  }, []);

  return (
    <Stack textAlign="center">
      <Typography variant="h2" paddingTop={5}>
        <Translate contentKey="welcome.title" />
      </Typography>

      <Typography variant="subtitle2">
        <Translate contentKey="welcome.subtitle" />
      </Typography>

      <Typography variant="h3">
        <Translate contentKey="welcome.account-activation" />
      </Typography>

      <Typography variant="body1" mb={3}>
        <Translate contentKey="welcome.fill-details" />
      </Typography>

      <AccountFormStepper />

      <Button color="neutral" size="small" endIcon={<DoubleArrowOutlinedIcon />} onClick={skipButtonClick}>
        <Translate contentKey="welcome.skip-for-now" />
      </Button>
    </Stack>
  );
};

export interface IBillingAccount {
  auditLogs: any[];
  name: string;
  firstNameLength: string;
  externalKey: string;
  email: string;
  billCycleDayLocal: number;
  currency: string;
  parentAccountExternalKey: string;
  isPaymentDelegatedToParent: boolean;
  paymentMethodId: string;
  referenceTime: string;
  timeZone: string;
  address1: string;
  address2: string;
  postalCode: string;
  company: string;
  city: string;
  state: string;
  country: string;
  locale: string;
  phone: string;
  notes: string;
  isMigrated: string;
  accountBalance: string;
  accountCBA: string;
}

export const billingAccountDefaultValue: Readonly<IBillingAccount> = {
  auditLogs: [],
  name: '',
  firstNameLength: '',
  externalKey: '',
  email: '',
  billCycleDayLocal: null,
  currency: '',
  parentAccountExternalKey: '',
  isPaymentDelegatedToParent: null,
  paymentMethodId: '',
  referenceTime: '',
  timeZone: '',
  address1: '',
  address2: '',
  postalCode: '',
  company: '',
  city: '',
  state: '',
  country: '',
  locale: '',
  phone: '',
  notes: '',
  isMigrated: '',
  accountBalance: '',
  accountCBA: '',
};

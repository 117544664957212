import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_HUB_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { ICohort } from 'app/shared/model/user-exploration/cohort.model';
import { serializeAxiosError } from '../reducer.utils';

const userExplorationUrl = DATA_HUB_API_URL + '/exploration/user';

const initialState = {
  cohortAnalysisLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side

  cohort_analysis: null as unknown as Array<ICohort>,
};

export type CohortState = Readonly<typeof initialState>;

export const getCohortAnalysisData = createAsyncThunk(
  'dataHub/FETCH_COHORT_ANALYSIS_DATA',
  async ({ filter, timeStep, nbTimeSteps }: any) => {
    return microserviceHttpClient(
      `${userExplorationUrl}/cohortAnalysis?timeStep=${timeStep}&nbTimeSteps=${nbTimeSteps}`,
      'post',
      JSON.stringify({
        filterEntryList: filter,
      })
    );
  },
  { serializeError: serializeAxiosError }
);

export const CohortSlice = createSlice({
  name: 'cohort',
  initialState: initialState as CohortState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCohortAnalysisData.pending, state => {
        state.cohortAnalysisLoading = true;
        state.errorMessage = null;
      })
      .addCase(getCohortAnalysisData.fulfilled, (state, action) => {
        state.cohortAnalysisLoading = false;
        state.cohort_analysis = action.payload.data.sort(function (a, b) {
          return Number(Object.keys(a.data).length) - Number(Object.keys(b.data).length);
        }) as Array<ICohort>;
      })
      .addCase(getCohortAnalysisData.rejected, (state, action) => {
        state.cohortAnalysisLoading = false;
        state.errorMessage = action.error.message;
        state.cohort_analysis = null as unknown as Array<ICohort>;
      });
  },
});

export default CohortSlice.reducer;

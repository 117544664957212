import { Grid, GridProps, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { IShowMoreLessProps, ShowMoreLess } from '../buttons/ShowMoreLess';

const defaultKeyProps: TypographyProps = {};

const defaultValueProps: TypographyProps = {
  align: 'right',
  color: 'textSecondary',
};

export type PropertiesType = [string | React.ReactNode, string | React.ReactNode][] | { [key: string]: string | React.ReactNode };

interface IPropertiesGridProps {
  properties: PropertiesType;
  keyProps?: TypographyProps;
  valueProps?: TypographyProps;
  gridProps?: GridProps;
}

export const PropertiesGrid = ({ properties, keyProps = {}, valueProps = {}, gridProps = {} }: IPropertiesGridProps) => {
  const propsList = !Array.isArray(properties) ? Object.entries(properties) : properties;

  return (
    <Grid container columns={2} {...gridProps}>
      {propsList
        .flatMap(entry => [
          { isKey: true, value: entry[0] },
          { isKey: false, value: entry[1] },
        ])
        .map((item, index) => {
          if (item.isKey) {
            return (
              <Grid item xs={2} md={1} key={index}>
                {typeof item.value === 'string' ? (
                  <Typography {...defaultKeyProps} {...keyProps}>
                    {item.value}
                  </Typography>
                ) : (
                  <>{item.value}</>
                )}
              </Grid>
            );
          } else {
            return (
              <Grid item xs={2} md={1} key={index}>
                {typeof item.value === 'string' ? (
                  <Typography {...defaultValueProps} {...valueProps}>
                    {item.value}
                  </Typography>
                ) : (
                  <>{item.value}</>
                )}
              </Grid>
            );
          }
        })}
    </Grid>
  );
};

interface ICollapsiblePropertiesGridProps extends IPropertiesGridProps, Partial<IShowMoreLessProps> {
  hideMoreThan: number;
}

export const CollapsiblePropertiesGrid = ({ properties, hideMoreThan, ...rest }: ICollapsiblePropertiesGridProps) => {
  const propsList = !Array.isArray(properties) ? Object.entries(properties) : properties;

  return (
    <ShowMoreLess
      stackProps={{ width: '100%' }}
      {...rest}
      openContent={<PropertiesGrid properties={properties} {...rest} />}
      closedContent={<PropertiesGrid properties={propsList.filter((value, index) => index < hideMoreThan)} {...rest} />}
    />
  );
};

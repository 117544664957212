import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

// import jwt_decode, { JwtPayload } from 'jwt-decode';
//
// const tokenUrl = SERVER_API_URL + `/api/access-token`;
// const tokenRefreshUrl = SERVER_API_URL + `/api/refresh-token`;
const isProd = true; // MICROSERVICES_API_URL === '';

// export const microserviceHttpClient = (endpoint: string, method = 'get', data = {}, headers = {}) => {
//   if (!isProd && (!sessionStorage.getItem('accessToken') || isTokenExpired())) {
//     return createRequestWithTokenUpdate(endpoint, method, data, headers);
//   } else {
//     return createRequest(endpoint, method, data, headers);
//   }
// };

export const microserviceHttpClient = (endpoint: string, method = 'get', data = {}, headers = {}) => {
  return createRequest(endpoint, method.toLowerCase(), data, headers);
};

const createRequest = (endpoint: string, method, data, headers: RawAxiosRequestHeaders) => {
  const requestConfig = {
    headers: {
      ...((method === 'post' || method === 'put') && { 'Content-Type': 'application/json' }),
      // ...(!isProd && { Authorization: 'Bearer ' + sessionStorage.getItem('accessToken') }),
      ...headers,
    },
    ...(method === 'delete' && { data }),
    withCredentials: isProd ? true : undefined,
  };
  switch (method) {
    case 'get':
      return axios.get(endpoint, requestConfig);
    case 'post':
      return axios.post(endpoint, data, requestConfig);
    case 'put':
      return axios.put(endpoint, data, requestConfig);
    case 'delete':
      return axios.delete(endpoint, requestConfig);
    default:
      throw new Error('Unsupported method: ' + method);
  }
};

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      header?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, body, params, header }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method: method ? method.toLowerCase() : 'get',
        data: body,
        params,
        withCredentials: true,
        ...header,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

// const createRequestWithTokenUpdate = (endpoint: string, method, data, headers: AxiosRequestHeaders) => {
//   return axios.get(tokenUrl, { withCredentials: true }).then(response => {
//     sessionStorage.setItem('accessToken', response.data);
//     return createRequest(endpoint, method, data, headers);
//   });
// };
//
// const isTokenExpired = () => {
//   const token = sessionStorage.getItem('accessToken');
//   const decodedToken = jwt_decode<JwtPayload>(token);
//   if (decodedToken.exp * 1000 < new Date().getTime()) {
//     return true;
//   } else {
//     return false;
//   }
// };

// Custom interceptor to convert string[] to comma-separated string in URL params
export const arrayParamsInterceptor = (config: InternalAxiosRequestConfig) => {
  if (config.params) {
    Object.keys(config.params).forEach(key => {
      const paramValue = config.params[key];

      // Check if the parameter value is an array of strings
      if (Array.isArray(paramValue) && typeof paramValue[0] === 'string') {
        // Convert the array to a comma-separated string
        config.params[key] = paramValue.join(',');
      }
    });
  }

  return config;
};

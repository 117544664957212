import { useAppSelector } from 'app/config/store';
import { useGetTimeframeUserScoresUsingPostQuery } from 'app/shared/reducers/api/data-hub-cloud-services/store/user-scores';
import { getUsersOverview } from 'app/shared/reducers/users';

export const useTopUsersData = () => {
  const { params } = useAppSelector(state => state.webAnalyticsOverview.topUsers);

  const { data = [] } = useGetTimeframeUserScoresUsingPostQuery(
    {
      from: params.from,
      to: params.to,
      scoreBodyDto: { filters: { filterEntryList: params.filter } },
      limit: '5',
    },
    {
      selectFromResult(res) {
        return {
          data: res.data ? getUsersOverview(res.data) : [],
        };
      },
    }
  );

  return {
    data,
  };
};

import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FieldProp, FormBuilder, validationUtils } from 'app/modules/form-builder-lib';
import { useForm } from 'react-hook-form';
import { Divider } from '@mui/material';

export const Item = ({ item }) => {
  const attributes: Array<FieldProp> = [
    {
      component: 'custom',
      attribute: 'divider-before',
      customComponent() {
        return <Divider />;
      },
      containerProps: {
        my: 'auto',
      },
      col: { xs: 5 },
      titleOptionalMarker: false,
    },
    {
      component: 'display-text',
      title: 'Attributes',
      attribute: 'attribute-title',
      titleOptionalMarker: false,
      containerProps: {
        my: 2,
        display: 'flex',
        justifyContent: 'center',
      },
      titleContainerProps: { m: '0 !important' },
      col: { xs: 2 },
    },
    {
      component: 'custom',
      attribute: 'divider-after',
      customComponent() {
        return <Divider />;
      },
      containerProps: {
        my: 'auto',
      },
      col: { xs: 5 },
      titleOptionalMarker: false,
    },
  ];

  if (item) {
    const att = [];
    Object.entries<string>(item.attributes).forEach(([k, v]) => {
      att.push(
        {
          component: 'display-text',
          title: k,
          attribute: k,
          col: { xs: 3 },
          titleOptionalMarker: false,
        },
        {
          component: 'display-text',
          title: ':',
          attribute: `filler.${k}`,
          col: { xs: 2 },
          titleOptionalMarker: false,
        },
        {
          component: 'text-field',
          attribute: `attributes.${k}`,
          props: {
            InputProps: {
              readOnly: true,
            },
          },
          col: { xs: 7 },
          titleOptionalMarker: false,
        }
      );
    });
    attributes.push(...att);
  }

  const getFields: Array<FieldProp> = [
    {
      component: 'text-field',
      attribute: 'id',
      label: 'ID',
      props: {
        // disabled: true,
        InputProps: {
          readOnly: true,
        },
      },
      col: { xs: 3 },
    },
    {
      component: 'text-field',
      attribute: 'title',
      label: 'Title',
      props: {
        InputProps: {
          readOnly: true,
        },
        multiline: true,
      },
      col: { xs: 9 },
    },
    {
      component: 'text-field',
      attribute: 'description',
      label: 'Description',
      props: {
        InputProps: {
          readOnly: true,
        },
        multiline: true,
      },
    },
    ...attributes,
  ];

  const schema = validationUtils.getFormSchema(getFields);
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const defaultValue = () => {
    const d = {} as any;

    if (item) {
      d['title'] = item.title;
      d['id'] = item.id;
      d['description'] = item.description;
      d['attributes'] = {};
      Object.entries<string>(item.attributes).forEach(([k, v]) => {
        d.attributes[k] = v;
      });
    }

    return d;
  };

  return (
    <FormBuilder
      fields={getFields}
      defaultValue={defaultValue()}
      methods={methods}
      optionalMarkerDefault={true}
      containerProps={{ marginY: 0.5, paddingX: 2 }}
    />
  );
};

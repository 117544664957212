import { useEffect, useRef } from 'react';

import { useAppDispatch } from 'app/config/store';
import { setAppBarHeight } from 'app/shared/reducers/main-layouts/appbar';

export const useAppbarRef = () => {
  const appBarRef = useRef(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setAppBarHeight(appBarRef.current.clientHeight));
  }, []);
  return appBarRef;
};

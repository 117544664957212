import React from 'react';
import { buildEChartOptions } from '../utils/EChartOptionsBuilder';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import { WithData } from 'app/modules/loading-lib/WithData';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';

const RecommendationsByTimeChart = ({ data, impressions, isFetching }) => {
  const theme = useTheme();

  return (
    <ReactECharts
      style={{ height: '100%', width: '100%' }}
      option={buildEChartOptions({
        title: { text: 'Recommendations By Time', textStyle: { color: theme.palette.text.primary } },
        legend: {},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        dataset: [
          {
            dimensions: ['time', 'value'],
            source: impressions.timeseries ?? [],
          },
          ...(impressions.byRecommenderType.timeseries
            ? impressions.byRecommenderType.timeseries.map(timeserie => ({
                dimensions: ['time', 'value'],
                source: timeserie.data,
              }))
            : []),
        ],
        xAxis: {
          type: 'time',
        },
        yAxis: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        series: [
          {
            name: 'All',
            type: 'bar',
            emphasis: {
              focus: 'series',
            },
            encode: {
              x: 'time',
              y: 'value',
            },
            barWidth: 10,
            datasetIndex: 0,
          },
          ...(impressions.byRecommenderType.timeseries
            ? impressions.byRecommenderType.timeseries.map((bucket, index) => ({
                name: bucket.key,
                type: 'bar',
                emphasis: {
                  focus: 'series',
                },
                encode: {
                  x: 'time',
                  y: 'value',
                },
                barWidth: 20,
                datasetIndex: index + 1,
                stack: 'recommendations',
              }))
            : []),
        ],
      })}
    />
  );
};

export default WithLoadingHOC(WithData(RecommendationsByTimeChart));

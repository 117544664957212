import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { isUndefined } from 'lodash';
import React, { useState } from 'react';
import { translate } from 'react-jhipster';
import { accountAddressAttributes, accountDetailsAttributes } from './accountAttributes';
import { AccountFormStep } from './AccountFormStep';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useUpdateAccountUsingPutMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account';
import { useUpdateAccountProfileStatusUsingPostMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';

const steps = ['account-details', 'billing-details'];

export const AccountFormStepper = () => {
  const { accountProfile, billingAccount, userProfile } = useAppAccountStates();
  const [updateBillingAccount] = useUpdateAccountUsingPutMutation();
  const [updateAccountProfileStatus] = useUpdateAccountProfileStatusUsingPostMutation();

  const readyAccountDetails = [billingAccount?.name, billingAccount?.email, billingAccount?.company].every(
    el => !isUndefined(el) && el !== null && el !== ''
  );
  const getBillingAccountValues = () => {
    return {
      ...(billingAccount.name && { name: billingAccount.name }),
      ...(billingAccount.company && { company: billingAccount.company }),
      email: billingAccount.email ? billingAccount.email : userProfile.email,
      ...(billingAccount.country && { country: billingAccount.country }),
      ...(billingAccount.address1 && { address1: billingAccount.address1 }),
      ...(billingAccount.city && { city: billingAccount.city }),
      ...(billingAccount.postalCode && { postalCode: billingAccount.postalCode }),
    };
  };

  const [activeStep, setActiveStep] = useState(() => {
    if (readyAccountDetails) {
      return 1;
    } else {
      return 0;
    }
  });
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepOptional = (s: number) => {
    return s === -1;
  };

  const isStepSkipped = (s: number) => {
    return skipped.has(s);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <BasicCardLayout content={{ sx: { p: 5 } }} card={{ sx: { alignSelf: 'center', width: 'fit-content' } }}>
      <Stepper activeStep={activeStep} sx={{ width: '80%', mx: 'auto', my: 2 }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">{translate('welcome.optional')}</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel /* StepIconComponent={LibraryBooksIcon} */ {...labelProps}>
                {<Typography sx={{ fontWeight: 'bold' }}>{translate(`welcome.${label}`)}</Typography>}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography variant="h3" sx={{ m: 5, textAlign: 'center' }}>
            {translate('welcome.steps-completed')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              onClick={() => {
                updateAccountProfileStatus();
              }}
              variant="contained"
            >
              {translate('welcome.go-to-home')}
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 ? (
            <AccountFormStep
              formTitle={translate('welcome.set-account-details')}
              accountAttributes={accountDetailsAttributes()}
              defaultValues={getBillingAccountValues()}
              onSubmit={data => {
                if (accountProfile.accountId) {
                  updateBillingAccount({ accountKey: accountProfile.accountId, accountDtoReq: data }).then(res => handleNext());
                }
              }}
              buttons={submitButton => {
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (
                      <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        {translate('welcome.skip')}
                      </Button>
                    )}
                    {/* <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button> */}
                    {submitButton(activeStep === steps.length - 1 ? translate('welcome.finish') : translate('welcome.next'))}
                  </Box>
                );
              }}
            />
          ) : (
            <AccountFormStep
              formTitle={translate('welcome.set-account-contact')}
              accountAttributes={accountAddressAttributes()}
              defaultValues={getBillingAccountValues()}
              onSubmit={data => {
                if (accountProfile.accountId) {
                  updateBillingAccount({
                    accountKey: accountProfile.accountId,
                    accountDtoReq: { ...data, name: billingAccount.name },
                  }).then(res => handleNext());
                }
              }}
              buttons={submitButton => {
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button color="inherit" onClick={handleBack} sx={{ mb: 2 }}>
                      {translate('welcome.back')}
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (
                      <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        {translate('welcome.skip')}
                      </Button>
                    )}
                    {/* <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button> */}
                    {submitButton(activeStep === steps.length - 1 ? translate('welcome.finish') : translate('welcome.next'))}
                  </Box>
                );
              }}
            />
          )}
        </React.Fragment>
      )}
    </BasicCardLayout>
  );
};

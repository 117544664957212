import React, { useState } from 'react';

import { GridColDef, DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { addFlex, chipString, currencyAmount, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import dayjs from 'dayjs';
import { useAppSelector } from 'app/config/store';
import { BasicCardLayout } from 'app/modules/layout-builder-lib/components/layout/card/BasicCardLayout';
import { translate } from 'react-jhipster';

export const TransactionsDataGrid = ({ payment, rowSelectionModel, setRowSelectionModel }) => {
  const { currentLocale }: any = useAppSelector(state => state.locale);

  const transactionsColumns: GridColDef[] = [
    {
      field: 'transactionId',
      flex: 2,
    },
    {
      field: 'effectiveDate',
      type: 'date',
      flex: 2,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) => value && dayjs(value).locale(currentLocale).format('llll'),
    },
    {
      field: 'transactionType',
      ...chipString(params => {
        return { variant: 'outlined', size: 'small' };
      }),
    },
    {
      field: 'amount',
      ...currencyAmount(),
    },
    {
      field: 'status',
      ...chipString(params => {
        return { variant: 'outlined', size: 'small', color: params.value === 'SUCCESS' ? 'success' : 'error' };
      }),
    },
  ];

  return (
    <BasicCardLayout
      variant="div"
      header={{ title: translate('account.transactions', null, 'Transactions') }}
      content={{
        sx: { p: 0, '&:last-child': { p: 0 } },
      }}
    >
      <DataGrid
        rows={payment.transactions}
        columns={transactionsColumns.map(addFlex).map(translateHeaders('account.transactions-table'))}
        hideFooter
        density="compact"
        getRowId={row => row.transactionId}
        onRowSelectionModelChange={newRowSelectionModel => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-main': { width: 0, minWidth: '100%' },
        }}
      />
    </BasicCardLayout>
  );
};

import React, { useEffect } from 'react';
import { setLocale } from 'app/shared/reducers/locale';
import { Storage } from 'react-jhipster';
import { isRTL } from 'app/config/translation';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LanguageMenuPresentation } from './LanguageMenu';

export const LanguageMenu = () => {
  const theme = useTheme();
  // useEffect(() => document.querySelector('html').setAttribute('dir', isRTL(sessionStorage.getItem('locale')) ? 'rtl' : 'ltr'));
  const dispatch = useAppDispatch();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLocaleChange = langKey => {
    dispatch(setLocale(langKey));
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
    handleClose();
  };

  return (
    <LanguageMenuPresentation
      theme={theme}
      handleClick={handleClick}
      anchorEl={anchorEl}
      handleClose={handleClose}
      handleLocaleChange={handleLocaleChange}
      currentLocale={currentLocale}
    />
  );
};

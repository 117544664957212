import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';
import { useParams } from 'react-router-dom';

export type ModuleState = {
  moduleName: string;
  isSubscribedProduct: (string) => boolean;
};

export function useModuleState(): ModuleState {
  const moduleName = useParams()['moduleName'] || 'home';
  const { subscribedProducts } = useSubscriptionsStates();
  function isSubscribedProduct(name) {
    return subscribedProducts.find(v => v.productName === name) !== undefined;
  }

  return {
    moduleName,
    isSubscribedProduct,
  };
}

import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
import { accountProfileApi } from 'app/shared/reducers/api/config-manager-cloud-services/store/account-profile';
import { useDeleteItemMutation } from 'app/shared/reducers/api/reco-engine/store/recommender-engine';
import { PropertiesGrid } from 'app/components/properties/properties-grid';
import { Translate, translate } from 'react-jhipster';

export const ConfirmDeleteItem = ({ setOpen, item }) => {
  const { data: accountProfile = {} } = accountProfileApi.endpoints.getCurrentAccountProfileUsingGet.useQueryState({});
  const [deleteItem] = useDeleteItemMutation();

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Dialog fullWidth={true} open={item !== null} onClose={handleClose} maxWidth="sm">
      <DialogTitle typography="h4">
        <Translate contentKey="recommendation.items.items-table.delete-item">Delete Item</Translate>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Translate contentKey="recommendation.items.confirm-delete-item">Confirm deleting item :</Translate>
        </DialogContentText>
        <PropertiesGrid
          gridProps={{ spacing: 1, mt: 1 }}
          properties={[
            ['ID', item?.id],
            ['Title', item?.title],
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translate('recommendation.cancel', null, 'Cancel')}</Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            deleteItem({ accountId: accountProfile?.accountId.substring(0, 8), id: item?.id });
            handleClose();
          }}
        >
          {translate('recommendation.items.items-table.delete-item', null, 'Delete Item')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';

import { Chip, Typography, Avatar, Stack } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { addFlex, arrayStrings, chipStringStyled, translateHeaders } from 'app/components/data-grid/columns/column-helpers';
import { Store } from '@mui/icons-material';
import { dynamicHeightProps } from 'app/components/data-grid/rows/rows-props';
import { toolbarProps } from 'app/components/data-grid/toolbar/toolbar-props';
import { sizedPagination } from 'app/components/data-grid/pagination/pagination-props';
import { useSetCurrentAccountUsingPutMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/user-profiles';
import { useUserStates } from 'app/shared/layout/restricted/hooks/selectors/useUserStates';

export const AccountsTable = () => {
  const [setCurrentAccount] = useSetCurrentAccountUsingPutMutation();
  const { allUserAccounts, userProfile } = useUserStates();

  const setCurrentProfile = accountId => {
    setCurrentAccount({ userEmail: userProfile.email, accountId });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      renderCell: params => (
        <Stack direction="row" alignItems="center">
          <Avatar>
            <Store />
          </Avatar>
          <Typography color={params.value ? 'inherit' : 'textSecondary'}>{params.value || params.row.accountId.split('-')[0]}</Typography>
          {params.row.accountId === userProfile.currentAccount && <Chip label="in use" variant="outlined" color="primary" size="small" />}
        </Stack>
      ),
    },
    {
      field: 'status',
      ...chipStringStyled({ variant: 'outlined', size: 'small', color: 'neutral' }, { READY: { color: 'success' } }),
    },
    {
      field: 'roles',
      ...arrayStrings(),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 0,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key="use"
          disabled={params.row.accountId === userProfile.currentAccount}
          onClick={() => {
            setCurrentProfile(params.row.accountId);
          }}
          label="Use"
          showInMenu
        />,
      ],
    },
  ];

  return (
    <DataGrid
      rows={allUserAccounts || []}
      columns={columns.map(addFlex).map(translateHeaders('profile'))}
      getRowId={row => row.accountId}
      density="compact"
      disableColumnMenu
      {...dynamicHeightProps()}
      {...toolbarProps()}
      {...sizedPagination()}
    />
  );
};

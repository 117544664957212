import React from 'react';

import * as echarts from 'echarts';
import world from 'app/shared/util/world.json';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import { WithLoadingHOC } from 'app/modules/loading-lib/WithLoadingHOC';
import latlong from 'app/modules/views/module-root/views/web-analytics/components/map-chart/latlong';
import { getName } from 'country-list';

interface LiveMapChartProps {
  data: any;
  selectedChart: any;
  isFetching?: boolean;
  isError: boolean;
  error: any;
}

const LiveMapChart = ({ data, selectedChart, error, isFetching, isError }: LiveMapChartProps) => {
  echarts.registerMap('world', world);
  const theme = useTheme();

  let max = -Infinity;
  let min = Infinity;
  data.forEach(function (element) {
    if (selectedChart.name === 'SESSIONS_PER_USER') {
      if (element.buckets.SESSION_COUNT.count > max) {
        max = element.buckets.SESSION_COUNT.count;
      }
      if (element.buckets.SESSION_COUNT.count < min) {
        min = element.buckets.SESSION_COUNT.count;
      }
    } else if (selectedChart.name === 'PAGEVIEWS_PER_SESSION') {
      if (element.buckets.PAGEVIEWS_COUNT.sum > max) {
        max = element.buckets.PAGEVIEWS_COUNT.sum;
      }
      if (element.buckets.PAGEVIEWS_COUNT.sum < min) {
        min = element.buckets.PAGEVIEWS_COUNT.sum;
      }
    } else {
      if (element.buckets[selectedChart.name][selectedChart.value] > max) {
        max = element.buckets[selectedChart.name][selectedChart.value];
      }
      if (element.buckets[selectedChart.name][selectedChart.value] < min) {
        min = element.buckets[selectedChart.name][selectedChart.value];
      }
    }
  });

  const option = {
    tooltip: {
      trigger: 'item',
      formatter(params) {
        return params.name + ' : ' + params.value[2];
      },
    },
    visualMap: {
      show: false,
      min: 0,
      max,
      inRange: {
        symbolSize: [6, 20],
      },
    },
    geo: {
      type: 'map',
      map: 'world',
      roam: true,
      scaleLimit: {
        max: 4,
        min: 1,
      },
      label: {
        emphasis: {
          show: false,
        },
      },
    },
    series: {
      type: 'effectScatter',
      coordinateSystem: 'geo',
      data: data.map(function (element) {
        let obj = {};
        for (const [key, value] of Object.entries(latlong)) {
          if (element.countryCode === key) {
            if (selectedChart.name === 'SESSIONS_PER_USER') {
              obj = {
                name: getName(key),
                value: [latlong[key].longitude, latlong[key].latitude, element.buckets.SESSION_COUNT.count],
              };
            } else if (selectedChart.name === 'PAGEVIEWS_PER_SESSION') {
              obj = {
                name: getName(key),
                value: [latlong[key].longitude, latlong[key].latitude, element.buckets.PAGEVIEWS_COUNT.sum],
              };
            } else {
              obj = {
                name: getName(key),
                value: [latlong[key].longitude, latlong[key].latitude, element.buckets[selectedChart.name][selectedChart.value]],
              };
            }
          }
        }
        return obj;
      }),
    },
  };

  return <ReactECharts style={{ height: '100%', width: '100%' }} option={option} />;
};

export default WithLoadingHOC(LiveMapChart);

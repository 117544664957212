import React from 'react';

import { useTheme } from '@mui/material/styles';
import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import 'echarts/i18n/langFR';
import { useAppSelector } from 'app/config/store';
import { WithLoading } from 'app/modules/loading-lib/WithLoading';
import { WithData } from 'app/modules/loading-lib/WithData';
import { Typography } from '@mui/material';
import { getTotal } from '../../../utils/chartsTotalUtils';
import { LoadingStackSkeleton } from 'app/modules/loading-lib/component/LoadingStackSkeleton';

const ActiveUsersChart = ({ data }) => {
  const theme = useTheme();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const config: EChartsOption = {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    series: [
      {
        type: 'bar',
        data,
        symbol: 'none',
      },
    ],
    xAxis: {
      type: 'time',
      show: false,
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    tooltip: {
      borderRadius: 20,
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
    },
    textStyle: {
      color: theme.palette.text.primary,
    },
  } as EChartsOption;

  return (
    <>
      <Typography textAlign="center" variant="h1" color={theme.palette.primary.main} my={2}>
        {getTotal(data)}
      </Typography>
      <ReactECharts style={{ height: '100%', width: '100%' }} option={config} opts={{ locale: currentLocale }} />
    </>
  );
};

export default WithLoading(WithData(ActiveUsersChart), state => state.webAnalyticsOverview.activeUsers.loading, {
  loader() {
    return <LoadingStackSkeleton />;
  },
});

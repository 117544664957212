import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { RECO_ENGINE_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { IProfile } from 'app/shared/model/recommendations/profile.model';

const profileUrl = RECO_ENGINE_API_URL + '/users';

export const initialState = {
  profileLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  profile: null as IProfile,
};

export type RecoProfileState = Readonly<typeof initialState>;

// Actions

export const getProfile = createAsyncThunk(
  'reco/getProfile',
  async ({ profileId }: { profileId: any }) => microserviceHttpClient(`${profileUrl}/${profileId}/profile`),
  {
    serializeError: serializeAxiosError,
  }
);

// Slice

export const recoProfileSlice = createSlice({
  name: 'profile',
  initialState: initialState as RecoProfileState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProfile.pending, state => {
        state.profileLoading = true;
        state.errorMessage = null;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.profileLoading = false;
        state.profile = action.payload.data as IProfile;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.profileLoading = false;
        state.errorMessage = action.error.message;
        state.profile = null as IProfile;
      });
  },
});

// Reducer
export default recoProfileSlice.reducer;

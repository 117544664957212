import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestFilterEntry } from '../api/data-hub-cloud-services/store/data-exploration';

const filtersAdapter = createEntityAdapter<RequestFilterEntry>({
  selectId: filter => filter.key,
  sortComparer: (a, b) => a.key.localeCompare(b.key),
});

interface ITimerange {
  chosenRange: string | Date[];
  fromDate: string;
  toDate: string;
  refresh: boolean;
  refreshRate: number;
  step: string;
  live: any;
}

const initialState = {
  filter: filtersAdapter.getInitialState(),
  timerange: undefined as ITimerange,
  cohortState: {
    timeStep: 'DAY',
    stepNumber: 7,
    displayedValueType: 'count',
  },
  selectedCohortCell: [],
  selectedCellDates: [],
  splitBy: 'sessions-by',
  splitLimit: 20,
  usersColumnVisibilityModel: {
    userId: false,
    engagementScore: true,
    sessionCount: true,
    eventsCounterSum: true,
    pageviewsCounterSum: true,
    sessionDurationSum: true,
    bounceCount: true,
    firstUserVisitEpochSeconds: false,
    lastEventEpochSeconds: false,
    timeSinceLastVisit: false,
    countrySplit: false,
    citySplit: false,
    continentSplit: false,
    firstVisitedPageSplit: false,
    lastVisitedPageSplit: false,
    refererSplit: false,
    fqdnSplit: false,
    deviceCategorySplit: false,
    browserFamilySplit: false,
    osFamilySplit: false,
  },
};

export type UserExplorationState = Readonly<typeof initialState>;

export const userExplorationSlice = createSlice({
  name: 'userExploration',
  initialState: initialState as UserExplorationState,
  reducers: {
    setUserExplorationTimerange(state, action) {
      state.timerange = action.payload;
    },
    addUserExplorationFilter(state, action) {
      filtersAdapter.setOne(state.filter, action.payload);
    },
    deleteUserExplorationFilter(state, action) {
      filtersAdapter.removeOne(state.filter, action.payload);
    },
    deleteAllUserExplorationFilters(state, action) {
      filtersAdapter.removeAll(state.filter);
    },
    setUserExplorationCohortState(state, action) {
      state.cohortState = action.payload;
    },
    setUserExplorationSelectedCohortCell(state, action) {
      state.selectedCohortCell = action.payload;
    },
    setUserExplorationSelectedCellDates(state, action) {
      state.selectedCellDates = action.payload;
    },
    setUserExplorationSplitBy(state, action) {
      state.splitBy = action.payload;
    },
    setUserExplorationSplitLimit(state, action) {
      state.splitLimit = action.payload;
    },
    setUsersColumnVisibilityModel(state, action) {
      state.usersColumnVisibilityModel = action.payload;
    },
  },
});

export const {
  selectAll: selectAllUserExplorationFilters,
  selectById: selectUserExplorationFiltersById,
  selectIds: selectUserExplorationFiltersIds,
} = filtersAdapter.getSelectors((state: any) => state.views.userExploration.filter);
export const getUserExplorationTimerange = state => state.views.userExploration.timerange;
export const getUserExplorationCohortState = state => state.views.userExploration.cohortState;
export const getUserExplorationSelectedCohortCell = state => state.views.userExploration.selectedCohortCell;
export const getUserExplorationSelectedCellDates = state => state.views.userExploration.selectedCellDates;
export const getUserExplorationSplitBy = state => state.views.userExploration.splitBy;
export const getUserExplorationSplitLimit = state => state.views.userExploration.splitLimit;
export const getUsersColumnVisibilityModel = state => state.views.userExploration.usersColumnVisibilityModel;

export const {
  setUserExplorationTimerange,
  addUserExplorationFilter,
  deleteUserExplorationFilter,
  deleteAllUserExplorationFilters,
  setUserExplorationCohortState,
  setUserExplorationSelectedCohortCell,
  setUserExplorationSelectedCellDates,
  setUserExplorationSplitBy,
  setUserExplorationSplitLimit,
  setUsersColumnVisibilityModel,
} = userExplorationSlice.actions;

export default userExplorationSlice.reducer;

import React from 'react';

import { Card, Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { timeRangeListDefault } from '../refresh-rate/timeRange';
import { DateTimePicker } from './DateTimePicker';
import { StickySubHeaderList } from './StickySubHeaderList';

interface TimePickerPopperProps {
  fromDate: dayjs.Dayjs;
  toDate: dayjs.Dayjs;
  step: any;
  setFrom: any;
  setTo: any;
  setChosenRange: any;
  setFromDate: any;
  setToDate: any;
  setStep: any;
  quickRanges?: any[];
  closePopper: () => void;
}

export const TimePickerPopper = ({
  fromDate,
  toDate,
  step,
  setFrom,
  setTo,
  setChosenRange,
  setFromDate,
  setToDate,
  setStep,
  quickRanges = timeRangeListDefault,
  closePopper,
}: TimePickerPopperProps) => {
  return (
    <Card sx={{ height: '275px' }}>
      <Stack direction="row" spacing={1} height="100%">
        <DateTimePicker
          fromDate={fromDate}
          toDate={toDate}
          setFrom={setFrom}
          setTo={setTo}
          setChosenRange={setChosenRange}
          setFromDate={setFromDate}
          setToDate={setToDate}
          closePopper={closePopper}
        />
        {quickRanges && quickRanges.length > 0 && (
          <>
            <Divider sx={{ marginX: '1px' }} orientation="vertical" flexItem />

            <StickySubHeaderList
              list={quickRanges}
              setRange={setChosenRange}
              setFrom={setFrom}
              setTo={setTo}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setStep={setStep}
              closePopper={closePopper}
            />
          </>
        )}
      </Stack>
    </Card>
  );
};

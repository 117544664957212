import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';

const accountProfilesUrl = CONFIG_MANAGER_API_URL + '/account-profiles';

export const initialState = {
  contributorsLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  contributors: null,
};

export type ContributorsState = Readonly<typeof initialState>;

// Actions

export const getContributors = createAsyncThunk(
  'accountProfile/getContributors',
  async ({ accountId }: { accountId: any }) => microserviceHttpClient(`${accountProfilesUrl}/${accountId}/contributors`),
  { serializeError: serializeAxiosError }
);
export const addContributors = createAsyncThunk(
  'accountProfile/addContributors',
  async ({ accountId, userEmail }: { accountId: any; userEmail: any }) =>
    microserviceHttpClient(`${accountProfilesUrl}/${accountId}/contributors/${userEmail}`, 'put'),
  { serializeError: serializeAxiosError }
);

// Slice

export const ContributorsSlice = createSlice({
  name: 'contributors',
  initialState: initialState as ContributorsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getContributors.pending, state => {
        state.contributorsLoading = true;
        state.errorMessage = null;
      })
      .addCase(getContributors.fulfilled, (state, action) => {
        state.contributorsLoading = false;
        state.contributors = action.payload.data;
      })
      .addCase(getContributors.rejected, (state, action) => {
        state.contributorsLoading = false;
        state.errorMessage = action.error.message;
        state.contributors = null;
      })
      .addCase(addContributors.pending, state => {
        state.contributorsLoading = true;
        state.errorMessage = null;
      })
      .addCase(addContributors.fulfilled, (state, action) => {
        state.contributorsLoading = false;
      })
      .addCase(addContributors.rejected, (state, action) => {
        state.contributorsLoading = false;
        state.errorMessage = action.error.message;
      });
  },
});

// Reducer
export default ContributorsSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from '../reducer.utils';

import { CONFIG_MANAGER_API_URL } from 'app/shared/api/constants';
import { microserviceHttpClient } from 'app/shared/api/microservices';
import { billingAccountDefaultValue, IBillingAccount } from 'app/shared/model/billing-account.model';
import { setCurrentAccount } from '../user-profile';

const billingAccountUrl = CONFIG_MANAGER_API_URL + '/billing/accounts';

export const initialState = {
  billingAccountLoading: true,
  loading: false,
  addMethodLoading: false,
  errorMessage: null as unknown as string, // Errors returned from server side
  addMethodErrorMessage: null as unknown as string,
  billingAccount: billingAccountDefaultValue as IBillingAccount,
  status: 'READY' as string,
  accountCreateSuccess: false,
  accountUpdatedSuccess: false,
  addPaymentobject: {},
};

export type BillingAccountState = Readonly<typeof initialState>;

// Actions

export const getBillingAccount = createAsyncThunk(
  'billingAccount/getBillingAccount',
  async ({ accountId }: { accountId: any }, thunkAPI) => {
    /* thunkAPI.dispatch(getAccountProfile(accountId)); */
    return microserviceHttpClient(`${billingAccountUrl}/${accountId}`);
  },
  { serializeError: serializeAxiosError }
);

export const getBillingAccountWithBalance = createAsyncThunk(
  'billingAccount/getBillingAccountWithBalance',
  async ({ accountId }: { accountId: string }) => microserviceHttpClient(`${billingAccountUrl}/${accountId}?accountWithBalanceAndCBA=true`),
  { serializeError: serializeAxiosError }
);

export const createEmptyBillingAccount = createAsyncThunk(
  'billingAccount/createEmptyBillingAccount',
  async () => microserviceHttpClient(`${billingAccountUrl}`, 'post'),
  { serializeError: serializeAxiosError }
);

export const updateBillingAccount = createAsyncThunk(
  'billingAccount/updateBillingAccount',
  async ({ accountId, updatedAccountInfos }: { accountId: any; updatedAccountInfos: any }, thunkAPI) =>
    microserviceHttpClient(`${billingAccountUrl}/${accountId}`, 'put', JSON.stringify(updatedAccountInfos)).then(resp => {
      /* thunkAPI.dispatch(getBillingAccount({ accountId })); */
      /* thunkAPI.dispatch(getCurrentAccountProfile()); */
      // i already call getAccountProfile in getBillingAccount
    }),
  { serializeError: serializeAxiosError }
);

export const addPaymentMethod = createAsyncThunk(
  'billingAccount/addPaymentMethod',
  async ({ accountId, scrId }: any) =>
    microserviceHttpClient(
      `${billingAccountUrl}/${accountId}/paymentMethods`,
      'post',
      JSON.stringify({
        pluginName: 'killbill-stripe',
        pluginProperty: {
          source: scrId,
        },
      })
    ),
  { serializeError: serializeAxiosError }
);

// Slice

export const BillingAccountSlice = createSlice({
  name: 'billingAccount',
  initialState: initialState as BillingAccountState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBillingAccount.pending, state => {
        state.billingAccountLoading = true;
        state.errorMessage = null;
      })
      .addCase(getBillingAccount.fulfilled, (state, action) => {
        state.billingAccountLoading = false;
        state.billingAccount = action.payload.data as IBillingAccount;
      })
      .addCase(getBillingAccount.rejected, (state, action) => {
        state.billingAccountLoading = false;
        state.errorMessage = action.error.message;
        state.billingAccount = initialState.billingAccount;
      })
      .addCase(createEmptyBillingAccount.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(createEmptyBillingAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.billingAccount = action.payload.data;
      })
      .addCase(createEmptyBillingAccount.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.billingAccount = initialState.billingAccount;
      })
      .addCase(addPaymentMethod.fulfilled, (state, action) => {
        state.addMethodLoading = false;
        state.addPaymentobject = action.payload.data;
      })
      .addCase(addPaymentMethod.rejected, (state, action) => {
        state.addMethodLoading = false;
        state.addMethodErrorMessage = action.error.message;
        state.addPaymentobject = {};
      })
      .addCase(addPaymentMethod.pending, (state, action) => {
        state.addMethodLoading = true;
      });
  },
});

// Reducer
export default BillingAccountSlice.reducer;

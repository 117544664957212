import React, { createContext, useState, useEffect } from 'react';

import { useAppDispatch } from 'app/config/store';
import { Plan, Product } from 'app/shared/model/catalog.model';
import { patterns } from 'app/shared/util/url-utils';
import { generatePath, useNavigate } from 'react-router-dom';
import { useProductsStates } from 'app/shared/layout/restricted/hooks/selectors/useProductsStates';
import { useAppAccountStates } from 'app/shared/layout/restricted/hooks/selectors/useAppAccountStates';
import { useCreateSubscriptionUsingPostMutation } from 'app/shared/reducers/api/config-manager-cloud-services/store/subscription';
import { useSubscriptionsStates } from 'app/shared/layout/restricted/hooks/selectors/useSubscriptionsStates';

const defaultContext = {
  product: null as Product,
  setProduct(p: Product) {},
  isProductDialogOpen: false,
  resetProductDialog() {},
  plan: null as Plan,
  setPlan(p: Plan) {},
  subscription: [],
  subscribedPlan: [],
  submitSubscriptionChange() {},
  uniqueActiveProducts: [] as Product[],
  otherProducts: [] as Product[],
};

export const ProductContext = createContext(defaultContext);
export type ProductContextType = typeof defaultContext;

export const ProductContextProvider = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { catalog } = useAppSelector(state => state.catalog);
  // const { accountProfile } = useAppSelector(state => state.accountProfile.accountProfileReducer);
  // const { subscribedProducts } = useAppSelector(state => state.subscriptionBundles);
  const { accountProfile } = useAppAccountStates();
  const { subscribedProducts } = useSubscriptionsStates();

  const [product, setProduct] = useState<Product>(null);
  const [plan, setPlan] = useState<Plan>(null);

  useEffect(() => {
    setPlan(product?.plans[0]);
  }, [product]);

  const isProductDialogOpen = product !== null;
  const { otherProducts, uniqueActiveProducts } = useProductsStates();
  const subscribedPlan = subscribedProducts.filter(sub => sub.planName === plan?.name);
  const subscription = subscribedProducts.filter(sub => sub.productName === product?.name);
  const [createSubscription] = useCreateSubscriptionUsingPostMutation({
    fixedCacheKey: 'shared-create-subscription-post',
  });
  // const activeProducts = subscribedProducts?.map(sub => {
  //   return catalog[0].products.filter(p => p.name === sub.productName)[0];
  // });
  // const uniqueActiveProducts = [...new Set(activeProducts)];
  // const otherProducts = catalog[0].products.filter((p: Product) => !subscribedProducts?.map(sub => sub.productName).includes(p.name));

  const resetProductDialog = () => {
    setProduct(null);
  };

  const submitSubscriptionChange = () => {
    try {
      if (accountProfile.accountId) {
        createSubscription({
          subscriptionDto: { accountExternalKey: accountProfile.accountId, planName: plan.name },
        })
          .unwrap()
          .then(res => {
            navigate(generatePath(patterns.product.settings, { productName: product.name }));
          });
        resetProductDialog();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ProductContext.Provider
      value={{
        product,
        setProduct,
        isProductDialogOpen,
        resetProductDialog,
        plan,
        setPlan,
        subscription,
        subscribedPlan,
        submitSubscriptionChange,
        uniqueActiveProducts,
        otherProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

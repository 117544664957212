import { emptyConfigManagerCloudServicesApi as api } from '../emptyConfigManagerCloudServicesApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getCatalogJsonUsingGet: build.query<GetCatalogJsonUsingGetApiResponse, GetCatalogJsonUsingGetApiArg>({
      query: () => ({ url: `/api/v1/billing/catalog` }),
    }),
    addSimplePlanUsingPost: build.mutation<AddSimplePlanUsingPostApiResponse, AddSimplePlanUsingPostApiArg>({
      query: queryArg => ({ url: `/api/v1/billing/catalog/simplePlan`, method: 'POST', body: queryArg.simplePlan }),
    }),
    getCatalogXmlUsingGet: build.query<GetCatalogXmlUsingGetApiResponse, GetCatalogXmlUsingGetApiArg>({
      query: () => ({ url: `/api/v1/billing/catalog/xml` }),
    }),
    createCatalogUsingPost: build.mutation<CreateCatalogUsingPostApiResponse, CreateCatalogUsingPostApiArg>({
      query: queryArg => ({ url: `/api/v1/billing/catalog/xml`, method: 'POST', body: queryArg.body }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as catalogApi };
export type GetCatalogJsonUsingGetApiResponse = /** status 200 OK */ any;
export type GetCatalogJsonUsingGetApiArg = void;
export type AddSimplePlanUsingPostApiResponse = unknown;
export type AddSimplePlanUsingPostApiArg = {
  simplePlan: SimplePlan;
};
export type GetCatalogXmlUsingGetApiResponse = /** status 200 OK */ string;
export type GetCatalogXmlUsingGetApiArg = void;
export type CreateCatalogUsingPostApiResponse = unknown;
export type CreateCatalogUsingPostApiArg = {
  body: string;
};
export type SimplePlan = {
  amount?: number;
  availableBaseProducts?: string[];
  billingPeriod?:
    | 'ANNUAL'
    | 'BIANNUAL'
    | 'BIENNIAL'
    | 'BIMESTRIAL'
    | 'BIWEEKLY'
    | 'DAILY'
    | 'MONTHLY'
    | 'NINETY_DAYS'
    | 'NO_BILLING_PERIOD'
    | 'QUARTERLY'
    | 'SIXTY_DAYS'
    | 'THIRTY_DAYS'
    | 'TRIANNUAL'
    | 'WEEKLY';
  currency?:
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ANG'
    | 'AOA'
    | 'ARS'
    | 'AUD'
    | 'AWG'
    | 'AZN'
    | 'BAM'
    | 'BBD'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BMD'
    | 'BND'
    | 'BOB'
    | 'BRL'
    | 'BSD'
    | 'BTC'
    | 'BTN'
    | 'BWP'
    | 'BYR'
    | 'BZD'
    | 'CAD'
    | 'CDF'
    | 'CHF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'CRC'
    | 'CUC'
    | 'CUP'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'EUR'
    | 'FJD'
    | 'FKP'
    | 'GBP'
    | 'GEL'
    | 'GGP'
    | 'GHS'
    | 'GIP'
    | 'GMD'
    | 'GNF'
    | 'GTQ'
    | 'GYD'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HTG'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'IMP'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JEP'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KGS'
    | 'KHR'
    | 'KMF'
    | 'KPW'
    | 'KRW'
    | 'KWD'
    | 'KYD'
    | 'KZT'
    | 'LAK'
    | 'LBP'
    | 'LKR'
    | 'LRD'
    | 'LSL'
    | 'LTL'
    | 'LVL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MNT'
    | 'MOP'
    | 'MRO'
    | 'MUR'
    | 'MVR'
    | 'MWK'
    | 'MXN'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NGN'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PGK'
    | 'PHP'
    | 'PKR'
    | 'PLN'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SBD'
    | 'SCR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SHP'
    | 'SLL'
    | 'SOS'
    | 'SPL'
    | 'SRD'
    | 'STD'
    | 'SVC'
    | 'SYP'
    | 'SZL'
    | 'THB'
    | 'TJS'
    | 'TMT'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TVD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'USD'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'VUV'
    | 'WST'
    | 'XAF'
    | 'XCD'
    | 'XDR'
    | 'XOF'
    | 'XPF'
    | 'YER'
    | 'ZAR'
    | 'ZMW'
    | 'ZWD';
  planId?: string;
  productCategory?: 'ADD_ON' | 'BASE' | 'STANDALONE';
  productName?: string;
  trialLength?: number;
  trialTimeUnit?: 'DAYS' | 'MONTHS' | 'UNLIMITED' | 'WEEKS' | 'YEARS';
};
export const {
  useGetCatalogJsonUsingGetQuery,
  useLazyGetCatalogJsonUsingGetQuery,
  useAddSimplePlanUsingPostMutation,
  useGetCatalogXmlUsingGetQuery,
  useCreateCatalogUsingPostMutation,
} = injectedRtkApi;
